require 'magnific-popup'

window.Hackathons.Projects =
  form: ($$, $this) ->
    class FormView extends Backbone.View
      HIDDEN_CLASS: 'u-hidden'

      initialize: ->
        @$dockerCustomInput = @$('.js-docker_text')

      events: ->
        'change .js-docker_radio': ->
          target = event.target
          @$dockerCustomInput.val target.value
          if target.value == ''
            @$dockerCustomInput.parent().removeClass @HIDDEN_CLASS
          else
            @$dockerCustomInput.parent().addClass @HIDDEN_CLASS

        'click .js-submit': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass 'c-button--loading'

          $target.addClass 'c-button--loading'
          @$('form').ajaxSubmit
            success: (response) =>
              window.location.href = response.redirect_path
            error: (response) =>
              if response.status == 422
                $html = $(response.responseJSON.html)
                @$el.replaceWith $html
                Animate.niceScrollToFirstError($html)
              else
                Tooltips.formServerError($target)

    new FormView el: $this

  show: ($$, $this) ->
    class ShowView extends Backbone.View
      initialize: ->
        $('.js-screenshot-gallery').magnificPopup({
          type: 'image',
          gallery: { enabled: true },
          delegate: 'a',
        })

    new ShowView el: $this
