import * as React from "react";

import { Progress } from "@react/components";
import Typography from "@react/components/typography/Typography";

import { getCollateralWarningText } from "@react/components/warnings";
import { roundFloat } from "../../../../utils";

export default function CollateralStats(props) {
  const currCollateralPercent = parseFloat(
    (props.collateralPercentage * 100).toFixed(0)
  );
  const progress =
    currCollateralPercent < 0
      ? 0
      : currCollateralPercent > 100
      ? 100
      : currCollateralPercent;

  return (
    <div className="c-box s-marginBottom1">
      <div
        style={{
          paddingBottom: 8,
          paddingLeft: 28,
          paddingRight: 28,
          paddingTop: 20,
        }}
      >
        <Typography type="p">
          {getCollateralWarningText(
            roundFloat(props.collateralPercentage * 100, 2),
            roundFloat(props.collateralRequirement * 100, 2),
            roundFloat(props.marginCallLevel * 100, 2),
            roundFloat(props.overCollaterallizedLevel * 100, 2)
          )}
        </Typography>
      </div>
      <div
        style={{
          paddingBottom: 28,
          paddingLeft: 28,
          paddingRight: 28,
          paddingTop: 10,
        }}
      >
        <Progress progress={progress} />
      </div>
    </div>
  );
}
