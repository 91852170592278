require('jquery-form')
Partials = require('../lib/partials')

window.SpvAllocationRequests.Shared =
  form: ($$, $this) =>
    class FormView extends Backbone.View
      events:
        'submit .js-form': (event) ->
          event.preventDefault()
          $form = $(event.target)
          $button = @$('.js-cta')

          return if $button.hasClass('c-button--disabled') || $button.prop('disabled')

          $form.ajaxSubmit
            success: (response) =>
              @trigger 'success'
              Partials.with Partials.selector('index/blockstack_spv_index/spv_allocation_request'), (partial) =>
                partial.showSuccessMessage()
            error: (response) =>
              if response.status == 422
                @$el.replaceWith response.responseJSON.html
              else
                Tooltips.formServerError($button)
    new FormView el: $this