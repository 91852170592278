window.Admin.Hackathons.Pages =
  form: ($$, $this) ->
    class FormView extends Backbone.View
      initialize: ->
        @$nameInput = $('.js-name_input')
        @$magicSlugSelect = $('.js-magic_slug_select')

        $optionElems = @$magicSlugSelect.children('option').toArray()
        @magicSlugOptions = $optionElems.map (o) -> o.value

      events:
        'input .js-name_input': (event) ->
          name = event.target.value
          @$magicSlugSelect.val(if name in @magicSlugOptions then name else '')

        'change .js-magic_slug_select': (event) ->
          @$nameInput.val event.target.value

        'click .js-submit': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass 'c-button--loading'
          $target.addClass 'c-button--loading'

          @$('form').ajaxSubmit
            success: (response) =>
              window.location.href = response.redirect
            error: (response) =>
              @$el.replaceWith(response.responseJSON.html)

    new FormView el: $this
