require('jquery-form')
accounting = require('accounting')
Partials = require('./lib/partials')

window.Investments = {
  usd_wire_refund_form: ($$, $this) =>
    class UsdWireRefundFormView extends Backbone.View
      events:
        'click .js-submit': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass 'c-button--loading'
          $target.addClass 'c-button--loading'
          @$('form').ajaxSubmit
            success: (response) =>
              window.location.href = response.redirect_url
            error: (response) =>
              if response.status == 422
                $html = $(response.responseJSON.html)
                @$el.replaceWith($html)
                $error = $html.find('.js-error').first()
                if $error.length > 0
                  Animate.niceScrollTo($error, -120)
              else
                Tooltips.formServerError($target)
    new UsdWireRefundFormView el: $this

  withdraw_extra_crypto: ($$, $this) =>
    class WithdrawView extends Backbone.View
      events:
        'click .js-submit': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass 'c-button--loading'
          $target.addClass 'c-button--loading'
          $.ajax
            url: $target.attr('href'),
            method: 'POST',
            success: (response) =>
              window.location.href = response.redirect

    new WithdrawView el: $this

  show: ($$, $this) =>
    class ShowView extends Backbone.View
      initialize: ->
        $data = @$('.js-data')

        if $data.length != 0
          @initializeCrypto()

      initializeCrypto: ->
        $data = @$('.js-data')
        @BTC = $data.data('btc')
        @ETH = $data.data('eth')
        @currency = $data.data('currency')

        Partials.with Partials.selector('web_sockets/blockchain_transactions'), (partial) =>
          partial.on 'update', (data) =>
            @updateCurrencyAmountDisplay(data.committed_currency_amount_not_yet_started)
            if data.transactions_html.length > 0
              @showReceivingFundSection()
              @updateTransactions(data.transactions_html)
              @updateAmountsReceived(data.total_currency_amount_started, data.total_usd_value_started)
              if data.funding_completed
                @showCompletedState()

      showReceivingFundSection: ->
        @$('.js-waiting_for_funds').hide()
        @$('.js-receiving_funds').show()

      showCompletedState: ->
        Partials.with Partials.selector('investments/show/receiving_funds'), (partial) =>
          partial.showCompletedState()

        Partials.with Partials.selector('investments/show/sidebar'), (partial) =>
          partial.hideAllocation()

      updateAmountsReceived: (currencyTotal, usdTotal) ->
        @$('.js-amount_received').text(currencyTotal)
        @$('.js-amount_received_in_usd').text(accounting.formatMoney(usdTotal))

      updateTransactions: (html) ->
        Partials.with Partials.selector('investments/show/receiving_funds'), (partial) =>
          partial.updateTransactions(html)

      updateCurrencyAmountDisplay: (amount) ->
        Partials.with Partials.selector('investments/show/crypto_payment_instructions'), (partial) =>
          if @currency == @BTC
            partial.updateBtcAmount(amount)
          else if @currency == @ETH
            partial.updateEthAmount(amount)


    new ShowView el: $this

  edit_ach_info: ($$, $this) ->
    class EditAchInfoForm extends Backbone.View
      initialize: ->
        @$new_account_form  = @$('.js-new_account_form')

      events: ->
        'change .js-ach_option': (event) ->
          if $(event.target).val() == 'new'
            @$new_account_form.slideDown()
          else
            @$new_account_form.slideUp()
    new EditAchInfoForm(el: $this)
}
