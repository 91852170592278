import { getFormattedTime } from "../../../../utils/date";
import { roundFloat } from "../../../../utils";

export const getAllCells = (
  interestRate: number,
  lockUpPeriod: number,
  minDeposit: number,
  assetSymbol: string,
  startDate: any
) => {
  let cells = [];
  if (interestRate && interestRate > 0) {
    cells.push({
      labelText: "Est. Annual Rewards",
      subtitle:
        "This is the estimated return on your investment, not including the fees that FLOW takes.",
      value: `${roundFloat(interestRate * 100, 2)}%`,
      tooltipTitle: "Amount you've entered as % of loan",
    });
  }
  if (lockUpPeriod && lockUpPeriod > 0 && assetSymbol != "T") {
    cells.push({
      labelText: "Lock Up Period",
      subtitle:
        "This is the amount of time your assets will be locked for staking and unavailable for withdrawal.",
      value: `${lockUpPeriod} days`,
      tooltipTitle: "Loan value denominated in USD",
    });
  } else {
    cells.push({
      labelText: "Lock Up Period",
      subtitle:
        "This is the amount of time your assets will be locked for staking and unavailable for withdrawal.",
      value: `Indefinite`,
      tooltipTitle: "Loan value denominated in USD",
    });
  }
  cells.push(
    {
      labelText: "Min. Deposit",
      subtitle:
        "This is the amount of time your assets will be locked for staking and unavailable for withdrawal.",
      value: `${minDeposit} ${assetSymbol}`,
      tooltipTitle: "Loan value denominated in USD",
    },
    {
      labelText: "Start Date",
      subtitle: "This is the date your assets will be begin staking.",
      value: getFormattedTime(startDate),
      tooltipTitle: "Loan value denominated in USD",
    }
  );
  return cells;
};
