import * as React from "react";

const AccountEntitySelectorPlusIcon: React.FC<{}> = ({}) => {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.803711 6.71191C0.803711 6.97559 0.896484 7.2002 1.08203 7.38574C1.26758 7.56641 1.48975 7.65674 1.74854 7.65674H6.0625V11.9707C6.0625 12.2246 6.15283 12.4443 6.3335 12.6299C6.51416 12.8154 6.73633 12.9082 7 12.9082C7.25879 12.9082 7.48096 12.8154 7.6665 12.6299C7.85205 12.4443 7.94482 12.2246 7.94482 11.9707V7.65674H12.2588C12.5127 7.65674 12.7324 7.56641 12.918 7.38574C13.1035 7.2002 13.1963 6.97559 13.1963 6.71191C13.1963 6.45312 13.1035 6.23096 12.918 6.04541C12.7324 5.85986 12.5127 5.76709 12.2588 5.76709H7.94482V1.46045C7.94482 1.20166 7.85205 0.979492 7.6665 0.793945C7.48096 0.608398 7.25879 0.515625 7 0.515625C6.73633 0.515625 6.51416 0.608398 6.3335 0.793945C6.15283 0.979492 6.0625 1.20166 6.0625 1.46045V5.76709H1.74854C1.48975 5.76709 1.26758 5.85986 1.08203 6.04541C0.896484 6.23096 0.803711 6.45312 0.803711 6.71191Z"
        fill="#333333"
      />
    </svg>
  );
};

export default AccountEntitySelectorPlusIcon;
