import * as React from "react";
import cx from "classnames";
import { Radio, RadioProps, InlineLabel } from "@react/components";

export interface RadioOption {
  label: React.ReactNode;
  radio: RadioProps;
}

export interface RadioCollectionsProps {
  name: string;
  value?: string;
  radioOptions: RadioOption[];
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

export const RadioCollections: React.FunctionComponent<RadioCollectionsProps> = ({
  name,
  value,
  radioOptions,
  onChange,
}) => {
  const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (onChange) {
      onChange(event);
    }
  };
  return (
    <div onChange={handleOnChange}>
      {radioOptions &&
        radioOptions.map((radioOption) => (
          <InlineLabel
            key={`${name}-${radioOption.radio.value}`}
            htmlFor={`${name}-${radioOption.radio.value}`}
          >
            <Radio
              name={name}
              id={`${name}-${radioOption.radio.value}`}
              checked={radioOption.radio.value == value}
              {...radioOption.radio}
            />
            {radioOption.label}
          </InlineLabel>
        ))}
    </div>
  );
};

export default RadioCollections;
