import * as React from "react";

import { Box } from "@react/components";

export interface ColorBrandedBorderBoxProps {
  children: React.ReactNode;
}

export const ColorBrandedBorderBox: React.FunctionComponent<ColorBrandedBorderBoxProps> = ({
  children,
}) => {
  return (
    <Box fullHeight fullWidth>
      <div
        style={{
          border: "1px solid #0000004d",
          boxShadow:
            "0px 1px 10px rgb(0 0 0 / 20%), 0px 1px 3px rgb(0 0 0 / 4%)",
          height: "100%",
          position: "relative",
          width: "100%",
        }}
      >
        {children}
      </div>
    </Box>
  );
};
