import * as React from "react";

import Box from "@react/components/Box";
import Flex from "@react/components/Flex";
import { Link } from "@react/components";
import Typography from "@react/components/typography/Typography";

import { hasFullSidebarButSmall } from "@react/utils";

export default function InfoBox(props) {
  const fullSideBarButSmall = hasFullSidebarButSmall();

  return (
    <Box
      onClick={props.onClick}
      style={{
        cursor: "pointer",
        marginBottom: 16,
        padding: 16,
        width: "100%",
      }}
    >
      <Flex alignItems="center" container>
        <Flex item xs={16}>
          <Flex container spacing={1}>
            <Flex item xs={24}>
              <Typography bold>Wrapped assets</Typography>
            </Flex>
            <Flex item xs={24}>
              <Typography bold color="gray" fontSize={13}>
                Wrapped tokens allow you to use tokens from different
                blockchains on the same one. For example, your wrapped BTC
                maintains a 1:1 value as BTC, but can be used on the Ethereum
                blockchain.
              </Typography>
            </Flex>
            <Flex item xs={24}>
              <Link fontWeight={700} fontSize={13} onClick={async () => {}}>
                Learn more
              </Link>
            </Flex>
          </Flex>
        </Flex>
        {!fullSideBarButSmall && (
          <Flex item justifyContent="flex-end" xs={8}>
            <i
              className={`icon-bank u-colorBrandSecondary`}
              style={{ fontSize: 50 }}
            ></i>
          </Flex>
        )}
      </Flex>
    </Box>
  );
}
