window.Purchases =
  index: ($$, $this) ->
    class IndexView extends Backbone.View
      initialize:
        $('.js-toggle a').on 'click', (e) ->
          e.stopPropagation()
      events:
        'click .js-toggle': (event) ->
          $target = $(event.currentTarget)

          targetId = $target.data('toggle-id')
          $toggleTarget = @$(".js-toggle-target[data-toggle-id=#{targetId}]")
          $toggleTarget.toggleClass('u-hidden')

          $target.find('.js-chevron').toggleClass('u-rotate90')

    new IndexView el: $this
