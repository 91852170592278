import * as React from "react";

import { Button, ButtonVariants } from "@react/components";

import { ICON_MAP } from "../../shared/icons";
import { StakingAsset } from "../../types/staking";
import Flex from "../Flex";
import { SegmentReact } from "@react/analytics/segment_react";
import { STX_SYMBOL } from "../dialogs/StakeDialog";
import { caseInsensitiveStringComparison } from "@react/utils";

interface StakingButtonProps {
  asset: StakingAsset;
  mobile: boolean;
  moreOptionsOnClick: () => void;
  stakeOnClick: () => void;
  unstakeOnClick: () => void;
  userId: string;
  sessionId: string;
}

interface AddFundsButtonProps {
  assetName: string;
  url: string;
  variant?: typeof ButtonVariants[number];
  title: string;
  userId: string;
  sessionId: string;
  style?: React.CSSProperties;
}

interface StakeAndUnstakeButtonProps {
  assetName: string;
  url: () => void;
}

function StakeButton(props: StakeAndUnstakeButtonProps) {
  return (
    <Button onClick={props.url} size="small">
      {caseInsensitiveStringComparison(props.assetName, STX_SYMBOL)
        ? "Stack"
        : "Stake"}
    </Button>
  );
}

function UnstakeButton(props: StakeAndUnstakeButtonProps) {
  return (
    <Button onClick={props.url} size="small" variant="gray">
      Unstake
    </Button>
  );
}

function AddFundsButton(props: AddFundsButtonProps) {
  return (
    <Button
      onClick={() => {
        SegmentReact.track(props.assetName + " Staking Page", {
          page_event: props.assetName + " " + props.title + " button clicked",
          user_id: props.userId,
          session_id: props.sessionId,
          viewed_at: new Date(),
        });
        window.open(props.url, "_blank").focus();
      }}
      size="small"
      variant={props.variant || "primary"}
      {...(props.style ? { style: props.style } : {})}
    >
      {props.title}
    </Button>
  );
}

function MoreOptionsButton(props) {
  return (
    <Button onClick={props.onClick} size="small" variant="gray">
      <img
        src={ICON_MAP.moreOptionsSvg}
        style={{
          /* https://stackoverflow.com/questions/22252472/how-to-change-the-color-of-an-svg-element */
          height: 15,
          width: 15,
        }}
      />
    </Button>
  );
}

export default function StakingButtons(props: StakingButtonProps) {
  const { asset, mobile } = props;

  const isEarlyAdopterThresholdVault =
    asset.symbol.toUpperCase() === "T" && asset.apy > 0.15;
  if (mobile) {
    const buttonsForMobile = [];
    if (
      (asset.autoStaking || !asset.stakeUrl) &&
      !isEarlyAdopterThresholdVault
    ) {
      return (
        <AddFundsButton
          assetName={props.asset.symbol}
          url={props.asset.depositUrl}
          title={"Deposit"}
          userId={props.userId}
          sessionId={props.sessionId}
          style={{ background: "black!important" }}
        />
      );
    }
    if (asset.depositsOn && !isEarlyAdopterThresholdVault) {
      buttonsForMobile.push(
        <StakeButton assetName={props.asset.symbol} url={props.stakeOnClick} />
      );
    }

    if (asset.withdrawalsOn) {
      buttonsForMobile.push(
        <UnstakeButton
          assetName={props.asset.symbol}
          url={props.unstakeOnClick}
        />
      );
    }

    return buttonsForMobile.length > 0 ? (
      <Flex container spacing={1}>
        {buttonsForMobile}
      </Flex>
    ) : (
      <div></div>
    );
  }

  if (asset.autoStaking || !asset.stakeUrl) {
    return (
      <Flex container spacing={1}>
        <div></div>
        <MoreOptionsButton onClick={props.moreOptionsOnClick} />
        <AddFundsButton
          assetName={props.asset.symbol}
          url={props.asset.depositUrl}
          variant={
            !asset.withdrawalsOn && !asset.depositsOn ? "primary" : "gray"
          }
          title={"Deposit & Auto-Stake"}
          userId={props.userId}
          sessionId={props.sessionId}
          {...(!asset.withdrawalsOn && !asset.depositsOn ? { style: { background: "black!important" } } : {})}

        />
        <AddFundsButton
          assetName={props.asset.symbol}
          url={props.asset.purchaseUrl}
          variant={"gray"}
          title={"Buy & Auto-Stake"}
          userId={props.userId}
          sessionId={props.sessionId}
        />
      </Flex>
    );
  }
  var buttons = [];
  if (!isEarlyAdopterThresholdVault) {
    buttons = [
      <AddFundsButton
        assetName={props.asset.symbol}
        url={props.asset.depositUrl}
        variant={!asset.withdrawalsOn && !asset.depositsOn ? "primary" : "gray"}
        title={"Deposit"}
        userId={props.userId}
        sessionId={props.sessionId}
        {...(!asset.withdrawalsOn && !asset.depositsOn ? { style: { background: "black!important" } } : {})}
      />,
      <AddFundsButton
        assetName={props.asset.symbol}
        url={props.asset.purchaseUrl}
        variant={"gray"}
        title={"Buy"}
        userId={props.userId}
        sessionId={props.sessionId}
      />,
    ];
  }

  if (asset.showMoreDetails && !isEarlyAdopterThresholdVault) {
    buttons.splice(
      0,
      0,
      <MoreOptionsButton onClick={props.moreOptionsOnClick} />
    );
  }
  if (asset.withdrawalsOn) {
    buttons.splice(
      buttons.length,
      0,
      <UnstakeButton
        assetName={props.asset.symbol}
        url={props.unstakeOnClick}
      />
    );
  }
  if (asset.depositsOn && !isEarlyAdopterThresholdVault) {
    buttons.splice(
      buttons.length,
      0,
      <StakeButton assetName={props.asset.symbol} url={props.stakeOnClick} />
    );
  }
  return (
    <Flex container spacing={1}>
      {buttons}
    </Flex>
  );
}
