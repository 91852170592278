window.Layouts.Shared =
  with_sidebar: ($$, $this) =>
    class WithSidebarView extends Backbone.View
      events: ->
        'click .js-toggle': ->
          @$('.js-sidebar').toggleClass('active')
    new WithSidebarView el: $this

  market: ($$, $this) =>
    class MarketView extends Backbone.View
      initialize: ->
        @sidebarActiveClass = @$el.data('sidebar-active-class')
        @sidebarCollapsedClass = @$el.data('sidebar-collapsed-class')

      scrollToTop: ->
        @$('.js-content').animate {
          scrollTop: 0
        }, AnimationPreset.transitionScrollto, AnimationPreset.transitionEasing

      events: ->
        'click .js-toggle': ->
          @$(".js-sidebar").toggleClass(@sidebarActiveClass)

        'click .js-collapse-sidebar': ->
          console.log(@sidebarCollapsedClass)
          @$(".js-sidebar").toggleClass(@sidebarCollapsedClass)

        'click .js-collapse-sidebar-sections': (event) ->
          $target = $(event.currentTarget)
          sectionClass = $target.data('section-class')
          hiddenClass = $target.data('hidden-class')

          $(sectionClass).each (index, section) =>
            $section = $(section)
            if (!$section.hasClass(hiddenClass))
              $section.toggleClass(hiddenClass)

        'click .js-collapse-sidebar-section': (event) ->
          $target = $(event.currentTarget)
          sectionClass = $target.data('section-class')
          hiddenClass = $target.data('hidden-class')
          @$(sectionClass).toggleClass(hiddenClass)


        'click .js-content': ->
          @$(".js-sidebar").removeClass(@sidebarActiveClass)

    new MarketView el: $this
