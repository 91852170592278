import * as React from "react";

import makeStyles from '@mui/styles/makeStyles';
import Dialog from "@mui/material/Dialog";

import { Divider } from "@react/components";
import Flex from "@react/components/Flex";
import Typography from "@react/components/typography/Typography";
import { isExtraSmallOrSmaller } from "@react/utils";

interface LPDialogProps {
  children: React.ReactNode;
  onClose: () => void;
  open: boolean;
  title: string;
}

const CONTAINER_PADDING = 20;

const useStyles = makeStyles(() => ({
  dialogPaper: {
    height: 420,
    maxWidth: 615,
    width: "100%",
  },
  dialogPaperXs: {
    height: 650,
    maxWidth: 600,
    width: "100%",
  },
}));

export default function LPDialog(props: LPDialogProps) {
  const classes = useStyles();
  const extraSmall = isExtraSmallOrSmaller();

  const paperStyle = extraSmall ? classes.dialogPaperXs : classes.dialogPaper;

  return (
    <Dialog
      classes={{
        paper: paperStyle,
      }}
      fullWidth
      onClose={props.onClose}
      open={props.open}
    >
      <Flex container style={{ padding: CONTAINER_PADDING }} spacing={1}>
        <Flex item xs={24}>
          <Typography type="h6">{props.title}</Typography>
        </Flex>
        <Flex item xs={24}>
          <Divider spacingTop={0} spacingBottom={0} />
        </Flex>
        <Flex container spacing={2} style={{ paddingTop: 8 }}>
          {props.children}
        </Flex>
      </Flex>
    </Dialog>
  );
}
