YouTubePlayer = require('youtube-player')

window.Rewards.Quizzes.Questions =
  question_module: ($$, $this) ->
    class ShowView extends Backbone.View
      QUESTION_ANSWERED_CLASS = 'js_question-module-answered'

      CORRECT_ANSWER_CLASS = 'js_correct-answer'
      CORRECT_SELECTED_CLASS = 'js_correct-selected'
      WRONG_SELECTED_CLASS = 'js_wrong-selected'
      QUIZ_COMPLETED_BUTTON_CLASS = 'js_quiz-completed-button'
      QUIZ_SHOW_NEXT_VIDEO_BUTTON_CLASS = 'js_quiz-next-video'

      FADE_DELAY_MS = 500
      FADE_DURATION_MS = 500
      WRONG_ANSWER_DELAY_MS = 500

      initialize: ->
        @markQuestionAnsweredPath = @$el.data('mark-question-answered-path')
        @nextQuestionPartialPath = @$el.data('next-question-partial-path')
        @nextQuestionPath = @$el.data('next-question-path')
        @upcomingVideoPath = @$el.data('upcoming-video-path')

        @markQuizCompletedPath = @$el.data('mark-quiz-completed-path')
        @completedPagePath = @$el.data('completed-page-path')

        @$questionModule = $('.js_question-module')
        @$tryAgainMessage = $('.js_try-again-message')
        @$postQuestionFlavorText = $('.js_post-question-flavor-text')
        @$nextButton = $('.js_next-button')
        @$loadingOverlay = $('.js_loading-overlay')

        unless Backbone.History.started
          Backbone.history.start({pushState: true})
          Backbone.history.route /.*/, ->
            # always reload the page when hitting the back button
            window.location.reload()

      showCorrectAnswerSelected: ($target) ->
        @$questionModule.addClass QUESTION_ANSWERED_CLASS
        @$tryAgainMessage.fadeOut()

        $target.addClass CORRECT_SELECTED_CLASS
        @$postQuestionFlavorText.delay FADE_DELAY_MS
          .fadeIn FADE_DURATION_MS, =>
            @$nextButton.fadeIn FADE_DURATION_MS

      showWrongAnswerSelected: ($target) ->
        @$tryAgainMessage.fadeIn()
        $target.addClass WRONG_SELECTED_CLASS
          .delay WRONG_ANSWER_DELAY_MS
          .queue (next) ->
            $target.removeClass WRONG_SELECTED_CLASS
            next()

      showErrorMessage: ->
        @$loadingOverlay.fadeOut FADE_DURATION_MS
        $('.js_question-error-message').fadeIn()

      transitionToNextQuestion: ->
        return if @transitioning
        @transitioning = true
        @$loadingOverlay.show()

        @markQuestionAnswered (err) =>
          return @showErrorMessage() if err

          $.get
            url: @nextQuestionPartialPath
            success: (response) =>
              @$el.replaceWith response.html
              Backbone.history.navigate @nextQuestionPath
            error: (response) =>
              @showErrorMessage()

      markQuestionAnswered: (cb) ->
        $.post
          url: @markQuestionAnsweredPath
          success: -> cb()
          error: -> cb new Error('Server error marking question answered')

      transitionToNextVideo: ->
        return if @transitioning
        @transitioning = true
        @$loadingOverlay.show()

        @markQuestionAnswered (err) =>
          return @showErrorMessage() if err
          window.location.href = @upcomingVideoPath

      transitionToCompletedPage: ->
        return if @transitioning
        @transitioning = true
        @$loadingOverlay.show()

        @markQuizCompleted (err) =>
          return @showErrorMessage() if err
          window.location.href = @completedPagePath

      markQuizCompleted: (cb) ->
        $.post
          url: @markQuizCompletedPath
          success: -> cb()
          error: -> cb new Error('Server error marking quiz completed')

      events:
        'click .js_answer-button': (event) ->
          event.preventDefault()
          $target = $(event.currentTarget)

          return if @$questionModule.hasClass QUESTION_ANSWERED_CLASS

          if $target.hasClass CORRECT_ANSWER_CLASS
            @showCorrectAnswerSelected $target
          else
            @showWrongAnswerSelected $target

        'click .js_next-button': (event) ->
          event.preventDefault()
          if $(event.target).hasClass QUIZ_COMPLETED_BUTTON_CLASS
            @transitionToCompletedPage()
          else if $(event.target).hasClass QUIZ_SHOW_NEXT_VIDEO_BUTTON_CLASS
            @transitionToNextVideo()
          else
            @transitionToNextQuestion()

    new ShowView el: $this
