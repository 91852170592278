window.Admin.Users.Show =
  options: ($$, $this) =>
    class OptionsView extends Backbone.View
      events:
        'click .js-toggle-options': (event) ->
          event.preventDefault()

          @$('.js-options').toggleClass('u-hidden')

        'click .js-trigger-mark-as-test-account': (event) ->
          event.preventDefault()
          event.stopPropagation()

          $target = $(event.currentTarget)
          $target.addClass 'c-button--loading'

          $.ajax
            url: $target.attr('href'),
            type: 'POST',
            success: (response) =>
              $target.removeClass 'c-button--loading'
              window.location.href = response.redirect

        'click .js-close_account_button': (event) ->
          $target = $(event.currentTarget)
          $target.addClass 'c-button--loading'

        'click .js-one_click_offboarding_button': (event) ->
          $target = $(event.currentTarget)
          $target.addClass 'c-button--loading'

    new OptionsView el: $this

  overview: ($$, $this) =>
    class OverviewView extends Backbone.View
      _toggleEmailForm: (showEmailForm) ->
        @$('.js-edit_email_content').toggleClass('u-hidden', !showEmailForm)
        @$('.js-show_email_content').toggleClass('u-hidden', showEmailForm)

      _toggleRfqzerodateForm: (showRfqzerodateForm) ->
        @$('.js-edit_rfqzerodate_content').toggleClass('u-hidden', !showRfqzerodateForm)
        @$('.js-show_rfqzerodate_content').toggleClass('u-hidden', showRfqzerodateForm)

      events:
        'click .js-cancel_edit_email_link': (event) ->
          @_toggleEmailForm false

        'click .js-edit_email_link': (event) ->
          @_toggleEmailForm true

        'click .js-cancel_edit_rfqzerodate_link': (event) ->
          @_toggleRfqzerodateForm false

        'click .js-edit_rfqzerodate_link': (event) ->
          @_toggleRfqzerodateForm true

        'click .js-blacklist-form-switch': (event) ->
          @$('.js-blacklist-status').hide()
          @$('.js-blacklist-form').show()

        'click .js-cancel_edit_blacklist_link': (event) ->
          @$('.js-blacklist-form').hide()
          @$('.js-blacklist-status').show()

        'click .js-salesban-form-switch': (event) ->
          @$('.js-salesban-status').hide()
          @$('.js-salesban-form').show()

        'click .js-cancel_edit_salesban_link': (event) ->
          @$('.js-salesban-form').hide()
          @$('.js-salesban-status').show()

    new OverviewView el: $this

  referrals: ($$, $this) ->
    class ReferralsView extends Backbone.View
      events:
        'click .js-void_referrals': (event) ->
          event.preventDefault()
          event.stopPropagation()
          $target = @$(event.currentTarget)
          $.ajax
            url: $target.attr('href'),
            type: 'POST',
            success: (response) =>
              window.location.reload()
    new ReferralsView el: $this

  admin_event_log: ($$, $this) ->
    class AdminEventLog extends Backbone.View
      events:
        'click .js-add-event-log-switch': (event) ->
          @$('.add-event-log-show-link').hide()
          @$('.add-event-log-form').show()

        'click .js-cancel-add-event-log-note': (event) ->
          @$('.add-event-log-show-link').show()
          @$('.add-event-log-form').hide()

    new AdminEventLog el: $this