import * as React from "react";

import Typography from "@react/components/typography/Typography";
import Flex from "@react/components/Flex";
import SectionTitle from "@react/components/SectionTitle";

interface RewardsProps {
  registerLink: string;
  style?: any;
}

export default function Rewards(props: RewardsProps) {
  const steps = [
    {
      header: "Create a CoinList account",
      linkElement: <a href={props.registerLink}>Sign up &raquo;</a>,
      subtitle:
        "Creating an account on CoinList takes minutes. You'll need to provide identity information including 2-factor authentication for added security.",
      symbol: "eth",
    },
    {
      header: "Deposit assets to stake",
      linkElement: <a href={props.registerLink}>Deposit assets &raquo;</a>,
      subtitle:
        "Deposit assets that are eligible for staking into your CoinList wallet. If you don't already own the assets you want to stake, you can easily purchase them on CoinList.",
      symbol: "flow",
    },
    {
      header: "Earn rewards",
      linkElement: <a href={props.registerLink}>Earn rewards &raquo;</a>,
      subtitle:
        "Once your funds arrive in your wallet, you'll immediately start earning staking rewards. Rewards are generally paid once per month.",
      symbol: "mina",
    },
  ];

  return (
    <Flex container style={props.style}>
      <SectionTitle
        style={{ paddingBottom: 48 }}
        subtitle="CoinList stakes, generates, and signs blocks on your behalf while you
          retain full ownership of your tokens and earn rewards."
        title="Start earning rewards in no time"
      />
      <Flex item xs={24}>
        <Flex container spacing={5}>
          {steps.map(({ header, linkElement, subtitle, symbol }, index) => (
            <Flex item key={symbol} sm={24 / steps.length} xs={24}>
              <Typography
                bold
                fontSize={30}
                style={{ paddingBottom: 8, width: "100%" }}
              >
                {index + 1}
              </Typography>
              <Typography
                bold
                fontSize={18}
                style={{ paddingBottom: 8, width: "100%" }}
              >
                {header}
              </Typography>
              <Typography style={{ paddingBottom: 8, width: "100%" }}>
                {subtitle}
              </Typography>
              <Flex item xs={24}>
                {linkElement}
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
}
