Partials = require('../../../lib/partials')

window.Admin.Offerings.Users.Permissions =
  index: ($$, $this) =>
    class IndexView extends Backbone.View
      initialize: ->
        Partials.with Partials.selector('admin/offerings/users/permissions/index/batch_create_form'), (partial) =>
          partial.on 'updateTable', (tableHtml) =>
            @$('.js-table_wrapper').show()
            @$('.js-table').html(tableHtml)

    new IndexView el: $this