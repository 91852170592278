import * as React from "react";

import Typography from "@react/components/typography/Typography";
import Flex from "@react/components/Flex";
import SectionTitle from "@react/components/SectionTitle";

interface RewardsProps {
  style?: any;
}

export default function Rewards(props: RewardsProps) {
  const sortedCustodians = [
    "Bitgo",
    "Coinbase Custody",
    "Gemini Custody",
    "Copper",
    "Finoa",
  ].sort().join(", ");
  const steps = [
    {
      header: "Reduced Price Risk",
      //   linkElement: <a href="/register">Sign up &raquo;</a>,
      subtitle:
        "The best desks compete to get you the best possible price. Onboard once with CoinList and have immediate access to global markets.",
    },
    {
      header: "Anonymity",
      //   linkElement: <a href={"/register"}>Deposit &raquo;</a>,
      subtitle:
        "CoinList protects your anonymity when trading on your behalf. Avoid the risk of an OTC desk piggy-backing your trade or trading against you.",
    },
    {
      header: "Simple Execution and Settlement",
      //   linkElement: <a href="/staking">Communicate &raquo;</a>,
      subtitle:
        "CoinList manages the execution of trades and settlement of assets with counterparties so you avoid operational errors and conserve time.",
    },
    {
      header: "Secure",
      //   linkElement: <a href="/staking">Trade &raquo;</a>,
      subtitle:
        `Your funds are available via your coinlist.co wallet. Powered by world-class custodians including ${sortedCustodians}.`,
    },
  ];

  return (
    <Flex container style={props.style}>
      <SectionTitle
        style={{ paddingBottom: 48 }}
        // subtitle="CoinList stakes, generates, and signs blocks on your behalf while you
        //   retain full ownership of your tokens and earn rewards."
        title="White glove trading services with simple and secure transactions and settlement"
      />
      <Flex item xs={24}>
        <Flex alignItems="flex-start" container spacing={5}>
          {steps.map(({ header, subtitle }, index) => (
            <Flex item key={header} sm={12} xs={24}>
              {/* <Typography
                bold
                fontSize={30}
                style={{ paddingBottom: 8, width: "100%" }}
              >
                {index + 1}
              </Typography> */}
              <Typography
                bold
                fontSize={18}
                style={{ paddingBottom: 8, width: "100%" }}
              >
                {header}
              </Typography>
              <Typography style={{ paddingBottom: 8, width: "100%" }}>
                {subtitle}
              </Typography>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
}
