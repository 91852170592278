import * as Castle from "@castleio/castle-js"

window.FullStory = require('@fullstory/browser')
window.rollbar = require('rollbar')
{ ampli } = require('../ampli')
window.ampli = ampli

window.Analytics =

  TRACK_LINK_EVENT_NAME: 'Link clicked'

  initialize: ->
    # Libraries
    @_initializeCastle()
    @_initializeFullStory()
    @_initializeRollbar()
    # Global tracking
    @_initializeLinkTracking()

    @_initializeAmpli()

  # Track: Generic
  trackEvent: (event, data = {}, callback) ->

    # TODO: Ideally wrap these in a promise so we don't callback() until both
    # are done
    @trackGtagEvent(event, data)

    # Callback is a defined function
    callback?()

  # Track: Google analytics
  trackGtagEvent: (event, data = {}) ->
    # Gtag specific data needs to have event_category defined
    gtagData = _.extend({'event_category': 'All'}, data)
    gtag?('event', event, gtagData)

  # Castle integration
  # Data is defined in app/views/layouts/_castle_data (only in production)
  _initializeCastle: ->
    if castleData?.castlePublishKey
      Castle.configure({ pk: castleData.castlePublishKey })

  # FullStory integration
  # Data is defined in app/views/layouts/_full_story_data
  _initializeFullStory: ->
    if FullStory? && fullStoryData?
      FullStory.init({
        orgId: fullStoryData.orgId
        debug: fullStoryData.debug
      })

      # Identify user
      if fullStoryData.userId?
        FS.identify(fullStoryData.userId, {
          displayName: fullStoryData.userName,
          email: fullStoryData.userEmail
        })

  # Rollbar integration
  # Data is defined in app/views/layouts/_rollbar_data
  _initializeRollbar: ->
    if rollbar? && _rollbarConfig? && _rollbarConfig?.accessToken
      window.Rollbar = rollbar.init(_rollbarConfig)

  # Global link click tracking
  _initializeLinkTracking: ->
    $('.js-analytic_track_link').on 'click', (event) =>

      # Prevent
      event.preventDefault()

      # $link
      $target = $(event.currentTarget)

      # Data
      design = if $target.hasClass('c-button') then 'button' else 'link'
      data = {
        content:      $target.text().trim(),                  # Text
        current_path: window.location.pathname,               # From path
        path:         $target.attr('href'),                   # To path
        design:       design,                                 # Button / link?
        context:      $target.closest('[data-rc]').data('rc') # Partial
      }

      # Track
      @trackGtagEvent(@TRACK_LINK_EVENT_NAME, { link_path: $target.attr('href') })

      # Redirect
      window.location.href = $target.prop('href')

  # Amplitude integration
  _initializeAmpli: ->
    if ampli? && amplitudeData?.apiKey
      ampli.load({ client: { apiKey: amplitudeData.apiKey } })

# lets do this
Analytics.initialize()
