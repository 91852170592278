window.Offerings.Participants.ManagementFeeRefund =
  form: ($$, $this) ->
    class FormView extends Backbone.View
      initialize: ->
        @$wire_form  = @$('.js-wire_form')
        @$ach_form  = @$('.js-ach_form')
        @_unrequireWireFields()

      events: ->
        'click .js-no_us_bank_account': (event) ->
          event.preventDefault()
          @$wire_form.slideDown()
          @$ach_form.slideUp()
          @$('.js-refund_method').val('wire')
        'change .js-select-bank-account': '_toggleAddBankAccount'
        'click .js-submit': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass 'c-button--loading'
          $target.addClass 'c-button--loading'
          @$('form').ajaxSubmit
            success: (response) =>
              window.location.href = response.redirect_url
            error: (response) =>
              if response.status == 422
                $html = $(response.responseJSON.html)
                @$el.replaceWith($html)
                $error = $html.find('.js-error').first()
                if $error.length > 0
                  Animate.niceScrollTo($error, -120)
              else
                Tooltips.formServerError($target)

      _requireWireFields: ->
        @$('.js-required-beneficiary-name').attr('required', true)
        @$('.js-required-beneficiary-address-line1').attr('required', true)
        @$('.js-required-beneficiary-address-line2').attr('required', true)
        @$('.js-required-beneficiary-account-number').attr('required', true)
        @$('.js-required-us-field').attr('required', true)
        @$('.js-required-secret').attr('required', true)

      _unrequireWireFields: ->
        @$('.js-required-beneficiary-name').attr('required', false)
        @$('.js-required-beneficiary-address-line1').attr('required', false)
        @$('.js-required-beneficiary-address-line2').attr('required', false)
        @$('.js-required-beneficiary-account-number').attr('required', false)
        @$('.js-required-us-field').attr('required', false)
        @$('.js-required-secret').attr('required', false)

      _toggleAddBankAccount: ->
        if @$('.js-select-bank-account').val() == 'new'
          @$('.js-add-bank').show()
          @_requireWireFields()
        else
          @$('.js-add-bank').hide()
          @_unrequireWireFields()

    new FormView el: $this

