window.Comments =
  list: ($$, $this) ->
    class ListView extends Backbone.View
      LOADING_CLASS = 'c-button--loading'

      events: ->
        'click .js-submit': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass LOADING_CLASS

          $target.addClass LOADING_CLASS
          @$('form').ajaxSubmit
            success: (response) =>
              @$el.replaceWith(response.html)
            error: (response) =>
              @$el.replaceWith(response.responseJSON.html)

    new ListView el: $this
