import * as React from "react";
import { useRef, useState } from "react";
import { CSVLink } from "react-csv";

import { Label, Link, Icon } from "@react/components";
import TableWithTitle from "@react/views/shared/TableWithTitle";
import Typography from "@react/components/typography/Typography";

import {
  getCSVFriendlyFormattedTime,
  getFormattedTime,
} from "@react/utils/date";
import { getStateText, roundFloat, roundFloatStr } from "@react/utils";
import { axiosRequest, RequestType } from "@react/utils/network";

const headCells = [
  "Principal",
  "Interest Rate",
  "State",
  "Start Date",
  "End Date",
  "",
];

const summaryHeadCells = ["Asset", "Principal", "Collateral"];

const getRows = (placedLoans: Array<any>) => {
  return placedLoans.map((placedLoan) => [
    <Typography fontSize={16}>
      {`${roundFloatStr(
        placedLoan.loan_asset.amount_subunit,
        6
      ).toLocaleString()} ${placedLoan.loan_asset.symbol}`}
    </Typography>,
    <Typography fontSize={16}>
      {`${(placedLoan.interest_rate * 100).toFixed(1)}%`}
    </Typography>,
    <Typography fontSize={16}>{getStateText(placedLoan)}</Typography>,
    <Typography fontSize={16}>
      {getFormattedTime(placedLoan.start_date)}
    </Typography>,
    <Typography fontSize={16}>
      {placedLoan.end_date ? getFormattedTime(placedLoan.end_date) : "TBD"}
    </Typography>,
    <a className="s-fontSize16" href={placedLoan.url}>
      View Details
    </a>,
  ]);
};

const getSummaryRows = (placedLoansSummaryArr) => {
  return placedLoansSummaryArr.map((assetRow) => assetRow);
};

export default function PlacedLoansTable(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const csvLink = useRef();

  const handleClick = async () => {
    setLoading(true);
    const response: any = await axiosRequest(
      props.csvUrl,
      null,
      RequestType.GET
    );
    setData(response.status === 200 ? response.data : []);
    // @ts-ignore
    if (csvLink && csvLink.current && csvLink.current.link) {
      // @ts-ignore
      csvLink.current.link.click();
    }
    setLoading(false);
  };

  return (
    <div>
      <div className="s-marginTop1">
        <TableWithTitle
          headers={headCells}
          rows={getRows(props.placedLoans)}
          title={
            <div>
              <div className="u-displayInline">
                Loans from CoinList&nbsp;&middot;&nbsp;
              </div>
              <div className="u-displayInline">
                <Link cursorPointer onClick={handleClick}>
                  Download Report
                </Link>
                {loading && (
                  <span className="s-paddingLeft0_5">
                    <Icon icon="spin6" />
                  </span>
                )}
                {/* https://stackoverflow.com/a/63430955 */}
                <CSVLink
                  className="hidden"
                  data={data}
                  filename={`coinlist_loans_${getCSVFriendlyFormattedTime(
                    new Date()
                  )}.csv`}
                  ref={csvLink}
                  target="_blank"
                />
              </div>
            </div>
          }
        />
      </div>
      <div className="s-marginTop1">
        <TableWithTitle
          headers={summaryHeadCells}
          rows={getSummaryRows(props.placedLoansSummaryArr)}
          title={
            <div>
              <div className="u-displayInline">
                Loans from CoinList&nbsp;&middot;&nbsp;
              </div>
              <div className="u-displayInline">Overview</div>
            </div>
          }
        />
      </div>
    </div>
  );
}
