// TODO: This should probably be removed in favor of blockField use
import * as React from "react";

import { TextInput } from "@react/components";
import BlockField from "@react/views/shared/forms/BlockField/BlockField";
import InputWithAddon from "@react/views/shared/forms/InputWithAddon/InputWithAddon";
import { Link } from "@react/components";

import { isInt, roundFloat } from "../../utils";

interface PercentageInputProps {
  floatingPointError: boolean;
  helperText?: string;
  insufficientBalanceError: boolean;
  percentage: string;
  setFloatingPointError: (boolean) => void;
  setInsufficientBalanceError: (boolean) => void;
  setPercentToWithdraw: (string) => void;
}

export default function PercentageInput(props: PercentageInputProps) {
  const onChangeHandler = (e) => {
    const invalidNumber = isNaN(parseFloat(e.target.value));
    const newValue = invalidNumber ? "" : e.target.value;
    props.setPercentToWithdraw(newValue);
    props.setFloatingPointError(
      Boolean(newValue) && (invalidNumber || !isInt(parseFloat(newValue)))
    );
    props.setInsufficientBalanceError(
      Boolean(newValue) && !invalidNumber && parseFloat(newValue) > 100
    );
  };
  const labelContent = (
    <>
      Percentage&nbsp;&middot;&nbsp;
      <Link onClick={() => props.setPercentToWithdraw("100")}>{"Max"}</Link>
    </>
  );

  return (
    <BlockField
      label={labelContent}
      error={props.insufficientBalanceError || props.floatingPointError}
      hint="Please enter a valid whole number less than or equal to 100"
    >
      <InputWithAddon addon="%">
        <TextInput
          onChange={onChangeHandler}
          placeholder="15"
          value={props.percentage.toString() || ""}
        />
      </InputWithAddon>
    </BlockField>
  );
}
