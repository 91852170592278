window.Admin.Rewards ||= {}
window.Admin.Rewards.Quizzes ||= {}
window.Admin.Rewards.Quizzes.Participants =
  index: ($$, $this) ->
    class IndexView extends Backbone.View
      LOADING_CLASS = 'c-button--disabled'

      getCorrespondingParticipantControls: ($toggleElement) ->
        $toggleElement
          .parents('tr')
          .next('tr')
          .find('.js_participant-controls')

      events:
        'click .js_show-participant-controls': (event) ->
          $target = $(event.currentTarget)
          $target.hide()
          $target.next().show()
          @getCorrespondingParticipantControls($target).slideDown()

        'click .js_hide-participant-controls': (event) ->
          $target = $(event.currentTarget)
          $target.hide()
          $target.prev().show()
          @getCorrespondingParticipantControls($target).slideUp()

        'click .js_participant-row-button': (event) ->
          event.preventDefault()
          $target = $(event.target)

          return if $target.hasClass LOADING_CLASS
          $target.addClass LOADING_CLASS
          buttonText = $target.text()
          $target.text '...'

          $.post
            url: $target.attr('href'),
            success: (response) ->
              $target.text buttonText
              $target.removeClass LOADING_CLASS
              $controlsRow = $target.parents('tr')

              # insert the new rendered rows and remove the old ones
              $controlsRow
                .add $controlsRow.prev()
                .first()
                .before response.html
                .end()
                .remove()

    new IndexView el: $this
