import * as React from "react";
import { useState } from "react";

import Snackbar from "@mui/material/Snackbar";

import Convert from "./components/Convert";
import Flex from "../../../components/Flex";
import Submit from "./components/Submit";
import TableWithTitle from "@react/views/shared/TableWithTitle";
import Typography from "../../../components/typography/Typography";
import Warning from "@react/components/warnings/Warning";

import {
  isExtraSmallOrSmaller,
  isTestEnv,
  roundFloatStr,
} from "../../../utils";

export const getRequestData = ({
  amount,
  assetToConvert,
  entityId,
  lendBtcBalance = null,
  lendTbtcBalance = null,
  lendWbtcBalance = null,
  walletMap,
}) => {
  if (assetToConvert.base_asset_symbol === "BTC") {
    return {
      wrap_request: {
        amount,
        base_asset_symbol: assetToConvert.base_asset_symbol,
        counter_asset_symbol: assetToConvert.counter_asset_symbol,
        customer_wallet_id: walletMap[assetToConvert.base_asset_symbol].id,
        entity_id: entityId,
        lend_tbtc_balance: lendTbtcBalance,
        lend_wbtc_balance: lendWbtcBalance,
      },
    };
  } else if (assetToConvert.base_asset_symbol === "WBTC") {
    return {
      unwrap_request: {
        amount,
        base_asset_symbol: assetToConvert.base_asset_symbol,
        counter_asset_symbol: assetToConvert.counter_asset_symbol,
        customer_wallet_id: walletMap[assetToConvert.base_asset_symbol].id,
        entity_id: entityId,
        lend_btc_balance: lendBtcBalance,
      },
    };
  }
  return {
    conversion_request: {
      amount,
      base_asset_symbol: assetToConvert.base_asset_symbol,
      counter_asset_symbol: assetToConvert.counter_asset_symbol,
      customer_wallet_id: walletMap[assetToConvert.base_asset_symbol].id,
    },
  };
};

const HEAD_CELLS = [
  "Date",
  "Type",
  <div className="u-textAlignRight">Amount Converted</div>,
  <div className="u-textAlignRight">Amount Received</div>,
];

const getRows = (previousConversions) => {
  return previousConversions.map((conversion) => [
    <Typography color="gray7" fontSize={14}>
      {conversion.date}
    </Typography>,
    <Typography color="gray7" fontSize={14}>
      {conversion.description}
    </Typography>,
    <Flex container justifyContent="flex-end">
      <Typography bold fontSize={14}>
        -{roundFloatStr(conversion.base_asset_amount, 6)}{" "}
        {conversion.base_asset}
      </Typography>
    </Flex>,
    <Flex container justifyContent="flex-end">
      <Typography bold color="green" fontSize={14}>
        +{roundFloatStr(conversion.quote_asset_amount, 6)}{" "}
        {conversion.counter_asset}
      </Typography>
    </Flex>,
  ]);
};

export default function Index(props) {
  const extraSmall = isExtraSmallOrSmaller();
  const [amount, setAmount] = useState("");
  const [amountToDeliver, setAmountToDeliver] = useState(0);
  const [conversionPairs, setConversionPairs] = useState(
    props.assetSelectOptions
  );
  const [assetToConvert, setAssetToConvert] = useState(
    conversionPairs.find((asset) => asset.selected) || conversionPairs[0]
  );
  const [fee, setFee] = useState(props.coinListFee);
  const [page, setPage] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [response, setResponse] = useState(null);

  return (
    <Flex container spacing={4}>
      <Flex
        item
        md={14}
        style={{
          maxWidth: props.transferRequests.length === 0 ? "550px" : "100%",
        }}
        xs={24}
      >
        {page === 0 ? (
          <Convert
            amount={amount}
            assetToConvert={assetToConvert}
            assetSelectOptions={props.assetSelectOptions}
            conversionPairs={conversionPairs}
            entityId={props.entityId}
            entitySelectOptions={props.entitySelectOptions}
            entitySlug={props.entitySlug}
            incrementPage={() => setPage(page + 1)}
            minAmountsMap={props.minAmountsMap}
            setAmount={(amount) => setAmount(amount)}
            setAmountToDeliver={(amount) => setAmountToDeliver(amount)}
            setAssetToConvert={(value) => setAssetToConvert(value)}
            setFee={(fee) => setFee(fee)}
            walletMap={props.walletMap}
          />
        ) : (
          <Submit
            amount={parseFloat(amount)}
            amountToDeliver={amountToDeliver}
            assetToConvert={assetToConvert}
            coinListFee={fee}
            conversionsIndexUrl={props.conversionsIndexUrl}
            decrementPage={() => setPage(page - 1)}
            entityId={props.entityId}
            lendBtcBalance={props.lendBtcBalance}
            lendTbtcBalance={props.lendTbtcBalance}
            lendWbtcBalance={props.lendWbtcBalance}
            response={response}
            setResponse={setResponse}
            setSnackbarOpen={setSnackbarOpen}
            walletMap={props.walletMap}
          />
        )}
      </Flex>
      <Flex item md={8} xs={0}>
        {/* For now, just space filler */}
        {/* <Flex container spacing={2}>
          <Flex item xs={24}>
            <ConvertCard />
          </Flex>
          <Flex item xs={24}>
            <LogoCard />
          </Flex>
        </Flex> */}
      </Flex>
      {/* TODO - table looks bad on mobile, but should be styled, not hidden */}
      {!extraSmall && props.transferRequests.length > 0 && (
        <Flex item md={14} xs={24}>
          <TableWithTitle
            headers={HEAD_CELLS}
            rows={getRows(props.transferRequests)}
            rowsPerPage={10}
            title={"Previous Conversions"}
          />
        </Flex>
      )}
      {/* Filler */}
      {!extraSmall && props.transferRequests.length > 0 && (
        <Flex item md={8} xs={0}></Flex>
      )}
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        autoHideDuration={3000}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
      >
        <Warning
          color={"orange"}
          fullWidth
          fontSize={13}
          padding={8}
          variant={"warning"}
          warningText={
            response && response.data.errors
              ? response.data.errors.message
              : "Error attempting conversion, please try again."
          }
        />
      </Snackbar>
    </Flex>
  );
}
