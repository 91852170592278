window.Admin.WaitlistRequests.Shared =
  table: ($$, $this) ->
    class TableView extends Backbone.View
      initialize: ->
        @positionColumn = @$('.js-position_column').index()
        options = _.extend(DataTablePresets.default(), {
          order: [[ @positionColumn, "desc" ]],
          scrollY: '50vh'
          scrollCollapse: true
        })
        @tableApi = @$('.js-table').DataTable(options)

      events:
        'input .js-search_input': (event) ->
          @tableApi.search(event.currentTarget.value).draw()

    new TableView el: $this
