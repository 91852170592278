require('jquery')
require('jquery.initialize/jquery.initialize')
cleave = require('cleave.js')

window.PrecisionPriceInput =
  options:
    numeral: true
    numeralPositiveOnly: true
    numeralThousandsGroupStyle: 'thousand'
    numeralDecimalScale: 6

  initialize: ->
    $.initialize '.js-precision_price_input', =>
      @initializePrecisionPriceInput()

  initializePrecisionPriceInput: ->
    $('.js-precision_price_input').toArray().forEach (field) =>
      $field = $(field)
      unless $field.data('cleaveInstance')
        cleaveInstance = new Cleave(field, @options)
        $field.data('cleaveInstance', cleaveInstance)