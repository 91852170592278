window.Keyboard =
  backspace: 8
  tab: 9
  clear: 12
  enter: 13
  return: 13
  esc: 27
  escape: 27
  space: 32
  left: 37
  up: 38
  right: 39
  down: 40
  del: 46
  delete: 46
  home: 36
  end: 35
  pageup: 33
  pagedown: 34