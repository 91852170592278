Plyr = require('plyr')

window.Index.CeloIndex =
  header: ($$, $this) =>
    class HeaderView extends Backbone.View
      initialize: ->
        @player = new Plyr('.js-video', PlyrPresets.default())

    new HeaderView el: $this

  about: ($$, $this) =>
    class AboutView extends Backbone.View
      initialize: ->

        @player = new Plyr(@$('.js-video'), PlyrPresets.default())

    new AboutView el: $this

  faq: ($$, $this) =>
    class FaqView extends Backbone.View
      events:
        'click .js-faq-question': (event) ->
          event.preventDefault()
          $target = @$(event.currentTarget)
          $target.next('.js-faq-answer').slideToggle()
          $target.closest('.js-faq').toggleClass('index-celo_index-faq__question--open')

    new FaqView el: $this

  faq_2: ($$, $this) =>
    class FaqView extends Backbone.View
      events:
        'click .js-faq-question': (event) ->
          event.preventDefault()
          $target = @$(event.currentTarget)
          $target.next('.js-faq-answer').slideToggle()
          $target.closest('.js-faq').toggleClass('index-celo_index-faq_2__question--open')

    new FaqView el: $this

  faq_auction: ($$, $this) =>
    class FaqAuctionView extends Backbone.View
      events:
        'click .js-faq-question': (event) ->
          event.preventDefault()
          $target = @$(event.currentTarget)
          $target.next('.js-faq-answer').slideToggle()
          $target.closest('.js-faq').toggleClass('index-celo_index-faq_auction__question--open')

    new FaqAuctionView el: $this

  faq_token: ($$, $this) =>
    class FaqAuctionView extends Backbone.View
      events:
        'click .js-faq-question': (event) ->
          event.preventDefault()
          $target = @$(event.currentTarget)
          $target.next('.js-faq-answer').slideToggle()
          $target.closest('.js-faq').toggleClass('index-celo_index-faq_token__question--open')

    new FaqAuctionView el: $this

  faq_celo: ($$, $this) =>
    class FaqAuctionView extends Backbone.View
      events:
        'click .js-faq-question': (event) ->
          event.preventDefault()
          $target = @$(event.currentTarget)
          $target.next('.js-faq-answer').slideToggle()
          $target.closest('.js-faq').toggleClass('index-celo_index-faq_celo__question--open')

    new FaqAuctionView el: $this

  contributors: ($$, $this) =>
    class ContributorsView extends Backbone.View
      initialize: ->
        @player = new Plyr(@$('.js-video'), PlyrPresets.default())

    new ContributorsView el: $this
