import * as React from "react";
import { useState } from "react";

import Flex from "../Flex";
import SectionTitle from "../SectionTitle";
import Typography from "../typography/Typography";

interface FAQQuestions {
  content: React.ReactNode;
  title: string;
}

interface FAQProps {
  style?: any;
  questions: Array<FAQQuestions>;
}

const createOpenElementsObj = (questions) => {
  let retObj = {};
  for (let i = 0; i < questions.length; i++) {
    retObj[i] = false;
  }
  return retObj;
};

export default function FAQ(props: FAQProps) {
  const [openElements, setOpenElements] = useState(
    createOpenElementsObj(props.questions)
  );
  return (
    <div style={props.style || {}}>
      <SectionTitle title="FAQ" />
      <Flex container>
        {props.questions.map(({ content, title }, index) => (
          <Flex item key={index} style={{ paddingBottom: 32 }} xs={24}>
            <Flex
              alignItems="center"
              container
              onClick={() =>
                setOpenElements({
                  ...openElements,
                  [index]: !openElements[index],
                })
              }
              style={{ cursor: "pointer" }}
            >
              <i
                className={`s-fontSize16 ${
                  openElements[index]
                    ? "icon-chevron-down"
                    : "icon-chevron-right"
                }`}
                style={{ paddingRight: 8 }}
              ></i>
              <Typography fontSize={22}>{title}</Typography>
              {openElements[index] && (
                <div style={{ paddingLeft: 25, paddingTop: 10 }}>{content}</div>
              )}
            </Flex>
          </Flex>
        ))}
      </Flex>
    </div>
  );
}
