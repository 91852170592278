import * as React from "react";
import { useState } from "react";
import {
  Field,
  Select,
  Checkbox,
  TextArea,
  TextInput,
  Label,
  InlineLabel,
  Hint,
  Error,
} from "@react/components";
import BlockField from "@react/views/shared/forms/BlockField/BlockField";
import RadioCollections from "@react/views/shared/forms/RadioCollections/RadioCollections";

interface BlockFieldSectionProps {
  // props
}

const BlockFieldSection: React.FunctionComponent<BlockFieldSectionProps> = (
  {
    // props
  }
) => {
  const blockFieldMeta = `<Field>
  <Label>
  {children //ie: input(s)} 
  <Error> || <Hint>
</Field>`;

  const blockField = `<BlockField label="Name" name="name" hint="check your ID">
  <TextInput name="name" placeholder="Jon" />
</BlockField>`;

  const fieldExample = `<Field>
  <Label htmlFor="name">
    Name
  </Label>
  <TextInput name="name" placeholder="Jon" />
  <Hint>check your ID</Hint>
</Field>`;

  const blockFieldWithErrorExample = `<BlockField label="Name" name="name" hint="check your ID" error="No numbers allowed">
  <TextInput name="name" placeholder="Jon" value="Samuel1234"/>
</BlockField>`;

  const fieldWithErrorExample = `<Field error="true">
  <Label htmlFor="name">
    Name
  </Label>
  <TextInput name="name" placeholder="Jon" value="Samuel1234"/>
  {error ? <Error>{error}</Error>}
  {!error && hint ? <Hint>{hint}</Hint>}
</Field>`;

  return (
    <div>
      <div className="s-marginBottom2">
        <h2 className="s-fontSize26 s-borderBottom0_5 s-marginBottom1 u-colorGray2">
          BlockField
        </h2>
        <p className="s-marginBottom0_5 u-colorGray6">
          BlockField is a fixed layout implementation of{" "}
          <code className="c-code c-code--inline">
            <a href="#Field">{"<Field>"}</a>
          </code>
          .
        </p>
        <p className="s-marginBottom0_5 u-colorGray6">
          It enforces this layout:
        </p>
        <pre>{blockFieldMeta}</pre>
      </div>

      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">Example</h3>
        <div className="s-marginBottom1">
          <pre>
            <BlockField label="Name" name="name" hint="check your ID">
              <TextInput name="name" placeholder="Jon" />
            </BlockField>
          </pre>
        </div>
        <p className="s-marginBottom0_5 u-colorGray6">Is the same as</p>
        <pre>{fieldExample}</pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          Example with error
        </h3>
        <div className="s-marginBottom1">
          <pre>
            <BlockField
              label="Name"
              name="name"
              hint="check your ID"
              error="No numbers allowed"
            >
              <TextInput name="name" placeholder="Jon" value="Samuel1234" />
            </BlockField>
          </pre>
        </div>
        <p className="s-marginBottom0_5 u-colorGray6">Is the same as</p>
        <pre>{fieldWithErrorExample}</pre>
      </div>
    </div>
  );
};

export default BlockFieldSection;
