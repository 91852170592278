import * as React from 'react';
import { useState } from 'react';
import axios from 'axios';
import {Button} from "@react/components";
import {getRailsHeaders} from "@react/utils/network";

const EndVaultPeriod = () => {
    const [assetSymbol, setAssetSymbol] = useState('');
    const [rewardsPeriodId, setRewardsPeriodId] = useState<string>('');
    const [endingBalance, setEndingBalance] = useState(0);
    const [modalMessage, setModalMessage] = useState('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;

        switch (name) {
            case 'assetSymbol':
                setAssetSymbol(value);
                break;
            case 'rewardsPeriodId':
                setRewardsPeriodId(value);
                break;
            case 'endingBalance':
                setEndingBalance(Number(value));
                break;
            default:
                break;
        }
    };

    const closePeriod = async () => {
        setModalMessage("Closing Rewards Period with id " + rewardsPeriodId + "...");
        setIsLoading(true);
        if (rewardsPeriodId) {
            try {
                const response = await axios.post('/admin/stake/vault/post_end_period', {
                    assetSymbol: assetSymbol,
                    rewardsPeriodId: rewardsPeriodId,
                    endingBalance: endingBalance,
                }, {headers: getRailsHeaders()});

                setModalMessage("Job to close rewards period with id " + rewardsPeriodId + " triggered");
                setIsLoading(false);
            } catch (error) {
                setModalMessage("Failed to close the period. Error: " + error.message);
                setIsLoading(false);
            }
        }
    }

    const closeModal = () => {
        setModalMessage('');
        setIsLoading(false);
    }

    return (
        <div>
            <div>
                <label>
                    Asset Symbol:
                    <input type="text" name="assetSymbol" value={assetSymbol} onChange={handleInputChange} />
                </label>
            </div>
            <div>
                <label>
                    Rewards Period Id:
                    <input type="text" name="rewardsPeriodId" value={rewardsPeriodId} onChange={handleInputChange} />
                </label>
            </div>
            <div>
                <label>
                    Ending Balance:
                    <input type="number" name="endingBalance" value={endingBalance} onChange={handleInputChange} />
                </label>
            </div>
            <Button
                loading={isLoading}
                size="small"
                onClick={() => closePeriod()}
            >
                End Period
            </Button>
            <div>
                <br></br>
                <br></br>
            </div>
            {modalMessage && (
                <div className="modal">
                    <p>{modalMessage}</p>
                    <br></br>
                    <Button
                        size="extra-small"
                        onClick={() => closeModal()}
                    >
                        Close Message
                    </Button>
                </div>
            )}
        </div>
    );
}

export default EndVaultPeriod;