import * as React from "react";
import { useState } from "react";

import { axiosRequest, RequestType } from "@react/utils/network";

import { Button } from "@react/components";

import Box from "@react/components/Box";
import Flex from "@react/components/Flex";
import HighlightDialog from "./components/HighlightDialog";

export default function EditHighlights(props) {
  const [selected, setSelected] = useState(props.cards[0]);
  const [open, setOpen] = useState(false);

  const handleClick = (id) => {
    setOpen(false);
    const newCard = props.cards.find((card) => card.id === id);
    setSelected(newCard);
    setOpen(true);
  };

  const handleDelete = async (id) => {
    const response: any = await axiosRequest(
      props.deleteUrl,
      { id: id },
      RequestType.POST
    );
    if (response.error) {
      alert("error");
    } else {
      alert("success, please refresh page");
    }
  };

  return (
    <Flex container spacing={2}>
      {props.cards.map((card) => (
        <Box>
          <Flex
            container
            spacing={2}
            style={{ padding: 40, cursor: "pointer" }}
          >
            <Flex container>{card.project_name}</Flex>
            <Flex container spacing={2}>
              <Button onClick={() => handleDelete(card.id)} variant="gray">
                Delete
              </Button>
              <Button onClick={() => handleClick(card.id)} variant="primary">
                Edit
              </Button>
            </Flex>
          </Flex>
        </Box>
      ))}
      {open && (
        <HighlightDialog
          card={selected}
          createHighlightUrl={props.createHighlightUrl}
          onClose={() => setOpen(false)}
          open={open}
          symbols={props.symbols}
          updateHighlightUrl={props.updateHighlightUrl}
        />
      )}
    </Flex>
  );
}
