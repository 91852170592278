window.Hackathons.Projects.AcceptMembershipInvites =
  show: ($$, $this) ->
    class FormView extends Backbone.View
      events: ->
        'click .js-submit': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass 'c-button--loading'

          $target.addClass 'c-button--loading'
          @$('form').ajaxSubmit
            success: (response) =>
              window.location.href = response.redirect_path
            error: (response) =>
              window.location.href = response.redirect_path

    new FormView el: $this
