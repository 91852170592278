window.Hackathons.Pages =
  render: ($$, $this) ->
    class RenderView extends Backbone.View
      initialize: ->
        # we use await import here
        hljsPromise = import('highlight.js')
        katexPromise = import('katex')
        renderMathInElementPromise = import('katex/dist/contrib/auto-render')

        Promise.all([hljsPromise, katexPromise, renderMathInElementPromise]).then(([hljs, katex, renderMathInElement]) =>
          hljs.default.initHighlightingOnLoad()

          document.addEventListener "DOMContentLoaded", ->
            blocks = $$('.math.display').get()
            blocks.forEach (b) ->
              katex.default.render b.innerText, b, {displayMode:true}
            blocks = $$('.math.inline').get()
            blocks.forEach (b) ->
              katex.default.render b.innerText, b, {displayMode:false}
            renderMathInElement.default $this.get(0)

          new RenderView el: $this
        )
