require('jquery-form')
Partials = require('./lib/partials')

window.Roles = {

  major_shareholders_form: ($$, $this) ->
    class MajorShareholdersFormView extends Backbone.View
      VISIBLE_INPUT_CLASS: 'js-visible_input'
      LOADING_CLASS = 'c-button--loading'

      initialize: () ->
        data = @$('.js-entity_data').data()
        @PERSON = data.person
        @COMPANY = data.company
        @TRUST = data.trust

      events:
        'click .js-save': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass 'c-button--loading'

          $form = $target.closest('form')

          # Check if address is US Based
          is_us_address = false
          $form.find('.js-shareholder_address_field:visible').each ->
            if $$(@).hasClass('js-shareholder_country') && $$(@).val() == 'US'
              is_us_address = true

          # Verify address fields
          address_valid = true
          $form.find('.js-shareholder_address_field:visible').each ->
            $$(@).closest('.c-input-group').removeClass('c-input-group--has-error')

            # Bypass check for state if address is non-US
            bypass = ($$(@).hasClass('js-shareholder_state') && !is_us_address)

            if !bypass && $$(@).val() == ''
              address_valid = false
              $$(@).closest('.c-input-group').addClass('c-input-group--has-error')

          return unless address_valid

          # Only validate SSN / Passport if the address is US Based
          # TODO - Add some kind of validation here if any ID number fields are visible but empty
          if is_us_address
            id_valid = true
            $form.find('.js-new_shareholder_id_number:visible').each ->
              $$(@).closest('.c-input-group').removeClass('c-input-group--has-error')
              if $$(@).val() == ''
                id_valid = false
                $$(@).closest('.c-input-group').addClass('c-input-group--has-error')
            return unless id_valid

          # Submit
          $target.addClass 'c-button--loading'
          $form.ajaxSubmit
            success: (response) =>
              @trigger 'replace', =>
                @$el.replaceWith response.html
              @trigger 'completed'
            error: (response) =>
              if response.status == 422
                @trigger 'replace', =>
                  @$el.replaceWith response.responseJSON.html
              else
                Tooltips.formServerError($target)

        'click .js-add': (event) ->
          event.preventDefault()
          $wrapper = @$('.js-new_shareholder:hidden').first()
          $wrapper.slideDown 'fast'
          $wrapper.find(".#{@VISIBLE_INPUT_CLASS}").val true
          @_toggleAddShareholdersLink()

        'change .js-entity_type': (event) ->
          event.preventDefault()
          $target = $(event.target)
          value = $target.val()
          $shareholderRow = $target.closest('.js-new_shareholder')
          if value == @PERSON
            $shareholderRow.find('.js-company_and_trust_fields').hide()
            $shareholderRow.find('.js-person_fields').show()
          else if value == @COMPANY || value == @TRUST
            $shareholderRow.find('.js-company_and_trust_fields').show()
            $shareholderRow.find('.js-person_fields').hide()

          # hide the errors, since the errors won't make sense for the new entity type
          $shareholderRow.find('.js-errorsHolder').hide()

        'click .js-remove': (event) ->
          event.preventDefault()
          $target = $(event.target)
          $wrapper = $target.closest('.js-new_shareholder')
          $wrapper.find('.js-new_shareholder_name').val('')
          $wrapper.find(".#{@VISIBLE_INPUT_CLASS}").val false
          $wrapper.slideUp 'fast'
          @_toggleAddShareholdersLink()

        'click .js-remove_existing': (event) ->
          event.preventDefault()
          $target = $(event.target)
          targetHtml = $target.html()
          $target.html('Removing...')
          $existingShareholder = $target.closest('.js-existing_shareholder')
          $.post $target.prop('href'),
            _method: 'delete'
            success: =>
              $existingShareholder.slideUp 'fast'
            error: =>
              $target.html(targetHtml)

      _toggleAddShareholdersLink: ->
        # only show "add shareholders" link if there are remaining "new shareholder divs"
        remainingDivs = _.select @$(".#{@VISIBLE_INPUT_CLASS}"), (input) =>
          visible = $(input).val() == 'true'
          !visible
        @$('.js-add').toggle(remainingDivs.length > 0)

    new MajorShareholdersFormView el: $this

  signatory_form: ($$, $this) ->
    class SignatoryFormView extends Backbone.View
      LOADING_CLASS = 'c-button--loading'

      events:

        'click .js-save': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass(@LOADING_CLASS)

          Partials.with Partials.selector('entities/people/identity_data_fields'), (partial) =>
            if partial.validatePhoneNumber()
              partial.formatPhoneNumber()
              $target.addClass(@LOADING_CLASS)
              $target.closest('form').ajaxSubmit
                success: (response) =>
                  @trigger 'replace', =>
                    @$el.replaceWith response.html
                  @trigger 'completed'
                error: (response) =>
                  if response.status == 422
                    @trigger 'replace', =>
                      @$el.replaceWith response.responseJSON.html
                  else
                    Tooltips.formServerError($target)

    new SignatoryFormView el: $this
}
