import * as React from "react";
import { Button, CircularProgress } from "@mui/material";

export interface ProgressButtonProps {
  children?: React.ReactNode;
  loading: boolean;
  className?: string;
  onClick: () => void;
}

const ProgressButton: React.FC<ProgressButtonProps> = ({
  loading,
  children,
  onClick,
  className,
}: ProgressButtonProps) => {
  return (
    <Button className={className} onClick={onClick}>
      {loading ? <CircularProgress /> : children}
    </Button>
  );
};

export default ProgressButton;
