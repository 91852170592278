Accounting = require('accounting')
Chart = require('chart.js')
Partials = require('../lib/partials')
ChartJsPresets = require('../lib/chart_js_presets')

class SharedUsdPrices extends Backbone.View
  FLASH_FADE_OUT: 2000,

  initialize: ->
    @_bindIndicativePricingWebSocket()
    @USD_ASSET_SYMBOL = @$el.data('usdAssetSymbol')
    @AMOUNT_FLASH_GREEN_CLASS = @$el.data('amountFlashGreenClass')
    @AMOUNT_FLASH_RED_CLASS = @$el.data('amountFlashRedClass')
    @current_timeframe = @$el.data('currentTimeframe')

    if @$('.js-chart').length > 0
      @_initializeChart()
      @_updateChartSelector()

  _updateChartSelector: ->
    @$('.js-chart_link').removeClass('current')
    @$(".js-#{@current_timeframe}_link").addClass('current')
    @$('.js-price_chart').hide()
    @$(".js-#{@current_timeframe}_chart").show()
    @$('.js-percentage_change').hide()
    @$(".js-#{@current_timeframe}_percentage_change").show()


  _initializeChart: ->
    $charts = @$('.js-chart')

    _.each $charts, (chart) =>
      $chart = $(chart)

      # Get default options
      chartOptions = ChartJsPresets.minimalLineOptions()

      # make line green or red
      if parseFloat($chart.data('midpricePercentageChangeWithinLastDay')) > 0
        chartOptions.elements.line.borderColor = '#63DA68'
      else
        chartOptions.elements.line.borderColor = '#FF3D00'

      new Chart chart.getContext('2d'),
                type: 'line'
                data: $chart.data('chartData')
                options: chartOptions

  _bindIndicativePricingWebSocket: ->
    Partials.with @$(Partials.selector 'web_sockets/rfq/indicative_pricing'), (partial) =>
      partial.on 'update', (message) =>
        @_renderPrices(message)

  # Re-render USD price on socket update
  _renderPrices: (indicativePricesHash) ->
    _.map indicativePricesHash, (indicativePrice, assetSymbol) =>
      return unless indicativePrice.USD?
      @_renderPercent(indicativePrice, assetSymbol)
      @_renderUsdPrice(indicativePrice, assetSymbol)

  # Update the percent change given by the web socket
  _renderPercent: (indicativePrice, assetSymbol) ->
    $percent = $(".js-usd-pair-price[data-asset-symbol='#{assetSymbol}'] .js-percent")
    return unless $percent.length > 0

    midpricePercentageChangeWithinLastDay =
        parseFloat(indicativePrice.USD.midprice_percentage_change_within_last_day).toFixed(2)

    if midpricePercentageChangeWithinLastDay >= 0
      $percent.removeClass('u-colorRed').addClass('u-colorGreen')
      midpricePercentageChangeWithinLastDayForDisplay = "+#{midpricePercentageChangeWithinLastDay}%"
    else
      $percent.removeClass('u-colorGreen').addClass('u-colorRed')
      midpricePercentageChangeWithinLastDayForDisplay = "#{midpricePercentageChangeWithinLastDay}%"

    $percent.text(midpricePercentageChangeWithinLastDayForDisplay)

  # Update the USD prices given by the web socket
  _renderUsdPrice: (indicativePrice, assetSymbol) ->
    $amount = $(".js-usd-pair-price[data-asset-symbol='#{assetSymbol}'] .js-amount")
    return unless $amount.length > 0

    assetAmountInUsdWithInfinitePrecision =
      indicativePrice.USD.asset_amount_in_usd_with_infinite_precision

    # Deformat the strings for easier comparison
    originalAmount = Accounting.unformat($.trim($amount.text()))
    updatedAmount = Accounting.unformat(assetAmountInUsdWithInfinitePrecision)

    # Return early if no change
    return if originalAmount == updatedAmount

    # Flash red/green depending on value change
    flashClass =
      if updatedAmount > originalAmount
        @AMOUNT_FLASH_GREEN_CLASS
      else
        @AMOUNT_FLASH_RED_CLASS

    # Add class & Update amount
    $amount.addClass(flashClass).text(assetAmountInUsdWithInfinitePrecision)

    # Remove class
    setTimeout(->
      $amount.removeClass(flashClass)
    , @FLASH_FADE_OUT)

  events:
    'click .js-chart_link' : (e) ->
      e.preventDefault()
      $target = $(e.currentTarget)
      @current_timeframe = $target.data('timeframe')
      @_updateChartSelector()

    'click .js-buy_button' : (e) ->
      e.preventDefault()
      $target = $(e.currentTarget)
      buyUrl = $target.data('buyUrl')
      window.location.href = buyUrl

    'click .js-usd-pair-price' : (e) ->
      # Update trade order form if present / else redirect to trade page
      $orderForm = $(Partials.selector('quotable_integration/requests_for_quote/order_form'))
      if $orderForm.length > 0
        e.preventDefault()
        baseAssetSymbol = @$(e.currentTarget).data('assetSymbol')
        Partials.with $(Partials.selector('quotable_integration/requests_for_quote/order_form')), (partial) =>
          unless partial.baseSelectize.getValue() == baseAssetSymbol &&
                 partial.counterSelectize.getValue() == @USD_ASSET_SYMBOL
            partial.baseSelectize.setValue(baseAssetSymbol)
            partial.counterSelectize.setValue(@USD_ASSET_SYMBOL)

class SharedUsdPricesLarge extends SharedUsdPrices
  _initializeChart: ->
    $charts = @$('.js-chart')

    _.each $charts, (chart) =>
      $chart = $(chart)
      chartData = $chart.data('chartData')

      # Get default options
      chartOptions = ChartJsPresets.minimalLineOptions()

      # style mods
      extraOptions =
        elements:
          line:
            borderColor: '#000'
            tension: 0
            backgroundColor: '#FAFAFA'
          point:
            style: 'circle'
            radius: 3
            borderWidth: 2
            backgroundColor: '#000'
            borderColor: '#FFFFFF'
            hoverRadius: 3
            hoverBorderWidth: 2
            hoverBackgroundColor: '#000'
            hoverBorderColor: '#FFFFFF'
        tooltips:
          enabled: true
          mode: 'nearest'
          intersect: false
          displayColors: false
          titleFontStyle: 'normal'
          titleAlign: 'center'
          bodyFontSize: 16
          bodyFontStyle: 'bold'
          bodyAlign: 'center'
          xPadding: 10
          yPadding: 8
          titleFontFamily: 'Akkurat, Roboto, sans-serif'
          bodyFontFamily: 'Akkurat, Roboto, sans-serif'
          callbacks:
            label: (tooltipItem) =>
              decimals = if (tooltipItem.yLabel < 1) then 4 else 2
              amount = tooltipItem.yLabel.toFixed(decimals)
              "$#{amount}"

      chartOptions = Object.assign(chartOptions, extraOptions)

      new Chart chart.getContext('2d'),
        type: 'line'
        data: chartData
        options: chartOptions

class SharedUsdPricesLargePlain extends SharedUsdPrices
  _initializeChart: ->
    $charts = @$('.js-chart')

    _.each $charts, (chart) =>
      $chart = $(chart)
      chartData = $chart.data('chartData')

      # Get default options
      chartOptions = ChartJsPresets.minimalLineOptions()

      # style mods
      extraOptions =
        elements:
          line:
            borderColor: '#000'
            tension: 0
            backgroundColor: '#FAFAFA'
          point:
            style: 'circle'
            radius: 0
            borderWidth: 0
            backgroundColor: 'rgba(0,0,0,0)'
            borderColor: 'rgba(0,0,0,0)'
            hoverRadius: 0
            hoverBorderWidth: 0
            hoverBackgroundColor: 'rgba(0,0,0,0)'
            hoverBorderColor: 'rgba(0,0,0,0)'
        tooltips:
          enabled: true
          mode: 'nearest'
          intersect: false
          displayColors: false
          titleFontStyle: 'normal'
          titleAlign: 'center'
          bodyFontSize: 16
          bodyFontStyle: 'bold'
          bodyAlign: 'center'
          xPadding: 10
          yPadding: 8
          titleFontFamily: 'Akkurat, Roboto, sans-serif'
          bodyFontFamily: 'Akkurat, Roboto, sans-serif'
          callbacks:
            label: (tooltipItem) =>
              decimals = if (tooltipItem.yLabel < 1) then 4 else 2
              amount = tooltipItem.yLabel.toFixed(decimals)
              "$#{amount}"

      chartOptions = Object.assign(chartOptions, extraOptions)

      new Chart chart.getContext('2d'),
        type: 'line'
        data: chartData
        options: chartOptions


class SharedLoadingUsdPrices extends Backbone.View
  initialize: ->
    $.ajax
      url: @$('.js-ajax_load').data('url'),
      method: 'GET',
      success: (response) =>
        @$('.js-ajax_load').replaceWith(response.html)

window.QuotableIntegration.IndicativePrices =
  usd_prices: ($$, $this) ->
    new SharedUsdPrices el: $this

  usd_prices_loading: ($$, $this) ->
    new SharedLoadingUsdPrices el: $this

  usd_prices_single: ($$, $this) ->
    new SharedUsdPricesLarge el: $this

  usd_prices_single_loading: ($$, $this) ->
    new SharedLoadingUsdPrices el: $this

  usd_prices_wallet: ($$, $this) ->
    new SharedUsdPricesLargePlain el: $this

  usd_prices_wallet_loading: ($$, $this) ->
    new SharedLoadingUsdPrices el: $this

  usd_prices_portfolio: ($$, $this) ->
    new SharedUsdPricesLargePlain el: $this

  usd_prices_portfolio_loading: ($$, $this) ->
    new SharedLoadingUsdPrices el: $this

  usd_prices_columns: ($$, $this) ->
    new SharedUsdPrices el: $this

  usd_prices_columns_loading: ($$, $this) ->
    new SharedLoadingUsdPrices el: $this

  usd_prices_columns_homepage: ($$, $this) ->
    new SharedUsdPrices el: $this

  usd_prices_columns_homepage_loading: ($$, $this) ->
    new SharedLoadingUsdPrices el: $this

  usd_prices_pro: ($$, $this) ->
    new SharedUsdPrices el: $this

  usd_prices_pro_loading: ($$, $this) ->
    new SharedLoadingUsdPrices el: $this
