import * as React from "react";
import { useState } from "react";
import { ThemeProvider, Theme, StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import Warning from "@react/components/warnings/Warning";
import CollateralFormDialog from "../../../admin/lend/placed_loans/components/CollateralFormDialog";
import { Button, Grid, Col } from "@react/components";

import { theme } from "../../../../utils/theme";
import { generatePlacedLoanWarnings } from "@react/components/warnings";


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


export default function LoansWarnings(props) {
  const [open, setOpen] = useState(false);
  const [collateralProps, setCollateralProps] = useState(null);

  const placedLoans = props.placedLoans;

  const warnings = generatePlacedLoanWarnings(
    placedLoans,
    props.priceMap,
    props.collateralBalanceMap,
    setCollateralProps,
    setOpen
  );

  const renderWarnings = () => {
    return warnings.map((warning, index) => (
      <div className="s-marginBottom1" key={index}>
        <Grid>
          <Col xs="24">
            <Warning
              button={
                <span className="u-floatRight">
                  <Button
                    variant={warning.buttonVariant}
                    onClick={warning.onClick}
                  >
                    {warning.buttonText}
                  </Button>
                </span>
              }
              color={warning.backgroundColor}
              warningText={warning.warningText}
            />
          </Col>
        </Grid>
      </div>
    ));
  };
  /**
   * TODO ARJUN - not clear what props are being passed here
   */
  return (
    <React.Fragment>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {renderWarnings()}
          {open && (
            <CollateralFormDialog
              {...collateralProps}
              handleClose={() => setOpen(false)}
              open={open}
            />
          )}
        </ThemeProvider>
      </StyledEngineProvider>
    </React.Fragment>
  );
}
