YouTubePlayer = require('youtube-player')

window.Rewards.Quizzes =
  show: ($$, $this) ->
    class ShowView extends Backbone.View
    new ShowView el: $this

window.Rewards.Quizzes.Custom =
  orchid_entry_banner: ($$, $this) ->
    class OrchidEntryBannerView extends Backbone.View
      events:
        'click .js_quiz-button': (event) ->
          $target = $(event.target)
          Analytics.trackEvent('OXT Rewards Quiz Button Clicked')
          return unless $target.data 'ineligibleForRewards'

          event.preventDefault()
          $target.addClass 'c-button--disabled'
          $('.js_ineligible-message').slideDown()

    new OrchidEntryBannerView el: $this

  tezos_entry_banner: ($$, $this) ->
    class TezosEntryBannerView extends Backbone.View
      events:
        'click .js_quiz-button': (event) ->

          $target = $(event.target)
          $ineligible_message = $('.js_ineligible-message')

          # TODO ED - Add analytics for button click
          return unless $target.data('loggedIn') && $target.data('ineligibleForRewards')

          event.preventDefault()
          $target.addClass 'c-button--disabled'
          $ineligible_message.removeClass('u-hidden')
          $ineligible_message.slideDown()

    new TezosEntryBannerView el: $this
