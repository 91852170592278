window.Index.SolanaFaq =
  faq_section: ($$, $this) =>
    class AboutView extends Backbone.View
      events:
        'click .js-faq-question': (event) ->
          event.preventDefault()
          $target = @$(event.currentTarget)
          $target.next('.js-faq-answer').slideToggle()
          $target.closest('.js-faq').toggleClass('index-solana_faq-faq_section__question--open')

    new AboutView el: $this