import * as React from "react";
import {
  Form,
  Button,
  TextInput,
  InlineLabel,
  Checkbox,
  Spacer,
} from "@react/components";
import BlockField from "@react/views/shared/forms/BlockField/BlockField";
interface FormSectionProps {
  // props
}

const FormSection: React.FunctionComponent<FormSectionProps> = (
  {
    // props
  }
) => {
  const formCodeExample = `import { Form, Button, TextInput, InlineLabel, Checkbox, Spacer } from "@react/components";

<Form onSubmit={() => alert("onSubmit")}>
  <BlockField label="Email">
    <TextInput placeholder="jon@doe.com"/>
  </BlockField>
  <Spacer/>
  <BlockField label="Password">
    <TextInput placeholder="*****" type="password"/>
  </BlockField>
  <Spacer/>
  <BlockField>
    <InlineLabel>
      <Checkbox /> Remember me
    </InlineLabel>
  </BlockField>
  <Spacer/>
  <Button fullWidth>Submit</Button>
</Form>`;

  return (
    <div>
      <div className="s-marginBottom2">
        <h2 className="s-fontSize26 s-borderBottom0_5 s-marginBottom1 u-colorGray2">
          Form
        </h2>
        <p className="s-marginBottom0_5 u-colorGray6">
          Implementation of the{" "}
          <code className="c-code c-code--inline">{"<form>"}</code> component
        </p>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          Default use
        </h3>
        <p className="s-marginBottom0_5 u-colorGray6">
          <code className="c-code c-code--inline">{"<Form>"}</code> is used to
          wrap a series of{" "}
          <code className="c-code c-code--inline">{"<Field>"}</code>,{" "}
          <code className="c-code c-code--inline">{"<input>"}</code>, etc..
        </p>
        <pre>
          <Form onSubmit={() => alert("onSubmit")}>
            <BlockField label="Email">
              <TextInput placeholder="jon@doe.com" />
            </BlockField>
            <Spacer />
            <BlockField label="Password">
              <TextInput placeholder="*****" type="password" />
            </BlockField>
            <Spacer />
            <BlockField>
              <InlineLabel>
                <Checkbox /> Remember me
              </InlineLabel>
            </BlockField>
            <Spacer />
            <Button fullWidth>Submit</Button>
          </Form>
        </pre>
      </div>
    </div>
  );
};

export default FormSection;
