import * as React from "react";
import { useState } from "react";
import { Button, ButtonProps, Icon } from "@react/components";
import {
  WalletLinkInput,
  WalletLinkModal,
  WalletLinkProvider,
} from "@coinlist/wallet-link";
import { walletLinkApi } from "./api";
import { Entity, LinkWalletData, WalletLinkEntryPoint } from "./types";
import { generateWebsiteSource } from "./utils";
import { SegmentReact } from "@react/analytics/segment_react";
require("@coinlist/wallet-link/dist/style.css");

const WalletLink: React.FC<{
  entity: Entity;
  entryPoint: WalletLinkEntryPoint;
  settingsLink?: string;
  offeringSlug?: string;
  onSuccess?: (entityId: string) => void;
  buttonText?: string;
  customButton?: React.ReactNode;
}> = ({
  entity,
  entryPoint,
  settingsLink,
  offeringSlug,
  onSuccess,
  buttonText,
  customButton,
}) => {
  const [isLinking, setIsLinking] = useState(false);
  const [linkingError, setLinkingError] = useState<Error | undefined>(
    undefined
  );
  const [walletLinked, setWalletLinked] = useState(false);

  const handleLinkWallet = (data: WalletLinkInput) => {
    if (!entity) return;

    setIsLinking(true);
    setLinkingError(undefined);

    const walletLinkData: LinkWalletData = {
      ...data,
      entityId: entity.id,
      websiteSource: generateWebsiteSource(entryPoint, offeringSlug),
    };

    walletLinkApi()
      .linkWallet(walletLinkData)
      .then((res) => {
        if (res.createLinkedWallet.success) {
          setWalletLinked(true);
          if (onSuccess) {
            onSuccess(entity.id);
          }
        } else {
          setLinkingError(res.createLinkedWallet.errors[0]);
        }
      })
      .catch((err) => {
        console.error(err);
        if (err?.response?.errors[0]) {
          setLinkingError(err?.response?.errors[0]);
        } else {
          // This gets rid of the insanely long error log we get back grom graphql
          err.message = "There was an error connecting your wallet.";
          setLinkingError(err);
        }
      })
      .finally(() => {
        setIsLinking(false);
      });
  };

  const handleCloseModal = () => {
    setWalletLinked(false);
    setLinkingError(undefined);
  };

  const fullName = entity?.name ?? "";
  const todayDate = new Date().toLocaleDateString();
  const customButtonProps: ButtonProps = {
    variant:
      [WalletLinkEntryPoint.DASHBOARD, WalletLinkEntryPoint.OFFERING].includes(entryPoint) ? "secondary" : "primary",
    fullWidth: entryPoint === WalletLinkEntryPoint.DASHBOARD ? true : false,
    size: [WalletLinkEntryPoint.ACCOUNT, WalletLinkEntryPoint.OFFERING].includes(entryPoint) ? "small" : "medium",
    style: {
      width: entryPoint === WalletLinkEntryPoint.ACCOUNT ? "26rem" : undefined,
    },
    children: (
      <>
        {/*TODO DILLON: Do we want to remove this icon? or make it default with prop?*/}
        <Icon icon="wallet" />
        {buttonText || "Link Non-Custodial Wallet"}
      </>
    ),
  };

  return (
    <WalletLinkProvider>
      <WalletLinkModal
        message={`I confirm that I'm the sole owner of the cryptocurrency address associated with this message as of ${todayDate}.`}
        isLinking={isLinking}
        isWalletLinked={walletLinked}
        onLinkWallet={handleLinkWallet}
        settingsLink={settingsLink}
        errorMsg={linkingError?.message || undefined}
        customInitButton={customButton || <Button {...customButtonProps} />}
        eventTracker={SegmentReact}
        onClose={() => {}}
      />
    </WalletLinkProvider>
  );
};

export default WalletLink;
