import * as React from "react";
import {
  Form,
  Button,
  TextInput,
  InlineLabel,
  Checkbox,
  Spacer,
} from "@react/components";
import { Spacings, SpacingsType } from "@react/types/ui-component";

import BlockField from "@react/views/shared/forms/BlockField/BlockField";
interface SpacerSectionProps {
  // props
}

const SpacerSection: React.FunctionComponent<SpacerSectionProps> = (
  {
    // props
  }
) => {
  const spacerCodeExample = `<p>A Line of text</p>
<Spacer />
<p>Another Line of text</p>
<Spacer spacing={2} />
<p>Yet another Line of text</p>
<Spacer spacing={8} />
<p>Last line of text</p>`;

  return (
    <div>
      <div className="s-marginBottom2">
        <h2 className="s-fontSize26 s-borderBottom0_5 s-marginBottom1 u-colorGray2">
          Spacer
        </h2>
        <p className="s-marginBottom0_5 u-colorGray6">
          A component to help us with vertical spacing.
        </p>
        <p className="s-marginBottom0_5 u-colorGray6">
          Spacer takes one argument,{" "}
          <code className="c-code c-code--inline">spacing</code>, which goes
          from <code className="c-code c-code--inline">1</code> to{" "}
          <code className="c-code c-code--inline">16</code> in increment of{" "}
          <code className="c-code c-code--inline">0.5</code>
        </p>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          Default use
        </h3>
        <pre>
          <p>A Line of text</p>
          <Spacer />
          <p>Another Line of text</p>
          <Spacer spacing={2} />
          <p>Yet another Line of text</p>
          <Spacer spacing={8} />
          <p>Last line of text</p>
        </pre>
      </div>
    </div>
  );
};

export default SpacerSection;
