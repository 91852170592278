import * as React from "react";
import { useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import Dialog from "@mui/material/Dialog";
import Snackbar from "@mui/material/Snackbar";

import Box from "@react/components/Box";
import { Button } from "@react/components";
import Confirmation from "@react/components/Confirmation";
import Flex from "@react/components/Flex";
import { TextInput } from "@react/components";
import BlockField from "@react/views/shared/forms/BlockField/BlockField";
import { Link, Divider } from "@react/components";
import Loading from "@react/components/Loading";
import Typography from "@react/components/typography/Typography";
import Warning from "@react/components/warnings/Warning";

import { axiosRequest, RequestType } from "@react/utils/network";
import { hasFullSidebarButSmall } from "@react/utils";

const CONTAINER_PADDING = 20;

const useStyles = makeStyles(() => ({
  dialogPaper: {
    height: 420,
    maxWidth: 615,
    width: "100%",
  },
  dialogPaperXs: {
    height: 650,
    maxWidth: 600,
    width: "100%",
  },
}));

export default function InfoBox(props) {
  const fullSideBarButSmall = hasFullSidebarButSmall();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  const [response, setResponse] = useState(null);
  const [formOpen, setFormOpen] = useState(false);

  const renderSnackbar = () => {
    return (
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        autoHideDuration={3000}
        open={open}
        onClose={() => setOpen(false)}
      >
        <Warning
          color={response && response.status === 200 ? "green" : "orange"}
          fullWidth
          fontSize={13}
          padding={8}
          variant={response && response.status === 200 ? "success" : "warning"}
          warningText={
            response
              ? response && response.status === 200
                ? props.successMessage
                : "Error joining waitlist, please try again."
              : ""
          }
        />
      </Snackbar>
    );
  };

  return (
    <Box
      onClick={props.onClick}
      style={{
        cursor: "pointer",
        marginBottom: 16,
        padding: 16,
        width: "100%",
      }}
    >
      <Flex alignItems="center" container>
        <Flex item xs={16}>
          <Flex container spacing={1}>
            <Flex item xs={24}>
              <Typography bold>{props.title}</Typography>
            </Flex>
            <Flex item xs={24}>
              <Typography bold color="gray" fontSize={13}>
                {props.mainContent}
              </Typography>
            </Flex>
            <Flex item xs={24}>
              {props.onWaitlist || (response && response.status === 200) ? (
                <div>
                  <div>
                    <i
                      className={`icon-ok s-fontSize13 u-colorGreen`}
                      style={{ display: "inline", paddingRight: 4 }}
                    ></i>
                    <div
                      className={`s-fontSize13 u-colorGreen`}
                      style={{ display: "inline" }}
                    >
                      {props.onWaitlist ? "Joined waitlist" : "Success"}
                    </div>
                  </div>
                </div>
              ) : (
                <Link
                  fontWeight={700}
                  fontSize={13}
                  onClick={async () => {
                    if (props.loggedInUser) {
                      const response: any = await axiosRequest(
                        props.joinWaitlistUrl,
                        {
                          category: props.category,
                        },
                        RequestType.POST
                      );
                      setResponse(response);
                      setOpen(true);
                    } else {
                      setFormOpen(true);
                    }
                  }}
                >
                  Join the waitlist
                </Link>
              )}
            </Flex>
          </Flex>
        </Flex>
        {!fullSideBarButSmall && props.loggedInUser && (
          <Flex item justifyContent="flex-end" xs={8}>
            {props.image}
          </Flex>
        )}
      </Flex>
      <Dialog
        classes={{
          paper: classes.dialogPaper,
        }}
        fullWidth
        onClose={() => setFormOpen(false)}
        open={formOpen}
      >
        <Flex container style={{ padding: CONTAINER_PADDING }} spacing={1}>
          <Flex item xs={24}>
            <Typography type="h6">Join Waitlist</Typography>
          </Flex>
          <Flex item xs={24}>
            <Divider spacingTop={0} spacingBottom={0} />
          </Flex>
          {response && response.status === 200 ? (
            <Confirmation
              mainText="Thanks for joining the waitlist."
              onClose={() => setFormOpen(false)}
              subText={props.confirmationSubText}
            />
          ) : (
            <Flex container spacing={2} style={{ paddingTop: 8 }}>
              <Flex item xs={24}>
                <BlockField label="Email" name="email">
                  <TextInput
                    value={email}
                    name="email"
                    onChange={(e) => setEmail(e.currentTarget.value)}
                  />
                </BlockField>
              </Flex>
              <Flex
                item
                justifyContent="flex-end"
                style={{ position: "absolute", bottom: 16, right: 16 }}
                xs={24}
              >
                <Button onClick={() => setLoading(true)}>
                  {loading ? (
                    <Loading
                      handleResponse={(response) => {
                        setResponse(response);
                        setLoading(false);
                        setOpen(response.status !== 200);
                      }}
                      loading={loading}
                      requestData={{
                        data: {
                          waitlist_request: {
                            category: props.category,
                            email,
                          },
                        },
                        type: RequestType.POST,
                        url: props.joinWaitlistUrl,
                      }}
                      spinnerColor="white"
                    />
                  ) : (
                    "Save"
                  )}
                </Button>
              </Flex>
            </Flex>
          )}
        </Flex>
        {renderSnackbar()}
      </Dialog>
      {!formOpen && renderSnackbar()}
    </Box>
  );
}
