Partials = require('../../../lib/partials')
require('@selectize/selectize')

window.Market.Entities.Wallets.Sidebar =
  wallets_input: ($$, $this) ->
    class WalletsInput extends Backbone.View

      initialize: ->
        @assetsData = @$el.data('assetsData')
        @$('.js-wallet-select-input').selectize(SelectizePreset.renderHtml(@assetsData))

      events:
        'change .js-wallet-select-input' : (e) ->
          e.preventDefault()
          window.location.pathname = @assetsData[e.target.value].url

    new WalletsInput el: $this

  wallet_link: ($$, $this) ->
    class WalletLink extends Backbone.View

      initialize: ->
        @symbol = @$('.js-wallet').data('symbol')

      handleSearch: (searchStr) ->
        if !searchStr || @symbol.toUpperCase().includes(searchStr.toUpperCase())
          @$('.js-wallet').show()
        else
          @$('.js-wallet').hide()

    new WalletLink el: $this

  search_input: ($$, $this)->
    class SearchView extends Backbone.View
      search: (searchStr) ->
        Partials.with Partials.selector('market/entities/wallets/sidebar/wallet_link'), (partial) =>
          partial.handleSearch(searchStr)

      events:
        'input .js-search_input': (event) ->
          $target = $(event.target)
          @search($target.val())

        'keydown .js-search_input': (event) ->
          if event.keyCode == Keyboard.enter
            event.preventDefault()
            return false

    new SearchView el: $this
