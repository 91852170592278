window.Centrifuge = {}
window.Centrifuge.DistributionAddresses =
  new: ($$, $this) ->
    class NewFormView extends Backbone.View
      events:
        'change .js-wcfg_radio': (event) ->
          $target = $(event.target)
          if $target.val() == 'true'
            $$('.js-no_wcfg').hide()
            $$('.js-wcfg').show()
          else
            $$('.js-no_wcfg').show()
            $$('.js-wcfg').hide()

    new NewFormView el: $this
