Plyr = require('plyr')

window.Index.DealPageIndex =
  header: ($$, $this) =>
    class HeaderView extends Backbone.View
      initialize: ->
        if @$('.js-video').length
          @player = new Plyr('.js-video', PlyrPresets.default())

      events:
        'click .js-offering_header_show_form': (event) ->
          @$('.js-form_wrapper').removeClass('u-hidden')
          @$('.js-content_wrapper').addClass('u-hidden')

        'click .js-offering_header_show_content': (event) ->
          @$('.js-form_wrapper').addClass('u-hidden')
          @$('.js-content_wrapper').removeClass('u-hidden')

        'submit .js-offering-form': (event) ->
          event.preventDefault()
          event.stopPropagation()
          $form = $(event.target)
          $button = $form.find('.js-offering-form-button')

          return if $button.hasClass('c-button--loading')
          $button.addClass('c-button--loading')

          $form.ajaxSubmit
            success: (response) =>
              @$el.replaceWith(response.html)
            error: (response) =>
              @$el.replaceWith(response.responseJSON.html)

    new HeaderView el: $this
