import * as Castle from "@castleio/castle-js"

class FormView extends Backbone.View
  handleSuccessResponse: (response) ->
    $html = $(response.html)
    $success = $html.find('.js-success')
    @$el.replaceWith $html
    $success.removeClass('u-hidden').animate {opacity: 0}, 2000, =>
      $success.hide()

  handleErrorResponse: (response, $button) ->
    if response.status == 422
      error = response.responseJSON.errors
      if error
        @$('.js-mfa_error').text(error.message).slideDown()
        $button.removeClass 'c-button--loading'
      else
        @$el.replaceWith response.responseJSON.html
    else
      Tooltips.formServerError($button)

  events:
    'submit form': (event) ->
      event.preventDefault()
      if castleData?.castlePublishKey
        Castle.createRequestToken().then (token) =>
          $('.castle_request_token').val(token)
      $form = $(event.target)
      $button = @$('.js-cta')
      return if $button.hasClass 'c-button--loading'
      $button.addClass 'c-button--loading'
      $form.ajaxSubmit
        success: (response) =>
          @handleSuccessResponse(response)
        error: (response) =>
          @handleErrorResponse(response, $button)

window.Market.Accounts.Basics =
  email_form: ($$, $this) ->
    new FormView el: $this

  password_form: ($$, $this) ->
    new FormView el: $this

  profile_form: ($$, $this) ->
    new FormView el: $this
