window.Admin = {
  style: ($$, $this) ->
    class StyleView extends Backbone.View
      initialize: ->
        @initalizeStickySidebar()
        @$(".js-font_size_16").show()

      events:
        'change .js-font_size_range': (event) ->
          $target = $(event.currentTarget)
          value = $target.val()
          @$(".js-font_size").hide()
          @$(".js-font_size_#{value}").show()

        'click .js-navigation_link': (event) ->
          $target = $(event.currentTarget)
          @openNavigationLink($target)

      openNavigationLink: ($target) ->
        $subNavigation = $target.next('.js-sub_navigation')
        if $target.hasClass('c-nav__item')
          @$('.js-navigation_link').removeClass('c-nav__item--active')
          @$('.js-sub_navigation').hide()
          $target.addClass('c-nav__item--active')
        if $subNavigation.length
          $target.next('.js-sub_navigation').show()

      initalizeStickySidebar: ->
        sidebar = @$('.js-navigation')
        sidebarStickyClass = 'admin-style__nav--sticky'
        content = @$('.js-content')
        sections = @$('.js-section')
        $window = $(window)

        $(document).ready =>
          contentTopOffset = content.offset().top
          sidebarBottom = sidebar.height() + sidebar.position().top
          contentBottom = content.height() + content.position().top - sidebarBottom

          $window.on 'scroll.sidebar', =>
            stickySidebar = $window.scrollTop() >= contentTopOffset &&
                            $window.scrollTop() <= contentBottom
            sidebar.toggleClass(sidebarStickyClass, stickySidebar)
          $(window).trigger('scroll.sidebar')

          $window.on 'scroll.navigation', =>
            currentPosition = $window.scrollTop()
            sections.each (index, section) =>
              $section = $(section)
              top = $section.offset().top
              bottom = top + $section.outerHeight()
              sectionId = $section.attr('id')
              if currentPosition >= top and currentPosition <= bottom
                @openNavigationLink(@$(".js-navigation_link[href='##{sectionId}']"))
          $(window).trigger('scroll.navigation')

    new StyleView el: $this
}