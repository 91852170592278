import * as React from "react";
import WalletLink from "@react/views/walletLink/WalletLink";
import {
  Entity,
  LinkedWallet,
  WalletLinkEntryPoint,
} from "@react/views/walletLink/types";
import { useState } from "react";
import { walletLinkApi } from "@react/views/walletLink/api";
import { Button, Icon } from "@react/components";

import makeStyles from "@mui/styles/makeStyles";
import cx from "classnames";
import { axiosRequest, RequestType } from "@react/utils/network";
import WalletModal from "./WalletLinkStepApproveModal";

const WalletLinkStep: React.FC<{
  entity: Entity;
  offeringSlug: string;
  offeringsParticipantId: string;
  selectLinkedWalletUrl: string;
  nextOfferingStepUrl: string;
  manageWalletsUrl: string;
  linkedWalletsJson: LinkedWallet[];
  issuerName: string;
  isOptional: boolean;
}> = ({
  entity,
  offeringSlug,
  offeringsParticipantId,
  selectLinkedWalletUrl,
  nextOfferingStepUrl,
  manageWalletsUrl,
  linkedWalletsJson,
  issuerName,
  isOptional,
}) => {
  const classes = useStyles();
  const [linkedWallets, setLinkedWallets] = useState<LinkedWallet[]>(
    linkedWalletsJson
  );
  const [selectedWallet, setSelectedWallet] = useState<LinkedWallet | undefined>(
    linkedWallets.length ? linkedWallets[0] : undefined
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isApprovalModalOpen, setIsApprovalModalOpen] = useState(false);

  const submitSelectedWallet = async (walletId: string) => {
    const data = {
      linked_wallet: {
        offerings_participant_id: offeringsParticipantId,
        wallet_id: walletId,
      },
    };

    try {
      await axiosRequest(selectLinkedWalletUrl, data, RequestType.POST);
      window.location.assign(nextOfferingStepUrl);
    } catch (error) {
      // TODO: right now this isn't being hit because the error is caught in the axiosRequest function :/,
      // try the redirect in the try
      alert(`${error.message}`);
    }
  };

  const redirectToManageWalletsUrl = () => {
    window.location.assign(manageWalletsUrl);
  };

  const displayLinkedWalletAddresses = (entityId: string) => {
    setIsLoading(true);

    walletLinkApi()
      .getLinkedWalletsByEntity(entityId)
      .then((res) => {
        setLinkedWallets(res.linkedWallets);
        if (res.linkedWallets.length) {
          setSelectedWallet(res.linkedWallets[0]);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const toggleSetSelectedWallet = (wallet: LinkedWallet) => {
    if (!selectedWallet || selectedWallet.id !== wallet.id) {
      setSelectedWallet(wallet);
    } else { // if the user clicks on the already selected wallet, unselect it
      setSelectedWallet(undefined);
    }
  };

  const LinkedWalletListItem: React.FC<{ linkedWallet: LinkedWallet }> = ({
    linkedWallet,
  }) => {
    // change epoch to Mon, DD, YYYY
    const updatedAtDate = new Date((linkedWallet.updatedAt as number) * 1000);
    const formattedUpdatedAt = `${updatedAtDate.toLocaleDateString("default", {
      month: "short",
    })} ${updatedAtDate.getDate()}, ${updatedAtDate.getFullYear()}`;
    const conditionalSelectedClass =
      selectedWallet === linkedWallet ? "selected" : "";
    return (
      <li
        className={`u-displayFlex u-alignItemsCenter s-marginBottom0_5 ${cx(
          classes.linkedAddressRow
        )} ${conditionalSelectedClass}`}
        onClick={() => toggleSetSelectedWallet(linkedWallet)}
      >
        <div
          className={`u-displayFlex u-justifyContentCenter u-alignItemsCenter ${cx(
            classes.radioColumn
          )}`}
        >
          <div
            className={`wallet-radio-fill ${cx(
              classes.radioFill
            )} ${conditionalSelectedClass}`}
          />
        </div>
        <div className={cx(classes.linkedWalletBox)}>
          <div className={`s-fontSize16 ${cx(classes.walletAddress)}`}>
            {linkedWallet.address}
          </div>
          <div className={`s-fontSize12 u-colorGray9`}>
            Linked: {formattedUpdatedAt}
          </div>
        </div>
      </li>
    );
  };

  const LinkedAddressesRows: React.FC = () => (
    <ul id="linked-wallets-list" className={cx(classes.linkedWalletsList)}>
      {linkedWallets.map((linkedWallet) => (
        <LinkedWalletListItem
          linkedWallet={linkedWallet}
          key={linkedWallet.id}
        />
      ))}
    </ul>
  );

  const AddAWalletButton: React.FC<{ variant: "primary" | "secondary" }> = ({
    variant,
  }) => {
    const children = (
      <>
        <Icon icon="plus" />
        Add a Wallet
      </>
    );
    const customButton = (
      <div id="add-wallet-button-box">
        <Button variant={variant} size="small" children={children} />
      </div>
    );

    return (
      <WalletLink
        entity={entity}
        entryPoint={WalletLinkEntryPoint.OFFERING}
        offeringSlug={offeringSlug}
        onSuccess={displayLinkedWalletAddresses}
        customButton={customButton}
      />
    );
  };

  const NoThanksButton: React.FC = () => (
    <a
      id="no-thanks-button"
      className="c-button c-button--secondary c-button--small"
      onClick={() => window.location.assign(nextOfferingStepUrl)}
    >
      No Thanks
    </a>
  );

  const ContinueButton: React.FC<{ 
    isDisabled: boolean;
    selectedWallet: LinkedWallet | undefined;
    onClick: () => void;
   }> = ({
    isDisabled,
    selectedWallet,
    onClick,
  }) => {
    let selectedDisplayText: string;
    if (selectedWallet) {
      // take the first 3 characters and the last 3 characters of the id and separate them by an ellipses
      const abbreviatedId = `(${selectedWallet.address.slice(
        0,
        4
      )}...${selectedWallet.address.slice(-4)})`;
      selectedDisplayText = abbreviatedId;
    } else {
      selectedDisplayText = "(none selected)";
    }

    return (
      <a
        // @ts-ignore we want this disabled optionally
        disabled={isDisabled}
        id="continue-button"
        className="c-button c-button--small"
        onClick={onClick}
        style={{ textTransform: "none" }}
      >
        {`CONTINUE ${selectedDisplayText}`}
      </a>
    );
  };

  const ManageWalletsButton: React.FC = () => (
    <a
      id="manage-wallets-button"
      className="c-button c-button--secondary c-button--small"
      onClick={() => redirectToManageWalletsUrl()}
    >
      <Icon icon="cog" />
      Manage Wallets
    </a>
  );

  const LoadingView: React.FC = () => (
    <div className="u-text-center">
      <Icon icon="spin6" />
    </div>
  );

  if (isLoading) {
    return <LoadingView />;
  } else if (isOptional && linkedWallets.length === 0) {
    return (
      <div className={`${cx(classes.userOptionContainer)}`}>
        <NoThanksButton />
        <AddAWalletButton variant="primary" />
      </div>
    );
  } else if (isOptional && linkedWallets.length > 0 && !selectedWallet) {
    return (
      <>
        <LinkedAddressesRows />
        <div className={`${cx(classes.userOptionContainer)}`}>
          <ManageWalletsButton />
          <AddAWalletButton variant="secondary" />
          <ContinueButton isDisabled={false} selectedWallet={selectedWallet} onClick={() => submitSelectedWallet(null)}/>
        </div>
      </>
    );
  } else if (isOptional && linkedWallets.length > 0 && selectedWallet) {
    return (
      <>
        <LinkedAddressesRows />
        <div className={`${cx(classes.userOptionContainer)}`}>
          <ManageWalletsButton />
          <AddAWalletButton variant="secondary" />
          <ContinueButton isDisabled={false} selectedWallet={selectedWallet} onClick={() => setIsApprovalModalOpen(true)}/>
          <WalletModal 
            isOpen={isApprovalModalOpen} 
            onDecline={() => setIsApprovalModalOpen(false)} 
            onAgree={() => submitSelectedWallet(selectedWallet.id)}
            protocolName={issuerName}
          />
        </div>
      </>
    );
  } else if (!isOptional && linkedWallets.length === 0) {
    return (
      <div className={`${cx(classes.userOptionContainer)}`}>
        <AddAWalletButton variant="primary" />
      </div>
    );
  } else if (!isOptional && linkedWallets.length > 0 && !selectedWallet) {
    return (
      <>
        <LinkedAddressesRows />
        <div className={`${cx(classes.userOptionContainer)}`}>
          <ManageWalletsButton />
          <AddAWalletButton variant="secondary" />
          <ContinueButton isDisabled={true} selectedWallet={null} onClick={() => submitSelectedWallet(null)}/>
        </div>
      </>
    );
  } else if (!isOptional && linkedWallets.length > 0 && selectedWallet) {
    return (
      <>
        <LinkedAddressesRows />
        <div className={`${cx(classes.userOptionContainer)}`}>
          <ManageWalletsButton />
          <AddAWalletButton variant="secondary" />
          <ContinueButton isDisabled={false} selectedWallet={selectedWallet} onClick={() => setIsApprovalModalOpen(true)}/>
          <WalletModal 
            isOpen={isApprovalModalOpen} 
            onDecline={() => setIsApprovalModalOpen(false)} 
            onAgree={() => submitSelectedWallet(selectedWallet.id)}
            protocolName={issuerName}
          />
        </div>
      </>
    );
  } else {
    throw new Error("Invalid state");
  }
};

export default WalletLinkStep;

const useStyles = makeStyles(() => ({
  linkedWalletsList: {
    marginBottom: "16px",
  },
  linkedAddressRow: {
    padding: "12px 12px 12px 12px",
    borderRadius: "12px",
    border: "2px solid rgba(215,215,215, 1)",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(0 , 0, 0, 0.05)",
    },
    "&.selected": {
      border: "2px solid rgba(0 , 0, 0, 1)",
      backgroundColor: "transparent",
    },
  },
  radioColumn: {
    minWidth: "16px",
    width: "16px",
    height: "16px",
    borderRadius: "50%",
    border: "1px solid rgba(0 , 0, 0, 1)",
    boxSizing: "border-box",
    marginRight: "8px",
  },
  radioFill: {
    "&.selected": {
      backgroundColor: "rgba(0 , 0, 0, 1)",
      borderRadius: "50%",
      width: "8px",
      height: "8px",
    },
  },
  linkedWalletBox: {
    overflow: "hidden",
  },
  walletAddress: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  addAnotherButton: {
    width: "fit-content",
    cursor: "pointer",
    backgroundColor: "white",
  },
  userOptionContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "1rem",
  },
}));
