import * as React from "react";
import { useState } from "react";
import ActionSelector from "./components/ActionSelector";

import { Action } from "./components/ActionSelector";
import ProcessEntries from "./components/ProcessEntries";
import UserEntry from "./components/UserEntry";

export interface ProcessStatus {
  isProcessing: boolean;
  success?: boolean;
  errMsg?: string;
}

// Specified a target user_id + entity_id for action.
// Note that some actions are only user targeted, and can permit non-existing entity_id
export interface EntryToProcess {
  entry_id: string;
  user_id: string;
  entity_id?: string;
  entity_api_id?: string;
  email: string;
  first_name?: string;
  last_name?: string;

  // Dict of additional entity properties (wallet balance, pro balance, etc)
  metadata?: { [key: string]: any };

  // Mapping of action id -> result for that action
  statusByAction: { [key: number]: ProcessStatus };
}

interface Props {
  fraudSelectOptions: { [key: string]: string };
  riskLevelOptions: { [key: string]: string };
  walletFeatureToggleOptions: { [key: string]: string };
  entitiesFromUserEmailsUrl: string;
  entitiesFromIdsUrl: string;
  processActionsUrl: string;
  notoBaseUrl: string;
}

enum AppState {
  INIT = "INIT",
  ENTRIES_FETCHED = "ENTRIES_FETCHED",
  PROCESSED_PENDING = "PROCESSED_PENDING",
  PROCESSED_ALL = "PROCESSED_ALL",
}

const BANNER_TEXT = {
  [AppState.INIT]:
    "Start by selecting list of actions to apply along with User IDs or Entity IDs.\n" +
    "Fetch Users when done.",
  [AppState.ENTRIES_FETCHED]:
    "Make sure you have the correct user/entities you wish to apply (you can remove any using the [x] button) \n" +
    "Once you're done go ahead and apply the actions.",
  [AppState.PROCESSED_PENDING]:
    "Not all actions were applied succesfully. See error report below.",
  [AppState.PROCESSED_ALL]: "",
};

export default function BulkEntityEdit(props: Props) {
  const [appState, setAppState] = useState<AppState>(AppState.INIT);
  const [selectedActions, setSelectedActions] = useState<Action[]>([]);
  const [processEntriesQueue, setProcessEntriesQueue] = useState<
    EntryToProcess[]
  >([]);
  const [outputReport, setOutputReport] = useState<string>(null);

  const generateReport = (entries: EntryToProcess[]) => {
    /*
            Report follows CSV format - 

            ENTITY_ID_1, example1@domain.com, success
            ENTITY_ID_2, example2@domain.com, success
            ENTITY_ID_3, example3@domain.com, pending, err1, err2, err3
            ENTITY_ID_4, example4@domain.com, pending, err1
        */

    var report_str = "";
    for (const entry of entries) {
      report_str += (entry.entity_id || "NO_ENTITIES") + ",";
      report_str += entry.email + ",";
      report_str += IsEntryProcessedSuccesfully(entry) ? "success" : "pending";
      for (const [action_id, status] of Object.entries(entry.statusByAction)) {
        if (!status.success) {
          report_str += `,#${action_id}:"${status.errMsg.replace(
            /[\n\r]/g,
            ""
          )}"`;
        }
      }
      report_str += "\n";
    }

    setOutputReport(report_str);
  };

  // Checks if entity had all actions succesfully applied
  const IsEntryProcessedSuccesfully = (entry: EntryToProcess) => {
    for (const action of selectedActions) {
      const status = entry.statusByAction[action.id];
      if (!status || !status.success) return false;
    }
    return true;
  };

  return (
    <>
      {BANNER_TEXT[appState] && (
        <p
          id="banner"
          style={{
            backgroundColor: "#ffeeb8",
            border: "1px solid grey",
            borderRadius: 10,
            marginBottom: 25,
            padding: 10,
          }}
        >
          {BANNER_TEXT[appState]}
        </p>
      )}

      {/* TODO: ADD BANNER WITH INSTRUCTIONS DEPENDING ON STATE AND MISSING PROPERTIES :D */}

      <ActionSelector
        disableEdit={[
          AppState.PROCESSED_PENDING,
          AppState.PROCESSED_ALL,
        ].includes(appState)}
        selectedActions={selectedActions}
        onChangeHandler={(actions) => setSelectedActions(actions)}
        fraudSelectOptions={props.fraudSelectOptions}
        riskLevelOptions={props.riskLevelOptions}
        walletFeatureToggleOptions={props.walletFeatureToggleOptions}
      />

      <hr />

      <UserEntry
        entries={processEntriesQueue}
        onEntriesFetched={(entries) => {
          setAppState(AppState.ENTRIES_FETCHED);
          setProcessEntriesQueue(entries);
        }}
        hidden={[AppState.PROCESSED_PENDING, AppState.PROCESSED_ALL].includes(
          appState
        )}
        entitiesFromUserEmailsUrl={props.entitiesFromUserEmailsUrl}
        entitiesFromIdsUrl={props.entitiesFromIdsUrl}
      />

      <ProcessEntries
        entries={processEntriesQueue}
        hidden={appState == AppState.INIT}
        onEntryChange={(newEntryData) => {
          var updatedQueue: EntryToProcess[] = [...processEntriesQueue];
          for (let i = 0; i < updatedQueue.length; i++) {
            if (updatedQueue[i].entity_id === newEntryData.entity_id) {
              updatedQueue[i] = newEntryData;
            }
          }
          setProcessEntriesQueue(updatedQueue);
        }}
        deleteEntry={(targetEntryId) => {
          var updatedQueue: EntryToProcess[] = [...processEntriesQueue];
          updatedQueue = updatedQueue.filter(
            (entry) => entry.entry_id !== targetEntryId
          );
          setProcessEntriesQueue(updatedQueue);
        }}
        actions={selectedActions}
        onFinishedProcessingAttempt={() => {
          // Generate report CSV
          generateReport(processEntriesQueue);

          // Check if any have pending errors
          for (const entry of processEntriesQueue) {
            if (!IsEntryProcessedSuccesfully(entry)) {
              setAppState(AppState.PROCESSED_PENDING);
              return;
            }
          }

          // All entries succeeded processing
          setAppState(AppState.PROCESSED_ALL);
        }}
        allProcessedSuccesfully={appState === AppState.PROCESSED_ALL}
        processActionsUrl={props.processActionsUrl}
        notoBaseUrl={props.notoBaseUrl}
      />

      {appState === AppState.PROCESSED_ALL && (
        <>
          <h3 style={{ color: "green" }}>
            Wahoo! <span>🎉</span> Actions applied to all Entries succesfully!{" "}
          </h3>
        </>
      )}

      {[AppState.PROCESSED_PENDING, AppState.PROCESSED_ALL].includes(
        appState
      ) &&
        !!outputReport &&
        outputReport.length > 0 && (
          <>
            <hr />

            <h3>Report:</h3>

            <div
              style={{
                padding: 30,
                border: "1px solid grey",
                backgroundColor: "#f7f7e1",
              }}
            >
              <p style={{ whiteSpace: "pre-line" }}>{outputReport}</p>
            </div>
          </>
        )}
    </>
  );
}
