window.Treasury.ExternalWireAccounts = {
  form: ($$, $this) ->
    class ExternalWireAccountForm extends Backbone.View
      events: ->
        'change .js-select-international': '_toggleInternational'
        'change .js-canadian-bank-field': '_toggleCanadianBranchCode'

      _toggleInternational: ->
        if @$('.js-select-international').val() == 'true'
          @$('.js-usa').hide()
          @$('.js-international').show()
          @$('.js-beneficiary-address2-label').text('Account holder city, country')
          @$('.js-required-us-field').removeClass('c-input--required').attr('required', false)
          @$('.js-required-int-field').addClass('c-input--required').attr('required', true)
        else
          @$('.js-usa').show()
          @$('.js-international').hide()
          @$('.js-beneficiary-address2-label').text('Account holder city, state, zip')
          @$('.js-required-us-field').addClass('c-input--required').attr('required', true)
          @$('.js-required-int-field').removeClass('c-input--required').attr('required', false)

      _toggleCanadianBranchCode: ->
        if @$('.js-canadian-bank-field').val() == 'true'
          @$('.js-canadian-branch-code').show()
        else
          @$('.js-canadian-branch-code').hide()

    new ExternalWireAccountForm(el: $this)
}
