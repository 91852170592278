window.Market.Accounts.Preferences =
  category_list: ($$, $this) ->
    class CategoryListView extends Backbone.View
      DISABLE_STATE: 'blocked'
      SUCCESS_FADE_OUT: 5000

      initialize: (event) ->
        @loadingClass = @$el.data('loading-class')
        @disabledClass = @$el.data('disabled-class')

        @$el.removeClass(@loadingClass)
        $data = @$('.js-email_preference_data')
        if $data.length > 0
          @handleEmailPreference($data.data('category'), $data.data('frequency'))

      handleEmailPreference: (category, frequency) ->
        $form = @$(".js-form_#{category}")
        $input = $form.find("input[value='#{frequency}']")
        Animate.niceScrollTo($form, -100)
        if $input.prop('checked')
          @successAnimation($form.find('.js-success'))
        else
          $form.find('input').prop('checked', false)
          $input.prop('checked', true)
          $label = $input.closest('.js-label')
          @formAjax($form, $label)

      formAjax: ($form, $label) ->
        $form.ajaxSubmit
          success: (response) =>
            @successAnimation($form.find('.js-success'))
            $form.find('.js-label').addClass('c-button--secondary')
            $label.removeClass('c-button--secondary')
            if response.state?
              disabled = response.state == @DISABLE_STATE
              @$('.js-email_preferences').toggleClass(@disabledClass, disabled)
          error: (response) =>
            $form.find('.js-error').show()

      successAnimation: ($success) ->
        $success.show().stop().css({opacity: 1})
        setTimeout(->
          $success.fadeOut(@SUCCESS_FADE_OUT)
        , @SUCCESS_FADE_OUT)

      events:
        'change .js-input': (event) ->
          event.preventDefault()
          $target = $(event.currentTarget)
          $form = $target.closest('.js-form')
          $label = $target.closest('.js-label')
          @formAjax($form, $label)

    new CategoryListView el: $this
