require 'magnific-popup'

# A class when you simply want to throw an alert up on the screen but don't want to use
# javascript's `alert` method. Also works for `confirm`, except in this case you must use
# callbacks
module.exports =
  alert: (message, callbacks) ->
    $alert_box = $('.js-pretty_alert')
    unless $alert_box.length == 1
      console.error("The PrettyAlert box is missing on the page HTML, defaulting to JS alert")
      alert(message)
    else
      $alert_box.find('.js-message').html(message)

      $alert_box.find('.js-cancel').hide()
      $alert_box.find('.js-ok').off('click')
      $alert_box.find('.js-ok').on 'click', ->
        $.magnificPopup.close()
        callbacks.success() if callbacks?.success

      $.magnificPopup.open
        modal: true
        items:
          src: $alert_box
          type: 'inline'

  # Callbacks should be called success and failure
  confirm: (message, callbacks) ->
    $alert_box = $('.js-pretty_alert')
    unless $alert_box.length == 1
      console.error("The PrettyAlert box is missing on the page HTML, defaulting to JS confirm")
      if confirm(message)
        callbacks.success() if callbacks?.success
      else
        callbacks.failure() if callbacks?.failure
    else
      $alert_box.find('.js-message').html(message)

      $alert_box.find('.js-cancel').show()
      $alert_box.find('.js-cancel').off('click')
      $alert_box.find('.js-cancel').on 'click', ->
        $.magnificPopup.close()
        callbacks.failure() if callbacks?.failure

      $alert_box.find('.js-ok').show()
      $alert_box.find('.js-ok').off('click')
      $alert_box.find('.js-ok').on 'click', ->
        $.magnificPopup.close()
        callbacks.success() if callbacks?.success

      $.magnificPopup.open
        modal: true
        items:
          src: $alert_box
          type: 'inline'
