window.Rewards.Vaults =
  deposit: ($$, $this) ->
    class DepositView extends Backbone.View
      LOADING_CLASS: 'c-button--loading'
      DISABLED_CLASS: 'c-button--disabled'

      initialize: ->
        @$amount = @$('.js-amount')
        @$amountError = @$('.js-amount-error')
        @$submitErrors = @$('.js-errors')
        @$submitButton = @$('.js-submit')
        @balance = @$el.data('balance')
        @minDepositAmount = @$el.data('min-deposit-amount')
        @decimalPrecision = @$el.data('decimal-precision')
        @inputErrorClass = @$el.data('input-error-class')

      _round: (amount) ->
        parseFloat(amount.toFixed(@decimalPrecision))

      _amount: ->
        amount = @$amount.val().replace(',', '')
        return null if !amount

        amount = parseFloat(amount)
        return 0.0 if isNaN(amount)
        @_round(amount)

      _validate: ->
        @$submitButton.addClass(@DISABLED_CLASS)
        @$amountError.hide()
        @$amount.removeClass(@inputErrorClass)

        amount = @_amount()

        return if !amount || amount < 0

        if amount > @balance
          @$amountError.text('Insufficient funds').show()
          @$amount.addClass(@inputErrorClass)
          return

        if amount < @minDepositAmount
          @$amountError.text(
            "The minimum deposit amount is #{@minDepositAmount}"
          ).show()
          @$amount.addClass(@inputErrorClass)
          return

        @$submitButton.removeClass(@DISABLED_CLASS)

      events: ->
        'input .js-amount': ->
          @_validate()

        'submit': ->
          # turn off submit on enter key
          false

        'click .js-max-deposit': ->
          @$amount.val(@balance)
          @_validate()

        'click .js-submit':  (e) ->
          $target = @$(e.target)
          return if $target.hasClass(@DISABLED_CLASS) || $target.hasClass(@LOADING_CLASS)

          $target.addClass(@LOADING_CLASS)
          @$submitErrors.hide()

          @$('form.js-submit-form').ajaxSubmit
            success: (response) =>

              @$('.js-show-on-success').show()
              @$('.js-hide-on-success').hide()
              @$('.js-form-section').hide()

            error: (response) =>
              if response.responseJSON.errors
                $target.removeClass(@LOADING_CLASS)
                @$submitErrors.text(response.responseJSON.errors.message).fadeIn()
              else
                Tooltips.formServerError($target)

    new DepositView el: $this

  withdraw: ($$, $this) ->
    class WithdrawView extends Backbone.View
      LOADING_CLASS: 'c-button--loading'
      DISABLED_CLASS: 'c-button--disabled'

      initialize: ->
        @$submitErrors = @$('.js-errors')

      events: ->
        'submit': ->
          # turn off submit on enter key
          false

        'click .js-edit_withdrawal': (e) ->
          @$('.js-form-section').show()
          @$('.js-existing_withdrawal').hide()

        'click .js-submit':  (e) ->
          $target = @$(e.target)
          return if $target.hasClass(@DISABLED_CLASS) || $target.hasClass(@LOADING_CLASS)

          $target.addClass(@LOADING_CLASS)
          @$submitErrors.hide()

          @$('form.js-submit-form').ajaxSubmit
            success: (response) =>
              @$('.js-show-on-success').show()
              @$('.js-hide-on-success').hide()
              @$('.js-form-section').hide()

            error: (response) =>
              if response.responseJSON.errors
                $target.removeClass(@LOADING_CLASS)
                @$submitErrors.text(response.responseJSON.errors.message).fadeIn()
              else
                Tooltips.formServerError($target)

    new WithdrawView el: $this
