require('datatables')

# classe defaults
$.fn.dataTable.ext.classes.sPageButton = 'c-button c-button--secondary c-button--small'
$.fn.dataTable.ext.classes.sPageButtonActive = 'c-button--active'

window.DataTablePresets =

  # This is redactor default config, it's here for education only
  default: ->
    # sDob builder
    tableDom = 't<"s-marginTop1 u-text-right"<"u-displayInlineBlock s-fontSize14 u-colorGray6"'
    tableDom+= 'i><"u-displayInlineBlock s-marginLeft1 c-button-group"p>>'
    displayLength: 30
    params = {
      sDom: tableDom
    }
    params
