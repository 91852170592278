import * as React from "react";
import Flex from "@react/components/Flex";
import { Button, Container } from "@react/components";

export default function OtcHero(props) {
  return (
    /*  Generate gradients: https://cssgradient.io/ */
    <div
      style={{
        backgroundColor: "#fafafa",
        color: "#000",
      }}
    >
      <Container maxSize="18">
        <Flex container style={{ paddingBottom: 136, paddingTop: 104 }}>
          <h1 className="s-fontSize48 s-marginBottom1 u-fontWeight700">
            CoinList OTC Desk: White Glove Service for Large Crypto Trades
          </h1>
          <h2 className="s-fontSize20">
            The CoinList OTC Desk offers deeper liquidity and a more
            personalized service to institutions and high-net-worth individuals.
            Contact us for competitive execution and settlement services.
          </h2>
          <Flex container style={{ marginTop: 24 }}>
            <span className="s-marginRight1">
              <Button
                onClick={() => (window.location.href = props.newOtcTradeUrl)}
                variant="primary"
              >
                I'm interested
              </Button>
            </span>
          </Flex>
        </Flex>
      </Container>
    </div>
  );
}
