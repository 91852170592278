require 'ezdz'

C32 = require 'c32check'
Partials = require '../lib/partials'
PrettyAlert = require '../lib/pretty_alert'
accounting = require 'accounting'
Clipboard = require('clipboard')

window.Offerings.Participants = {

  StacksPurchaseLimit: {
    form: ($$, $this) ->
      class FormView extends Backbone.View
        submit: ->
          @$('form').ajaxSubmit
            success: (response) =>
              window.location = response.redirect
            error: (response) =>
              @$el.replaceWith response.responseJSON.html

        events:
          'click .js-create': (event) ->
            event.preventDefault()
            $target = $(event.target)
            if $target.hasClass 'c-button--disabled' || $target.hasClass 'c-button--loading'
              return

            $target.addClass 'c-button--loading'
            @submit()

      new FormView el: $this
  }

  StacksVoucher: {
    skip_form: ($$, $this) ->
      class FormView extends Backbone.View

        submit: ->
          @$('form').ajaxSubmit
            success: (response) =>
              window.location = response.redirect
            error: (response) =>
              @$el.replaceWith response.responseJSON.html

        events:
          'click .js-skip': (event) ->
            event.preventDefault()
            $target = $(event.target)
            if $target.hasClass 'c-button--disabled' || $target.hasClass 'c-button--loading'
              return

            $target.addClass 'c-button--loading'
            @submit()

      new FormView el: $this

    form: ($$, $this) ->
      class FormView extends Backbone.View
        initialize: ->
          @initializeVoucherInput()

        # Voucher drag & drop
        initializeVoucherInput: ->
          $voucherInput = @$('.js-voucher_input')
          options = _.extend(EdznHelper.defaultOptions(), {
            text: $voucherInput.data('dropzoneText'),
            validators: {
              maxSize: $voucherInput.data('maxSize'),
            }
            accept: (file) =>
              @$('.js-voucher_label_highlight').removeClass('u-colorRed')
              @$('.js-dropzone-error').addClass(@HIDDEN_CLASS)
            reject: (file, errors) =>
              @$('.js-voucher_label_highlight').addClass('u-colorRed')
              @$('.js-dropzone-error').removeClass(@HIDDEN_CLASS)
                .text(EdznHelper.messageForError(file, errors))
          })
          $voucherInput.ezdz(options)

        submit: ->
          @$('form').ajaxSubmit
            success: (response) =>
              window.location = response.redirect
            error: (response) =>
              @$el.replaceWith response.responseJSON.html

        events:
          'click .js-create': (event) ->
            event.preventDefault()
            $target = $(event.target)
            if $target.hasClass 'c-button--disabled' || $target.hasClass 'c-button--loading'
              return

            $target.addClass 'c-button--loading'
            @submit()

          'keypress .js-voucher_number_input': (event) ->
            if event.keyCode == Keyboard.enter
              event.preventDefault()
              @submit()

      new FormView el: $this
  }

  StacksWallet: {
    form: ($$, $this) ->
      class FormView extends Backbone.View

        initialize: ->
          @validateAddress()

        trackSubmission: ->
          allocationRequested = @$('.js-data').data('allocationRequested')
          fbq?('track', 'Lead', {currency: "USD", value: allocationRequested})
          Analytics.trackEvent('Wallet Submission', {value: allocationRequested, send_to: 'AW-799254041/ghLvCMLW1qEBEJnMjv0C'})
          ga?('gtag_UA_119163063_4.send', {hitType: 'event', eventCategory: 'token', eventAction: 'wallet submission', eventValue: allocationRequested})

        submit: ->
          $target = @$('.js-create')

          if $target.hasClass 'c-button--disabled' || $target.hasClass 'c-button--loading'
            return

          $target.addClass 'c-button--loading'

          @$('form').ajaxSubmit
            success: (response) =>
              @trackSubmission()
              window.location = response.redirect

            error: (response) =>
              @$el.replaceWith response.responseJSON.html

        validateAddress: ->
          $metastableTarget = @$('.js-wallets-location')
          $target = @$('.js-address')

          if !$target.val() && !$metastableTarget.val()
            @$('.js-create').addClass 'c-button--disabled'
            $target.qtip('destroy', true)
            return

          valid = true

          if valid
            $target.qtip('destroy', true)
            @$('.js-create').removeClass 'c-button--disabled'
          else
            @$('.js-create').addClass 'c-button--disabled'
            Tooltips.formError($target, '<i class="icon-attention"></i> Invalid Stacks address')

        events:
          'change .js-wallets-location': (event) ->
            if event.currentTarget.value == 'coinlist'
              @$('.js-address-metastable').addClass 'u-hidden'
              @$('.js-address-coinlist').removeClass 'u-hidden'
            else
              @$('.js-address-metastable').removeClass 'u-hidden'
              @$('.js-address-coinlist').addClass 'u-hidden'

          'click .js-create': (event) ->
            event.preventDefault()
            @submit()

          'keypress .js-address': (event) ->
            if event.keyCode == Keyboard.enter
              event.preventDefault()
              @submit()

          'input .js-address': ->
            @validateAddress()

      new FormView el: $this
  }

  algorand_staking_addresses: ($$, $this) ->
    class FormView extends Backbone.View
      events:
        'click .js-create': (event) ->
          $button = $$(event.currentTarget)
          $form = $button.closest('form')

          return if $button.hasClass('c-button--loading')
          $button.addClass('c-button--loading')

          $form.ajaxSubmit
            success: =>
              window.location = $button.data('redirect')
            error: =>
              $button.removeClass('c-button--loading')
              PrettyAlert.alert("Those addresses could not be saved, please check they are valid")

    new FormView el: $this

  fund_wallets: ($$, $this) ->
    class FundWalletsView extends Backbone.View
      initialize: ->
        ## instance variables
        @currencyOptions = @$('.js-currency')
        @selectAssetBox = $('#select-asset-box')
        @minPurchaseAmount = @$el.data('minPurchaseAmount')
        @sufficientFundsText = $('#sufficient-funds-text')
        @needAdditionalFundsBox = $('#need-additional-funds-box')
        @prefundContinueBox = $('#prefund-continue-box')
        @amountToSendText = $('#amount-to-send-text')

        ## methods to run on initialize
        @_initializeCopyToClipboardEventHandler()

      _selectCurrency: (selectedCurrencyOption) ->
        # remove class from all currency inputs
        @currencyOptions.removeClass('active')
        # add class to first currency input
        selectedCurrencyOption.addClass('active')

        @_updateRelevantCurrencyElements(selectedCurrencyOption)

      _initializeCopyToClipboardEventHandler: ->
          # Initialize the needed jQuery elements
          $element = $('#copy-wallet-address-btn')
          $input_element = $element.closest('.js-address-holder').find('#js-deposit-address')

          # Skip if the button is already bound
          return if $element.data('clipboard-instance')

          # Prepare the Clipboard.js library with the appropriate text to be copied
          value = $input_element.val().trim()
          $element.attr('data-clipboard-text', value)

          # Create new Clipboard instance and attach it to the button
          clipboard = new Clipboard($element.get(0))
          $element.data('clipboard-instance', clipboard)

          # Prepare tracking for analytics
          asset_symbol = $element.data('asset_symbol')

          clipboard.on 'success', (e) =>
            # Select input on success
            $input_element.select()

            # Change the button title to give the user feedback
            $element.attr('title', 'Copied!')

            # Track the event on the analytics
            Analytics.trackEvent(asset_symbol + ' - Deposit Copy Button Clicked')

            # Create and show tooltip
            options = Tooltips.presetSimple()
            options = _.extend options,
              show:
                ready: true
              hide:
                event: false
            $element.qtip(options)

            # Destroy the tooltip after a delay
            setTimeout(->
              $element.qtip('destroy', true)
            , 2000)

      _updateRelevantCurrencyElements: (selectedCurrencyOption) ->
        # get all the info we need from the selected currency option to update elements
        uppercaseAssets = $('.js-asset-upcased')
        assetIcon = $('#js-asset-icon')
        newAssetSymbol = selectedCurrencyOption.data('funding-method')
        # Not sure why it's converting the image path to camelCase from the data attribute in fund_wallets.html.haml
        dataImagePathKey = "#{newAssetSymbol}ImagePath"
        assetIconPath = $("#currency-icon-box").data(dataImagePathKey)
        depositAddress = $('#js-deposit-address')
        newAddress = selectedCurrencyOption.data('deposit-address')

        # put the uppercase asset symbol in the spots that need it
        newAssetSymbolUppercase = newAssetSymbol.toUpperCase()
        uppercaseAssets.text(newAssetSymbolUppercase)
        # update the asset icon and deposit address
        assetIcon.attr('src', assetIconPath)
        depositAddress.val(newAddress)

        # show the necessary amount and deposit address or show the user they have sufficient funds
        desiredAmountString = $('#js-desired-amount').val()
        amountStringWithoutComma = desiredAmountString.replace(/,/g, '')
        desiredAmount = parseInt(amountStringWithoutComma)
        selectedBalance = selectedCurrencyOption.data('balance')
        if selectedBalance >= desiredAmount
          @sufficientFundsText.removeClass('u-hidden')
          @needAdditionalFundsBox.addClass('u-hidden')
        else
          @sufficientFundsText.addClass('u-hidden')
          regDeadline = @$el.data('registrationDeadline')
          @amountToSendText.text("You need to send $#{desiredAmount - selectedBalance} more #{newAssetSymbolUppercase} to your CoinList wallet by #{regDeadline}")
          @needAdditionalFundsBox.removeClass('u-hidden')

        # show the continue button either way
        @prefundContinueBox.removeClass('u-hidden')

      _isAmountValidated: (desiredAmountValue) ->
        # toggle the error message if the user enters an amount that is too low
        minPurchaseAmount = @$el.data('minPurchaseAmount')
        maxPurchaseAmount = @$el.data('maxPurchaseAmount')
        desiredAmountError = $('#desired-amount-error')

        isValid = desiredAmountValue != null && (desiredAmountValue >= minPurchaseAmount && desiredAmountValue <= maxPurchaseAmount)

        # return true or false so that we know whether to show the currency selectors
        if isValid
          desiredAmountError.text("")
          desiredAmountError.removeClass('s-paddingBottom1')
          desiredAmountError.addClass('s-paddingBottom2')
          return true
        else
          desiredAmountError.text("Please enter a valid amount")
          desiredAmountError.removeClass('s-paddingBottom2')
          desiredAmountError.addClass('s-paddingBottom1')
          return false


      events: ->
        'click .js-currency': (e) ->
          selectedCurrencyOption = $(e.currentTarget)
          @_selectCurrency(selectedCurrencyOption)

        'input #js-desired-amount': (e) ->
          desiredAmountInputString = $(e.currentTarget).val()
          cleanedIntegerString = desiredAmountInputString.replace(/,/g, '')
          desiredAmountValue = parseInt(cleanedIntegerString)
          # if amount is not valid, show an error message
          isValidAmount = @_isAmountValidated(desiredAmountValue)
          selectAssetBox = $('#select-asset-box')
          # if amount is valid, we show the currency selectors
          if isValidAmount
            selectAssetBox.removeClass('u-hidden')
            @_selectCurrency(@currencyOptions.first())
            @_updateRelevantCurrencyElements(@currencyOptions.first())
          else
            # otherwise, we hide everything and start over
            selectAssetBox.addClass('u-hidden')
            @sufficientFundsText.addClass('u-hidden')
            @needAdditionalFundsBox.addClass('u-hidden')
            @prefundContinueBox.addClass('u-hidden')

    new FundWalletsView el: $this
}
