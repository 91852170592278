import * as React from "react";
import { useState } from "react";
import axios from "axios";

import isEmail from "validator/lib/isEmail";
import isUUID from "validator/lib/isUUID";

import Flex from "@react/components/Flex";
import RadioCollections from "@react/views/shared/forms/RadioCollections/RadioCollections";
import { EntryToProcess } from "../index";
import { Checkbox, TextArea, Label, Button, Icon } from "@react/components";
import BlockField from "@react/views/shared/forms/BlockField/BlockField";
import { getRailsHeaders } from "../../../../utils/network";

const USER_FETCH_BATCH_SIZE = 200;
const USER_ENTRY_SIZE_CAP = 2000;

enum SupportedEntryTypes {
  ENTITY_API_ID = "ENTITY_API_ID",
  ENTITY_UUID = "ENTITY_UUID",
  USER_EMAIL = "USER_EMAIL",
}

const SupportedEntryTypesOpts = {
  [SupportedEntryTypes.ENTITY_API_ID]: {
    label: "Entity API ID",
    validate_fn: (id) => id.length === 32,
    validation_label: "Entity API ID",
  },
  [SupportedEntryTypes.ENTITY_UUID]: {
    label: "Entity ID",
    validate_fn: (id) => isUUID(id, 4),
    validation_label: "UUID v4",
  },
  [SupportedEntryTypes.USER_EMAIL]: {
    label: "User Emails",
    validate_fn: (email) => isEmail(email),
    validation_label: "email",
  },
};

interface UserEntryProps {
  entries: EntryToProcess[];
  onEntriesFetched: (entries: EntryToProcess[]) => void;
  hidden: boolean;
  entitiesFromUserEmailsUrl: string;
  entitiesFromIdsUrl: string;
}

// Emails are encoded with dots '.' replaced by '%' as for the URL not to be mal-interpreted by backend
const encodeEmailForUri = (email: string) =>
  encodeURIComponent(email.replace(/\./g, "%"));

export default function UserEntry(props: UserEntryProps) {
  const [entryType, setEntryType] = useState<SupportedEntryTypes>(
    SupportedEntryTypes.ENTITY_API_ID
  );
  const [entryInput, setEntryInput] = useState<string>("");
  const [fetchErrors, setFetchErrors] = useState<string[]>([]);
  const [entryError, setEntryError] = useState<string>(null);
  const [isFetchOngoing, setIsFetchOngoing] = useState<boolean>(false);
  const [isViewCollapsed, setIsViewCollapsed] = useState<boolean>(false);
  const [shouldFetchMetadata, setShouldFetchMetadata] = useState<boolean>(false);

  const fetchUsersButton = () => {
    if (entryInput.length === 0) {
      return null;
    } else if (isFetchOngoing) {
      return (
        <Flex alignItems="center" container justifyContent="center">
          <Icon icon="spin6" />
        </Flex>
      );
    } else {
      return (
        <Button onClick={fetchUsersHandler}>
          {props.entries.length === 0 ? "Fetch Users" : "Re-fetch Users"}
        </Button>
      );
    }
  };

  // Massage data for each user in response to one or more 'EntryToProcess'
  const responseUserToEntriesToProcess = (resUser) => {
    let entries: EntryToProcess[] = [];

    // If a user has no registered entities, just push a 'EntryToProcess' with blank entity-related parameters
    if (resUser.entities.length === 0) {
      entries.push({
        entry_id: resUser.user_id,
        user_id: resUser.user_id,
        email: resUser.email,
        statusByAction: {},
        metadata: {},
      });
      return entries;
    }

    for (const entity of resUser.entities) {
      entries.push({
        entry_id: resUser.user_id + "_" + entity.id,
        entity_id: entity.id,
        entity_api_id: entity.entity_api_id,
        user_id: resUser.user_id,
        email: resUser.email,
        first_name: entity.first_name,
        last_name: entity.last_name,
        metadata: shouldFetchMetadata ? resUser.metadata[entity.id] : {},
        statusByAction: {},
      });
    }
    return entries;
  };

  const fetchUsersHandler = async () => {
    // Process text input into slice of entries + trim whitespaces
    var entries: string[] = entryInput.split("\n").map((entry) => entry.trim());

    // Remove empty strings
    entries = entries.filter((entry) => !!entry);

    // Remove any duplicates
    entries = entries.filter((entry, index) => {
      return entries.indexOf(entry) == index;
    });

    // Validate input is correct
    for (const entry of entries) {
      const isValid = SupportedEntryTypesOpts[entryType].validate_fn(entry);
      if (!isValid) {
        return setEntryError(
          `Entry ${entry} does not match ${SupportedEntryTypesOpts[entryType].validation_label} format`
        );
      }
    }

    // Cap number of entries user can input
    if (entries.length >= USER_ENTRY_SIZE_CAP) {
      return setEntryError(
        `Tool only supports populating less than ${USER_ENTRY_SIZE_CAP} entries at a time.`
      );
    }

    // Clear out input validation errs
    setEntryError(null);

    // Fetch entity IDs via API
    setIsFetchOngoing(true);

    var userPulls: EntryToProcess[] = [];
    var userPullErrors: string[] = [];

    var base_url;
    if (
      [
        SupportedEntryTypes.ENTITY_UUID,
        SupportedEntryTypes.ENTITY_API_ID,
      ].includes(entryType)
    ) {
      base_url = props.entitiesFromIdsUrl;
    } else if (entryType === SupportedEntryTypes.USER_EMAIL) {
      base_url = props.entitiesFromUserEmailsUrl;
    }

    for (var i = 0; i < entries.length; i += USER_FETCH_BATCH_SIZE) {
      try {
        const entries_batch = entries.slice(
          i,
          Math.min(i + USER_FETCH_BATCH_SIZE, entries.length)
        );

        const request = {
          entries: entries_batch,
          should_fetch_metadata: shouldFetchMetadata,
        };
        const response = await axios.post(base_url, request, {
          headers: getRailsHeaders(),
        });

        // API returns a slice of hashes, each hash with a unique user id and list of the user's corresponding entities
        response.data.forEach((responseUser) => {
          if (responseUser.error) {
            userPullErrors.push(responseUser.error);
            return;
          }

          let entriesToProcess = responseUserToEntriesToProcess(responseUser);
          entriesToProcess.forEach((entry) => userPulls.push(entry));
        });
      } catch (error) {
        userPullErrors.push(
          (error.response &&
            error.response.data &&
            error.response.data.error) ||
            "Unknown error occured"
        );
      }
    }

    props.onEntriesFetched(userPulls);
    setFetchErrors(userPullErrors);
    setIsFetchOngoing(false);
  };

  if (props.hidden) {
    return <></>;
  }

  if (!props.hidden && isViewCollapsed) {
    return (
      <Button variant={"secondary"} onClick={() => setIsViewCollapsed(false)}>
        <span>📝</span>&nbsp;&nbsp;Edit users to process
      </Button>
    );
  }

  const entryTypeRadioOptions = Object.keys(SupportedEntryTypes).map((type) => {
    return {
      label: SupportedEntryTypesOpts[type].label,
      radio: { value: type },
    };
  });
  return (
    <>
      <div className="s-fontSize18 u-fontWeight700">Enter users to process</div>

      <p style={{ marginTop: 15, fontSize: '13px', color: "grey" }}>
        If metadata serach is checked, the tool will search for user info such as their balance
        holdings AND display it in a the table. However, it will take significantly longer to
        process the users.
      </p>
      <Flex style={{ marginTop: 30 }} container>
        <Flex item xs={4}>
          <Label children="Search for user metadata ?" />
          <Checkbox
            checked={shouldFetchMetadata}
            onChange={(e) => setShouldFetchMetadata(e.target.checked)}
          />
          <div style={{ marginTop: 30 }}/>
          <Label children="Select Entry Type:" />
          <RadioCollections
            radioOptions={entryTypeRadioOptions}
            name="entryType"
            onChange={(e) =>
              setEntryType(e.target.value as SupportedEntryTypes)
            }
            value={entryType}
          />
          
        </Flex>
        <Flex item xs={20}>
          <Flex container>
            <BlockField label="Enter Users:" error={entryError}>
              <TextArea
                placeholder={"Insert Entity IDs or User Emails line by line..."}
                onChange={(e) => {
                  e.target.value && setEntryInput(e.target.value);
                }}
                rows={5}
                value={entryInput}
              />
            </BlockField>
          </Flex>
          {fetchErrors.length > 0 && (
            <Flex container style={{ color: "red", fontWeight: 800 }}>
              <p>Errors:</p>
              <ul>
                {fetchErrors.map((err) => (
                  <li> {err} </li>
                ))}
              </ul>
            </Flex>
          )}
          <Flex container>{fetchUsersButton()}</Flex>
        </Flex>
      </Flex>
    </>
  );
}
