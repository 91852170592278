import * as React from "react";
import { KycSelectorOrganizationType } from '@coinlist/ui-lib';
import UiLibWrapper from '../../utils/UiLibWrapper';

type OrgTypeSelectorPageProps = {
  locale: string;
}

export default function OrgTypeSelectorPage(props : OrgTypeSelectorPageProps) {  
  const redirectToOrgApplication = (entityId: string) => {
    window.location.href = `/kyc/organization/${entityId}`;
  };

  return (
    <UiLibWrapper locale={props.locale}>
      <KycSelectorOrganizationType redirectToOrgApplication={redirectToOrgApplication}/>
    </UiLibWrapper>
  );
}
