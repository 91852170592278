import * as React from "react";
import { ampli } from '../../../../ampli'
import { KarmaHome } from "@coinlist/frontend-ui-lib"
require("@coinlist/frontend-ui-lib/dist/style.css");

export default function Karma(props) {
  return (
    <KarmaHome amplitudeEvent={(event, data) => ampli.track(event, undefined, data)} entityId={props.entityId} apiUrl={"/karma"} csrfToken={document.querySelector("meta[name='csrf-token']").getAttribute("content")} />
  );
}
