import * as React from "react";

import Box from "../Box";
import Flex from "../Flex";
import StakingButtons from "./StakingButtons";
import Typography from "../typography/Typography";
import WalletCells from "../WalletCells";

import { StakingAsset } from "../../types/staking";
import { LOGO_MAP } from "@react/shared/logos";
import { SegmentReact } from "@react/analytics/segment_react";
import { COLOR_MAP } from "@react/utils/color";

interface StakingBoxProps {
  asset: StakingAsset;
  moreOptionsOnClick: () => void;
  stakeOnClick: () => void;
  unstakeOnClick: () => void;
  walletCells: Array<any>;
  userId: string;
  sessionId: string;
  originPage: string;
}

const T_SYMBOL = "T";

function ProtocolPageLink(props) {
  return (
    <Typography
      bold
      fontSize={18}
      onClick={() => {
        window.open(props.asset.protocolPageUrl, "_blank").focus();
        SegmentReact.track(props.asset.symbol + " Staking Page", {
          page_event: props.asset.symbol + " Protocol Page clicked",
          user_id: props.userId,
          session_id: props.sessionId,
          viewed_at: new Date(),
        });
      }}
      style={{ color: COLOR_MAP.primary, cursor: "pointer" }}
    >
      &nbsp;Asset Profile
    </Typography>
  );
}

export default function StakingBox(props: StakingBoxProps) {
  const {
    asset,
    moreOptionsOnClick,
    stakeOnClick,
    unstakeOnClick,
    walletCells,
  } = props;
  return (
    <Box id={"mainContainer"} fullWidth>
      <Flex container>
        {/* TITLE & BUTTONS */}
        <Flex
          alignItems="center"
          container
          justifyContent="space-between"
          separator
          style={{ padding: 8 }}
        >
          <div>
            <Flex alignItems="center" container style={{ padding: 8 }}>
              <img
                alt={`${asset.symbol} Logo`}
                src={LOGO_MAP[asset.symbol.toLowerCase()]}
                style={{ height: 40, marginRight: 8, width: 40 }}
              />
              <Typography bold fontSize={18}>
                {asset.symbol.toUpperCase() === T_SYMBOL && asset.apy > 0.15
                  ? asset.symbol + " Early Adopter Staking"
                  : asset.symbol + " Staking"}
                &nbsp;&middot;&nbsp;
              </Typography>
              <Typography
                bold
                fontSize={18}
                onClick={() => {
                  SegmentReact.track(
                    asset.symbol + " " + props.originPage + " Page",
                    {
                      page_event: asset.symbol + " Learn More clicked",
                      user_id: props.userId,
                      session_id: props.sessionId,
                      viewed_at: new Date(),
                    }
                  );
                  window.open(asset.helpUrl, "_blank").focus();
                }}
                style={{ color: COLOR_MAP.primary, cursor: "pointer" }}
              >
                Learn More
              </Typography>
              {asset.protocolPageUrl && (
                <Typography bold fontSize={18} style={{ color: "#000000" }}>
                  &nbsp;&middot;
                </Typography>
              )}
              {asset.protocolPageUrl && (
                <ProtocolPageLink
                  asset={asset}
                  userId={props.userId}
                  sessionId={props.sessionId}
                />
              )}
            </Flex>
          </div>
          <div>
            <Flex
              container
              justifyContent="flex-end"
              spacing={1}
              style={{ padding: 8 }}
            >
              <StakingButtons
                asset={asset}
                mobile={false}
                moreOptionsOnClick={moreOptionsOnClick}
                stakeOnClick={stakeOnClick}
                unstakeOnClick={unstakeOnClick}
                userId={props.userId}
                sessionId={props.sessionId}
              />
            </Flex>
          </div>
        </Flex>
        {/* CELLS TO DISPLAY INFO */}
        <WalletCells walletCells={walletCells} />
      </Flex>
    </Box>
  );
}
