Partials = require('../../lib/partials')

window.Market.Dashboard.Notifications =
  index: ($$, $this) ->
    class IndexView extends Backbone.View
      initialize: ->
        @bindFormPartial()

      bindFormPartial: ->
        formPartial = 'market/dashboard/notifications/form'

        # Avoid listening events from other forms on the page
        $formScoped =
            @$el.find('.js-new_notification_form_wrapper')
                .find(Partials.selector(formPartial))

        Partials.with $formScoped , (partial) =>
          partial.on 'success', (notificationRowHtml) =>
            @$('.js-notifications').prepend(notificationRowHtml)
            @$('.js-new_notification_form_wrapper').slideUp('fast')
            partial.resetForm()

          partial.on 'cancel', =>
            @$('.js-new_notification_form_wrapper').slideUp('fast')

      events: ->
        'click .js-show_new_notification_form_wrapper': (e) ->
          @$('.js-new_notification_form_wrapper').slideDown('fast')

        'change .js-show_archived_notification_input': (e) ->
          $target = @$(e.currentTarget)
          if $target.is(':checked')
            @$('.js-archived_notification').slideDown('fast')
          else
            @$('.js-archived_notification').slideUp('fast')

    new IndexView el: $this

  form: ($$, $this) ->
    class FormView extends Backbone.View
      initialize: ->
        @$originalForm = @$el.clone()

        @$('.js-disallowed_countries_selector').selectize({
          plugins: ['remove_button']
        })

      submitForm: ->
        @$('.js-notification_form_submit').addClass('c-button--loading')
        @$('.js-notification_form').ajaxSubmit
          success: (response) =>
            @trigger('success', response.notification_row_html)
          error: (response) =>
            @$el.replaceWith(response?.responseJSON.form_html)

            # Need to rebind these partials
            Partials.with $(Partials.selector('market/dashboard/notifications/index')), (partial) =>
              partial.bindFormPartial()

            Partials.with $(Partials.selector('market/dashboard/notifications/notification_row')), (partial) =>
              partial.bindFormPartial()


      resetForm: ->
        @$el.replaceWith(@$originalForm)

      events: ->
        'submit .js-notification_form': (e) ->
          e.preventDefault()
          @submitForm()

        'click .js-notification_form_cancel': (e) ->
          e.preventDefault()
          @trigger('cancel')
          @resetForm()

    new FormView el: $this

  notification_row: ($$, $this) ->
    class NotificiationRowView extends Backbone.View
      initialize: ->
        @bindFormPartial()

      bindFormPartial: ->
        formPartial = 'market/dashboard/notifications/form'
        Partials.with @$(Partials.selector(formPartial)), (partial) =>
          partial.on 'success', (notificationRowHtml) =>
            @$el.replaceWith(notificationRowHtml)
          partial.on 'cancel', =>
            @$('.js-notification_content_wrapper').removeClass('u-hidden')
            @$('.js-notification_form_wrapper').addClass('u-hidden')

      events: ->
        'click .js-edit_notification': (e) ->
          e.preventDefault()
          @$('.js-notification_content_wrapper').addClass('u-hidden')
          @$('.js-notification_form_wrapper').removeClass('u-hidden')

        'click .js-publish_notification': (e) ->
          event.preventDefault()
          $target = @$(e.target)
          if confirm('Are you sure you want to publish notification?')
            $.ajax @$(e.currentTarget).attr('href'),
              dataType: 'json'
              type: 'PATCH'
              success: (response) =>
                @$el.replaceWith(response.notification_row_html)

        'click .js-archive_notification': (e) ->
          event.preventDefault()
          $target = @$(e.target)
          if confirm('Are you sure you want to archive notification?')
            $.ajax @$(e.currentTarget).attr('href'),
              dataType: 'json'
              type: 'PATCH'
              success: (response) =>
                @$el.replaceWith(response.notification_row_html)

        'click .js-destroy_notification': (e) ->
          event.preventDefault()
          $target = @$(e.target)
          if confirm('Are you sure you want to remove notification?')
            $.ajax @$(e.currentTarget).attr('href'),
              dataType: 'json'
              type: 'DELETE'
              success: (response) =>
                @$el.remove()

        'click .js-notification_link': (e) ->
          e.preventDefault()
          Analytics.trackEvent 'Market Dashboard Notification Clicked', {}, =>
            window.location.href = e.target.href


    new NotificiationRowView el: $this
