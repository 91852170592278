import * as React from "react";
import { useState } from "react";

import SectionTitle from "@react/components/SectionTitle";
import Flex from "@react/components/Flex";
import Typography from "@react/components/typography/Typography";

const getQuestions = () => {
  return [
    {
      content: (
        <div>
          <p>
            Staking lets you earn rewards by contributing to the Proof of Stake
            (PoS) network of a particular asset. Some crypto networks, like
            Bitcoin, use a Proof of Work (PoW) consensus mechanism, where miners
            validate transactions by solving difficult math problems. Other
            networks use a consensus mechanism known as Proof of Stake (PoS). In
            PoW networks, miners maintain consensus and secure the network,
            while PoS networks rely on participants known as validators to
            perform the same work.
          </p>
          <br />
          <p>
            Validators confirm transactions and maintain consensus on the
            network to maintain the integrity and security of the blockchain
            they’re validating. To ensure that validators are acting honestly,
            PoS networks require validators to stake a minimum amount of tokens
            native to that network. This stake can be aggregated from many
            users, not just the validator, and essentially acts as a security
            deposit – if a validator is confirming invalid transactions, then
            validators will lose part of the capital they staked. As
            compensation for their work and for the opportunity cost of capital,
            validators earn network rewards for their work. These rewards can be
            shared among their community with users who have provided additional
            stake.{" "}
          </p>
          <br />
          <p>
            Staking crypto on your own can be a challenge. At CoinList, we do
            the hard work for you. While CoinList stakes, generates, and signs
            blocks on your behalf, you retain full ownership of your tokens and
            earn rewards.
          </p>
        </div>
      ),
      title: "What is staking?",
    },
    {
      /**
       * TODO ARJUN -- make this non hardcoded
       */
      content: (
        <div>
          <p>
            Algorand (ALGO), Casper Network (CSPR), Celo (CELO), Flow (FLOW), Mina (MINA), NuCypher (NU), and Oasis (ROSE).
          </p>
        </div>
      ),
      title: "Which assets earn staking rewards on CoinList?",
    },
    {
      content: (
        <div>
          <p>
            To stake on CoinList, you must have your identity verified and
            reside in a location where staking for that asset is allowed. Visit{" "}
            <a href="/legal">coinlist.co/legal</a> for more information on our
            supported jurisdictions.
          </p>
        </div>
      ),
      title: "Who is eligible to stake on CoinList?",
    },
    {
      content: (
        <div>
          <p>
            Yes. Each network sets the underlying rewards rate depending on the
            number of staking participants. We take a commission on all staking
            rewards received. The fee ranges from 8-15% depending on the
            network. For exact fees on each network, visit{" "}
            <a href="/legal#fees">coinlist.co/legal</a> (bottom of the page).
          </p>
        </div>
      ),
      title: "Is there a fee associated with staking services?",
    },
    {
      content: (
        <div>
          <p>
            If you are a US resident, CoinList will provide you with a Form
            1099-MISC. This form reports to you and the IRS any income generated
            from activities such as airdrops, staking, referrals, etc. in excess
            of $600.You can learn more about the 1099-MISC on the official
            website of the IRS. If you are not a US resident, please consult a
            tax advisor in your jurisdiction.
          </p>
        </div>
      ),
      title: "Will I be taxed on my rewards?",
    },
  ];
};

const createOpenElementsObj = (questions) => {
  let retObj = {};
  for (let i = 0; i < questions.length; i++) {
    retObj[i] = false;
  }
  return retObj;
};

export default function FAQ(props) {
  const questions = getQuestions();
  const [openElements, setOpenElements] = useState(
    createOpenElementsObj(questions)
  );
  return (
    <div style={props.style || {}}>
      <SectionTitle title="FAQ" />
      <Flex container>
        {questions.map(({ content, title }, index) => (
          <Flex item key={index} style={{ paddingBottom: 32 }} xs={24}>
            <Flex
              alignItems="center"
              container
              onClick={() =>
                setOpenElements({
                  ...openElements,
                  [index]: !openElements[index],
                })
              }
              style={{ cursor: "pointer" }}
            >
              <i
                className={`s-fontSize16 ${
                  openElements[index]
                    ? "icon-chevron-down"
                    : "icon-chevron-right"
                }`}
                style={{ paddingRight: 8 }}
              ></i>
              <Typography fontSize={22}>{title}</Typography>
              {openElements[index] && (
                <div style={{ paddingLeft: 25, paddingTop: 10 }}>{content}</div>
              )}
            </Flex>
          </Flex>
        ))}
      </Flex>
    </div>
  );
}
