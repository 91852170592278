require('jquery-form')
require('jquery-countdown')
accounting = require('accounting')
moment = require('moment')
require('moment-timezone')

window.Investments.Shared = {
  countdown_time: ($$, $this) =>
    class CountdownTimeView extends Backbone.View
      initialize: ->
        @timeFormat = @$('.js-countdown').data('format')
        time = moment(@$('.js-countdown').data('time'))
        timeWithZone = moment.tz(time, moment.tz.guess())
        @startCountdown(timeWithZone)

      resetTime: (timeData) ->
        time = moment(timeData)
        timeWithZone = moment.tz(time, moment.tz.guess())
        @startCountdown(timeWithZone)

      startCountdown: (timeWithZone) ->
        @$('.js-countdown').countdown timeWithZone.format('M/D/YYYY, H:mm:ss'), (event) =>
          @$('.js-countdown').html(event.strftime(@timeFormat))
          if event.offset.totalSeconds <= 0
            window.location.reload()

    new CountdownTimeView el: $this

  ach_form: ($$, $this) =>
    class AchForm extends Backbone.View
      initialize: ->
        @$new_account_form  = @$('.js-new_account_form')

      events:
        'change .js-ach_option': (event) ->
          if $(event.target).val() == 'new'
            @$new_account_form.slideDown()
          else
            @$new_account_form.slideUp()
    new AchForm el: $this

  wire_selection_form: ($$, $this) =>
    class WireSelectionForm extends Backbone.View
      events: ->
        'change .js-select-bank-account': '_toggleAddBankAccount'

      _toggleAddBankAccount: ->
        if @$('.js-select-bank-account').val() == 'new'
          @$('.js-add-bank').show()
        else
          @$('.js-add-bank').hide()

    new WireSelectionForm(el: $this)

  wire_form: ($$, $this) =>
    class WireForm extends Backbone.View
      events: ->
        'change .js-select-international': '_toggleInternational'
        'change .js-canadian-bank-field': '_toggleCanadianBranchCode'

      _toggleInternational: ->
        if @$('.js-select-international').val() == 'true'
          @$('.js-usa').hide()
          @$('.js-international').show()
          @$('.js-beneficiary-address2-label').text('Account holder city')
        else
          @$('.js-usa').show()
          @$('.js-international').hide()
          @$('.js-beneficiary-address2-label').text('Account holder city, state, zip')

      _toggleCanadianBranchCode: ->
        if @$('.js-canadian-bank-field').val() == 'true'
          @$('.js-canadian-branch-code').show()
        else
          @$('.js-canadian-branch-code').hide()

    new WireForm(el: $this)

  usd_payments: ($$, $this) =>
    class UsdPaymentsForm extends Backbone.View
      initialize: ->
        @$ach_section       = @$('.js-ach_section')
        @$wire_section      = @$('.js-wire_section')

        if @$('.js-funding_method_hidden').length > 0
          @fundingMethod = @$('.js-funding_method_hidden').val()
        else
          @fundingMethod = @$('.js-funding_method:checked').val()

      getFundingMethod: ->
        @fundingMethod

      # Called from the investment form when the user's balance covers their investment
      selectWire: ->
        return if $('.js-funding_method[value="wire"]').is(':checked')

        @$('.js-funding_method[value="wire"]').prop('checked', true)
        @$('.js-funding_method').trigger('change')

      updateAmount: (amount) ->
        return if !amount? || amount == ''
        amount = accounting.unformat(amount)
        wire_method = @$el.data('wire-method')

        $usdTransferType = @$('.js-usd_transfer_type')
        $maxAchAmountExceeded = @$('.js-max_ach_amount_exceeded')
        $paymentOptions = @$('.js-payment_options')
        $wireInput = @$("input.js-funding_method[value='#{wire_method}']")

        # within max ach amount?
        maxAchAmount = @$el.data('max-ach-amount')
        withinMaxAchAmount = amount >= maxAchAmount

        $maxAchAmountExceeded.toggleClass('u-hidden', !withinMaxAchAmount)
        $paymentOptions.toggleClass('u-hidden', withinMaxAchAmount)

        # within entity balance?
        entityBalance = @$el.data('entity-balance')
        withinEntityBalance = amount <= entityBalance

        $usdTransferType.toggleClass('u-hidden', withinEntityBalance)

        # select wire method
        if (withinMaxAchAmount || withinEntityBalance) && !$wireInput.is(':checked')
          $wireInput.prop('checked', true).change()

      events:
        'change .js-funding_method': (event) ->
          # When an input gets unchecked, it also fires this event.
          return unless $(event.target).is(':checked')

          @fundingMethod = $(event.target).val()
          if @fundingMethod == 'ach'
            @$ach_section.slideDown()
            @$wire_section.slideUp()
          else
            @$ach_section.slideUp()
            @$wire_section.slideDown()

          @trigger('fundingMethodSelect', @fundingMethod)

        'click .js-create': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass 'c-button--loading'
          $target.addClass 'c-button--loading'
          @$('form').ajaxSubmit
            success: (response) =>
              window.location.href = response.redirect
            error: (response) =>
              if response.status == 422
                @$el.replaceWith response.responseJSON.html
              else
                Tooltips.formServerError($target)
    new UsdPaymentsForm el: $this
}
