window.Treasury ?= {}
window.Treasury.AchPulls = {
  new: ($$, $this) =>
    class DepositForm extends Backbone.View
      initialize: ->
        @$ach_form = $('.js-ach_section')
        @$submit_button = $('input[type="submit"]')
        @$wire_instructions = $('.js-wire_instructions')

      events:
        'change .js-funding_method': (event) ->
          if $(event.target).val() == 'wire'
            @$ach_form.slideUp => @$wire_instructions.slideDown()
            @$submit_button.prop('disabled', true)
          else
            @$wire_instructions.slideUp => @$ach_form.slideDown()
            @$submit_button.prop('disabled', false)

    new DepositForm el: $this
}
