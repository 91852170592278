accounting = require 'accounting'

window.Admin.OtcTrades =
  mark_as_completed: ($$, $this) ->
    class MarkAsCompletedView extends Backbone.View
      updateUserAmount: ->
        counterAssetAmount = accounting.unformat(@$('.js-counter_asset_amount').val())
        if !!@$('.js-fee_amount').val()
          feeAmount = accounting.unformat(@$('.js-fee_amount').val())
          @$('.js-user_amount').html("#{counterAssetAmount - feeAmount}")
        else
          feeRate = @$('.js-user_amount').data('feeRate')
          feeAmount = counterAssetAmount * feeRate
          @$('.js-user_amount').html("#{counterAssetAmount - feeAmount}")
      events:
        'input .js-counter_asset_amount': (event) ->
          @updateUserAmount()
        'input .js-fee_amount': (event) ->
          @updateUserAmount()
        'click .js-complete_otc': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass 'c-button--loading'
          $target.addClass 'c-button--loading'

          $target.closest('form').ajaxSubmit
            success: (response) =>
              window.location.reload()
            error: (response) =>
              $target.removeClass 'c-button--loading'
              Tooltips.formServerError($target)

    new MarkAsCompletedView el: $this

  index: ($$, $this) ->
    class IndexView extends Backbone.View
      events:
        'click .js-submit_otc': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass 'c-button--loading'
          $target.addClass 'c-button--loading'

          $target.closest('form').ajaxSubmit
            success: (response) =>
              window.location.reload()
            error: (response) =>
              $target.removeClass 'c-button--loading'
              Tooltips.formServerError($target)
        'click .js-start_otc': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass 'c-button--loading'
          $target.addClass 'c-button--loading'

          $target.closest('form').ajaxSubmit
            success: (response) =>
              window.location.reload()
            error: (response) =>
              $target.removeClass 'c-button--loading'
              Tooltips.formServerError($target)

    new IndexView el: $this
