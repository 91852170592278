import * as React from "react";
import { KycAppOrganization } from '@coinlist/ui-lib';
import UiLibWrapper from '../../utils/UiLibWrapper';


type KybCompanyPageProps = {
  locale: string;
  entityId: string;
}

export default function KybCompanyPage(props : KybCompanyPageProps) {  
  return (
    <UiLibWrapper locale={props.locale}>
      <KycAppOrganization
        entityId={props.entityId}
        navigateToStatusPage={() => {
          window.location.href = "/kyc/status/" + props.entityId;
        }}
      />
    </UiLibWrapper>
  );
}
