require('jquery-form')
require('./lib/keyboard')

window.Addresses = {
  address_fields: ($$, $this) ->
    class AddressFieldsView extends Backbone.View

      UNITED_STATES: 'US'

      initialize: ->
        @googlePlacesKey = @$('.js-google_places_data').data('key')
        @$autocompleteInput = @$('.js-autocomplete')
        @$stateSelectInput = @$('.js-state_us')
        @$stateTextInput = @$('.js-state_international')
        @$el.data('stateSelectInput', @$stateSelectInput)
        @$el.data('stateTextInput', @$stateTextInput)
        @$stateSelectInput.remove() if @$stateSelectInput.hasClass('u-hidden')
        @$stateTextInput.remove() if @$stateTextInput.hasClass('u-hidden')
        @country = @$('.js-country').val()
        @initializeGoogleAutocomplete()

      initializeGoogleAutocomplete: =>
        @googlePlacesAutocomplete =
          GooglePlaces.autocomplete(@$autocompleteInput, @googlePlacesKey)
        @googlePlacesAutocomplete.on 'autocompleted', (locationData) =>
          @setLocation(locationData)
          @fillAddress()

      setLocation: (locationData) =>
        @country = locationData.country || ''
        @postalCode = locationData.postalCode || ''
        @city = locationData.city || ''
        @street = locationData.street || ''
        @state = locationData.state || ''

      fillAddress: () =>
        @$('.js-street1').val(@street).change()
        @$('.js-postal_code').val(@postalCode).change()
        @$('.js-city').val(@city).change()
        @$('.js-country').val(@country).change()

        @handleStateInput()

        @$('.js-state').val(@state)

      showAddressFields: =>
        @$('.js-address_fields').slideDown()
        @$('.js-autocomplete_field').slideUp()

      clearFields: =>
        @$('.js-street1, .js-postal_code, .js-city, .js-country, .js-state').val('').change()

      getTextInput: =>
        $input = @$el.data('stateTextInput')
        $input.val(@$('.js-state').val()).removeClass('u-hidden')
        $input

      getSelectInput: =>
        $input = @$el.data('stateSelectInput')
        $input.val(@$('.js-state').val()).removeClass('u-hidden')
        $input

      handleStateInput: =>
        $currentInput = @$('.js-state')
        if @country == @UNITED_STATES && $currentInput.is('input')
          $currentInput.replaceWith @getSelectInput()
        else if @country != @UNITED_STATES && $currentInput.is('select')
          $currentInput.replaceWith @getTextInput()

      events: ->
        'input .js-country': (event) ->
          $target = $(event.currentTarget)
          @country = $target.val()
          @handleStateInput()

        'input .js-autocomplete': (event) ->
          $target = $(event.currentTarget)
          if $target.val() == ''
            @clearFields()

        'change .js-country': (event) ->
          @trigger 'countryChanged', $(event.target).val()

        'keydown .js-autocomplete': (event) ->
          @showAddressFields() if event.keyCode == Keyboard.enter

        'blur .js-autocomplete': (event) ->
          $target = $(event.currentTarget)
          @showAddressFields()

    new AddressFieldsView el: $this
}