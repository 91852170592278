import * as React from "react";
import * as ReactDOMServer from "react-dom/server";
import { useRef, useEffect } from "react";
import { Select, SelectProps } from "@react/components";
import $ from "jquery";
import "@selectize/selectize";
import { SelectizeOption } from "@react/types/ui-component";

export interface SelectizeProps extends SelectProps {
  options: SelectizeOption[];
}

export const Selectize: React.FunctionComponent<SelectProps> = (props) => {
  const selectRef = useRef();

  // This is kind hacky, but will work until we use a new/better select library
  const renderOptions = (value: string) => {
    const option = props.options.filter(
      (option) => option.value === value
    )[0] as SelectizeOption;
    const labelHtml = option.labelHtml as React.ReactElement;
    return ReactDOMServer.renderToStaticMarkup(labelHtml);
  };

  useEffect(() => {
    $(selectRef.current).selectize({
      copyClassesToDropdown: false,
      onChange: props.onChange,
      onDropdownClose: function ($dropdown) {
        $($dropdown).find(".selected").not(".active").removeClass("selected");
      },
      render: {
        option: function (item, escape) {
          return `<div class="option" data-selectable="" data-value="${
            item.value
          }" >${renderOptions(item.value)}</div>`;
        },
        item: function (item, escape) {
          return `<div class="item item--tag" data-value="${
            item.value
          }">${renderOptions(item.value)}</div>`;
        },
      },
    });
  }, []);

  return <Select ref={selectRef} {...props} />;
};

export default Selectize;
