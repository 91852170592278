window.Layouts.Algorand =

  header: ($$, $this) =>
    class HeaderView extends Backbone.View
      events:
        'click .js-nav-menu-control': (event) ->
          @$('.js-nav-menu').slideDown('fast')

        'click .js-nav-menu-close': (event) ->
          @$('.js-nav-menu').slideUp('fast')

    new HeaderView el: $this