import * as React from "react";
import { useMediaQuery } from "react-responsive";

import { Button, Col, Container, Grid } from "@react/components";
import Flex from "@react/components/Flex";

import ContactDialog from "./components/lend/ContactDialog";

const lendToCoinListLogo = require("../../../../assets/images/index/for_investors/lending/lend-to-coinlist.png");
const logo = require("../../../../assets/images/index/for_investors/lending/filecoin-borrowing-icon.png");

export default function Lend(props) {
  const [open, setOpen] = React.useState(false);

  const isSmall = useMediaQuery({ maxWidth: 900 });

  const handleContactUs = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const contactButton = (
    <Button onClick={handleContactUs} rounded variant="outlined">
      Contact Us
    </Button>
  );

  const fileCoinBorrowingText =
    "CoinList's FIL Lending Desk offers a reliable and sustainable method for Filecoin borrowers and storage providers to obtain fixed term FIL loans. CoinList's fixed loans range from 30 to 540 days in duration with no sudden changes or surprises. These loans are ideal for storage providers looking to scale up their mining operation without needing to purchase FIL on the open market.";
  const lendToCoinListText =
    "If you have any interest in lending assets to CoinList, please reach out to lend@coinlist.co. Minimum loan amounts begin at $500,000.";

  return (
    <div style={{ backgroundColor: "#FEFEFF" }}>
      <div style={{ padding: "80px 0px" }}>
        <Container maxSize="18">
          {isSmall ? (
            <Grid>
              <Col middle xs={"24"}>
                <Flex
                  container
                  justifyContent="center"
                  style={{ paddingBottom: 40 }}
                >
                  <img
                    src={logo.default}
                    style={{
                      height: 300,
                      width: 300,
                    }}
                  />
                </Flex>
              </Col>
              <Col middle xs={"24"}>
                <Flex container spacing={2}>
                  <h4 className="u-fontWeight700" style={{ color: "#361573" }}>
                    Filecoin Borrowing
                  </h4>
                  <div>{fileCoinBorrowingText}</div>
                  {contactButton}
                  <Button
                    onClick={() => (window.location.href = props.borrowFilUrl)}
                    rounded
                    variant="outlined"
                  >
                    Borrow FIL
                  </Button>
                </Flex>
              </Col>
            </Grid>
          ) : (
            <Grid>
              <Col middle xs={"12"}>
                <Flex container spacing={2}>
                  <h4 className="u-fontWeight700" style={{ color: "#361573" }}>
                    Filecoin Borrowing
                  </h4>
                  <div>{fileCoinBorrowingText}</div>
                  {contactButton}
                  <Button
                    onClick={() => (window.location.href = props.borrowFilUrl)}
                    rounded
                    variant="outlined"
                  >
                    Borrow FIL
                  </Button>
                </Flex>
              </Col>
              <Col middle xs={"12"}>
                <Flex container justifyContent="flex-end">
                  <img
                    src={logo.default}
                    style={{
                      height: 400,
                      width: 400,
                    }}
                  />
                </Flex>
              </Col>
            </Grid>
          )}
        </Container>
      </div>
      <div style={{ background: "#F9FAFF", padding: "80px 0px" }}>
        <Container maxSize="18">
          {isSmall ? (
            <Grid>
              <Col middle xs={"24"}>
                <Flex
                  container
                  justifyContent="center"
                  style={{ paddingBottom: 40 }}
                >
                  <img
                    src={lendToCoinListLogo.default}
                    style={{
                      height: 300,
                      width: 300,
                    }}
                  />
                </Flex>
              </Col>
              <Col middle xs={"24"}>
                <Flex container spacing={2}>
                  <h4 className="u-fontWeight700" style={{ color: "#361573" }}>
                    Lend to CoinList
                  </h4>
                  <div>{lendToCoinListText}</div>
                  {contactButton}
                </Flex>
              </Col>
            </Grid>
          ) : (
            <Grid>
              <Col middle xs={"12"}>
                <Flex container>
                  <img
                    src={lendToCoinListLogo.default}
                    style={{
                      height: 400,
                      width: 400,
                    }}
                  />
                </Flex>
              </Col>
              <Col middle xs={"12"}>
                <Flex container spacing={2}>
                  <h4 className="u-fontWeight700" style={{ color: "#361573" }}>
                    Lend to CoinList
                  </h4>
                  <div>{lendToCoinListText}</div>
                  {contactButton}
                </Flex>
              </Col>
            </Grid>
          )}
        </Container>
      </div>
      <div style={{ background: "#F3E8F9", padding: "80px 0px" }}>
        <Container maxSize="14">
          <Flex container justifyContent="center" spacing={2}>
            <div className="s-fontSize30 u-fontWeight700 u-text-center">
              The fullstack solution for smart crypto investors
            </div>
            <div className="u-text-center">
              CoinList is your one-stop shop for putting your crypto to work.{" "}
              {props.allSections
                ? "Get access to token launches, stake and earn, trade the best new tokens, andparticipate in DeFi."
                : "Trade the best new tokens, stake and earn, and participate in DeFi through WBTC."}
            </div>
            <div className="u-text-center">
              Contact us for white-glove, bespoke investor and institutional
              services such as API access, OTC execution, and SPV management.
            </div>
            <div>{contactButton}</div>
          </Flex>
        </Container>
      </div>
      <ContactDialog onClose={handleCloseDialog} open={open} />
    </div>
  );
}
