import { Entity, LinkedWallet, WalletLinkEntryPoint } from "./types";

export const getEntityNameByEntityId = (
  entityId: string,
  entities: Entity[]
): string => {
  if (!entities.length || !entityId) return "";

  const entity: Entity = entities.find((entity) => entity.id === entityId);

  return entity?.name ?? "";
};

// filter out linked wallets without address
export const filteredLinkedWallets = (
  linkedWallets: LinkedWallet[]
): LinkedWallet[] =>
  linkedWallets && linkedWallets.length
    ? linkedWallets.filter((wallet) => wallet.address)
    : [];

// TODO: move this util to wallet-link lib
export const generateWebsiteSource = (
  entryPoint: WalletLinkEntryPoint,
  offeringSlug?: string
): string => {
  if (!entryPoint) return "";

  const siteName = "compliance";
  const prefix = `${siteName}-${entryPoint.toLocaleLowerCase()}`;
  switch (entryPoint) {
    case WalletLinkEntryPoint.ACCOUNT:
      return `${prefix}-addresses`;
    case WalletLinkEntryPoint.DASHBOARD:
      return `${prefix}-sidebar`;
    case WalletLinkEntryPoint.OFFERING:
      return `${prefix}-${offeringSlug}`;
    default:
      return prefix;
  }
};
