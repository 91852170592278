require 'magnific-popup'
Clipboard = require('clipboard')

window.WalletAddresses.Shared =
  form: ($$, $this) ->
    class FormView extends Backbone.View
      events:
        'submit .js-wallet_address_form': (event) ->
          event.preventDefault()
          $button = @$('.js-wallet_address_form_submit')
          $button.addClass('c-button--loading')
          $form = $(event.target)
          $form.ajaxSubmit
            success: (response) =>
              window.location.href = response.redirect
            error: (response) =>
              if response.status == 422
                @$el.replaceWith response.responseJSON.form_html
              else
                Tooltips.formServerError($button)

    new FormView el: $this

  add_signed_address: ($$, $this) ->
    class AddSignedAddressView extends Backbone.View
      initialize: ->
        $('.js-add-crypto-wallet').magnificPopup({
          type: 'ajax'
        })

      events:
        'click .js-add-crypto-wallet': (event) ->
          event.preventDefault()
          $('.js-add-crypto-wallet').magnificPopup({
            type: 'ajax'
          })

      new AddSignedAddressView el: $this

  signed_address_form: ($$, $this) ->
    class SignedAddressFormView extends Backbone.View
      initialize: ->
        @$('.js-copy').each (i, element) =>
          $element = @$(element)
          return if $element.data('clipboard-instance')

          value = @$('.js-copy_text').val()
          $element.attr('data-clipboard-text', value)
          clipboard = new Clipboard($element.get(0), { container: $('.mfp-container').get(0) })
          $element.data('clipboard-instance', clipboard)
          clipboard.on 'success', (e) ->
            @$('.js-copy_text').select()
            @$('.js-copy_text').focus()
            $success = $element.closest('.js-copy-holder').find('.js-success')
            $success.show()
            setTimeout =>
              $success.fadeOut(800)
            , 400

      updateCurrencyOption: (input, label) ->
        $('.js-label').addClass('c-button--secondary')
        label.removeClass('c-button--secondary')
        if input.val() == 'ETH'
          $('.js-eth-form').show()
          $('.js-btc-form').hide()
        else
          $('.js-btc-form').show()
          $('.js-eth-form').hide()

      events:
        'change .js-input': (event) ->
          event.preventDefault()
          $target = $(event.currentTarget)
          $label = $target.closest('.js-label')
          @updateCurrencyOption($target, $label)
        'click .js-submit-crypto-wallet': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass 'c-button--loading'
          $target.addClass 'c-button--loading'
          @$('.js-errors').hide()
          $target.closest('form').ajaxSubmit
            success: (response) =>
              $target.removeClass 'c-button--loading'
              if $('.js-auto_accreditation_form').length > 0
                $('.js-auto_accreditation_form').replaceWith response.html
              else
                window.location.reload()
              $.magnificPopup.close()
            error: (response) =>
              $target.removeClass 'c-button--loading'
              if response.status == 422
                $$('.js-errors').html(response.responseJSON.error).show()
              else
                Tooltips.formServerError($target)

    new SignedAddressFormView el: $this

  asset_symbol_input: ($$, $this) ->
    class AssetSymbolInputView extends Backbone.View
      initialize: ->
        @ASSET_SYMBOL_HTML_DATA = @$el.data('assetSymbolHtmlData')
        @_initializeSelectize()

      _initializeSelectize: ->
        selectizeOptions =
          _.extend(SelectizePreset.renderHtml(@ASSET_SYMBOL_HTML_DATA))
        $baseSelect = @$('.js-asset_symbol_input').selectize(selectizeOptions)

    new AssetSymbolInputView el: $this

  address_input_select: ($$, $this) ->
    class AssetSymbolInputView extends Backbone.View
      initialize: ->
        @ADDRESS_INPUT_HTML_DATA = @$el.data('addressInputHtmlData')
        @ENTER_MANUALLY_OPTION_VALUE = @$el.data('enterManuallyOptionValue')
        @_initializeSelectize()

      getValue: ->
        @$('.js-wallet_address').val()

      _initializeSelectize: ->
        selectizeOptions =
          _.extend(SelectizePreset.renderHtml(@ADDRESS_INPUT_HTML_DATA), {allowEmptyOption: true, create: true})
        $baseSelect = @$('.js-selectize_address_input').selectize(selectizeOptions)

      events:
        'input .js-text_address_input': (e) ->
          @trigger('change', e.target.value)

        'input .js-text_address_input': (e) ->
          @trigger('change', e.target.value)

        'change .js-selectize_address_input': (e) ->
          # When selecting the "enter manually" option, we replace the select
          # input by the text input
          if e.target.value == @ENTER_MANUALLY_OPTION_VALUE
            @$('.js-inputs_wrapper').html(@$('.js-inputs_wrapper').data('textInput'))
            @$('.js-text_address_input').focus()

          @trigger('change', e.target.value)

    new AssetSymbolInputView el: $this

  table_row: ($$, $this) ->
    class TableRowView extends Backbone.View
      events:
        'click .js-destroy_wallet_address': (event) ->
          event.preventDefault()
          delete_text = 'Are you sure you want to delete this address?'
          return unless window.confirm(delete_text)
          $target = $(event.target)
          $.ajax
            url: $target.attr('href')
            type: 'DELETE'
            success: (response) ->
              $this.remove()

    new TableRowView el: $this
