import * as React from "react";
import { KycAppStatus } from '@coinlist/ui-lib';
import UiLibWrapper from '../../utils/UiLibWrapper';


type KycStatusPageProps = {
  locale: string;
  entityId: typeof KycAppStatus.defaultProps.entityId;
}

export default function KycStatusPage(props : KycStatusPageProps) {  
  return (
    <UiLibWrapper locale={props.locale}>
      <KycAppStatus
        entityId={props.entityId}
        navigateToKycHome={() => window.location.href = '/kyc'}
      />
    </UiLibWrapper>
  );
}
