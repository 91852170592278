window.Admin.Hackathons =
  form: ($$, $this) ->
    class FormView extends Backbone.View
      events:
        'click .js-logo_file_button': ->
          $('input#hackathon_logo').click()

        'change input#hackathon_logo': (event) ->
          $('.js-logo_file_label').text event.target.files[0].name

        'click .js-submit': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass 'c-button--loading'
          $target.addClass 'c-button--loading'

          @$('form').ajaxSubmit
            success: (response) =>
              window.location.href = response.redirect
            error: (response) =>
              @$el.replaceWith(response.responseJSON.html)

    new FormView el: $this
