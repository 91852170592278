import * as React from "react";

import Typography from "../typography/Typography";
import Flex from "../Flex";
import SectionTitle from "../SectionTitle";

interface HowItWorksSteps {
  header: string;
  linkElement: React.ReactNode;
  subtitleElement: React.ReactNode;
}

interface HowItWorksProps {
  steps: Array<HowItWorksSteps>;
  style?: any;
}

export default function HowItWorks(props: HowItWorksProps) {
  return (
    <Flex container style={props.style}>
      <SectionTitle style={{ paddingBottom: 24 }} title="How it works" />
      <Flex item xs={24}>
        <Flex container spacing={5}>
          {props.steps.map(
            ({ header, linkElement, subtitleElement }, index) => (
              <Flex item key={header} sm={24 / props.steps.length} xs={24}>
                <Typography
                  bold
                  fontSize={30}
                  style={{ paddingBottom: 8, width: "100%" }}
                >
                  {index + 1}
                </Typography>
                <Typography
                  bold
                  fontSize={18}
                  style={{ paddingBottom: 8, width: "100%" }}
                >
                  {header}
                </Typography>
                <Flex item style={{ paddingBottom: 8 }} xs={24}>
                  {subtitleElement}
                </Flex>
                <Flex item xs={24}>
                  {linkElement}
                </Flex>
              </Flex>
            )
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}
