atob = require('atob')
c32check = require('c32check')
isEthereumAddress = require('is-ethereum-address')
Clipboard = require('clipboard')

window.DistributionRecipientAddresses =
  ethereum_wallet_form: ($$, $this) ->
    class EthereumWalletFormView extends Backbone.View
      events:
        'change .js-wallet_name': (event) ->
          $target = $(event.target)
          useOtherWallet = $target.val() != ''
          @$('input.js-wallet_name_text').prop('disabled', useOtherWallet).val('')
          @$('.js-wallet_name_text_holder').toggle(!useOtherWallet)

        'click .js-submit': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass 'c-button--loading'

          $invalidAddress = @$('.js-invalid_address')
          $invalidAddress.hide()

          address = @$('input.js-address').val()
          if !isEthereumAddress(address)
            $invalidAddress.show()
            return

          $target.addClass 'c-button--loading'
          @$('form').ajaxSubmit
            success: (response) =>
              window.location.href = response.redirect
            error: (response) =>
              if response.status == 422
                $html = $(response.responseJSON.html)
                @$el.replaceWith $html
                Animate.niceScrollToFirstError($html)
              else
                Tooltips.formServerError($target)

    new EthereumWalletFormView el: $this

  blockstack_wallet_form: ($$, $this) ->
    class BlockstackWalletFormView extends Backbone.View
      _validAddress: (address) ->
        try
          c32check.c32addressDecode(address)
          true
        catch
          false

      _validVerificationCode: (verification_code) ->
        try
          JSON.parse(atob(verification_code.trim()))
          true
        catch
          false

      events:
        'click .js-submit': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass 'c-button--loading'

          $invalidAddress = @$('.js-invalid_address').hide()
          $invalidVerificationCode = @$('.js-invalid_verification_code').hide()

          address = @$('input.js-address').val()
          unless @_validAddress(address)
            $invalidAddress.show()
            Animate.niceScrollTo(@$('.js-wallet_address'))
            return

          verification_code = @$('textarea.js-verification_code').val()
          unless @_validVerificationCode(verification_code)
            $invalidVerificationCode.show()
            Animate.niceScrollTo(@$('.js-verification_code'))
            return

          $target.addClass 'c-button--loading'
          @$('form').ajaxSubmit
            success: (response) =>
              window.location.href = response.redirect
            error: (response) =>
              if response.status == 422
                $html = $(response.responseJSON.html)
                @$el.replaceWith $html
                Animate.niceScrollToFirstError($html)
              else
                Tooltips.formServerError($target)

    new BlockstackWalletFormView el: $this

  wallet_info: ($$, $this) ->
    class WalletInfoView extends Backbone.View
      initialize: ->
        @$('.js-copy').each (i, element) =>
          $element = @$(element)
          return if $element.data('clipboard-instance')

          value = $element.closest('.js-input').find('.js-value').text()
          $element.attr('data-clipboard-text', value)
          clipboard = new Clipboard($element.get(0))

          clipboard.on 'success', (e) ->
            $success = $element.closest('.js-copy-holder').find('.js-success')
            $success.show()
            setTimeout =>
              $success.fadeOut(800)
            , 400

          $element.data('clipboard-instance', clipboard)

    new WalletInfoView el: $this
