Partials = require('../lib/partials')

window.WalletApplications.Status =
  auto_approval: ($$, $this) ->
    class NextStepsView extends Backbone.View
      POLLING_CADENCE_IN_MILLISECONDS: 5000

      initialize: ->
        @APPROVED_STATUS = @$el.data('wallet-application-approved-status')
        @REJECTED_STATUS = @$el.data('wallet-application-rejected-status')
        @GIVE_UP_ON_AUTO_APPROVE_TIME_IN_MILLISECONDS =
          @$el.data('milliseconds-until-we-give-up-on-auto-approval')
        @STATUS_DATA_WALLET_APPLICATION_PATH =
          @$el.data('status-data-wallet-application-path')

        setInterval (=> @_pollServerForWalletApplicationData()), @POLLING_CADENCE_IN_MILLISECONDS

        # After 5 minutes, give up on auto-approve. Probably something has gone wrong
        # and the user will need to be reviewed manually.
        window.setTimeout ->
          window.location.reload()
        , @GIVE_UP_ON_AUTO_APPROVE_TIME_IN_MILLISECONDS

      _pollServerForWalletApplicationData: ->
        # poll every 5 seconds to see if the wallet application has been approved
        $.ajax
          url: @STATUS_DATA_WALLET_APPLICATION_PATH,
          method: 'GET',
          success: (response) =>
            wallet_application_status = response.wallet_application_status
            if wallet_application_status == @APPROVED_STATUS ||
               wallet_application_status == @REJECTED_STATUS
              # we have an authoritative answer about whether the wallet application will be
              # approved or rejected, so reload the page to notify the user
              window.location.reload()

            if !response.auto_approval_is_possible
              # if auto-approval is no longer possible, reload the page, so that we can tell
              # user about next steps
              window.location.reload()

    new NextStepsView el: $this
