FormWithMultipleFileUpload = require('./form_with_multiple_file_upload')
Partials = require('../lib/partials')

window.KycAmlStatuses.Companies =
  company_documentation_verification_form: ($$, $this) ->
    new FormWithMultipleFileUpload el: $this

  major_shareholder_documents_form: ($$, $this) ->
    new FormWithMultipleFileUpload el: $this

  signatory_documents_form: ($$, $this) ->
    new FormWithMultipleFileUpload el: $this
