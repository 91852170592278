require('jquery-form')
Clipboard = require('clipboard')
Partials = require('./lib/partials')

window.FilecoinInvestments =
  disambiguate: ($$, $this) ->
    class DisambiguateView extends Backbone.View
      initialize: ->
        @$form = $this.find('form')

      events:
        'click .js-select_entity_button': ->
          $$('.js-preselected_prompt').hide()
          $$('.js-entity_selector').show()

        'click .js-continue_button': ->
          @$form.submit()

    new DisambiguateView el: $this

  wallet_alternatives: ($$, $this) ->
    class WalletAlternativesView extends Backbone.View
      initialize: ->
        @unsupportedJurisdiction = @$el.data('unsupported-jurisdiction')

      events:
        'click .js-coinlist_custody_option': ->
          if @unsupportedJurisdiction
            @$('.js-unsupported_jurisdictions_disclaimer').show()

        'click .js-external_custody_option': ->
          @$('.js-unsupported_jurisdictions_disclaimer').hide()

    new WalletAlternativesView el: $this

  manual_self_custody: ($$, $this) ->
    class ManualSelfCustodyView extends Backbone.View
      initialize: ->
        @numAddressInputs = 3

      _toggleAddAddressButton: () ->
        if @numAddressInputs >= 5
          @$('.js-add_another_address').hide()
        else
          @$('.js-add_another_address').show()

      events:
        'input .js-address1_input': (event) ->
          $target = $(event.target)
          if $target.val() == ''
            @$('.js-submit').addClass 'c-button--disabled'
          else
            @$('.js-submit').removeClass 'c-button--disabled'

        'click .js-remove_address1': ->
          @$('.js-address1').hide()
          @numAddressInputs -= 1
          @_toggleAddAddressButton()

        'click .js-remove_address2': ->
          @$('.js-address2').hide()
          @numAddressInputs -= 1
          @_toggleAddAddressButton()

        'click .js-remove_address3': ->
          @$('.js-address3').hide()
          @numAddressInputs -= 1
          @_toggleAddAddressButton()

        'click .js-remove_address4': ->
          @$('.js-address4').hide()
          @numAddressInputs -= 1
          @_toggleAddAddressButton()

        'click .js-remove_address5': ->
          @$('.js-address5').hide()
          @numAddressInputs -= 1
          @_toggleAddAddressButton()

        'click .js-add_another_address': ->
          if @$('.js-address1').is(':hidden')
            @$('.js-address1').show()
          else if @$('.js-address2').is(':hidden')
            @$('.js-address2').show()
          else if @$('.js-address3').is(':hidden')
            @$('.js-address3').show()
          else if @$('.js-address4').is(':hidden')
            @$('.js-address4').show()
          else if @$('.js-address5').is(':hidden')
            @$('.js-address5').show()

          @numAddressInputs += 1
          @_toggleAddAddressButton()

    new ManualSelfCustodyView el: $this

  glif_self_custody: ($$, $this) ->
    class GlifSelfCustodyView extends Backbone.View
      initialize: ->
        @$('.js-copy').each (i, element) =>
          $element = @$(element)
          return if $element.data('clipboard-instance')
          $input_element = $element.closest('.js-address-holder').find('.js-address')

          value = $input_element.val().trim()
          $element.attr('data-clipboard-text', value)
          clipboard = new Clipboard($element.get(0))
          $element.data('clipboard-instance', clipboard)
          asset_symbol = $element.data('asset_symbol')

          clipboard.on 'success', (e) =>
            $input_element.select()
            $element.attr('title', 'Copied!')
            Analytics.trackEvent(asset_symbol + ' - Deposit Copy Button Clicked')
            options = Tooltips.presetSimple()
            options = _.extend options,
              show:
                ready: true
              hide:
                event: false
            $element.qtip(options)
            setTimeout(->
              $element.qtip('destroy', true)
            , 2000)

      events:
        'input .js-verification_code_input': (event) ->
          $target = $(event.target)
          if $target.val() == ''
            @$('.js-submit').addClass 'c-button--disabled'
          else
            @$('.js-submit').removeClass 'c-button--disabled'

    new GlifSelfCustodyView el: $this
