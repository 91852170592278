import { StateObject } from "./components/AddLoanDialog";

/**
 * @param obj - existing loan data (as stored in Rails) that needs to be normalized
 * @returns object that is used to seed AddLoanDialog initial state
 */
export const normalizeData = (obj: any): StateObject => {
  const collateral_assets = {};
  if (obj.collateral_assets) {
    obj.collateral_assets.forEach(({ amount_subunit, symbol }) => {
      collateral_assets[symbol] = amount_subunit;
    });
  }
  return {
    ...obj,
    collateral_requirement: parseFloat(obj.collateral_requirement) * 100,
    collateral_assets,
    entity_api_id: obj.entity_api_id,
    interest_rate: parseFloat(obj.interest_rate) * 100,
    margin_call_level: parseFloat(obj.margin_call_level) * 100,
    over_collateralized_level: parseFloat(obj.over_collateralized_level) * 100,
    start_date: new Date(obj.start_date),
  };
};
