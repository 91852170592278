import * as React from "react";
import { WalletStatus as WalletStatusPartial } from '@coinlist/ui-lib';
import UiLibWrapper from '../../../utils/UiLibWrapper';

export default function WalletStatus( {locale, entityId}: {locale: string, entityId: string} ) {  
  return (
    <UiLibWrapper locale={locale}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center"
        }}
      >
        <WalletStatusPartial
          entityId={entityId}
          navigateToKycHome={() => window.location.href = '/kyc'}
          navigateToKycVerification={() => window.location.href = `/kyc/person/${entityId}`}
          overrideIconSize={'60px'}
          overrideHeaderFontSize={'20px'}
          overrideSubheaderFontSize={'16px'}
        />
      </div>
    </UiLibWrapper>
  );
}
