import * as React from "react";

export const Spacings = [
  0.5,
  1,
  1.5,
  2,
  2.5,
  3,
  3.5,
  4,
  4.5,
  5,
  5.5,
  6,
  6.5,
  7,
  7.5,
  8,
  8.5,
  9,
  9.5,
  10,
  10.5,
  11,
  11.5,
  12,
  12.5,
  13,
  13.5,
  14,
  14.5,
  15,
  15.5,
  16,
];
export type SpacingsType = typeof Spacings[number];

export const RowsType = [10, 25, 50];
export type RowsPerPageType = typeof RowsType[number];

export interface SelectOption {
  labelText: string;
  value: string;
  disabled?: boolean;
  url?: string;
}

export interface SelectizeOption {
  labelText: string;
  labelHtml?: React.ReactNode;
  value: string;
  disabled?: boolean;
}
