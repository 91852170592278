import * as React from "react";
import { useState } from "react";

import Flex from "@react/components/Flex";
import { Link } from "@react/components";

export default function Description(props) {
  const [expanded, setExpanded] = useState(false);
  /**
   * Regex comes from https://stackoverflow.com/a/33202102
   */
  const fullContent = props.tokenProfile.about;
  const shortenedContent = props.tokenProfile.about.split(
    /(^.*?[a-z]{2,}[.!?])\s+\W*[A-Z]/
  )[1];
  const content = expanded ? fullContent : shortenedContent || fullContent;
  return (
    <Flex container>
      <Flex item xs={24}>
        <div style={{ display: "inline" }}>
          <div style={{ display: "inline" }}>{content}</div>
          {shortenedContent && (
            <Link onClick={() => setExpanded(!expanded)}>
              {expanded ? " Read less" : " Read more"}
            </Link>
          )}
        </div>
      </Flex>
    </Flex>
  );
}
