import * as React from "react";
import { useState } from "react";
import { AxiosResponse } from "axios";
import { Checkbox, Grid, Input, Paper, Typography } from "@mui/material";
import { RequestType, typedAxiosRequest } from "@react/utils/network";

import DateInput from "./DateInput";
import InputWrapper from "./InputWrapper";
import FormWrapper from "./FormWrapper";

import { useStyles } from "../hooks/useStyles";

export interface CreateLendingProgramFormProps<T> {
  handleSubmitResponse: (res: AxiosResponse<T>) => void;
}

export interface CreateLendingProgramResponse {
  error?: string;
  id?: string;
}

export default ({
  handleSubmitResponse,
}: CreateLendingProgramFormProps<CreateLendingProgramResponse>) => {
  const styles = useStyles();

  const [interestRate, setInterestRate] = useState<number>(0);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [minAmount, setMinAmount] = useState<number>(0);
  const [maxAmount, setMaxAmount] = useState<number>(0);
  const [displayPromo, setDisplayPromo] = useState<boolean>(false);
  const [symbol, setSymbol] = useState<string>("");
  const [currency, setCurrency] = useState<string>("");
  const [offeringId, setOfferingId] = useState<string>("");

  const submitForm = async () => {
    const requestBody = {
      interestRate,
      startDate: startDate.toISOString().split("T")[0],
      endDate: endDate.toISOString().split("T")[0],
      minAmount,
      maxAmount,
      displayPromo,
      symbol,
      currency,
      offeringId,
    };

    const response = await typedAxiosRequest<CreateLendingProgramResponse>(
      "/admin/lend/programs",
      RequestType.POST,
      requestBody
    );

    handleSubmitResponse(response);
  };

  return (
    <Paper className={styles.paper}>
      <Grid container className={styles.paperContent}>
        <Grid
          item
          xs={12}
          borderBottom={1}
          borderColor="#CCCCCC"
          className={styles.paperHeader}
        >
          <Typography
            gutterBottom
            component="div"
            className={styles.typographyPaperHeader}
          >
            Create a new lending program
          </Typography>
        </Grid>
        <FormWrapper
          className={styles.formInputWrapper}
          handleSubmit={submitForm}
          columns={2}
        >
          <InputWrapper label="Start Date">
            <DateInput handleChange={(d) => setStartDate(d)} />
          </InputWrapper>
          <InputWrapper label="End Date">
            <DateInput handleChange={(d) => setEndDate(d)} />
          </InputWrapper>
          <InputWrapper label="Interest Rate %">
            <Input
              type="number"
              value={interestRate}
              onChange={(e) => setInterestRate(+e.target.value)}
            />
          </InputWrapper>
          <InputWrapper label="Min Amount">
            <Input
              type="number"
              value={minAmount}
              onChange={(e) => setMinAmount(+e.target.value)}
            />
          </InputWrapper>
          <InputWrapper label="Max Amount">
            <Input
              type="number"
              value={maxAmount}
              onChange={(e) => setMaxAmount(+e.target.value)}
            />
          </InputWrapper>
          <InputWrapper label="Symbol">
            <Input
              type="string"
              value={symbol}
              onChange={(e) => setSymbol(e.target.value)}
            />
          </InputWrapper>
          <InputWrapper label="Currency">
            <Input
              type="string"
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
            />
          </InputWrapper>
          <InputWrapper label="Offering ID">
            <Input
              type="string"
              value={offeringId}
              onChange={(e) => setOfferingId(e.target.value)}
            />
          </InputWrapper>
          <InputWrapper label="Visible">
            <Checkbox
              checked={displayPromo}
              onChange={(e) => {
                setDisplayPromo(e.target.checked);
              }}
            />
          </InputWrapper>
        </FormWrapper>
      </Grid>
    </Paper>
  );
};
