import * as React from "react";
import { useState } from "react";

import { Label, Link } from "@react/components";
import PaymentToolBar from "./components/PaymentToolBar";
import TableWithTitle from "@react/views/shared/TableWithTitle";

import { getTextForPlacedLoanPaymentState, roundFloatStr } from "@react/utils";
import { getFormattedTime } from "@react/utils/date";

const FILTER_BUTTONS = [
  { state: "all", title: "Clear Filters" },
  { state: "completed", title: "Completed" },
  { state: "active", title: "Upcoming" },
  {
    state: "awaiting_payment",
    title: "Overdue",
  },
  {
    state: "cancelled",
    title: "Cancelled",
  },
];

const rowsForTable = (payments) => {
  return payments.map((payment) => [
    <Link href={payment.placed_loan_url}>{payment.placed_loan_id}</Link>,
    <Link href={payment.placed_loan_url}>{payment.common_loan_number}</Link>,
    <Link href={payment.entity_url}>{payment.entity_name}</Link>,
    `${roundFloatStr(payment.amount_subunit, 4)} ${payment.symbol}`,
    getFormattedTime(payment.due_date),
    getTextForPlacedLoanPaymentState(payment),
  ]);
};

const createFilters = (filterButtons) => {
  let filters = {};
  filterButtons.forEach((button) => (filters[button.state] = false));
  return filters;
};

export default function Payments(props) {
  const [rows, setRows] = useState(props.payments);
  const [filters, setFilters] = useState(createFilters(FILTER_BUTTONS));

  const handleFilter = (state: string) => {
    const newFilters = { ...filters, [state]: !filters[state] };
    /* Handle show all case */
    if (
      state === "all" ||
      Object.values(newFilters).every((filter) => !filter)
    ) {
      setRows(props.payments);
      setFilters(createFilters(FILTER_BUTTONS));
    } else {
      setRows(props.payments.filter((row) => newFilters[row.state]));
      setFilters({ ...newFilters });
    }
  };

  return (
    <TableWithTitle
      headers={[
        "Placed Loan #",
        "Common Loan #",
        "Entity",
        "Amount",
        "Due Date",
        "State",
      ]}
      rows={rowsForTable(rows)}
      title={
        <PaymentToolBar
          handleFilter={handleFilter}
          filters={filters}
          filterButtons={FILTER_BUTTONS}
        />
      }
    />
  );
}
