import * as React from "react";
import { useState } from "react";
import { Button, ButtonGroup } from "@react/components";

export interface ButtonGroupSectionProps {
  // props
}

const ButtonGroupSection: React.FunctionComponent<ButtonGroupSectionProps> = (
  {
    // props
  }
) => {
  const buttonGroupExample = `<ButtonGroup>
  <Button size="small" variant="gray">Daily</Button>
  <Button size="small" variant="primary">Weekly</Button>
  <Button size="small" variant="gray">Monthly</Button>
  <Button size="small" variant="gray">Yearly</Button>
</ButtonGroup>`;
  return (
    <div>
      <div className="s-marginBottom2">
        <h2 className="s-fontSize26 s-borderBottom0_5 s-marginBottom1 u-colorGray2">
          ButtonGroup
        </h2>
        <p className="s-marginBottom0_5 u-colorGray6">Group some buttons!</p>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">Default</h3>
        <pre>
          <ButtonGroup>
            <Button size="small" variant="gray">
              Daily
            </Button>
            <Button size="small" variant="primary">
              Weekly
            </Button>
            <Button size="small" variant="gray">
              Monthly
            </Button>
            <Button size="small" variant="gray">
              Yearly
            </Button>
          </ButtonGroup>
        </pre>
      </div>
    </div>
  );
};

export default ButtonGroupSection;
