import * as React from "react";
import { DateField } from "@mui/x-date-pickers";
export interface DateInputProps {
  handleChange: (newDate: Date) => void;
}

export default ({ handleChange }: DateInputProps) => {
  return (
    <DateField<Date> onChange={(value, _) => handleChange(value)} />
  )
}
