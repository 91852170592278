require('jquery-form')
require('../lib/keyboard')
Partials = require('../lib/partials')

window.Entities.Shared =

  money_services_business_information_form: ($$, $this) ->
    class MoneyServicesBusinessInformationFormView extends Backbone.View
      NETVERIFY_MESSAGE_EVENT: 'message'
      SUCCESS_PAYLOAD: 'success'
      ERROR_PAYLOAD: 'error'
      DISABLED_BUTTON_CLASS: 'c-button--disabled'

      initialize: ->
        @coinlistNetverifyUrl = @$el.data().coinlistNetverifyUrl
        @successUrl = @$el.data().successUrl
        @errorUrl = @$el.data().errorUrl

        $(window).on @NETVERIFY_MESSAGE_EVENT, (event) => @_handleNetverifyMessageEvent(event)

      events:
        'submit .js-money_services_business_information_form': (event) ->
          event.preventDefault()
          $form = $(event.target)
          $button = @$('.js-submit')
          $form.ajaxSubmit
            success: (response) =>
              window.location.href = response.redirect
            error: (response) =>
              if response.status == 422
                @$el.replaceWith response.responseJSON.html
              else
                Rollbar.error("MSB Step Failure in JS", {status: response.status, statusText: response.statusText, responseText: response.responseText})
                Tooltips.formServerError($button)

      _submitSuccess: (transactionRef) ->
        $.get(@successUrl, {transactionReference: transactionRef})

      _submitError: (transactionRef, errorCode) ->
        $.get(@errorUrl, {transactionReference: transactionRef, errorCode: errorCode})

      _handleNetverifyMessageEvent: (event) ->
        origin = event.originalEvent.origin
        return unless origin.indexOf(@coinlistNetverifyUrl) != -1

        data = JSON.parse(event.originalEvent.data)
        if data.payload.value == @SUCCESS_PAYLOAD
          @$('.js-submit').removeClass(@DISABLED_BUTTON_CLASS)
          @_submitSuccess(data.transactionReference)

          partial_name = 'entities/people/money_services_business_information_fields'
          Partials.with @$(Partials.selector partial_name), (partial) =>
            partial.netverifySuccess()

        else if data.payload.value == @ERROR_PAYLOAD
          @_submitError(data.transactionReference, data.payload.metainfo.code)
          partial_name = 'entities/people/money_services_business_information_fields'
          Partials.with @$(Partials.selector partial_name), (partial) =>
            partial.netverifyError()

    new MoneyServicesBusinessInformationFormView el: $this


  entity_selector: ($$, $this)->
    class EntitySelectView extends Backbone.View
      initialize: ->
        @entitiesData = @$el.data('entitiesData')
        @entityUrl = @$el.data('entityUrl')
        @$('.js-entity_selector').selectize(SelectizePreset.renderHtml(@entitiesData))

      events: ->
        'change .js-entity_selector': (e) ->
          if e.target.value == 'new'
            window.location.href = @entitiesData[e.target.value].url
            return

          $target = @$(e.target)
          $.ajax
            url: @entityUrl
            method: 'POST'
            data: {entity: e.target.value}
            success: (response) =>
              @trigger 'slugChange', @entitiesData[e.target.value].slug
            error: (response) =>
              @trigger 'slugChange', @entitiesData[e.target.value].slug

    new EntitySelectView el: $this
