import * as React from "react";
import { useState } from "react";
import {
  Button,
  ButtonVariants,
  ButtonSizes,
  ButtonTypes,
} from "@react/components";

interface ButtonSectionProps {
  // props
}

const ButtonSection: React.FunctionComponent<ButtonSectionProps> = (
  {
    // props
  }
) => {
  return (
    <div>
      <div className="s-marginBottom2">
        <h2 className="s-fontSize26 s-borderBottom0_5 s-marginBottom1 u-colorGray2">
          Button
        </h2>
        <p className="s-marginBottom0_5 u-colorGray6">
          Implementation of our{" "}
          <code className="c-code c-code--inline">{"<button>"}</code> component
        </p>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">Default</h3>
        <pre>
          <Button>Default Button</Button>
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">Sizes</h3>
        {ButtonSizes.map((size, index) => (
          <div className="s-marginBottom1" key={`button-size-${index}`}>
            <pre>
              <Button size={size}>{size} Button</Button>
            </pre>
          </div>
        ))}
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">Types</h3>
        {ButtonTypes.map((type, index) => (
          <div className="s-marginBottom1" key={`button-type-${index}`}>
            <pre>
              <Button type={type}>{type} Button</Button>
            </pre>
          </div>
        ))}
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">Variants</h3>
        {ButtonVariants.map((variant, index) => (
          <div className="s-marginBottom1" key={`button-variant-${index}`}>
            <pre>
              <Button variant={variant}>{variant} Button</Button>
            </pre>
          </div>
        ))}
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">Disabled</h3>
        <pre>
          <Button disabled>disabled Button</Button>
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">Rounded</h3>
        <pre>
          <Button rounded>rounded Button</Button>
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">Loading</h3>
        <pre>
          <Button loading>loading Button</Button>
        </pre>
      </div>
    </div>
  );
};

export default ButtonSection;
