require('jquery')
require('jquery.easing')

window.AnimationPreset = {
  transitionScrollto: 350,
  transitionTime: 200,
  transitionTimeShort: 100,
  transitionEasing: 'easeInOutCirc'
}

window.Animate = {
  niceScrollToFirstError: (html) =>
    $html = $(html)
    $error = $html.find('.c-input-group--has-error,.js-error').first()
    if $error.length > 0
      Animate.niceScrollTo($error, -32)

  niceScrollTo: (target, offset=0) =>
    # If not already
    $target = $(target)

    # Animate
    window.niceScrollToAnimation = true
    $('html, body').animate {
      scrollTop: $target.offset().top + offset
    }, AnimationPreset.transitionScrollto, AnimationPreset.transitionEasing, ->
      window.niceScrollToAnimation = false

    # Sanity - Stop scroll animation if you scroll
    eventsTrigger = 'scroll.stopScroll mousedown.stopScroll DOMMouseScroll.stopScroll
     mousewheel.stopScroll keyup.stopScroll'
    $(window).on eventsTrigger, (event) ->
      if event.which > 0 or event.type == 'mousedown' or event.type == 'mousewheel'
        $('html, body').stop()
        window.niceScrollToAnimation = false
        $(window).off eventsTrigger

  highlight: (target) =>
    # If not already
    $target = $(target)

    $target.addClass('u-highlight u-highlight--highlighted')
    setTimeout( ->
      $target.removeClass('u-highlight--highlighted')
    , 2000)
}