import * as React from "react";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { AxiosResponse } from "axios";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CreateLendingProgramForm, {
  CreateLendingProgramResponse,
} from "./components/CreateLendingProgramForm";
import Confetti from "react-dom-confetti";
import ResultDialog from "./components/ResultDialog";

const timeout = (delay: number) => {
  return new Promise((res) => setTimeout(res, delay));
};

const redirectToLendingPrograms = () => {
  window.location.href = "/admin/lend/programs";
};

const confettiConfig = {
  angle: 70,
  spread: 360,
  startVelocity: 40,
  elementCount: 500,
  dragFriction: 0.04,
  duration: 3000,
  stagger: 2,
  width: "13px",
  height: "17px",
  perspective: "500px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
};

export default () => {
  const [confettiActive, setConfettiActive] = React.useState<boolean>(false);
  const [submitWasSuccess, setSubmitWasSuccess] = React.useState<boolean>(
    false
  );

  const [resultDialogOpen, setResultDialogOpen] = React.useState<boolean>(
    false
  );
  const [resultDialogHeader, setResultDialogHeader] = React.useState<string>(
    ""
  );
  const [resultDialogDetail, setResultDialogDetail] = React.useState<string>(
    ""
  );

  const doConfetti = async () => {
    setConfettiActive(true);
    await timeout(3000).then((_) => setConfettiActive(false));
  };
  const onSuccess = async (id: string) => {
    console.log("Created lending program with ID", id);
    setSubmitWasSuccess(true);
    setResultDialogHeader("Great Success!");
    setResultDialogDetail(`Lending Program ${id} created successfully!`);
    setResultDialogOpen(true);
    await doConfetti();
    //redirectToLendingPrograms();
  };
  const onFailure = (error: string) => {
    setResultDialogHeader("Ouch!");
    setResultDialogDetail(`Unable to create lending program: ${error}`);
    setResultDialogOpen(true);
  };
  const handleSubmitResponse = async (
    response: AxiosResponse<CreateLendingProgramResponse>
  ) => {
    if (response.status >= 400) {
      await onFailure(response.statusText);
      return;
    }
    const result = response.data;

    if (result.id) {
      await onSuccess(result.id);
    } else {
      await onFailure(result.error ?? "unknown error");
    }
  };
  const theme = useTheme();
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <ResultDialog
          open={resultDialogOpen}
          setOpen={setResultDialogOpen}
          header={resultDialogHeader}
          detail={resultDialogDetail}
          handleClick={() => {
            submitWasSuccess
              ? redirectToLendingPrograms()
              : setResultDialogOpen(false);
          }}
        />
        <CreateLendingProgramForm handleSubmitResponse={handleSubmitResponse} />
        <Confetti config={confettiConfig} active={confettiActive} />
      </ThemeProvider>
    </LocalizationProvider>
  );
};
