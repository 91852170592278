import * as React from "react";
import { useState } from "react";

import Flex from "@react/components/Flex";
import HighlightDialog from "./HighlightDialog";
import { Button } from "@react/components";

export default function AddHighlight(props) {
  const [open, setOpen] = useState(false);
  return (
    <Flex spacing={1}>
      <Button
        onClick={() =>
          (window.location.href = window.location.href + "/edit_highlights")
        }
        variant="gray"
      >
        Edit Highlights
      </Button>
      <Button onClick={() => setOpen(true)} variant="gray">
        Add Highlight
      </Button>
      <HighlightDialog
        createHighlightUrl={props.createHighlightUrl}
        onClose={() => setOpen(false)}
        open={open}
        symbols={props.symbols}
      />
    </Flex>
  );
}
