window.Build.Profiles =
  form: ($$, $this) ->
    class FormView extends Backbone.View
      OTHER_ROLE_TYPE = 'Other'

      events: ->
        'input .js-role_type': (event) ->
          roleType = @$('.js-role_type').val()
          @$('.js-other_role_type').toggleClass('u-hidden', (roleType != OTHER_ROLE_TYPE))

        'blur .js-github': (event) ->
          $target = @$(event.target)
          username = $target.val()
          _.each ['/', '@'], (chr) ->
            if username.indexOf(chr) >= 0
              parts = username.split(chr)
              username = _.last(parts.filter (part) -> part)
          $target.val(username)

        'click .js-submit': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass 'c-button--loading'

          $target.addClass 'c-button--loading'
          @$('form').ajaxSubmit
            success: (response) =>
              window.location.href = response.redirect_path
            error: (response) =>
              if response.status == 422
                $html = $(response.responseJSON.html)
                @$el.replaceWith $html
                Animate.niceScrollToFirstError($html)
              else
                Tooltips.formServerError($target)

    new FormView el: $this
