import * as React from "react";
import { useState } from "react";
import {
  TextInput,
  TextInputSizes,
  TextInputTypes,
  Icon,
} from "@react/components";
import InputWithAddon from "@react/views/shared/forms/InputWithAddon/InputWithAddon";

interface TextInputSectionProps {
  // props
}

const TextInputSection: React.FunctionComponent<TextInputSectionProps> = (
  {
    // props
  }
) => {

  return (
    <div>
      <div className="s-marginBottom2">
        <h2 className="s-fontSize26 s-borderBottom0_5 s-marginBottom1 u-colorGray2">
          TextInput
        </h2>
        <p className="s-marginBottom0_5 u-colorGray6">
          An implementation of{" "}
          <code className="c-code c-code--inline">{"<input>"}</code> for
          text-based input, (ie: not radio, checkbox, file, etc..)
        </p>
        <p className="s-marginBottom0_5 u-colorBlue">
          Note: If you want Label, Hint, Error, etc... use this component with{" "}
          <code className="c-code c-code--inline">
            <a href="#BlockField">{"<BlockField>"}</a>
          </code>
        </p>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">Default</h3>
        <pre>
          <TextInput />
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          With placeholder
        </h3>
        <pre>
          <TextInput placeholder="Placeholder" />
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          With value
        </h3>
        <pre>
          <TextInput value="Value" />
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          With Addon
        </h3>
        <pre>
          <InputWithAddon addon="BTC">
            <TextInput />
          </InputWithAddon>
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          With Addon on the Left
        </h3>
        <pre>
          <InputWithAddon addon="$" position="left">
            <TextInput />
          </InputWithAddon>
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">Disabled</h3>
        <pre>
          <TextInput disabled value="Value" />
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">Sizes</h3>
        {TextInputSizes.map((size, index) => (
          <div className="s-marginBottom1" key={`select-input-size-${index}`}>
            <pre>
              <TextInput placeholder={size} size={size} />
            </pre>
          </div>
        ))}
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          Input Types
        </h3>
        {TextInputTypes.map((type, index) => (
          <div className="s-marginBottom1" key={`select-input-type-${index}`}>
            <pre>
              <TextInput type={type} />
            </pre>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TextInputSection;
