import * as React from "react";
import { useState } from "react";
import { Button } from "@react/components";
import { WalletUnlinkModal } from "@coinlist/wallet-link";
import { walletLinkApi } from "./api";
import { UnlinkWalletData } from "./types";
import { SegmentReact } from "@react/analytics/segment_react";
require("@coinlist/wallet-link/dist/style.css");

const WalletUnlink: React.FC<{
  address: string;
  chainId: number;
  entityId: string;
  onSuccess?: (entityId: string) => void;
}> = ({ address, chainId, entityId, onSuccess }) => {
  const [isUnlinking, setIsUnlinking] = useState(false);
  const [unlinkingError, setUnlinkingError] = useState<Error | undefined>(
    undefined
  );
  const [walletUnlinked, setWalletUnlinked] = useState(false);

  const handleUnlinkWallet = () => {
    setIsUnlinking(true);
    setUnlinkingError(undefined);

    const data: UnlinkWalletData = {
      address,
      chainId: chainId,
      entityId,
    };

    walletLinkApi()
      .unlinkWallet(data)
      .then((res) => {
        if (res.unlinkLinkedWallet.success) {
          setWalletUnlinked(true);
          if (onSuccess) {
            onSuccess(entityId);
          }
        } else {
          setUnlinkingError(res.unlinkLinkedWallet.errors[0]);
        }
      })
      .catch((err) => {
        console.error(err);
        setUnlinkingError(err);
      })
      .finally(() => {
        setIsUnlinking(false);
      });
  };

  const handleCloseModal = () => {
    setWalletUnlinked(false);
    setUnlinkingError(undefined);
  };

  return (
    <WalletUnlinkModal
      address={address}
      isUnlinking={isUnlinking}
      isWalletUnlinked={walletUnlinked}
      onUnlinkWallet={handleUnlinkWallet}
      onClose={handleCloseModal}
      customInitButton={
        <Button size="extra-small" variant="secondary">
          Unlink
        </Button>
      }
      errorMsg={unlinkingError?.message || undefined}
      eventTracker={SegmentReact}
    />
  );
};

export default WalletUnlink;
