window.DistributionAddresses =
  form: ($$, $this) ->
    class DistributionAddressForm extends Backbone.View
      events:
        'click .js-coinlist-wallet': (event) ->
          $disable_fields = $('.js-disable')
          if event.target.checked
            $disable_fields.prop('disabled', true)
            $disable_fields.val("")
          else
            $disable_fields.prop('disabled', false)


        'click .js-address_submit': (event) ->
          event.preventDefault()
          event.stopPropagation()
          coinlist_wallet_checkbox = $('.js-coinlist-wallet')[0]
          if !coinlist_wallet_checkbox || !coinlist_wallet_checkbox.checked || window.confirm("Confirm CoinList wallet")
            $target = $(event.target)
            return if $target.hasClass 'c-button--loading'
            $target.addClass 'c-button--loading'
            @$('.js-address-form').ajaxSubmit
              success: (response) =>
                window.location.href = response.redirect
              error: (response) =>
                @$el.replaceWith(response.responseJSON.html)


    new DistributionAddressForm el: $this
