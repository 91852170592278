require('jquery')
require('underscore')
require('qtip2')

window.Tooltips =
  BASE_CLASS: 'c-tooltip'

  initialize: ->
    $.initialize '.js-tooltip_input', (html) =>
      $('.js-tooltip_input').qtip(@presetForm())

    $.initialize '.js-tooltip_simple', (html) =>
      $('.js-tooltip_simple').qtip(@presetSimple())

    $.initialize '.js-tooltip_content', (html) =>
      $('.js-tooltip_content').qtip(@presetContent())


  presetSimple: ->
    position:
      my: 'bottom center'
      at: 'top center'
      viewport: $('body')
    hide:
      fixed: true
    style:
      classes: "c-tooltip #{@BASE_CLASS}--simple"
      tip:
        border: 1
        height: 4
        width: 12

  presetContent: ->
    options = @presetSimple()
    _.extend options,
      show:
        event: 'click'
        solo: true
      hide:
        event: 'unfocus'
        distance: 500
      style:
        classes: "c-tooltip #{@BASE_CLASS}--white"
        tip:
          border: 1
          height: 4
          width: 12


  formServerError: ($target) ->
    html = """
      <i class="icon-attention"></i>
      Unexpected error.
      <a href="https://support.coinlist.co/en/support/home" target="_blank">Get help &raquo;</a>
    """
    @formError($target, html)

  formError: ($target, html) ->
    formPresent = @presetForm()
    preset = _.extend formPresent,
      style:
        classes: "#{formPresent.style.classes} #{@BASE_CLASS}--error"
        tip: formPresent.style.tip
      hide:
        event: false
      show:
        ready: true
      content:
        text: html

    $target.qtip(preset)

  presetForm: ->
    windowWidth = $(window).width()
    @formTooltipMy = if windowWidth > 768 then 'center left' else 'bottom center'
    @formTooltipAt = if windowWidth > 768 then 'center right' else 'top center'
    @formTooltipClass = if windowWidth > 768
                          "#{@BASE_CLASS}--input"
                        else
                          "#{@BASE_CLASS}--input #{@BASE_CLASS}--input_mobile"

    show: 'focus'
    position:
      my: @formTooltipMy
      at: @formTooltipAt
      adjust:
        method: 'shift none'
    hide:
      fixed: true
      event: 'blur'
    viewport: $(window)
    style:
      classes: @formTooltipClass
      tip:
        border: 1
        height: 6
        width: 12
