require('jquery-form')
require('jquery-countdown')
autosize = require('autosize')
accounting = require('accounting')
Partials = require('../lib/partials')

window.AdditionalAllocations.New = {

  confirmation: ($$, $this) ->
    class ConfirmationView extends Backbone.View
      initialize: ->
        $$('.js-edit_request').on 'click', (event)->
          $.magnificPopup.close()
          Partials.with Partials.selector('additional_allocations/new/form'), (partial) =>
            partial._removeLoadingOnRequestButton()
        $$('.js-confirm_request').on 'click', (event)->
          $button = $(event.target)
          return if $button.hasClass('c-button--loading')
          $button.addClass 'c-button--loading'
          $.magnificPopup.close()
          Partials.with Partials.selector('additional_allocations/new/form'), (partial) =>
            partial._submitForm()

    new ConfirmationView el: $this

  form: ($$, $this) ->
    class FormView extends Backbone.View
      initialize: ->
        @$request_button = @$('.js-submit-additional-allocation-form')
        @$request_button_message = @$('.js-request_button_message')
        @$amount_input = @$('input.js-committed_amount_input')

        data = @$el.data()
        @offeringName   = data.offering.name

        @minAdditionalAllocation  = data.additionalAllocation.min_amount
        @maxAdditionalAllocation  = data.additionalAllocation.max_amount

        @USDC           = data.currencies.usdc
        @USDT           = data.currencies.usdt

        @actionVerb = data.displayText.action_verb

        @_updateAmounts()

        @$request_button.on 'click', (event) =>
          event.preventDefault()
          @_showConfirmation()

      _calculateAmounts: ->
        @amount = accounting.unformat(@$amount_input.val())

      _updatePartialsWithAmounts: ->

        # update currency selector
        Partials.with Partials.selector('additional_allocations/new/payment_options'), (partial) =>
          partial.updateCurrencyAmount(@USDC, @amount)
          partial.updateCurrencyAmount(@USDT, @amount)

      _removeLoadingOnRequestButton: ->
        @$request_button.removeClass('c-button--loading')

      _updateRequestButton: ->
        @$request_button.html("#{@actionVerb} #{accounting.formatMoney(@amount)}")

        # some hacky fix to this issue:
        # https://stackoverflow.com/questions/42016113/rails-data-disable-with-re-enabling-button
        # TODO implement a better fix
        @$request_button
          .attr('data-disable-with', "#{@actionVerb} #{accounting.formatMoney(@amount)}")

        enableButton =
          (!@maxAdditionalAllocation? || @amount <= @maxAdditionalAllocation) &&
            @amount >= @minAdditionalAllocation && @amount > 0
          # TODO: can add this after docs package
          # && @$('.js-download_required_notice').length == 0

        if enableButton
          @$request_button.removeClass('c-button--disabled')
          @$request_button_message.addClass('u-hidden')
        else
          if @amount < @minAdditionalAllocation && @amount != 0
            title = "Minimum request for additional allocation in #{@offeringName} is \
                    #{accounting.formatMoney(@minAdditionalAllocation)}"
          else if @maxAdditionalAllocation? && @amount > @maxAdditionalAllocation
            title = "#{accounting.formatMoney(@amount)} exceeds the max additional allocation \
                    amount that can be requested."
          else
            title = ""

          @$request_button.addClass('c-button--disabled')
          @$request_button_message.text(title).removeClass('u-hidden')

      _updateAmounts: ->
        @_calculateAmounts()
        @_updatePartialsWithAmounts()
        @_updateRequestButton()

      _showConfirmation: ->
        $button = @$request_button

        return if $button.hasClass('c-button--disabled') || $button.hasClass('c-button--loading')
        $button.addClass 'c-button--loading'
        $('.js-confirmPurchaseAmount').html(accounting.formatMoney(@amount))
        $('.js-confirmFundingMethod').html($('input.js-funding-method').val().toUpperCase())
        @$('.js-purchaseConfirmation').show()
        $.magnificPopup.open({
          items: {
            src: @$('.js-purchaseConfirmation')
            type: 'inline'
          },
          closeOnBgClick: false,
          enableEscapeKey: false,
          showCloseBtn:false
        })

      _submitForm: ->
        $button = @$request_button
        return if $button.hasClass('c-button--disabled')

        $form = $('form.js-additional-allocation-form')
        $form.ajaxSubmit
          success: (response) =>

            window.location.href = response.redirect

          error: (response) =>
            $button.removeClass 'c-button--loading'
            if response.status == 422
              $html = $(response.responseJSON.html)
              @$el.replaceWith $html
              # hacky, had to do this because of the way the form cascades across partials
              $error = $html.find('.c-input-group--has-error,.js-error').first()
              if $error
                $error = Animate.niceScrollTo($error, 700)
            else
              Tooltips.formServerError($button)

      events: ->
        'input .js-committed_amount_input': (event) ->
          @_updateAmounts()
        'keydown input[type="text"]': (event) ->
          if event.keyCode == Keyboard.enter
            event.preventDefault()

    new FormView el: $this

  payment_options: ($$, $this) ->
    class PaymentOptionsView extends Backbone.View
      initialize: ->
        @$fundingMethodInput = @$('input.js-funding-method')
        @_selectCurrency()

      _setFundingMethod: (fundingMethod) ->
        @$fundingMethodInput.val(fundingMethod)

      _selectCurrency: ->
        currency = @$('input.js-currency:checked').val()
        @_setFundingMethod(currency)

      updateCurrencyAmount: (currency, amount) ->
        amount = Math.ceil(amount * 1000) / 1000
        amountString = if amount > 0 then "~ #{amount} #{currency.toUpperCase()}" else '&nbsp;'
        @$(".js-amount[data-currency='#{currency}']").html(amountString)

      events: ->
        'change input.js-currency': ->
          @_selectCurrency()

    new PaymentOptionsView el: $this
}

