import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Box, Typography } from '@mui/material';
import { Button } from "@react/components";
import { Divider } from "@react/components";
import CloseIcon from '@mui/icons-material/Close';
import cx from "classnames";
import makeStyles from "@mui/styles/makeStyles";
import { ICON_MAP } from '@react/shared/icons';

interface WalletModalProps {
  isOpen: boolean;
  onDecline: () => void;
  onAgree: () => void;
  protocolName: string;
}

const useStyles = makeStyles(() => ({
  closeIconButton: {
    opacity: 0.5,
    "&:hover": {
      background: "transparent",
      backgroundColor: "transparent" ,
      color: "black",
    }
  },
  dialogActions: {
    justifyContent: 'space-between',
  },
  image: {
    marginRight: 16,
  }
}));

const WalletModal: React.FC<WalletModalProps> = ({ isOpen, onDecline, onAgree, protocolName }) => {
  const classes = useStyles();

  return (
    <Dialog open={isOpen} onClose={onDecline} PaperProps={{sx:{ borderRadius: "20px" }}}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <IconButton sx={{ opacity: 0, marginRight: "0px", pointerEvents: "none" }}>
          <CloseIcon />
        </IconButton>
        <DialogTitle fontWeight={600} sx={{ flexGrow: "1", textAlign: "center" }}>Link your wallet</DialogTitle>
        <IconButton className={cx(classes.closeIconButton)} edge="end" color="inherit" onClick={onDecline} sx={{ marginRight: "0px" }}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider spacingTop={0} spacingBottom={0} />
      <DialogContent>
        <Box display="flex" alignItems="start">
          <img src={ICON_MAP.dataswapIcon} alt={protocolName} className={classes.image} />
          <Typography sx={{ maxWidth: "257px", opacity: 0.5 }}>{protocolName} will receive my wallet address and signed signature request</Typography>
        </Box>
      </DialogContent>
      <DialogActions disableSpacing={true} sx={{ paddingBottom: "16px", paddingLeft: "16px", paddingRight: "16px", gap: "8px"}} className={classes.dialogActions}>
        <Button onClick={onDecline} variant="secondary" fullWidth>Decline</Button>
        <Button id="agree-button" onClick={onAgree} variant="primary" fullWidth>Agree</Button>
      </DialogActions>
    </Dialog>
  );
}

export default WalletModal;
