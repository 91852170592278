import * as React from "react";
import { Dialog, Grid, Paper } from "@mui/material";
import Typography from "@react/components/typography/Typography";
import { Button } from "@react/components";
import { useStyles } from "../hooks/useStyles";

interface SubmitResult {
  header: string;
  detail: string;
}

export interface ResultDialogProps extends SubmitResult {
  open: boolean;
  setOpen: (boolean) => void;
  handleClick?: () => void;
}

export default ({
  handleClick,
  header,
  detail,
  open,
  setOpen,
}: ResultDialogProps) => {
  const styles = useStyles()
  return (
    <Dialog open={open}>
      <Paper className={styles.resultDialogContent}>
        <Grid container spacing={3}>
          <Grid item xs={12} borderBottom={1} borderColor="#CCCCCC">
            <Typography type="h1">{header}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography type="h5">{detail}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Button onClick={handleClick ?? (() => setOpen(false))}>Ok</Button>
          </Grid>
        </Grid>
      </Paper>
    </Dialog>
  );
};
