import {
  ApolloClient,
  createHttpLink,
  defaultDataIdFromObject,
  InMemoryCache,
} from '@apollo/client'

import { setContext } from '@apollo/client/link/context'

// TODO - find a way to populate these secrets from compliance .env file
const devApiGqlSecret = 'DEVELOPMENT_API_SECRET_DEADBEEF1'
const prodApiGqlSecret = 'TODO'

// Do the same as above but prepend with base path
const gqlUrl = new URL(window.location.origin + '/graphql')

gqlUrl.searchParams.append('no_auth_tokens', 'false')

const gqlHttpLink = createHttpLink({
  uri: gqlUrl.toString(),
  credentials: 'include',
})

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'X-Compliance-GraphQL-API-Secret':
        typeof window === 'undefined'
          ? prodApiGqlSecret
          : devApiGqlSecret
    },
  }
})

const apolloClient = new ApolloClient({
  link: authLink.concat(gqlHttpLink),
  cache: new InMemoryCache({
    dataIdFromObject(responseObject) {
      switch (responseObject.__typename) {
        case 'Asset':
          return `Asset:${responseObject.symbol}`
        default:
          return defaultDataIdFromObject(responseObject)
      }
    },
    typePolicies: {
      Query: {
        fields: {
          Asset: {
            merge: true,
          },
          Entity: {
            merge: true,
          },
        },
      },
    },
  }),
  ssrMode: true,
})

export default apolloClient