import * as React from "react";
import {
  Grid,
  Col,
  Spacer,
  Link,
  Icon,
  Button,
  Table,
} from "@react/components";
import * as moment from "moment";
import { formatCryptoMoney } from "@react/utils/money";

export interface GovernanceProposalShowResultsProps {
  proposal: any;
  results: Object;
}

export const GovernanceProposalShowResults: React.FunctionComponent<GovernanceProposalShowResultsProps> = ({
  proposal,
  results,
}) => {
  const rows = proposal.choices.map((choice) => [
    choice.value,
    results[choice.value].count,
    formatCryptoMoney(results[choice.value].weight),
  ]);
  return <Table headers={["Choice", "Count", "Weight"]} rows={rows} />;
};

export default GovernanceProposalShowResults;
