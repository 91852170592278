require('jquery-form')
require('../lib/keyboard')
Partials = require('../lib/partials')

window.Entities.Companies = {

  edit_form: ($$, $this) =>
    class EditFormView extends Backbone.View
      CONTINUE_CLASS: 'js-continue'
      DISABLED_CLASS: 'c-button--disabled'
      LOADING_CLASS = 'c-button--loading'

      initialize: ->
        @_bindIdentityDataFormListeners()
        @_bindCompanyProfileFormListeners()
        @_bindSignatoryFormListeners()
        @_bindMajorShareholdersFormListeners()
        @_bindCompanyDocumentationVerificationFormListeners()
        @_bindMajorShareholdersDocumentsFormListeners()
        @_bindSignatoryDocumentsFormListener()

      _onComplete: ($form) ->
        $section = $form.closest('.js-section')
        $section.addClass 'js-completed_section'
        $section.find('.js-completed').removeClass('u-hidden')
        $section.find('.js-section_completed_icon').removeClass('u-hidden')
        $section.find('.js-show_edit').slideUp 'fast'
        if @$('.js-completed_section.js-section:not(.js-optional)').length == @$('.js-section:not(.js-optional)').length
          @$(".#{@CONTINUE_CLASS}").removeClass @DISABLED_CLASS

      _bindIdentityDataFormListeners: ->
        Partials.with @_getIdentityDataForm(), (partial) =>
          partial.on 'completed', =>
            @_onComplete(@_getIdentityDataForm())
            Partials.with @_getIdentityDataForm(), (partial) =>
              @$('.js-company_documentation').toggle(!partial.isUnitedStates())

          # every time the content is replaced, @$el is replaced, so we have to rebind the
          # listeners to the new @$el
          partial.on 'replace', (replaceMethod) =>
            replaceMethod()
            @_bindIdentityDataFormListeners()

      _bindCompanyProfileFormListeners: ->
        Partials.with @_getCompanyProfileForm(), (partial) =>
          partial.on 'completed', =>
            @_onComplete @_getCompanyProfileForm()

          # every time the content is replaced, @$el is replaced, so we have to rebind the
          # listeners to the new @$el
          partial.on 'replace', (replaceMethod) =>
            replaceMethod()
            @_bindCompanyProfileFormListeners()

      _bindSignatoryFormListeners: ->
        Partials.with @_getSignatoryForm(), (partial) =>
          partial.on 'completed', => @_onComplete @_getSignatoryForm()

          # every time the content is replaced, @$el is replaced, so we have to rebind the
          # listeners to the new @$el
          partial.on 'replace', (replaceMethod) =>
            replaceMethod()
            @_bindSignatoryFormListeners()

      _bindMajorShareholdersFormListeners: ->
        Partials.with @_getMajorShareholdersForm(), (partial) =>
          partial.on 'completed', =>
            @_onComplete @_getMajorShareholdersForm()

          # every time the content is replaced, @$el is replaced, so we have to rebind the
          # listeners to the new @$el
          partial.on 'replace', (replaceMethod) =>
            replaceMethod()
            @_bindMajorShareholdersFormListeners()

      _bindCompanyDocumentationVerificationFormListeners: ->
        Partials.with @_getCompanyDocumentationVerificationForm(), (partial) =>
          partial.on 'completed', => @_onComplete @_getCompanyDocumentationVerificationForm()

          # every time the content is replaced, @$el is replaced, so we have to rebind the
          # listeners to the new @$el
          partial.on 'replace', (replaceMethod) =>
            replaceMethod()
            @_bindCompanyDocumentationVerificationFormListeners()

      _bindMajorShareholdersDocumentsFormListeners: ->
        Partials.with @_getMajorShareholderDocumentsForm(), (partial) =>
          partial.on 'completed', => @_onComplete @_getMajorShareholderDocumentsForm()

          # every time the content is replaced, @$el is replaced, so we have to rebind the
          # listeners to the new @$el
          partial.on 'replace', (replaceMethod) =>
            replaceMethod()
            @_bindMajorShareholdersDocumentsFormListeners()

      _bindSignatoryDocumentsFormListener: ->
        Partials.with @_getSignatoryDocumentsForm(), (partial) =>
          partial.on 'completed', => @_onComplete @_getSignatoryDocumentsForm()

          # every time the content is replaced, @$el is replaced, so we have to rebind the
          # listeners to the new @$el
          partial.on 'replace', (replaceMethod) =>
            replaceMethod()
            @_bindSignatoryDocumentsFormListener()

      _getIdentityDataForm: ->
        @$(Partials.selector 'entities/companies/identity_data_form')

      _getCompanyProfileForm: ->
        @$(Partials.selector 'entities/companies/company_profile_form')

      _getMajorShareholdersForm: ->
        @$(Partials.selector 'roles/major_shareholders_form')

      _getSignatoryForm: ->
        @$(Partials.selector 'roles/signatory_form')

      _getCompanyDocumentationVerificationForm: ->
        @$(Partials.selector 'kyc_aml_statuses/companies/company_documentation_verification_form')

      _getMajorShareholderDocumentsForm: ->
        @$(Partials.selector 'kyc_aml_statuses/companies/major_shareholder_documents_form')

      _getSignatoryDocumentsForm: ->
        @$(Partials.selector 'kyc_aml_statuses/companies/signatory_documents_form')

      events:
        'click .js-edit': (event) ->
          $target = $(event.target)
          $target.closest('.js-completed').addClass('u-hidden')
          $target.closest('.js-section').find('.js-show_edit').slideDown 'fast'

        'click .js-continue': (event) ->
          if @$(".#{@CONTINUE_CLASS}").hasClass @DISABLED_CLASS
            event.preventDefault()

    new EditFormView el: $this

  identity_data_form: ($$, $this) ->
    class IdentityDataFormView extends Backbone.View
      UNITED_STATES = 'US'
      LOADING_CLASS = 'c-button--loading'

      initialize: ->
        @$stateSelectInput = @$('.js-incorporation_state_us')
        @$stateTextInput = @$('.js-incorporation_state_international')
        @$el.data('incorporationStateSelectInput', @$stateSelectInput)
        @$el.data('incorporationStateTextInput', @$stateTextInput)
        @$stateSelectInput.remove() if @$stateSelectInput.hasClass('u-hidden')
        @$stateTextInput.remove() if @$stateTextInput.hasClass('u-hidden')
        @country = @$('.js-country').val()

      getTextInput: =>
        $input = @$el.data('incorporationStateTextInput')
        $input.val(@$('.js-incorporation_state').val()).removeClass('u-hidden')
        $input

      getSelectInput: =>
        $input = @$el.data('incorporationStateSelectInput')
        $input.val(@$('.js-incorporation_state').val()).removeClass('u-hidden')
        $input

      handleStateInput: =>
        $currentInput = @$('.js-incorporation_state')
        if @country == UNITED_STATES && $currentInput.is('input')
          $currentInput.replaceWith @getSelectInput()
        else if @country != UNITED_STATES && $currentInput.is('select')
          $currentInput.replaceWith @getTextInput()

      isUnitedStates: =>
        @$('.js-incorporation_country').val() == UNITED_STATES

      events:
        'input .js-incorporation_country': (event) ->
          $target = $(event.currentTarget)
          @country = $target.val()
          @handleStateInput()

        'click .js-save': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass @LOADING_CLASS
          $target.addClass @LOADING_CLASS
          $target.closest('form').ajaxSubmit
            success: (response) =>
              @trigger 'replace', =>
                @$el.replaceWith response.html
              @trigger 'completed'
            error: (response) =>
              if response.status == 422
                @trigger 'replace', =>
                  @$el.replaceWith response.responseJSON.html
              else
                $target.removeClass @LOADING_CLASS
                Tooltips.formServerError($target)

    new IdentityDataFormView el: $this


  company_profile_form: ($$, $this) =>
    class CompanyProfileFormView extends Backbone.View
      LOADING_CLASS = 'c-button--loading'

      events: ->
        'click .js-save': (event) ->
          # All modern browsers support form validation tooltips on submit, just have to prevent
          # from submission to go through
          if !($(".c-form.questionnaire")[0].checkValidity())
            return
          else

          event.preventDefault()

          $target = $(event.target)
          return if $target.hasClass(@LOADING_CLASS)
          $target.addClass(@LOADING_CLASS)

          @$('form').ajaxSubmit
            success: (response) =>
              @trigger 'replace', =>
                @$el.replaceWith response.html
              @trigger 'completed'
            error: (response) =>
              $target.removeClass(@LOADING_CLASS)
              Tooltips.formServerError($target)

    new CompanyProfileFormView el: $this

  new_form: ($$, $this) ->
    class NewFormView extends Backbone.View
      events:
        'keydown input[type="text"]': (event) ->
          if event.keyCode == Keyboard.enter
            event.preventDefault()
    new NewFormView el: $this

  accreditation_form: ($$, $this) ->
    class AccreditationForm extends Backbone.View
      # TODO ideally this would be in sync with the UI
      ALL_OWNERS_ACCREDITED: 'all_owners_accredited'
      ALL_OWNERS_QUALIFIED_PURCHASER: 'all_owners_qualified_purchaser'
      ASSETS: 'assets'
      LOADING_CLASS = 'c-button--loading'

      getBasis: ->
        @$('.js-basis input:checked').val()

      replaceMultipleUploadForms: ->
        Partials.with Partials.selector('components/multiple_file_upload'), (partial) =>
          htlm = @multipleFileUploadForms[partial.uniqIndex()]
          if htlm.length != 0 && partial.$el.find('.js-form_error').length == 0
            htlm.find('.js-form_error').remove()
            partial.$el.replaceWith htlm

      saveMultipleFileUploadsForms: ->
        @multipleFileUploadForms = {}
        Partials.with Partials.selector('components/multiple_file_upload'), (partial) =>
          @multipleFileUploadForms[partial.uniqIndex()] = partial.$el.clone()

      events:
        'click .js-create': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass @LOADING_CLASS
          $target.addClass @LOADING_CLASS

          @saveMultipleFileUploadsForms()

          basis = @getBasis()
          if basis == @ALL_OWNERS_ACCREDITED || basis == @ALL_OWNERS_QUALIFIED_PURCHASER
            partialSelector =
              Partials.selector 'entities/companies/acts_like_company_all_owners_qualifies_form'
            # Submit the correct form
            partialSelector = "#{partialSelector}.js-#{basis}"
            Partials.with @$(partialSelector), (form) =>
              form.submit().catch (response) =>
                if response.status == 422
                  $target.removeClass @LOADING_CLASS
                  @replaceMultipleUploadForms()
                else
                  Tooltips.formServerError($target)

          else if basis == @ASSETS
            partialSelector = Partials.selector 'entities/companies/acts_like_company_assets_form'
            Partials.with @$(partialSelector), (form) =>
              form.submit().catch (response) =>
                if response.status == 422
                  $target.removeClass @LOADING_CLASS
                  @replaceMultipleUploadForms()
                else
                  Tooltips.formServerError($target)

        'change .js-basis': (event) ->
          $target = $(event.target)
          basis = $target.val()
          $activeBasis = @$(".js-#{basis}_content")
          if $activeBasis.is(':hidden')
            @$('.js-basis_content').hide()
            @$('.js-basis').removeClass 'c-tab__item--active'
            $target.addClass 'c-tab__item--active'
            $activeBasis.show()

    new AccreditationForm el: $this

  acts_like_company_assets_form: ($$, $this) ->
    class ActsLikeCompanyAssetsFormView extends Backbone.View

      submit: ->
        deferred = $.Deferred()

        @$('form').ajaxSubmit
          success: (response) =>
            window.location.href = response.redirect
            deferred.resolve()
          error: (response) =>
            if response.status == 422
              $html = $(response.responseJSON.html)
              @$el.replaceWith($html)
              Animate.niceScrollToFirstError($html)
            deferred.reject(response)

        deferred.promise()

    new ActsLikeCompanyAssetsFormView el: $this

  acts_like_company_all_owners_qualifies_form: ($$, $this) ->
    class ActsLikeCompanyAllOwnersQualifiesFormView extends Backbone.View

      submit: ->
        deferred = $.Deferred()

        @$('form').ajaxSubmit
          success: (response) =>
            window.location.href = response.redirect
            deferred.resolve()
          error: (response) =>
            if response.status == 422
              $html = $(response.responseJSON.html)
              @$el.replaceWith($html)
              Animate.niceScrollToFirstError($html)
            deferred.reject(response)

        deferred.promise()

    new ActsLikeCompanyAllOwnersQualifiesFormView el: $this
}

