import * as React from "react";
import { Selectize } from "@react/components";

export interface EntitySelectorProps {
  selectedEntity: any;
  entities: any[];
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
}

const EntitySelector: React.FunctionComponent<EntitySelectorProps> = ({
  selectedEntity,
  entities,
  onChange,
}) => {
  const renderLabelHtml = (entity) => {
    return (
      <div className="u-textOverflow u-displayInlineBlock u-verticalAlignMiddle u-flex1 u-fontWeight700 s-fontSize16">
        {entity.name}
        <div className="u-colorGrayA u-fontWeight400 s-fontSize14 u-textOverflow">
          {entity.entity_type_for_display}
        </div>
      </div>
    );
  };

  const optionsSelectize = entities.map((entity) => {
    return {
      labelText: entity.name,
      labelHtml: renderLabelHtml(entity),
      value: entity.slug,
    };
  });

  return (
    <Selectize
      options={optionsSelectize}
      value={selectedEntity.slug}
      onChange={onChange}
    />
  );
};

export default EntitySelector;
