import * as React from "react";
import { Link, Icon, Label, Divider } from "@react/components";
import EntitySelector from "@react/views/shared/forms/EntitySelector/EntitySelector";
import { getFormattedTimeLong } from "@react/utils/date";
import { capitalize } from "@react/utils/string";
import { formatCryptoMoney } from "@react/utils/money";
import { SetValue } from "@react/hooks/useLocalStorage";
import GovernanceProposalForm from "@react/views/governance/proposals/show/form";
import SnapshotResults from "@react/views/governance/proposals/show/snapshot_results";
import * as moment from "moment";

export interface GovernanceProposalsShowSidebarProps {
  protocol: any;
  proposal: any;
  proposal_results: any;
  governance_proposal_path?: string;
  entity: any;
  entity_can_vote: boolean;
  entity_voting_power_balance: any;
  voting_entities?: any;
  governance_faq_path: string;
  currentVote?: string;
  setCurrentVote?: SetValue<string>;
  initialVote?: string;
  setInitialVote?: SetValue<string>;
  votingTermRef: React.MutableRefObject<HTMLDivElement>;
}

const GovernanceProposalsShowSidebar: React.FunctionComponent<GovernanceProposalsShowSidebarProps> = ({
  protocol,
  proposal,
  proposal_results,
  governance_proposal_path,
  entity,
  entity_can_vote = false,
  entity_voting_power_balance,
  voting_entities,
  governance_faq_path,
  currentVote,
  setCurrentVote,
  initialVote,
  setInitialVote,
  votingTermRef,
}) => {
  const classByState = {
    created: "u-colorOrange",
    published: "u-colorGreen",
    closed: "u-colorGray8",
    hidden: "u-colorGrayA",
  };

  const protocolSection = () => {
    return (
      <div>
        <Label>Protocol</Label>
        <div className="u-displayFlex u-alignItemsCenter">
          <div className="c-avatar c-avatar--extra_small">
            <img
              className="governance-protocols-shared-sidebar__asset_logo"
              alt={protocol.token_profile.name}
              title={protocol.token_profile.name}
              src={protocol.token_profile.logo_url}
            />
          </div>
          <div className="u-flex1 s-paddingLeft1">
            <h2 className="s-fontSize18 u-fontWeight700">
              {protocol.token_profile.name}
              <span className="u-colorGrayA u-fontWeight400 s-marginLeft0_5">
                {protocol.token_profile.symbol}
              </span>
            </h2>
          </div>
        </div>
      </div>
    );
  };

  const proposalSection = () => {
    return (
      <>
        <div className="s-marginTop1">
          <Label>Proposal</Label>
          <div>{proposal.title}</div>
          {protocol.snapshot_integration_id &&
            proposal.snapshot_integration_id && (
              <p className="s-fontSize14 s-marginTop0_5">
                <Link
                  href={`https://snapshot.org/#/${protocol.snapshot_integration_id}/proposal/${proposal.snapshot_integration_id}`}
                  target="_blank"
                  muted
                >
                  ⚡ View on Snapshot
                </Link>
              </p>
            )}
        </div>
        <div className="s-marginTop1">
          <Label>State</Label>
          <div className={classByState[proposal.state]}>
            {capitalize(proposal.state)}
          </div>
        </div>
        <div className="s-marginTop1">
          <Label>Start at</Label>
          <div>{getFormattedTimeLong(proposal.start_at)}</div>
        </div>
        <div className="s-marginTop1">
          <Label>End at</Label>
          <div>{getFormattedTimeLong(proposal.end_at)}</div>
        </div>
      </>
    );
  };

  const handleOnChange: React.ChangeEventHandler<HTMLSelectElement> = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    window.location.href = `${governance_proposal_path}/${event}`;
  };
  const entitySection = () => {
    const initialVoteIsString = typeof initialVote === "string";
    return (
      <>
        <div className="s-marginTop1">
          <Label>Voting as</Label>
          {voting_entities.length == 1 && (
            <div className="u-textOverflow u-displayInlineBlock u-verticalAlignMiddle u-fontWeight700 s-fontSize16">
              {entity.name}
              <span className="u-colorGrayA u-fontWeight400 s-marginLeft0_5">
                {entity.entity_type_for_display}
              </span>
            </div>
          )}
          {voting_entities.length > 1 && (
            <EntitySelector
              entities={voting_entities}
              selectedEntity={entity}
              onChange={handleOnChange}
            />
          )}
        </div>
        {entity_voting_power_balance && (
          <div className="s-marginTop1">
            <Label>Voting power</Label>
            <div>{formatCryptoMoney(entity_voting_power_balance)}</div>
          </div>
        )}

        {initialVote.length > 0 && (
          <div className="s-marginTop1">
            <Label>Your current vote</Label>
            <div>
              {initialVoteIsString
                ? initialVote
                : (initialVote as string[]).join(", ")}
            </div>
          </div>
        )}
      </>
    );
  };

  const showResults =
    moment(proposal.end_at).isBefore() &&
    Object.keys(proposal_results).length != 0;
  return (
    <div className="c-box s-padding1_5">
      {protocolSection()}
      {proposalSection()}
      {entity && (
        <>
          <Divider />
          {entitySection()}
        </>
      )}
      {!protocol.egl && (
        <div className="s-marginTop1">
          <Divider />
          {showResults && (
            <>
              <Label>Results</Label>
              <div className="s-marginTop0_5">
                <SnapshotResults
                  proposal={proposal}
                  proposal_results={proposal_results}
                />
              </div>
            </>
          )}
          {!showResults && (
            <>
              <Label>Vote</Label>
              <GovernanceProposalForm
                proposal={proposal}
                protocol={protocol}
                votingTermRef={votingTermRef}
                entity={entity}
                entity_can_vote={entity_can_vote}
                governance_faq_path={governance_faq_path}
                currentVote={currentVote}
                setCurrentVote={setCurrentVote}
                initialVote={initialVote}
                setInitialVote={setInitialVote}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default GovernanceProposalsShowSidebar;
