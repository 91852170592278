crypto = require 'crypto'

window.FilecoinInvestments.Custody = {
  self_with_glif: ($$, $this) ->
    class SelfWithGlifView extends Backbone.View
      initialize: ->
        @investorIdHash = @$el.data('investorIdHash')

        hexString = $('.js-verification_code').text().trim()
        [vals, hash] = Buffer.from(hexString, 'hex').toString('utf8').split(':')
        [address, investor_hash] = vals.split(',')
        if @investorIdHash == investor_hash
          $('.js-address').text address
        else
          $('.js-address_error').show()

    new SelfWithGlifView el: $this
}