require('jquery-form')
require('../lib/keyboard')
require('../lib/animation')
accounting = require('accounting')
autosize = require('autosize')
Partials = require('../lib/partials')

window.AllocationRequests.New = {
  form: ($$, $this) =>
    class FormView extends Backbone.View
      initialize: ->
        @currency          = @$('.js-currency:checked').val()

        @USD               = @$('.js-data-currency').data('usd')
        @BTC               = @$('.js-data-currency').data('btc')
        @ETH               = @$('.js-data-currency').data('eth')
        @USDC              = @$('.js-data-currency').data('usdc')

        @fundingMethod     = @$('.js-funding_method_input').val()
        @amount            = accounting.unformat(@$('.js-requested_amount_input').val())

        if @$('.js-currency')
          @currency = @$('.js-currency').val()
        else
          Partials.with Partials.selector('allocation_requests/new/currency_input'), (partial) =>
            @currency = partial.getCurrency()
            @updateDisplay()

            partial.on 'currencyUpdated', (currency) =>
              @currency = currency
              @updateDisplay()

        @updateDisplay()

        Partials.with Partials.selector('investments/shared/usd_payments'), (partial) =>
          partial.on 'fundingMethodSelect', (fundingMethod) =>
            @updateDisplay()

      updateHiddenInput: ->
        @fundingMethod = @currency
        @$('.js-funding_method_input').val(@fundingMethod)

      updateDisplay: ->
        @updateHiddenInput()

      submit: ->
        $target = @$('.js-create')
        return if $target.hasClass 'c-button--loading'
        $target.addClass 'c-button--loading'
        @$('form').ajaxSubmit
          success: (response) =>
            window.location.href = response.redirect
          error: (response) =>
            if response.status == 422
              $html = $(response.responseJSON.html)
              @$el.replaceWith $html
              Animate.niceScrollToFirstError($html)
            else
              Tooltips.formServerError($target)
      events:
        'change .js-must_be_accredited': (event) ->
          if @$('.js-must_be_accredited:checked').val() == 'true'
            @$('.js-accreditation').slideDown 'fast'
          else
            @$('.js-accreditation').slideUp 'fast'

        'change .js-currency': (event) ->
          $target = $(event.target)
          @currency = $target.val()
          @updateDisplay()

        'keydown .js-requested_amount_input': (event) ->
          if event.keyCode == Keyboard.enter
            event.preventDefault()
            @submit()

        'input .js-requested_amount_input': (event) ->
          $target = $(event.target)
          @amount = accounting.unformat($target.val())
          @updateDisplay()

        'click .js-create': (event) ->
          event.preventDefault()
          @submit()


    new FormView el: $this

  currency_input: ($$, $this) =>
    class CurrencyInputView extends Backbone.View
      initialize: ->
        @currency = @$('.js-currency:checked').val()

      getCurrency: ->
        @currency = @$('.js-currency:checked').val()
        @currency

      events:
        'click .js-element': (event) ->
          event.preventDefault()

        'change .js-currency': (event) ->
          event.preventDefault()
          $target = $(event.currentTarget)
          @trigger('currencyUpdated', $target.val())

    new CurrencyInputView el: $this

  proof_of_assets: ($$, $this) =>
    class ProofOfAssetsView extends Backbone.View
      initialize: ->
        autosize(@$('.js-signature_input'))

      showBtc: ->
        @$('.js-btc').show()
        @$('.js-eth').hide()

      showEth: ->
        @$('.js-btc').hide()
        @$('.js-eth').show()

      updateHint: (amount, currency, btcAmount, ethAmount) ->
        if amount.length == 0
          @$('.js-btc_address').qtip('destroy', true)
          @$('.js-eth_address').qtip('destroy', true)
        else
          btcMessage = "This wallet should have a balance of at least #{btcAmount} BTC"
          ethMessage = "This wallet should have a balance of at least #{ethAmount} ETH"

          @$('.js-btc_address').attr('title', btcMessage).qtip(Tooltips.presetForm())
          @$('.js-eth_address').attr('title', ethMessage).qtip(Tooltips.presetForm())

    new ProofOfAssetsView el: $this
}
