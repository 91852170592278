import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, Theme, StyledEngineProvider } from "@mui/material/styles";
import { theme } from "../../../../utils/theme";

import PlacedLoan from "./components/PlacedLoan";


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


export default function PlacedLoansParent(props) {
  return (
    <React.Fragment>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <PlacedLoan {...props} />
        </ThemeProvider>
      </StyledEngineProvider>
    </React.Fragment>
  );
}
