import * as React from "react";
import { Stepper } from "@coinlist/frontend-ui-lib"
require("@coinlist/frontend-ui-lib/dist/style.css");

export default function StepperUI({ steps, activeStep }) {
    return (
        <Stepper activeStep={activeStep}>
            {steps.map((step, index) => (
                <Stepper.Step key={index} step={index}>
                    {step}
                    {index < steps.length - 1 && <Stepper.Separator step={index} />}
                </Stepper.Step>
            ))}
        </Stepper>
    );
}
