window.Hackathons.Projects.AddDetails =
  tags: ($$, $this) ->
    class TagsView extends Backbone.View
      events: ->
        'click .js-add-tag': (event) ->
          event.preventDefault()
          $target = $(event.target)

          $.ajax
            url: $target.attr('data-url')
            method: 'PATCH'
            success: (response) =>
              @$el.replaceWith(response.html)

        'click .js-remove-tag': (event) ->
          event.preventDefault()
          $target = $(event.target)

          $.ajax
            url: $target.attr('data-url')
            method: 'DELETE'
            success: (response) =>
              @$el.replaceWith(response.html)

    new TagsView el: $this
