import * as React from "react";

import Box from "@react/components/Box";
import Typography from "@react/components/typography/Typography";
import Flex from "@react/components/Flex";
import { Button } from "@react/components";
import SectionTitle from "@react/components/SectionTitle";

import { LOGO_MAP } from "../../../../shared/logos";
import { roundFloat } from "../../../../utils";

interface RewardsProps {
  registerLink: string;
  stakingAssets: Array<any>;
  style?: any;
}

export default function Rewards(props: RewardsProps) {
  const stakingAssets = props.stakingAssets;
  return (
    <Flex container style={props.style}>
      <SectionTitle
        style={{ paddingBottom: 16 }}
        subtitle="Get started by staking any of the following assets in less than five
          minutes."
        title="Earn more by holding assets that generate rewards"
      />
      <Flex item xs={24}>
        <Flex container spacing={2}>
          {stakingAssets.map(({ apy, symbol }) => {
            /**
             *  TODO ARJUN - extremely bad hack to fix flex box grid
             */
            if (!symbol) {
              return <Flex item xs={8}></Flex>;
            }
            return (
              <Flex item key={symbol} xs={8}>
                <Box style={{ padding: 16 }}>
                  <Flex container spacing={2}>
                    <Flex alignItems="center" container justifyContent="center">
                      <img
                        src={LOGO_MAP[symbol.toLowerCase()]}
                        style={{ height: 28, marginRight: 5, width: 28 }}
                      />
                      <Typography
                        bold
                        fontSize={20}
                        noBreak
                        style={{ textAlign: "center" }}
                      >
                        {symbol.toUpperCase()}
                      </Typography>
                    </Flex>
                    <Flex container justifyContent="center">
                      <Typography
                        bold
                        color="primary"
                        noBreak
                        style={{ textAlign: "center" }}
                      >
                        Yearly rewards{" "}
                        {apy
                          ? `up to ${roundFloat(parseFloat(apy) * 100, 2)}%`
                          : "TBD"}
                      </Typography>
                    </Flex>
                    <Flex container justifyContent="center">
                      <Button
                        onClick={() =>
                          (window.location.href = props.registerLink)
                        }
                        size="small"
                      >
                        Stake
                      </Button>
                    </Flex>
                  </Flex>
                </Box>
              </Flex>
            );
          })}
        </Flex>
      </Flex>
    </Flex>
  );
}
