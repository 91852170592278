import * as React from "react";
import { useState } from "react";
import { Select, SelectSizes, Selectize } from "@react/components";

interface SelectizeSectionProps {
  // props
}

const SelectizeSection: React.FunctionComponent<SelectizeSectionProps> = (
  {
    // props
  }
) => {
  const optionsSelectize = [
    {
      labelText: "Paul",
      labelHtml: (
        <p>
          <i className="icon-ok u-colorBrand s-marginRight0_5"></i>
          <b>Hi Paul,</b> How's it going?
        </p>
      ),
      value: "apple",
    },
    {
      labelText: "Paul",
      labelHtml: (
        <p>
          <i className="icon-clock u-colorBlue s-marginRight0_5"></i>
          <em>Very well</em> how about you?
        </p>
      ),
      value: "pear",
    },
  ];

  const selectizeExample = `const optionsSelectize = [
  {
    labelText: "Paul",
    labelHtml: (
      <p>
        <i className="icon-ok u-colorBrand s-marginRight0_5"></i>
        <b>Hi Paul,</b> How's it going?
      </p>
    ),
    value: "apple",
  },
  {
    labelText: "Paul",
    labelHtml: (
      <p>
        <i className="icon-clock u-colorBlue s-marginRight0_5"></i>
        <em>Very well</em> how about you?
      </p>
    ),
    value: "pear",
  },
];

return (
  <Selectize options={optionsSelectize} />
)
`;

  return (
    <div>
      <div className="s-marginBottom2">
        <h2 className="s-fontSize26 s-borderBottom0_5 s-marginBottom1 u-colorGray2">
          Selectize
        </h2>
        <p className="s-marginBottom0_5 u-colorGray6">
          An implementation of <a href="https://selectize.dev/">selectize.js</a>{" "}
          for select input with custom markup.
        </p>
        <p className="s-marginBottom0_5 u-colorBlue">
          Note: If you want Label, Hint, Error, etc... use this component with{" "}
          <code className="c-code c-code--inline">
            <a href="#BlockField">{"<BlockField>"}</a>
          </code>
        </p>
      </div>

      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          Selectize Implementation
        </h3>
        <pre>
          <Selectize options={optionsSelectize} />
        </pre>
      </div>
    </div>
  );
};

export default SelectizeSection;
