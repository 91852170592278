import * as React from "react";

import { Divider } from "@react/components";
import Flex from "@react/components/Flex";

import Typography from "@react/components/typography/Typography";

export default function Disclaimer(props) {
  return (
    <Flex container>
      <Divider spacingBottom={2} />
      <Typography italic>
        Disclaimer: Nothing on this page including, but not limited to, the
        highlighted projects should be construed to be a recommendation,
        endorsement, or investment advice. Highlighted projects are selected
        based on internal considerations which may be unrelated to the project’s
        respective market and are subject to change at CoinList’s sole
        discretion and without any notice to you. CoinList may have received
        compensation in connection with certain offerings made by this protocol.
        CoinList expressly disclaims any and all liability for content
        controlled by third parties.
      </Typography>
    </Flex>
  );
}
