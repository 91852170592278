import * as React from "react";

import BlockField from "@react/views/shared/forms/BlockField/BlockField";
import { Button } from "@react/components";
import Flex from "@react/components/Flex";
import { TextInput } from "@react/components";

interface Button {
  state: string;
  title: string;
}

const FILTER_BUTTONS: Array<Button> = [
  { state: "all", title: "Clear Filters" },
  { state: "completed", title: "Completed" },
  { state: "on_going", title: "In Progress" },
  { state: "cancelled", title: "Cancelled" },
  { state: "awaiting_collateral_payment", title: "Awaiting Collateral" },
];

export default function PlacedLoanTableToolbar(props) {
  const { filters } = props;
  const [entitySearch, setEntitySearch] = React.useState("");

  const handleOnChange = (e) => {
    const value = e.currentTarget.value;
    setEntitySearch(value);
  };

  const handleOnKeyPress = (e) => {
    const keyCode = e.code || e.key;
    if (keyCode == "Enter") {
      props.setSearchValue(entitySearch);
    }
  };

  return (
    <Flex container spacing={2} style={{ padding: 10 }}>
      <Flex alignItems="center" container justifyContent="space-between">
        All Loans
        <span className="s-marginLeft1">
          <Button onClick={props.handleAddLoan} size="extra-small">
            Add New Loan
          </Button>
        </span>
      </Flex>
      <Flex item xs={24}>
        <Flex alignItems="flex-end" container spacing={1}>
          <Flex item xs={8}>
            <BlockField label="Search by Entity Name">
              <TextInput
                onChange={handleOnChange}
                onKeyPress={handleOnKeyPress}
                value={entitySearch}
              />
            </BlockField>
          </Flex>
          <Flex item xs={16}>
            <Flex container justifyContent="flex-end" spacing={1}>
              {FILTER_BUTTONS.map((button, index) => (
                <span className="ml-2" key={index}>
                  <Button
                    key={button.title}
                    onClick={() => props.handleFilter(button.state)}
                    size="extra-small"
                    variant={filters[button.state] ? "purple" : "gray"}
                  >
                    {button.title}
                  </Button>
                </span>
              ))}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
