import * as React from "react";
import { useState } from "react";

import MoreOptionsDialog from "../../staking/components/MoreOptionsDialog";
import StakingBox from "@react/components/staking/StakingBox";
import StakeDialog from "@react/components/dialogs/StakeDialog";
import UnstakeDialog from "../../staking/components/UnstakeDialog";

import {
  caseInsensitiveStringComparison,
  createWalletCells,
  isTestEnv,
} from "@react/utils";
import { SegmentReact } from "@react/analytics/segment_react";

const createAsset = (stakingAssets, tokenProfile) => {
  return stakingAssets.find((asset) =>
    isTestEnv()
      ? caseInsensitiveStringComparison(asset.symbol, "teth")
      : caseInsensitiveStringComparison(asset.symbol, tokenProfile.symbol)
  );
};

export default function StakingBoxAssetPage(props) {
  const [stakingAssets, setStakingAssets] = useState(() => props.stakingMap);
  const [asset, setAsset] = useState(() =>
    createAsset(stakingAssets, props.tokenProfile)
  );
  const [moreOptionsOpen, setMoreOptionsOpen] = useState(false);
  const [stakeOpen, setStakeOpen] = useState(false);
  const [unstakeOpen, setUnstakeOpen] = useState(false);
  const [walletCells, setWalletCells] = useState(() =>
    createWalletCells(asset)
  );

  const moreOptionsOnClick = () => {
    setMoreOptionsOpen(true);
  };
  const stakeOnClick = () => {
    setStakeOpen(true);
    SegmentReact.track(props.assetName + " Protocol Page", {
      page_event: props.assetName + " Stake button clicked",
      user_id: props.userId,
      session_id: props.sessionId,
      viewed_at: new Date(),
    });
  };
  const unstakeOnClick = () => {
    setUnstakeOpen(true);
    SegmentReact.track(props.assetName + " Protocol Page", {
      page_event: props.assetName + " Unstake button clicked",
      user_id: props.userId,
      session_id: props.sessionId,
      viewed_at: new Date(),
    });
  };

  return (
    <div>
      <StakingBox
        asset={asset}
        moreOptionsOnClick={moreOptionsOnClick}
        stakeOnClick={stakeOnClick}
        unstakeOnClick={unstakeOnClick}
        walletCells={walletCells}
        userId={props.userId}
        sessionId={props.sessionId}
        originPage={"Protocol"}
      />
      <StakeDialog
        amountAvailable={asset.balance}
        amountAvailableFormattedStr={asset.balanceFormattedStr}
        amountAvailableErc20={asset.balanceErc20}
        amountAvailableFormattedStrErc20={asset.balanceFormattedStrErc20}
        assetSymbol={asset.symbol}
        depositUrl={asset.depositUrl}
        purchaseUrl={asset.purchaseUrl}
        interestRate={asset.apy}
        lockUpPeriod={parseInt(asset.lockUpPeriod)}
        minDeposit={asset.minDeposit}
        onClose={() => setStakeOpen(false)}
        open={stakeOpen}
        priceInUsd={asset.priceInUsd}
        startDate={asset.startDate}
        stxToUsdPrice={props.stxToUsdPrice}
        url={asset.stakeUrl}
        urlErc20={asset.stakeUrlErc20}
        userId={props.userId}
        sessionId={props.sessionId}
        originPage={"Protocol"}
      />
      <MoreOptionsDialog
        assetSymbol={asset.symbol}
        autoStaked={asset.autoStaking}
        autoStakingOptOut={asset.autoStakingOptOut}
        changeAutoStake={(optOut: boolean, assetSymbol: string) => {
          const newAssets = [...stakingAssets];
          const updatedAsset = newAssets.find(
            (asset) => asset.symbol === assetSymbol
          );
          updatedAsset.autoStakingOptOut = optOut;
          setStakingAssets(newAssets);
        }}
        onClose={() => setMoreOptionsOpen(false)}
        open={moreOptionsOpen}
        selectedEntityId={props.entityId}
        url={props.toggleAutoStakingUrl}
      />
      <UnstakeDialog
        assetSymbol={asset.symbol}
        endDate={asset.startDate}
        onClose={() => setUnstakeOpen(false)}
        open={unstakeOpen}
        stakedAmount={asset.currentlyStakedAmountGQL}
        stakedAmountFormattedStr={asset.currentlyStakedAmount}
        url={asset.withdrawUrl}
        unstakeDelay={asset.unstakeDelay}
        userId={props.userId}
        sessionId={props.sessionId}
        originPage={"Protocol"}
      />
    </div>
  );
}
