window.Offerings.Participants.AdditionalInfo =
  form: ($$, $this) ->
    class FormView extends Backbone.View
      events: ->
        'click .js-select': ->
          if event.target.value == undefined
            # do nothing
          else if event.target.value == "Other"
            $("#other_textfield").attr("disabled", false)
          else
            $("#other_textfield").attr("disabled", true)

        'click .js-submit': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass 'c-button--loading'
          $target.addClass 'c-button--loading'

          @$('form').ajaxSubmit
            success: (response) =>
              window.location.href = response.redirect_url
            error: (response) =>
              if response.status == 422
                error_message = response.responseJSON.error
                error_div = @$('.js-errors')
                error_div.show()
                error_div.text(error_message)
              else
                Tooltips.formServerError($target)
              $target.removeClass 'c-button--loading'

    new FormView el: $this
