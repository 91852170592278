ActionCable = require('actioncable')

class WebSocketView extends Backbone.View

  client: ->
    return window.WebSocketClient if window.webSocketClient?
    window.WebSocketClient = ActionCable.createConsumer()
    window.WebSocketClient

# Provides the plumbing for getting data updates from web socket channels into the front-end.
# Each partial below will emit Backbone `update` events when data is received from its WebSocket
# connection, which can be consumed by listening for those events from other partials.
window.WebSockets = {

  blockchain_transactions: ($$, $this) ->
    class BlockchainTransactionsView extends WebSocketView
      initialize: ->
        offeringId = @$('.js-socket_data').data('offeringId')
        entityId = @$('.js-socket_data').data('entityId')
        entityType = @$('.js-socket_data').data('entityType')
        channel = @$('.js-socket_data').data('channel')
        channelData = {
          channel: channel,
          entity_id: entityId,
          entity_type: entityType,
          offering_id: offeringId
        }
        @client().subscriptions.create channelData,
          received: (data) =>
            @trigger 'update', data

    new BlockchainTransactionsView el: $this

  currency_prices: ($$, $this) ->
    class CurrencyPricesView extends WebSocketView

      initialize: ->
        channel = @$('.js-socket_data').data('channel')
        @client().subscriptions.create channel,
          received: (data) =>
            @trigger 'update', data

    new CurrencyPricesView el: $this
  Rfq: {
    requests_for_quote: ($$, $this) ->
      class RequestsForQuoteView extends WebSocketView
        initialize: ->
          entityId = @$('.js-socket_data').data('entityId')
          entityType = @$('.js-socket_data').data('entityType')
          channel = @$('.js-socket_data').data('channel')
          channelData = {
            channel: channel,
            entity_id: entityId,
            entity_type: entityType
          }
          @client().subscriptions.create channelData,
            connected: =>
              @trigger 'connected'
            disconnected: =>
              @trigger 'disconnected'
            received: (data) =>
              @trigger 'update', data

      new RequestsForQuoteView el: $this

    indicative_pricing: ($$, $this) ->
      class IndicativePricingView extends WebSocketView

        initialize: ->
          channel = @$('.js-socket_data').data('channel')
          @client().subscriptions.create channel,
            received: (data) =>
              @trigger 'update', data

      new IndicativePricingView el: $this
  }
}