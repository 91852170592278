import { TableCell, TableRow } from "@react/components";
import * as React from "react";

export interface GenerateTableRowData {
  key?: string;
  status: string;
  priority_queue: boolean;
  event_id: string;
  tokens_generated: number;
  estimated_tokens: number;
  total_karma: number;
  staking_karma: number;
  trading_karma: number;
  protocol_users: number;
  started_at: string;
  token_generator_id: string;
}

export const GenerateTableRow: React.FunctionComponent<GenerateTableRowData> = (
  props: GenerateTableRowData
) => {
  const priority = props.priority_queue ? "Priority" : "Normal";
  const date = new Date(props.started_at);

  return (
    <TableRow key={props.token_generator_id}>
      <TableCell>{props.status}</TableCell>
      <TableCell>
        {date.toLocaleDateString("en-us", {
          year: "numeric",
          month: "short",
          day: "numeric",
        })}
      </TableCell>
      <TableCell>{priority}</TableCell>
      <TableCell>{props.event_id}</TableCell>
      <TableCell>
        <b>{props.tokens_generated}</b>
      </TableCell>
      <TableCell>{props.total_karma}</TableCell>
      <TableCell>{props.staking_karma}</TableCell>
      <TableCell>{props.trading_karma}</TableCell>
      <TableCell>{props.protocol_users}</TableCell>
    </TableRow>
  );
};
