window.EdznHelper =
  messageForError: (file, errors)->
    return "#{file.name} filetype not supported" if errors.mimeType
    return "#{file.name} file size is too large" if errors.maxSize

    errors_message = []

    if errors.maxWidth && errors.maxHeight
      errors_message.push('width and height are too large')
    else
      errors_message.push('width is too large') if errors.maxWidth
      errors_message.push('height is too large') if errors.maxHeight

    if errors.minWidth && errors.minHeight
      errors_message.push('width and height are too small')
    else
      errors_message.push('width is too small') if errors.minWidth
      errors_message.push('height is too small') if errors.minHeight

    "#{file.name} #{errors_message.join(', ')}"


  defaultOptions: ->
    classes: {
      main:   'c-dropzone',
      enter:  'c-dropzone--enter',
      reject: 'c-dropzone--reject',
      accept: 'c-dropzone--accept',
      focus:  'c-dropzone--focus'
    }