require('../lib/validation')
Partials = require('../lib/partials')

window.WaitlistRequests.Shared =
  form_logged_out: ($$, $this) ->
    class FormLoggedOutView extends Backbone.View
      getFormatedEmail: ->
        $.trim(@$('.js-waitlist_request_email_input').val())

      isValidEmail: ->
        Validation.isValidEmail(@getFormatedEmail())

      enableButton: ->
        @$('.js-waitlist_request_button').prop('disabled', !@isValidEmail())

      showSuccessMessage: ->
        @$('.js-success_message').show()

      events:
        'submit .js-waitlist_request_form': (event) ->
          event.preventDefault()
          return if !@isValidEmail()

          $button = @$('.js-waitlist_request_button')
          return if $button.hasClass('c-button--loading')
          $button.addClass('c-button--loading')
          $form = $(event.target)

          $form.ajaxSubmit
            success: (response) =>
              @trigger('success', response)

            error: (response) =>
              Tooltips.formServerError($button)

        'input .js-waitlist_request_email_input': (event) ->
          @enableButton()

    new FormLoggedOutView el: $this

  form_logged_in: ($$, $this) ->
    class FormLoggedInView extends Backbone.View
      events:
        'click .js-waitlist_request_button': (event) ->
          event.preventDefault()
          $button = @$('.js-waitlist_request_button')
          return if $button.hasClass('c-button--loading')
          $button.addClass('c-button--loading')
          $.ajax
            url: $button.prop('href'),
            type: 'POST',
            success: (response) =>
              @trigger('success', response)

            error: (response) =>
              Tooltips.formServerError($button)

    new FormLoggedInView el: $this

  success_message: ($$, $this) ->
    class SuccessMessage extends Backbone.View
    new SuccessMessage el: $this

  waitlist_count: ($$, $this) ->
    class WaitlistCount extends Backbone.View
    new WaitlistCount el: $this