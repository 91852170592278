import * as React from "react";
import { shuffle } from "underscore";
import { useState, useMemo } from "react";
import { Button, Form } from "@react/components";
import RadioCollections from "@react/views/shared/forms/RadioCollections/RadioCollections";
import BlockField from "@react/views/shared/forms/BlockField/BlockField";

export interface GovernanceProposalQuizProps {
  successCallback: () => void;
}

const GovernanceProposalQuiz: React.FunctionComponent<GovernanceProposalQuizProps> = ({
  successCallback,
}) => {
  const gasLimitValidAnswer =
    "The block gas limit determines Ethereum’s block sizes and is determined by miners.";
  const gasLimitAnswers = useMemo(() => {
    return shuffle([
      gasLimitValidAnswer,
      "The block gas limit is required for every transaction and limits the total gas that a user is willing to pay to process a transaction.",
      "The block gas limit determines the amount of staking rewards users are eligible for.",
    ]);
  }, []);

  const benefitsValidAnswer =
    "The higher the gas limit, the larger the potential block size and the more transactions a block can process.";
  const benefitsAnswers = useMemo(() => {
    return shuffle([
      benefitsValidAnswer,
      "Ethereum becomes more decentralized and secure with higher gas limits.",
      "ETH prices increase as block gas limits increase.",
    ]);
  }, []);

  const drawbacksValidAnswer =
    "The higher the gas limit, the more likely the network is to desync and miners to miss blocks. Additionally, the network becomes more centralized.";
  const drawbacksAnswers = useMemo(() => {
    return shuffle([
      drawbacksValidAnswer,
      "Higher block gas limits increase the number of miners required to secure the network.",
      "The Ethereum network is able to fit less transactions per block with higher block gas limits.",
    ]);
  }, []);

  const [gasLimit, setGasLimit] = useState("");
  const [benefits, setBenefits] = useState("");
  const [drawbacks, setDrawbacks] = useState("");

  const gasLimitRadioOptions = useMemo(() => {
    return gasLimitAnswers.map((gasLimitAnswer) => {
      return {
        label: gasLimitAnswer,
        radio: {
          value: gasLimitAnswer,
        },
      };
    });
  }, []);

  const benefitsRadioOptions = useMemo(() => {
    return benefitsAnswers.map((benefitsAnswer) => {
      return {
        label: benefitsAnswer,
        radio: {
          value: benefitsAnswer,
        },
      };
    });
  }, []);

  const drawbacksRadioOptions = useMemo(() => {
    return drawbacksAnswers.map((drawbacksAnswer) => {
      return {
        label: drawbacksAnswer,
        radio: {
          value: drawbacksAnswer,
        },
      };
    });
  }, []);

  const [showError, setShowError] = useState(false);
  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formIsValid =
      gasLimitValidAnswer == gasLimit &&
      benefitsValidAnswer == benefits &&
      drawbacksValidAnswer == drawbacks;
    if (formIsValid) {
      successCallback();
    } else {
      setShowError(!formIsValid);
    }
  };

  return (
    <Form onSubmit={handleOnSubmit}>
      <p className="s-marginBottom1">To vote, please answer these:</p>
      <div className="s-marginBottom2">
        <BlockField label="What is Ethereum’s block gas limit?">
          <RadioCollections
            radioOptions={gasLimitRadioOptions}
            name="gas_limit"
            value={gasLimit}
            onChange={(e) => setGasLimit(e.target.value)}
          />
        </BlockField>
      </div>
      <div className="s-marginBottom2">
        <BlockField label="What are the benefits of higher block gas limits?">
          <RadioCollections
            radioOptions={benefitsRadioOptions}
            name="What are the benefits of higher block gas limits?"
            value={benefits}
            onChange={(e) => setBenefits(e.target.value)}
          />
        </BlockField>
      </div>
      <div className="s-marginBottom2">
        <BlockField label="What are the drawbacks of higher block gas limits?">
          <RadioCollections
            radioOptions={drawbacksRadioOptions}
            name="drawbacks"
            value={drawbacks}
            onChange={(e) => setDrawbacks(e.target.value)}
          />
        </BlockField>
      </div>
      <Button type="submit">Submit Answers</Button>
      {showError && (
        <p className="u-colorRed s-marginTop1">
          Some of your answers are not correct, please try again
        </p>
      )}
    </Form>
  );
};

export default GovernanceProposalQuiz;
