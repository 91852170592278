import * as React from "react";
import { useState } from "react";
import { Button, Form, Link, Icon } from "@react/components";
import RangeInputWithTooltip from "@react/views/shared/forms/RangeInputWithTooltip/RangeInputWithTooltip";
import { SetValue } from "@react/hooks/useLocalStorage";

import {
  axiosRequest,
  RequestType,
  successfulRequest,
} from "@react/utils/network";

export interface GovernanceProposalEglGasLimitFormProps {
  proposal: any;
  entity: any;
  entity_can_vote: boolean;

  currentVote?: string;
  setCurrentVote?: SetValue<string>;
}

const GovernanceProposalEglGasLimitForm: React.FunctionComponent<GovernanceProposalEglGasLimitFormProps> = ({
  proposal,
  entity,
  entity_can_vote,

  currentVote,
  setCurrentVote,
}) => {
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleOnSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!entity_can_vote || loading) {
      return;
    }

    setLoading(true);
    setShowSuccess(false);

    const requestData = {
      value: currentVote,
      entity_id: entity.id,
      entity_type: entity.type,
      proposal_id: proposal.id,
    };

    const response = await axiosRequest(
      "/governance/votes",
      requestData,
      RequestType.POST
    );

    if (successfulRequest(response)) {
      setShowSuccess(true);
      setLoading(false);
    } else {
      // TODO: Error
    }
  };

  const handleVoteSuggestionOnClick = (
    event: React.MouseEvent<HTMLElement>,
    voteSuggestion: string
  ) => {
    event.preventDefault();
    setCurrentVote(voteSuggestion);
  };

  // Suggested / scale ranges
  const voteSuggestions = ["-3", "-1.5", "0", "1.5", "3"];
  return (
    <Form onSubmit={handleOnSubmit}>
      <RangeInputWithTooltip
        min="-3"
        max="3"
        step="0.01"
        value={currentVote}
        tooltipContent={`${currentVote}M`}
        onChange={(event) => setCurrentVote(event.target.value)}
      />

      <div className="u-displayFlex u-justifyContentSpaceBetween s-marginBottom2">
        {voteSuggestions.map((voteSuggestion) => (
          <Link
            muted={currentVote != voteSuggestion}
            onClick={(event) =>
              handleVoteSuggestionOnClick(event, voteSuggestion)
            }
            cursorPointer
            key={voteSuggestion}
          >
            {voteSuggestion == "0" ? "0 (current)" : `${voteSuggestion}M`}
          </Link>
        ))}
      </div>
      {entity_can_vote && (
        <div className="u-displayFlex u-alignItemsCenter">
          <span className="s-paddingRight1">
            <Button type="submit" loading={loading}>
              Vote
            </Button>
          </span>
          {showSuccess && (
            <div className="u-text-center u-colorGreen">
              <span className="s-marginRight0_5">
                <Icon icon="ok" />
              </span>
              Vote successfully recorded
            </div>
          )}
        </div>
      )}
      {!entity_can_vote && (
        <div className="s-border1 u-textAlignCenter u-colorGray8 s-marginTop2">
          You need to be a participant of EGL genesis
        </div>
      )}
    </Form>
  );
};

export default GovernanceProposalEglGasLimitForm;
