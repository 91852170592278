require('trumbowyg')
require('trumbowyg/dist/plugins/upload/trumbowyg.upload')

window.HelpArticles.Shared =
  list: ($$, $this) =>
    class ListView extends Backbone.View
      events:
        'click .js-view_all_help_articles': (event) ->
          @$('.js-help_article_row').removeClass('u-hidden')
          @$('.js-view_all_help_articles').addClass('u-hidden')

    new ListView el: $this

  row: ($$, $this) =>
    class SearchRow extends Backbone.View
      initialize: ->
        @activeClass = @$('.js-data').data('activeClass')
        @url = @$('.js-data').data('url')
        @incremented = false

      toggleBody: ->
        @$('.js-help_article_body').slideToggle('fast')
        @$('.js-content').toggleClass(@activeClass)

      incrementViews: ->
        return if @incremented
        return unless @$('.js-content').hasClass(@activeClass)
        $.ajax @url, type: 'PATCH'
        @incremented = true

      events:
        'click .js-help_article_title': (event) ->
          @toggleBody()
          @incrementViews()

    new SearchRow el: $this

  search: ($$, $this) ->
    class SearchView extends Backbone.View
      initialize: ->
        @searchUrl = @$('.js-data_search').data('url')
        @minLength = @$('.js-data_search').data('minLength')

      searchHelpArticles: (search) ->
        $.ajax
          url: @searchUrl,
          data: {
            search: search
          },
          type: 'GET',
          success: (response) =>
            @$('.js-results').show().html(response.html)
            @trigger('showResults')
          error: (response) =>

      events:
        'input .js-help_articles_search_input': (event) ->
          $target = $(event.target)
          event.preventDefault()
          val = $.trim($target.val())
          return if val.length < @minLength
          @searchHelpArticles val
          clearTimeout @timer if @timer

          @timer = setTimeout( =>
            @searchHelpArticles val
          , 150)

        'keydown .js-help_articles_search_input': (event) ->
          return false if event.keyCode == 13

    new SearchView el: $this

  form: ($$, $this) ->
    class FormView extends Backbone.View
      initialize: ->
        # Local icon path
        $.trumbowyg.svgPath = @$('.js-svg_icon').data('url')

        # With image upload
        $imageUploadData = @$('.js-image_upload_data')
        @serverPath = $imageUploadData.data('serverPath')

        # Default
        trumbowygOptions =
          removeformatPasted: true
          autogrow: true
          hideButtonTexts: false
          btns: [
            ['viewHTML']
            ['formatting']
            ['horizontalRule']
            ['strong', 'em', 'del']
            ['link']
            ['unorderedList']
            ['upload']
          ]
          plugins:
            upload:
              serverPath: @serverPath
              data: [
                {
                 name: 'authenticity_token',
                 value: @$('input[name="authenticity_token"]').val()
                }
              ]
              fileFieldName: 'image'
              urlPropertyName: 'url'

        @$('.js-body_input').trumbowyg(trumbowygOptions)

      events:
        'submit .js-help_article_form': (event) ->
          event.preventDefault()
          $form = $(event.target)
          $button = @$('.js-cta')
          $form.ajaxSubmit
            success: (response) =>
              @trigger 'success', response
            error: (response) =>
              if response.status == 422
                @$el.replaceWith response.responseJSON.html
              else
                Tooltips.formServerError($button)

    new FormView el: $this