accounting = require('accounting')
Partials = require('../lib/partials')
moment = require('moment')
require('moment-timezone')

window.Investments.Show =
  receiving_funds: ($$, $this) =>
    class RecevingFundsView extends Backbone.View
      updateTransactions: (transactionsHtml) ->
        @$('.js-transactions').html('')
        i = 0
        _.each transactionsHtml, (html) =>
          if i > 0
            @$('.js-transactions').append $('<hr/>')
          @$('.js-transactions').append $(html)
          i += 1

      showCompletedState: ->
        # We could just reload the view via javascript or something?
        @$('.js-title_completed').show()
        @$('.js-title_pending').hide()
        @$('.js-label_completed').show()
        @$('.js-label_pending').hide()
        @$('.js-amount_received_in_currency').addClass('u-colorGreen').removeClass('u-colorOrange')
        @$('.js-amount_received_in_usd').addClass('u-colorGreen').removeClass('u-colorOrange')

        @$('.js-transations_container').slideUp()
        @$('.js-instructions_container').slideUp()
        @$('.js-transaction_detail_label').hide()
        @$('.js-view_transaction_detail_label').show()
        @$('.js-success_section').show()

      events:
        'click .js-view_transaction_detail_label': (event) ->
          event.preventDefault()
          @$('.js-transations_container').slideToggle()

    new RecevingFundsView el: $this

  transaction_details: ($$, $this) =>
    class TransactionDetailsView extends Backbone.View
      initialize: ->
        $data = @$('.js-data')

        @transactionAmount = accounting.unformat($data.data('transactionAmount'))
        @usdValue = accounting.unformat($data.data('usdValue'))

    new TransactionDetailsView el: $this

  crypto_payment_instructions: ($$, $this) =>
    class InvestInstructionsView extends Backbone.View

      updateBtcAmount: (btcAmount) ->
        @$('.js-btc_input').val(btcAmount) if @$('.js-btc_input').length
        @updateTimestamp()

      updateEthAmount: (ethAmount) ->
        @$('.js-eth_input').val(ethAmount) if @$('.js-eth_input').length
        @updateTimestamp()

      updateTimestamp: ->
        time = moment()
        timeWithZone = moment.tz(time, moment.tz.guess())
        timeForDisplay = timeWithZone.format('MMM D YYYY, h:mm:s A z')

        @$('.js-timestamp').text(timeForDisplay)


    new InvestInstructionsView el: $this

  sidebar: ($$, $this) =>
    class SidebarView extends Backbone.View
      updateBtcAmount: (btcAmount) ->
        @$('.js-btc_amount_due').text(btcAmount) if @$('.js-btc_amount_due').length

      updateEthAmount: (ethAmount) ->
        @$('.js-eth_amount_due').text(ethAmount) if @$('.js-eth_amount_due').length

      hideAllocation: ->
        @$('.js-guaranteed_allocation_section').hide()

    new SidebarView el: $this
