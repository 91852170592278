
window.SocialMediaSharePopup =

  POPUP_HEIGHT: 440
  POPUP_WIDTH: 560

  initialize: ->
    $('.js-social_media_share_link').on 'click', (event) =>
      event.preventDefault()
      $target = $(event.currentTarget)
      @openPopup(event.currentTarget.href)

  # Inspired from:
  # http://stackoverflow.com/questions/12882689/launch-facebook-share-popup-at-center-of-screen
  openPopup: (url) ->
    leftPosition = window.screen.width / 2 - (@POPUP_WIDTH / 2 + 10)
    topPosition = window.screen.height / 2 - (@POPUP_HEIGHT / 2 + 50)

    windowFeatures =
      "height=#{@POPUP_HEIGHT}," +
      "width=#{@POPUP_WIDTH}," +
      "left=#{leftPosition}," +
      "top=#{topPosition}," +
      "screenX=#{leftPosition}," +
      "screenY=#{topPosition}," +
      'status=no,' +
      'resizable=yes,' +
      'toolbar=no,' +
      'menubar=no,' +
      'scrollbars=no,' +
      'location=no,' +
      'directories=no'

    window.open url, 'sharer', windowFeatures
