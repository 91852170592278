import * as React from "react";
import { InputLabel } from "@mui/material";

export interface InputWrapperProps {
  children?: React.ReactNode;
  label: string;
}

const InputWrapper: React.FC<InputWrapperProps> = ({ label, children }: InputWrapperProps) => (
  <div>
    <InputLabel>{label}</InputLabel>
    {children}
  </div>
)

export default InputWrapper;
