import * as moment from "moment";

/**
 * Functions related to date / time
 */
export const getFormattedTime = (date) => {
  const time = moment(date);
  return time.format("MMM D, YYYY");
};

export const getCSVFriendlyFormattedTime = (date) => {
  const time = moment(date);
  return time.format("MMM/D/YYYY");
};

export const getFormattedTimeShort = (date) => {
  const time = moment(date);
  return time.format("MMM D");
};

export const getFormattedTimeDay = (date) => {
  const time = moment(date);
  return time.format("MMM D, h:mm A z");
};

export const getFormattedTimeLong = (date) => {
  const time = moment(date);
  return time.format("MMM D YYYY, h:mm A z");
};

export const getDiffDays = (firstDate, secondDate) => {
  return moment(firstDate).diff(
    secondDate ? moment(secondDate) : moment(),
    "days"
  );
};

export const addDays = (date, daysToAdd) => {
  return moment(date).add(daysToAdd, "days");
};

/**
 * Expects D
 */
export const changeDay = (date, newDay): Date => {
  return moment(date).date(newDay).toDate();
};

/**
 * Expects MMMM
 */
export const changeMonth = (date, newMonth): Date => {
  return moment(date).month(newMonth).toDate();
};

/**
 * Expects YYYY
 */
export const changeYear = (date, newYear): Date => {
  return moment(date).year(newYear).toDate();
};

export const getDayFromDate = (date) => {
  return moment(date).format("D");
};

export const getYearFromDate = (date) => {
  return moment(date).format("YYYY");
};

export const getMonthFromDate = (date) => {
  return moment(date).format("MMMM");
};

export const getCurrMonth = () => {
  return moment().format("MMMM");
};

export const getDaysForMonthArr = (
  month: string,
  year: string
): Array<string> => {
  const daysForMonth = [];
  const daysInMonth = moment(`${year}-${month}`, "YYYY-MMMM").daysInMonth();
  for (let i = 1; i <= daysInMonth; i++) {
    daysForMonth.push(i);
  }
  return daysForMonth.map((day) => day.toString());
};

export const getAllMonthsArr = (): Array<string> => {
  return [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
};

export const getAllYearsArr = (): Array<string> => {
  const allYears = [];
  const currYear = parseInt(moment().format("YYYY"));
  for (let i = currYear + 1; i >= currYear - 10; i--) {
    allYears.push(i);
  }
  return allYears.map((year) => year.toString());
};
