import * as React from "react";
import { KarmaDashboard as Dashboard } from "@coinlist/frontend-ui-lib"
import { ampli } from '../../../../ampli'
require("@coinlist/frontend-ui-lib/dist/style.css");

export default function KarmaDashboard(props) {
    return (
        <Dashboard amplitudeEvent={(event, data) => ampli.track(event, undefined, data)} entityId={props.entityId} apiUrl={"/karma"} />
    );
}
