import * as React from "react";

import Flex from "@react/components/Flex";
import { Link } from "@react/components";
import { SegmentReact } from "@react/analytics/segment_react";
import { roundFloat } from "@react/utils";

export default function LinksContainer(props) {
  return (
    <Flex alignItems="center" container justifyContent="space-between">
      <div>
        {props.links.map(({ href, iconClassName, title }) => (
          <div
            key={title}
            onClick={() => {
              SegmentReact.track(props.page, {
                page_event: props.project_name + " " + title + " link clicked",
                user_id: props.user_id,
                session_id: props.session_id,
                viewed_at: new Date(),
              });
              window.open(href, "_blank").focus();
            }}
            style={{
              cursor: "pointer",
              display: "inline",
              padding: props.padding || 8,
            }}
          >
            <Link fontSize={props.fontSize}>
              <i
                className={`${iconClassName} u-displayInlineBlock u-verticalAlignMiddle u-colorBrand`}
              ></i>
            </Link>
          </div>
        ))}
      </div>
      {/* TODO ARJUN -- hack that prevents people seeing $0.00 for tokens w/ small values */}
      {props.priceData && parseFloat(props.priceData.usd.toFixed(1)) > 0 && (
        <Link cursorPointer onClick={props.handleExpand}>
          Pricing Info
        </Link>
      )}
    </Flex>
  );
}
