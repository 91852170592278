import * as React from "react";
import { ampli } from '../../../ampli'
import { ThemeProvider, StyledEngineProvider} from '@mui/material/styles';
import { CssBaseline } from "@mui/material";
import { MuiTheme, ConstantsProvider, AmplitudeProvider, TranslationProvider } from '@coinlist/ui-lib';
import { ApolloProvider } from '@apollo/client'
import apolloClient from "./apolloClient";
import 'react-phone-input-2/lib/style.css'

export interface UiLibWrapperProps {
  locale: string;
  children: React.ReactNode;
}

/**
 * UiLibWrapper
 * 
 * This component is used to render any components from the @coinlist/ui-lib component
 * library. It is required to wrap the component in this component in order to ensure
 * that the component is rendered with the correct theme and css reset.
 * 
 * @param {React.ReactNode} children - The component to render from the @coinlist/ui-lib
 * component library.
 * 
 * @returns {React.ReactNode} - The component to render from the @coinlist/ui-lib
 * component library.
 * 
 */


export default function UiLibWrapper({locale, children}: UiLibWrapperProps) {
  return (
    <div> 
      {/* Force the background-color of body to be white as opposed to the default gray-ish */}
      <style>{"body { background-color: white !important; }"}</style>

        <TranslationProvider locale={locale}>
          <AmplitudeProvider amplitudeEventHandler={(eventName, data) => ampli.track(eventName, data)}>
            <ConstantsProvider shouldMockConstants={false} constantsUrl={"/constants"}>
              <ApolloProvider client={apolloClient}>
                <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={ MuiTheme }>
                    <CssBaseline/>
                    { children }
                  </ThemeProvider>
                </StyledEngineProvider>
              </ApolloProvider>
            </ConstantsProvider>
          </AmplitudeProvider>
        </TranslationProvider>
    </div>
  )
}
