import * as React from "react";
import { useEffect, useState } from "react";

import Disclaimer from "@react/components/landing-pages/Disclaimer";
import FAQ from "@react/components/landing-pages/FAQ";
import HeaderSubtitleGrid from "@react/components/landing-pages/HeaderSubtitleGrid";
import HowItWorks from "@react/components/landing-pages/HowItWorks";
import Typography from "@react/components/typography/Typography";
import { Link, Container, Divider, Spacer } from "@react/components";

import ContactDialog from "./components/lend/ContactDialog";

export interface FAQQuestions {
  content: React.ReactNode;
  title: string;
}

const gridItems = [
  {
    header: "Reduced Price Risk",
    subtitle:
      "Obtain FIL as working capital to scale up your mining operation without purchasing FIL on the open market and being exposed to considerable price risk.",
  },
  {
    header: "Fixed Duration and Interest Rates",
    subtitle:
      "Borrow FIL at a fixed, highly competitive rate for a set duration of time, ranging from 30 to 540 days. No sudden changes, no variability.",
  },
  {
    header: "Largest inventory of available FIL",
    subtitle:
      "CoinList has done over $450M in FIL originations since launch and is positioned to source individual loans greater than 250k FIL in days.",
  },
  {
    header: "Secure",
    subtitle:
      "Your collateral is secure. We only work with reputable custodians and the vast majority of your collateral will be stored offline.",
  },
];

const questions: Array<FAQQuestions> = [
  {
    content: (
      <div>
        <p>
          CoinList and Protocol Labs, the developers of Filecoin, have worked
          together since 2017, including the Filecoin launch in 2020. Due to
          CoinList and Protocol Labs’ close relationship, CoinList has access to
          a wide variety of FIL holders. CoinList has an unmatched ability to
          source FIL directly from Filecoin holders on its platform, making it
          one of the few lenders able to source individual loans greater than
          250k FIL in days.
        </p>
      </div>
    ),
    title: "How does CoinList source FIL?",
  },
  {
    content: (
      <div>
        <p>
          CoinList currently provides fixed-term loans. The terms of the loan
          can range between 30 (not available for multi-sig, low collateral
          loans) and 540 days and are agreed upon prior to finalizing the loan.
        </p>
      </div>
    ),
    title: "What is the duration of the loan?",
  },
  {
    content: (
      <div>
        <ul>
          <li>
            The starting collateral ratio for all borrowers ranges between 10%
            and 130%, depending on the structure of the loan.
          </li>
          <ul>
            <li>
              With an undercollateralized loan utilizing the Filecoin network,
              CoinList requires a collateral ratio as low as 10% in upfront
              collateral in FIL, BTC, ETH, USDC, or USDT. CoinList also requires
              additional collateral taken in the form of mining rewards earned
              by the storage providers. Additional mining rewards are taken
              until the total collateral ratio is equal to 35% of the principal
              amount. This is ideal for smaller or new storage providers without
              capital for collateral who are willing to pay higher interest for
              a lower collateral loan.
            </li>
            <li>
              With an overcollateralized loan, CoinList requires upfront 130%
              collateral for all new borrowers. This is ideal for storage
              providers that have the ability to put up significant capital as
              collateral and subsequently receive lower interest rates.
            </li>
          </ul>
          <li>
            If your collateral ratio falls below the lower bound, we will
            message you to deposit additional collateral.
          </li>
          <li>
            We do not automatically liquidate your collateral - we intend to
            build long-term relationships with all of our borrowers and we will
            work with you when you need to post additional collateral. You can
            post additional collateral by simply depositing assets into your
            existing CoinList account and posting collateral via{" "}
            <a href="/loans">coinlist.co/loans</a>.
          </li>
        </ul>
      </div>
    ),
    title: "What are the collateral requirements for the loan?",
  },
  {
    content: (
      <div>
        <p>
          Interest payments are due at the end of each month. You will
          automatically be notified when interest payments are due.
        </p>
        <ul>
          <li>
            For multi-sig, low collateral loans the interest payment will be
            deducted from your mining rewards. The rewards, net of interest, are
            then available for you in your CoinList account to withdraw or sell.
          </li>
          <li>
            For traditional, fully collateralized loans, you will then deposit
            the interest owed to your CoinList account and pay the interest
            payment directly from your FIL wallet.
          </li>
        </ul>
      </div>
    ),
    title: "When are interest payments due?",
  },
];

export default function Lend(props) {
  const [open, setOpen] = React.useState(false);
  const [response, setResponse] = useState(null);

  const emailElement = <a onClick={() => setOpen(true)}>email us</a>;

  useEffect(() => {
    if (response && open) {
      setResponse(null);
    }
  }, [open]);

  const howItWorksSteps = [
    {
      header: "Email us your borrow request",
      linkElement: <Link onClick={() => setOpen(true)}>Email us &raquo;</Link>,
      subtitleElement: (
        <Typography>
          Let us know your borrowing needs and we will contact you to confirm
          the details.
        </Typography>
      ),
    },
    {
      header: "Complete onboarding",
      linkElement: (
        <Link href={"/borrow-filecoin/onboarding"}>Onboard &raquo;</Link>
      ),
      subtitleElement: (
        <Typography>
          Complete the borrowing process by signing the borrowing agreement. If
          you don’t have an account, you will be prompted to create one and
          complete KYC. To expedite your application, please {emailElement}.
        </Typography>
      ),
    },
    {
      header: "Start your loan",
      linkElement: <Link href={props.loansUrl}>View loans &raquo;</Link>,
      subtitleElement: (
        <Typography>
          Once you sign the Master Loan Agreement and term sheet corresponding
          to your loan, you'll be prompted to post collateral & receive the loan
          all through your CoinList dashboard.
        </Typography>
      ),
    },
  ];

  return (
    <div style={{ backgroundColor: "#eeeeee" }}>
      <Container maxSize="18">
        <div className="s-paddingTop6" style={{ backgroundColor: "#eeeeee" }} />
        <HeaderSubtitleGrid
          gridItems={gridItems}
          title="Grow your FIL business with CoinList"
        />
        <Divider spacingBottom={2} spacingTop={2} />
        <HowItWorks steps={howItWorksSteps} />
        <Divider />
        <FAQ questions={questions} />
        <Divider spacingBottom={2} spacingTop={2} />
        <Disclaimer>
          By using this site, you explicitly agree to our{" "}
          <Link href="https://coinlist.co/terms">Terms of Service</Link> and{" "}
          <Link href="https://coinlist.co/privacy">Privacy Policy</Link>.
          CoinList Lend LLC is not a bank or a broker-dealer. Lending products
          are not securities and are not covered by SIPC, FDIC, or any other
          organization providing similar protections. All communications by you
          to the lending desk are non-binding until confirmed by CoinList.
          CoinList and its employees, officers, directors, and affiliates of
          CoinList may have proprietary interests in assets available for
          lending. Lending services are only directed toward the residents of
          jurisdictions permitting the use of such services.
        </Disclaimer>
        <div className="s-paddingTop6" style={{ backgroundColor: "#eeeeee" }} />
        <ContactDialog onClose={() => setOpen(false)} open={open} />
      </Container>
    </div>
  );
}
