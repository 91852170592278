GoogleMapsLoader = require('google-maps')

window.GooglePlaces = {
  autocomplete: ($input, key) ->
    class GooglePlacesAutocompletes extends Backbone.View
      UNITED_STATES = CountryList.US

      initialize: (options) ->
        @$input = options.$input.get(0)
        GoogleMapsLoader.KEY = options.key
        GoogleMapsLoader.LIBRARIES = ['places']
        GoogleMapsLoader.load (google) =>
          autocomplete = new google.maps.places.Autocomplete(@$input)
          google.maps.event.addDomListener @$input, 'keydown', (event) ->
            if event.key == 'Enter'
              event.preventDefault()
          autocomplete.addListener 'place_changed', =>
            if @$input.value
              @place = autocomplete.getPlace()
              locationData = {
                street: @getStreet(),
                city: @getLocality(),
                state: @getState(),
                country: @getCountry(),
                postalCode: @getPostalCode()
              }
              @trigger('autocompleted', locationData)

      getByType: (type, format = 'long_name') =>
        value = false
        _.each @place.address_components, (address_component) ->
          if address_component.types.includes(type)
            value = address_component[format]
        value

      getPostalCode: =>
        @getByType('postal_code')

      getCountry: (format = 'short_name') =>
        @getByType('country', format)

      getState: (format = 'short_name') =>
        if @getByType('country', 'short_name') == UNITED_STATES
          @getByType('administrative_area_level_1', format)
        else if @getByType('administrative_area_level_1', 'long_name')
          @getByType('administrative_area_level_1', 'long_name')
        else
          @getByType('locality')

      getLocality:  =>
        if @getByType('locality')
          @getByType('locality')
        else if @getByType('sublocality_level_1')
          @getByType('sublocality_level_1')
        else
          false

      getStreet:  =>
        if (@getByType('street_number') && @getByType('route'))
          "#{@getByType('street_number')} #{@getByType('route')}"
        else
          @getByType('route')

    new GooglePlacesAutocompletes({$input: $input, key: key})

}