moment = require('moment')
require('moment-timezone')
download = require('downloadjs')

window.Market.Entities.Wallets.Crypto.PastTransactions =
  transactions: ($$, $this) ->
    class TransactionsView extends Backbone.View
      initialize: ->
        @$('.js-timestamp').each (_i, timestampElem) ->
          $timestampElem = $(timestampElem)
          time = moment($timestampElem.data('time'))
          timeWithZone = moment.tz(time, moment.tz.guess())
          timeForDisplay = timeWithZone.format('MMM D YYYY, h:mm A z')
          $timestampElem.text(timeForDisplay)

    new TransactionsView el: $this
