import * as React from "react";
import { useState } from "react";

import RadioCollections from "@react/views/shared/forms/RadioCollections/RadioCollections";
import {
  Field,
  Select,
  Label,
  Checkbox,
  InlineLabel,
  Spacer,
} from "@react/components";
import BlockField from "@react/views/shared/forms/BlockField/BlockField";
import Flex from "@react/components/Flex";
import { Button, TextArea } from "@react/components";
import Table from "./Table";
import * as moment from "moment";
import DateInput from "../../lend/programs/new/components/DateInput";

// Determines wether action is targeted to user entity or user
export enum ApplyTargetType {
  ENTITY = "ENTITY",
  USER = "USER",
}

// Options for an action that involves setting / unsetting a flag
enum BinaryFlagToggleOpts {
  TURN_ON = "TURN_ON",
  TURN_OFF = "TURN_OFF",
}

// Radio options for an action that involves setting / unsetting a flag
const BinaryFlagRadioOptions = [
  {
    label: "Turn Off",
    radio: {
      value: BinaryFlagToggleOpts.TURN_OFF,
    },
  },
  {
    label: "Turn On",
    radio: {
      value: BinaryFlagToggleOpts.TURN_ON,
    },
  },
];

export interface Action {
  id: number;
  actionName: string;
  applyTarget: ApplyTargetType;
  params: { [key: string]: any };
}

export enum SupportedActionNames {
  BAN_FROM_FUTURE_SALE = "BAN_FROM_FUTURE_SALE",
  BLACKLIST = "BLACKLIST",
  CHANGE_RISK_LEVEL = "CHANGE_RISK_LEVEL",
  CREATE_NOTO_NOTE = "CREATE_NOTO_NOTE",
  MARK_ENTITY_FOR_FRAUD = "MARK_ENTITY_FOR_FRAUD",
  OFFBOARD = "OFFBOARD",
  TOGGLE_WALLET_FEATURE_FLAG = "TOGGLE_WALLET_FEATURE_FLAG",
  TOGGLE_FINANCIAL_SERVICES_INSTITUTION = "TOGGLE_FINANCIAL_SERVICES_INSTITUTION",
  SCHEDULE_REVERIFICATION = "SCHEDULE_REVERIFICATION",
  ACCOUNT_COMPROMISED_REVIEW = "ACCOUNT_COMPROMISED_REVIEW",
}

interface ActionSelectorProps {
  disableEdit: boolean;
  onChangeHandler: (actions) => void;
  selectedActions: Action[];
  fraudSelectOptions: { [key: string]: string };
  riskLevelOptions: { [key: string]: string };
  walletFeatureToggleOptions: { [key: string]: string };
}

export default function ActionSelector(props: ActionSelectorProps) {
  const actionTypeConfig = {
    [SupportedActionNames.BAN_FROM_FUTURE_SALE]: {
      label: "Ban From Future Sales",
      allowMultiple: false,
      defaultParams: {},
      applyTarget: ApplyTargetType.USER,
      validate: (params) => {
        if (!("note" in params) || params["note"].length == 0) {
          throw "Banning or unbanning reason required";
        }
        if (!("new_state" in params)) {
          throw "Banned new state required";
        }
      },
    },
    [SupportedActionNames.BLACKLIST]: {
      label: "Blacklist",
      allowMultiple: false,
      defaultParams: {},
      applyTarget: ApplyTargetType.USER,
      validate: (params) => {
        if (
          !("blacklist_note" in params) ||
          params["blacklist_note"].length == 0
        ) {
          throw "Blacklist note required";
        }
        if (!("new_state" in params)) {
          throw "Blacklist new state required";
        }
      },
    },
    [SupportedActionNames.CHANGE_RISK_LEVEL]: {
      label: "Change Risk Level",
      allowMultiple: false,
      defaultParams: { risk_level: Object.values(props.riskLevelOptions)[0] },
      applyTarget: ApplyTargetType.ENTITY,
      validate: (params) => {
        if (
          !("risk_level" in params) ||
          !Object.values(props.riskLevelOptions).includes(params["risk_level"])
        ) {
          throw "Invalid risk level selected";
        }
      },
    },
    [SupportedActionNames.CREATE_NOTO_NOTE]: {
      label: "Create Noto Note",
      allowMultiple: false,
      defaultParams: { note: "" },
      applyTarget: ApplyTargetType.ENTITY,
      validate: (params) => {
        if (!("note" in params) || params["note"].length == 0) {
          throw "Noto note must be non-empty";
        }
      },
    },
    [SupportedActionNames.TOGGLE_WALLET_FEATURE_FLAG]: {
      label: "Toggle Wallet Feature Flag",
      allowMultiple: false,
      defaultParams: {
        flag_names: [],
        flag_toggle: BinaryFlagToggleOpts.TURN_OFF,
      },
      applyTarget: ApplyTargetType.ENTITY,
      validate: (params) => {
        if (!("flag_names" in params) || params.flag_names.length == 0) {
          throw "Flag type not selected";
        }
        if (
          !("flag_toggle" in params) ||
          !Object.values(BinaryFlagToggleOpts).includes(params["flag_toggle"])
        ) {
          throw "Flag toggle selection is invalid";
        }
        if (!("reason" in params) || params["reason"].length == 0) {
          throw "Toggle wallet flag reason must be non-empty";
        }
      },
    },
    [SupportedActionNames.TOGGLE_FINANCIAL_SERVICES_INSTITUTION]: {
      label: "Toggle Businesses as Financial Services Institution",
      allowMultiple: false,
      defaultParams: {
        flag_toggle: BinaryFlagToggleOpts.TURN_OFF,
      },
      applyTarget: ApplyTargetType.ENTITY,
      validate: (params) => {
        if (
          !("flag_toggle" in params) ||
          !Object.values(BinaryFlagToggleOpts).includes(params["flag_toggle"])
        ) {
          throw "Flag toggle selection is invalid";
        }
      },
    },
    [SupportedActionNames.MARK_ENTITY_FOR_FRAUD]: {
      label: "Mark Entity For Fraud",
      allowMultiple: false,
      defaultParams: {
        fraud_label: Object.values(props.fraudSelectOptions)[0],
      },
      applyTarget: ApplyTargetType.ENTITY,
      validate: (params) => {
        if (
          !("fraud_label" in params) ||
          !Object.values(props.fraudSelectOptions).includes(params.fraud_label)
        ) {
          throw "Invalid fraud flag selected";
        }
      },
    },
    [SupportedActionNames.OFFBOARD]: {
      label: "Offboard User",
      allowMultiple: false,
      applyTarget: ApplyTargetType.USER,
      validate: (params) => {
        return true;
      },
    },
    [SupportedActionNames.SCHEDULE_REVERIFICATION]: {
      label: "Schedule Reverification",
      allowMultiple: false,
      defaultParams: {
        ready_to_turn_on_at: new Date(),
        ready_to_restrict_account_at: new Date(),
        hard_deadline_at: new Date(),
        soft_deadline_at: new Date(),
        reverification_reason: "",
      },
      applyTarget: ApplyTargetType.ENTITY,
      validate: (params) => {
        if (
          !("ready_to_turn_on_at" in params) ||
          params["ready_to_turn_on_at"].length == 0
        ) {
          throw "Ready to turn on date cannot be blank";
        }
        if (
          !("ready_to_restrict_account_at" in params) ||
          params["ready_to_restrict_account_at"].length == 0
        ) {
          throw "Ready to turn on date cannot be blank";
        }
        if (
          !("hard_deadline_at" in params) ||
          params["hard_deadline_at"].length == 0
        ) {
          throw "Ready to turn on date cannot be blank";
        }
        if (
          !("soft_deadline_at" in params) ||
          params["soft_deadline_at"].length == 0
        ) {
          throw "Ready to turn on date cannot be blank";
        }
        if (
          !("reverification_reason" in params) ||
          params["reverification_reason"].length == 0
        ) {
          throw "Reverification reason cannot be blank";
        }
      },
    },
    [SupportedActionNames.ACCOUNT_COMPROMISED_REVIEW]: {
      label: "Account Compromised Review",
      allowMultiple: false,
      defaultParams: {},
      applyTarget: ApplyTargetType.USER,
      validate: (params) => {
        if (!("new_state" in params)) {
          throw "Account Compromised Review new state required";
        }
      },
    },
  };

  const genNewActionData = () => {
    return {
      id: nextNewActionId,
      actionName: SupportedActionNames.BAN_FROM_FUTURE_SALE,
      params: {
        ...actionTypeConfig[SupportedActionNames.BAN_FROM_FUTURE_SALE]
          .defaultParams,
      },
    } as Action;
  };

  const [showNewActionView, setShowNewActionView] = useState<boolean>(false);
  const [nextNewActionId, setNextNewActionId] = useState<number>(1);
  const [newActionData, setNewActionData] = useState<Action>(
    genNewActionData()
  );
  const [newActionErrMsg, setNewActionErrMsg] = useState<string>("");

  const setNewActionParam = (key, value) => {
    var modNewActionData = { ...newActionData };
    modNewActionData.params[key] = value;
    setNewActionData(modNewActionData);
  };

  const addNewAction = () => {
    // If an action type already exists for this and duplicates don't allow, err out
    const dupAllowed = actionTypeConfig[newActionData.actionName].allowMultiple;
    for (const action of props.selectedActions) {
      if (!dupAllowed && action.actionName === newActionData.actionName) {
        setNewActionErrMsg(
          `Multiple actions for type '${newActionData.actionName}' not allowed'`
        );
        return;
      }
    }

    // Validate parameters
    try {
      const validateFn = actionTypeConfig[newActionData.actionName].validate;
      validateFn(newActionData.params);
    } catch (validationErr) {
      setNewActionErrMsg(validationErr.toString());
      return;
    }

    // Append new action to list of actions to apply
    const updatedActions = [...props.selectedActions, { ...newActionData }];
    props.onChangeHandler(updatedActions);

    // Action id for the next will be this + 1
    setNextNewActionId(nextNewActionId + 1);

    // Clear out new action data
    setNewActionData(genNewActionData());

    // Toggle hide new action view
    setShowNewActionView(false);

    // Clear out err messages in validation
    setNewActionErrMsg("");
  };

  const deleteAction = (id) => {
    var updatedActions = [...props.selectedActions];
    var removeIndex = updatedActions.map((action) => action.id).indexOf(id);
    ~removeIndex && updatedActions.splice(removeIndex, 1);
    props.onChangeHandler(updatedActions);
  };

  const actionTypes = Object.values(SupportedActionNames);

  const newActionForms = {
    [SupportedActionNames.CREATE_NOTO_NOTE]: () => (
      <BlockField label="Note" name={"noto-note-text"}>
        <TextArea
          name={"noto-note-text"}
          placeholder={"Insert Noto Note Here..."}
          onChange={(e) => {
            setNewActionParam("note", e.target.value);
          }}
        />
      </BlockField>
    ),
    [SupportedActionNames.CHANGE_RISK_LEVEL]: () => (
      <BlockField label="Change Risk Level To:" name={"noto-note-text"}>
        <Select
          value={newActionData.params["risk_level"]}
          onChange={(e) => {
            setNewActionParam("risk_level", e.target.value);
          }}
          options={Object.keys(props.riskLevelOptions).map((label) => ({
            labelText: label,
            value: props.riskLevelOptions[label],
          }))}
        />
      </BlockField>
    ),
    [SupportedActionNames.TOGGLE_WALLET_FEATURE_FLAG]: () => (
      <>
        <BlockField label="Feature flags to toggle:">
          {Object.entries(props.walletFeatureToggleOptions).map(
            ([flag_label, flag_name]) => {
              return (
                <InlineLabel key={flag_name}>
                  <Checkbox
                    onChange={(e) => {
                      var flag_names_state = newActionData.params.flag_names;
                      e.target.checked
                        ? flag_names_state.push(flag_name)
                        : (flag_names_state = flag_names_state.filter(
                            (e) => e !== flag_name
                          ));
                      setNewActionParam("flag_names", flag_names_state);
                    }}
                    checked={newActionData.params.flag_names.includes(
                      flag_name
                    )}
                  />
                  {flag_label.toUpperCase()}
                </InlineLabel>
              );
            }
          )}
        </BlockField>
        <BlockField label="Setting:">
          <RadioCollections
            radioOptions={BinaryFlagRadioOptions}
            value={newActionData.params["flag_toggle"]}
            onChange={(e) => setNewActionParam("flag_toggle", e.target.value)}
            name="feature-flag-toggle"
          />
        </BlockField>
        <BlockField label="Reason">
          <TextArea
            name="toggle-wallet-feature-flag-reason"
            placeholder="Insert reason"
            onChange={(e) => {
              setNewActionParam("reason", e.target.value);
            }}
          />
        </BlockField>
      </>
    ),
    [SupportedActionNames.OFFBOARD]: () => (
      <>
        <p>
          <b>Warning: </b> This action can not be undone
        </p>
      </>
    ),
    [SupportedActionNames.BLACKLIST]: () => (
      <>
        <BlockField label="Setting:">
          <RadioCollections
            radioOptions={BinaryFlagRadioOptions}
            value={newActionData.params["new_state"]}
            name="blacklist-new-state"
            onChange={(e) => {
              setNewActionParam("new_state", e.target.value);
            }}
          />
        </BlockField>
        <BlockField label="Note">
          <TextArea
            name="blacklisted-note"
            placeholder="Insert note"
            onChange={(e) => {
              setNewActionParam("blacklist_note", e.target.value);
            }}
          />
        </BlockField>
      </>
    ),
    [SupportedActionNames.BAN_FROM_FUTURE_SALE]: () => (
      <>
        <BlockField label="Setting:">
          <RadioCollections
            radioOptions={BinaryFlagRadioOptions}
            value={newActionData.params["new_state"]}
            name="banned-new-state"
            onChange={(e) => {
              setNewActionParam("new_state", e.target.value);
            }}
          />
        </BlockField>
        <BlockField label="Note">
          <TextArea
            name="banned-note"
            placeholder="Insert ban/unban reason"
            onChange={(e) => {
              setNewActionParam("note", e.target.value);
            }}
          />
        </BlockField>
      </>
    ),
    [SupportedActionNames.TOGGLE_FINANCIAL_SERVICES_INSTITUTION]: () => (
      <BlockField label="Toggle Financial Services Institution">
        <RadioCollections
          radioOptions={BinaryFlagRadioOptions}
          value={newActionData.params["flag_toggle"]}
          name="financial-services-institution-toggle"
          onChange={(e) => {
            setNewActionParam("flag_toggle", e.target.value);
          }}
        />
      </BlockField>
    ),
    [SupportedActionNames.MARK_ENTITY_FOR_FRAUD]: () => (
      <BlockField label="Toggle Fradulent User Flag">
        <Label>Option:</Label>
        <Select
          value={newActionData.params["fraud_label"]}
          onChange={(e) => setNewActionParam("fraud_label", e.target.value)}
          options={Object.entries(props.fraudSelectOptions).map(
            ([label, value]) => {
              return { labelText: label, value: value };
            }
          )}
        />
      </BlockField>
    ),
    [SupportedActionNames.SCHEDULE_REVERIFICATION]: () => (
      <>
        <BlockField
          label="Scheduled reverification start (UTC time)"
          name="ready-to-turn-on-at"
          hint="Account will have reverifications turned on and comms sent on this date"
        >
          <input
            type="datetime-local"
            name={"ready-to-turn-on-at"}
            onChange={(e) => {
              setNewActionParam("ready_to_turn_on_at", e.target.value);
            }}
          />
        </BlockField>

        <Spacer spacing={7} />

        <BlockField
          label="Account restriction date (UTC time)"
          name="ready-to-restrict-account-at"
          hint="Account will be restricted to withdrawals only on this date if user does not complete reverification"
        >
          <input
            type="datetime-local"
            name={"ready-to-restrict-account-at"}
            onChange={(e) => {
              setNewActionParam("ready_to_restrict_account_at", e.target.value);
            }}
          />
        </BlockField>

        <Spacer spacing={7} />

        <BlockField
          label="Soft deadline (UTC time)"
          name="soft-deadline-at"
          hint="Date communicated to the user as the supposed deadline for reverification"
        >
          <input
            type="datetime-local"
            name={"soft-deadline-at"}
            onChange={(e) => {
              setNewActionParam("soft_deadline_at", e.target.value);
            }}
          />
        </BlockField>

        <Spacer spacing={7} />

        <BlockField
          label="Hard deadline (UTC time)"
          name="hard-deadline-at"
          hint="After this date, account will remain locked and user will not be given chance to re-verify"
        >
          <input
            type="datetime-local"
            name={"hard-deadline-at"}
            onChange={(e) => {
              setNewActionParam("hard_deadline_at", e.target.value);
            }}
          />
        </BlockField>

        <Spacer spacing={7} />

        <BlockField
          label="Reverification reason"
          name={"reverification-reason"}
        >
          <TextArea
            name={"reverification-reason"}
            onChange={(e) => {
              setNewActionParam("reverification_reason", e.target.value);
            }}
          />
        </BlockField>
      </>
    ),
    [SupportedActionNames.ACCOUNT_COMPROMISED_REVIEW]: () => (
      <>
        <BlockField label="Setting:">
          <RadioCollections
            radioOptions={BinaryFlagRadioOptions}
            value={newActionData.params["new_state"]}
            name="account-compromised-review-new-state"
            onChange={(e) => {
              setNewActionParam("new_state", e.target.value);
            }}
          />
        </BlockField>
      </>
    ),
  };

  const selectedActionsView = (
    <>
      <Table
        title="Selected actions"
        headCells={["ID", "Action", "Parameters", "Delete"]}
        rows={props.selectedActions.map((action) => {
          const deleteButton = props.disableEdit ? (
            <></>
          ) : (
            <Button
              variant="secondary"
              style={{ marginBottom: 5 }}
              onClick={() => {
                setShowNewActionView(false);
                deleteAction(action.id);
              }}
            >
              <span>❌</span>
            </Button>
          );

          // Pretty print the parameters for action in list
          const paramsView = (
            <ul>
              {Object.entries(action.params).map(([key, value]) => {
                if (!value) {
                  return;
                }

                const valueStr = String(value);
                var valueRefined =
                  valueStr.length > 30
                    ? valueStr.substring(0, 30) + "..."
                    : valueStr;
                valueRefined = valueRefined.replace(/\n/g, " ");
                return (
                  <li key={key}>
                    <b>{key}: </b>
                    {valueRefined}
                  </li>
                );
              })}
            </ul>
          );

          return [
            <div>{`#${action.id}`}</div>,
            <div>{actionTypeConfig[action.actionName].label}</div>,
            <div> {paramsView} </div>,
            deleteButton,
          ];
        })}
        style={{ marginTop: 10, marginBottom: 10 }}
      />
    </>
  );

  const newActionView = (
    <>
      <Flex
        container
        spacing={2}
        style={{
          backgroundColor: "#f7f7e1",
          padding: 25,
          border: "1px solid grey",
          borderRadius: 4,
          position: "relative",
        }}
      >
        <Button
          variant={"secondary"}
          style={{ marginTop: 10, position: "absolute", top: 0, right: 10 }}
          onClick={() => {
            setShowNewActionView(false);
          }}
        >
          Collapse ^
        </Button>

        <Flex item md={6} style={{ paddingRight: 20 }}>
          <Field>
            <Label>New action type</Label>
            <Select
              value={newActionData.actionName}
              onChange={(e) => {
                const newAction = SupportedActionNames[e.target.value];
                setNewActionErrMsg("");
                setNewActionData({
                  id: nextNewActionId,
                  actionName: newAction,
                  applyTarget: actionTypeConfig[newAction].applyTarget,
                  params: { ...actionTypeConfig[newAction].defaultParams },
                });
              }}
              options={actionTypes.map((action) => ({
                labelText: actionTypeConfig[action].label,
                value: action,
              }))}
            />
          </Field>
        </Flex>
        <Flex item md={12}>
          <Flex container>
            {newActionForms[newActionData.actionName]() || <></>}
          </Flex>
          <Flex container>
            <p style={{ color: "red", fontWeight: 800, marginBottom: 20 }}>
              {newActionErrMsg}
            </p>
          </Flex>
          <Flex container style={{ marginTop: 12 }}>
            <Button onClick={addNewAction}>+ Add new action</Button>
          </Flex>
        </Flex>
      </Flex>
    </>
  );

  return (
    <>
      {props.selectedActions.length > 0 && selectedActionsView}

      {!props.disableEdit && (
        <>
          {props.selectedActions.length == 0 && (
            <div className="s-fontSize18 u-fontWeight700">
              Insert new action to begin
            </div>
          )}

          {!showNewActionView && (
            <Button
              variant={"secondary"}
              onClick={() => {
                setShowNewActionView(true);
              }}
            >
              <span>🚀</span>&nbsp;&nbsp;Insert new action
            </Button>
          )}
          {showNewActionView && newActionView}
        </>
      )}
    </>
  );
}
