window.Hackathons.Participants =
  apply_form: ($$, $this) ->
    class ApplyFormView extends Backbone.View
      HIDDEN_CLASS: 'u-hidden'

      initialize: ->
        @$howDidYouHearCustomInput = @$('.js-how_did_you_hear_text')
        @googlePlacesKey = @$el.data('google-places-key')
        googlePlacesAutocomplete = GooglePlaces.autocomplete(@$('.js-location'), @googlePlacesKey)

      events: ->
        'blur .js-github': (event) ->
          $target = @$(event.target)
          username = $target.val()
          _.each ['/', '@'], (chr) ->
            if username.indexOf(chr) >= 0
              parts = username.split(chr)
              username = _.last(parts.filter (part) -> part)
          $target.val(username)

        'change .js-how_did_you_hear_select': (event) ->
          target = event.target
          @$howDidYouHearCustomInput.val target.value
          if target.value == ''
            @$howDidYouHearCustomInput.parent().removeClass @HIDDEN_CLASS
          else
            @$howDidYouHearCustomInput.parent().addClass @HIDDEN_CLASS

        'click .js-submit': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass 'c-button--loading'

          $target.addClass 'c-button--loading'
          @$('form').ajaxSubmit
            success: (response) =>
              window.location.href = response.redirect_path
            error: (response) =>
              if response.status == 422
                $html = $(response.responseJSON.html)
                @$el.replaceWith $html
                Animate.niceScrollToFirstError($html)
              else
                Tooltips.formServerError($target)

    new ApplyFormView el: $this

  show: ($$, $this) ->
    class ShowView extends Backbone.View
      LOADING_CLASS = 'c-button--loading'
      UNSUBMIT_HOVER_TEXT = 'Unsubmit'
      UNSUBMIT_HOVER_OUT_TEXT = 'Submitted'

      postButtonUrl: (event) ->
        event.preventDefault()
        $target = $(event.target)
        return if $target.hasClass LOADING_CLASS

        $target.addClass LOADING_CLASS
        $.ajax
          url: $target.data('url'),
          type: 'POST',
          success: (response) =>
            window.location.href = response.redirect_path
          error: (response) =>
            Tooltips.formServerError($target)

      events:
        'click .js-submit-button': 'postButtonUrl'

        'click .js-unsubmit-button': 'postButtonUrl'

        'mouseover .js-unsubmit-button': (event) ->
          $target = $(event.target)
          $target.text UNSUBMIT_HOVER_TEXT

        'mouseout .js-unsubmit-button': (event) ->
          $target = $(event.target)
          $target.text UNSUBMIT_HOVER_OUT_TEXT

    new ShowView el: $this

  prize_claim_form: ($$, $this) ->
    class PrizeClaimFormView extends Backbone.View
      events: ->
        'click .js-submit': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass 'c-button--loading'

          $target.addClass 'c-button--loading'
          @$('form').ajaxSubmit
            success: (response) =>
              window.location.href = response.redirect_path
            error: (response) =>
              if response.status == 422
                $html = $(response.responseJSON.html)
                @$el.replaceWith $html
                Animate.niceScrollToFirstError($html)
              else
                Tooltips.formServerError($target)

    new PrizeClaimFormView el: $this
