import * as React from "react";
import { useState } from "react";
import { Link, LinkVariants } from "@react/components";

interface LinkSectionProps {
  // props
}

const LinkSection: React.FunctionComponent<LinkSectionProps> = (
  {
    // props
  }
) => {
  return (
    <div>
      <div className="s-marginBottom2">
        <h2 className="s-fontSize26 s-borderBottom0_5 s-marginBottom1 u-colorGray2">
          Link
        </h2>
        <p className="s-marginBottom0_5 u-colorGray6">
          Implementation of our{" "}
          <code className="c-code c-code--inline">{"<a>"}</code> component
        </p>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">Default</h3>
        <pre>
          <Link href="#">Default Link</Link>
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">Underline</h3>
        <pre>
          <Link href="#" underline="underline">
            Underline Link
          </Link>
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          No underlined
        </h3>
        <pre>
          <Link href="#" underline="no_underline">
            No Underlined Link
          </Link>
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">Unstyled</h3>
        <pre>
          <Link href="#" unstyled>
            unstyled Link
          </Link>
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">Disabled</h3>
        <pre>
          <Link href="#" disabled>
            disabled Link
          </Link>
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          Misc: Affect Font sizes
        </h3>
        <p className="s-marginBottom0_5 u-colorBlue">
          Note: The <code className="c-code c-code--inline">{"<Link>"}</code>{" "}
          component will naturally inherit its parent font size, so only use
          this prop if you can't set it on the parent container
        </p>
        <pre>
          <Link fontSize={24} href="#">
            {" "}
            Link
          </Link>
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          Misc: Affect Font weight
        </h3>
        <p className="s-marginBottom0_5 u-colorBlue">
          Note: The <code className="c-code c-code--inline">{"<Link>"}</code>{" "}
          component will naturally inherit its parent font weight, so only use
          this prop if you can't set it on the parent container
        </p>
        <pre>
          <Link fontWeight={700} href="#">
            {" "}
            Link
          </Link>
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">Variants</h3>
        {LinkVariants.map((variant, index) => (
          <div className="s-marginBottom1" key={`link-variant-${index}`}>
            <pre>
              <Link href="#" variant={variant}>
                {variant} Link
              </Link>
            </pre>
          </div>
        ))}
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          Variants Muted (Hover)
        </h3>
        {LinkVariants.map((variant, index) => (
          <div className="s-marginBottom1" key={`link-variant-${index}`}>
            <pre>
              <Link href="#" variant={variant} muted>
                {variant} Link Muted
              </Link>
            </pre>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LinkSection;
