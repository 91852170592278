import * as React from "react";
import { useState } from "react";
import { Checkbox, InlineLabel } from "@react/components";
import InputWithAddon from "@react/views/shared/forms/InputWithAddon/InputWithAddon";

interface CheckboxSectionProps {
  // props
}

const CheckboxSection: React.FunctionComponent<CheckboxSectionProps> = (
  {
    // props
  }
) => {
  const checkboxWithLabel = `<InlineLabel>
  <Checkbox />
  Check me !
</InlineLabel>`;

  return (
    <div>
      <div className="s-marginBottom2">
        <h2 className="s-fontSize26 s-borderBottom0_5 s-marginBottom1 u-colorGray2">
          Checkbox
        </h2>
        <p className="s-marginBottom0_5 u-colorGray6">
          An implementation of
          <code className="c-code c-code--inline">
            {'<input type="checkbox">'}
          </code>
        </p>
        <p className="s-marginBottom0_5 u-colorBlue">
          Note: If you want Label, Hint, Error, etc... use this component with{" "}
          <code className="c-code c-code--inline">
            <a href="#BlockField">{"<BlockField>"}</a>
          </code>
        </p>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">Default</h3>
        <pre>
          <Checkbox />
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">Checked</h3>
        <pre>
          <Checkbox checked />
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">Disabled</h3>
        <pre>
          <Checkbox disabled />
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          Indeterminate
        </h3>
        <pre>
          <Checkbox indeterminate />
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          With Label
        </h3>
        <pre>
          <InlineLabel>
            <Checkbox />
            Check me !
          </InlineLabel>
        </pre>
      </div>
    </div>
  );
};

export default CheckboxSection;
