import * as React from "react";
import { useState, useEffect } from "react";
import $ from "jquery";
import {
  Button,
  Form,
  Link,
  Icon,
  Checkbox,
  InlineLabel,
  Divider,
} from "@react/components";
import RadioCollections from "@react/views/shared/forms/RadioCollections/RadioCollections";
import * as moment from "moment";
import { SetValue } from "@react/hooks/useLocalStorage";

import {
  axiosRequest,
  RequestType,
  successfulRequest,
} from "@react/utils/network";

export interface GovernanceProposalFormProps {
  proposal: any;
  protocol: any;
  entity: any;
  entity_can_vote: boolean;
  governance_faq_path: string;
  currentVote?: any;
  setCurrentVote?: SetValue<any>;
  initialVote?: any;
  setInitialVote?: SetValue<any>;
  votingTermRef: React.MutableRefObject<HTMLDivElement>;
}

const GovernanceProposalForm: React.FunctionComponent<GovernanceProposalFormProps> = ({
  proposal,
  protocol,
  entity,
  entity_can_vote,
  governance_faq_path,
  currentVote,
  setCurrentVote,
  initialVote,
  setInitialVote,
  votingTermRef,
}) => {
  const [loading, setLoading] = useState(false);
  const [termChecked, setTermChecked] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [hasAlreadyVoted, setHasAlreadyVoted] = useState(
    initialVote.length != 0
  );

  const handleOnSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    if (initialVote.length != 0) {
      setHasAlreadyVoted(true);
    } else {
      setHasAlreadyVoted(false);
    }

    event.preventDefault();
    if (!entity_can_vote || loading) {
      return;
    }

    if (!termChecked) {
      return window.alert("You need to agree to the Voting terms");
    }

    // Cast single choice votes into an array as that's what the backend is expecting
    let votes;
    if (proposal.voting_system == "multiple_choices") {
      votes = currentVote;
    } else if (currentVote) {
      votes = [currentVote];
    }

    if (!votes || votes.length == 0) {
      return window.alert("You need to select an option");
    }

    setLoading(true);
    setShowSuccess(false);

    const requestData = {
      value: votes,
      entity_id: entity.id,
      entity_type: entity.type,
      proposal_id: proposal.id,
    };

    const response = await axiosRequest(
      "/governance/votes",
      requestData,
      RequestType.POST
    );

    if (successfulRequest(response)) {
      setShowSuccess(true);
      setLoading(false);
      setInitialVote(currentVote);
    } else {
      // TODO: Error
    }
  };

  const voteRadioOptions = proposal.choices.map((choice) => {
    return {
      label: choice.value,
      radio: {
        value: choice.value,
      },
    };
  });

  const navAndContentPadding = 100;
  const termHandleOnClick = (event: React.MouseEvent<HTMLElement>) => {
    $(".js-content-wrapper")[0].scrollTo({
      behavior: "smooth",
      top: votingTermRef.current.offsetTop + navAndContentPadding,
    });
  };

  let cannotVoteReason;
  if (protocol.egl) {
    cannotVoteReason = (
      <>You need to be a participant of {protocol.token_profile.name} to vote</>
    );
  } else if (moment(proposal.start_at).isAfter()) {
    cannotVoteReason = <>Proposal is not open for vote yet</>;
  } else if (moment(proposal.end_at).isBefore()) {
    cannotVoteReason = <>The voting period has ended</>;
  } else {
    cannotVoteReason = (
      <>
        you need hold $50 worth of {protocol.token_profile.symbol} at proposal
        start time in your CoinList wallet to vote
      </>
    );
  }

  const multipleChoices = proposal.voting_system == "multiple_choices";
  const numOfChoicesAllowed = proposal.num_of_choices_allowed;
  const [maxChoicesReached, setMaxChoicesReached] = useState<boolean>(false);

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      setCurrentVote((oldCurrentVote) => [
        ...oldCurrentVote,
        event.target.value,
      ]);
    } else {
      setCurrentVote(
        currentVote.filter((choiceValue) => choiceValue !== event.target.value)
      );
    }
  };

  useEffect(() => {
    if (numOfChoicesAllowed) {
      setMaxChoicesReached(currentVote.length == numOfChoicesAllowed);
    }
  }, [currentVote, numOfChoicesAllowed]);
  return (
    <Form onSubmit={handleOnSubmit}>
      {!multipleChoices && (
        <RadioCollections
          radioOptions={voteRadioOptions}
          value={currentVote}
          name={"vote-" + proposal.id}
          onChange={(e) => setCurrentVote(e.target.value)}
        />
      )}
      {multipleChoices && (
        <>
          {proposal.choices.map((choice) => (
            <InlineLabel
              key={`choice-label-${choice.value}`}
              htmlFor={`choice-${choice.value}`}
            >
              <Checkbox
                name={`choice-${choice.value}`}
                value={choice.value}
                checked={currentVote.includes(choice.value)}
                onChange={handleCheckboxChange}
                disabled={
                  maxChoicesReached && !currentVote.includes(choice.value)
                }
              />
              {choice.value}
            </InlineLabel>
          ))}
          {maxChoicesReached && (
            <p className="s-fontSize14 u-colorGray5">
              You have selected all of the {numOfChoicesAllowed} allowed choices
            </p>
          )}
        </>
      )}
      {entity_can_vote && (
        <>
          <Divider />
          <InlineLabel>
            <Checkbox
              checked={termChecked}
              onChange={() => setTermChecked(!termChecked)}
            />
            <p>
              I agree to the
              <Link onClick={termHandleOnClick}> Voting terms</Link>
            </p>
          </InlineLabel>
          <div className="u-displayFlex u-alignItemsCenter s-marginTop1">
            <span className="s-paddingRight1">
              <Button type="submit" loading={loading}>
                Vote
              </Button>
            </span>
            {showSuccess && (
              <div className="u-text-center u-colorGreen">
                <span className="s-marginRight0_5">
                  <Icon icon="ok" />
                </span>
                {hasAlreadyVoted ? "Vote updated" : "Vote casted"}
              </div>
            )}
          </div>
        </>
      )}
      {!entity_can_vote && (
        <div className="s-border1 u-textAlignCenter u-colorGray8 s-marginTop1">
          {cannotVoteReason}
        </div>
      )}
    </Form>
  );
};

export default GovernanceProposalForm;
