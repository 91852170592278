require('jquery-sticky')

window.Index.Legal =
  navigation: ($$, $this) =>
    class NavigationView extends Backbone.View
      NAVIGATION_ACTIVE_CLASS: 'c-nav__item--active'

      initialize: ->
        @_initialieScrollEvent()
        @_initializeSticky()

      _initializeSticky: ->
        if @$('.js-navigation').is(':visible')
          @$('.js-navigation').sticky(
            topSpacing: 20,
            bottomSpacing: @_bottomSpacing()
          )
        if @$('.js-mobile_navigation_input').is(':visible')
          @$('.js-mobile_navigation_input').sticky(
            topSpacing: 16,
            bottomSpacing: @_bottomSpacing()
          )

      _bottomSpacing: ->
        documentHeight = $(document).height()
        navWrapperOffsetTop = $('.js-nav_wrapper').offset().top
        navWrapperHeight = $('.js-nav_wrapper').height()
        parseInt(documentHeight - navWrapperOffsetTop - navWrapperHeight)

      _initialieScrollEvent: ->
        $sections = $('.js-section')
        $window = $(window)
        $window.on 'scroll.navigation', =>
          return if window.niceScrollToAnimation
          currentPosition = $window.scrollTop()
          firstSectionOffsetTop = Math.floor($sections.first().offset().top)
          $sections.each (index, section) =>
            $section = $(section)
            top = Math.floor($section.offset().top)
            bottom = top + $section.outerHeight()
            sectionId = $section.attr('id')
            if currentPosition >= top && currentPosition <= bottom
              @updateLink("##{sectionId}")

        $window.trigger 'scroll.navigation'

      updateLink: (id) ->
        @$('.js-section').hide()
        @$('.js-navigation_link').removeClass('c-nav__item--active')
        @$(".js-navigation_link[href='#{id}']").addClass('c-nav__item--active')
        @$('.js-mobile_navigation_input').val(id)

      scrollToSection: (id) ->
        Animate.niceScrollTo($(id), -@$('.js-mobile_navigation_input').height())

      events:
        'click .js-navigation_link': (event) ->
          $target = $(event.target)
          event.preventDefault()
          @updateLink($target.attr('href'))
          @scrollToSection($target.attr('href'))

        'change .js-mobile_navigation_input': (event) ->
          $target = $(event.target)
          @updateLink($target.val())
          @scrollToSection($target.val())

    new NavigationView el: $this
