require 'magnific-popup'

window.Offerings.Participants.Show =

  sidebar: ($$, $this) ->
    class SidebarView extends Backbone.View
      events: ->
        'click .js-edit-allocation-amount-link': (event) ->
          @$(event.target).hide()
          @$('.js-edit-allocation-amount-form').show()

    new SidebarView el: $this

  request_refund: ($$, $this) ->
    class RequestRefundView extends Backbone.View
      events:
        'click .js-request-refund': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass 'c-button--loading'
          $target.addClass 'c-button--loading'

          $.ajax @$($target).attr('href'),
            type: 'POST'
            success: (response) =>
              window.location.reload()
    new RequestRefundView el: $this

  token_price_confirmation: ($$, $this) ->
    class TokenPriceConfirmationView extends Backbone.View
      initialize: ->
        $$('.js-confirm_tokens').on 'click', ->
          $.magnificPopup.close()
        $$('.js-cancel_tokens').on 'click', (event)->
          $target = $(event.target)
          $target.addClass 'c-button--loading'
        @$('.js-tokenPriceConfirmation').show()
        $.magnificPopup.open({
          items: {
            src: @$('.js-tokenPriceConfirmation')
            type: 'inline'
          }
        })

      new TokenPriceConfirmationView el: $this
