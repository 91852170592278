window.Market.Entities.Wallets.Usd.Deposit = {

  plaid_link_update: ($$, $this) ->
    class PlaidLinkUpdateView extends Backbone.View
      initialize: ->
        if Plaid?
          @initializePlaid()
        else
          @$('.js-update-link').hide()
          @$('.js-plaid-script-blocked-message').show()

      initializePlaid: ->
        @$updateLink = @$('.js-update-link')

        clientName = @$updateLink.data('client-name')
        key = @$updateLink.data('key')
        product = @$updateLink.data('product')
        token = @$updateLink.data('publicToken')

        environment = PlaidIntegration.plaidEnvironment($('.js-environment').data('environment'))

        @plaidHandler = Plaid.create
          clientName: clientName,
          env: environment,
          key: key,
          product: [product],
          token: token,
          onEvent: (eventName, metadata) =>
            if eventName == 'ERROR'
              Rollbar.info("Plaid link error #{metadata.error_code} #{metadata.error_message}. Request ID: #{metadata.request_id}")
          onSuccess: (public_token, metadata) =>
            $.ajax
              url: @$updateLink.data('url'),
              type: 'POST',
              success: (response) =>
                @trigger 'plaidLinkUpdateSuccess'

      events:
        'click .js-update-link': (event) ->
          event.preventDefault()
          return unless @plaidHandler?
          @plaidHandler.open()


    new PlaidLinkUpdateView el: $this
}
