import * as React from "react";
import { Icon, IconNames } from "@react/components";
interface IconSectionProps {
  // props
}

const IconSection: React.FunctionComponent<IconSectionProps> = (
  {
    // props
  }
) => {
  return (
    <div>
      <div className="s-marginBottom2">
        <h2 className="s-fontSize26 s-borderBottom0_5 s-marginBottom1 u-colorGray2">
          Icon
        </h2>
        <p className="s-marginBottom0_5 u-colorGray6">
          Implementation of our Fontello Icon library
        </p>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          Default use
        </h3>
        <pre>
          <Icon icon="chevron-right" />
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">All Icons</h3>
        <pre>
          {IconNames.map((icon, index) => (
            <span className="s-padding0_5">
              <Icon icon={icon} />
            </span>
          ))}
        </pre>
      </div>
    </div>
  );
};

export default IconSection;
