/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull rails-browser'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 25
 * Build: 1.0.0
 * Runtime: browser:javascript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/coinlist/Styx/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/coinlist/Styx/implementation/rails-browser)
 */

import * as amplitude from '@amplitude/analytics-browser';

/**
 * @typedef BaseEvent
 * @type {object}
 * @property {string} event_type
 * @property {Object.<string, *>} [event_properties]
 */

/**
 * @typedef Plan
 * @type {object}
 * @property {string} [branch]
 * @property {string} [source]
 * @property {string} [version]
 * @property {string} [versionId]
 */

/**
 * @typedef LoadClientOptions
 * @type {object}
 * @property {string} [apiKey]
 * @property {Object} [configuration]
 * @property {BrowserClient} [instance]
 */

/**
 * @typedef LoadOptions
 * @type {object}
 * @property {'production'|'development'} [environment]
 * @property {boolean} [disabled]
 * @property {LoadClientOptions} [client]
 */

/**
 * @typedef {Object} EventOptions
 * @type {object}
 */

/**
 * @typedef ApiKey
 * @type {object}
 * @property {string} production
 * @property {string} development
 */
export const ApiKey = {
  production: '',
  development: ''
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration = {
  plan: {
    version: '25',
    branch: 'main',
    source: 'rails-browser',
    versionId: '525a8939-ae0f-4e55-a214-b73bc2664c73'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-javascript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export class Identify {
  constructor(properties) {
    this.event_type = amplitude.Types.SpecialEventType.IDENTIFY;
    this.event_properties = properties;
  }
}

export class DashKarma {
  constructor() {
    this.event_type = 'dash-karma';
  }
}

export class DashNotification {
  constructor(properties) {
    this.event_type = 'dash-notification';
    this.event_properties = properties;
  }
}

export class DashStatsKarma {
  constructor() {
    this.event_type = 'dash-stats-karma';
  }
}

export class DashStatsViewall {
  constructor() {
    this.event_type = 'dash-stats-viewall';
  }
}

export class DealpageCommunity {
  constructor(properties) {
    this.event_type = 'dealpage-community';
    this.event_properties = properties;
  }
}

export class DealpageCtaPrimary1 {
  constructor(properties) {
    this.event_type = 'dealpage-cta-primary1';
    this.event_properties = properties;
  }
}

export class DealpageCtaPrimary2 {
  constructor(properties) {
    this.event_type = 'dealpage-cta-primary2';
    this.event_properties = properties;
  }
}

export class DealpageCtaPrimary3 {
  constructor(properties) {
    this.event_type = 'dealpage-cta-primary3';
    this.event_properties = properties;
  }
}

export class DealpageCtaSecondary1 {
  constructor(properties) {
    this.event_type = 'dealpage-cta-secondary1';
    this.event_properties = properties;
  }
}

export class DealpageCtaSecondary2 {
  constructor(properties) {
    this.event_type = 'dealpage-cta-secondary2';
    this.event_properties = properties;
  }
}

export class DealpageView {
  constructor(properties) {
    this.event_type = 'dealpage-view';
    this.event_properties = properties;
  }
}

export class HeaderRfq {
  constructor(properties) {
    this.event_type = 'header-rfq';
    this.event_properties = properties;
  }
}

export class HomeLoaded {
  constructor() {
    this.event_type = 'home-loaded';
  }
}

export class KarmaClaim {
  constructor(properties) {
    this.event_type = 'karma-claim';
    this.event_properties = properties;
  }
}

export class KarmaHistoryLoad {
  constructor(properties) {
    this.event_type = 'karma-history-load';
    this.event_properties = properties;
  }
}

export class KarmaLearnmore {
  constructor() {
    this.event_type = 'karma-learnmore';
  }
}

export class KarmaTiercard {
  constructor(properties) {
    this.event_type = 'karma-tiercard';
    this.event_properties = properties;
  }
}

export class LoNotification {
  constructor(properties) {
    this.event_type = 'LO-notification';
    this.event_properties = properties;
  }
}

export class Nav {
  constructor(properties) {
    this.event_type = 'nav';
    this.event_properties = properties;
  }
}

export class NavKarma {
  constructor() {
    this.event_type = 'nav-karma';
  }
}

export class OnboardingAccountCreated {
  constructor() {
    this.event_type = 'onboarding-account-created';
  }
}

export class OnboardingAccountError {
  constructor() {
    this.event_type = 'onboarding-account-error';
  }
}

export class OnboardingLoginCompleted {
  constructor() {
    this.event_type = 'onboarding-login-completed';
  }
}

export class OnboardingVerificationDash {
  constructor() {
    this.event_type = 'onboarding-verification-dash';
  }
}

export class PrefundAllocationDesired {
  constructor(properties) {
    this.event_type = 'prefund-allocation-desired';
    this.event_properties = properties;
  }
}

export class WalletconnectError {
  constructor() {
    this.event_type = 'walletconnect-error';
  }
}

export class WalletconnectSelect {
  constructor(properties) {
    this.event_type = 'walletconnect-select';
    this.event_properties = properties;
  }
}

export class WalletconnectSuccess {
  constructor() {
    this.event_type = 'walletconnect-success';
  }
}

/**
 * @typedef PromiseResult
 * @type {object}
 * @property {Promise|undefined} promise
 */

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  constructor() {
    /* @type {BrowserClient|undefined} */
    this.amplitude = undefined;
    this.disabled = false;
  }

  /**
   * @return {BrowserClient}
   */
  get client() {
    this.isInitializedAndEnabled();
    return this.amplitude;
  }

  /**
   * @return {boolean}
   */
  get isLoaded() {
    return this.amplitude != null;
  }

  /**
   * @private
   * @return {boolean}
   */
  isInitializedAndEnabled() {
    if (!this.isLoaded) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param {LoadOptions} options Configuration options to initialize the Ampli SDK with. 'environment', 'client.apiKey' or 'client.instance' is required.
   *
   * @return {PromiseResult}
   */
  load(options) {
    this.disabled = options?.disabled ?? false;

    if (this.isLoaded) {
      console.warn('WARNING: Ampli is already initialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey;
    if (options?.client?.apiKey) {
      apiKey = options.client.apiKey;
    } else if (options?.environment) {
      apiKey = ApiKey[options.environment];
    }

    if (options?.client?.instance) {
      this.amplitude = options?.client?.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...options?.client?.configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set or update that user's properties.
   *
   * @param {string|undefined} userId The user's id.
   * @param {Object} [properties] The user's properties.
   * @param {string} [properties.entity-id] User's entity id to associate with internal data
   * @param {EventOptions} [options] Optional event options.
   *
   * @return {PromiseResult}
   */
  identify(userId, properties, options) {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options, user_id: userId};
    }

    const ampIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        ampIdentify.set(key, value);
      }
    }
    return this.amplitude.identify(ampIdentify, options);
  }

  /**
  * Flush the event.
  */
  flush() {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude.flush();
  }

  /**
   * Track event
   *
   * @param {BaseEvent} event The event to track.
   * @param {EventOptions} [options] Optional event options.
   * @param {Object} [properties] The event properties.
   * @return {PromiseResult}
   */
  track(event, options, properties) {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude.track(event, properties, options);
  }

  /**
   * dash-karma
   *
   * [View in Tracking Plan](https://data.amplitude.com/coinlist/Styx/events/main/latest/dash-karma)
   *
   * user clicked into karma from dashboard
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  dashKarma(options) {
    return this.track(new DashKarma(), options);
  }

  /**
   * dash-notification
   *
   * [View in Tracking Plan](https://data.amplitude.com/coinlist/Styx/events/main/latest/dash-notification)
   *
   * user clicked a notification on the home dash
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.notification-name] the name of the notification the user clicked on (tied to name in admin)
   * @param {EventOptions} [options] Options for this track call.
   */
  dashNotification(properties, options) {
    return this.track(new DashNotification(properties), options);
  }

  /**
   * dash-stats-karma
   *
   * [View in Tracking Plan](https://data.amplitude.com/coinlist/Styx/events/main/latest/dash-stats-karma)
   *
   * user interacted with the karma information on the stats element to nav to the karma page
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  dashStatsKarma(options) {
    return this.track(new DashStatsKarma(), options);
  }

  /**
   * dash-stats-viewall
   *
   * [View in Tracking Plan](https://data.amplitude.com/coinlist/Styx/events/main/latest/dash-stats-viewall)
   *
   * user interacted with the viewall link in the dash stats element
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  dashStatsViewall(options) {
    return this.track(new DashStatsViewall(), options);
  }

  /**
   * dealpage-community
   *
   * [View in Tracking Plan](https://data.amplitude.com/coinlist/Styx/events/main/latest/dealpage-community)
   *
   * user clicks on a community link
   *
   * @param {Object} [properties] The event's properties.
   * @param {'discord'|'webpage'|'twitter'|'telegram'} [properties.community-type] Property has no description in tracking plan.
   * @param {string} [properties.deal-slug] name of the protocol
   * @param {boolean} [properties.loggedin] is user logged in
   * @param {string} [properties.utm_campaign] Property has no description in tracking plan.
   * @param {string} [properties.utm_medium] Property has no description in tracking plan.
   * @param {string} [properties.utm_source] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  dealpageCommunity(properties, options) {
    return this.track(new DealpageCommunity(properties), options);
  }

  /**
   * dealpage-cta-primary1
   *
   * [View in Tracking Plan](https://data.amplitude.com/coinlist/Styx/events/main/latest/dealpage-cta-primary1)
   *
   * user clicks primary cta
   *
   * @param {Object} [properties] The event's properties.
   * @param {'discord'|'signup'|'telegram'|'form'|'action'} [properties.cta] what cta type was actioned
   * @param {string} [properties.deal-slug] name of the protocol
   * @param {boolean} [properties.loggedin] is user logged in
   * @param {string} [properties.utm_campaign] Property has no description in tracking plan.
   * @param {string} [properties.utm_medium] Property has no description in tracking plan.
   * @param {string} [properties.utm_source] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  dealpageCtaPrimary1(properties, options) {
    return this.track(new DealpageCtaPrimary1(properties), options);
  }

  /**
   * dealpage-cta-primary2
   *
   * [View in Tracking Plan](https://data.amplitude.com/coinlist/Styx/events/main/latest/dealpage-cta-primary2)
   *
   * user clicks primary cta (if two CTA types)
   *
   * @param {Object} [properties] The event's properties.
   * @param {'discord'|'signup'|'telegram'|'form'|'action'} [properties.cta] what cta type was actioned
   * @param {string} [properties.deal-slug] name of the protocol
   * @param {boolean} [properties.loggedin] is user logged in
   * @param {string} [properties.utm_campaign] Property has no description in tracking plan.
   * @param {string} [properties.utm_medium] Property has no description in tracking plan.
   * @param {string} [properties.utm_source] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  dealpageCtaPrimary2(properties, options) {
    return this.track(new DealpageCtaPrimary2(properties), options);
  }

  /**
   * dealpage-cta-primary3
   *
   * [View in Tracking Plan](https://data.amplitude.com/coinlist/Styx/events/main/latest/dealpage-cta-primary3)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {'discord'|'signup'|'telegram'|'form'|'action'} [properties.cta] what cta type was actioned
   * @param {string} [properties.deal-slug] name of the protocol
   * @param {boolean} [properties.loggedin] is user logged in
   * @param {string} [properties.utm_campaign] Property has no description in tracking plan.
   * @param {string} [properties.utm_medium] Property has no description in tracking plan.
   * @param {string} [properties.utm_source] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  dealpageCtaPrimary3(properties, options) {
    return this.track(new DealpageCtaPrimary3(properties), options);
  }

  /**
   * dealpage-cta-secondary1
   *
   * [View in Tracking Plan](https://data.amplitude.com/coinlist/Styx/events/main/latest/dealpage-cta-secondary1)
   *
   * user clicks the secondary location (below the fold) cta button for action 1
   *
   * @param {Object} [properties] The event's properties.
   * @param {'discord'|'signup'|'telegram'|'form'|'action'} [properties.cta] what cta type was actioned
   * @param {string} [properties.deal-slug] name of the protocol
   * @param {boolean} [properties.loggedin] is user logged in
   * @param {EventOptions} [options] Options for this track call.
   */
  dealpageCtaSecondary1(properties, options) {
    return this.track(new DealpageCtaSecondary1(properties), options);
  }

  /**
   * dealpage-cta-secondary2
   *
   * [View in Tracking Plan](https://data.amplitude.com/coinlist/Styx/events/main/latest/dealpage-cta-secondary2)
   *
   * user click the secondary placement (below the fold) of cta 2
   *
   * @param {Object} [properties] The event's properties.
   * @param {'discord'|'signup'|'telegram'|'form'|'action'} [properties.cta] what cta type was actioned
   * @param {string} [properties.deal-slug] name of the protocol
   * @param {boolean} [properties.loggedin] is user logged in
   * @param {EventOptions} [options] Options for this track call.
   */
  dealpageCtaSecondary2(properties, options) {
    return this.track(new DealpageCtaSecondary2(properties), options);
  }

  /**
   * dealpage-view
   *
   * [View in Tracking Plan](https://data.amplitude.com/coinlist/Styx/events/main/latest/dealpage-view)
   *
   * user views a dealpage
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.deal-slug] name of the protocol
   * @param {boolean} [properties.loggedin] is user logged in
   * @param {string} [properties.utm_campaign] Property has no description in tracking plan.
   * @param {string} [properties.utm_medium] Property has no description in tracking plan.
   * @param {string} [properties.utm_source] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  dealpageView(properties, options) {
    return this.track(new DealpageView(properties), options);
  }

  /**
   * header-rfq
   *
   * [View in Tracking Plan](https://data.amplitude.com/coinlist/Styx/events/main/latest/header-rfq)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {*} [properties.lastpage] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  headerRfq(properties, options) {
    return this.track(new HeaderRfq(properties), options);
  }

  /**
   * home-loaded
   *
   * [View in Tracking Plan](https://data.amplitude.com/coinlist/Styx/events/main/latest/home-loaded)
   *
   * This event tracks when the home/dash page is loaded by a logged in user
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  homeLoaded(options) {
    return this.track(new HomeLoaded(), options);
  }

  /**
   * karma-claim
   *
   * [View in Tracking Plan](https://data.amplitude.com/coinlist/Styx/events/main/latest/karma-claim)
   *
   * user claimed karma
   *
   * @param {Object} [properties] The event's properties.
   * @param {number} [properties.claimed-amount] amount claimed
   * @param {number} [properties.ending-karma-point] Property has no description in tracking plan.
   * @param {number} [properties.journalentry-ID] Property has no description in tracking plan.
   * @param {number} [properties.milestone-ID] Property has no description in tracking plan.
   * @param {string} [properties.milestone-name] Property has no description in tracking plan.
   * @param {string} [properties.milestone-rule] Property has no description in tracking plan.
   * @param {string} [properties.milestone-type] type of milestone actioned
   * @param {number} [properties.starting-karma-point] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  karmaClaim(properties, options) {
    return this.track(new KarmaClaim(properties), options);
  }

  /**
   * karma-history-load
   *
   * [View in Tracking Plan](https://data.amplitude.com/coinlist/Styx/events/main/latest/karma-history-load)
   *
   * user clicked to load all history
   *
   * @param {Object} [properties] The event's properties.
   * @param {number} [properties.current-karma-point] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  karmaHistoryLoad(properties, options) {
    return this.track(new KarmaHistoryLoad(properties), options);
  }

  /**
   * karma-learnmore
   *
   * [View in Tracking Plan](https://data.amplitude.com/coinlist/Styx/events/main/latest/karma-learnmore)
   *
   * user clicked in for more information from karma page
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  karmaLearnmore(options) {
    return this.track(new KarmaLearnmore(), options);
  }

  /**
   * karma-tiercard
   *
   * [View in Tracking Plan](https://data.amplitude.com/coinlist/Styx/events/main/latest/karma-tiercard)
   *
   * user clicked into tiercard
   *
   * @param {Object} [properties] The event's properties.
   * @param {number} [properties.current-karma-point] Property has no description in tracking plan.
   * @param {string} [properties.current-tier] Property has no description in tracking plan.
   * @param {string} [properties.next-threshold] Property has no description in tracking plan.
   * @param {string} [properties.previous-threshold] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  karmaTiercard(properties, options) {
    return this.track(new KarmaTiercard(properties), options);
  }

  /**
   * LO-notification
   *
   * [View in Tracking Plan](https://data.amplitude.com/coinlist/Styx/events/main/latest/LO-notification)
   *
   * notification card clicked on the logged out (LO) homepage
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.notification-name] the name of the notification the user clicked on (tied to name in admin)
   * @param {EventOptions} [options] Options for this track call.
   */
  loNotification(properties, options) {
    return this.track(new LoNotification(properties), options);
  }

  /**
   * nav
   *
   * [View in Tracking Plan](https://data.amplitude.com/coinlist/Styx/events/main/latest/nav)
   *
   * tracks when a user interacts with site nav page options
   *
   * Owner: Luke Therien
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.asset] the asset tied to the action
   * @param {*} [properties.lastpage] Property has no description in tracking plan.
   * @param {'wallet'|'rfq'|'pro'|'conversion'|'lending'|'staking'|'governance'|'tokensales'|'protocols'|'account'|'home'|'otc'} [properties.nav-location] the element in nav clicked by the user
   * @param {EventOptions} [options] Options for this track call.
   */
  nav(properties, options) {
    return this.track(new Nav(properties), options);
  }

  /**
   * nav-karma
   *
   * [View in Tracking Plan](https://data.amplitude.com/coinlist/Styx/events/main/latest/nav-karma)
   *
   * user clicked into karma from nav
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  navKarma(options) {
    return this.track(new NavKarma(), options);
  }

  /**
   * onboarding-account-created
   *
   * [View in Tracking Plan](https://data.amplitude.com/coinlist/Styx/events/main/latest/onboarding-account-created)
   *
   * User clicks the "Create Account" button to create their CoinList account having successfully filled out the registration form.
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  onboardingAccountCreated(options) {
    return this.track(new OnboardingAccountCreated(), options);
  }

  /**
   * onboarding-account-error
   *
   * [View in Tracking Plan](https://data.amplitude.com/coinlist/Styx/events/main/latest/onboarding-account-error)
   *
   * Triggers when a user attempts to submit their CoinList account form with incomplete or invalid information leading to errors in the provided inputs. Examples being "email already taken" or "password doesn't match"
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  onboardingAccountError(options) {
    return this.track(new OnboardingAccountError(), options);
  }

  /**
   * onboarding-login-completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/coinlist/Styx/events/main/latest/onboarding-login-completed)
   *
   * User logs in to their CoinList account with their email and password. Currently presented to a user after verifying their email address.
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  onboardingLoginCompleted(options) {
    return this.track(new OnboardingLoginCompleted(), options);
  }

  /**
   * onboarding-verification-dash
   *
   * [View in Tracking Plan](https://data.amplitude.com/coinlist/Styx/events/main/latest/onboarding-verification-dash)
   *
   * User clicks "get started" on their dashboard to navigate to kyc/b
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  onboardingVerificationDash(options) {
    return this.track(new OnboardingVerificationDash(), options);
  }

  /**
   * prefund-allocation-desired
   *
   * [View in Tracking Plan](https://data.amplitude.com/coinlist/Styx/events/main/latest/prefund-allocation-desired)
   *
   * collects allocation amount desired on prefund flow
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.amount] quantity
   * @param {string} [properties.asset] the asset tied to the action
   * @param {string} [properties.deal-slug] name of the protocol
   * @param {string} [properties.entity-id] entity id of the entity performing the action
   * @param {EventOptions} [options] Options for this track call.
   */
  prefundAllocationDesired(properties, options) {
    return this.track(new PrefundAllocationDesired(properties), options);
  }

  /**
   * walletconnect-error
   *
   * [View in Tracking Plan](https://data.amplitude.com/coinlist/Styx/events/main/latest/walletconnect-error)
   *
Event has no description in tracking plan.
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  walletconnectError(options) {
    return this.track(new WalletconnectError(), options);
  }

  /**
   * walletconnect-select
   *
   * [View in Tracking Plan](https://data.amplitude.com/coinlist/Styx/events/main/latest/walletconnect-select)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.walletplatform] what wallet user connected
   * @param {EventOptions} [options] Options for this track call.
   */
  walletconnectSelect(properties, options) {
    return this.track(new WalletconnectSelect(properties), options);
  }

  /**
   * walletconnect-success
   *
   * [View in Tracking Plan](https://data.amplitude.com/coinlist/Styx/events/main/latest/walletconnect-success)
   *
Event has no description in tracking plan.
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  walletconnectSuccess(options) {
    return this.track(new WalletconnectSuccess(), options);
  }
}

export const ampli = new Ampli();
