import * as React from "react";
import { useState } from "react";

import Box from "@react/components/Box";
import { Button } from "@react/components";
import Confirmation from "@react/components/Confirmation";
import Flex from "@react/components/Flex";
import Loading from "@react/components/Loading";
import Typography from "@react/components/typography/Typography";

import { isSmallOrSmaller, roundFloat } from "../../../../utils";
import { COLOR_MAP } from "../../../../utils/color";
import { RequestType } from "../../../../utils/network";
import { DECIMAL_PLACES } from "./Convert";
import { getRequestData } from "..";

const BOX_PADDING = 32;
export const FLEX_TITLE_STYLE = {
  borderBottom: "1px solid #EAEDF3",
  padding: `${BOX_PADDING}px 24px`,
};
export const FLEX_ITEM_STYLE = {
  borderBottom: "1px solid #EAEDF3",
  padding: 24,
};

interface SubmitProps {
  amount: number;
  amountToDeliver: number;
  assetToConvert: any;
  coinListFee: number;
  conversionsIndexUrl: string;
  decrementPage: () => void;
  entityId: string;
  lendBtcBalance: string;
  lendTbtcBalance: string;
  lendWbtcBalance: string;
  response: any;
  setResponse: (any) => void;
  setSnackbarOpen: (boolean) => void;
  walletMap: any;
}

export default function Submit(props: SubmitProps) {
  const small = isSmallOrSmaller();
  const [loading, setLoading] = useState(false);

  return (
    <Box>
      <MainContainer {...props} response={props.response} />
      {(!props.response || props.response.status !== 200) && (
        <Flex
          container
          justifyContent="flex-end"
          spacing={2}
          style={{
            padding: 24,
          }}
        >
          <Button onClick={() => props.decrementPage()} variant="gray">
            Cancel
          </Button>
          <Button
            onClick={() => setLoading(true)}
            style={{
              height: 46,
              width: 105,
            }}
          >
            {loading ? (
              <Loading
                handleResponse={(response) => {
                  props.setSnackbarOpen(response.status !== 200);
                  setLoading(false);
                  props.setResponse(response);
                }}
                loading={loading}
                requestData={{
                  data: getRequestData({
                    amount: props.amount,
                    assetToConvert: props.assetToConvert,
                    entityId: props.entityId,
                    lendBtcBalance: props.lendBtcBalance,
                    lendTbtcBalance: props.lendTbtcBalance,
                    lendWbtcBalance: props.lendWbtcBalance,
                    walletMap: props.walletMap,
                  }),
                  type: RequestType.POST,
                  url: props.assetToConvert.convert_url,
                }}
                spinnerColor="white"
              />
            ) : (
              "Submit"
            )}
          </Button>
        </Flex>
      )}
    </Box>
  );
}

const MainContainer = (props) => {
  const { response } = props;
  return (
    <Flex
      container
      style={{
        paddingBottom: BOX_PADDING,
        position: "relative",
      }}
    >
      <Flex item style={FLEX_TITLE_STYLE} xs={24}>
        <Typography bold>Confirm</Typography>
      </Flex>
      {response && response.status === 200 ? (
        <Flex container>
          <Confirmation
            mainText={`Successfully converted ${props.assetToConvert.base_asset_symbol} to ${props.assetToConvert.counter_asset_symbol}`}
            subText={
              "The conversion may take a few minutes to show up in your wallet."
            }
          />
          <Flex
            alignItems="center"
            container
            justifyContent="flex-end"
            spacing={2}
            style={{ position: "absolute", bottom: 16, right: 16 }}
          >
            <Button
              onClick={() =>
                (window.location.href = `${props.assetToConvert.wallet_url}`)
              }
              variant="gray"
            >
              View {props.assetToConvert.counter_asset_symbol} Wallet
            </Button>
            <Button
              onClick={() => (window.location.href = props.conversionsIndexUrl)}
            >
              Done
            </Button>
          </Flex>
        </Flex>
      ) : (
        <Flex container>
          <Flex container style={FLEX_ITEM_STYLE}>
            <Flex item xs={8}>
              <Typography bold>Convert</Typography>
            </Flex>
            <Flex item xs={16}>
              <Typography>
                {new Intl.NumberFormat().format(parseFloat(props.amount))} {props.assetToConvert.base_asset_symbol} to{" "}
                {props.assetToConvert.counter_asset_symbol}
              </Typography>
            </Flex>
          </Flex>
          <Flex container style={FLEX_ITEM_STYLE}>
            <Flex item xs={8}>
              <Typography bold>CoinList Fee</Typography>
            </Flex>
            <Flex item xs={16}>
              <Typography>
                {props.assetToConvert.value === "nu_to_t"
                  ? 0
                  : roundFloat(props.coinListFee * 100, 2)}
                %
              </Typography>
            </Flex>
          </Flex>
          <Flex
            container
            style={{
              ...FLEX_ITEM_STYLE,
              backgroundColor: COLOR_MAP.grayLightest,
            }}
          >
            <Flex item xs={8}>
              <Typography bold>You Get</Typography>
            </Flex>
            <Flex item xs={16}>
              <Typography bold>
                {props.assetToConvert.value === "nu_to_t"
                  ? new Intl.NumberFormat().format(roundFloat(props.amount * 3.259242493160745, DECIMAL_PLACES))
                  : new Intl.NumberFormat().format(roundFloat(props.amountToDeliver, DECIMAL_PLACES))}
                {props.assetToConvert.counter_asset_symbol}
              </Typography>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
