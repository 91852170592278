import * as React from "react";

import { Theme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import cx from "classnames";

import { ICON_MAP } from "../../../../shared/icons";

import Flex from "@react/components/Flex";
import Typography from "@react/components/typography/Typography";
import AccountBadgeRightArrowIcon from "./icons/AccountBadgeRightArrowIcon";

export interface AccountBadgeAttributes {
  background: string;
  stroke: string;
  imageBackground: string;
  accent?: string;
  title: string;
  subtitle?: string;
  image: string;
  hasArrow: boolean;
}

export const AccountBadge: React.FC<{
  badgeAttributes: AccountBadgeAttributes;
  onClick?: () => void;
}> = ({ badgeAttributes, onClick }) => {
  const classes = useStyles(badgeAttributes);
  return (
    <button onClick={onClick} className={cx(classes.badgeButton)}>
      <Flex
        alignItems="center"
        justifyContent="center"
        style={{
          width: 40,
          height: 40,
          backgroundColor: badgeAttributes.imageBackground,
          borderRadius: "50%",
        }}
      >
        <img src={badgeAttributes.image} />
      </Flex>

      <Flex style={{ flexDirection: "column", paddingLeft: "8px" }}>
        <Typography
          fontSize={badgeAttributes.subtitle ? 15 : 17}
          style={{ fontWeight: 600, color: "#333333" }}
        >
          {badgeAttributes.title}
        </Typography>
        {badgeAttributes.subtitle && (
          <Flex
            alignItems="center"
            style={{ flexDirection: "row", gap: "4px" }}
          >
            <Typography fontSize={12} style={{ color: badgeAttributes.accent }}>
              {badgeAttributes.subtitle}
            </Typography>
            {badgeAttributes.hasArrow && (
              <AccountBadgeRightArrowIcon fill={badgeAttributes.accent} />
            )}
          </Flex>
        )}
      </Flex>
    </button>
  );
};

const useStyles = makeStyles<Theme, AccountBadgeAttributes>((theme) => ({
  badgeButton: {
    borderStyle: "solild",
    borderRadius: 100,
    background: ({ background }) => background,
    borderWidth: "1.5px",
    borderColor: ({ stroke }) => stroke,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "8px",
    paddingRight: "16px",
    boxShadow: "none",
    "&:hover": {
      background: ({ background }) => background,
    },
  },
}));

export const GetMoreBadgesAttributes: AccountBadgeAttributes = {
  background: "rgb(245, 245, 245)",
  stroke: "rgb(234, 234, 234)",
  imageBackground: "rgb(51, 51, 51)",
  accent: "rgb(117, 195, 81)",
  title: "Get More Badges",
  subtitle: null,
  image: ICON_MAP.badgeIconPlus,
  hasArrow: false,
};

export const KarmaBadgesAttributes: (
  count: string
) => AccountBadgeAttributes = (count) => ({
  background: "rgb(182, 66, 226, 0.05)",
  stroke: "rgb(250, 0, 255, 0.1)",
  imageBackground: "rgb(255, 123, 250)",
  accent: "rgb(255, 123, 250)",
  title: count + " CoinList Karma",
  subtitle: "EARN MORE",
  image: ICON_MAP.badgeIconKarma,
  hasArrow: true,
});

export const StartKYCBadgeAttributes: AccountBadgeAttributes = {
  background: "rgb(117, 195, 81, 0.05)",
  stroke: "rgb(117, 195, 81, 0.15)",
  imageBackground: "rgb(117, 195, 81)",
  accent: "rgb(117, 195, 81)",
  title: "Identity Verification",
  subtitle: "START VERIFICATION",
  image: ICON_MAP.badgeIconKYCNotStarted,
  hasArrow: true,
};

export const IncompleteKYCBadgeAttributes: AccountBadgeAttributes = {
  background: "rgb(246, 163, 85, 0.05)",
  stroke: "rgb(246, 163, 85, 0.15)",
  imageBackground: "rgb(246, 163, 85)",
  accent: "rgb(205, 180, 156)",
  title: "Identity Verification",
  subtitle: "COMPLETE VERIFICATION",
  image: ICON_MAP.badgeIconKYCIncomplete,
  hasArrow: true,
};

export const ProcessingKYCBadgeAttributes: AccountBadgeAttributes = {
  background: "rgb(246, 163, 85, 0.05)",
  stroke: "rgb(246, 163, 85, 0.15)",
  imageBackground: "rgb(246, 163, 85)",
  accent: "rgb(205, 180, 156)",
  title: "Processing Identity",
  subtitle: "Your identity is being processed",
  image: ICON_MAP.badgeIconKYCProcessing,
  hasArrow: false,
};

export const RejectedKYCBadgeAttributes: AccountBadgeAttributes = {
  background: "rgb(246, 85, 85, 0.05)",
  stroke: "rgb(246, 85, 85, 0.15)",
  imageBackground: "rgb(246, 85, 85)",
  accent: "rgb(246, 85, 85)",
  title: "Identity Rejected",
  subtitle: "SEE WHY",
  image: ICON_MAP.badgeIconKYCRejected,
  hasArrow: true,
};

export const VerifiedKYCBadgeAttributes: AccountBadgeAttributes = {
  background: "rgb(1, 191, 255, 0.05)",
  stroke: "rgb(0, 102, 255, 0.10)",
  imageBackground: "rgb(0, 209, 255)",
  accent: "rgb(19, 68, 113, 0.5)",
  title: "Verified Identity",
  subtitle: "Your identity has been verified",
  image: ICON_MAP.badgeIconKYCVerified,
  hasArrow: false,
};
