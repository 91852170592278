import * as React from "react";

import SegmentAnalytics from "analytics";
import segmentPlugin from "@analytics/segment";

export const SegmentReact = SegmentAnalytics({
  app: "react-compliance",
  plugins: [
    segmentPlugin({
      writeKey: "2xnT9vuC4tQKcVpxUG8f7HwxcfyD8Uqz",
    }),
  ],
});
