import * as React from "react";
import { useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import Dialog from "@mui/material/Dialog";

import Flex from "@react/components/Flex";
import Loading from "@react/components/Loading";
import { TextInput, Select, Button, Divider } from "@react/components";
import BlockField from "@react/views/shared/forms/BlockField/BlockField";
import Typography from "@react/components/typography/Typography";

import { isTestEnv, testEntity, testInvestmentsData } from "@react/utils";

import { RequestData } from "@react/components/Loading";
import { RequestType } from "@react/utils/network";

const LOOKUP_URL = "/admin/trade/investor_lookup";
const AVG_DAYS_IN_MONTH = 30.436875;

const getDailyVestingAmount = (tokenQuantity, vestingPeriodInMonths) => {
  return tokenQuantity / vestingPeriodInMonths / AVG_DAYS_IN_MONTH;
};

const totalVestingAmount = (investments) => {
  return investments.reduce((acc, investment) => {
    const dailyVestingAmount = getDailyVestingAmount(
      investment.token_quantity,
      investment.vesting_period_in_months
    );
    return acc + dailyVestingAmount * 28;
  }, 0);
};

const getEntityName = (entity) => {
  if (entity.owner_type === "User") {
    return entity.first_name + " " + entity.last_name;
  }
  return entity.name;
};

const renderResponse = (response: any) => {
  if (response.data.errors) {
    return <div></div>;
  }
  const { entity = {}, investments } = response.data;
  return (
    <Flex container spacing={2}>
      <Flex item xs={24}>
        <Typography fontSize={20} noBreak>
          {getEntityName(entity)} Investments
        </Typography>
      </Flex>
      {investments.map((investment) => {
        const dailyVestingAmount = getDailyVestingAmount(
          investment.token_quantity,
          investment.vesting_period_in_months
        );
        return (
          <Flex container>
            <Flex item xs={24}>
              <ul>
                <li>Token quantity: {investment.token_quantity}</li>
                <li>
                  Vesting period (in months):{" "}
                  {investment.vesting_period_in_months}
                </li>
                <li>Investor ID hash: {investment.investor_id_hash}</li>
                <li>Daily vesting amount: {dailyVestingAmount}</li>
                <Typography color="red">
                  <i>
                    Calculated by: tokenQuantity / vestingPeriodInMonths /
                    AVG_DAYS_IN_MONTH (where AVG_DAYS_IN_MONTH: 30.436875){" "}
                  </i>
                </Typography>
                <li>28 day vesting total: {dailyVestingAmount * 28}</li>
              </ul>
            </Flex>
            <Flex item xs={24}>
              <Divider spacingTop={0} spacingBottom={0} />
            </Flex>
          </Flex>
        );
      })}
      <Flex item xs={24}>
        Total 28 day unvested amount: {totalVestingAmount(investments)}
      </Flex>
      <Flex item xs={8}>
        <BlockField label="Current FIL price">
          <TextInput />
        </BlockField>
      </Flex>
      <Flex item xs={8}>
        <BlockField label="Price quoted to client">
          <TextInput />
        </BlockField>
      </Flex>
      <Flex item xs={8}>
        <BlockField label="Amount to sell">
          <TextInput />
        </BlockField>
      </Flex>
      <Flex item xs={8}>
        <Button onClick={() => console.log("hi")}>Execute</Button>
      </Flex>
    </Flex>
  );
};

const useStyles = makeStyles((theme: any) => ({
  dialogPaper: {
    minHeight: "80%",
    width: "100%",
  },
}));

export default function InvestorLookUp(props) {
  const classes = useStyles();
  const [requestData, setRequestData] = useState<RequestData>(null);
  const [entityApiId, setEntityApiId] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [response, setResponse] = useState(null);
  return (
    <div>
      <Divider />
      <Flex container spacing={2}>
        <Flex item xs={24}>
          <Typography type="h2">Unvested Trading</Typography>
        </Flex>
        <Flex item xs={12}>
          <Typography>
            When a user says they would like to sell their unvested FIL, we need
            to confirm how much they are actually vesting in order to come up
            with an accurate total.
          </Typography>
        </Flex>
        <Flex item xs={12}></Flex>
        <Flex item xs={12}>
          <Button onClick={() => setOpen(true)}>Schedule Look Up</Button>
        </Flex>
        <Flex item xs={12}></Flex>
      </Flex>
      <Dialog
        classes={{ paper: classes.dialogPaper }}
        onClose={() => setOpen(false)}
        open={open}
      >
        <Flex container spacing={2} style={{ padding: 20 }}>
          <Flex item xs={24}>
            <Typography type="h6">Investor Look Up</Typography>
          </Flex>
          <Flex item xs={24}>
            <Divider spacingTop={0} spacingBottom={0} />
          </Flex>
          {response ? (
            renderResponse(response)
          ) : (
            <Flex container>
              <Loading
                handleResponse={(response) => setResponse(response)}
                loading={loading}
                requestData={requestData}
              />
              {!loading && (
                <Flex container spacing={2}>
                  <Flex item xs={24}>
                    <BlockField label="Entity API ID">
                      <TextInput
                        value={entityApiId}
                        onChange={(e) => setEntityApiId(e.target.value)}
                      />
                    </BlockField>
                  </Flex>
                  <Flex item xs={24}>
                    <Select
                      onChange={() => console.log("hi")}
                      options={[{ labelText: "Filecoin", value: "Filecoin" }]}
                    />
                  </Flex>
                  <Flex item xs={24}>
                    <Button
                      onClick={() => {
                        if (isTestEnv()) {
                          setResponse({
                            data: {
                              entity: testEntity(),
                              investments: testInvestmentsData(),
                            },
                          });
                        } else {
                          setRequestData({
                            data: {},
                            type: RequestType.GET,
                            url: `${LOOKUP_URL}?entity_api_id=${entityApiId}`,
                          });
                          setLoading(true);
                        }
                      }}
                    >
                      Search
                    </Button>
                  </Flex>
                </Flex>
              )}
            </Flex>
          )}
        </Flex>
      </Dialog>
    </div>
  );
}
