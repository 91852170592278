import * as React from "react";
import { useState } from "react";
import { ThemeProvider, Theme, StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Snackbar from "@mui/material/Snackbar";

import CollateralStats from "./components/CollateralStats";
import CollateralFormDialog from "../../admin/lend/placed_loans/components/CollateralFormDialog";
import { Button, Col, Container, Grid, Label, Spacer } from "@react/components";
import Box from "@react/components/Box";
import Flex from "@react/components/Flex";
import InfoWarning from "@react/components/warnings/InfoWarning";
import Loading from "@react/components/Loading";
import MakeFinalPaymentsDialog from "./components/MakeFinalPaymentsDialog";
import TableWithTitle from "@react/views/shared/TableWithTitle";
import Typography from "@react/components/typography/Typography";
import WalletCells from "@react/components/WalletCells";
import WalletSideBar from "@react/components/WalletSideBar";
import Warning from "@react/components/warnings/Warning";

import { theme } from "@react/utils/theme";
import { getFormattedTime } from "@react/utils/date";
import {
  getStateText,
  getTextForPlacedLoanPaymentState,
  getTextForPaymentType,
  roundFloatStr,
  isSmallOrSmaller,
} from "@react/utils";
import { generatePlacedLoanWarnings } from "@react/components/warnings";
import { LOGO_MAP } from "@react/shared/logos";
import { RequestType } from "@react/utils/network";


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


const headCells = ["Amount", "Direction", "Type", "State"];

const getTextForDirection = (type, name) => {
  return type === "fund_loan" ? `from CoinList` : `to CoinList`;
};

const getRows = (payments: Array<any>, name) => {
  return payments.map((payment) => [
    `${parseFloat(parseFloat(payment.amount_subunit).toFixed(4))} ${
      payment.symbol
    }`,
    getTextForDirection(payment.payment_type, name),
    getTextForPaymentType(payment.payment_type),
    getTextForPlacedLoanPaymentState(payment),
  ]);
};

const warningMessage = (response) => {
  if (!response) {
    return "";
  }
  if (response.data && response.data.errors) {
    return response.data.errors.message;
  }
  return "Error terminating your loan, please email lend@coinlist.co.";
};

export default function Show(props) {
  const small = isSmallOrSmaller();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requestData, setRequestData] = useState(null);
  const [response, setResponse] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [makeFinalPaymentsOpen, setMakeFinalPaymentsOpen] = useState(false);


  const placedLoans = props.placedLoans;
  const placedLoan = placedLoans[0];

  const warnings = generatePlacedLoanWarnings(
    placedLoans,
    props.priceMap,
    props.collateralBalanceMap,
    null,
    setOpen
  );

  const wallets = placedLoan.collateral_assets.map(
    ({ amount_subunit, symbol }) => ({
      amount: amount_subunit,
      assetSymbol: symbol,
      logo: LOGO_MAP[symbol.toLowerCase()],
      url: placedLoan.collateral_assets_url_map[symbol],
    })
  );

  const collateralPercentage =
    parseFloat(placedLoan.collateral_percentage) * 100;

  const walletCells = [
    {
      labelText: "Principal",
      value: `${parseFloat(
        parseFloat(placedLoan.loan_asset.amount_subunit).toFixed(4)
      )} ${placedLoan.loan_asset.symbol}`,
    },
    {
      labelText: "Duration",
      value: `${getFormattedTime(placedLoan.start_date)} - ${
        placedLoan.end_date ? getFormattedTime(placedLoan.end_date) : "TBD"
      }`,
    },
    {
      labelText: "State",
      value: getStateText(placedLoan),
    },
  ];

  if (placedLoan.collateral_required) {
    walletCells.splice(1, 0, {
      labelText: "Collateral Value",
      value: `${parseFloat(collateralPercentage.toFixed(2))}% of ${parseFloat(
        parseFloat(placedLoan.loan_asset.amount_subunit).toFixed(4)
      )} ${placedLoan.loan_asset.symbol}`,
    });
  }

  const finalizeTermination =
    placedLoan.payments &&
    placedLoan.payments.filter(
      (payment) => payment.payment_type === "principal"
    ).length > 0;

  return (
    <React.Fragment>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Container>
            <Grid>
              <Col xs="24">
                <a
                  className="c-link--muted"
                  href={props.allLoansUrl}
                  style={{ cursor: "pointer" }}
                >
                  &laquo; Back to loans
                </a>
              </Col>
            </Grid>
            <Spacer />
            <Grid>
              <Col xs="24">
                {warnings.map(
                  ({
                    backgroundColor,
                    buttonVariant,
                    buttonText,
                    onClick,
                    warningText,
                  }) => (
                    <Warning
                      button={
                        <span className="u-floatRight">
                          <Button variant={buttonVariant} onClick={onClick}>
                            {buttonText}
                          </Button>
                        </span>
                      }
                      color={backgroundColor}
                      style={{ marginBottom: 16 }}
                      warningText={warningText}
                    />
                  )
                )}
              </Col>
            </Grid>
            <Spacer />
            <Grid>
              {placedLoan.collateral_required && (
                <Col xs={small ? "24" : "6"}>
                  <CollateralStats
                    collateralPercentage={placedLoan.collateral_percentage}
                    collateralRequirement={placedLoan.collateral_requirement}
                    marginCallLevel={placedLoan.margin_call_level}
                    overCollaterallizedLevel={
                      placedLoan.over_collateralized_level
                    }
                  />
                  {wallets.map(({ amount, assetSymbol, logo, url }) => (
                    <WalletSideBar
                      amount={amount}
                      assetSymbol={assetSymbol}
                      fullWidth
                      logo={logo}
                      onClick={() => {
                        window.open(url, "_blank").focus();
                      }}
                      subtitle={`${roundFloatStr(
                        amount,
                        4
                      ).toString()} ${assetSymbol} as collateral`}
                    />
                  ))}
                </Col>
              )}
              <Col xs={small || !placedLoan.collateral_required ? "24" : "18"}>
                <Box fullWidth>
                  <Flex container>
                    <Flex
                      alignItems="center"
                      container
                      justifyContent="space-between"
                      separator
                      style={{ padding: 16 }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          alt="Logo"
                          src={
                            LOGO_MAP[placedLoan.loan_asset.symbol.toLowerCase()]
                          }
                          style={{ display: "inline", marginRight: 8 }}
                        />
                        <Typography
                          bold
                          fontSize={18}
                          style={{ display: "inline" }}
                        >
                          {placedLoan.loan_asset.symbol} Loan for{" "}
                          <a href={`/${placedLoan.entity_slug}/wallets`}>
                            {placedLoan.entity_name}
                          </a>
                        </Typography>
                      </div>
                      <Flex container justifyContent="flex-end">
                        {placedLoan.open_term && (
                          <Button
                            onClick={() => {
                              if (finalizeTermination) {
                                setMakeFinalPaymentsOpen(true);
                              } else {
                                const data = {
                                  placedLoanId: placedLoan.id,
                                };
                                setRequestData({
                                  data: data,
                                  type: RequestType.POST,
                                  url: placedLoan.terminate_loan_url,
                                });
                                setLoading(true);
                              }
                            }}
                          >
                            {loading ? (
                              <Loading
                                handleResponse={(response) => {
                                  if (response.status === 200) {
                                    setMakeFinalPaymentsOpen(true);
                                  } else {
                                    setSnackbarOpen(true);
                                  }
                                  setLoading(false);
                                  setResponse(response);
                                }}
                                loading={loading}
                                requestData={requestData}
                                spinnerColor="white"
                              />
                            ) : finalizeTermination ? (
                              "Pay & Terminate"
                            ) : (
                              "Start Termination"
                            )}
                          </Button>
                        )}
                        {placedLoan.collateral_required && (
                          <Button onClick={() => setOpen(true)}>
                            Edit Collateral
                          </Button>
                        )}
                      </Flex>
                    </Flex>
                    <Flex item xs={24}>
                      <WalletCells walletCells={walletCells} />
                    </Flex>
                  </Flex>
                </Box>
                {placedLoan.payments && placedLoan.payments.length > 0 && (
                  <div className="s-marginTop1">
                    <InfoWarning>
                      Payments will automatically be withdrawn from your CoinList
                      wallet
                    </InfoWarning>
                    <Spacer spacing={1} />
                    <TableWithTitle
                      headers={headCells}
                      rows={getRows(placedLoan.payments, props.name)}
                      title={"Payments"}
                    />
                  </div>
                )}
              </Col>
            </Grid>
          </Container>
          {open && placedLoan.collateral_required && (
            <CollateralFormDialog
              collateralAssets={placedLoan.collateral_assets}
              collateralAssetSymbols={placedLoan.valid_collateral_asset_symbols}
              collateralAssetsUrlMap={placedLoan.collateral_assets_url_map}
              collateralBalanceMap={props.collateralBalanceMap}
              collateralRequirement={parseFloat(
                placedLoan.collateral_requirement
              )}
              handleClose={() => setOpen(false)}
              loanAmountSubunit={parseFloat(placedLoan.loan_asset.amount_subunit)}
              loanAssetSymbol={placedLoan.loan_asset.symbol}
              marginCallLevel={parseFloat(placedLoan.margin_call_level)}
              open={open}
              overCollaterallizedLevel={parseFloat(
                placedLoan.over_collateralized_level
              )}
              placedLoanId={placedLoan.id}
              postCollateralUrl={placedLoan.collateral_url}
              priceMap={props.priceMap}
              startDate={placedLoan.start_date}
            />
          )}
          {makeFinalPaymentsOpen && (
            <MakeFinalPaymentsDialog
              onClose={() => setMakeFinalPaymentsOpen(false)}
              open={makeFinalPaymentsOpen}
              placedLoanId={placedLoan.id}
              setPaymentsResponse={setResponse}
              setSnackbarOpen={setSnackbarOpen}
              url={placedLoan.make_final_payments_url}
            />
          )}
          <Snackbar
            anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
            autoHideDuration={3000}
            open={snackbarOpen}
            onClose={() => setSnackbarOpen(false)}
          >
            <Warning
              color={"orange"}
              fullWidth
              fontSize={13}
              padding={8}
              variant={"warning"}
              warningText={warningMessage(response)}
            />
          </Snackbar>
        </ThemeProvider>
      </StyledEngineProvider>
    </React.Fragment>
  );
}
