require('jquery')
require('underscore')
require('ezdz')
require('jquery-form')
require('intl-tel-input/build/js/intlTelInput-jquery')
require('intl-tel-input/build/js/utils')
require('../lib/keyboard')

Partials = require('../lib/partials')

window.Entities.People = {
  identity_data_fields: ($$, $this) ->
    class IdentityDataFieldsView extends Backbone.View
      HIDDEN_CLASS: 'u-hidden'

      initialize: ->
        # Phone input
        @$phoneInput = @$(".js-phone_input")
        autocomplete = @$phoneInput.prop('autocomplete')

        $phoneInputIntTel = @$phoneInput.intlTelInput({
          initialCountry: @$phoneInput.data('userCountryCode')
        })
        @$phoneInput.attr('autocomplete', autocomplete)

        @initializePassportInput()

        Partials.with @$(Partials.selector 'addresses/address_fields'), (partial) =>
          partial.on 'countryChanged', (country) =>
            if country != partial.UNITED_STATES
              @_toggleSsn(false)
              @_toggleSsnOption(false)
            else
              @_toggleSsn(true)
              @_toggleSsnOption(true)

      # Passport/ID drag & drop
      initializePassportInput:->
        # Front-side
        $passportInput = @$('.js-passport_input')
        options = _.extend(EdznHelper.defaultOptions(), {
          text: $passportInput.data('dropzoneText'),
          validators: {
            maxSize: $passportInput.data('maxSize'),
            minWidth: $passportInput.data('minWidth'),
            minHeight: $passportInput.data('minHeight')
          }
          accept: (file) =>
            @$('.js-passport_label_highlight').removeClass('u-colorRed')
            @$('.js-dropzone-error').addClass(@HIDDEN_CLASS)
          reject: (file, errors) =>
            @$('.js-passport_label_highlight').addClass('u-colorRed')
            @$('.js-dropzone-error').removeClass(@HIDDEN_CLASS)
                                    .text(EdznHelper.messageForError(file, errors))
        })
        $passportInput.ezdz(options)

        # Back-side
        $backImageInput = @$('.js-back_image_input')
        options = _.extend(EdznHelper.defaultOptions(), {
          text: $backImageInput.data('dropzoneText'),
          validators: {
            maxSize: $backImageInput.data('maxSize'),
            minWidth: $backImageInput.data('minWidth'),
            minHeight: $backImageInput.data('minHeight')
          }
          accept: (file) =>
            @$('.js-back_image_label_highlight').removeClass('u-colorRed')
            @$('.js-back_image_error_label').addClass('u-hidden')
            @$('.js-back-dropzone-error').addClass(@HIDDEN_CLASS)
          reject: (file, errors) =>
            @$('.js-back_image_label_highlight').addClass('u-colorRed')
            @$('.js-back_image_error_label').removeClass('u-hidden')
            @$('.js-back-dropzone-error').removeClass(@HIDDEN_CLASS)
                                         .text(EdznHelper.messageForError(file, errors))
        })
        $backImageInput.ezdz(options)

      formatPhoneNumber: ->
        formattedPhoneNumber = @$phoneInput.intlTelInput('getNumber')
        @$phoneInput.val(formattedPhoneNumber)

      validatePhoneNumber: ->
        isValidNumber = @$phoneInput.intlTelInput('isValidNumber')
        unless isValidNumber
          error = @$phoneInput.intlTelInput('getValidationError')
          errorMessage = if $.trim(@$phoneInput.val()).length == 0
                           'can\'t be blank'
                         else if error == intlTelInputUtils.validationError.IS_POSSIBLE
                           'is an invalid phone number'
                         else if error == intlTelInputUtils.validationError.INVALID_COUNTRY_CODE
                           'wrong country code'
                         else if error == intlTelInputUtils.validationError.NOT_A_NUMBER
                           'wrong format'
                         else if error == intlTelInputUtils.validationError.TOO_LONG
                           'is too long'
                         else if error == intlTelInputUtils.validationError.TOO_SHORT
                           'is too short'

          @$('.js-phone-error').text(errorMessage).show()
          @$phoneInput.parent().addClass('c-input-group--has-error')

        isValidNumber

      getDropZones: ->
        passport: @$('.js-passport_file_input_wrapper').clone(),
        back_image: @$('.js-back_image_file_input_wrapper').clone()

      replaceDropZones: ($dropZoneClones) ->
        # Only replace if the current input has no error
        if @$('.js-passport_input_error').length == 0
          @$('.js-passport_file_input_wrapper').replaceWith($dropZoneClones.passport)

          # resetDropzone if user decide to change input despite valid input
          @$('.js-passport_input').one 'click', =>
            @resetDropzones()

        if @$('.js-back_image_input_error').length == 0
          @$('.js-back_image_file_input_wrapper').replaceWith($dropZoneClones.back_image)

          # resetDropzone if user decide to change input despite valid input
          @$('.js-back_image_input').one 'click', =>
            @resetDropzones()

      resetDropzones: ->
        @$('.js-passport_file_input_wrapper').html(@$('.js-passport_input'))
        @$('.js-back_image_file_input_wrapper').html(@$('.js-back_image_input'))
        @initializePassportInput()

      _toggleSsn: (showSsn) ->
        if @$('.js-require_id_input').val() == 'false'
          # Always leave this on if ID is required
          @$('.js-passport').toggleClass @HIDDEN_CLASS, showSsn
        @$('.js-ssn').toggleClass @HIDDEN_CLASS, !showSsn
        @$('.js-united_states_citizen_input').val showSsn

      _toggleSsnOption: (shouldShow) ->
        @$('.js-show_ssn').toggleClass @HIDDEN_CLASS, !shouldShow

      events:

        # Chrome and some other browser empty file input on select/cancel, we need to restart
        # dropzone
        'change .js-passport_input': (event) ->
          $target = $(event.target)
          @resetDropzones() if $target.val().length == 0

        'click .js-show_passport': (event) ->
          event.preventDefault()
          @_toggleSsn false

        'click .js-show_ssn': (event) ->
          event.preventDefault()
          @_toggleSsn true

        'click .js-edit_ssn': (event) ->
          event.preventDefault()
          @$('.js-ssn_details').addClass(@HIDDEN_CLASS)
          @$('.js-ssn_input').removeClass(@HIDDEN_CLASS)

        'click .js-edit_birthdate': (event) ->
          event.preventDefault()
          @$('.js-birthdate_details').addClass(@HIDDEN_CLASS)
          @$('.js-birthdate_input').removeClass(@HIDDEN_CLASS)

    new IdentityDataFieldsView el: $this

  identity_data_form: ($$, $this) ->
    class IdentityDataFormView extends Backbone.View
      events:
        'click .js-save': (event) ->
          event.preventDefault()
          Partials.with Partials.selector('entities/people/identity_data_fields'), (partial) =>
            if partial.validatePhoneNumber()
              partial.formatPhoneNumber()
              $target = $(event.target)
              return if $target.hasClass 'c-button--loading'
              $target.addClass 'c-button--loading'
              $target.closest('form').ajaxSubmit
                success: (response) =>
                  window.location.href = response.redirect
                error: (response) =>
                  if response.status == 422
                    $dropZoneClones = partial.getDropZones()
                    @$el.replaceWith response.responseJSON.html
                    Partials.with Partials.selector('entities/people/identity_data_fields'), (p) =>
                      p.replaceDropZones($dropZoneClones)
                  else
                    Tooltips.formServerError($target)

    new IdentityDataFormView el: $this

  money_services_business_information_fields: ($$, $this) ->
    class MoneyServicesBusinessInformationFieldsView extends Backbone.View
      netverifySuccess: ->
        @$('.js-netverify-iframe').hide()
        @$('.js-netverify-success').show()

      netverifyError: ->
        @$('.js-netverify-iframe').hide()
        @$('.js-netverify-error').show()

    new MoneyServicesBusinessInformationFieldsView el: $this

  new_form: ($$, $this) ->
    class NewFormView extends Backbone.View
      events:
        'keydown input[type="text"]': (event) ->
          if event.keyCode == Keyboard.enter
            event.preventDefault()

    new NewFormView el: $this

  acts_like_person_assets_form: ($$, $this) ->
    class ActsLikePersonAssetsForm extends Backbone.View
      HIDDEN_CLASS: 'u-hidden'

      submit: ->
        deferred = $.Deferred()

        @$('form').ajaxSubmit
          success: (response) =>
            window.location.href = response.redirect
            deferred.resolve()
          error: (response) =>
            if response.status == 422
              $html = $(response.responseJSON.html)
              @$el.replaceWith($html)
              Animate.niceScrollToFirstError($html)
            deferred.reject(response)

        deferred.promise()

      _toggleSsnInput: (showSsnInput) ->
        @$('.js-ssn_input').toggleClass @HIDDEN_CLASS, !showSsnInput
        @$('.js-ssn_display').toggleClass @HIDDEN_CLASS, showSsnInput

      _toggleSsn: (showSsn) ->
        @$('.js-ssn').toggle showSsn
        @$('.js-credit_report').toggle !showSsn

      events:
        'change .js-united_states_citizen': (event) ->
          $target = $(event.target)
          @_toggleSsn $target.val() == true.toString()

        'click .js-show_ssn_input': ->
          @_toggleSsnInput true

        'click .js-hide_ssn_input': ->
          @_toggleSsnInput false

        'change .js-proof_type': (event) ->
          $target = $(event.target)
          proof_type = $target.val()

          # Hide all inputs for the other proof options except the selected one
          $('.js-proof_option').hide()
          $('.js-proof_' + proof_type).show()

          # If it's third party, we need to hide the credit report upload and hint
          $('.js-third_party_hidden').toggle(proof_type != 'third_party')

    new ActsLikePersonAssetsForm el: $this

  acts_like_person_income_form: ($$, $this) ->
    class ActsLikePersonIncomeForm extends Backbone.View

      submit: ->
        deferred = $.Deferred()

        @$('form').ajaxSubmit
          success: (response) =>
            window.location.href = response.redirect
            deferred.resolve()
          error: (response) =>
            if response.status == 422
              $html = $(response.responseJSON.html)
              @$el.replaceWith($html)
              Animate.niceScrollToFirstError($html)
            deferred.reject(response)

        deferred.promise()

    new ActsLikePersonIncomeForm el: $this

  accreditation_form: ($$, $this) ->
    class AccreditationForm extends Backbone.View
      HIDDEN_CLASS: 'u-hidden'
      INCOME: 'income'
      ASSETS: 'assets'

      getBasis: ->
        @$('.js-basis input:checked').val()

      replaceMultipleUploadForms: ->
        Partials.with Partials.selector('components/multiple_file_upload'), (partial) =>
          htlm = @multipleFileUploadForms[partial.uniqIndex()]
          if htlm.length != 0 && partial.$el.find('.js-form_error').length == 0
            htlm.find('.js-form_error').remove()
            partial.$el.replaceWith htlm

      saveMultipleFileUploadsForms: ->
        @multipleFileUploadForms = {}
        Partials.with Partials.selector('components/multiple_file_upload'), (partial) =>
          @multipleFileUploadForms[partial.uniqIndex()] = partial.$el.clone()

      events:
        'click .js-create': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass 'c-button--loading'
          $target.addClass 'c-button--loading'
          basis = @getBasis()

          @saveMultipleFileUploadsForms()

          if basis == @ASSETS
            Partials.with @$(Partials.selector 'entities/people/acts_like_person_assets_form'), (form) =>
              form.submit().catch (response) =>
                if response.status == 422
                  $target.removeClass 'c-button--loading'
                  @replaceMultipleUploadForms()
                else
                  Tooltips.formServerError($target)

          else if basis == @INCOME
            Partials.with @$(Partials.selector 'entities/people/acts_like_person_income_form'), (form) =>
              form.submit().catch (response) =>
                if response.status == 422
                  $target.removeClass 'c-button--loading'
                  @replaceMultipleUploadForms()
                else
                  Tooltips.formServerError($target)

        'change .js-basis': (event) ->
          $basisContent = @$(".js-#{@getBasis()}_content")
          if $basisContent.is(':hidden')
            @$('.js-basis_content').addClass @HIDDEN_CLASS
            $basisContent.removeClass @HIDDEN_CLASS

    new AccreditationForm el: $this

}
