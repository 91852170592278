PrettyAlert = require('./lib/pretty_alert')
window.PlaidIntegration = {

  plaidEnvironment: (env) ->
    if env == 'production'
      env
    else
      'development'

  plaid_link_update: ($$, $this) ->
    class PlaidLinkUpdateView extends Backbone.View
      initialize: ->
        if Plaid?
          @initializePlaid()
        else
          @$('.js-update-link').hide()
          @$('.js-plaid-script-blocked-message').show()

      initializePlaid: ->

        @$updateLink = @$('.js-update-link')

        clientName = @$updateLink.data('client-name')
        key = @$updateLink.data('key')
        product = @$updateLink.data('product')
        token = @$updateLink.data('publicToken')

        environment = PlaidIntegration.plaidEnvironment($('.js-environment').data('environment'))

        return unless Plaid?
        @plaidHandler = Plaid.create
          clientName: clientName,
          env: environment,
          key: key,
          product: [product],
          token: token,
          onEvent: (eventName, metadata) =>
            if eventName == 'ERROR'
              Rollbar.info("Plaid link error #{metadata.error_code} #{metadata.error_message}. Request ID: #{metadata.request_id}")
          onSuccess: (public_token, metadata) =>
            $.ajax
              url: @$updateLink.data('url'),
              type: 'POST',
              success: (response) =>
                $('.js-auto_accreditation_form').replaceWith response.html
      events:
        'click .js-update-link': (event) ->
          event.preventDefault()
          return unless @plaidHandler?
          @plaidHandler.open()

    new PlaidLinkUpdateView el: $this

  plaid_link: ($$, $this) ->
    class PlaidLinkView extends Backbone.View
      LOADING_CLASS = 'c-button--loading'

      initialize: ->
        if Plaid?
          @initializePlaid()
        else
          @$('.js-add-bank-account').hide()
          @$('.js-plaid-script-blocked-message').show()

      initializePlaid: ->
        $('.js-add-bank-account').removeClass('c-button--loading')
        environment = PlaidIntegration.plaidEnvironment($('.js-environment').data('environment'))
        entity_id = @$('.js-add-bank-account').data('entityId')
        entity_type = @$('.js-add-bank-account').data('entityType')
        institution_ids = @$('.js-add-bank-account').data('institutionIds').split(',')
        @items_linked = @$('.js-add-bank-account').data('itemsLinked')
        plaid_button = @$('.js-add-bank-account')

        @plaidHandler = Plaid.create
          clientName: 'CoinList',
          env: environment,
          key: 'a0410ee89720d664683c146534da7c',
          product: ['assets'],
          onEvent: (eventName, metadata) =>
            if eventName == 'SELECT_INSTITUTION'
              if metadata.institution_id in institution_ids
                @plaidHandler.exit({ force: true })
                @$('.js-duplicate-error').show()
              else
                @$('.js-duplicate-error').hide()
            if eventName == 'ERROR'
              Rollbar.info("Plaid link error #{metadata.error_code} #{metadata.error_message}. Request ID: #{metadata.request_id}")
          onSuccess: (public_token, metadata) =>
            plaid_button.addClass LOADING_CLASS
            $.ajax
              url: @$('.js-add-bank-account').data('url'),
              type: 'POST',
              data: {
                plaid_integration: {
                  public_token: public_token,
                  entity_id: entity_id,
                  entity_type: entity_type,
                  metadata: metadata
                }
              },
              success: (response) =>
                $('.js-auto_accreditation_form').replaceWith response.html
                plaid_button.removeClass LOADING_CLASS
              error: (response) =>
                Tooltips.formServerError(plaid_button)
                plaid_button.removeClass LOADING_CLASS

      events:
        'click .js-add-bank-account': (event) ->
          event.preventDefault()
          return unless @plaidHandler?
          $target = $(event.target)
          return if $target.hasClass LOADING_CLASS
          return if @items_linked >= 3
          @plaidHandler.open()

    new PlaidLinkView el: $this

  plaid_link_bank: ($$, $this) ->
    class PlaidLinkBankView extends Backbone.View
      LOADING_CLASS = 'c-button--loading'

      initialize: ->
        if Plaid?
          @initializePlaid()
        else
          @$('.js-add-bank-account').hide()
          @$('.js-plaid-script-blocked-message').show()

      initializePlaid: ->
        $('.js-add-bank-account').removeClass('c-button--loading')
        environment = PlaidIntegration.plaidEnvironment($('.js-environment').data('environment'))
        entity_id = @$('.js-add-bank-account').data('entityId')
        entity_type = @$('.js-add-bank-account').data('entityType')
        plaid_button = @$('.js-add-bank-account')
        return unless Plaid?
        @plaidHandler = Plaid.create
          clientName: 'CoinList',
          env: environment,
          selectAccount: true,
          key: 'a0410ee89720d664683c146534da7c',
          product: ['auth'],
          onSuccess: (public_token, metadata) =>
            plaid_button.addClass LOADING_CLASS
            $.ajax
              url: @$('.js-add-bank-account').data('url'),
              type: 'POST',
              data: {
                plaid_integration: {
                  public_token: public_token,
                  entity_id: entity_id,
                  entity_type: entity_type,
                  metadata: metadata
                }
              },
              success: (response) =>
                window.location = response.redirect
              error: (response) =>
                base_errors = response.responseJSON.errors.base
                if base_errors && base_errors[0] == 'account_already_exists'
                  PrettyAlert.alert('This bank account has already been linked. For security reasons, we
                                     only allow a bank account to be linked to one entity at a time.
                                     Please email team@coinlist.co if this is a mistake, or if you need
                                     to link this bank account to multiple entities.')
                else if base_errors && base_errors[0] == 'too_mank_relinks'
                  PrettyAlert.alert('This bank account is linked too many times. If you have questions,
                                     please email team@coinlist.co.')
                else
                  Tooltips.formServerError(plaid_button)
                plaid_button.removeClass LOADING_CLASS

      events:
        'click .js-add-bank-account': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return unless @plaidHandler?
          return if $target.hasClass LOADING_CLASS
          return if @items_linked >= 3
          @plaidHandler.open()

    new PlaidLinkBankView el: $this
}
