import * as React from "react";

import Dialog from "@mui/material/Dialog";
import makeStyles from '@mui/styles/makeStyles';
import cx from "classnames";

import { ICON_MAP } from "../../../../shared/icons";

import { Entity } from "@react/views/walletLink/types";
import { Divider } from "@react/components";
import Flex from "@react/components/Flex";
import Typography from "@react/components/typography/Typography";
import AccountEntitySelectorPlusIcon from "./icons/AccountEntitySelectorPlusIcon";

const AccountEntitySelector: React.FC<{
  open: boolean;
  onClose: () => void;
  selectedEntity: Entity;
  entities: Entity[];
  onAddEntity: () => void;
  onSelectEntity: (entity: Entity) => void;
}> = ({
  open,
  onClose,
  selectedEntity,
  entities,
  onAddEntity,
  onSelectEntity,
}) => {
  const classes = useStyles();

  const entityCells = entities.map((entity) => (
    <li key={entity.slug}>
      <button
        onClick={() => onSelectEntity(entity)}
        className={cx(classes.entityCell)}
        style={{
          background:
            entity.slug == selectedEntity.slug
              ? "rgb(51, 51, 51, 0.05)"
              : "rgb(51, 51, 51, 0)",
        }}
      >
        <Typography fontSize={15} style={{ color: "#333333", fontWeight: 600 }}>
          {entity.name}
        </Typography>
        <Typography fontSize={15} style={{ color: "#333333", opacity: 0.5 }}>
          {entity.type}
        </Typography>
      </button>
    </li>
  ));

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      fullWidth
      onClose={onClose}
      open={open}
    >
      <Flex container flexDirection="column" alignItems="strech">
        <Flex
          container
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <img
            src={ICON_MAP.xMarkIcon}
            style={{ padding: "16px", opacity: 0 }}
          />
          <Typography fontSize={17}>Switch Entity</Typography>
          <button onClick={onClose} className={cx(classes.xButton)}>
            <img src={ICON_MAP.xMarkIcon} style={{ padding: "16px" }} />
          </button>
        </Flex>

        <Divider spacingBottom={0} spacingTop={0.5} />

        <ul style={{ listStyle: "none", margin: 0, padding: "16px" }}>
          {entityCells}
        </ul>

        <button onClick={onAddEntity} className={cx(classes.addEntityButton)}>
          <AccountEntitySelectorPlusIcon />
          <Typography fontSize={15} style={{ fontWeight: 600 }}>
            Add a New Entity
          </Typography>
        </button>
      </Flex>
    </Dialog>
  );
};

export default AccountEntitySelector;

const useStyles = makeStyles(() => ({
  dialogPaper: {
    minHeight: 100,
    maxWidth: 400,
    width: "100%",
    borderRadius: 20,
  },
  xButton: {
    borderStyle: "solid",
    background: "rgba(255,255,255,0)",
    borderWidth: 0,
    borderColor: "rgba(255,255,255,0)",
    padding: 0,
    boxShadow: "none",
    "&:hover": {
      background: "rgba(255,255,255,0)",
    },
  },
  addEntityButton: {
    display: "flex",
    flexDirection: "row",
    borderStyle: "solild",
    background: "rgba(255,255,255,0)",
    borderWidth: "1.5px",
    borderColor: "rgb(224, 224, 224)",
    padding: "12px",
    margin: "16px",
    boxShadow: "none",
    gap: "4px",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      background: "rgba(255,255,255,0)",
    },
  },
  entityCell: {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
    padding: "8px",
    borderStyle: "solild",
    borderWidth: 0,
    borderRadius: "12px",
    boxShadow: "none",
    width: "100%",
  },
}));
