require('jquery-form')
require('./lib/keyboard')
Partials = require('./lib/partials')

window.Entities =
  new_form: ($$, $this) ->
    class NewFormView extends Backbone.View
      initialize: ->
        @entityTypePerson = @$('> .js-data').data('entityTypePerson')

      submitForm: (event) ->
        event.preventDefault()
        $target = $(event.target)
        return if $target.hasClass 'c-button--loading'
        $target.addClass 'c-button--loading'
        @$('.js-new_entity:visible').find('form').ajaxSubmit
          success: (response) =>
            window.location.href = response.redirect
          error: (response) =>
            if response.status == 422
              @$el.replaceWith response.responseJSON.html
              $target.removeClass 'c-button--loading'
            else
              Tooltips.formServerError($target)

      events:
        'change .js-entity_type': (event) ->
          $target = $(event.target)
          entityType = $target.val()
          $activeEntityType = @$(".js-new_#{entityType}")
          if $activeEntityType.is(':hidden')
            @$('.js-new_entity').hide()
            @$('.js-entity_type').removeClass 'c-tab__item--active'
            $target.addClass 'c-tab__item--active'
            $activeEntityType.show()

    new NewFormView el: $this

  new: ($$, $this) ->
    class NewView extends Backbone.View
      events:
        'click .js-create_new_entity': (event) ->
          Partials.with Partials.selector('entities/new_form'), (partial) =>
            partial.submitForm(event)

    new NewView el: $this

  verify: ($$, $this) ->
    class VerifyView extends Backbone.View
      initialize: ->
        JumioClient.setVars({
          authorizationToken: @$('.js-data').data('token'),
          responsiveLayout: true
        }).initVerify("jumio-frame")

    new VerifyView el: $this

  international_accreditation_form: ($$, $this) ->
    class InternationalAccreditationFormView extends Backbone.View
      initialize: ->
        @updateDisplayForDocumentRequirements()

      updateDisplayForDocumentRequirements: ->
        _.each $('.js-document_requirement'), (element) -> $(element).addClass('u-hidden')
        checked_values = _.map $('.js-criteria_container input:checked'), (element) -> $(element).val()
        if checked_values.length > 0
          _.each checked_values, (value) =>
            @$("#js-document_requirement_#{value}").removeClass('u-hidden')
          if $('.js-document_requirement').not(".u-hidden").length > 0
            @$(".js-file_upload_section").removeClass('u-hidden')
          else
            @$(".js-file_upload_section").addClass('u-hidden')
        else
          @$('.js-file_upload_section').addClass('u-hidden')

      saveMultipleFileUploadsForms: ->
        @multipleFileUploadForms = {}
        Partials.with Partials.selector('components/multiple_file_upload'), (partial) =>
          @multipleFileUploadForms[partial.uniqIndex()] = partial.$el.clone()

      events:
        'click .js-create': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass 'c-button--loading'
          $target.addClass 'c-button--loading'
          @saveMultipleFileUploadsForms()
          @$('form').ajaxSubmit
            success: (response) =>
              window.location.href = response.redirect
            error: (response) =>
              if response.status == 422
                @$el.replaceWith response.responseJSON.html
                $target.removeClass 'c-button--loading'
              else
                Tooltips.formServerError($target)

        'change .js-accreditation_criteria_option': (event) ->
          @updateDisplayForDocumentRequirements()


    new InternationalAccreditationFormView el: $this

  secure_upload: ($$, $this) ->
    class SecureUploadView extends Backbone.View
      initialize: ->
        @$submitButton = $('.js-submit')

      events:
        'change .js-file_input': (event) ->
          $target = $(event.target)
          @$submitButton.attr 'disabled', !$target.val()

    new SecureUploadView el: $this

  one_time_secure_upload: ($$, $this) ->
    class SecureUploadView extends Backbone.View
      initialize: ->
        @$submitButton = $('.js-submit')

      events:
        'change .js-file_input': (event) ->
          $target = $(event.target)
          @$submitButton.attr 'disabled', !$target.val()

    new SecureUploadView el: $this

  ac_one_time_secure_upload: ($$, $this) ->
    class SecureUploadView extends Backbone.View
      initialize: ->
        @$submitButton = $('.js-submit')

      events:
        'change .js-file_input': (event) ->
          $target = $(event.target)
          @$submitButton.attr 'disabled', !$target.val()

    new SecureUploadView el: $this

