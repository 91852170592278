import * as React from "react";

import Flex from "@react/components/Flex";
import Typography from "@react/components/typography/Typography";

export default function Disclaimer(props) {
  return (
    <Flex container>
      <Typography>
        <i>
          Disclaimer: The minimum order size is $50,000 USD. Eligibility for OTC trading is subject to AML/KYC and other requirements.
          Flat fee of 0.50% per trade. Exceptions may apply.
        </i>
      </Typography>
    </Flex>
  );
}
