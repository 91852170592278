Partials = require('../lib/partials')

window.Market.Karma = {
  history: ($$, $this)->
    class KarmaHistory extends Backbone.View
      HIDDEN_CLASS = 'u-hidden'

      initialize: ->
        Partials.with Partials.selector('entities/shared/entity_selector'), (partial) =>
          partial.on 'slugChange', (slug) =>
            window.location = "/#{slug}/karma/history"

      events:
        'click .js-toggle-expired-weekly-karma': (event) ->
          @$('.js-expired_weekly_karma').toggleClass HIDDEN_CLASS

        'click .js-toggle-expired-boosts-karma': (event) ->
          @$('.js-expired_boosts_karma').toggleClass HIDDEN_CLASS

    new KarmaHistory el: $this


  index: ($$, $this)->
    class Karma extends Backbone.View
      initialize: ->
        Partials.with Partials.selector('entities/shared/entity_selector'), (partial) =>
          partial.on 'slugChange', (slug) =>
            window.location = "/#{slug}/karma"

    new Karma el: $this
}