import * as React from "react";
import { useState } from "react";
import { Radio, InlineLabel } from "@react/components";
import InputWithAddon from "@react/views/shared/forms/InputWithAddon/InputWithAddon";
import RadioCollections from "@react/views/shared/forms/RadioCollections/RadioCollections";

interface RadioSectionProps {
  // props
}

const RadioSection: React.FunctionComponent<RadioSectionProps> = (
  {
    // props
  }
) => {
  const checkboxWithLabel = `<InlineLabel>
  <Radio />
  Check me !
</InlineLabel>`;

  return (
    <div>
      <div className="s-marginBottom2">
        <h2 className="s-fontSize26 s-borderBottom0_5 s-marginBottom1 u-colorGray2">
          Radio
        </h2>
        <p className="s-marginBottom0_5 u-colorGray6">
          An implementation of{" "}
          <code className="c-code c-code--inline">
            {'<input type="radio">'}
          </code>{" "}
          input
        </p>
        <p className="s-marginBottom1 u-colorBlue">
          Note: You're most likely looking for{" "}
          <code className="c-code c-code--inline">
            <a href="#RadioCollections">{"<RadioCollections>"}</a>
          </code>{" "}
          to compose a list of radio inputs
        </p>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">Default</h3>
        <pre>
          <Radio />
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">Checked</h3>
        <pre>
          <Radio checked />
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">Disabled</h3>
        <pre>
          <Radio disabled />
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          With Label
        </h3>
        <pre>
          <InlineLabel>
            <Radio />
            Check me !
          </InlineLabel>
        </pre>
      </div>
    </div>
  );
};

export default RadioSection;
