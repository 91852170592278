require('../../lib/keyboard')
PrettyAlert = require('../../lib/pretty_alert')
Partials = require('../../lib/partials')
import * as Castle from '@castleio/castle-js'

window.Market.Accounts.Security =

  sessions: ($$, $this) ->
    class SessionsView extends Backbone.View

      handlePost: ($target) ->
        $.post($target.prop('href'), _method: 'delete').done (response) =>
          @$el.replaceWith response.html

      events:
        'click .js-destroy': (event) ->
          event.preventDefault()
          $target = $(event.currentTarget)
          @handlePost($target)

        'click .js-destroy_all': (event) ->
          event.preventDefault()
          $target = $(event.target)
          @handlePost($target)

    new SessionsView el: $this

  security_key_enabled: ($$, $this) ->
    class SecurityKeyEnabledView extends Backbone.View

      events:
        'click .js-add_key': (event) ->
          event.preventDefault()
          $target = $(event.target)
          $target.hide()
          @$('.js-setup').slideDown 'fast'

        'click .js-edit_nickname': (event) ->
          event.preventDefault()
          @$('.js-nickname').show()
          @$('.js-nickname_form').hide()
          $key = $(event.target).closest('.js-key')
          $key.find('.js-nickname').hide()
          $key.find('.js-nickname_form').slideDown 'fast'

        'click .js-cancel_nickname_edit': (event) ->
          event.preventDefault()
          $key = $(event.target).closest('.js-key')
          $key.find('.js-nickname_form').slideUp 'fast', ->
            $key.find('.js-nickname').show()


    new SecurityKeyEnabledView el: $this

  security_key_setup: ($$, $this) ->
    class SecurityKeySetupView extends Backbone.View

      register: ->
        Partials.with @$(Partials.selector 'webauthn_credentials/app'), (app) =>
          app.get().register()
            .then => window.location.reload()
            .catch (error) =>
              @$('.js-waiting').hide()
              @$('.js-error').show()
              Rollbar.error("Webauthn register error", error)

      events: ->
        'click .js-set_up': ->
          event.preventDefault()
          @$('.js-disabled').hide()
          @$('.js-steps').slideDown 'fast'

        'click .js-inserted': ->
          event.preventDefault()
          @$('.js-insert_key').hide()
          @$('.js-activate_key').slideDown 'fast'
          @register()

        'click .js-retry': ->
          @$('.js-waiting').show()
          @$('.js-error').hide()

          @register()

    new SecurityKeySetupView el: $this

  totp_form: ($$, $this) ->
    class MfaFormView extends Backbone.View

      submit: ->
        $target = @$('.js-enable')
        return if $target.hasClass('c-button--loading')
        $target.addClass('c-button--loading')
        if castleData?.castlePublishKey
          Castle.createRequestToken().then (token) ->
            $('#castle_request_token').val(token)
        @$('form.js-enable-form').ajaxSubmit
          success: (response) =>
            PrettyAlert.alert(response.html, 
              success: -> window.location.reload()
            )
          error: (response) =>
            $target.removeClass('c-button--loading')
            if response.status == 422
              @$el.replaceWith response.responseJSON.html
            else
              Tooltips.formServerError($target)

      events:
        'click .js-enable': (event) ->
          event.preventDefault()
          @submit()

        'click .js-show_manual_setup': (event) ->
          event.preventDefault()
          $target = $(event.target)
          $target.hide()
          @$('.js-manual_setup').fadeIn()

        'keydown .js-code': (event) ->
          if event.keyCode == Keyboard.enter
            event.preventDefault()
            @submit()

    new MfaFormView el: $this

  totp_enabled: ($$, $this) ->
    class MfaEnabledView extends Backbone.View
      events:
        'click .js-disable': (event) ->
          unless @$('.js-password').is(':visible')
            event.preventDefault()
            @$('.js-password').removeClass 'u-hidden'
          else
            if castleData?.castlePublishKey
              Castle.createRequestToken().then (token) ->
                $('#castle_request_token').val(token)

        'click .js-show_backup_codes': (event) ->
          event.preventDefault()
          $target = $(event.target)
          $target.hide()
          @$('.js-backup_codes').slideDown 'fast'

    new MfaEnabledView el: $this

  close_account: ($$, $this) ->
    class CloseAccountView extends Backbone.View
      REQUIRED_CLOSE_TEXT: 'CLOSE'
      REQUIRED_CANCEL_TEXT: 'CANCEL'

      enableSubmit: ->
        @$('.js-submit').removeClass 'c-button--disabled'

      disableSubmit: ->
        @$('.js-submit').addClass 'c-button--disabled'

      canSubmit: ->
        !@$('.js-submit').hasClass 'c-button--disabled'

      events:
        'keydown .js-confirmation': (event) ->
          if event.keyCode == Keyboard.enter
            event.preventDefault()
            return false

        'input .js-confirmation-close': ->
          if @$('.js-confirmation-close').val() == @REQUIRED_CLOSE_TEXT
            @enableSubmit()
          else
            @disableSubmit()

        'input .js-confirmation-cancel': ->
          if @$('.js-confirmation-cancel').val() == @REQUIRED_CANCEL_TEXT
            @enableSubmit()
          else
            @disableSubmit()

        'click .js-submit': (event) ->
          event.preventDefault() unless @canSubmit()

    new CloseAccountView el: $this
