require('jquery')
require('underscore')
require('qtip2')
require('jquery.initialize/jquery.initialize')
autosize = require('autosize')
Clipboard = require('clipboard')

window.CopyToClipboardInput =

  initialize: ->
    $.initialize '.js-clipboard_input', =>
      @initializeCopyToClipboardInput()

  initializeCopyToClipboardInput: ->
    $('.js-clipboard_input').toArray().forEach (field) =>
      $field = $(field)
      return if $field.data('clipboard-instance')

      autosize($field)

      $button = $field.next('.c-button')
      inputValue = $field.data('clipboardText') || $field.prop('value')
      $button.attr('data-clipboard-text', inputValue)

      $field.on 'focus click', =>
        $field.select()

      clipboard = new Clipboard($button.get(0))

      clipboard.on 'success', (e) ->
        $field.trigger('focus')
        $button.attr('title', 'Copied!')
        options = Tooltips.presetSimple()
        options = _.extend options,
          show:
            ready: true
          hide:
            event: false
        $button.qtip(options)
        setTimeout(->
          $button.qtip('destroy', true)
        , 2000)

      $field.data('clipboard-instance', clipboard)
