Pikaday = require('pikaday')

window.Admin.KycAmlStatuses = {
  index: ($$, $this) ->
    class IndexView extends Backbone.View
      HIDDEN_CLASS = 'u-hidden'

      CHECKBOXES_TO_HIDDEN_INPUTS = {
        '#filter_entity_type_only_filter': '.js-only_entity_type_select'
        '#filter_used_in_offering': '.js-offering_slug_input'
        '#filter_ctry_codes_filter': '.js-country_code_input'
        '#filter_use_last_login_date_range': '.js-last_login_datepicker'
      }

      initialize: ->
        last_login_date_start_picker = new Pikaday({ field: $('#filter_last_login_date_start')[0] })
        last_login_date_end_picker = new Pikaday({ field: $('#filter_last_login_date_end')[0] })

        this._update_hidden_inputs()

      _update_hidden_inputs: ->
        for checkbox, input of CHECKBOXES_TO_HIDDEN_INPUTS
          if @$(checkbox)[0].checked
            @$(input).removeClass HIDDEN_CLASS
          else
            @$(input).addClass HIDDEN_CLASS

      events:
        # TODO would be nice to DRY up with CHECKBOXES_TO_HIDDEN_INPUTS ids
        'click #filter_entity_type_only_filter': ->
          this._update_hidden_inputs()
        'click #filter_used_in_offering': ->
          this._update_hidden_inputs()
        'click #filter_ctry_codes_filter': ->
          this._update_hidden_inputs()
        'click #filter_use_last_login_date_range': ->
          this._update_hidden_inputs()

    new IndexView el: $this
}
