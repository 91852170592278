import * as React from "react";
import { StakingAsset } from "../../types/staking";

import Box from "../Box";
import Flex from "../Flex";
import { Label } from "@react/components";
import StakingButtons from "./StakingButtons";
import Typography from "../typography/Typography";
import { LOGO_MAP } from "@react/shared/logos";

interface StakingBoxMobileProps {
  asset: StakingAsset;
  moreOptionsOnClick: () => void;
  stakeOnClick: () => void;
  unstakeOnClick: () => void;
  walletCells: Array<any>;
  userId: string;
  sessionId: string;
}

export default function StakingBoxMobile(props: StakingBoxMobileProps) {
  const { asset, walletCells } = props;

  return (
    <Box id={"mainContainer"} fullWidth>
      <Flex container>
        {/* TITLE & BUTTONS */}
        <Flex
          alignItems="center"
          container
          justifyContent="space-between"
          separator
          spacing={2}
          style={{ padding: 16 }}
        >
          <div style={{ flexGrow: 1, verticalAlign: "center" }}>
            <img
              alt={`${asset.symbol} Logo`}
              src={LOGO_MAP[asset.symbol.toLowerCase()]}
              style={{
                display: "inline-block",
                height: 25,
                marginRight: 8,
                width: 25,
              }}
            />
            <Typography bold fontSize={18} style={{ display: "inline-block" }}>
              {asset.symbol} Staking
            </Typography>
          </div>
          <Flex
            container
            justifyContent="flex-end"
            spacing={1}
            style={{ padding: 4 }}
          >
            <StakingButtons
              asset={asset}
              mobile={true}
              moreOptionsOnClick={props.moreOptionsOnClick}
              stakeOnClick={props.stakeOnClick}
              unstakeOnClick={props.unstakeOnClick}
              userId={props.userId}
              sessionId={props.sessionId}
            />
          </Flex>
        </Flex>
        {/* CELLS TO DISPLAY INFO */}
        <Flex container spacing={3} style={{ padding: 16 }}>
          {walletCells.map(({ labelText, value }) => {
            return (
              <Flex item justifyContent="center" key={labelText} xs={12}>
                <Label center>{labelText}</Label>
                <div
                  style={{
                    fontSize: "calc(10px + 1.5vw)",
                    lineHeight: "16px",
                  }}
                >
                  {value}
                </div>
              </Flex>
            );
          })}
        </Flex>
      </Flex>
    </Box>
  );
}
