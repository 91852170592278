window.Hackathons.Projects.Screenshots =
  list: ($$, $this) ->
    class FormView extends Backbone.View
      events: ->
        'click .js-submit': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass 'c-button--loading'

          $target.addClass 'c-button--loading'
          @$('form').ajaxSubmit
            success: (response) =>
              $html = $(response.html)
              @$el.replaceWith $html
            error: (response) =>
              if response.status == 422
                $html = $(response.responseJSON.html)
                @$el.replaceWith $html
              else
                Tooltips.formServerError($target)

        'click .js-remove': (event) ->
          event.preventDefault()
          $target = $(event.target)

          $.ajax
            url: $target.attr('data-url')
            method: 'DELETE'
            success: (response) =>
              @$el.replaceWith(response.html)


    new FormView el: $this
