window.Distributions =
  index: ($$, $this) ->
    class IndexView extends Backbone.View
      LOADING_CLASS = 'c-button--loading'

      initialize: ->
        @$('.js-wallet-info').qtip(@_tooltipOptions())

      _tooltipOptions: ->
        position:
          my: 'top right'
          at: 'bottom center'
        show:
          solo: true
        hide:
          event: 'unfocus'
          distance: 500
        style:
          classes: "c-tooltip c-tooltip--white qtip-distributions-wallet-info"
          tip:
            border: 1
            height: 4
            width: 12

      events:
        'click .js-confirm-address-button': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass LOADING_CLASS

          $target.addClass LOADING_CLASS
          $.ajax
            url: $target.attr('data-url'),
            type: 'PATCH',
            success: (response) =>
              window.location.href = response.redirect_path
            error: (response) =>
              Tooltips.formServerError($target)

    new IndexView el: $this
