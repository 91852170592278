import * as React from "react";
import { Link, Icon, Label, Divider } from "@react/components";

export interface GovernanceProtocolsShowSidebarProps {
  protocol: any;
}

const GovernanceProtocolsShowSidebar: React.FunctionComponent<GovernanceProtocolsShowSidebarProps> = ({
  protocol,
}) => {
  const token_profile = protocol.token_profile;

  const sidebarLinksSection = () => {
    if (token_profile.website_url || token_profile.medium_url) {
      return (
        <div className="s-fontSize14 s-marginTop1">
          {token_profile.website_url && (
            <span className="s-marginRight1_5">
              <Link target="_blank" href={token_profile.medium_url}>
                <Icon icon="globe" /> Website
              </Link>
            </span>
          )}
          {token_profile.medium_url && (
            <span className="s-marginRight1_5">
              <Link target="_blank" href={token_profile.medium_url}>
                <Icon icon="medium" /> Medium
              </Link>
            </span>
          )}
        </div>
      );
    }
  };

  return (
    <div className="c-box s-padding2">
      <div className="u-displayFlex u-alignItemsCenter">
        <div className="c-avatar">
          <img
            className="governance-protocols-shared-sidebar__asset_logo"
            alt={token_profile.name}
            title={token_profile.name}
            src={token_profile.logo_url}
          />
        </div>
        <div className="u-flex1 s-paddingLeft1">
          <h2 className="s-fontSize22 u-fontWeight700">
            {token_profile.name}
            <span className="u-colorGrayA u-fontWeight400 s-marginLeft0_5">
              {token_profile.symbol}
            </span>
          </h2>
        </div>
      </div>
      <div className="s-fontSize14 u-colorGray8 s-marginTop1_5">
        {token_profile.about}
      </div>
      {sidebarLinksSection()}
    </div>
  );
};

export default GovernanceProtocolsShowSidebar;
