import * as React from "react";
import { useState } from "react";
import makeStyles from '@mui/styles/makeStyles';

import Dialog from "@mui/material/Dialog";
import Snackbar from "@mui/material/Snackbar";

import Flex from "@react/components/Flex";
import { Hint, Divider } from "@react/components";
import Switch from "@react/components/Switch";
import Typography from "@react/components/typography/Typography";
import Warning from "@react/components/warnings/Warning";

import { axiosRequest, RequestType } from "../../../../utils/network";
import { isExtraSmallOrSmaller } from "../../../../utils";

interface StakeDialogProps {
  assetSymbol: string;
  autoStaked: boolean;
  autoStakingOptOut: boolean;
  changeAutoStake: (boolean, string) => void;
  onClose: () => void;
  open: boolean;
  selectedEntityId: string;
  url: string;
}

const CONTAINER_PADDING = 20;

const useStyles = makeStyles(() => ({
  dialogPaper: {
    minHeight: 470,
    maxWidth: 550,
    width: "100%",
  },
  dialogPaperXs: {
    minHeight: 600,
    maxWidth: 600,
    width: "100%",
  },
}));

export default function MoreOptionsDialog(props: StakeDialogProps) {
  const classes = useStyles();

  const paperStyle = isExtraSmallOrSmaller()
    ? classes.dialogPaperXs
    : classes.dialogPaper;

  const [open, setOpen] = useState(false);
  const [response, setResponse] = useState(null);

  const hintText = props.autoStaked
    ? `Choose if you want all of the ${props.assetSymbol} in your wallet
  to be automatically staked`
    : `Choose if you want the locked ${props.assetSymbol} in your wallet
  to be automatically staked`;

  return (
    <Dialog
      classes={{
        paper: paperStyle,
      }}
      fullWidth
      onClose={props.onClose}
      open={props.open}
    >
      <Flex container style={{ padding: CONTAINER_PADDING }} spacing={1}>
        <Flex item xs={24}>
          <Typography type="h6">More Options</Typography>
        </Flex>
        <Flex item xs={24}>
          <Divider spacingTop={0} spacingBottom={0} />
        </Flex>
        <Flex container spacing={2} style={{ paddingTop: 8 }}>
          <Flex container>
            <Switch
              labelText={
                props.autoStaked
                  ? "Auto stake assets"
                  : "Auto stake locked assets"
              }
              checked={!props.autoStakingOptOut}
              onChange={async (e) => {
                const oldOptOut = props.autoStakingOptOut;
                props.changeAutoStake(!oldOptOut, props.assetSymbol);
                const postResponse: any = await axiosRequest(
                  props.url,
                  {
                    entity_id: props.selectedEntityId,
                    symbol: props.assetSymbol,
                  },
                  RequestType.POST
                );
                if (postResponse && postResponse.data.errors) {
                  /* Reset */
                  props.changeAutoStake(oldOptOut, props.assetSymbol);
                }
                setResponse(postResponse);
                setOpen(true);
              }}
            />
            <Hint>{hintText}</Hint>
          </Flex>
        </Flex>
      </Flex>
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        autoHideDuration={3000}
        open={open}
        onClose={() => setOpen(false)}
      >
        <Warning
          color={response && response.status === 200 ? "green" : "orange"}
          fullWidth
          fontSize={13}
          padding={8}
          variant={response && response.status === 200 ? "success" : "warning"}
          warningText={
            response
              ? response && response.status === 200
                ? "Successfully updated your preferences"
                : "Error updating your preferences, please try again."
              : ""
          }
        />
      </Snackbar>
    </Dialog>
  );
}
