import * as React from "react";
import { useState } from "react";

import {createTheme, ThemeProvider} from '@mui/material/styles';

import { Link } from "@react/components";
import Flex from "@react/components/Flex";
import { getStateText, roundFloat, roundFloatStr } from "@react/utils";
import { getFormattedTime } from "@react/utils/date";
import { axiosRequest, RequestType } from "@react/utils/network";
import TableWithTitle from "@react/views/shared/TableWithTitle";

import AddLoanDialog from "./components/AddLoanDialog";
import PlacedLoanTableToolbar from "./components/PlacedLoanTableToolbar";
import { normalizeData } from "./utils";

interface Props {
  addLoanUrl: string;
  editLoanUrl: string;
  collateralAssets: Array<any>;
  collateralAssetSymbols: Array<string>;
  collateralByEntityAndAsset: Array<any>;
  deleteLoanUrl: string;
  entityNames: any;
  flaggedPlacedLoans: Array<any>;
  // highLevelLoanInfo: Array<any>;
  /* TODO - ADD ASSET TYPE */
  interestPaymentOptions: Array<string>;
  loanAssets: Array<any>;
  loanAssetSymbols: Array<any>;
  loansByEntity: Array<any>;
  loansByEntityAndAsset: Array<any>;
  loanUrl: string;
  /* TODO - ADD LENDING PROGRAM TYPE */
  lendingPrograms: Array<any>;
  /* TODO - ADD PLACED LOAN TYPE */
  placedLoans: Array<any>;
  possibleStates: Array<string>;
  previousEntities: Array<any>;
}

const INITIAL_FILTERS = {
  all: false,
  awaiting_collateral_payment: false,
  cancelled: false,
  completed: false,
  on_going: true,
};
const LOAN_ROWS_HEAD_CELLS: Array<React.ReactNode> = [
  "Loan ID",
  "Common Loan #",
  "Amount",
  "Entity Name",
  "State",
  "Start Date",
  "End Date",
  "Collateral Percentage",
  "",
  "",
];

export default function MyComponent(props: Props) {
  const theme = createTheme();
  const placedLoans = props.placedLoans;
  const initialRows = placedLoans.filter((row) => row.state === "on_going");
  const [filters, setFilters] = useState<any>(INITIAL_FILTERS);
  const [loanRows, setLoanRows] = useState<Array<any>>(initialRows);
  const [open, setOpen] = useState<boolean>(false);
  const [editId, setEditId] = useState<number>(0);

  console.log(props.loansByEntity);

  /* Only normalize the data if a loan has been selected */
  const selectedLoan =
    editId === 0
      ? undefined
      : { ...placedLoans.find(({ id }) => id === editId) };

  const existingData =
    editId === 0
      ? undefined
      : normalizeData({
          ...selectedLoan,
          collateral_assets: selectedLoan.collateral_assets,
          loan_amount_subunit: selectedLoan.loan_asset.amount_subunit,
          loan_asset_symbol: selectedLoan.loan_asset.symbol,
        });

  const handleAddLoan = () => {
    setOpen(true);
  };

  const handleFilter = (state: string) => {
    const newFilters = { ...filters, [state]: !filters[state] };
    /* Handle show all case */
    if (
      state === "all" ||
      Object.values(newFilters).every((filter) => !filter)
    ) {
      setLoanRows(placedLoans);
      let allFilters = {};
      Object.keys(INITIAL_FILTERS).forEach((key) => (allFilters[key] = false));
      setFilters(allFilters);
    } else {
      setLoanRows(placedLoans.filter((row) => newFilters[row.state]));
      setFilters({ ...newFilters });
    }
  };

  const handleSearch = (searchValue) => {
    if (!searchValue) {
      setLoanRows(placedLoans);
      return;
    }
    setLoanRows(
      placedLoans.filter((loan) =>
        loan.entity_name.toUpperCase().includes(searchValue.toUpperCase())
      )
    );
  };

  const handleRowClick = (id) => {
    window.open(`${props.loanUrl}/${id}`, "_blank").focus();
  };

  const handleDelete = async (placed_loan_id) => {
    const data = { placed_loan_id };
    try {
      await axiosRequest(props.deleteLoanUrl, data, RequestType.POST);
      alert("Loan deleted");
    } catch (error) {
      alert(`${error.message}`);
    }
  };

  const getLoanRows = (rows) => {
    return rows.map((row) => [
      <div onClick={() => handleRowClick(row.id)}>{row.id}</div>,
      <div onClick={() => handleRowClick(row.id)}>
        {row.common_loan_number || "N/A"}
      </div>,
      <div className="u-textOverflow" onClick={() => handleRowClick(row.id)}>
        {row.loan_asset &&
          `${roundFloatStr(
            row.loan_asset.amount_subunit,
            6
          ).toLocaleString()} ${row.loan_asset.symbol}`}
      </div>,
      <div>
        <a href={`/admin/entities/${row.entity_id}`}>{row.entity_name}</a>
      </div>,
      <div className="u-textOverflow" onClick={() => handleRowClick(row.id)}>
        {getStateText(row)}
      </div>,
      <div onClick={() => handleRowClick(row.id)}>
        {getFormattedTime(row.start_date)}
      </div>,
      <div onClick={() => handleRowClick(row.id)}>
        {row.end_date ? getFormattedTime(row.end_date) : "N/A"}
      </div>,
      <div onClick={() => handleRowClick(row.id)}>
        {roundFloat(parseFloat(row.collateral_percentage) * 100, 2)}%
      </div>,
      <div className="s-fontSize14">
        <Link onClick={() => handleRowClick(row.id)}>View Details</Link>
      </div>,
      <Flex container justifyContent="flex-end">
        <Link fontSize={14} onClick={() => setEditId(row.id)}>
          Edit
        </Link>
        {row.payments &&
          row.payments.length > 0 &&
          row.payments.every((payment) => payment.state === "active") && (
            <Link fontSize={14} onClick={() => handleDelete(row.id)}>
              &nbsp;/&nbsp;Delete
            </Link>
          )}
      </Flex>,
    ]);
  };

  const getHighLevelRows = (rows) => {
    return rows.map((row) => [
      <div>{row[0]}</div>,
      <div>{`${roundFloatStr(row[1], 6)} ${row[0]}`}</div>,
      <div>{row[2]}</div>,
      <div>{row[3]}</div>,
    ]);
  };

  const getLoansByEntity = (rows) => {
    return rows.map((row) => [
      <div>{row[0]}</div>,
      <div>{row[1]}</div>,
      <div>{row[2]}</div>,
      <div>{row[3]}</div>,
    ]);
  };

  const getLoansByEntityAndAsset = (rows) => {
    rows.splice(0, 1);
    return rows;
  };

  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <Flex container spacing={3}>
          <Flex container>
            <TableWithTitle
              headers={["Entity", "Loan $", "Collateral $", "Coverage"]}
              rows={getLoansByEntity(props.loansByEntity)}
              rowsPerPage={10}
              title="Loans by Entity"
            />
          </Flex>
          <Flex container>
            <TableWithTitle
              headers={props.loansByEntityAndAsset[0]}
              rows={getLoansByEntityAndAsset(props.loansByEntityAndAsset)}
              rowsPerPage={10}
              title="Loan Assets by Entity"
            />
          </Flex>
          <Flex container>
            <TableWithTitle
              headers={props.collateralByEntityAndAsset[0]}
              rows={getLoansByEntityAndAsset(props.collateralByEntityAndAsset)}
              rowsPerPage={10}
              title="Collateral Assets by Entity"
            />
          </Flex>
          {/* ACTIONS REQUIRED */}
          {props.flaggedPlacedLoans.length > 0 && (
            <Flex container>
              <Flex item xs={24}>
                <TableWithTitle
                  headers={LOAN_ROWS_HEAD_CELLS}
                  rows={getLoanRows(props.flaggedPlacedLoans)}
                  rowsPerPage={10}
                  title="Flagged Loans"
                />
              </Flex>
            </Flex>
          )}
          {/* ALL LOANS */}
          <Flex container>
            <Flex item xs={24}>
              <TableWithTitle
                headers={LOAN_ROWS_HEAD_CELLS}
                rows={getLoanRows(loanRows)}
                rowsPerPage={10}
                title={
                  <PlacedLoanTableToolbar
                    filters={{ ...filters }}
                    handleAddLoan={handleAddLoan}
                    handleFilter={handleFilter}
                    setSearchValue={(searchValue) => handleSearch(searchValue)}
                  />
                }
              />
            </Flex>
          </Flex>
        </Flex>
        <AddLoanDialog
          addLoanUrl={props.addLoanUrl}
          editLoanUrl={props.editLoanUrl}
          collateralAssetSymbols={props.collateralAssetSymbols}
          handleClose={() => setOpen(false)}
          interestPaymentOptions={props.interestPaymentOptions}
          loanAssetSymbols={props.loanAssetSymbols}
          lendingPrograms={props.lendingPrograms}
          open={open}
          possibleStates={props.possibleStates}
          previousEntities={props.previousEntities}
        />
        {editId !== 0 && (
          <AddLoanDialog
            addLoanUrl={props.addLoanUrl}
            editLoanUrl={props.editLoanUrl}
            collateralAssetSymbols={props.collateralAssetSymbols}
            existingData={existingData}
            handleClose={() => setEditId(0)}
            interestPaymentOptions={props.interestPaymentOptions}
            loanAssetSymbols={props.loanAssetSymbols}
            lendingPrograms={props.lendingPrograms}
            open={editId !== 0}
            possibleStates={props.possibleStates}
            previousEntities={props.previousEntities}
          />
        )}
      </React.Fragment>
    </ThemeProvider>
  );
}
