require('datatables')
require('underscore')

window.Admin.Offerings.Shared =
  table: ($$, $this) ->
    class TableView extends Backbone.View
      initialize: ->
        @createdAtColumn = @$('.js-created_at_column').index()
        options = _.extend(DataTablePresets.default(), {
          order: [[ @createdAtColumn, "desc" ]],
          scrollY: '50vh'
          scrollCollapse: true
        })
        @tableApi = @$('.js-table').DataTable(options)

      events:
        'input .js-search_input': (event) ->
          @tableApi.search(event.currentTarget.value).draw()


    new TableView el: $this
