window.Index.BlockstackIndex = {

  redirect: ($$, $this) =>
    class RedirectView extends Backbone.View
      events:
        'click .js-redirect': (event) ->
          @$('.js-redirect_modal').show()
          $.magnificPopup.open({
            items: {
              src: @$('.js-redirect_modal')
              type: 'inline'
            }
          })

    new RedirectView el: $this

}