import { GraphQLClient, gql } from "graphql-request";
import { getRailsHeaders } from "@react/utils/network";
import { LinkWalletData, UnlinkWalletData } from "./types";

const query = gql`
  query LinkedWallets($entity: String!) {
    linkedWallets(entity: $entity, orderByUpdatedAt: "desc") {
      id
      entity {
        id
        slug
        name
      }
      address
      chainId
      createdAt
      updatedAt
      websiteSource
    }
  }
`;

const mutationLink = gql`
  mutation LinkedWallets(
    $address: String!
    $chainId: Int!
    $entityId: String!
    $message: String!
    $signature: String!
    $websiteSource: String!
  ) {
    createLinkedWallet(
      input: {
        address: $address
        chainId: $chainId
        entity: $entityId
        message: $message
        signature: $signature
        websiteSource: $websiteSource
      }
    ) {
      success
      errors {
        message
        code
        backtrace
      }
    }
  }
`;

const mutationUnlink = gql`
  mutation LinkedWallets(
    $address: String!
    $chainId: Int!
    $entityId: String!
  ) {
    unlinkLinkedWallet(
      input: { address: $address, chainId: $chainId, entity: $entityId }
    ) {
      success
      errors {
        message
        code
        backtrace
      }
    }
  }
`;

export const walletLinkApi = () => {
  const endpoint = "/graphql?no_auth_tokens=true";

  const client = new GraphQLClient(endpoint, { headers: getRailsHeaders() });

  const linkWallet = (linkWalletData: LinkWalletData) =>
    client.request(mutationLink, linkWalletData);

  const unlinkWallet = (unlinkWalletData: UnlinkWalletData) =>
    client.request(mutationUnlink, unlinkWalletData);

  const getLinkedWalletsByEntity = (entity: string) =>
    client.request(query, { entity });

  return {
    linkWallet,
    unlinkWallet,
    getLinkedWalletsByEntity,
  };
};
