import * as React from "react";
import {
  Table,
  TableProps,
  TableBody,
  TableBodyProps,
  TableCell,
  TableCellProps,
  TableHead,
  TableHeadProps,
  TableHeaderCell,
  TableHeaderCellProps,
  TableRow,
  TableRowProps,
} from "@react/components";

import TableWithTitle from "@react/views/shared/TableWithTitle";

interface TableSectionProps {
  // props
}

const TableSection: React.FunctionComponent<TableSectionProps> = (
  {
    // props
  }
) => {
  const tableShortExampleCode = `const users = [{
  "first_name": "Shepard",
  "last_name": "Balcers",
  "id": 1
}, {
  "first_name": "Oswell",
  "last_name": "Bostock",
  "id": 2
// ...
}]

const rows = users.map((user) => {
  [
    user.first_name,
    user.last_name,
    <b>{user.id}</b>
  ]
})

<Table
  headers={["First", "Last", "ID"]}
  rows={rows}
/>`;

  const tableExampleCode = `<Table>
  <TableHead>
    <TableRow>
      <TableHeaderCell>First</TableHeaderCell>
      <TableHeaderCell>Last</TableHeaderCell>
      <TableHeaderCell>ID</TableHeaderCell>
    </TableRow>
  </TableHead>
  <TableBody>
    {users.map((user) => (
      <TableRow>
        <TableCell>{user.first_name}</TableCell>
        <TableCell>{user.last_name}</TableCell>
        <TableCell><b>{user.id}</b></TableCell>
      </TableRow>
    )}
  </TableBody>
</Table>`;

  const tableBorderedExampleCode = `<Table
  style="bordered"
  headers={["First", "Last", "ID"]}
  rows={rows}
/>`;

  const tableColExampleCode = `<Table
  headers={["First", "Last", "ID"]}
  headersGridSizes={[10, 10, 4]}
  rows={rows}
/>`;

  const tableWithTitleExample = `<TableWithTitle
  headers={["First", "Last", "ID"]}
  headersGridSizes={[10, 10, 4]}
  rows={rows}
  title={"My great table"}
/>`;

  const users = [
    {
      first_name: "Shepard",
      last_name: "Balcers",
      id: 1,
    },
    {
      first_name: "Oswell",
      last_name: "Bostock",
      id: 2,
    },
    {
      first_name: "Ebony",
      last_name: "Ancliff",
      id: 3,
    },
    {
      first_name: "Giff",
      last_name: "Stuckley",
      id: 4,
    },
    {
      first_name: "Consolata",
      last_name: "Dearlove",
      id: 5,
    },
    {
      first_name: "Hatty",
      last_name: "Halligan",
      id: 6,
    },
    {
      first_name: "Blanch",
      last_name: "Ferrini",
      id: 7,
    },
    {
      first_name: "Ave",
      last_name: "Costanza",
      id: 8,
    },
    {
      first_name: "Loreen",
      last_name: "Tows",
      id: 9,
    },
    {
      first_name: "Sax",
      last_name: "Haslegrave",
      id: 10,
    },
  ];

  const rows = users.map((user) => [
    user.first_name,
    user.last_name,
    <b>{user.id}</b>,
  ]);

  return (
    <div>
      <div className="s-marginBottom2">
        <h2 className="s-fontSize26 s-borderBottom0_5 s-marginBottom1 u-colorGray2">
          Table
        </h2>
        <p className="s-marginBottom0_5 u-colorGray6">
          A series of component to build tables
        </p>
      </div>

      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          Default use: built-in props
        </h3>
        <pre>
          <Table headers={["First", "Last", "ID"]} rows={rows} />
        </pre>
        <p className="s-marginBottom0_5 s-marginTop1 u-colorGray6">
          Can also be achieved with pure components:
        </p>
        <pre>{tableExampleCode}</pre>
      </div>

      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">Bordered</h3>
        <pre>
          <Table
            style="bordered"
            headers={["First", "Last", "ID"]}
            rows={rows}
          />
        </pre>
      </div>

      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          Table: Col grid
        </h3>
        <p className="s-marginBottom0_5 s-marginTop1 u-colorGray6">
          You can pass a{" "}
          <code className="c-code c-code--inline">headerCols</code> prop with a
          number from <code className="c-code c-code--inline">1</code> to{" "}
          <code className="c-code c-code--inline">24</code> for each header
        </p>
        <pre>
          <Table
            headers={["First", "Last", "ID"]}
            headersGridSizes={[10, 10, 4]}
            rows={rows}
          />
        </pre>
      </div>

      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          Misc: Shared component: TableWithTitle
        </h3>
        <pre>
          <TableWithTitle
            headers={["First", "Last", "ID"]}
            headersGridSizes={[10, 10, 4]}
            rows={rows}
            title={"My great table"}
          />
        </pre>
      </div>
    </div>
  );
};

export default TableSection;
