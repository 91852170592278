require('./lib/validation')

window.EmailSignups = {
  form: ($$, $this) ->
    class FormView extends Backbone.View
      getFormatedEmail: ->
        $.trim(@$('.js-email_signup_input').val())

      isValidEmail: ->
        Validation.isValidEmail(@getFormatedEmail())

      enableButton: ->
        @$('.js-email_signup_button').prop('disabled', !@isValidEmail())

      showSuccessMessage: ->
        @$('.js-success_message').show()

      events:
        'submit .js-email_signup_form': (event) ->
          event.preventDefault()
          return if !@isValidEmail()

          $button = @$('.js-email_signup_button')
          $form = $(event.target)

          $form.ajaxSubmit
            success: (response) =>
              @$('.js-success_message').slideDown('fast')
              @$('.js-email_signup_form').slideUp('fast')
            error: (response) =>
              if response.status == 422
                @$el.replaceWith response.responseJSON.html
              else
                Tooltips.formServerError($button)

        'input .js-email_signup_input': (event) ->
          event.preventDefault()
          @enableButton()

    new FormView el: $this

}