Partials = require('../lib/partials')
import * as Castle from "@castleio/castle-js"

window.Market.Accounts =
  transaction_reports: ($$, $this)->
    class TransactionReports extends Backbone.View
      initialize: ->
        Partials.with Partials.selector('entities/shared/entity_selector'), (partial) =>
          partial.on 'slugChange', (slug) =>
            path =  @$('.js-data').data('rootPath') + '/' + slug
            window.location.pathname = path

    new TransactionReports el: $this

  profile_form: ($$, $this)->
    class ProfileFormView extends Backbone.View

      events: ->
        'click .js-cta': (event) ->
          if castleData?.castlePublishKey
            Castle.createRequestToken().then (token) ->
              $('#castle_request_token').val(token)

    new ProfileFormView el: $this