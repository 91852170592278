window.Admin.Offerings.Users.Permissions.Index =
  batch_create_form: ($$, $this) =>
    class BatchCreateFormView extends Backbone.View
      handleServerResponse: (response) ->
        @$('.js-email_created').hide()
        @$('.js-email_updated').hide()
        @$('.js-email_failed').hide()
        if response.email_created != undefined && response.email_created.length > 0
          @$('.js-email_created').show().text("Emails created: #{response.email_created}")
        if response.email_updated != undefined && response.email_updated.length > 0
          @$('.js-email_updated').show().text("Emails updated: #{response.email_updated}")
        if response.email_failed != undefined && response.email_failed.length > 0
          @$('.js-email_failed').show().text("Emails failed: #{response.email_failed}")
        if response.table_html != undefined
          @trigger 'updateTable', response.table_html

      events:
        'submit .js-batch_create_form': (event) ->
          event.preventDefault()
          $form = $(event.target)
          $button = @$('.js-cta')

          return if $button.hasClass('c-button--loading')
          $button.addClass('c-button--loading')

          $form.ajaxSubmit
            success: (response) =>
              $button.removeClass('c-button--loading').prop('disabled', false)
              @handleServerResponse(response)
            error: (response) =>
              if response.status == 422
                $button.removeClass('c-button--loading').prop('disabled', false)
                @handleServerResponse(response.responseJSON)
              else
                Tooltips.formServerError($button)

    new BatchCreateFormView el: $this

  table_row: ($$, $this) =>
    class TableRowView extends Backbone.View
      editRow: (targetId) ->
        @$(".js-content_row[data-target-id=#{targetId}]").addClass('u-hidden')
        @$(".js-edit_row[data-target-id=#{targetId}]").removeClass('u-hidden')

      events:
        'click .js-edit_offerings_users_permission': (event) ->
          event.preventDefault()
          $target = $(event.currentTarget)
          @editRow($target.data('targetId'))

        'click .js-destroy_offerings_users_permission': (event) ->
          event.preventDefault()
          $target = $(event.currentTarget)
          if window.confirm('Are you sure?')
            url = @$('.js-edit_offerings_users_permission_form').attr('action')
            $.ajax
              url: url,
              type: 'DELETE',
              success: =>
                @$el.remove()

        'submit .js-edit_offerings_users_permission_form': (event) ->
          event.preventDefault()
          $form = $(event.target)
          $button = @$('.js-cta')

          return if $button.hasClass('c-button--loading') || $button.prop('disabled')
          $button.addClass('c-button--loading')
          $form.ajaxSubmit
            success: (response) =>
              @$el.replaceWith response.html
            error: (response) =>
              if response.status == 422
                @$el.replaceWith response.responseJSON.html
              else
                Tooltips.formServerError($button)

    new TableRowView el: $this