import { GraphQLClient } from "graphql-request";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded date */
  ISO8601Date: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  /** Money Amount & Currency Scalar */
  Money: string;
  /** Time type that accepts ints, floats, or ISO8601 strings of time values; transported as a String; returns in UTC */
  Time: string;
  /** A valid URL, transported as a string */
  Url: any;
};

/** Store for all the CL account types */
export type Accounts = {
  __typename?: "Accounts";
  holding: Array<AccountsHolding>;
  staking: Array<AccountsStaking>;
};

/** Store for all the CL account types */
export type AccountsHoldingArgs = {
  assets?: InputMaybe<Array<Scalars["String"]>>;
};

/** Store for all the CL account types */
export type AccountsStakingArgs = {
  assets?: InputMaybe<Array<Scalars["String"]>>;
};

/** Holding account balance and history for a specific asset */
export type AccountsHolding = InterfacesActiveRecord & {
  __typename?: "AccountsHolding";
  asset: Asset;
  balance: Scalars["Money"];
  createdAt?: Maybe<Scalars["Int"]>;
  createdAtIso?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Wallet address to receive funds, unique to this user. */
  depositAddress?: Maybe<Scalars["String"]>;
  /** Reasons the object couldn't be created or updated */
  errors?: Maybe<Array<Scalars["String"]>>;
  fiatBalance?: Maybe<Scalars["Money"]>;
  id?: Maybe<Scalars["ID"]>;
  transactions: TransactionConnection;
  updatedAt?: Maybe<Scalars["Int"]>;
  updatedAtIso?: Maybe<Scalars["ISO8601DateTime"]>;
};

/** Holding account balance and history for a specific asset */
export type AccountsHoldingFiatBalanceArgs = {
  fiat?: InputMaybe<Scalars["String"]>;
};

/** Holding account balance and history for a specific asset */
export type AccountsHoldingTransactionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** Staking account balance and history for a specific asset */
export type AccountsStaking = {
  __typename?: "AccountsStaking";
  apy: Scalars["Float"];
  asset: Asset;
  balance: Scalars["Money"];
  fiatBalance?: Maybe<Scalars["Money"]>;
  id: Scalars["ID"];
};

/** Staking account balance and history for a specific asset */
export type AccountsStakingFiatBalanceArgs = {
  fiat?: InputMaybe<Scalars["String"]>;
};

export type Application = {
  __typename?: "Application";
  /** Return true if the API is healthy */
  healthCheck: Scalars["Boolean"];
  public?: Maybe<Scalars["Boolean"]>;
  /** Return the current API version */
  version: Scalars["String"];
};

export type ApplicationHealthCheckArgs = {
  checkDb?: InputMaybe<Scalars["Boolean"]>;
  checkRedis?: InputMaybe<Scalars["Boolean"]>;
};

export type ApplicationMutationsInternal = {
  __typename?: "ApplicationMutationsInternal";
  acceptQuote?: Maybe<QuoteAcceptPayload>;
  createAuthorizedSignatory?: Maybe<KycCreateAuthorizedSignatoryPayload>;
  createBeneficiary?: Maybe<KycCreateBeneficiaryPayload>;
  createOrganizationEntity?: Maybe<EntityCreateOrganizationPayload>;
  createPersonEntity?: Maybe<EntityCreatePersonPayload>;
  createQuote?: Maybe<QuoteCreatePayload>;
  deleteAuthorizedSignatory?: Maybe<KycDeleteAuthorizedSignatoryPayload>;
  deleteBeneficiary?: Maybe<KycDeleteBeneficiaryPayload>;
  editAuthorizedSignatory?: Maybe<KycEditAuthorizedSignatoryPayload>;
  editBeneficiary?: Maybe<KycEditBeneficiaryPayload>;
  finalizeBasicDetails?: Maybe<BasicDetailsFinalizePayload>;
  findOrCreateVeriffIdvSession?: Maybe<VeriffIdvSessionFindOrCreatePayload>;
  uploadDocument?: Maybe<DocumentUploadPayload>;
  upsertBasicDetails?: Maybe<BasicDetailsUpsertPayload>;
  upsertBusinessInfo?: Maybe<KycUpsertBusinessInfoPayload>;
  withdrawRequestAccept?: Maybe<WithdrawRequestAcceptPayload>;
  withdrawRequestCreate?: Maybe<WithdrawRequestCreatePayload>;
};

export type ApplicationMutationsInternalAcceptQuoteArgs = {
  input?: InputMaybe<QuoteAcceptInput>;
};

export type ApplicationMutationsInternalCreateAuthorizedSignatoryArgs = {
  input?: InputMaybe<KycCreateAuthorizedSignatoryInput>;
};

export type ApplicationMutationsInternalCreateBeneficiaryArgs = {
  input?: InputMaybe<KycCreateBeneficiaryInput>;
};

export type ApplicationMutationsInternalCreateOrganizationEntityArgs = {
  input?: InputMaybe<EntityCreateOrganizationInput>;
};

export type ApplicationMutationsInternalCreatePersonEntityArgs = {
  input?: InputMaybe<EntityCreatePersonInput>;
};

export type ApplicationMutationsInternalCreateQuoteArgs = {
  input?: InputMaybe<QuoteCreateInput>;
};

export type ApplicationMutationsInternalDeleteAuthorizedSignatoryArgs = {
  input?: InputMaybe<KycDeleteAuthorizedSignatoryInput>;
};

export type ApplicationMutationsInternalDeleteBeneficiaryArgs = {
  input?: InputMaybe<KycDeleteBeneficiaryInput>;
};

export type ApplicationMutationsInternalEditAuthorizedSignatoryArgs = {
  input?: InputMaybe<KycEditAuthorizedSignatoryInput>;
};

export type ApplicationMutationsInternalEditBeneficiaryArgs = {
  input?: InputMaybe<KycEditBeneficiaryInput>;
};

export type ApplicationMutationsInternalFinalizeBasicDetailsArgs = {
  input?: InputMaybe<BasicDetailsFinalizeInput>;
};

export type ApplicationMutationsInternalFindOrCreateVeriffIdvSessionArgs = {
  input?: InputMaybe<VeriffIdvSessionFindOrCreateInput>;
};

export type ApplicationMutationsInternalUploadDocumentArgs = {
  input?: InputMaybe<DocumentUploadInput>;
};

export type ApplicationMutationsInternalUpsertBasicDetailsArgs = {
  input?: InputMaybe<BasicDetailsUpsertInput>;
};

export type ApplicationMutationsInternalUpsertBusinessInfoArgs = {
  input?: InputMaybe<KycUpsertBusinessInfoInput>;
};

export type ApplicationMutationsInternalWithdrawRequestAcceptArgs = {
  input?: InputMaybe<WithdrawRequestAcceptInput>;
};

export type ApplicationMutationsInternalWithdrawRequestCreateArgs = {
  input?: InputMaybe<WithdrawRequestCreateInput>;
};

export type ApplicationQueriesInternal = {
  __typename?: "ApplicationQueriesInternal";
  application?: Maybe<Application>;
  asset?: Maybe<Asset>;
  assets?: Maybe<Array<Asset>>;
  me?: Maybe<User>;
};

export type ApplicationQueriesInternalAssetArgs = {
  symbol: Scalars["String"];
};

export type ApplicationQueriesInternalAssetsArgs = {
  symbols?: InputMaybe<Array<Scalars["String"]>>;
};

/** Crypto asset information */
export type Asset = InterfacesActiveRecord & {
  __typename?: "Asset";
  about?: Maybe<Scalars["String"]>;
  autoStakingApy?: Maybe<Scalars["Float"]>;
  conversions?: Maybe<Array<Conversion>>;
  createdAt?: Maybe<Scalars["Int"]>;
  createdAtIso?: Maybe<Scalars["ISO8601DateTime"]>;
  dailyChange: Scalars["Float"];
  /** Reasons the object couldn't be created or updated */
  errors?: Maybe<Array<Scalars["String"]>>;
  id?: Maybe<Scalars["ID"]>;
  logoUrl?: Maybe<Scalars["Url"]>;
  name?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["Money"]>;
  priceHistory?: Maybe<Array<ChartData>>;
  saleInfo?: Maybe<SaleInfo>;
  stakingVaults: Array<StakingVault>;
  symbol: Scalars["ID"];
  /** List of symbols for which this asset can trade into on RFQ or Pro */
  tradingCounterAssets?: Maybe<Array<Scalars["String"]>>;
  updatedAt?: Maybe<Scalars["Int"]>;
  updatedAtIso?: Maybe<Scalars["ISO8601DateTime"]>;
};

/** Crypto asset information */
export type AssetPriceArgs = {
  fiat?: InputMaybe<Scalars["String"]>;
};

/** Crypto asset information */
export type AssetPriceHistoryArgs = {
  endTime?: InputMaybe<Scalars["Time"]>;
  interval: ChartDataInterval;
  startTime: Scalars["Time"];
};

/** Crypto asset information */
export type AssetStakingVaultsArgs = {
  entityId: Scalars["ID"];
};

/** Staking information for auto staked asset */
export type AutoStakingVault = {
  __typename?: "AutoStakingVault";
  apy: Scalars["Float"];
};

/** pii associated with an entity */
export type BasicDetails = {
  __typename?: "BasicDetails";
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  /** YYYY-MM-DD string representing entities' date of birth */
  dob?: Maybe<Scalars["String"]>;
  firstName: Scalars["String"];
  lastName?: Maybe<Scalars["String"]>;
  occupation?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  stateIdentifier?: Maybe<Scalars["String"]>;
  streetAddress?: Maybe<Scalars["String"]>;
  zipcode?: Maybe<Scalars["String"]>;
};

export type BasicDetailsFinalizeInput = {
  entityId: Scalars["String"];
};

/** Autogenerated return type of BasicDetailsFinalize. */
export type BasicDetailsFinalizePayload = {
  __typename?: "BasicDetailsFinalizePayload";
  errors?: Maybe<Array<Error>>;
  result?: Maybe<BasicDetails>;
  success?: Maybe<Scalars["Boolean"]>;
};

export type BasicDetailsUpsertInput = {
  city?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  dob?: InputMaybe<Scalars["String"]>;
  entityId: Scalars["String"];
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  occupation?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  stateIdentifier?: InputMaybe<Scalars["String"]>;
  streetAddress?: InputMaybe<Scalars["String"]>;
  zipcode?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of BasicDetailsUpsert. */
export type BasicDetailsUpsertPayload = {
  __typename?: "BasicDetailsUpsertPayload";
  errors?: Maybe<Array<Error>>;
  result?: Maybe<BasicDetails>;
  success?: Maybe<Scalars["Boolean"]>;
};

/** Data point that is consumed by the frontend chart. */
export type ChartData = {
  __typename?: "ChartData";
  /** The date for the given data point as a unix timestamp. */
  date: Scalars["Time"];
  /** The date for the given data point in the iso format. */
  dateIso: Scalars["ISO8601DateTime"];
  /** The value at the given data point. */
  value: Scalars["Float"];
};

export enum ChartDataInterval {
  Day = "DAY",
  Hour = "HOUR",
  Month = "MONTH",
  Week = "WEEK",
  Year = "YEAR",
}

/** Conversion from one asset to another */
export type Conversion = {
  __typename?: "Conversion";
  base: Scalars["String"];
  counter: Scalars["String"];
  /** Fee percent, [0..1.0] */
  flatFeeRate?: Maybe<Scalars["Float"]>;
  id: Scalars["ID"];
  /** Minimum required amount of the base asset */
  minAmount?: Maybe<Scalars["Float"]>;
};

export type Document = {
  __typename?: "Document";
  /** what is this doc used for? ex: good standing verification */
  docCategory?: Maybe<DocumentPurpose>;
  fileContent?: Maybe<Scalars["String"]>;
  fileName?: Maybe<Scalars["String"]>;
  /** entity ID of the person/business this doc belongs to */
  ownerEntityId?: Maybe<Scalars["String"]>;
};

export enum DocumentPurpose {
  CompaniesEvidenceToAct = "companies_evidence_to_act",
  CompaniesGoodStandingVerification = "companies_good_standing_verification",
  CompaniesMajorShareholderIdVerification = "companies_major_shareholder_id_verification",
  CompaniesMajorShareholderOwnershipVerification = "companies_major_shareholder_ownership_verification",
  CompaniesProofOfAddress = "companies_proof_of_address",
  CompaniesRegistrationVerification = "companies_registration_verification",
  ProofOfResidence = "proof_of_residence",
  SignatoryProofOfResidence = "signatory_proof_of_residence",
  TrustsAgreementVerification = "trusts_agreement_verification",
}

export type DocumentUploadInput = {
  docCategory: Scalars["String"];
  fileContent: Scalars["String"];
  fileName: Scalars["String"];
  ownerEntityId: Scalars["String"];
};

/** Autogenerated return type of DocumentUpload. */
export type DocumentUploadPayload = {
  __typename?: "DocumentUploadPayload";
  errors?: Maybe<Array<Error>>;
  result?: Maybe<Document>;
  success?: Maybe<Scalars["Boolean"]>;
};

export type Entity = InterfacesActiveRecord & {
  __typename?: "Entity";
  accounts: Accounts;
  balanceHistory: Array<ChartData>;
  createdAt?: Maybe<Scalars["Int"]>;
  createdAtIso?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Reasons the object couldn't be created or updated */
  errors?: Maybe<Array<Scalars["String"]>>;
  externalAccounts: ExternalAccounts;
  id?: Maybe<Scalars["ID"]>;
  karma: Scalars["Int"];
  kind: Scalars["String"];
  kyc?: Maybe<Kyc>;
  name: Scalars["String"];
  slug: Scalars["String"];
  updatedAt?: Maybe<Scalars["Int"]>;
  updatedAtIso?: Maybe<Scalars["ISO8601DateTime"]>;
};

export type EntityBalanceHistoryArgs = {
  endTime?: InputMaybe<Scalars["Time"]>;
  interval: ChartDataInterval;
  startTime: Scalars["Time"];
};

export type EntityCreateOrganizationInput = {
  name: Scalars["String"];
};

/** Autogenerated return type of EntityCreateOrganization. */
export type EntityCreateOrganizationPayload = {
  __typename?: "EntityCreateOrganizationPayload";
  errors?: Maybe<Array<Error>>;
  result?: Maybe<Entity>;
  success?: Maybe<Scalars["Boolean"]>;
};

export type EntityCreatePersonInput = {
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  middleName?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of EntityCreatePerson. */
export type EntityCreatePersonPayload = {
  __typename?: "EntityCreatePersonPayload";
  errors?: Maybe<Array<Error>>;
  result?: Maybe<Entity>;
  success?: Maybe<Scalars["Boolean"]>;
};

export type Error = {
  __typename?: "Error";
  backtrace?: Maybe<Array<Scalars["String"]>>;
  code?: Maybe<Scalars["Int"]>;
  message?: Maybe<Scalars["String"]>;
};

/** The bank account that are linked to an Entity */
export type ExternalAccounts = {
  __typename?: "ExternalAccounts";
  ach: Array<ExternalAccountsAch>;
  id?: Maybe<Scalars["ID"]>;
  sen: Array<ExternalAccountsSen>;
  wire: Array<ExternalAccountsWire>;
};

/** An external bank account that can be used in ACH (push or pull) */
export type ExternalAccountsAch = {
  __typename?: "ExternalAccountsAch";
  accountNumber: Scalars["String"];
  accountNumberLastFour: Scalars["String"];
  bankName: Scalars["String"];
  id: Scalars["ID"];
};

/** A bank account that can be used to send digital assets over the SEN (Silvergate Exchange Network) */
export type ExternalAccountsSen = {
  __typename?: "ExternalAccountsSen";
  accountNumberLastFour: Scalars["String"];
  id: Scalars["ID"];
};

/** A bank account that can be used for outgoing wires */
export type ExternalAccountsWire = {
  __typename?: "ExternalAccountsWire";
  beneficiaryBankName: Scalars["String"];
  beneficiaryRoutingNumber: Scalars["String"];
  id: Scalars["ID"];
  isInternational: Scalars["Boolean"];
};

export type InterfacesActiveRecord = {
  createdAt?: Maybe<Scalars["Int"]>;
  createdAtIso?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Reasons the object couldn't be created or updated */
  errors?: Maybe<Array<Scalars["String"]>>;
  id?: Maybe<Scalars["ID"]>;
  updatedAt?: Maybe<Scalars["Int"]>;
  updatedAtIso?: Maybe<Scalars["ISO8601DateTime"]>;
};

/** either KYC or KYB information, depending on the type of the entity */
export type Kyc = KycIndividual | KycOrganization;

export type KycBusinessOperationsAddress = {
  __typename?: "KycBusinessOperationsAddress";
  city: Scalars["String"];
  country: Scalars["String"];
  postalCode: Scalars["String"];
  region: Scalars["String"];
  streetAddress: Scalars["String"];
};

export type KycCreateAuthorizedSignatoryInput = {
  city: Scalars["String"];
  country: Scalars["String"];
  dob: Scalars["ISO8601DateTime"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  middleName?: InputMaybe<Scalars["String"]>;
  /** entity id of the business this signatory is part of */
  ownerId: Scalars["String"];
  phoneNumber: Scalars["String"];
  postalCode: Scalars["String"];
  /** highest level administrative region for a country: state for Americans, province for Canadians, etc */
  region: Scalars["String"];
  /** SSN for Americans, other state identifier for internationals */
  stateIdentifier?: InputMaybe<Scalars["String"]>;
  streetAddress: Scalars["String"];
  title: Scalars["String"];
};

/** Autogenerated return type of KycCreateAuthorizedSignatory. */
export type KycCreateAuthorizedSignatoryPayload = {
  __typename?: "KycCreateAuthorizedSignatoryPayload";
  errors?: Maybe<Array<Error>>;
  result?: Maybe<KycRelatedPerson>;
  success?: Maybe<Scalars["Boolean"]>;
};

export type KycCreateBeneficiaryInput = {
  city: Scalars["String"];
  country: Scalars["String"];
  dob: Scalars["ISO8601DateTime"];
  entityId: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  middleName?: InputMaybe<Scalars["String"]>;
  phoneNumber: Scalars["String"];
  postalCode: Scalars["String"];
  /** highest level administrative region for a country: state for Americans, province for Canadians, etc */
  region: Scalars["String"];
  /** SSN for Americans, other state identifier for internationals */
  stateIdentifier: Scalars["String"];
  streetAddress: Scalars["String"];
  title: Scalars["String"];
};

/** Autogenerated return type of KycCreateBeneficiary. */
export type KycCreateBeneficiaryPayload = {
  __typename?: "KycCreateBeneficiaryPayload";
  errors?: Maybe<Array<Error>>;
  result?: Maybe<KycRelatedPerson>;
  success?: Maybe<Scalars["Boolean"]>;
};

export enum KycDecision {
  /** the entity's kyc has not been decisioned yet */
  NoFinalDecision = "NO_FINAL_DECISION",
  /** the entity's kyc has been rejected */
  Rejected = "REJECTED",
  /** the entity's kyc has been verified */
  Verified = "VERIFIED",
}

export type KycDeleteAuthorizedSignatoryInput = {
  entityId: Scalars["String"];
};

/** Autogenerated return type of KycDeleteAuthorizedSignatory. */
export type KycDeleteAuthorizedSignatoryPayload = {
  __typename?: "KycDeleteAuthorizedSignatoryPayload";
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars["Boolean"]>;
};

export type KycDeleteBeneficiaryInput = {
  beneficiaryId: Scalars["String"];
  entityId: Scalars["String"];
};

/** Autogenerated return type of KycDeleteBeneficiary. */
export type KycDeleteBeneficiaryPayload = {
  __typename?: "KycDeleteBeneficiaryPayload";
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars["Boolean"]>;
};

export type KycDocuments = {
  __typename?: "KycDocuments";
  evidenceToAct?: Maybe<Array<Document>>;
  governmentId?: Maybe<Array<Document>>;
  proofOfBeneficiaryOwnership?: Maybe<Array<Document>>;
  proofOfResidence?: Maybe<Array<Document>>;
};

export type KycEditAuthorizedSignatoryInput = {
  city?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  dob?: InputMaybe<Scalars["ISO8601DateTime"]>;
  entityId: Scalars["String"];
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  middleName?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  /** highest level administrative region for a country: state for Americans, province for Canadians, etc */
  region?: InputMaybe<Scalars["String"]>;
  /** SSN for Americans, other state identifier for internationals */
  stateIdentifier?: InputMaybe<Scalars["String"]>;
  streetAddress?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  zipcode?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of KycEditAuthorizedSignatory. */
export type KycEditAuthorizedSignatoryPayload = {
  __typename?: "KycEditAuthorizedSignatoryPayload";
  errors?: Maybe<Array<Error>>;
  result?: Maybe<KycRelatedPerson>;
  success?: Maybe<Scalars["Boolean"]>;
};

export type KycEditBeneficiaryInput = {
  beneficiaryId: Scalars["String"];
  city?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  dob?: InputMaybe<Scalars["ISO8601DateTime"]>;
  entityId: Scalars["String"];
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  middleName?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  /** highest level administrative region for a country: state for Americans, province for Canadians, etc */
  region?: InputMaybe<Scalars["String"]>;
  /** SSN for Americans, other state identifier for internationals */
  stateIdentifier?: InputMaybe<Scalars["String"]>;
  streetAddress?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  zipcode?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of KycEditBeneficiary. */
export type KycEditBeneficiaryPayload = {
  __typename?: "KycEditBeneficiaryPayload";
  errors?: Maybe<Array<Error>>;
  result?: Maybe<KycRelatedPerson>;
  success?: Maybe<Scalars["Boolean"]>;
};

export type KycIncorporationInfo = {
  __typename?: "KycIncorporationInfo";
  countryOfIncorporation: Scalars["String"];
  dateOfIncorporation: Scalars["ISO8601DateTime"];
  regionOfIncorporation: Scalars["String"];
  /** only applicable for Americans */
  taxIdNumber?: Maybe<Scalars["String"]>;
};

/** contains all the KYC related data for individuals (address, personal details, IDV info, status, etc) */
export type KycIndividual = {
  __typename?: "KycIndividual";
  basicDetails: BasicDetails;
  status: KycDecision;
  veriffIdvSession?: Maybe<VeriffIdvSession>;
};

/** contains all the KYB related data for an entity */
export type KycOrganization = {
  __typename?: "KycOrganization";
  authorizedSignatory?: Maybe<KycRelatedPerson>;
  beneficiaries?: Maybe<Array<KycRelatedPerson>>;
  businessOpsAddress?: Maybe<KycBusinessOperationsAddress>;
  dbaName: Scalars["String"];
  documents?: Maybe<KycDocuments>;
  incorporationInfo?: Maybe<KycIncorporationInfo>;
  industry: Scalars["String"];
  legalName: Scalars["String"];
  servicesProvided: Scalars["String"];
  status: KycDecision;
};

/** personal data for either an authorized signatory or a beneficiary */
export type KycRelatedPerson = {
  __typename?: "KycRelatedPerson";
  city: Scalars["String"];
  country: Scalars["String"];
  dob: Scalars["ISO8601DateTime"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  middleName: Scalars["String"];
  phoneNumber: Scalars["String"];
  region: Scalars["String"];
  /** id of the child entity */
  relatedPersonId: Scalars["String"];
  stateIdentifier: Scalars["String"];
  streetAddress: Scalars["String"];
  title: Scalars["String"];
  zipcode: Scalars["String"];
};

export type KycUpsertBusinessInfoBusinessOperationsAddressInput = {
  city?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  postalCode?: InputMaybe<Scalars["String"]>;
  region?: InputMaybe<Scalars["String"]>;
  streetAddress?: InputMaybe<Scalars["String"]>;
};

export type KycUpsertBusinessInfoCompanyProfileSurveyInput = {
  industry?: InputMaybe<Scalars["String"]>;
  servicesProvided?: InputMaybe<Scalars["String"]>;
};

export type KycUpsertBusinessInfoIncorporationInfoInput = {
  countryOfIncorporation?: InputMaybe<Scalars["String"]>;
  dateOfIncorporation?: InputMaybe<Scalars["ISO8601DateTime"]>;
  regionOfIncorporation?: InputMaybe<Scalars["String"]>;
  taxIdNumber?: InputMaybe<Scalars["String"]>;
};

export type KycUpsertBusinessInfoInput = {
  businessOpsAddress?: InputMaybe<KycUpsertBusinessInfoBusinessOperationsAddressInput>;
  companyProfileSurvey?: InputMaybe<KycUpsertBusinessInfoCompanyProfileSurveyInput>;
  dbaName?: InputMaybe<Scalars["String"]>;
  entityId: Scalars["String"];
  incorporationInfo?: InputMaybe<KycUpsertBusinessInfoIncorporationInfoInput>;
  legalName?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of KycUpsertBusinessInfo. */
export type KycUpsertBusinessInfoPayload = {
  __typename?: "KycUpsertBusinessInfoPayload";
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars["Boolean"]>;
};

/** Staking information for a locked staking vault */
export type LockedStakingVault = {
  __typename?: "LockedStakingVault";
  apy: Scalars["Float"];
  endDate: Scalars["Time"];
  endDateIso: Scalars["ISO8601Date"];
  /** The number of days the vault is locked */
  lockupDays: Scalars["Int"];
  /** Minimum amount needed to be included in staking vault. */
  minDeposit: Scalars["Float"];
  startDate: Scalars["Time"];
  startDateIso: Scalars["ISO8601Date"];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

export type Quote = {
  __typename?: "Quote";
  /** Fee for the transaction, in unit of the counter asset */
  coinlistFeeAmount: Scalars["Float"];
  expiresAt: Scalars["Time"];
  expiresAtIso: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  /** Price of quote, without fees, in unit of the counter asset */
  price: Scalars["Money"];
};

export type QuoteAcceptInput = {
  entityId: Scalars["ID"];
  rfqId: Scalars["ID"];
};

/** Autogenerated return type of QuoteAccept. */
export type QuoteAcceptPayload = {
  __typename?: "QuoteAcceptPayload";
  errors?: Maybe<Array<Error>>;
  result?: Maybe<Quote>;
  success?: Maybe<Scalars["Boolean"]>;
};

export type QuoteCreateInput = {
  baseAssetSymbol: Scalars["String"];
  counterAssetSymbol: Scalars["String"];
  entityId: Scalars["String"];
  primaryAssetAmount: Scalars["Float"];
  primaryAssetSymbol: Scalars["String"];
  side: QuoteSide;
};

/** Autogenerated return type of QuoteCreate. */
export type QuoteCreatePayload = {
  __typename?: "QuoteCreatePayload";
  errors?: Maybe<Array<Error>>;
  result?: Maybe<Quote>;
  success?: Maybe<Scalars["Boolean"]>;
};

export enum QuoteSide {
  /** Buying a specific asset. */
  Buy = "BUY",
  /** Selling a specific asset. */
  Sell = "SELL",
}

/** Sale metadata for tokens launched on CoinList */
export type SaleInfo = {
  __typename?: "SaleInfo";
  closedAt: Scalars["Time"];
  closedAtIso: Scalars["ISO8601Date"];
  investmentReturnPercentage: Scalars["Int"];
};

/** Objects which can be staked */
export type StakingVault = AutoStakingVault | LockedStakingVault;

/** Transactions for a specific asset within an account. */
export type Transaction = {
  __typename?: "Transaction";
  amount: Scalars["Money"];
  /** Category of the transaction. One of [Deposit, Withdrawal, Transfer, Trade, Earn] */
  category: Scalars["String"];
  createdAt: Scalars["Time"];
  createdAtIso: Scalars["ISO8601DateTime"];
  /** Human-readable description of the transaction */
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  status: TransactionStatus;
};

/** The connection type for Transaction. */
export type TransactionConnection = {
  __typename?: "TransactionConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TransactionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Transaction>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type TransactionEdge = {
  __typename?: "TransactionEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<Transaction>;
};

export enum TransactionStatus {
  Completed = "COMPLETED",
  Failed = "FAILED",
  Pending = "PENDING",
  Rejected = "REJECTED",
}

/** User information */
export type User = InterfacesActiveRecord & {
  __typename?: "User";
  createdAt?: Maybe<Scalars["Int"]>;
  createdAtIso?: Maybe<Scalars["ISO8601DateTime"]>;
  email: Scalars["String"];
  entities?: Maybe<Array<Entity>>;
  /** Reasons the object couldn't be created or updated */
  errors?: Maybe<Array<Scalars["String"]>>;
  firstName: Scalars["String"];
  id?: Maybe<Scalars["ID"]>;
  lastName: Scalars["String"];
  name: Scalars["String"];
  updatedAt?: Maybe<Scalars["Int"]>;
  updatedAtIso?: Maybe<Scalars["ISO8601DateTime"]>;
};

/** this type contains data about an entity's veriff IDV session */
export type VeriffIdvSession = {
  __typename?: "VeriffIdvSession";
  /** whether the user finished the veriff flow or not */
  isSubmitted: Scalars["Boolean"];
  /** the link to the page where the user will go through the veriff flow */
  url: Scalars["Url"];
};

export type VeriffIdvSessionFindOrCreateInput = {
  entityId: Scalars["String"];
};

/** Autogenerated return type of VeriffIdvSessionFindOrCreate. */
export type VeriffIdvSessionFindOrCreatePayload = {
  __typename?: "VeriffIdvSessionFindOrCreatePayload";
  errors?: Maybe<Array<Error>>;
  result?: Maybe<VeriffIdvSession>;
  success?: Maybe<Scalars["Boolean"]>;
};

/** A type that contains information to withdraw from CoinList */
export type WithdrawRequest = {
  __typename?: "WithdrawRequest";
  address: Scalars["String"];
  amount: Scalars["Money"];
  assetFee: Scalars["Money"];
  createdAt: Scalars["Time"];
  createdAtIso: Scalars["ISO8601Date"];
  fiatFee?: Maybe<Scalars["Money"]>;
  id: Scalars["ID"];
  state?: Maybe<Scalars["String"]>;
};

/** A type that contains information to withdraw from CoinList */
export type WithdrawRequestFiatFeeArgs = {
  fiat?: InputMaybe<Scalars["String"]>;
};

export type WithdrawRequestAcceptInput = {
  entityId: Scalars["ID"];
  withdrawRequestId: Scalars["ID"];
};

/** Autogenerated return type of WithdrawRequestAccept. */
export type WithdrawRequestAcceptPayload = {
  __typename?: "WithdrawRequestAcceptPayload";
  errors?: Maybe<Array<Error>>;
  result?: Maybe<WithdrawRequest>;
  success?: Maybe<Scalars["Boolean"]>;
};

export type WithdrawRequestCreateInput = {
  amount: Scalars["Money"];
  destinationAddress: Scalars["String"];
  entityId: Scalars["String"];
};

/** Autogenerated return type of WithdrawRequestCreate. */
export type WithdrawRequestCreatePayload = {
  __typename?: "WithdrawRequestCreatePayload";
  errors?: Maybe<Array<Error>>;
  result?: Maybe<WithdrawRequest>;
  success?: Maybe<Scalars["Boolean"]>;
};

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (
  action,
  _operationName,
  _operationType
) => action();

export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper
) {
  return {};
}
export type Sdk = ReturnType<typeof getSdk>;
