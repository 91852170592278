window.Settings.Usd =
  entity: ($$, $this) ->
    class EntityView extends Backbone.View
      events:
        'click .js-cancel_ach_pull': (event) ->
          if confirm('Are you sure?')
            $target = $(event.target)
            $target.closest('form').ajaxSubmit
              success: (response) =>
                window.location.reload()
              error: (response) =>
                Tooltips.formServerError($target)

    new EntityView el: $this
