Partials = require('../../../lib/partials')
require('@selectize/selectize')
accounting = require('accounting')

window.Market.Entities.Wallets.Usd =
  deposit: ($$, $this) ->
    class DepositView extends Backbone.View
      initialize: ->
        @$usdDepositTabs = @$('.js-usd_deposit_tab')
        @$usdDepositContents = @$('.js-usd_deposit_content')

      events: ->
        'click .js-usd_deposit_tab': (e) ->
          # dehighlight all tabs
          @$usdDepositTabs.removeClass('c-tab__item--active')
          # hide all contents
          @$usdDepositContents.addClass('u-hidden')

          # get clicked tab and highlight it
          $jqCurrentTarget = $(e.currentTarget)
          $jqCurrentTarget.addClass('c-tab__item--active')

          # get associated content and show it
          id = $jqCurrentTarget.attr('id')
          # id format is usd-tab-item-<idx>
          idx = id.split('-').pop()
          # id format is usd-tab-content-<idx>
          $associatedContent = $("#usd-tab-content-#{idx}")
          $associatedContent.removeClass('u-hidden')

      ##### OLD, but keeping here because we will want some similar functionality later #####
      # initialize: ->
      #   @$amount = @$('.js-amount')
      #   @$from = @$('.js-from')
      #   @$formSection =  @$('.js-form-section')
      #   @$reviewSection =  @$('.js-review-section')
      #   @$reviewButton = @$('.js-review')
      #   @$amountError = @$('.js-amount-error')
      #   @$submitButton = @$('.js-usd_deposit_form_submit')
      #   @$errorBox = @$('.js-usd_deposit_form_errors')

      #   @balance = @$el.data('balance')
      #   @accountLabels = @$el.data('account-labels')
      #   @inputErrorClass = @$el.data('input-error-class')

      # _getPlaidLinkUpdatePartial: ->
      #   Partials.selector('market/entities/wallets/usd/deposit/plaid_link_update')

      # _bindPlaidUpdateLinkRefresh: ->
      #   Partials.with @_getPlaidLinkUpdatePartial(), (partial) =>
      #     partial.on 'plaidLinkUpdateSuccess', =>
      #       @$errorBox.slideUp()
      #       @$submitButton.removeClass 'c-button--disabled'

      # _from: ->
      #   accountId = @$from.val()
      #   return @accountLabels[accountId]

      # # Money input have a Cleave instance attached to them (ref: money_input.coffee)
      # _amount: ->
      #   amount = parseFloat(@$amount.data('cleaveInstance').getRawValue())
      #   if isNaN(amount) then 0.0 else amount

      # _validate: ->
      #   @$reviewButton.addClass('c-button--disabled')
      #   @$amountError.hide()
      #   @$amount.removeClass(@inputErrorClass)

      #   return if @_amount() == null

      #   if @_amount() <= 0
      #     @$amountError.text('Amount must be greater than $0').show()
      #     @$amount.addClass(@inputErrorClass)
      #     return

      #   @$reviewButton.removeClass('c-button--disabled')

      # _switch_to_ach_tab: ->
      #   @$('.js-ach_tab').addClass 'c-tab__item--active'
      #   @$('.js-wire_tab').removeClass 'c-tab__item--active'

      #   @$('.js-ach_content').removeClass 'u-hidden'
      #   @$('.js-wire_content').addClass 'u-hidden'

      # _switch_to_wire_tab: ->
      #   @$('.js-wire_tab').addClass 'c-tab__item--active'
      #   @$('.js-ach_tab').removeClass 'c-tab__item--active'

      #   @$('.js-ach_content').addClass 'u-hidden'
      #   @$('.js-wire_content').removeClass 'u-hidden'

      # events: ->
      #   'click .js-ach_tab': (e) ->
      #     @_switch_to_ach_tab()

      #   'click .js-wire_tab': (e) ->
      #     @_switch_to_wire_tab()

      #   'click .js-wire_tab_switch_link': (e) ->
      #     @_switch_to_wire_tab()

      #   'input .js-amount': (e) ->
      #     @_validate()

      #   'click .js-review': (e) ->
      #     @$formSection.addClass('u-hidden')
      #     @$reviewSection.removeClass('u-hidden')
      #     @$('.js-account_options_tabs').hide()

      #     @$('.js-review-from').text(@_from())
      #     @$('.js-review-amount').text(accounting.formatMoney(@_amount()))

      #   'click .js-back': (e) ->
      #     @$formSection.removeClass('u-hidden')
      #     @$reviewSection.addClass('u-hidden')
      #     @$('.js-account_options_tabs').show()
      #     @$submitButton.removeClass 'c-button--disabled'
      #     @$errorBox.slideUp()

      #   'click .js-usd_deposit_form_submit':  (e) ->
      #     $target = @$(e.target)
      #     return if $target.hasClass('c-button--loading')

      #     $target.addClass('c-button--loading')
      #     @$('form.js-deposit-form').ajaxSubmit
      #       success: (response) =>
      #         @$('.js-show-on-success').show()
      #         @$('.js-hide-on-success').hide()
      #         @$('.js-account_options_tabs').hide()

      #       error: (response) =>
      #         $target.removeClass('c-button--loading')
      #         error = response.responseJSON.errors
      #         if error.html
      #           @$errorBox.html(error.html).slideDown()
      #           @$submitButton.addClass 'c-button--disabled'
      #           @_bindPlaidUpdateLinkRefresh()
      #         else if error.message
      #           @$errorBox.text(error.message).slideDown()
      #           @$submitButton.addClass 'c-button--disabled'
      #         else if error.redirect
      #           window.location.href = error.redirect
      #         else
      #           Tooltips.formServerError($target)

    new DepositView el: $this

  withdraw: ($$, $this) ->
    class WithdrawView extends Backbone.View
      HIDDEN_CLASS = 'u-hidden'

      initialize: ->
        @$amount = @$('.js-amount')
        @$amountError = @$('.js-amount-error')
        @$to = @$('.js-external_account_id_input')
        @$transferFee = @$('.js-transfer_fee')
        @$formSection =  @$('.js-form-section')
        @$reviewSection =  @$('.js-review-section')
        @$reviewButton = @$('.js-review')
        @$submitButton = @$('.js-submit')
        @$errorBox = @$('.js-errors')
        @balance = parseFloat(@$el.data('balance'))
        @accountLabels = @$el.data('account-labels')
        @inputErrorClass = @$el.data('input-error-class')
        @accountTypesHash = @$el.data('account-types-hash')
        @internationalExternalWireAccounts = @$el.data('international-external-wire-accounts')
        @achAccountType = @$el.data('ach-account-type')
        @wireAccountType = @$el.data('wire-account-type')
        @senAccountType = @$el.data('sen-account-type')
        @achPushFee = @$el.data('ach-push-fee')
        @domesticWireFee = @$el.data('domestic-wire-fee')
        @internationalWireFee = @$el.data('international-wire-fee')
        @senTransferFee = @$el.data('sen-wire-fee')

      # Money input have a Cleave instance attached to them (ref: money_input.coffee)
      _amount: ->
        amount = parseFloat(@$amount.data('cleaveInstance').getRawValue())
        if isNaN(amount) then 0.0 else amount

      # Hidden input, get/parse value manually
      _transferFee: ->
        fee = parseFloat(@$transferFee.val())
        if isNaN(fee) then 0.0 else fee

      # Amount + Fee
      _total: ->
        @_amount() + @_transferFee()

      _validate: ->
        @$reviewButton.addClass('c-button--disabled')
        @$amountError.hide()
        @$amount.removeClass(@inputErrorClass)

        return if @_amount() == null

        # Return invalid with no error if input is empty
        if !@$amount.data('cleaveInstance').getRawValue()
          return

        if @_amount() <= 0
          @$amountError.text('Amount must be greater than $0').show()
          @$amount.addClass(@inputErrorClass)
          return

        if @_amount() + @_transferFee() > @balance
          errorMessage = "Insufficient funds"
          if @_transferFee() > 0
            errorMessage+= " (Including a #{accounting.formatMoney(@_transferFee())} wire fee)"
          @$amountError.text(errorMessage).show()
          @$amount.addClass(@inputErrorClass)
          return

        @$reviewButton.removeClass('c-button--disabled')

      _to: ->
        accountId = @$to.val()
        return @accountLabels[accountId]

      _submit: ->
        @$('form.js-withdraw-form').ajaxSubmit
          success: (response) =>
            @$errorBox.slideUp()
            @$('.js-show-on-success').show()
            @$('.js-hide-on-success').hide()
            @$('.js-mfa_field').hide()

          error: (response) =>
            @$submitButton.removeClass('c-button--loading')
            error = response.responseJSON.errors
            if error.message
              @$errorBox.text(error.message).slideDown()
              @$submitButton.addClass 'c-button--disabled'
            else
              Tooltips.formServerError(@$submitButton)

      _setWithdrawalMethod: (method) ->
        @$('.js-withdrawal_method').val(method)

      events: ->
        'click .js-review': (e) ->
          e.preventDefault()
          @$formSection.addClass('u-hidden')
          @$reviewSection.removeClass('u-hidden')

          @$('.js-review-to').text(@_to())
          @$('.js-review-amount').text(accounting.formatMoney(@_amount()))
          @$('.js-review-total-amount').text(accounting.formatMoney(@_total()))

        'input .js-amount': ->
          @_validate()

        'cut .js-amount': ->
          @$('.js-amount').trigger('input')

        'click .js-back': (e) ->
          @$formSection.removeClass('u-hidden')
          @$reviewSection.addClass('u-hidden')
          @$('.js-mfa_field').val('')
          @$errorBox.slideUp()

        'click .js-submit':  (e) ->
          $target = @$(e.target)
          return if $target.hasClass('c-button--loading')

          $target.addClass('c-button--loading')
          @_submit()

        'keypress .js-mfa_field': (event) ->
          if event.keyCode == Keyboard.enter
            event.preventDefault()
            return if @$submitButton.hasClass('c-button--loading')

            @$submitButton.addClass('c-button--loading')
            @_submit()

        'input .js-mfa_field': ->
          @$submitButton.removeClass 'c-button--disabled'
          @$errorBox.slideUp()

        'change .js-external_account_id_input': (event) ->
          $target = $(event.target)

          shouldShowAch = @accountTypesHash[$target.val()] == @achAccountType
          shouldShowWire = @accountTypesHash[$target.val()] == @wireAccountType
          shouldShowSen = @accountTypesHash[$target.val()] == @senAccountType

          isInternationalAccount = @internationalExternalWireAccounts.includes($target.val())
          shouldShowWireDomestic = shouldShowWire && !isInternationalAccount
          shouldShowWireInternational = shouldShowWire && isInternationalAccount

          @$('.js-wire_message').toggle shouldShowWire
          @$('.js-review_wire_message').toggle shouldShowWire

          @$('.js-ach_message').toggle shouldShowAch
          @$('.js-review_ach_message').toggle shouldShowAch
          @$('.js-fee_ach_amount').toggle shouldShowAch

          @('.js-sen_transfer_message').toggle shouldShowSen
          @('.js-review_sen_transfer_message').toggle shouldShowSen
          @$('.js-fee_sen_transfer_amount').toggle shouldShowSen

          @$('.js-wire_fees_international_message_form').toggle shouldShowWireInternational
          @$('.js-wire_fees_international_message_review').toggle shouldShowWireInternational
          @$('.js-fee_international_amount').toggle shouldShowWireInternational

          @$('.js-wire_fees_domestic_message_form').toggle shouldShowWireDomestic
          @$('.js-wire_fees_domestic_message_review').toggle shouldShowWireDomestic
          @$('.js-fee_domestic_amount').toggle shouldShowWireDomestic

          if shouldShowAch
            @_setWithdrawalMethod(@achAccountType)

          else if shouldShowWire
            @_setWithdrawalMethod(@wireAccountType)
          else if shouldShowSen
            @_setWithdrawalMethod(@achAccountType)

          if shouldShowWireDomestic
            @$transferFee.val(@domesticWireFee)
          else if shouldShowWireInternational
            @$transferFee.val(@internationalWireFee)
          else if shouldShowAch
            @$transferFee.val(@achPushFee)
          else if shouldShowSen
            @$transferFee.val(@senTransferFee)
          @_validate()

    new WithdrawView el: $this

  convert_usd: ($$, $this) ->
    class ConvertUsdView extends Backbone.View
      LOADING_CLASS: 'c-button--loading'
      DISABLED_CLASS: 'c-button--disabled'

      initialize: ->
        @$amount = @$('.js-amount')
        @$amountError = @$('.js-amount-error')
        @$reviewButton = @$('.js-review')
        @$reviewErrors = @$('.js-review-errors')
        @$submitButton = @$('.js-submit')

        @balance = @$el.data('balance')
        @minAmount = @$el.data('min-amount')
        @decimalPrecision = @$el.data('decimal-precision')
        @feePercent = @$el.data('fee-percent')
        @inputErrorClass = @$el.data('input-error-class')

      _round: (amount) ->
        parseFloat(amount.toFixed(@decimalPrecision))

      _amount: ->
        amount = @$amount.val().replace(',', '')
        return 0 if !amount

        amount = parseFloat(amount)
        return 0.0 if isNaN(amount)
        @_round(amount)

      _fee: ->
        # round up the cents on the fee here. example: 0.0325 will be 0.04
        Math.ceil(@_amount() * @feePercent * 100) / 100

      _clearInputs: ->
        @$amount.val('')

      _validate: ->
        @$reviewButton.addClass(@DISABLED_CLASS)
        @$amountError.hide()
        @$amount.removeClass(@inputErrorClass)

        amount = @_amount()

        return if amount == 0

        if amount > @balance
          @$amountError.text('Insufficient funds').show()
          @$amount.addClass(@inputErrorClass)
          return

        if amount < @minAmount
          @$amountError.text(
            "The minimum amount is #{@_round(@minAmount)}"
          ).show()
          @$amount.addClass(@inputErrorClass)
          return

        @$reviewButton.removeClass(@DISABLED_CLASS)

      events: ->
        'input .js-amount': ->
          @_validate()

        'cut .js-amount': ->
          @$('.js-amount').trigger('input')

        'click .js-review': (e) ->
          $target = @$(e.target)
          return if $target.hasClass(@DISABLED_CLASS)

          # fill fields for review
          @$('.js-review-amount').text(@_amount())
          @$('.js-review-fee').text(@feePercent * 100 + '%')
          @$('.js-review-total-amount').text(@_round(@_amount() - @_fee()))

          # toggle content
          @$('.js-form-section').hide()
          @$('.js-submit-section').show()

        'click .js-back': ->
          @$reviewErrors.hide()

          @$('.js-form-section').show()
          @$('.js-submit-section').hide()

        'click .js-set-to-max': ->
          @$('.js-amount').val(@balance)
          @_validate()

        'click .js-submit':  (e) ->
          $target = @$(e.target)
          return if $target.hasClass(@DISABLED_CLASS) || $target.hasClass(@LOADING_CLASS)

          $target.addClass(@LOADING_CLASS)
          @$reviewErrors.hide()

          @$('form.js-submit-form').ajaxSubmit
            success: (response) =>
              @_clearInputs()
              @$('.js-hide-on-success').hide()
              @$('.js-show-on-success').show()

            error: (response) =>
              if response.responseJSON
                $target.removeClass(@LOADING_CLASS)
                @$reviewErrors.text(response.responseJSON.errors.message).fadeIn()
              else
                Tooltips.formServerError($target)

    new ConvertUsdView el: $this

  pending_transactions: ($$, $this) ->
    class PendingTransactionsView extends Backbone.View

      initialize: ->
        @$('.js-timestamp').each (_i, timestampElem) ->
          $timestampElem = $(timestampElem)
          time = moment($timestampElem.data('time'))
          timeWithZone = moment.tz(time, moment.tz.guess())
          timeForDisplay = timeWithZone.format('MMM D YYYY, h:mm A z')
          $timestampElem.text(timeForDisplay)

      events:
        'click .js-cancel_ach_pull': (event) ->
          if confirm('Are you sure?')
            $target = $(event.target)
            $target.closest('form').ajaxSubmit
              success: (response) =>
                window.location.reload()
              error: (response) =>
                Tooltips.formServerError($target)

    new PendingTransactionsView el: $this

  past_transactions: ($$, $this) ->
    class PastTransactionsView extends Backbone.View
      DISABLED_CLASS = 'disabled'

      initialize: ->
        @page = 1
        @transactionPagesCount = @$el.data('transaction-pages-count')

      events: ->
        'click .js-more': (e) ->
          e.preventDefault()
          $target = @$(e.target)
          return if $target.hasClass(DISABLED_CLASS)

          @page += 1
          $target.addClass(DISABLED_CLASS)
          url = $target.data('url')

          $.ajax
            url: url
            data:
              page: @page
            dataType: 'json'
            success: (data) =>
              if data.html
                $target.removeClass(DISABLED_CLASS)
                $target.hide() if @page >= @transactionPagesCount
                @$('.js-transactions-holder').append(data.html)

    new PastTransactionsView el: $this
