import * as React from "react";
import { useState } from "react";

import {
  Button,
  TextInput,
  Select,
  Field,
  Container,
  Label,
  Divider,
} from "@react/components";
import Flex from "@react/components/Flex";
import LargeDisplayAmount from "@react/components/LargeDisplayAmount";
import Loading, { RequestData } from "@react/components/Loading";
import RadioCollections from "@react/views/shared/forms/RadioCollections/RadioCollections";
import { RequestType } from "@react/utils/network";
import Typography from "@react/components/typography/Typography";
import InfoWarning from "@react/components/warnings/InfoWarning";
import BlockField from "@react/views/shared/forms/BlockField/BlockField";
import InputWithAddon from "@react/views/shared/forms/InputWithAddon/InputWithAddon";
import { getEntitiesForSelect, roundFloat } from "@react/utils";
import { isMediumOrSmaller, invalidNumber, dollarValue } from "@react/utils";

const BUY_DIRECTION = "BUY";
const SELL_DIRECTION = "SELL";

export default function New(props) {
  const mediumOrSmaller = isMediumOrSmaller();

  const [amount, setAmount] = useState<string>("");
  const [baseAssetSymbol, setBaseAssetSymbol] = useState<string>(
    Object.keys(props.assetPairMap)[0]
  );
  const [counterAssetSymbol, setCounterAssetSymbol] = useState<string>(
    props.assetPairMap[baseAssetSymbol][0]
  );
  const [direction, setDirection] = useState<string>(BUY_DIRECTION);
  const [email, setEmail] = useState<string>("");
  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [memo, setMemo] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [requestData, setRequestData] = useState<RequestData>(null);
  const [response, setResponse] = useState(null);

  /* https://stackoverflow.com/a/57525133 */
  const [entityOptions] = useState(() => getEntitiesForSelect(props.entities));
  const [selectedEntityId, setSelectedEntityId] = useState<any>(
    entityOptions.length ? entityOptions[0].value : ""
  );

  const hasErrors = ({
    amount,
    baseAssetSymbol,
    counterAssetSymbol,
    selectedEntityId,
    direction,
  }) => {
    const newErrors: any = {};

    const assetToSellSymbol =
      direction === BUY_DIRECTION ? counterAssetSymbol : baseAssetSymbol;
    const tradeDollarValue = dollarValue(
      amount,
      props.priceMap[baseAssetSymbol]
    );
    const balanceDollarValue = dollarValue(
      props.entitiesBalancesMap[selectedEntityId][assetToSellSymbol],
      props.priceMap[assetToSellSymbol]
    );

    if (invalidNumber(amount)) {
      newErrors.amount = "Please enter a valid amount";
    } else if (tradeDollarValue < parseFloat(props.minOrderValue)) {
      newErrors.amount = `OTC orders must be worth at least $${props.minOrderValue}`;
    } else if (balanceDollarValue < tradeDollarValue) {
      newErrors.amount = `OTC order amount is more than account balance`;
    }

    if (baseAssetSymbol === counterAssetSymbol) {
      newErrors.counterAssetSymbol =
        "The counter asset must be different from the base asset";
    }
    if (entityOptions.length === 0 && !name) {
      newErrors.name = "Please enter your name";
    }
    if (entityOptions.length === 0 && !email) {
      newErrors.email = "Please enter your email";
    }

    if (!props.entitiesVerifiedMap[selectedEntityId]) {
      newErrors.selectedEntityId =
        "Can't submit OTC trade with unverified entity";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length > 0;
  };

  const assetOptions = Object.keys(props.assetPairMap).map((symbol) => ({
    labelText: symbol,
    value: symbol,
  }));

  const counterAssetOptions = props.assetPairMap[baseAssetSymbol].map(
    (symbol) => ({
      labelText: symbol,
      value: symbol,
    })
  );

  const radioOptions = [
    {
      label: "Buy",
      radio: { value: BUY_DIRECTION },
    },
    {
      label: "Sell",
      radio: { value: SELL_DIRECTION },
    },
  ];

  const getRadioButtonForm = () => (
    <Field>
      <Label style={{ marginBottom: 8 }}>Buy/Sell</Label>
      <Flex container spacing={1} style={{ flexWrap: "nowrap" }}>
        <RadioCollections
          radioOptions={radioOptions}
          value={direction}
          name="trade-direction"
          onChange={(e) => setDirection(e.target.value)}
        />
      </Flex>
    </Field>
  );

  const renderMainComponent = () => {
    return (
      <Flex container style={{ backgroundColor: "#FEFEFF", padding: 32 }}>
        <Flex item style={{ marginBottom: response ? 0 : 20 }} xs={24}>
          <Typography type="h2">
            {mediumOrSmaller ? "OTC Trade" : "OTC Trade Request"}
          </Typography>
          <Flex container>
            <Divider spacingTop={1} spacingBottom={1.5} />
          </Flex>
          {!response && (
            <Flex container spacing={2}>
              <InfoWarning>
                Once we receive your request, a trader from our desk will email
                you with details on the price, fees, and next steps to execute
                the proposed trade.
              </InfoWarning>
            </Flex>
          )}
        </Flex>
        <Flex container justifyContent="center">
          <Loading
            handleResponse={(response) => {
              setResponse(response);
              setLoading(false);
            }}
            loading={loading}
            requestData={requestData}
          />
        </Flex>
        {!loading &&
          (response ? (
            <Flex container spacing={2}>
              <Flex item xs={24}>
                <Typography>
                  {response.status === 200
                    ? "Your trade request has been received. A trader from our desk will reach out shortly with next steps."
                    : response.data.errors.message}
                </Typography>
              </Flex>
              <Flex
                item
                justifyContent="flex-end"
                style={{ paddingTop: 32 }}
                xs={24}
              >
                {response.status === 200 ? (
                  <Button
                    onClick={() =>
                      (window.location.href =
                        entityOptions.length > 0 ? "/dashboard" : "/otc")
                    }
                  >
                    {entityOptions.length > 0
                      ? "Go to dashboard"
                      : "Back to landing page"}
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setRequestData(null);
                      setResponse(null);
                    }}
                  >
                    Try again
                  </Button>
                )}
              </Flex>
            </Flex>
          ) : (
            <Flex container spacing={mediumOrSmaller ? 0 : 2}>
              <Flex item xs={mediumOrSmaller ? 24 : 16}>
                <Flex container spacing={mediumOrSmaller ? 0 : 2}>
                  {entityOptions.length > 0 ? (
                    <Flex container spacing={2}>
                      <Flex item xs={mediumOrSmaller ? 24 : 12}>
                        <div className={mediumOrSmaller ? "u-width100" : ""}>
                          <BlockField
                            error={errors.selectedEntityId}
                            label="Entity"
                          >
                            <Select
                              onChange={(e) =>
                                setSelectedEntityId(e.target.value)
                              }
                              options={entityOptions}
                              value={selectedEntityId}
                            />
                          </BlockField>
                        </div>
                      </Flex>
                      <Flex>{getRadioButtonForm()}</Flex>
                    </Flex>
                  ) : (
                    <Flex container spacing={2}>
                      <Flex item xs={mediumOrSmaller ? 24 : 8}>
                        <BlockField
                          label="Full Name"
                          name="name"
                          error={errors.name}
                        >
                          <TextInput
                            value={name}
                            name="name"
                            onChange={(e) => setName(e.currentTarget.value)}
                          />
                        </BlockField>
                      </Flex>
                      <Flex item xs={mediumOrSmaller ? 24 : 8}>
                        <BlockField
                          label="Email"
                          name="email"
                          error={errors.email}
                        >
                          <TextInput
                            value={email}
                            name="email"
                            onChange={(e) => setEmail(e.currentTarget.value)}
                          />
                        </BlockField>
                      </Flex>
                      <Flex>{getRadioButtonForm()}</Flex>
                    </Flex>
                  )}
                  <Flex container spacing={2} style={{ paddingBottom: 8 }}>
                    <Flex item xs={mediumOrSmaller ? 24 : 8}>
                      <BlockField
                        label={direction === BUY_DIRECTION ? "Buy" : "Sell"}
                      >
                        <Select
                          onChange={(e) => {
                            const newSymbol = e.currentTarget.value;
                            setBaseAssetSymbol(newSymbol);
                            setCounterAssetSymbol(
                              props.assetPairMap[newSymbol][0]
                            );
                          }}
                          options={assetOptions}
                          value={baseAssetSymbol}
                        />
                      </BlockField>
                    </Flex>
                    <Flex item xs={mediumOrSmaller ? 24 : 8}>
                      <BlockField
                        error={errors.counterAssetSymbol}
                        label={direction === BUY_DIRECTION ? "With" : "For"}
                      >
                        <Select
                          onChange={(e) =>
                            setCounterAssetSymbol(e.currentTarget.value)
                          }
                          options={counterAssetOptions}
                          value={counterAssetSymbol}
                        />
                      </BlockField>
                    </Flex>
                    <Flex item xs={mediumOrSmaller ? 24 : 8}>
                      <BlockField error={errors.amount} label="Amount">
                        <InputWithAddon
                          position="right"
                          addon={baseAssetSymbol}
                        >
                          <TextInput
                            value={amount}
                            onChange={(e) => setAmount(e.currentTarget.value)}
                          />
                        </InputWithAddon>
                      </BlockField>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
              {!mediumOrSmaller && (
                <Flex item style={{ overflow: "hidden" }} xs={8}>
                  <LargeDisplayAmount
                    mainString={`$${roundFloat(
                      dollarValue(amount, props.priceMap[baseAssetSymbol]),
                      0
                    ).toString()}`}
                    topLabelText={"Trade Dollar Value"}
                  />
                </Flex>
              )}
              <Flex item xs={24}>
                <Flex
                  container
                  justifyContent="flex-end"
                  style={{ paddingTop: mediumOrSmaller ? 32 : 0 }}
                >
                  <Button
                    onClick={() => {
                      if (
                        !hasErrors({
                          amount,
                          baseAssetSymbol,
                          counterAssetSymbol,
                          selectedEntityId,
                          direction,
                        })
                      ) {
                        setRequestData({
                          data: {
                            amount_subunit: amount,
                            base_asset_symbol: baseAssetSymbol,
                            counter_asset_symbol: counterAssetSymbol,
                            direction: direction,
                            entity_id: selectedEntityId,
                            logged_out_email: email,
                            logged_out_name: name,
                            memo: memo,
                          },
                          type: RequestType.POST,
                          url: props.url,
                        });
                        setLoading(true);
                      }
                    }}
                  >
                    Submit
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          ))}
      </Flex>
    );
  };

  if (entityOptions.length === 0) window.location.href = "/dashboard";
  else
    return mediumOrSmaller ? (
      renderMainComponent()
    ) : (
      <Container maxSize={entityOptions.length > 0 ? "18" : "24"}>
        {renderMainComponent()}
      </Container>
    );
}
