import * as React from "react";

import { Container, Divider, Spacer } from "@react/components";
import HowItWorks from "./components/staking/HowItWorks";
import Rewards from "./components/staking/Rewards";
import FAQ from "./components/staking/FAQ";
import Disclaimer from "./components/staking/Disclaimer";

export default function Staking(props) {
  const { stakingAssets } = props;
  return (
    <div style={{ backgroundColor: "#EEEEEE" }}>
      <Container maxSize="18">
      <div className="s-paddingTop6" style={{ backgroundColor: "#EEEEEE" }} />
        <Rewards
          registerLink={props.registerLink}
          stakingAssets={stakingAssets}
        />
        <Divider />
        <HowItWorks registerLink={props.registerLink} />
        <Divider />
        <FAQ />
        <Divider />
        <Disclaimer />
        <div className="s-paddingTop6" style={{ backgroundColor: "#EEEEEE" }} />
      </Container>
    </div>
  );
}
