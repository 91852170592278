import * as React from "react";

import Flex from "../Flex";
import Typography from "../typography/Typography";

interface DisclaimerProps {
  children: React.ReactNode;
}

export default function Disclaimer(props: DisclaimerProps) {
  return (
    <Flex container>
      <Typography>
        <i>{props.children}</i>
      </Typography>
    </Flex>
  );
}
