import * as React from "react";
import { useState } from "react";

import { Grid } from "@mui/material";

import ProgressButton from "./ProgressButton";
import { useStyles } from "../hooks/useStyles";

export interface FormInputWrapperProps
  extends React.HtmlHTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  handleSubmit: () => Promise<void>;
  submitLabel?: string;
  columns?: 1 | 2 | 3;
}

const FormWrapper: React.FC<FormInputWrapperProps> = ({
  className,
  children,
  handleSubmit,
  columns,
  submitLabel,
}: FormInputWrapperProps) => {
  columns = columns ?? 1;
  submitLabel = submitLabel ?? "submit";

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const styles = useStyles();

  const gridItemWidth = Math.floor(12 / columns);
  return (
    <div className={className}>
      <Grid container spacing={2}>
        {React.Children.map(children, (child) => {
          return (
            <Grid item xs={gridItemWidth}>
              {child}
            </Grid>
          );
        })}
      </Grid>
      <div className={styles.submitButtonContainer}>
        <ProgressButton
          loading={isLoading}
          className={styles.submitButton}
          onClick={async () => {
            setIsLoading(true);
            await handleSubmit();
            setIsLoading(false);
          }}
        >
          Submit
        </ProgressButton>
      </div>
    </div>
  );
};
export default FormWrapper;
