import * as React from "react";
import { useEffect, useState } from "react";

import Flex from "@react/components/Flex";
import { Button, Container } from "@react/components";

import ContactDialog from "../lend/ContactDialog";

export default function LendHero(props) {
  const [open, setOpen] = React.useState(false);
  const [response, setResponse] = useState(null);

  useEffect(() => {
    if (response && open) {
      setResponse(null);
    }
  }, [open]);

  return (
    /*  Generate gradients: https://cssgradient.io/ */
    <div
      style={{
        color: "#000",
        backgroundColor: "#fafafa",
      }}
    >
      <Container maxSize="18">
        <Flex container style={{ paddingBottom: 136, paddingTop: 104 }}>
          <h1 className="s-fontSize48 s-marginBottom1 u-fontWeight700">
            Borrow FIL on CoinList: For Filecoin Miners and Other Borrowers
          </h1>
          <h2 className="s-fontSize20">
            CoinList’s FIL Lending Desk offers a reliable and sustainable method
            for Filecoin borrowers and storage miners to obtain fixed term FIL
            loans starting at 3,000 FIL.
          </h2>
          <Flex container style={{ marginTop: 24 }}>
            <Button
              onClick={() => setOpen(true)}
              style={{ marginRight: 10 }}
              variant="primary"
            >
              Borrow FIL
            </Button>
          </Flex>
        </Flex>
        <ContactDialog onClose={() => setOpen(false)} open={open} />
      </Container>
    </div>
  );
}
