require('datatables')
require('underscore')
require('../lib/keyboard')
PrettyAlert = require('../lib/pretty_alert')

window.Offerings.Manage =

  external_funding_dropdown_row: ($$, $this) ->
    class ToggleView extends Backbone.View
      events:
        'click .js-toggle_completion': (event) ->
          event.preventDefault()
          $.ajax $$(event.currentTarget).attr('href'),
            dataType: 'json',
            type: 'POST',
            success: (response) =>
              # If the completion of this payment made the investment complete, let's flip
              # the color on the investment status as well.
              if response.investment_complete
                $this.closest('.js-investment_status').removeClass('u-colorOrange').
                      addClass('u-colorGreen')
              else
                $this.closest('.js-investment_status').removeClass('u-colorGreen').
                      addClass('u-colorOrange')
              $this.replaceWith(response.html)
            error: =>
              alert('There was an issue marking that payment as complete. Please report this
                     error to team@coinlist.co')

    new ToggleView el: $this

  admins: ($$, $this) ->
    class AdminsView extends Backbone.View
      events:
        'click .js-remove': (event) ->
          if confirm('Are you sure?')
            $target = $(event.target)
            $target.closest('form').ajaxSubmit
              success: (response) =>
                window.location.reload()
              error: (response) =>
                Tooltips.formServerError($target)

        'click .js-add_offering_admin': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass 'c-button--loading'
          $target.addClass 'c-button--loading'
          @$('.js-errors').slideUp()

          $target.closest('form').ajaxSubmit
            success: (response) =>
              window.location.reload()
            error: (response) =>
              $target.removeClass 'c-button--loading'
              error_message = response.responseJSON.error_message
              if error_message
                @$('.js-errors').text(error_message).slideDown()
              else
                Tooltips.formServerError($target)

    new AdminsView el: $this

  disallowed_jurisdictions: ($$, $this) ->
    class DisallowedJurisdictionsView extends Backbone.View
      events: ->
        'click .js-remove': (event) ->
          $target = $(event.target)
          if confirm("Are you sure?")
            $target.closest('form').ajaxSubmit
              success: (response) =>
                $target.closest('.js-tag').remove()
              error: (response) =>
                Tooltips.formServerError($target)

        'click .js-save-jurisdiction': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass 'c-button--loading'
          $target.addClass 'c-button--loading'

          $form = $target.closest('form')
          $form.find('.js-name').val($form.find('option:selected').text())

          $form.ajaxSubmit
            success: (response) =>
              window.location.reload()
            error: (response) =>
              $target.removeClass 'c-button--loading'
              Tooltips.formServerError($target)

    new DisallowedJurisdictionsView el: $this


  whitelist: ($$, $this) ->
    class WhitelistView extends Backbone.View
      events:
        'click .js-submit-form': (event) ->
          event.preventDefault()
          $target = $(event.target)
          if !$target.data('require-confirmation') || confirm('Are you sure?')
            return if $target.hasClass 'c-button--loading'
            $target.addClass 'c-button--loading'
            $target.closest('form').ajaxSubmit
              success: (response) =>
                window.location.reload()
              error: (response) =>
                if response.status == 422
                  @$el.replaceWith response.responseJSON.html
                else
                  Tooltips.formServerError($target)

    new WhitelistView el: $this

  bids: ($$, $this) ->
    class BidsView extends Backbone.View
      events:
        'click .js-raw_bid': (event) ->
          PrettyAlert.alert($$(event.currentTarget).data('raw'))

    new BidsView el: $this

  settings: ($$, $this) ->
    class SettingsView extends Backbone.View
      events:
        'click .js-save': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass 'c-button--loading'
          $target.addClass 'c-button--loading'
          $target.closest('form').ajaxSubmit
            success: (response) =>
              window.location.reload()
            error: (response) =>
              if response.status == 422
                @$el.replaceWith response.responseJSON.html
              else
                Tooltips.formServerError($target)

    new SettingsView el: $this


  table: ($$, $this) ->
    class TableView extends Backbone.View
      initialize: ->
        @createdAtColumn = @$('.js-created_at_column').index()
        url = @$('.js-offerings-manage-table').data('url')
        options = _.extend(DataTablePresets.default(), {
          order: [[ @createdAtColumn, "desc" ]],
          scrollCollapse: true,
          pageLength: 20,
          serverSide: true,
          processing: true,
          autoWidth: false,
          ajax: (data, callback, settings) =>
            @$('.js-offerings-manage-table').addClass('c-table--loading')
            data = _.extend(data, { show_hidden: @$('.js-show_hidden').prop('checked')})
            $.ajax url,
              dataType: 'json'
              type: 'GET'
              data: data
              success: (response) =>
                @$('.js-offerings-manage-table').removeClass('c-table--loading')
                api = settings.oInstance.api()
                dataRows = $(response.rows).filter('tr')
                totalRows = response.total_records
                callback({data: {}, recordsFiltered: totalRows, recordsTotal: totalRows, draw: 0})
                api.rows.add dataRows
                settings.aiDisplay = settings.aiDisplayMaster.slice()
                settings.bAjaxDataGet = false
                api.draw(false)
                settings.bAjaxDataGet = true
        })
        @tableApi = @$('.js-offerings-manage-table').DataTable(options)

      search: ->
        @tableApi.search(@$('.js-search_input').val()).draw()

      events:
        'click .js-verify_voucher': (event) ->
          event.preventDefault()
          $target = $(event.currentTarget)
          $.post $target.prop('href'), (response) =>
            $controls = $target.closest('.js-voucher_controls')
            $controls.find('.js-voucher_icons').hide()
            $container = $controls.find('.js_voucher_state_container')
            $container.find('.js-voucher_state').text(response.state)
            $container.show()

        'click .js-reject_voucher': (event) ->
          event.preventDefault()
          $target = $(event.currentTarget)
          $.post $target.prop('href'), (response) =>
            $controls = $target.closest('.js-voucher_controls')
            $controls.find('.js-voucher_icons').hide()
            $container = $controls.find('.js_voucher_state_container')
            $container.find('.js-voucher_state').text(response.state)
            $container.show()

        'keydown .js-search_input': (event) ->
          if event.keyCode == Keyboard.enter
            event.preventDefault()
            @search()

        'click .js-search': ->
          @search()

        # Note(andreasklinger): The checkbox value gets added above automatically to data
        #   ideally this would just be link that triggers datatables' api with correct value
        #   but i couldnt figure out how to trigger the api correctly.
        'change .js-show_hidden': (event) ->
          @search()

    new TableView el: $this

  xpub: ($$, $this) ->
    class XpubView extends Backbone.View
      events: ->
        'click .js-submitCheck': (event) ->
          event.preventDefault()
          $$('.js-addressesHolder').hide()
          $target = $(event.target)
          $target.addClass 'c-button--loading'
          $target.closest('form').ajaxSubmit
            success: (response) =>
              $$('.js-addresses').html(response.addresses)
              $$('.js-addressesHolder, .js-submitToBursarPrompt').show()
              $$('.js-submitCheck').hide()
            error: (response) =>
              alert(response.responseJSON.error)
            complete: =>
              $target.removeClass 'c-button--loading'

        'click .js-submitSave': (event) ->
          event.preventDefault()
          $target = $(event.target)
          $target.addClass 'c-button--loading'
          $target.closest('form').ajaxSubmit
            data:
              save: true
            success: =>
              window.location.reload()
            error: (response) =>
              alert(response.responseJSON.error)
            complete: =>
              $target.removeClass 'c-button--loading'

        'click .js-resetForm': ->
          @resetForm()
          $$('form').trigger('reset')

        'change .js-resetIfChanged': 'resetForm'
        'keyup .js-resetIfChanged': 'resetForm'

      resetForm: ->
        $$('.js-addressesHolder, .js-submitToBursarPrompt').hide()
        $$('.js-submitCheck').show()

    new XpubView el: $this

  reports: ($$, $this) ->
    class ReportsView extends Backbone.View
      events: ->
        'click .js-view-all': (event) ->
          @$(event.target).hide()
          @$('.js-report').show()

    new ReportsView el: $this

  overview: ($$, $this) ->
    class OverviewView extends Backbone.View
      events: ->
        'change .js-show_hidden': (event) ->
          showHidden = @$(event.target).is(':checked')
          window.location.search = "?show_hidden=#{showHidden}"

    new OverviewView el: $this
