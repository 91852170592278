import * as React from "react";
import { useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import Snackbar from "@mui/material/Snackbar";

import { Button, Divider, TextInput, Link } from "@react/components";
import Dialog from "@mui/material/Dialog";
import Flex from "@react/components/Flex";
import InfoWarning from "@react/components/warnings/InfoWarning";
import BlockField from "@react/views/shared/forms/BlockField/BlockField";
import InputWithAddon from "@react/views/shared/forms/InputWithAddon/InputWithAddon";

import LargeDisplayAmount from "@react/components/LargeDisplayAmount";
import Loading, { RequestData } from "@react/components/Loading";
import Typography from "@react/components/typography/Typography";
import Warning from "@react/components/warnings/Warning";

import { RequestType } from "@react/utils/network";
import { getFormattedTime } from "@react/utils/date";
import {
  appendQueryStringToCurrentUrl,
  isExtraSmallOrSmaller,
  isInt,
  roundFloat,
} from "@react/utils";
import { SegmentReact } from "@react/analytics/segment_react";

interface UnstakeDialogProps {
  assetSymbol: string;
  endDate: string;
  onClose: () => void;
  open: boolean;
  stakedAmount: number;
  stakedAmountFormattedStr: string;
  url: string;
  unstakeDelay: number;
  userId: string;
  sessionId: string;
  originPage: string;
}

const BUTTON_HEIGHT = 46;
const CONTAINER_PADDING = 20;

const successfulRequest = (response) => {
  return Boolean(response) && response.status === 200;
};

const responseMessage = (response) => {
  if (!response) {
    return "Error making request, please try again later.";
  }
  if (successfulRequest(response)) {
    return `Successfully processed your request.`;
  }
  if (response.data.errors) {
    return response.data.errors.message;
  }
  return "Error processing your request, please try again later.";
};

const getWalletMessage = (symbol, delay) => {
  let message = `After processing, funds will be sent to your ${symbol} wallet`;
  if (delay != 0) {
    return `${message} when it is unbonded from the protocol (~${delay} days after it is processed)`;
  }
  return `${message}.`;
};

const useStyles = makeStyles(() => ({
  dialogPaper: {
    minHeight: 400,
    maxWidth: 615,
    width: "100%",
  },
  dialogPaperXs: {
    minHeight: 470,
    maxWidth: 600,
    width: "100%",
  },
}));

export default function UnstakeDialog(props: UnstakeDialogProps) {
  const classes = useStyles();

  const [percentToWithdraw, setPercentToWithdraw] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [requestData, setRequestData] = useState<RequestData>(null);
  const [response, setResponse] = useState(null);

  const insufficientBalanceError =
    (percentToWithdraw !== "" && isNaN(parseFloat(percentToWithdraw))) ||
    parseFloat(percentToWithdraw) > 100;
  const floatingPointError =
    percentToWithdraw !== "" &&
    !isNaN(parseFloat(percentToWithdraw)) &&
    !isInt(parseFloat(percentToWithdraw));

  const paperStyle = isExtraSmallOrSmaller()
    ? classes.dialogPaperXs
    : classes.dialogPaper;

  return (
    <Dialog
      classes={{
        paper: paperStyle,
      }}
      fullWidth
      onClose={props.onClose}
      open={props.open}
    >
      <Flex
        container
        style={{
          padding: `${CONTAINER_PADDING}px ${CONTAINER_PADDING}px ${
            CONTAINER_PADDING + BUTTON_HEIGHT
          }px ${CONTAINER_PADDING}px`,
          position: "relative",
        }}
      >
        <Flex container spacing={1}>
          <Flex item xs={24}>
            <Typography type="h6">Unstake</Typography>
          </Flex>
          <Flex item xs={24}>
            <Divider spacingTop={0} spacingBottom={0} />
          </Flex>
          {response && successfulRequest(response) ? (
            <Flex
              container
              spacing={2}
              style={{
                marginTop: 16,
              }}
            >
              <Flex item justifyContent="center" xs={24}>
                <i
                  className={`icon-ok u-colorGreen`}
                  style={{ fontSize: 64 }}
                ></i>
              </Flex>
              <Flex container spacing={1}>
                <Flex item justifyContent="center" xs={24}>
                  <Typography center bold fontSize={20}>
                    {responseMessage(response)}
                  </Typography>
                </Flex>
                <Flex
                  item
                  justifyContent="center"
                  style={{ paddingBottom: 32 }}
                  xs={24}
                >
                  <Typography center fontSize={16}>
                    Funds will be moved to your {props.assetSymbol} wallet after
                    the current rewards period ends on{" "}
                    {getFormattedTime(props.endDate)}.
                  </Typography>
                </Flex>
              </Flex>
              <Button
                onClick={() => {
                  const queryString = `?symbol=${props.assetSymbol}`;
                  window.location.href = appendQueryStringToCurrentUrl(
                    queryString
                  );
                }}
                style={{
                  height: BUTTON_HEIGHT,
                  width: 113,
                  bottom: CONTAINER_PADDING,
                  position: "absolute",
                  right: CONTAINER_PADDING,
                }}
              >
                Exit
              </Button>
            </Flex>
          ) : (
            <Flex container spacing={2}>
              <Flex container spacing={2}>
                <Flex item sm={8} xs={24}>
                  <label
                    className={`c-label s-fontSize12 ${
                      insufficientBalanceError || floatingPointError
                        ? "u-colorRed"
                        : ""
                    }`}
                  >
                    Percentage&nbsp;&middot;&nbsp;
                    <Link onClick={() => setPercentToWithdraw("100")}>Max</Link>
                  </label>
                  <BlockField
                    error={insufficientBalanceError || floatingPointError}
                    hint={`Amount Available: ${props.stakedAmountFormattedStr}`}
                  >
                    <InputWithAddon addon="%">
                      <TextInput
                        placeholder="15"
                        value={percentToWithdraw.toString() || ""}
                        onChange={(e) =>
                          setPercentToWithdraw(
                            isNaN(parseFloat(e.target.value))
                              ? ""
                              : e.target.value
                          )
                        }
                      />
                    </InputWithAddon>
                  </BlockField>
                </Flex>
                <Flex item sm={16} style={{ overflow: "hidden" }} xs={0}>
                  <LargeDisplayAmount
                    error={insufficientBalanceError || floatingPointError}
                    mainString={`${
                      isNaN(parseFloat(percentToWithdraw))
                        ? "0"
                        : roundFloat(
                            (parseFloat(percentToWithdraw) *
                              parseFloat(props.stakedAmountFormattedStr.split(",").join(""))) /
                              100,
                            2
                          )
                    } ${props.assetSymbol}`}
                    topLabelText={"Estimated Amount"}
                  />
                </Flex>
              </Flex>
              <Flex item xs={24}>
                <InfoWarning>
                  Please note, the amount available does not include rewards earned for the staking period.
                  If you elect to withdraw 100% of the amount, then CoinList will send you the balance + rewards earned.
                </InfoWarning>
              </Flex>
              <Flex item style={{ paddingBottom: 16 }} xs={24}>
                <InfoWarning>
                  Withdrawal requests are processed at the end of the current
                  rewards period: {getFormattedTime(props.endDate)} .{" "}
                  {getWalletMessage(props.assetSymbol, props.unstakeDelay)}
                </InfoWarning>
              </Flex>
              <Button
                onClick={() => {
                  if (insufficientBalanceError || percentToWithdraw === "") {
                    alert(
                      "Please enter a valid percentage of your staked amount to withdraw."
                    );
                    return;
                  }
                  if (floatingPointError) {
                    alert(
                      "Please enter a whole number to withdraw rather than a decimal."
                    );
                    return;
                  }
                  setRequestData({
                    data: {
                      vault_withdraw: {
                        percentage_of_balance_to_withdraw: percentToWithdraw,
                      },
                    },
                    type: RequestType.POST,
                    url: props.url,
                  });
                  setLoading(true);
                }}
                style={{
                  height: BUTTON_HEIGHT,
                  width: 113,
                  bottom: CONTAINER_PADDING,
                  position: "absolute",
                  right: CONTAINER_PADDING,
                }}
              >
                <Loading
                  handleResponse={(response) => {
                    if (!successfulRequest(response)) {
                      setOpen(true);
                    } else {
                      SegmentReact.track(
                        props.assetSymbol + " " + props.originPage + " Page",
                        {
                          page_event: props.assetSymbol + " Unstake successful",
                          user_id: props.userId,
                          session_id: props.sessionId,
                          viewed_at: new Date(),
                        }
                      );
                    }
                    setResponse(response);
                    setLoading(false);
                  }}
                  loading={loading}
                  requestData={requestData}
                  spinnerColor="white"
                />
                {!loading && "Unstake"}
              </Button>
            </Flex>
          )}
        </Flex>
      </Flex>
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        autoHideDuration={3000}
        open={open}
        onClose={() => setOpen(false)}
      >
        <Warning
          color={"orange"}
          fullWidth
          fontSize={13}
          padding={8}
          variant={"warning"}
          warningText={responseMessage(response)}
        />
      </Snackbar>
    </Dialog>
  );
}
