import * as React from "react";
import { KarmaHistoryLog } from "@coinlist/frontend-ui-lib"
import { ampli } from '../../../../ampli'
require("@coinlist/frontend-ui-lib/dist/style.css");

export default function KarmaHistory(props) {
    return (
        <KarmaHistoryLog amplitudeEvent={(event, data) => ampli.track(event, undefined, data)} entityId={props.entityId} apiUrl={"/karma"} />
    );
}
