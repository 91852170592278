import * as React from "react";
import { Grid, Col, Spacer, Link, Icon, Tag } from "@react/components";
import GovernanceProtocolsShowSidebar from "@react/views/governance/protocols/show/sidebar";
import { renderTextFromMarkdown } from "@react/utils/markdown";
import * as moment from "moment";

export interface GovernanceProtocolsShowProps {
  protocol: any;
  proposals: any[];
  governance_path: string;
  governance_protocol_path: string;
  governance_faq_path: string;
}

export const GovernanceProtocolsShow: React.FunctionComponent<GovernanceProtocolsShowProps> = ({
  protocol,
  proposals,
  governance_path,
  governance_protocol_path,
  governance_faq_path,
}) => {
  const renderProposalsList = (_proposals: any) => {
    return _proposals.map((_proposal) => {
      const endAt = moment(_proposal.end_at);
      const textContent = renderTextFromMarkdown(_proposal.content);
      var truncatedTextContent = textContent.substr(0, 175);
      truncatedTextContent = truncatedTextContent.substr(
        0,
        Math.min(
          truncatedTextContent.length,
          truncatedTextContent.lastIndexOf(" ")
        )
      );
      return (
        <div className="s-marginBottom1_5" key={_proposal.id}>
          <Link unstyled href={_proposal.path}>
            <div className="u-displayFlex c-box s-padding1 u-alignItemsCenter">
              <div className="u-flex1 u-displayFlex">
                <div className="u-flex1">
                  <h4 className="s-fontSize18 s-paddingRight0_5 u-fontWeight700">
                    {_proposal.title}
                  </h4>
                  {truncatedTextContent && (
                    <p className="u-colorGray8 s-marginTop0_5">
                      {truncatedTextContent}...
                    </p>
                  )}
                  <p className="u-colorGrayA s-marginTop0_5 s-fontSize14">
                    {endAt.isBefore() ? "Ended on " : "Deadline:"}{" "}
                    {endAt.format("MMMM Do YYYY")}
                  </p>
                </div>
              </div>
              <span className="s-fontSize16 u-colorGray8">
                <Icon icon="chevron-right" />
              </span>
            </div>
          </Link>
        </div>
      );
    });
  };

  const publishedProposals = proposals
    .filter((proposal) => proposal.state === "published")
    .sort((a, b) => {
      return new Date(a.start_at).getTime() - new Date(b.start_at).getTime();
    })
    .reverse();

  const closedProposals = proposals
    .filter((proposal) => proposal.state === "closed")
    .sort((a, b) => {
      return new Date(a.start_at).getTime() - new Date(b.start_at).getTime();
    })
    .reverse();

  return (
    <Grid>
      <Col sm="24">
        <h1 className="s-fontSize20 s-marginBottom2 u-fontWeight700 s-borderBottom1 u-displayFlex u-alignItemsCenter u-hiddenSmDown">
          <Link href={governance_path}>
            <span className="s-marginRight0_5">
              <Icon icon="governance" />
            </span>
            Governance Protocols
          </Link>
          <span className="s-paddingHoriz0_5 s-fontSize18 u-displayInlineBlock u-colorGrayA">
            /
          </span>
          {protocol.token_profile.name} Proposals
          <span className="u-flex1 u-textAlignRight u-fontWeight400">
            <Link href={governance_faq_path} target="_blank">
              FAQs
            </Link>
          </span>
        </h1>
        <p className="u-hiddenMdUp s-marginBottom1 u-displayFlex">
          <Link href={governance_path}>&laquo; Back to all Protocols</Link>
          <span className="u-flex1 u-fontWeight400 u-textAlignRight">
            <Link href={governance_faq_path} target="_blank">
              FAQs
            </Link>
          </span>
        </p>
      </Col>
      <Col sm="8">
        <GovernanceProtocolsShowSidebar protocol={protocol} />
        <Spacer />
      </Col>
      <Col sm="16">
        {publishedProposals.length > 0 && (
          <div className="s-marginBottom2">
            <h3 className="s-fontSize14 u-textTransformUppercase u-letterSpacing1 u-colorGreen u-fontWeight700 s-marginBottom1">
              Published
            </h3>
            {renderProposalsList(publishedProposals)}
          </div>
        )}
        {closedProposals.length > 0 && (
          <div className="s-marginBottom2">
            <h3 className="s-fontSize14 u-textTransformUppercase u-letterSpacing1 u-colorGray8 u-fontWeight700 s-marginBottom1">
              Closed
            </h3>
            {renderProposalsList(closedProposals)}
          </div>
        )}
      </Col>
    </Grid>
  );
};

export default GovernanceProtocolsShow;
