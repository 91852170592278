import * as React from "react";

import LinksContainer from "./LinksContainer";

import { createLinkObjects, isExtraSmallOrSmaller } from "../../../../utils";

export default function WebsiteLinks(props) {
  const extraSmall = isExtraSmallOrSmaller();
  return extraSmall ? (
    <div></div>
  ) : (
    <div>
      <LinksContainer
        fontSize={props.loggedInUser ? 16 : 22}
        links={createLinkObjects(props.tokenProfile)}
        page={props.tokenProfile.symbol.toUpperCase() + " Protocol Page"}
        project_name={props.tokenProfile.name}
        user_id={props.user_id}
        session_id={props.session_id}
      />
    </div>
  );
}
