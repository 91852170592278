import * as React from "react";

import { KycSelectorEntityType } from '@coinlist/ui-lib';
import UiLibWrapper from '../../utils/UiLibWrapper';

export default function TestKyc() {  
  return (
    <UiLibWrapper locale='en'>
      <KycSelectorEntityType
        newEntityData={{}}
        onSubmit={() => {return false}}
        isCreatePersonEntityLoading={false}
      />
    </UiLibWrapper>
  );
}
