Partials = require('./lib/partials')

window.FrontIntegration = {

  plugin: ($$, $this) ->
    class PluginView extends Backbone.View
      WIDTH: 400

      initialize: ->
        Front.on 'panel_visible', =>
          @setWidth()

        Front.on 'conversation', (data) =>
          @setWidth()
          Partials.with $(Partials.selector('front_integration/plugin_content')), (partial) =>
            partial.loadContent(data.contact.id, data.contact.handle)

      setWidth: ->
        Front.setPanelWidth(@WIDTH)
    new PluginView el: $this

  plugin_content: ($$, $this) ->
    class PluginContentView extends Backbone.View

      getAuthSecret: ->
        @$('.js-data').data('authSecret')

      loadContent: (frontContactId, emailAddress) ->
        data = {
          front_contact_id: frontContactId,
          email: emailAddress,
          auth_secret: @getAuthSecret()
        }
        $.ajax
          url: window.location.pathname,
          data: data,
          dataType: 'JSON',
          success: (response) =>
            @$el.replaceWith response.html

    new PluginContentView el: $this
}