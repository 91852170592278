import accounting from "accounting";

interface RailsMoney {
  fractional: string;
  currency: {
    subunit_to_unit: number;
    symbol: string;
  };
}

// Useful to format a rails money object
export const formatCryptoMoney = (railsMoneyObject: RailsMoney) => {
  const amountFractional =
    Number(railsMoneyObject.fractional) /
    railsMoneyObject.currency.subunit_to_unit;

  return accounting.formatMoney(amountFractional, {
    symbol: railsMoneyObject.currency.symbol,
    format: "%v %s",
  });
};

export const getAmount = (railsMoneyObject: RailsMoney) => {
  const amountFractional =
    Number(railsMoneyObject.fractional) /
    railsMoneyObject.currency.subunit_to_unit;

  return amountFractional;
};
