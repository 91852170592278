require('jquery-form')

window.Accreditation.ThirdPartyVerifiers = {
  show: ($$, $this) ->
    class VerifyView extends Backbone.View
      events:
        'change .js-name_input': (event) ->
          name = $$(event.currentTarget).val()
          $$('.js-verifier_name').html(name)

        'click .js-decline_signature': (event) ->
          event.preventDefault()
          if confirm("Are you sure you wish to decline to sign this statement?")
            $.ajax
              url: $$(event.currentTarget).attr('href')
              method: 'PUT'
              data: {decline: true}
              dataType: 'json'
              success: (response) =>
                window.location = response.post_decline_url

    new VerifyView el: $this
}
