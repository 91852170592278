import { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Divider,
  InlineLabel,
  Link,
  Spacer,
  TextInput,
} from "@react/components";
import BlockField from "@react/views/shared/forms/BlockField/BlockField";
import Preview, { PreviewProps } from "./components/Preview";
import {
  axiosRequest,
  getRailsHeaders,
  RequestType,
} from "@react/utils/network";
import axios from "axios";
import Col from "@react/components/core/grid/Col/Col";
import Grid from "@react/components/core/grid/Grid/Grid";
import GenerateTable from "@react/views/admin/queue_tokens/components/GenerateTable";
import * as React from "react";
import { GenerateTableRowData } from "@react/views/admin/queue_tokens/components/GenerateTableRow";

interface QueueTokenGeneratorProps {
  existingJobs: GenerateTableRowData[];
}

export default function QueueTokenGenerator(props: QueueTokenGeneratorProps) {
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [previewProps, setPreviewProps] = useState<PreviewProps>(null);
  const [previewLoading, setPreviewLoading] = useState<boolean>(false);
  const [generateLoading, setGenerateLoading] = useState<boolean>(false);
  const [existingJobs, setExistingJobs] = useState<GenerateTableRowData[]>(
    props.existingJobs
  );
  const [showPriorityQueueInput, setShowPriorityQueueInput] = useState<boolean>(
    false
  );
  const [eventIdError, setEventIdError] = useState<string>("");

  const handlePreview = async (event: Event) => {
    event.preventDefault();
    const url = `./queue_tokens/preview`;

    const formData = new FormData(
      document.getElementById("form") as HTMLFormElement
    );

    setPreviewLoading(true);
    try {
      const { data } = await axios.post(url, formData, {
        headers: getRailsHeaders(),
      });
      setPreviewProps(data as PreviewProps);
      setShowPreview(true);
      setPreviewLoading(false);
    } catch (error) {
      setPreviewLoading(false);
    }
  };

  const handleGenerate = async (event: Event) => {
    setGenerateLoading(true);

    const url = "./queue_tokens/generate";
    const formData = new FormData(
      document.getElementById("form") as HTMLFormElement
    );

    try {
      const { data } = await axios.post(url, formData, {
        headers: getRailsHeaders(),
      });
      setGenerateLoading(false);
      setExistingJobs([data, ...existingJobs]);
    } catch (error) {
      setGenerateLoading(false);
      if ("event_id" in error.response.data.error)
        setEventIdError(error.response.data.error.event_id);
    }
  };

  const removeFileFromInput = () => {
    const inputField = document.getElementById(
      "csv_email_list"
    ) as HTMLInputElement;
    inputField.value = null;
  };

  const updateJobs = async () => {
    const jobsToUpdate = existingJobs
      .filter((item) => item.status !== "done")
      .map((job) => job.token_generator_id);

    try {
      const response: any = await axiosRequest(
        `./queue_tokens/status`,
        { jobs_to_update: jobsToUpdate },
        RequestType.GET
      );
      let responseMap = Object.assign(
        {},
        ...response.data.map((item) => ({ [item.id]: item }))
      );
      setExistingJobs((oldState) => {
        return oldState.slice().map((job, index) => {
          if (Boolean(responseMap[job.token_generator_id])) {
            job.status = responseMap[job.token_generator_id].status;
            job.tokens_generated =
              responseMap[job.token_generator_id].token_count;
          }
          return job;
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const tick = setInterval(() => {
      updateJobs();
    }, 6000);
    return () => {
      clearInterval(tick);
    };
  }, [existingJobs]);

  return (
    <>
      <Grid>
        <Col xs={"12"}>
          <form id={"form"} name={"form"}>
            <BlockField label="Event ID" error={eventIdError}>
              <TextInput name="event_id" />
            </BlockField>
            <Spacer />
            <InlineLabel>
              <Checkbox
                name="priority_queue"
                value={showPriorityQueueInput.toString()}
                onChange={(event) => {
                  setShowPriorityQueueInput(event.target.checked);
                }}
              />
              Priority queue
            </InlineLabel>
            <Spacer />
            {showPriorityQueueInput && (
              <>
                <BlockField label="Additional User Emails / Wallets">
                  <Col xs={"20"}>
                    <input
                      type="file"
                      name="csv_email_list"
                      id="csv_email_list"
                      accept={"text/csv"}
                      className="c-input--file-input c-input c-input--optional"
                    />
                  </Col>
                  <Col xs={"4"}>
                    <Link fontWeight={700} onClick={removeFileFromInput}>
                      <i className={"icon-cancel"} />
                    </Link>
                  </Col>
                </BlockField>
                <Spacer />
                <BlockField label="Total Karma">
                  <TextInput
                    name="total_karma"
                    type="number"
                    defaultValue={"0"}
                  />
                </BlockField>
                <Spacer />
                <BlockField label="Staking karma">
                  <TextInput
                    name="staking_karma"
                    type="number"
                    defaultValue={"0"}
                  />
                </BlockField>
                <Spacer />
                <BlockField label="Trading karma">
                  <TextInput
                    name="trading_karma"
                    type="number"
                    defaultValue={"0"}
                  />
                </BlockField>
                <Spacer spacing={2} />
                <Button
                  onClick={handlePreview}
                  type={"submit"}
                  loading={previewLoading}
                  disabled={previewLoading}
                >
                  Preview
                </Button>
              </>
            )}
          </form>
          <Spacer spacing={2} />
          <Button
            onClick={handleGenerate}
            variant="warning"
            loading={generateLoading}
            disabled={generateLoading}
          >
            Generate
          </Button>
        </Col>
        <Col xs={"12"} pushXs={"3"}>
          <Divider spacingTop={2} spacingBottom={3} />
          {showPreview && Preview({ ...previewProps })}
        </Col>
      </Grid>
      <Divider spacingTop={1} spacingBottom={2} />
      {existingJobs.length > 0 && (
        <GenerateTable jobs={existingJobs} generateLoading={generateLoading} />
      )}
    </>
  );
}
