import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import makeStyles from '@mui/styles/makeStyles';
import { COLOR_MAP } from "@react/utils/color";

interface SwitchProps {
  checked: boolean;
  disabled?: boolean;
  id?: string;
  key?: string;
  labelText: string;
  onChange: (e) => void;
}

const useStyles = makeStyles((theme: any) => ({
  checked: {},
  formControlRoot: {
    marginLeft: "-6px",
    width: "auto",
  },
  formControlSelect: {
    margin: "4px",
    minWidth: 120,
  },
  label: {
    color: "#9FA0A5",
    fontFamily: "inherit",
    fontSize: 14,
    fontWeight: 700,
    textTransform: "none",
  },
  switchBase: {
    color: "#bdbdbd",
    /* https://color.adobe.com/create/color-wheel */
    "&$checked": {
      color: COLOR_MAP.primary,
    },
    "&$checked + $track": {
      backgroundColor: "#E02F88",
    },
  },
  track: {},
}));

export default function CustomSwitch(props: SwitchProps) {
  const classes = useStyles();
  return (
    <FormControlLabel
      classes={{
        label: classes.label,
        root: classes.formControlRoot,
      }}
      control={
        <Switch
          checked={props.checked}
          classes={{
            checked: classes.checked,
            switchBase: classes.switchBase,
            track: classes.track,
          }}
          disabled={props.disabled}
          id={props.id}
          key={props.key}
          onChange={props.onChange}
          size="small"
        />
      }
      label={props.labelText}
    />
  );
}
