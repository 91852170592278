import * as React from "react";
import { DashboardSection, FeaturedList, Button, AlertList, FeaturedEmpty, Distribution } from "@coinlist/frontend-ui-lib"
require("@coinlist/frontend-ui-lib/dist/style.css");

export default function Dashboard(props) {
    return (
        <div className="flex flex-col gap-6 flex-1">
            {props.showAdminButton && <Button isFullWidth={false} isDark style={{ alignSelf: "flex-start" }} onClick={() => window.location.href = "/admin/dashboard_notifications"}>Add offer notification</Button>}
            <AlertList limit={1} locale={props.locale} items={props.alertNotifications.items} />
            <FeaturedList limit={1} locale={props.locale} items={props.tokenLaunchNotifications.items} />
            <Distribution entityId={props.entityId} locale={props.locale} items={props.distributions.items} title={props.distributions.title} />
            <DashboardSection locale={props.locale} title={props.nowOfferingNotifications.title} subtitle={props.nowOfferingNotifications.subtitle} items={props.nowOfferingNotifications.items} />
            <DashboardSection limit={5} locale={props.locale} title={props.testnetRewardsNotifications.title} subtitle={props.testnetRewardsNotifications.subtitle} items={props.testnetRewardsNotifications.items} />
            <DashboardSection locale={props.locale} title={props.activeNotifications.title} subtitle={props.activeNotifications.subtitle} items={props.activeNotifications.items} />
            <DashboardSection isCard viewMoreOnClick={() => {
                window.location.href = "/wallets"
            }} locale={props.locale} title={props.nowListingNotifications.title} subtitle={props.nowListingNotifications.subtitle} items={props.nowListingNotifications.items} />
            <DashboardSection viewMoreOnClick={() => {
                window.location.href = "/wallets"
            }} isCard locale={props.locale} title={props.buyCryptoNotifications.title} subtitle={props.buyCryptoNotifications.subtitle} items={props.buyCryptoNotifications.items} />
            {props.tokenLaunchNotifications.items.length === 0 && (!props.isClm || props.isAdmin) && <FeaturedEmpty locale={props.locale} />}
        </div>
    );
}
