import * as Castle from '@castleio/castle-js'

window.Devise.Passwords = {

  new: ($$, $this) ->
    class NewFormView extends Backbone.View
      initialize: ->
        @toggleButtonState()

      toggleButtonState: ->
        @$('.js-form_submit').prop('disabled', !@validForm())

      getFormattedEmail: ->
        $.trim(@$('.js-form_email_input').val())

      validEmail: ->
        pattern = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
        pattern.test(@getFormattedEmail())

      validForm: ->
        @validEmail()

      events:
        'blur .js-form_email_input': (event) ->
          if @validEmail() || $.trim(@$('.js-form_email_input').val()) == ""
            @$('.js-form_email_input_error').hide()
          else
            @$('.js-form_email_input_error').show()
        'input .js-form_email_input': (event) ->
          @toggleButtonState()
        'click .c-button--block': (event) ->
          if castleData?.castlePublishKey
            Castle.createRequestToken().then (token) ->
              $('#castle_request_token').val(token)

    new NewFormView el: $this

  edit: ($$, $this) ->
    class EditFormView extends Backbone.View

      events:
        'click .c-button.c-button--block.c-button--large': (event) ->
          if castleData?.castlePublishKey
            Castle.createRequestToken().then (token) ->
              $('#castle_request_token').val(token)

    new EditFormView el: $this


}