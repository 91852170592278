window.Admin.Users.Show.Overview =
  blacklist_form: ($$, $this) =>
    class BlacklistFormView extends Backbone.View
      events:
        'submit form': (event) ->
          event.preventDefault()
          $form = $(event.target)
          $button = @$('.js-submit')
          return if $button.hasClass 'c-button--loading'
          $button.addClass 'c-button--loading'
          $form.ajaxSubmit
            success: (response) =>
              window.location.reload()
            error: (response) =>
              if response.status == 422
                @$el.replaceWith response.responseJSON.html
              else
                Tooltips.formServerError($button)

    new BlacklistFormView el: $this
