import * as React from "react";
import { useState } from "react";
import { Alert, AlertVariants, AlertSizes } from "@react/components";

interface AlertSectionProps {
  // props
}

const AlertSection: React.FunctionComponent<AlertSectionProps> = (
  {
    // props
  }
) => {
  return (
    <div>
      <div className="s-marginBottom2">
        <h2 className="s-fontSize26 s-borderBottom0_5 s-marginBottom1 u-colorGray2">
          Alert
        </h2>
        <p className="s-marginBottom0_5 u-colorGray6">
          Implementation of our alert component
        </p>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">Default</h3>
        <pre>
          <Alert>Default Alert</Alert>
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          Not rounded
        </h3>
        <pre>
          <Alert rounded={false}>Not rounded</Alert>
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">Sizes</h3>
        {AlertSizes.map((size, index) => (
          <div className="s-marginBottom1" key={`alert-size-${index}`}>
            <pre>
              <Alert size={size}>{size} Alert</Alert>
            </pre>
          </div>
        ))}
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">Variants</h3>
        {AlertVariants.map((variant, index) => (
          <div className="s-marginBottom1" key={`alert-variant-${index}`}>
            <pre>
              <Alert variant={variant}>{variant} Alert</Alert>
            </pre>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AlertSection;
