import * as React from "react";
import { useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import Snackbar from "@mui/material/Snackbar";

import { Button, Divider } from "@react/components";
import Dialog from "@mui/material/Dialog";
import Flex from "../Flex";
import FormattedCells from "../FormattedCells";
import InfoWarning from "../warnings/InfoWarning";
import { TextInput } from "@react/components";
import BlockField from "@react/views/shared/forms/BlockField/BlockField";
import InputWithAddon from "@react/views/shared/forms/InputWithAddon/InputWithAddon";
import LargeDisplayAmount from "../LargeDisplayAmount";
import { Link } from "@react/components";
import Loading, { RequestData } from "../Loading";
import Typography from "../typography/Typography";
import Warning from "../warnings/Warning";

import { RequestType } from "../../utils/network";
import {
  appendQueryStringToCurrentUrl,
  caseInsensitiveStringComparison,
  isExtraSmallOrSmaller,
} from "../../utils";
import { getAllCells } from "../../views/market/staking/shared/stakingCells";
import { getFormattedTime } from "../../utils/date";
import { SegmentReact } from "@react/analytics/segment_react";

interface StakeDialogProps {
  amountAvailable: number;
  amountAvailableFormattedStr: string;
  amountAvailableErc20: number;
  amountAvailableFormattedStrErc20: string;
  assetSymbol: string;
  depositUrl: string;
  purchaseUrl: string;
  interestRate: number;
  lockUpPeriod: number;
  minDeposit: number;
  onClose: () => void;
  open: boolean;
  priceInUsd: number;
  startDate: string;
  stxToUsdPrice: number;
  url: string;
  urlErc20: string;
  userId: string;
  sessionId: string;
  originPage: string;
}

const CONTAINER_PADDING = 20;
const BTC_SYMBOL = "BTC";
export const STX_SYMBOL = "STX";
const AXL_SYMBOL = "AXL";

const successfulRequest = (response) => {
  return (
    Boolean(response) &&
    Boolean(response.data) &&
    response.status === 200 &&
    typeof response.data === "object"
  );
};

const responseMessage = (response, assetSymbol) => {
  if (successfulRequest(response)) {
    return `Successfully staked ${assetSymbol.toUpperCase()}`;
  }
  if (response && response.data.errors) {
    return response.data.errors.message;
  }
  return "Error staking, please try again.";
};

const calculateStacksRewards = (
  interestRate,
  stxToUsdPrice,
  amountStaked
): number => {
  return parseFloat((interestRate * amountStaked * stxToUsdPrice).toFixed(2));
};

const useStyles = makeStyles(() => ({
  dialogPaper: {
    minHeight: 484,
    maxWidth: 615,
    width: "100%",
  },
  dialogPaperXs: {
    minHeight: 510,
    maxWidth: 600,
    width: "100%",
  },
}));

export default function StakeDialog(props: StakeDialogProps) {
  const classes = useStyles();
  const extraSmall = isExtraSmallOrSmaller();

  const [amountStaked, setAmountStaked] = useState<string>("");
  const [amountStakedErc20, setAmountStakedErc20] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [insufficientBalanceError, setInsufficientBalanceError] = useState(
    false
  );
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [requestData, setRequestData] = useState<RequestData>(null);
  const [response, setResponse] = useState(null);

  const allCells = getAllCells(
    props.interestRate,
    props.lockUpPeriod,
    props.minDeposit,
    props.assetSymbol,
    props.startDate
  );

  const walletCells = extraSmall ? [allCells[1], allCells[3]] : [...allCells];

  const yearlyRewards: number =
    props.assetSymbol === STX_SYMBOL
      ? calculateStacksRewards(
          props.interestRate,
          props.stxToUsdPrice,
          Number(amountStaked)
        )
      : props.assetSymbol === AXL_SYMBOL
      ? parseFloat(
          (
            props.interestRate *
            (Number(amountStaked) + Number(amountStakedErc20))
          ).toFixed(2)
        )
      : parseFloat((props.interestRate * Number(amountStaked)).toFixed(2));

  const rewardsInUsd = isNaN(parseFloat(amountStaked))
    ? 0
    : (props.priceInUsd * yearlyRewards).toFixed(2);

  const paperStyle = extraSmall ? classes.dialogPaperXs : classes.dialogPaper;

  const hasErrors = () => {
    let newError = "";
    if (
      (!amountStaked || isNaN(parseFloat(amountStaked))) &&
      (!amountStakedErc20 || isNaN(parseFloat(amountStakedErc20)))
    ) {
      newError = `Please enter a valid amount to ${
        caseInsensitiveStringComparison(props.assetSymbol, STX_SYMBOL)
          ? "stack"
          : "stake"
      }`;
    } else if (
      (amountStaked && parseFloat(amountStaked) < props.minDeposit) ||
      (amountStakedErc20 && parseFloat(amountStakedErc20) < props.minDeposit)
    ) {
      newError = `Please ${
        caseInsensitiveStringComparison(props.assetSymbol, STX_SYMBOL)
          ? "stack"
          : "stake"
      } more than the minimum deposit amount of ${props.minDeposit} ${
        props.assetSymbol
      }`;
    }
    setError(newError);
    return Boolean(newError) || insufficientBalanceError;
  };

  const renderResponseContainer = () => {
    return (
      <Flex container spacing={2} style={{ marginTop: 16 }}>
        <Flex item justifyContent="center" xs={24}>
          <i className={`icon-ok u-colorGreen`} style={{ fontSize: 64 }}></i>
        </Flex>
        <Flex container spacing={1}>
          <Flex item justifyContent="center" xs={24}>
            <Typography center bold fontSize={20}>
              {responseMessage(response, props.assetSymbol)}
            </Typography>
          </Flex>
          <Flex item justifyContent="center" xs={24}>
            <Typography center fontSize={16}>
              You'll start earning rewards on{" "}
              {getFormattedTime(props.startDate)}.
            </Typography>
          </Flex>
        </Flex>
        <Flex container style={{ marginTop: 16 }}>
          <Button
            onClick={() => {
              const queryString = `?symbol=${props.assetSymbol}`;
              window.location.href = appendQueryStringToCurrentUrl(queryString);
            }}
            style={{
              bottom: CONTAINER_PADDING,
              position: "absolute",
              right: CONTAINER_PADDING,
            }}
          >
            Exit
          </Button>
        </Flex>
      </Flex>
    );
  };

  const labelContent = (
    <>
      Amount&nbsp;&middot;&nbsp;
      <span className="s-fontSize12">
        {caseInsensitiveStringComparison(props.assetSymbol, AXL_SYMBOL) ? (
          <Link
            onClick={() =>
              setAmountStaked(
                props.amountAvailable.toString().replace(/,/g, "")
              )
            }
          >
            Max AXL
          </Link>
        ) : (
          <Link
            onClick={() =>
              setAmountStaked(
                props.amountAvailable.toString().replace(/,/g, "")
              )
            }
          >
            Max
          </Link>
        )}
      </span>
    </>
  );

  const amountStakedInputOnChange = (e: any, type?: any) => {
    const newAmountStaked = isNaN(
      parseFloat(e.target.value.replaceAll(",", ""))
    )
      ? ""
      : e.target.value.replaceAll(",", "");

    if (type === "erc20") {
      setInsufficientBalanceError(
        parseFloat(newAmountStaked) > props.amountAvailableErc20
      );
      setAmountStakedErc20(newAmountStaked);
    } else {
      setInsufficientBalanceError(
        parseFloat(newAmountStaked) > props.amountAvailable
      );
      setAmountStaked(newAmountStaked);
    }
  };

  const renderInformationalContainer = () => {
    return (
      <Flex container spacing={3}>
        <Flex container spacing={3}>
          <Flex item sm={9} xs={24}>
            <BlockField
              label={labelContent}
              error={error || insufficientBalanceError}
              hint={`You have ${props.amountAvailableFormattedStr} available for staking`}
            >
              {props.assetSymbol === AXL_SYMBOL ? (
                <>
                  <InputWithAddon addon={`${props.assetSymbol}`}>
                    <TextInput
                      onChange={amountStakedInputOnChange}
                      placeholder="1.0123"
                      value={amountStaked.toString()}
                      disabled={amountStakedErc20 !== ""}
                    />
                  </InputWithAddon>
                  <div className="s-marginTop1"></div>
                  <InputWithAddon addon={`W${props.assetSymbol}`}>
                    <TextInput
                      onChange={(e) => amountStakedInputOnChange(e, "erc20")}
                      placeholder="1.0123"
                      value={amountStakedErc20.toString()}
                      disabled={amountStaked !== ""}
                    />
                  </InputWithAddon>
                </>
              ) : (
                <InputWithAddon addon={props.assetSymbol}>
                  <TextInput
                    onChange={amountStakedInputOnChange}
                    placeholder="1.0123"
                    value={amountStaked.toString()}
                  />
                </InputWithAddon>
              )}
            </BlockField>
          </Flex>
          <Flex
            item
            sm={props.interestRate && props.interestRate > 0 ? 15 : 0}
            style={{ overflow: "hidden" }}
            xs={0}
          >
            <LargeDisplayAmount
              bottomElement={
                props.priceInUsd ? (
                  <p
                    className={
                      Boolean(error) || insufficientBalanceError
                        ? "u-colorRed"
                        : "u-ColorBlack"
                    }
                  >
                    <i>&asymp; ${rewardsInUsd}</i>
                  </p>
                ) : (
                  <div></div>
                )
              }
              error={Boolean(error) || insufficientBalanceError}
              mainString={`${yearlyRewards} ${
                props.assetSymbol === STX_SYMBOL
                  ? BTC_SYMBOL
                  : props.assetSymbol
              }`}
              topLabelText={"Est. Yearly Rewards"}
            />
          </Flex>
        </Flex>
        <Flex item xs={24}>
          {caseInsensitiveStringComparison(props.assetSymbol, AXL_SYMBOL) ? (
            <InfoWarning>
              Assets can be staked at any time and begin to earn rewards on the
              start date below. As staking is only supported for the native AXL
              token, any of your wrapped Axelar tokens will be converted to
              native AXL tokens. Your principal and rewards will be distributed
              in the native token. To convert back to the wrapped AXL token, you
              will need to go through our Conversions workflow.
            </InfoWarning>
          ) : (
            <InfoWarning>
              Assets can be staked at any time and begin to earn rewards on the
              start date below.
            </InfoWarning>
          )}
        </Flex>
        <Flex item xs={24}>
          <FormattedCells
            cells={walletCells}
            cellPadding={extraSmall ? 8 : null}
            parentSpacing={2}
          />
        </Flex>
        <Flex
          container
          justifyContent="flex-end"
          style={{
            bottom: CONTAINER_PADDING,
            right: CONTAINER_PADDING,
          }}
        >
          <Button
            onClick={() => {
              if (!hasErrors() && amountStaked !== "") {
                setRequestData({
                  data: {
                    vault_deposit: {
                      amount: amountStaked,
                    },
                  },
                  type: RequestType.POST,
                  url: props.url,
                });
                setLoading(true);
              } else if (!hasErrors() && amountStakedErc20 !== "") {
                setRequestData({
                  data: {
                    vault_deposit: {
                      amount: amountStakedErc20,
                    },
                  },
                  type: RequestType.POST,
                  url: props.urlErc20,
                });
                setLoading(true);
              }
            }}
            style={{
              height: 46,
              width: 95,
            }}
          >
            <Loading
              handleResponse={(response) => {
                if (!successfulRequest(response)) {
                  setOpen(true);
                } else {
                  SegmentReact.track(
                    props.assetSymbol + " " + props.originPage + " Page",
                    {
                      page_event: props.assetSymbol + " Stake successful",
                      user_id: props.userId,
                      session_id: props.sessionId,
                      viewed_at: new Date(),
                    }
                  );
                }
                setResponse(response);
                setLoading(false);
              }}
              loading={loading}
              requestData={requestData}
              spinnerColor="white"
            />
            {!loading &&
            caseInsensitiveStringComparison(props.assetSymbol, STX_SYMBOL)
              ? "Stack"
              : "Stake"}
          </Button>
        </Flex>
      </Flex>
    );
  };

  return (
    <Dialog
      classes={{
        paper: paperStyle,
      }}
      fullWidth
      onClose={props.onClose}
      open={props.open}
    >
      <Flex container style={{ padding: CONTAINER_PADDING }} spacing={1}>
        <Flex item xs={24}>
          <Typography type="h6">
            {caseInsensitiveStringComparison(props.assetSymbol, STX_SYMBOL)
              ? "Stack"
              : "Stake"}
          </Typography>
        </Flex>
        <Flex item xs={24}>
          <Divider spacingTop={0} spacingBottom={0} />
        </Flex>
        {response && successfulRequest(response)
          ? renderResponseContainer()
          : renderInformationalContainer()}
      </Flex>
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        autoHideDuration={3000}
        open={open}
        onClose={() => setOpen(false)}
      >
        <Warning
          color={"orange"}
          fullWidth
          fontSize={13}
          padding={8}
          variant={"warning"}
          warningText={responseMessage(response, props.assetSymbol)}
        />
      </Snackbar>
    </Dialog>
  );
}
