Chart = require 'chart.js'

window.Offerings.Manage.Overview =
  top_metrics: ($$, $this) ->
    class TopMetricsView extends Backbone.View
      initialize: ->
        $chart = @$('.js-chart')
        chart_data = $chart.data('chart-data')
        return unless chart_data

        new Chart $chart,
            type: 'line'
            data: chart_data
            options:
              layout:
                padding:
                  top: 10
              legend:
                display: chart_data.datasets.length > 1
                position: 'bottom'
                labels:
                  padding: 24
              scales:
                xAxes: [{
                  gridLines:
                    display: false
                  ticks:
                    maxTicksLimit: 8
                }],
                yAxes: [{
                  display: false
                  ticks:
                    beginAtZero: true
                }]
              tooltips:
                backgroundColor: 'rgba(255,255,255,0.93)'
                titleFontSize: 14
                titleFontColor: '#666'
                titleSpacing: 10
                titleMarginBottom: 14
                bodyFontSize: 14
                bodyFontColor: '#666'
                xPadding: 14
                yPadding: 14
                cornerRadius: 3
                displayColors: false
                borderColor: '#ddd'
                borderWidth: 1
                caretSize: 8
                callbacks:
                  label: (tooltipItem, data) =>
                    @_numberToCurrency(tooltipItem.yLabel) + ' ' +
                      (data.datasets[tooltipItem.datasetIndex].label || '')

      _numberToCurrency: (number) ->
        parts = number.toString().split(".")
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        '$' + parts.join(".")

    new TopMetricsView el: $this
