Plyr = require('plyr')
moment = require('moment')
require('moment-timezone')

window.Index.KadenaIndex =
  header: ($$, $this) =>
    class HeaderView extends Backbone.View
      initialize: ->
        @player = new Plyr('.js-video', PlyrPresets.default())

        time = moment(@$('.js-countdown').data('time'))
        timeWithZone = moment.tz(time, moment.tz.guess())

        @$('.js-countdown').countdown timeWithZone.format('M/D/YYYY, H:mm:ss'), (event) =>
          @$('.js-countdown').html(event.strftime('%-D day%!d %-H hr%!H %-M min %-S sec'))
          if event.offset.totalSeconds <= 0
            window.location.reload()

    new HeaderView el: $this
