import * as React from "react";

import Flex from "@react/components/Flex";
import Typography from "@react/components/typography/Typography";

export default function Disclaimer(props) {
  return (
    <Flex container>
      <Typography>
        <i>
          Disclaimer: Any reward APYs displayed are for informational purposes
          only, are based on estimates and should not be relied upon, are not
          guaranteed, and do not take into account CoinList fees. CoinList does
          not provide tax advice and the information presented is not intended
          to be relied upon as advice concerning the appropriate treatment or
          possible tax consequences of any transaction. Please consult with your
          tax advisor. This article contains references to information obtained
          from third-party content providers (content hosted on sites
          unaffiliated with CoinList). As such, CoinList makes no
          representations whatsoever regarding any information obtained from
          third parties that may be referenced directly or indirectly in this
          article.
        </i>
      </Typography>
    </Flex>
  );
}
