import * as React from "react";
import { useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import Dialog from "@mui/material/Dialog";
import { Button, Divider } from "@react/components";
import Confirmation from "@react/components/Confirmation";
import { RequestData } from "@react/components/Loading";
import LargeDisplayAmount from "@react/components/LargeDisplayAmount";
import { TextInput } from "@react/components";
import BlockField from "@react/views/shared/forms/BlockField/BlockField";
import InputWithAddon from "@react/views/shared/forms/InputWithAddon/InputWithAddon";
import Typography from "@react/components/typography/Typography";
import Flex from "@react/components/Flex";
import FormattedCells from "@react/components/FormattedCells";
import InfoWarning from "@react/components/warnings/InfoWarning";
import { Link } from "@react/components";

import { axiosRequest, RequestType } from "@react/utils/network";
import { roundFloat, dollarValue } from "@react/utils";
import { Alert } from "@mui/material";

/**
 * TODO ARJUN - use a fn instead of
 * collateral buffer. It should:
 * (1) take in the new value
 * (2) use a while loop to increment
 *  the last digit place until it's
 *  greater than collateral req.
 */
const COLLATERAL_BUFFER = 0.001;
const MAX_DECIMALS = 8;

export interface CollateralFormDialogProps {
  collateralAssets?: Array<any>;
  collateralAssetSymbols: Array<string>;
  collateralAssetsUrlMap: any;
  collateralBalanceMap: any;
  collateralRequirement: number;
  // collateralRequired: boolean;
  handleClose: () => void;
  loanAmountSubunit: number;
  loanAssetSymbol: string;
  marginCallLevel: number;
  open: boolean;
  overCollaterallizedLevel: number;
  placedLoanId: number;
  postCollateralUrl: string;
  priceMap: any;
  startDate: string;
}

const useStyles = makeStyles(() => ({
  dialogPaper: {
    minHeight: 615,
    maxWidth: 800,
    width: "100%",
  },
}));

/* https://stackoverflow.com/a/46322213/3893556 */
const limitDecimalPlaces = (e) => {
  var t = e.currentTarget.value;
  e.currentTarget.value =
    t.indexOf(".") >= 0
      ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), MAX_DECIMALS + 1)
      : t;
};

const getHelperText = ({ collateralEntered, collateralBalance, symbol }) => {
  return collateralEntered > collateralBalance
    ? `You can't post that much ${symbol} since you only have ${roundFloat(
        collateralBalance,
        MAX_DECIMALS
      )} ${symbol} in your wallet.`
    : `You have ${roundFloat(
        collateralBalance,
        MAX_DECIMALS
      )} ${symbol} that can be used as collateral.`;
};

const createInitialCollateralValueObj = (
  collateralAssets,
  collateralAssetSymbols
) => {
  const collateralValue = {};
  if (collateralAssets && collateralAssets.length > 0) {
    collateralAssets.forEach(
      (asset) =>
        (collateralValue[asset.symbol] = parseFloat(asset.amount_subunit))
    );
  } else {
    collateralAssetSymbols.forEach((symbol) => (collateralValue[symbol] = 0));
  }
  return collateralValue;
};

const calculateCollateralPercentage = (
  collateralValue,
  loanDollarValue,
  priceMap
) => {
  let postedCollateral = 0;
  Object.keys(collateralValue).forEach((symbol) => {
    postedCollateral += priceMap[symbol] * collateralValue[symbol];
  });
  return postedCollateral / loanDollarValue;
};

export default function CollateralFormDialog(props: CollateralFormDialogProps) {


  const classes = useStyles();

  const loanDollarValue = dollarValue(
    props.loanAmountSubunit,
    props.priceMap[props.loanAssetSymbol]
  );

  const [collateralValue, setCollateralValue] = useState(() =>
    createInitialCollateralValueObj(
      props.collateralAssets,
      props.collateralAssetSymbols
    )
  );
  const [initialCollateralValue, setInitialCollateralValue] = useState(
    collateralValue
  );
  const [loading, setLoading] = useState(false);
  const [postResponse, setPostResponse] = useState(null);
  const [requestData, setRequestData] = useState<RequestData>(null);
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  if(snackBarOpen){
    console.log("Error when posting collateral", postResponse)
  }

  const priceMapError = Object.keys(props.priceMap)
    .filter((symbol) => props.collateralAssetSymbols.includes(symbol))
    .some((symbol) => props.priceMap[symbol] === 0);

  let postedCollateral = calculateCollateralPercentage(
    collateralValue,
    loanDollarValue,
    props.priceMap
  );

  const walletCells = [
    {
      labelText: "Principal",
      value: `${parseFloat(props.loanAmountSubunit.toFixed(4))} ${
        props.loanAssetSymbol
      }`,
      tooltipTitle: "Amount you've entered as % of loan",
    },
    {
      labelText: "Collateral Required",
      value: `${parseFloat((props.collateralRequirement * 100).toFixed(4))}%`,
      tooltipTitle: "We need this much collateral to start your loan",
    },
    {
      labelText: "Margin Call",
      value: `${props.marginCallLevel * 100}%`,
      tooltipTitle: "We'll request more collateral below this level",
    },
    {
      labelText: "Overcollateralized",
      value: `${roundFloat(props.overCollaterallizedLevel * 100, 2)}%`,
      tooltipTitle: "We'll unlock collateral above this level",
    },
  ];

  const checkErrors = (): string => {
    for (const symbol of Object.keys(collateralValue)) {
      const valueEntered = collateralValue[symbol];
      const collateralBalanceValue =
        parseFloat(props.collateralBalanceMap[symbol]) +
        initialCollateralValue[symbol];
      if (!valueEntered) {
        continue;
      }
      if (valueEntered && isNaN(valueEntered as any)) {
        return `You must enter a numeric value for ${symbol}.`;
      }
      if (parseFloat(valueEntered) <= 0) {
        return `You must enter a positive value for ${symbol}.`;
      }
      if (parseFloat(valueEntered) > collateralBalanceValue) {
        return `You've entered ${valueEntered} ${symbol}, but you only have ${collateralBalanceValue} ${symbol} in your wallet.`;
      }
    }

    /* Every check from this point on depends on price map being valid */
    if (priceMapError) {
      return "";
    }

    if (postedCollateral < props.collateralRequirement) {
      return `Please enter at least ${
        props.collateralRequirement * 100
      }% of your loan's value as collateral.`;
    }

    /**
     * Handle the case where if they're NOT overcollateralized
     * then they cannot withdraw funds
     */
    const initialCollateralPercentage = calculateCollateralPercentage(
      initialCollateralValue,
      loanDollarValue,
      props.priceMap
    );
    if (
      props.collateralAssets &&
      props.collateralAssets.some(
        (asset) => parseFloat(asset.amount_subunit) > 0
      ) &&
      /* They should always be able to add collateral, but conditionally withdraw */
      postedCollateral < initialCollateralPercentage &&
      initialCollateralPercentage < props.overCollaterallizedLevel
    ) {
      return `Since you previously entered collateral, you can only withdraw collateral if you're overcollateralized (${
        props.overCollaterallizedLevel * 100
      }% of your loan's value as collateral).`;
    }
    return "";
  };

  const handleFill = (postedCollateral, symbol) => {
    const currAssetAmount = parseFloat(collateralValue[symbol] || 0);
    if (postedCollateral === props.collateralRequirement) {
      alert(
        "You're already at the collateral requirement, there is nothing to fill."
      );
    } else if (postedCollateral > props.collateralRequirement) {
      /**
       * Handle case to automate user removal
       */
      if (currAssetAmount === 0) {
        alert(
          "You're already at the collateral requirement & this asset is currently at 0, so there is nothing to add/remove."
        );
        return;
      }
      /* Adding small amount bc rounding can be weird */
      const percentageOver =
        postedCollateral - (props.collateralRequirement + COLLATERAL_BUFFER);
      const valueToRemove = loanDollarValue * percentageOver;
      const amountOfAssetToRemove = valueToRemove / props.priceMap[symbol];
      const newValue = roundFloat(
        currAssetAmount - amountOfAssetToRemove,
        MAX_DECIMALS
      );
      setCollateralValue({
        ...collateralValue,
        [symbol]: Math.max(newValue, 0).toString(),
      });
    } else {
      /**
       * Handle case where user needs to add more
       */
      /* Adding small amount bc rounding can be weird */
      const percentageRemaining =
        props.collateralRequirement + COLLATERAL_BUFFER - postedCollateral;
      const valueToFill = loanDollarValue * percentageRemaining;
      const amountOfAssetToAdd = valueToFill / props.priceMap[symbol];
      const newValue = roundFloat(
        currAssetAmount + amountOfAssetToAdd,
        MAX_DECIMALS
      );
      setCollateralValue({
        ...collateralValue,
        [symbol]: newValue.toString(),
      });
    }
  };

  const handleUpdate = async () => {
    const errorMessage = checkErrors();
    if (errorMessage) {
      alert(errorMessage);
    } else {
      const data = {
        collateralValue,
        placedLoanId: props.placedLoanId,
      };
      setLoading(true);
      const response: any = await axiosRequest(
        props.postCollateralUrl,
        data,
        RequestType.POST
      );
      setSnackBarOpen(response.status !== 200);
      setLoading(false);
      setPostResponse(response);
    }
  };

  return (
    <Dialog
      classes={{
        paper: classes.dialogPaper,
      }}
      fullWidth
      onClose={props.handleClose}
      open={props.open}
    >
      <Flex container style={{ padding: 24 }}>
        <Flex container>
          <Flex item xs={24}>
            <Typography style={{ paddingBottom: 10 }} type="h6">
              Update Collateral
            </Typography>
          </Flex>
          <Flex item style={{ marginBottom: 16 }} xs={24}>
            <Divider spacingBottom={0} spacingTop={0} />
          </Flex>
          {postResponse && postResponse.status === 200 ? (
            <Flex container>
              <Confirmation
                mainText={"Successfully updated collateral"}
                subText={postResponse.data.successMessage || ""}
              />
              <Flex
                container
                justifyContent="flex-end"
                spacing={1}
                style={{ bottom: 16, position: "absolute", right: 16 }}
              >
                <Button
                  onClick={() => (window.location.href = "/loans")}
                  variant="gray"
                >
                  View all loans
                </Button>
                <Button
                  onClick={() => {
                    window.location.reload();
                    return false;
                  }}
                >
                  Back to loan
                </Button>
              </Flex>
            </Flex>
          ) : (
            <div>
              {(!postResponse || postResponse.status !== 200) && (
                <Flex container>
                  <Flex item style={{ marginBottom: 8, marginTop: 8 }} xs={24}>
                    <InfoWarning>
                      The collateral entered in the textfields below is the
                      total amount of collateral to set.
                    </InfoWarning>
                  </Flex>
                  <Flex container spacing={1} style={{ paddingTop: 8 }}>
                    <Flex item xs={priceMapError ? 24 : 16}>
                      <Flex container spacing={2}>
                        {Object.keys(collateralValue).map((symbol, index) => (
                          <Flex item style={{ paddingBottom: 8 }} xs={12}>
                            <label
                              className={`c-label s-fontSize12 ${
                                collateralValue[symbol] >
                                parseFloat(props.collateralBalanceMap[symbol]) +
                                  initialCollateralValue[symbol]
                                  ? "u-colorRed"
                                  : ""
                              }`}
                            >
                              {`${symbol} Collateral`}
                              {props.priceMap[symbol] !== 0 && (
                                <>&nbsp;&middot;&nbsp;</>
                              )}
                              {props.priceMap[symbol] !== 0 && (
                                <Link
                                  onClick={() =>
                                    handleFill(postedCollateral, symbol)
                                  }
                                >
                                  Fill
                                </Link>
                              )}
                            </label>
                            <BlockField
                              error={
                                collateralValue[symbol] >
                                parseFloat(props.collateralBalanceMap[symbol]) +
                                  initialCollateralValue[symbol]
                              }
                              hint={getHelperText({
                                collateralEntered: parseFloat(
                                  collateralValue[symbol]
                                ),
                                collateralBalance:
                                  parseFloat(
                                    props.collateralBalanceMap[symbol]
                                  ) + initialCollateralValue[symbol],
                                symbol: symbol,
                              })}
                            >
                              <InputWithAddon addon={symbol}>
                                <TextInput
                                  onChange={(e) =>
                                    setCollateralValue({
                                      ...collateralValue,
                                      [symbol]: e.currentTarget.value,
                                    })
                                  }
                                  placeholder="1.0123"
                                  onInput={limitDecimalPlaces}
                                  value={collateralValue[symbol]}
                                />
                              </InputWithAddon>
                            </BlockField>
                          </Flex>
                        ))}
                        {/* TODO ARJUN - HUGE HACK BC FLEX NOT WORKING PROPERLY */}
                        {props.collateralAssetSymbols.length % 2 !== 0 && (
                          <Flex item xs={12}></Flex>
                        )}
                      </Flex>
                    </Flex>
                    {!priceMapError && (
                      <Flex item style={{ overflow: "hidden" }} xs={8}>
                        <Flex container style={{ paddingBottom: 40 }}>
                          <LargeDisplayAmount
                            bottomElement={<p>/ of loan value</p>}
                            error={
                              postedCollateral < props.collateralRequirement
                            }
                            mainString={`${roundFloat(
                              postedCollateral * 100,
                              2
                            )}%`}
                            topLabelText={"Collateral Value"}
                          />
                        </Flex>
                      </Flex>
                    )}
                  </Flex>
                  <FormattedCells
                    cells={walletCells}
                    cellPadding={12}
                    parentSpacing={2}
                    style={{ marginTop: 20 }}
                  />
                </Flex>
              )}
            </div>
          )}
          {(!postResponse || postResponse.status !== 200) && (
            <Flex
              container
              justifyContent="flex-end"
              style={{ paddingTop: 24 }}
            >
              <Button loading={loading} onClick={handleUpdate}>
                Update
              </Button>
            </Flex>
          )}
          {postResponse && postResponse.status !== 200 && (
            <Alert severity="error" >
              {postResponse.data && postResponse.data.errors
                ? postResponse.data.errors.message
                : 'Error updating collateral, please refresh the page and try again'}
            </Alert>
          )}
        </Flex>
      </Flex>

    </Dialog>
  );
}
