import * as React from "react";
import { useEffect, useRef } from "react";
import { Chart } from "chart.js";

import ChartJsPresets from "@react/components/core/Chart";

const extraOptions = {
  elements: {
    line: {
      borderColor: "#000",
      tension: 0,
      backgroundColor: "#FAFAFA",
    },
    point: {
      style: "circle",
      radius: 3,
      borderWidth: 2,
      backgroundColor: "#000",
      borderColor: "#FFFFFF",
      hoverRadius: 3,
      hoverBorderWidth: 2,
      hoverBackgroundColor: "#000",
      hoverBorderColor: "#FFFFFF",
    },
  },
  tooltips: {
    enabled: true,
    mode: "nearest",
    intersect: false,
    displayColors: false,
    titleFontStyle: "normal",
    titleAlign: "center",
    bodyFontSize: 16,
    bodyFontStyle: "bold",
    bodyAlign: "center",
    xPadding: 10,
    yPadding: 8,
    titleFontFamily: "Akkurat, Roboto, sans-serif",
    bodyFontFamily: "Akkurat, Roboto, sans-serif",
    callbacks: {
      label: (function (_this) {
        return function (tooltipItem) {
          var amount, decimals;
          decimals = tooltipItem.yLabel < 1 ? 4 : 2;
          amount = tooltipItem.yLabel.toFixed(decimals);
          return "$" + amount;
        };
      })(this),
    },
  },
};

export interface MinimalLineChartProps {
  data: any;
}

export const MinimalLineChart: React.FunctionComponent<MinimalLineChartProps> = ({
  data,
}) => {
  const chartOptions = Object.assign(
    ChartJsPresets.minimalLineOptions(),
    extraOptions
  );

  const canvasRef = useRef();

  useEffect(() => {
    if (canvasRef) {
      // @ts-ignore
      var myChart = new Chart(canvasRef.current.getContext("2d"), {
        type: "line",
        data: data,
        options: chartOptions,
      });
      return function cleanup() {
        myChart.destroy();
      };
    }
  }, [data]);

  return <canvas ref={canvasRef}></canvas>;
};

export default MinimalLineChart;
