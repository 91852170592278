import * as React from "react";
import { useState } from "react";
import { Select, SelectSizes, Selectize } from "@react/components";

interface SelectSectionProps {
  // props
}

const SelectSection: React.FunctionComponent<SelectSectionProps> = (
  {
    // props
  }
) => {
  const options = [
    {
      labelText: "Apple",
      value: "apple",
    },
    {
      labelText: "Pear",
      value: "pear",
    },
    {
      labelText: "Banana",
      value: "banana",
    },
  ];

  const optionsDisabled = [
    {
      labelText: "Apple",
      value: "apple",
    },
    {
      labelText: "Pear",
      value: "pear",
      disabled: true,
    },
    {
      labelText: "Banana",
      value: "banana",
    },
  ];

  return (
    <div>
      <div className="s-marginBottom2">
        <h2 className="s-fontSize26 s-borderBottom0_5 s-marginBottom1 u-colorGray2">
          Select
        </h2>
        <p className="s-marginBottom0_5 u-colorGray6">
          An implementation of{" "}
          <code className="c-code c-code--inline">{"<select>"}</code> input
        </p>
        <p className="s-marginBottom0_5 u-colorBlue">
          Note: If you want Label, Hint, Error, etc... use this component with{" "}
          <code className="c-code c-code--inline">
            <a href="#BlockField">{"<BlockField>"}</a>
          </code>
        </p>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">Default</h3>
        <pre>
          <Select options={options} />
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          Placeholder
        </h3>
        <pre>
          <Select options={options} placeholder="Pick a fruit" />
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          Selected value
        </h3>
        <pre>
          <Select options={options} defaultValue="banana" />
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          Disabled option
        </h3>
        <pre>
          <Select options={optionsDisabled} />
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">Sizes</h3>
        {SelectSizes.map((size, index) => (
          <div className="s-marginBottom1" key={`select-input-size-${index}`}>
            <pre>
              <Select options={options} size={size} />
            </pre>
          </div>
        ))}
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">Disabled</h3>
        <pre>
          <Select options={options} disabled />
        </pre>
      </div>
    </div>
  );
};

export default SelectSection;
