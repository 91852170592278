import * as React from "react";

import Typography from "@react/components/typography/Typography";
import Flex from "@react/components/Flex";
import SectionTitle from "@react/components/SectionTitle";

export default function HowItWorks(props) {
  /**
   * TODO ARJUN -- these hrefs should not be hardcoded & instead passed from controller
   */
  const steps = [
    {
      header: "Sign up",
      linkElement: <a href={props.registerUrl}>Sign up &raquo;</a>,
      subtitle:
        "Sign up for a CoinList Wallet and go through KYC onboarding. This usually takes around 5 minutes. ",
    },
    {
      header: "Deposit",
      linkElement: <a href={props.registerUrl}>Deposit &raquo;</a>,
      subtitle:
        "Deposit crypto into your CoinList Wallet. You can deposit any supported asset into CoinList to fund your account.",
    },
    {
      header: "Communicate",
      linkElement: <a href={props.newOtcTradeUrl}>Communicate &raquo;</a>,
      subtitle:
        "Get in touch with our trade desk about your preferred trade with this order form.",
    },
    {
      header: "Trade",
      linkElement: <a href={props.newOtcTradeUrl}>Trade &raquo;</a>,
      subtitle:
        "Once the trade is confirmed,  you will receive an email with the execution details.",
    },
  ];

  return (
    <Flex container style={props.style}>
      <SectionTitle
        style={{ paddingBottom: 48 }}
        // subtitle="CoinList stakes, generates, and signs blocks on your behalf while you
        //   retain full ownership of your tokens and earn rewards."
        title="How it works"
      />
      <Flex item xs={24}>
        <Flex container spacing={5}>
          {steps.map(({ header, linkElement, subtitle }, index) => (
            <Flex item key={header} sm={24 / steps.length} xs={24}>
              <Typography
                bold
                fontSize={30}
                style={{ paddingBottom: 8, width: "100%" }}
              >
                {index + 1}
              </Typography>
              <Typography
                bold
                fontSize={18}
                style={{ paddingBottom: 8, width: "100%" }}
              >
                {header}
              </Typography>
              <Typography style={{ paddingBottom: 8, width: "100%" }}>
                {subtitle}
              </Typography>
              <Flex item xs={24}>
                {linkElement}
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
}
