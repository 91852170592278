import * as React from "react";
import { useState } from "react";
import makeStyles from '@mui/styles/makeStyles';

import Dialog from "@mui/material/Dialog";

import Flex from "@react/components/Flex";
import BlockField from "@react/views/shared/forms/BlockField/BlockField";
import Loading from "@react/components/Loading";
import Typography from "@react/components/typography/Typography";
import {
  Field,
  Select,
  Label,
  Button,
  Divider,
  Checkbox,
  InlineLabel,
  TextInput,
} from "@react/components";
import { SelectOption } from "@react/types/ui-component";
import { RequestType } from "@react/utils/network";

const CONTAINER_PADDING = 20;

const useStyles = makeStyles(() => ({
  dialogPaper: {
    height: 420,
    maxWidth: 615,
    width: "100%",
  },
  dialogPaperXs: {
    height: 650,
    maxWidth: 600,
    width: "100%",
  },
}));

const createOptions = (symbols): Array<SelectOption> => {
  return symbols.map((symbol) => ({
    labelText: symbol,
    value: symbol,
  }));
};

export default function AddHighlight(props) {
  const classes = useStyles();
  const { card = {} } = props;
  const [fileName, setFileName] = useState(card.logo_path || "");
  const [loading, setLoading] = useState(false);
  const [projectName, setProjectName] = useState(card.project_name || "");
  const [description, setDescription] = useState(card.description || "");
  const [website, setWebsite] = useState(card.website_url || "");
  const [twitter, setTwitter] = useState(card.twitter_url || "");
  const [discord, setDiscord] = useState(card.discord_url || "");
  const [telegramUrl, setTelegramUrl] = useState(card.telegram_url || "");
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState(() => createOptions(props.symbols));
  const [symbol, setSymbol] = useState(options[0].value);
  const [marketplace, setMarketplace] = useState(card.marketplace_url || "");
  const [buyTokenUrl, setBuyTokenUrl] = useState(card.buy_token_url || "");
  const [dappUrl, setDappUrl] = useState(card.dapp_url || "");
  const [fromTeam, setFromTeam] = useState(card.from_team || false);
  const [coinGeckoId, setCoinGeckoId] = useState(card.coin_gecko_id || "");
  const [coinGeckoSymbol, setCoinGeckoSymbol] = useState(
    card.coin_gecko_symbol || ""
  );
  return (
    <div>
      <Dialog
        classes={{
          paper: classes.dialogPaper,
        }}
        fullWidth
        onClose={props.onClose}
        open={props.open}
      >
        <Flex container style={{ padding: CONTAINER_PADDING }} spacing={1}>
          <Flex item xs={24}>
            <Typography type="h6">Add Highlight</Typography>
          </Flex>
          <Flex item xs={24}>
            <Divider spacingTop={0} spacingBottom={0} />
          </Flex>
          <Flex container spacing={2} style={{ paddingTop: 8 }}>
            {props.card ? (
              <div></div>
            ) : (
              <Flex item xs={8}>
                <Field>
                  <Label htmlFor="asset">Asset</Label>
                  <Select
                    onChange={(e) => setSymbol(e.currentTarget.value)}
                    options={options}
                    value={symbol}
                    name="asset"
                  />
                </Field>
              </Flex>
            )}
            <Flex item xs={8}>
              <BlockField label="Project Name" name="projectName">
                <TextInput
                  value={projectName}
                  name="projectName"
                  onChange={(e) => setProjectName(e.currentTarget.value)}
                />
              </BlockField>
            </Flex>
            <Flex item xs={8}>
              <BlockField label="Description" name="description">
                <TextInput
                  value={description}
                  name="description"
                  onChange={(e) => setDescription(e.currentTarget.value)}
                />
              </BlockField>
            </Flex>
            <Flex item xs={8}>
              <BlockField label="Website" name="website">
                <TextInput
                  value={website}
                  name="website"
                  onChange={(e) => setWebsite(e.currentTarget.value)}
                />
              </BlockField>
            </Flex>
            <Flex item xs={8}>
              <BlockField label="Twitter" name="twitter">
                <TextInput
                  value={twitter}
                  name="twitter"
                  onChange={(e) => setTwitter(e.currentTarget.value)}
                  placeholder="https://twitter.com/coinlist"
                />
              </BlockField>
            </Flex>
            <Flex item xs={8}>
              <BlockField label="Discord" name="discord">
                <TextInput
                  value={discord}
                  name="discord"
                  onChange={(e) => setDiscord(e.currentTarget.value)}
                  placeholder="https://discord.com/coinlist"
                />
              </BlockField>
            </Flex>
            <Flex item xs={8}>
              <BlockField label="Telegram URL" name="telegramUrl">
                <TextInput
                  value={telegramUrl}
                  name="telegramUrl"
                  onChange={(e) => setTelegramUrl(e.currentTarget.value)}
                  placeholder="https://telegram.com/btc"
                />
              </BlockField>
            </Flex>
            <Flex item xs={8}>
              <BlockField label="Marketplace Link" name="marketplace">
                <TextInput
                  value={marketplace}
                  name="marketplace"
                  onChange={(e) => setMarketplace(e.currentTarget.value)}
                  placeholder="https://opensea.com/loomlock"
                />
              </BlockField>
            </Flex>
            <Flex item xs={8}>
              <BlockField label="Buy Token URL" name="buyTokenUrl">
                <TextInput
                  value={buyTokenUrl}
                  name="buyTokenUrl"
                  onChange={(e) => setBuyTokenUrl(e.currentTarget.value)}
                  placeholder="https://coinlist.co/buy/btc"
                />
              </BlockField>
            </Flex>
            <Flex item xs={8}>
              <BlockField label="Dapp URL" name="dappUrl">
                <TextInput
                  value={dappUrl}
                  name="dappUrl"
                  onChange={(e) => setDappUrl(e.currentTarget.value)}
                  placeholder="https://app.uniswap.org"
                />
              </BlockField>
            </Flex>
            {props.card && (
              <Flex item xs={8}>
                <BlockField label="Coin Gecko ID" name="coinGeckoId">
                  <TextInput
                    value={coinGeckoId}
                    name="coinGeckoId"
                    onChange={(e) => setCoinGeckoId(e.currentTarget.value)}
                    placeholder="bitcoin"
                  />
                </BlockField>
              </Flex>
            )}
            {props.card && (
              <Flex item xs={8}>
                <BlockField label="Coin Gecko Symbol" name="coinGeckoSymbol">
                  <TextInput
                    value={coinGeckoSymbol}
                    name="coinGeckoSymbol"
                    onChange={(e) => setCoinGeckoSymbol(e.currentTarget.value)}
                    placeholder="BTC"
                  />
                </BlockField>
              </Flex>
            )}
            <Flex item xs={8}>
              <BlockField label="Logo File Name" name="filename">
                <TextInput
                  value={fileName}
                  name="filename"
                  onChange={(e) => setFileName(e.currentTarget.value)}
                  placeholder="coinlist.svg"
                />
              </BlockField>
            </Flex>
            <Flex item xs={8}>
              <InlineLabel>
                <Checkbox onChange={() => setFromTeam(!fromTeam)} />
                Highlighted by Team
              </InlineLabel>
            </Flex>
            <Flex item justifyContent="flex-end" xs={24}>
              <Button onClick={() => setLoading(true)}>
                {loading ? (
                  <Loading
                    handleResponse={(response) => {
                      if (response.status === 200) {
                        alert(
                          "Saved highlight, to add more change the data & save again."
                        );
                      } else {
                        alert(
                          "Error saving highlight, please refresh the page and try again."
                        );
                      }
                      setLoading(false);
                    }}
                    loading={loading}
                    requestData={{
                      data: {
                        id: props.card ? props.card.id : "",
                        from_team: fromTeam,
                        logo_path: fileName,
                        project_name: projectName,
                        discord,
                        twitter,
                        website,
                        symbol,
                        description,
                        marketplace,
                        buy_token_url: buyTokenUrl,
                        dapp_url: dappUrl,
                        coin_gecko_id: coinGeckoId,
                        coin_gecko_symbol: coinGeckoSymbol,
                        telegram_url: telegramUrl,
                      },
                      type: RequestType.POST,
                      url: props.card
                        ? props.updateHighlightUrl
                        : props.createHighlightUrl,
                    }}
                  />
                ) : (
                  "Save"
                )}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Dialog>
    </div>
  );
}
