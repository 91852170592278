import * as React from "react";
import cx from "classnames";

export const IconNames = [
  "resize-vertical",
  "lock",
  "lock-open-alt",
  "ok",
  "cancel",
  "heart",
  "star",
  "search",
  "attention",
  "chevron-up",
  "clock",
  "back-in-time",
  "cog",
  "move",
  "info",
  "gauge",
  "governance",
  "portfolio",
  "globe",
  "facebook",
  "twitter",
  "linkedin",
  "angellist",
  "google",
  "google-plus",
  "menu",
  "spin6",
  "align-left",
  "align-center",
  "align-right",
  "picture",
  "link",
  "list",
  "paragraph",
  "plus",
  "fire",
  "video",
  "chevron-right",
  "mail-alt",
  "pencil",
  "comment",
  "qrcode",
  "location",
  "flash",
  "chevron-down",
  "discord",
  "minus",
  "chevron-left",
  "indent-left",
  "indent-right",
  "code",
  "share",
  "telegram",
  "mail",
  "algorand",
  "eye",
  "gift",
  "user-outline",
  "help",
  "wallet",
  "trade",
  "account",
  "auction",
  "nut",
  "pro-exchange",
  "calendar",
  "basket",
  "shop",
  "right",
  "wrench",
  "info-circled",
  "info-circled-alt",
  "link-ext",
  "github-circled",
  "doc",
  "youtube-play",
  "bank",
  "git",
  "medium",
  "telegram-circled",
] as const;

export type IconName = typeof IconNames[number];

export interface IconProps {
  icon: IconName;
}

export const Icon: React.FunctionComponent<IconProps> = ({ icon }) => {
  const iconClass = `icon-${icon}`;
  return <i className={iconClass} />;
};

export default Icon;
