import * as React from "react";

import Box from "@react/components/Box";
import Flex from "@react/components/Flex";
import { Link } from "@react/components";
import Typography from "@react/components/typography/Typography";

export default function WalletSideBar(props) {
  return (
    <Box
      onClick={props.onClick}
      style={{
        cursor: "pointer",
        marginBottom: 16,
        padding: 16,
        width: "100%",
      }}
    >
      <Flex alignItems="center" container>
        <Flex item xs={24}>
          <Flex container spacing={1}>
            <Flex container justifyContent="center">
              <Typography center>Available Balance</Typography>
            </Flex>
            <Flex container justifyContent="center">
              <Typography bold center color="primary" fontSize={20}>
                300 IMX
              </Typography>
            </Flex>
            <Flex container justifyContent="center">
              <Link fontWeight={700} fontSize={13} href="/join">
                View wallet
              </Link>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
}
