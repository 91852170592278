window.Admin.Entities.Wallet =
  usd_ledger_entries_table: ($$, $this) ->
    class CryptoLedgerEntriesTable extends Backbone.View
      initialize: ->
        @createdAtColumn = @$('.js-created_at_column').index()
        options = _.extend(DataTablePresets.default(), {
          order: [[ @createdAtColumn, "desc" ]],
          displayLength: 10
        })
        @tableApi = @$('.js-table').DataTable(options)

      events:
        'input .js-search_input': (event) ->
          @tableApi.search(event.currentTarget.value).draw()

    new CryptoLedgerEntriesTable el: $this
