window.Index.Jobs =
  header: ($$, $this) =>
    class HeaderView extends Backbone.View
      events: ->
        'click .js-view-openings': ->
          Animate.niceScrollTo($('.js-openings-section'))

    new HeaderView el: $this

  openings: ($$, $this) =>
    class OpeningsView extends Backbone.View
      initialize: () ->
        anchorValue = window.location.hash
        if (!!anchorValue)
          @$(anchorValue).toggleClass('opened')
          @$(anchorValue + ' ~ .js-details').slideToggle()
          Animate.niceScrollTo($(anchorValue))

    new OpeningsView el: $this

  opening: ($$, $this) =>
    class OpeningView extends Backbone.View
      events: ->
        'click .js-view-details': (target) ->
          $target = $(target)
          $target.toggleClass('opened')
          @$('.js-details').slideToggle()
          if !$target.hasClass('opened')
            # Update location hash to the id of the opening
            window.location.hash = '#' + @$('.js-view-details').attr('id')

    new OpeningView el: $this
