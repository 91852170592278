import * as React from "react";
import { ICON_MAP } from "../../../../shared/icons";

import Box from "@react/components/Box";
import Flex from "@react/components/Flex";
import InfoBox from "./InfoBox";
import { Link } from "@react/components";
import Typography from "@react/components/typography/Typography";

export default function StakingInfo(props) {
  const algo = props.tokenProfile.symbol.toUpperCase() === "ALGO";
  return algo ? (
    <Box
      onClick={props.onClick}
      style={{
        cursor: "pointer",
        marginBottom: 16,
        padding: 16,
        width: "100%",
      }}
    >
      <Flex alignItems="center" container>
        <Flex item xs={16}>
          <Flex container spacing={1}>
            <Flex item xs={24}>
              <Typography bold>Stake ALGO</Typography>
            </Flex>
            <Flex item xs={24}>
              <Typography bold color="gray" fontSize={13}>
                Secure the network while earning ALGO.
              </Typography>
            </Flex>
            <Flex item xs={24}>
              <Link
                fontWeight={700}
                fontSize={13}
                href="https://coinlist.co/register"
              >
                Sign Up
              </Link>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  ) : (
    <InfoBox
      category="imx_staking"
      confirmationSubText={`We'll email you when ${props.tokenProfile.symbol.toUpperCase()} staking is live on CoinList.`}
      /* https://stackoverflow.com/a/53336754 */
      image={
        <img
          src={ICON_MAP.stakingSvg}
          style={{
            height: 50,
            filter:
              "brightness(0) saturate(100%) invert(31%) sepia(60%) saturate(3895%) hue-rotate(311deg) brightness(98%) contrast(91%)",
            width: 50,
          }}
        />
      }
      joinWaitlistUrl={props.joinWaitlistUrl}
      loggedInUser={props.loggedInUser}
      mainContent="Secure the network while earning IMX. Coming soon to CoinList."
      onWaitlist={props.onStakingWaitlist}
      successMessage="Successfully joined the staking waitlist."
      title={"Staking"}
    />
  );
}
