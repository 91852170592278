import * as React from "react";
import { useState } from "react";
import BulkEntityEdit, { EntryToProcess } from "../index";

import { Action, ApplyTargetType } from "./ActionSelector";
import Table from "./Table";
import { Button, Icon } from "@react/components";
import Flex from "@react/components/Flex";

import axios from "axios";
import { getRailsHeaders } from "../../../../utils/network";

interface ProcessEntitesProps {
  entries: EntryToProcess[];
  onEntryChange: (newEntryData: EntryToProcess) => void;
  onFinishedProcessingAttempt: () => void;
  deleteEntry: (entryId) => void;
  actions: Action[];
  hidden: boolean;
  allProcessedSuccesfully: boolean;
  processActionsUrl: string;
  notoBaseUrl: string;
}

export default function ProcessEntities(props: ProcessEntitesProps) {
  // Indicator that a batch of action+user pairs pending success are being processed
  const [isAllProcessing, setIsAllProcessing] = useState<boolean>(false);

  // Indicator that processing has been attempted at least once
  const [processingAttempted, setProcessingAttempted] = useState<boolean>(
    false
  );

  if (props.hidden) {
    return <> </>;
  }

  // Create mapping of action ID to header in table
  var tableHeadersForActions: { [key: number]: string } = {};
  props.actions.forEach((action) => {
    tableHeadersForActions[action.id] = `Action #${action.id}`;
  });

  // Process single entry + action pair
  const processSingleHandler = async (
    entry: EntryToProcess,
    action: Action
  ) => {
    setProcessingAttempted(true);
    entry.statusByAction[action.id] = {
      success: false,
      errMsg: null,
      isProcessing: true,
    };
    props.onEntryChange(entry);

    try {
      // Send action along with entity id + user id to backend
      const payload = {
        entity_id: entry.entity_id,
        user_id: entry.user_id,
        action_to_apply: action,
      };
      const response = await axios.post(props.processActionsUrl, payload, {
        headers: getRailsHeaders(),
      });

      var resSuccess: boolean, resError: string;

      if (response.status === 200) {
        [resSuccess, resError] = [true, null];
      } else {
        [resSuccess, resError] = [
          false,
          (response.data && response.data.error) || "Unknown error",
        ];
      }
    } catch (error) {
      [resSuccess, resError] = [
        false,
        (error.response && error.response.data && error.response.data.error) ||
          "Unknown error occured",
      ];
    } finally {
      entry.statusByAction[action.id] = {
        success: resSuccess,
        errMsg: resError,
        isProcessing: false,
      };
      props.onEntryChange(entry);
      props.onFinishedProcessingAttempt();
    }
  };

  // Process actions for all those that have not yet been succesful
  const processAllPendingSuccess = async () => {
    // If there are no actions to process, err out
    if (props.actions.length === 0) {
      alert("Insert at least one action prior to processing");
      return;
    }

    // Keep track of action IDs applied per user ID
    var appliedPerUserId = {};
    props.entries.forEach((entry) => {
      if (entry.user_id in appliedPerUserId) {
        return;
      }
      appliedPerUserId[entry.user_id] = new Set();
    });

    setIsAllProcessing(true);
    for (const entry of props.entries) {
      for (const action of props.actions) {
        const isAlreadyAppliedUser = appliedPerUserId[entry.user_id].has(
          action.id
        );

        // Set as succesful if action already applied for user and action is user_id indexed
        if (
          action.applyTarget == ApplyTargetType.USER &&
          isAlreadyAppliedUser
        ) {
          entry.statusByAction[action.id] = {
            isProcessing: false,
            success: true,
          };
        }

        // Skip action apply for entities that are already succesful
        var status = entry.statusByAction[action.id];
        if (status && status.success) {
          continue;
        }

        // Apply action
        await processSingleHandler(entry, action);

        // Mark action as applied for this user id
        appliedPerUserId[entry.user_id].add(action.id);
      }
    }
    setIsAllProcessing(false);
  };

  // Button below actions table to process actions if some are unapplied or require retry
  const processButton = () => {
    if (props.entries.length === 0 || props.allProcessedSuccesfully) {
      return null;
    }
    const label = processingAttempted
      ? "Retry applying failed actions"
      : "Apply all actions";
    return (
      <Button onClick={processAllPendingSuccess} disabled={isAllProcessing}>
        {label} <span>💣</span>
      </Button>
    );
  };

  // Cells to display actions
  const generateActionCells = (entry) =>
    props.actions.map((action) => {
      const statuses = entry.statusByAction;

      if (!(action.id in statuses)) {
        return <span style={{ color: "purple" }}>Not Applied</span>;
      } else if (statuses[action.id].isProcessing) {
        return (
          <Flex alignItems="center" container justifyContent="center">
            <Icon icon="spin6" />
          </Flex>
        );
      } else if (statuses[action.id].success) {
        return (
          <span style={{ color: "green" }}>
            <b>Success</b>
          </span>
        );
      } else {
        return (
          <div>
            <span style={{ color: "red" }}>
              <b>Failed</b>{" "}
            </span>
            <a
              href="javascript:void(0)"
              onClick={(e) => {
                e.preventDefault();
                processSingleHandler(entry, action);
              }}
            >
              (Retry)
            </a>
          </div>
        );
      }
    });

  // Button to delete an entry in any given row
  const deleteEntryButton = (entry: EntryToProcess) =>
    props.allProcessedSuccesfully || isAllProcessing ? (
      <></>
    ) : (
      <a
        style={{ marginRight: "4px" }}
        href="javascript:void(0)"
        onClick={(e) => props.deleteEntry(entry.entry_id)}
      >
        [x]
      </a>
    );

  return (
    <div>
      <Table
        title="Users to Process"
        headCells={[
          "User",
          "Entity",
          "Links",
          "Wallet Balance",
          "PRO Balance",
          "Active Vault Participation",
          "Active Lend Participation",
          "Last Completed Investment",
        ]}
        style={{ marginTop: 10, marginBottom: 10 }}
        rows={props.entries.map((entry) => {
          
          const userColumn = (
            <a href={`/admin/users/${entry.user_id}`}>{entry.email}</a>
            );
            
            const entityColumn = entry.entity_api_id ? (
              <span>{entry.entity_api_id.substring(0, 12) + "..."}</span>
              ) : (
                <span>No Entities</span>
                );
                
                const links = entry.entity_api_id ? (
                  <span>
              <a
                href={
                  props.notoBaseUrl +
                  "/kyc_review/united_states/" +
                  entry.entity_api_id
                }
              >
                [Noto]
              </a>
              &nbsp;
              <a href={`/admin/entities/${entry.entity_api_id}`}>[Admin]</a>
            </span>
          ) : (
            <></>
            );
            
          const metadata_exists = Object.keys(entry.metadata).length > 0;

          const completed_investments = entry.metadata?.completed_invested_offerings;
          const investmentsColumn = metadata_exists ? (
            <span>
              {entry.metadata?.last_completed_investment_offering}{" "}
              {completed_investments && completed_investments.length > 0 ? (
                <i
                  className={
                    "icon-info-circled-alt js-tooltip_simple u-colorGray6"
                  }
                  title={
                    "Also participated in -\n " +
                    completed_investments.join(", ")
                  }
                />
              ) : null}
            </span>
          ) : <span>N/A</span>;

          return [
            <span style={{ display: "inline-flex" }}>
              {" "}
              {deleteEntryButton(entry)} {userColumn}{" "}
            </span>,
            <span>{entityColumn}</span>,
            <span>{links}</span>,
            metadata_exists ? (entry.metadata?.has_wallet_balance ? "✅" : "❌") : "N/A",
            metadata_exists ? (entry.metadata?.has_pro_balance ? "✅" : "❌") : "N/A",
            metadata_exists ? (entry.metadata?.has_active_vault_participation ? "✅" : "❌") : "N/A",
            metadata_exists ? (entry.metadata?.has_active_lend_loan_participations ? "✅" : "❌") : "N/A",
            investmentsColumn,
          ];
        })}
      />
      <Table
        title="Actions To Process"
        headCells={["User/Entity", ...Object.values(tableHeadersForActions)]}
        style={{ marginTop: 10, marginBottom: 10 }}
        rows={props.entries.map((entry) => {
          const userIdentifierColumn =
            entry.email +
            " - " +
            (entry.entity_api_id
              ? entry.entity_api_id.substring(0, 12) + "..."
              : "No Entities");

          const actionCells = generateActionCells(entry);

          return [userIdentifierColumn, ...actionCells];
        })}
      />

      <div style={{ marginTop: 20 }}>{processButton()}</div>
    </div>
  );
}
