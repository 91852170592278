import * as React from "react";
import {
  Progress,
  ProgressSizes,
  ProgressVariants,
} from "@react/components";

interface ProgressSectionProps {
  // props
}

const ProgressSection: React.FunctionComponent<ProgressSectionProps> = (
  {
    // props
  }
) => {
  return (
    <div>
      <div className="s-marginBottom2">
        <h2 className="s-fontSize26 s-borderBottom0_5 s-marginBottom1 u-colorGray2">
          Progress
        </h2>
        <p className="s-marginBottom0_5 u-colorGray6">
          A component to indicate progress. (duh)
        </p>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          Default use
        </h3>
        <pre>
          <Progress />
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          Progress props
        </h3>
        <div className="s-marginBottom1">
          <pre>
            <Progress progress={0} />
          </pre>
        </div>
        <div className="s-marginBottom1">
          <pre>
            <Progress progress={20} />
          </pre>
        </div>
        <div className="s-marginBottom1">
          <pre>
            <Progress progress={40} />
          </pre>
        </div>
        <div className="s-marginBottom1">
          <pre>
            <Progress progress={65} />
          </pre>
        </div>
        <div className="s-marginBottom1">
          <pre>
            <Progress progress={85} />
          </pre>
        </div>
        <div className="s-marginBottom1">
          <pre>
            <Progress progress={100} />
          </pre>
        </div>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          Show Progress
        </h3>
        <div className="s-marginBottom1">
          <pre>
            <Progress progress={0} showProgress />
          </pre>
        </div>
        <div className="s-marginBottom1">
          <pre>
            <Progress progress={20} showProgress />
          </pre>
        </div>
        <div className="s-marginBottom1">
          <pre>
            <Progress progress={40} showProgress />
          </pre>
        </div>
        <div className="s-marginBottom1">
          <pre>
            <Progress progress={65} showProgress />
          </pre>
        </div>
        <div className="s-marginBottom1">
          <pre>
            <Progress progress={85} showProgress />
          </pre>
        </div>
        <div className="s-marginBottom1">
          <pre>
            <Progress progress={100} showProgress />
          </pre>
        </div>
      </div>

      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">Sizes</h3>
        {ProgressSizes.map((size, index) => (
          <>
            <div className="s-marginBottom1" key={`button-size-${index}`}>
              <pre>
                <Progress size={size}>{size} Progress</Progress>
              </pre>
            </div>

            <div className="s-marginBottom1" key={`button-size-${index}`}>
              <pre>
                <Progress size={size} showProgress>
                  {size} Progress
                </Progress>
              </pre>
            </div>
          </>
        ))}
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">Variants</h3>
        {ProgressVariants.map((variant, index) => (
          <>
            <div className="s-marginBottom1" key={`button-variant-${index}`}>
              <pre>
                <Progress variant={variant}>{variant} Progress</Progress>
              </pre>
            </div>
            <div className="s-marginBottom1" key={`button-variant-${index}`}>
              <pre>
                <Progress variant={variant} showProgress>
                  {variant} Progress
                </Progress>
              </pre>
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

export default ProgressSection;
