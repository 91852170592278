window.Admin.Users = {
  index: ($$, $this) ->
    class IndexView extends Backbone.View
      initialize: ->
        @firstNameColumn = @$('.js-first_name_column').index()
        url = @$('.js-table').data('url')
        @options = _.extend(DataTablePresets.default(), {
          order: [[ @firstNameColumn, "asc" ]],
          pageLength: 50,
          serverSide: true,
          processing: true,
          deferLoading: 0,
          ajax: (data, callback, settings) =>
            @$('.js-offerings-manage-table').addClass('c-table--loading')
            @$('.js-search').prop("disabled",true)
            $.ajax url,
              dataType: 'json'
              type: 'GET'
              data: data
              success: (response) =>
                @$('.js-table').removeClass('c-table--loading')
                @$('.js-search').prop("disabled",false)
                api = settings.oInstance.api()
                dataRows = $(response.rows).filter('tr')
                totalRows = response.total_records
                callback({data: {}, recordsFiltered: totalRows, recordsTotal: totalRows, draw: 0})
                api.rows.add dataRows
                settings.aiDisplay = settings.aiDisplayMaster.slice()
                settings.bAjaxDataGet = false
                api.draw(false)
                settings.bAjaxDataGet = true
        })
        @tableApi = @$('.js-table').DataTable(@options)

      search: ->
        @tableApi.search(@$('.js-search_input').val().trim()).draw()

      events:
        'keydown .js-search_input': (event) ->
          if event.keyCode == Keyboard.enter
            event.preventDefault()
            @search()

        'click .js-search': ->
          @search()



    new IndexView el: $this
}
