
window.SelectizePreset =

  # Preset to have selectize render given html
  renderHtml: (dataHash) ->

    # The actual {options} passed to selectize
    copyClassesToDropdown: false,

    # Unsure why, but options don't seem to get unselected, so we doing this manually
    onChange: (value) ->
      if value
        $.each @options, (optionValue) ->
          $(".option.selected[data-value='#{optionValue}']").removeClass('selected')
        $(".option.selected[data-value='#{value}']").addClass('selected')

    render:
      item: (item, escape) =>
        data = dataHash[item.value]

        # If the given DataHash has a html key/value for given value, use that, else use default
        itemMarkup = if data? && data.html? then data.html else item.text
        """
          <div class="item item--tag" data-value="#{item.value}">
            #{itemMarkup}
          </div>
        """
      option: (item, escape) =>
        data = dataHash[item.value]

        # If the given DataHash has a html key/value for given value, use that, else use default
        itemMarkup = if data? && data.html? then data.html else item.text
        """
          <div class="option" data-selectable=" data-value="#{item.value}" >
            #{itemMarkup}
          </div>
        """