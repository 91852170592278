require('jquery-form')
require('jquery-countdown')
autosize = require('autosize')
accounting = require('accounting')
Partials = require('../lib/partials')

window.Investments.New = {

  investment_documents_package: ($$, $this) ->
    class InvestmentDocumentsPackageView extends Backbone.View

      initialize: ->
        offeringId = @$('.js-download_required_notice').data('offeringId')
        @localStorageKey = "hasDownloadedInvestmentDocumentsPackage_#{offeringId}"

        if localStorage.getItem(@localStorageKey)
          @hideDownloadNotice()

      hideDownloadNotice: ->
        @$('.js-download_required_notice').remove()
        Partials.with Partials.selector('investments/new/form'), (partial) =>
          partial._updateInvestButton()

      events:
        'click .js-download_investment_documents_package': ->
          @hideDownloadNotice()
          localStorage.setItem(@localStorageKey, true)

    new InvestmentDocumentsPackageView el: $this

  confirmation: ($$, $this) ->
    class ConfirmationView extends Backbone.View
      initialize: ->
        $$('.js-edit_purchase').on 'click', (event)->
          $.magnificPopup.close()
          Partials.with Partials.selector('investments/new/form'), (partial) =>
            partial._removeLoadingOnInvestButton()
        $$('.js-confirm_purchase').on 'click', (event)->
          $button = $(event.target)
          return if $button.hasClass('c-button--loading')
          $button.addClass 'c-button--loading'
          $.magnificPopup.close()
          Partials.with Partials.selector('investments/new/form'), (partial) =>
            partial._submitForm()

    new ConfirmationView el: $this

  form: ($$, $this) ->
    class FormView extends Backbone.View
      initialize: ->
        @$invest_button = @$('.js-submit-investment-form')
        @$invest_button_message = @$('.js-invest_button_message')
        @$amount_input = @$('input.js-committed_amount_input')

        data = @$el.data()
        @offeringName   = data.offering.name

        @minInvestment  = data.investment.min_amount
        @maxInvestmentUsdc = data.investment.max_amount_usdc
        @maxInvestmentUsdt = data.investment.max_amount_usdt
        @feeMultiplier  = data.managementFee.multiplier

        @BTC            = data.currencies.btc
        @ETH            = data.currencies.eth
        @USDC           = data.currencies.usdc
        @USDT           = data.currencies.usdt
        @btcPrice       = data.currencyPrices.btc
        @ethPrice       = data.currencyPrices.eth

        @investVerb = data.displayText.invest_verb
        @isBlockstack = data.offering.blockstack

        @_updateAmounts()

        @$invest_button.on 'click', (event) =>
          event.preventDefault()
          @_showConfirmation()

      _calculateAmounts: ->
        @amount = accounting.unformat(@$amount_input.val())

        @managementFee = @amount * @feeMultiplier
        @totalDue = @amount + @managementFee

        @btcAmount = @totalDue / @btcPrice
        @ethAmount = @totalDue / @ethPrice

      _updatePartialsWithAmounts: ->
        Partials.with Partials.selector('investments/shared/usd_payments'), (partial) =>
          partial.updateAmount(@amount)
        Partials.with Partials.selector('investments/new/estimated_token'), (partial) =>
          partial.updateAmount(@amount)

        # update currency selector
        Partials.with Partials.selector('investments/new/payment_options'), (partial) =>
          partial.updateCurrencyAmount(@USDC, @amount)
          partial.updateCurrencyAmount(@USDT, @amount)
          partial.updateCurrencyAmount(@BTC, @btcAmount)
          partial.updateCurrencyAmount(@ETH, @ethAmount)

      _updateManagementFees: ->
        @$('.js-management-fees').text(accounting.formatMoney(@managementFee))
        @$('.js-total-amount-due').text(accounting.formatMoney(@totalDue))

      _removeLoadingOnInvestButton: ->
        @$invest_button.removeClass('c-button--loading')

      _updateInvestButton: ->
        @$invest_button.html("#{@investVerb} #{accounting.formatMoney(@amount)}")

        # some hacky fix to this issue:
        # https://stackoverflow.com/questions/42016113/rails-data-disable-with-re-enabling-button
        # TODO implement a better fix
        @$invest_button.attr('data-disable-with', "#{@investVerb} #{accounting.formatMoney(@amount)}")

        $fundingMethodInput = $('input.js-funding-method')
        if $fundingMethodInput.val() == 'usdc'
          maxInvestmentAmount = @maxInvestmentUsdc
        else if $fundingMethodInput.val() == 'usdt'
          maxInvestmentAmount = @maxInvestmentUsdt
        else
          maxInvestmentAmount = null
        enableButton =
          (!maxInvestmentAmount? || @amount <= maxInvestmentAmount) && @amount >= @minInvestment && @amount > 0 && @$('.js-download_required_notice').length == 0

        if enableButton
          @$invest_button.removeClass('c-button--disabled')
          @$invest_button_message.addClass('u-hidden')
        else
          if @amount < @minInvestment && @amount != 0
            title = "Minimum investment in #{@offeringName} is #{accounting.formatMoney(@minInvestment)}"
          else if @maxInvestment? && @amount > @maxInvestment
            title = "#{accounting.formatMoney(@amount)} exceeds the max investment amount."
          # TODO kill me after stacks sale
          else if @$('.js-download_required_notice').length > 0
            title = "You must download and review the purchase documents"
          else
            title = ""

          @$invest_button.addClass('c-button--disabled')
          @$invest_button_message.text(title).removeClass('u-hidden')

      # TODO kill me after blockstack
      _updatePurchaseLimit: ->
        return if @$('.js-purchase_limit').length == 0

        if @amount && @maxInvestment && @amount >= @maxInvestment
          @$('.js-purchase_limit').slideDown 'fast'
        else
          @$('.js-purchase_limit').slideUp 'fast'

      _updateAmounts: ->
        @_calculateAmounts()
        @_updatePartialsWithAmounts()
        @_updateManagementFees()
        @_updateInvestButton()
        @_updatePurchaseLimit()

      _showConfirmation: ->
        $button = @$invest_button

        return if $button.hasClass('c-button--disabled') || $button.hasClass('c-button--loading')
        $button.addClass 'c-button--loading'
        $('.js-confirmPurchaseAmount').html(accounting.formatMoney(@amount))
        $('.js-confirmFundingMethod').html($('input.js-funding-method').val().toUpperCase())
        @$('.js-purchaseConfirmation').show()
        $.magnificPopup.open({
          items: {
            src: @$('.js-purchaseConfirmation')
            type: 'inline'
          },
          closeOnBgClick: false,
          enableEscapeKey: false,
          showCloseBtn:false
        })

      _submitForm: ->
        $button = @$invest_button
        return if $button.hasClass('c-button--disabled')

        $form = $('form.js-investment-form')
        $form.ajaxSubmit
          success: (response) =>
            if @isBlockstack
              fbq?('track', 'Purchase', {currency: 'USD', value: @amount})

              Analytics.trackGtagEvent 'Purchase',
                send_to: 'AW-799254041/3vG5CNvg2qUBEJnMjv0C',
                value: @amount,
                currency: 'USD'

            window.location.href = response.redirect

          error: (response) =>
            $button.removeClass 'c-button--loading'
            if response.status == 422
              $html = $(response.responseJSON.html)
              @$el.replaceWith $html
              # hacky, had to do this because of the way the form cascades across partials
              $error = $html.find('.c-input-group--has-error,.js-error').first()
              if $error.length
                $error = Animate.niceScrollTo($error, 700)
            else
              Tooltips.formServerError($button)

      events: ->
        'input .js-committed_amount_input': (event) ->
          @_updateAmounts()
        'keydown input[type="text"]': (event) ->
          if event.keyCode == Keyboard.enter
            event.preventDefault()

    new FormView el: $this

  estimated_token: ($$, $this) =>
    class EstimatedToken extends Backbone.View
      initialize: ->
        @tokensData = @$('.js-data-tokens-left').data()
        @pricesData = @$('.js-data-prices').data()

      updateAmount: (amount) ->
        amountLeft = amount
        totalTokens = 0
        pricesInOrder = Object.keys(@pricesData).sort()
        for index, price of pricesInOrder
          roundName = @pricesData[price]
          tokensAvailable = @tokensData[roundName]
          tokenAmount = Math.min(Math.floor(amountLeft / price), tokensAvailable)
          amountLeft -= tokenAmount * price
          totalTokens += tokenAmount
          tokenString = if tokenAmount > 0 then "#{tokenAmount} @ $#{price} per token" else ''
          @$(".js-price_breakdown_#{roundName}").text(tokenString)

        @$('.js-token_count').text(totalTokens)
        @$('.js-estimated_token_count').val(totalTokens)

    new EstimatedToken el: $this

  payment_options: ($$, $this) ->
    class PaymentOptionsView extends Backbone.View
      initialize: ->
        @$fundingMethodInput = @$('input.js-funding-method')
        $investmentForm = $('.investments-new-form')
        data = $investmentForm.data()
        @maxAmountUsdc = data.investment.max_amount_usdc
        @maxAmountUsdt = data.investment.max_amount_usdt

        @_selectCurrency(true)

        # for internal USD payments, read value from `usd_payments` partial
        Partials.with Partials.selector('investments/shared/usd_payments'), (partial) =>
          partial.on 'fundingMethodSelect', (fundingMethod) =>
            @_setFundingMethod(fundingMethod)

      _setFundingMethod: (fundingMethod) ->
        @$fundingMethodInput.val(fundingMethod)

      _updateMaxInvestmentAmount: (currency) ->
        maxInvestmentText = $('#js-max-investment-amount')
        if currency == 'usdc'
          maxInvestmentText.text("$#{@maxAmountUsdc}")
        else if currency == 'usdt'
          maxInvestmentText.text("$#{@maxAmountUsdt}")

      _selectCurrency: (onInitialize = false) ->
        # clear out committed amount and update amounts and buttons
        if !onInitialize
          $('#investment_committed_amount').val('')
          Partials.with Partials.selector('investments/new/form'), (partial) =>
            partial._updateAmounts()

        currency = @$('input.js-currency:checked').val()

        $('.js-show-for-selected-currency').hide()
        $('.js-show-for-selected-currency').each (i, item) =>
          if $(item).data('funding-methods').indexOf(currency) >= 0
            $(item).show()
        @_setFundingMethod(currency)
        @_updateMaxInvestmentAmount(currency)

      updateCurrencyAmount: (currency, amount) ->
        amount = Math.ceil(amount * 1000) / 1000
        amountString = if amount > 0 then "~ #{amount} #{currency.toUpperCase()}" else '&nbsp;'
        @$(".js-amount[data-currency='#{currency}']").html(amountString)

      events: ->
        'change input.js-currency': ->
          @_selectCurrency()

    new PaymentOptionsView el: $this

  saft_download: ($$, $this) =>
    class SaftDownloadView extends Backbone.View
      initialize: ->
        @noVesting           = @$('.js-data-vesting').data('noVesting')
        @twelveMonthVesting  = @$('.js-data-vesting').data('twelveMonthVesting')
        @toggleShowVestingDoc()

      showNoVestingDoc: ->
        @$('.js-saft_placeholder').hide()
        @$('.js-saft_doc_no_discount').show()
        @$('.js-saft_doc_discount').hide()

      showTwelveMonthVestingDoc: ->
        @$('.js-saft_placeholder').hide()
        @$('.js-saft_doc_no_discount').hide()
        @$('.js-saft_doc_discount').show()

      toggleShowVestingDoc: ->
        value = @$('.js-vesting_period:checked').val()

        if value == @noVesting
          @showNoVestingDoc()
        else if value == @twelveMonthVesting
          @showTwelveMonthVestingDoc()

      events:
        'change .js-vesting_period': (event) ->
          event.preventDefault()
          @toggleShowVestingDoc()

    new SaftDownloadView el: $this
}
