require('jquery-form')
require('../lib/keyboard')
Partials = require('../lib/partials')

window.Offerings.Entities =
  new: ($$, $this) ->
    class NewView extends Backbone.View
      LOADING_CLASS: 'c-button--loading'

      # * this radio may not be on the page, but since we can't use the is_new_kyc_active_for_offerings? LD flag here, we're leaving the coffeescript in case we turn the flag back off
      initialize: ->
        # if we don't do this...
        #
        #   if the user selects "new entity," goes to a different website,
        #   and hits the browser back button,
        #   then the new entity radio will be selected, but the "new form" will not be displayed
        if @$('.js-new_entity_radio').length != 0
          @_toggleNewEntityForm @$('.js-new_entity_radio').is(':checked')


      events:
        'change .js-entity_radio': (event) ->
          $target = $(event.target)
          value = $target.val()
          displayValue = $target.data('displayValue')
          showNewForm = value == @$el.data('new_entity_value')
          # * since we can't use the is_new_kyc_active_for_offerings? LD flag here, we're leaving the coffeescript in case we turn the flag back off
          @_toggleNewEntityForm showNewForm

          unless showNewForm
            @$('.js-submit_existing_entity').text(displayValue)

        'click .js-submit_existing_entity': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass @LOADING_CLASS
          $target.addClass @LOADING_CLASS
          @$('.js-offerings_participant_form').ajaxSubmit
            success: (response) =>
              window.location.href = response.redirect
            error: (response) =>
              Tooltips.formServerError($target)

        # * since we can't use the is_new_kyc_active_for_offerings? LD flag here, we're leaving the coffeescript in case we turn the flag back off
        'click .js-create_new_entity': (event) ->
          Partials.with Partials.selector('entities/new_form'), (partial) =>
            partial.submitForm(event)

      # * since we can't use the is_new_kyc_active_for_offerings? LD flag here, we're leaving the coffeescript in case we turn the flag back off
      _toggleNewEntityForm: (showNewForm) ->
        @$('.js-new_form').toggle showNewForm
        @$('.js-existing_entity').toggle !showNewForm

    new NewView el: $this

  # TODO: Tis should really be in participant.coffee / folder
  onboarding: ($$, $this) =>
    class OnboardingView extends Backbone.View
      initialize: ->
        $data = @$('.js-data')
        @showRequestedAmount = $data.data('showRequestedAmount')
        @nextStepUrl = $data.data('nextStepUrl')

        Partials.with Partials.selector('offerings/entities/onboarding/blockstack_onboarding'), (partial) =>
          partial.on 'goToNextStep', =>
            @goToNextStep(partial.$button)

        Partials.with Partials.selector('offerings/entities/onboarding/props_onboarding'), (partial) =>
          partial.on 'goToNextStep', =>
            @goToNextStep(partial.$button)

      goToNextStep: ($button) ->
        if @showRequestedAmount
          @saveRequestedAmount($button)
        else
          window.location.href = @nextStepUrl

      saveRequestedAmount: ($button) ->
        partialSelector = Partials.selector('offerings/entities/onboarding/requested_amount')
        Partials.with partialSelector, (partial) =>
          partial.updateRequestedAmount().done( =>
            window.location.href = @nextStepUrl
          ).catch (response) =>
            if response.status == 422
              $button.removeClass 'c-button--loading'
            else
              Tooltips.formServerError($button)

      events:
        'click .js-get_started_cta': (event) ->
          event.preventDefault()
          $button = @$(event.currentTarget)
          return if $button.hasClass('c-button--loading')
          $button.addClass('c-button--loading')
          @goToNextStep($button)

    new OnboardingView el: $this

  Sidebar:
    requested_amount: ($$, $this) =>
      class RequestedAmountView extends Backbone.View
        showRequestedAmountForm: ->
          @$('.js-requested_amount_content').hide()
          @$('.js-form').show()

        events:
          'click .js-edit_requested_amount': ->
            @showRequestedAmountForm()

          'submit .js-form': (event) ->
            event.preventDefault()
            $form = $(event.target)
            $button = @$('.js-cta')

            return if $button.hasClass('c-button--loading')

            $button.addClass('c-button--loading')

            $form.ajaxSubmit
              success: (response) =>
                @$el.replaceWith response.html
              error: (response) =>
                if response.status == 422
                  @$el.replaceWith response.responseJSON.html
                else
                  Tooltips.formServerError($button)

      new RequestedAmountView el: $this
