import * as React from "react";

import Flex from "@react/components/Flex";
import { Button } from "@react/components";

export default function PaymentToolBar(props) {
  return (
    <Flex
      alignItems="center"
      container
      justifyContent="space-between"
      spacing={1}
      style={{ padding: 10 }}
    >
      <div>Payments</div>
      <div>
        <Flex container spacing={1}>
          {props.filterButtons.map((button) => (
            <Button
              key={button.title}
              onClick={() => props.handleFilter(button.state)}
              size="extra-small"
              variant={props.filters[button.state] ? "primary" : "gray"}
            >
              {button.title}
            </Button>
          ))}
        </Flex>
      </div>
    </Flex>
  );
}
