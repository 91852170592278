Partials = require('../lib/partials')

window.Lend.Index =
  asset_table: ($$, $this) ->
    class AssetTableView extends Backbone.View
      events:
        'click .js-cta': (event) ->
          $target = $(event.target)

          Partials.with Partials.selector('lend/index/interest_form'), (partial) =>
            partial.updateInputs($target.data('action'), $target.data('symbol'))

    new AssetTableView el: $this

  interest_form: ($$, $this) ->
    class InterestFormView extends Backbone.View
      updateInputs: (action, symbol) ->
        @$('.js-lending-or-borrowing-input').val(action)
        @$('.js-asset-input').val(symbol)
        @updateSymbol()

      updateSymbol: ->
        @$('.js-asset-symbol').text(@$('.js-asset-input').val())

      events:
        'change .js-asset-input': (event) ->
          @updateSymbol()

        'submit .js-interest-form': (event) ->
          event.preventDefault()
          @$('.js-cta').addClass('c-button--loading')
          @$('.js-interest-form').ajaxSubmit
            success: (response) =>
              # Show the success message
              @$('.js-success-message').removeClass('u-hidden')
              @$('.js-interest-form').addClass('u-hidden')

              # Wait 3 seconds, then close the popup (hash reset) and replace the form
              setTimeout =>
                window.location.hash = ''

                # Wait 1 second and reset the form
                setTimeout =>
                  @$el.replaceWith response.form_html
                , 1000
              , 4000

            error: (response) =>
              if response.status == 422
                @$el.replaceWith response.responseJSON.form_html
              else
                Tooltips.formServerError(@$('.js-cta'))


    new InterestFormView el: $this

