YouTubePlayer = require('youtube-player')

window.Rewards.Quizzes.Videos =
  show: ($$, $this) ->
    class ShowView extends Backbone.View
      FADE_DURATION_MS = 500

      initialize: ->
        @markVideoWatchedPath = @$el.data('mark-video-watched-path')
        @nextQuestionPath = @$el.data('next-question-path')
        @nextQuestionPartialPath = @$el.data('next-question-partial-path')
        @currentVideoOrder = @$el.data('current-video-order')

        @$finishReminder = $('.js_finish-reminder')
        @$questionLoadingIndicator = $('.js_question-loading-indicator')
        @$questionModuleContainer = $('.js_question-module-container')

        Backbone.history.start({pushState: true})
        Backbone.history.route /.*/, ->
          # always reload the page when hitting the back button
          window.location.reload()

        @videoPlayer = YouTubePlayer('quiz-video-embed')
        $ @_bindVideoFinished

        # If this is not the initial video, show instructions
        @$finishReminder.fadeIn() if @currentVideoOrder > 1


      _bindVideoFinished: =>
        @videoPlayer.on 'stateChange', (event) =>
          return if @videoWatched
          switch event.data
            when YT.PlayerState.PAUSED
              @$finishReminder.fadeIn()
            when YT.PlayerState.ENDED
              @transitionToNextQuestion()

      markVideoWatched: (cb) ->
        $.post
          url: @markVideoWatchedPath
          success: -> cb()
          error: -> cb new Error('Server error marking video watched')

      transitionToNextQuestion: ->
        return if @videoWatched
        @videoWatched = true
        @$questionLoadingIndicator.fadeIn()

        @markVideoWatched (err) =>
          return @showErrorMessage() if err

          $.get
            url: @nextQuestionPartialPath
            success: (response) =>
              @$finishReminder.fadeOut()
              @$questionLoadingIndicator.fadeOut FADE_DURATION_MS, =>
                @$questionModuleContainer.html response.html
                @$questionModuleContainer.fadeIn FADE_DURATION_MS
              Backbone.history.navigate @nextQuestionPath
            error: (response) =>
              @showErrorMessage()

      showErrorMessage: ->
        @$finishReminder.fadeOut()
        @$questionLoadingIndicator.fadeOut()
        $('.js_error-message').fadeIn()

    new ShowView el: $this

