import * as React from "react";
import cx from "classnames";

export interface TableHeadProps {
  bottomBorder?: boolean;
  children?: React.ReactNode;
}

export const TableHead: React.FunctionComponent<TableHeadProps> = ({
  bottomBorder = false,
  children,
}) => {
  const classes = cx(
    { "s-borderBottom1": bottomBorder },
    { "u-colorGray2": bottomBorder }
  );

  return <thead className={classes}>{children}</thead>;
};

export default TableHead;
