Partials = require('../lib/partials')

window.Shared.Notes =
  # Note(andreasklinger): using $ instead of @$ because
  #   the tooltip trigger is outside of the list partial

  tooltip_trigger: ($$, $this) ->
    class TooltipTriggerView extends Backbone.View

      initialize: ->
        $inactiveIconClass = @$el.data('inactive-icon-class')
        $activeIconClass = @$el.data('active-icon-class')
        $tooltip = @$('.js-tooltip_content')
        $tooltip.qtip(
          _.extend Tooltips.presetContent(),
            events:
              render: =>
                Partials.with Partials.selector('shared/notes/list'), (partial) =>

                  partial.on 'noteCreated', (offeringsParticipantId) =>
                    if @$el.data('offerings-participant-id') == offeringsParticipantId
                      $tooltip.addClass $activeIconClass
                      @$el.data('num-notes', @$el.data('num-notes') + 1)
                      # reposition makes the tooltip point at the same icon after adding a note
                      $tooltip.qtip('reposition')

                  partial.on 'noteDeleted', (offeringsParticipantId) =>
                    if @$el.data('offerings-participant-id') == offeringsParticipantId
                      @$el.data('num-notes', @$el.data('num-notes') - 1)
                      if @$el.data('num-notes') == 0
                        $tooltip.removeClass $activeIconClass
                        $tooltip.addClass $inactiveIconClass
                      $tooltip.qtip('reposition')

                  partial.on 'noteEdited', (offeringsParticipantId) =>
                    if @$el.data('offerings-participant-id') == offeringsParticipantId
                      $tooltip.qtip('reposition')

                  partial.on 'noteError', (offeringsParticipantId) =>
                    if @$el.data('offerings-participant-id') == offeringsParticipantId
                      $tooltip.qtip('reposition')
        )

    new TooltipTriggerView el: $this

  list: ($$, $this) ->
    class PartialView extends Backbone.View
      events:
        'submit .js-notes-form': (event) ->
          event.preventDefault()
          event.stopPropagation()
          $target = @$(event.currentTarget)
          $target.ajaxSubmit
            success: (response) =>
              $this.html $(response.html).html()
              # we will only trigger noteCreated if it is a new note
              # this data attribute of new-note will be false if we are editing a note
              if $target.data('new-note')
                @trigger 'noteCreated', @$el.data('offerings-participant-id')

            error: (response) =>
              $this.html $(response.html).html()
              @trigger 'noteError', @$el.data('offerings-participant-id')

        'click .js-delete-note': (event) ->
          event.preventDefault()
          event.stopPropagation()
          $target = @$(event.currentTarget)

          $.ajax
            url: $target.attr('href'),
            type: 'DELETE',
            success: (response) =>
              $this.html $(response.html).html()
              @trigger 'noteDeleted', @$el.data('offerings-participant-id')

        'click .js-edit-note': (event) ->
          event.preventDefault()
          event.stopPropagation()
          $target = @$(event.currentTarget)

          $.ajax
            url: $target.attr('href'),
            success: (response) =>
              $this.html $(response.html).html()
              @trigger 'noteEdited', @$el.data('offerings-participant-id')

    new PartialView el: $this

