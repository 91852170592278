require('jquery')
require('underscore')

window.Hackathons ||= {}
window.Hackathons.Custom ||= {}
window.Hackathons.Custom.Zerox||= {}

window.Hackathons.Custom.Zerox.LandingPage =
  quotes: ($$, $this) =>
    class BuildView extends Backbone.View
      initialize: ->
        setInterval(window.Hackathons.Custom.Zerox.LandingPage.shuffeHidden, 5000)

    new BuildView el: $this


window.Hackathons.Custom.Zerox.LandingPage.shuffeHidden = ->
  $slides = $('.js-carousel-slide')

  $slides.hide()
  $(_.sample($slides)).fadeIn()
