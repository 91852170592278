Partials = require('../lib/partials')
import * as Castle from '@castleio/castle-js'

window.Devise.Sessions = {

  new_form: ($$, $this) ->
    class NewFormView extends Backbone.View
      initialize: ->
        @toggleButtonState()

      toggleButtonState: ->
        @$('.js-form_submit').prop('disabled', !@validForm())

      getFormattedEmail: ->
        $.trim(@$('.js-form_email_input').val())

      validEmail: ->
        pattern = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
        pattern.test(@getFormattedEmail())

      validPassword: ->
        $.trim(@$('.js-form_password_input').val()).length > 9
        
      validForm: ->
        @validEmail() && @validPassword()

      events:
        'click .js-submit': (event) ->
          if castleData?.castlePublishKey
            Castle.createRequestToken().then (token) =>
              $('#castle_request_token').val(token)
            event.preventDefault()
            $target = $(event.target)
            $target.addClass 'c-button--loading'
            $target.closest('form').submit()

        'blur .js-form_password_input': (event) ->
          if @validPassword()
            @$('.js-form_password_input_error').hide()
          else
            @$('.js-form_password_input_error').show()

        'blur .js-form_email_input': (event) ->
          if @validEmail() || $.trim(@$('.js-form_email_input').val()) == ""
            @$('.js-form_email_input_error').hide()
          else
            @$('.js-form_email_input_error').show()
            
        'input .js-form_email_input': (event) ->
          @toggleButtonState()

        'input .js-form_first_name_input': (event) ->
          @toggleButtonState()

        'input .js-form_last_name_input': (event) ->
          @toggleButtonState()

        'input .js-form_password_input': (event) ->
          @toggleButtonState()

    new NewFormView el: $this

  multi_factor_new: ($$, $this) ->
    class MultiFactorNewView extends Backbone.View

      initialize: ->
        if @$('.js-security_key').is(':visible')
          @login()

      login: ->
        Partials.with @$(Partials.selector 'webauthn_credentials/app'), (app) =>
          app.get().login()
            .then (response) =>
              # We're passing this in debugInfo because the webauthn lib is expecting a particular set
              # of properties and this is the only one we can use to pass back arbitrary info
              window.location = response.debugInfo.redirect
            .catch (error) =>
              Rollbar.error('Webauthn login error', error)
              @$('.js-waiting').hide()
              @$('.js-error').show()

      events:
        'click .js-retry': ->
          @$('.js-waiting').show()
          @$('.js-error').hide()

          @login()

        'click .js-show_authenticator_app': ->
          @$('.js-multi_factor_method').hide()
          @$('.js-authenticator_app').fadeIn 'fast'

        'click .js-show_security_key': ->
          @$('.js-multi_factor_method').hide()
          @$('.js-security_key').fadeIn 'fast', =>
            @login()

    new MultiFactorNewView el: $this

}