import * as React from "react";
import { useState } from "react";

import Box from "@react/components/Box";
import { Button } from "@react/components";
import Flex from "@react/components/Flex";
import Typography from "@react/components/typography/Typography";
import WalletCells from "@react/components/WalletCells";

interface WalletCell {
  labelText: string;
  toolTipText?: string;
  value: string;
}

interface WalletBoxProps {
  lockedWalletBalance: string;
  usdHoldings: string;
  walletBalance: string;
  walletCells: Array<WalletCell>;
  walletUrl: string;
}

const createWalletCells = (
  lockedWalletBalance,
  walletBalance,
  usdHoldings
): Array<WalletCell> => {
  let cells = [
    {
      labelText: "Available Balance",
      value: walletBalance,
    },
    {
      labelText: "Locked Balance",
      value: lockedWalletBalance,
    },
  ];
  if (usdHoldings) {
    cells.push({
      labelText: "Your Holdings",
      value: usdHoldings,
    });
  }
  return cells;
};

export default function WalletBox(props: WalletBoxProps) {
  const [walletCells, setWalletCells] = useState(() =>
    createWalletCells(
      props.lockedWalletBalance,
      props.walletBalance,
      props.usdHoldings
    )
  );

  return (
    <Box id={"mainContainer"} fullWidth>
      <Flex container>
        {/* TITLE & BUTTONS */}
        <Flex
          alignItems="center"
          container
          justifyContent="space-between"
          separator
          style={{ padding: 8 }}
        >
          <div>
            <Flex alignItems="center" container style={{ padding: 8 }}>
              <Typography bold fontSize={18}>
                Wallet Overview
              </Typography>
            </Flex>
          </div>
          <div>
            <Flex
              container
              justifyContent="flex-end"
              spacing={1}
              style={{ padding: 8 }}
            >
              <Button
                onClick={() => window.open(props.walletUrl, "_blank").focus()}
                size="small"
              >
                View Wallet
              </Button>
            </Flex>
          </div>
        </Flex>
        {/* CELLS TO DISPLAY INFO */}
        <WalletCells walletCells={walletCells} />
      </Flex>
    </Box>
  );
}
