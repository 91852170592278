import * as React from "react";
import { useState } from "react";
import { Radio, InlineLabel } from "@react/components";
import InputWithAddon from "@react/views/shared/forms/InputWithAddon/InputWithAddon";
import RadioCollections from "@react/views/shared/forms/RadioCollections/RadioCollections";

interface RadioSectionProps {
  // props
}

const RadioSection: React.FunctionComponent<RadioSectionProps> = (
  {
    // props
  }
) => {
  const [radioValue, setRadioValue] = useState("");
  const radioOptions = [
    {
      label: "Toyota",
      radio: {
        value: "toyota",
      },
    },
    {
      label: "Ford",
      radio: {
        value: "ford",
        disabled: true,
      },
    },
    {
      label: (
        <p>
          <span>⚡</span> Tesla
        </p>
      ),
      radio: {
        value: "tesla",
      },
    },
  ];

  const radioCollectionsExamples = `const [radioValue, setRadioValue] = useState("");
const radioOptions = [
  {
    label: "Toyota",
    radio: {
      value: "toyota",
    },
  },
  {
    label: "Ford",
    radio: {
      value: "ford",
      disabled: true,
    },
  },
  {
    label: <p><span>⚡</span> Tesla</p>,
    radio: {
      value: "tesla",
    },
  },
];

<RadioCollections
  radioOptions={radioOptions}
  name="car"
  value={radioValue}
  onChange={(e) => setRadioValue(e.target.value)}
/>`;

  return (
    <div>
      <div className="s-marginBottom2">
        <h2 className="s-fontSize26 s-borderBottom0_5 s-marginBottom1 u-colorGray2">
          RadioCollections
        </h2>
        <p className="s-marginBottom0_5 u-colorGray6">
          A shared component to build a list of radio inputs
        </p>
        <p className="s-marginBottom0_5 u-colorBlue">
          Note: If you want Label, Hint, Error, etc... use this component with{" "}
          <code className="c-code c-code--inline">
            <a href="#BlockField">{"<BlockField>"}</a>
          </code>
        </p>
      </div>

      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          Collection
        </h3>
        <pre>
          <RadioCollections
            radioOptions={radioOptions}
            name="car"
            value={radioValue}
            onChange={(e) => setRadioValue(e.target.value)}
          />
        </pre>
      </div>
    </div>
  );
};

export default RadioSection;
