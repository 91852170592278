require('jquery-form')
require('./lib/keyboard')

window.AllocationRequests =
  create_form: ($$, $this) ->
    class CreateFormView extends Backbone.View
      submit: ->
        $target = @$('.js-continue')
        return if $target.hasClass 'c-button--loading'
        $target.addClass 'c-button--loading'
        $target.closest('form').ajaxSubmit
          success: (response) =>
            window.location.href = response.redirect
          error: (response) =>
            if response.status == 422
              @$el.replaceWith response.responseJSON.html
            else
              Tooltips.formServerError($target)

      events:
        'click .js-continue': (event) ->
          event.preventDefault()
          @submit()

        'keydown .js-requested_amount_input': (event) ->
          if event.keyCode == Keyboard.enter
            event.preventDefault()
            @submit()

    new CreateFormView el: $this

  edit_requested_amount_form: ($$, $this) ->
    class EditRequestedAmountFormView extends Backbone.View
      events:
        'submit form': (event) ->
          event.preventDefault()
          $form = $(event.target)
          $button = @$('.js-submit')
          return if $button.hasClass 'c-button--loading'
          $button.addClass 'c-button--loading'
          $form.ajaxSubmit
            success: (response) =>
              window.location.href = response.redirect_url
            error: (response) =>
              if response.status == 422
                @$el.replaceWith response.responseJSON.html
              else
                Tooltips.formServerError($button)

    new EditRequestedAmountFormView el: $this
