import { LOGO_MAP } from "@react/shared/logos";
import * as React from "react";

import Box from "@react/components/Box";
import Flex from "@react/components/Flex";
import Typography from "@react/components/typography/Typography";

import { hasFullSidebarButSmall } from "@react/utils";

export default function InfoBox(props) {
  const fullSideBarButSmall = hasFullSidebarButSmall();

  return (
    <Box
      onClick={props.onClick}
      style={{
        cursor: "pointer",
        marginBottom: 16,
        padding: 16,
        width: "100%",
      }}
    >
      <Flex
        alignItems="center"
        container
        spacing={3}
        style={{ paddingBottom: 16 }}
      >
        <img src={LOGO_MAP.fil} style={{ height: 42, width: 42 }} />
        <i className={`icon-right`} style={{ fontSize: 30 }}></i>
        <img src={LOGO_MAP.efil} style={{ height: 42, width: 42 }} />
      </Flex>
      <Flex container>
        <Typography bold color="gray" fontSize={13}>
          Wrapped tokens allow you to use tokens from different blockchains on
          the same one. For example, your wrapped BTC maintains a 1:1 value as
          BTC, but can be used on the Ethereum blockchain.
        </Typography>
      </Flex>
    </Box>
  );
}
