window.Index.NearIndex =
  header: ($$, $this) =>
    class HeaderView extends Backbone.View
      events:
        'click .js-scroll-link': (event) ->
          event.preventDefault()
          $link = $(event.currentTarget)
          target = $link.attr('href')
          Animate.niceScrollTo($(target))

    new HeaderView el: $this

  auction_details: ($$, $this) =>
    class AuctionDetailsView extends Backbone.View
      events:
        'click .js-scroll-link': (event) ->
          event.preventDefault()
          $link = $(event.currentTarget)
          target = $link.attr('href')
          Animate.niceScrollTo($(target))

    new AuctionDetailsView el: $this

  risks: ($$, $this) =>
    class RisksView extends Backbone.View
      events:
        'click .js-scroll-link': (event) ->
          event.preventDefault()
          $link = $(event.currentTarget)
          target = $link.attr('href')
          Animate.niceScrollTo($(target))

    new RisksView el: $this