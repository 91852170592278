window.Hackathons.Votes =
  list: ($$, $this) ->
    class ListView extends Backbone.View
      LOADING_CLASS = 'hackathons-votes-list__vote-button--loading'

      events: ->
        'click .js-sort-button': (event) ->
          $target = $(event.target)
          return if $target.hasClass LOADING_CLASS

          $target.addClass LOADING_CLASS
          $target.html('…')
          $.ajax
            url: $target.attr('data-url')
            success: (response) ->
              $this.replaceWith(response.html)

    new ListView el: $this

  vote_button: ($$, $this) ->
    class VoteButtonView extends Backbone.View
      LOADING_CLASS = 'hackathons-votes-list__vote-button--loading'

      events: ->
        'click .js-vote-button': (event) ->
          $target = $(event.target)
          return if $target.hasClass LOADING_CLASS

          $target.addClass LOADING_CLASS
          $target.html('…')
          $.ajax
            url: $target.attr('data-url')
            type: $target.attr('data-method')
            success: (response) ->
              if response.redirect_url
                window.location.href = response.redirect_url
              else
                $target.replaceWith(response.html)

          # Note(andreasklinger): for some reason preventDefault() is not enough
          false

    new VoteButtonView el: $this
