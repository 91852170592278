require('jquery-form')
require('../lib/keyboard')
Partials = require('../lib/partials')

window.Entities.Trusts = {

  edit_form: ($$, $this) =>
    class EditFormView extends Backbone.View
      CONTINUE_CLASS: 'js-continue'
      DISABLED_CLASS: 'c-button--disabled'

      initialize: ->
        @_bindIdentityDataFormListeners()
        @_bindSignatoryFormListeners()
        @_bindMajorShareholdersFormListeners()
        @_bindAgreementVerificationFormListeners()

      _onComplete: ($form) ->
        $section = $form.closest('.js-section')
        $section.addClass 'js-completed_section'
        $section.find('.js-completed').removeClass('u-hidden')
        $section.find('.js-section_completed_icon').removeClass('u-hidden')
        $section.find('.js-show_edit').slideUp 'fast'
        if @$('.js-completed_section.js-section:not(.js-optional)').length == @$('.js-section:not(.js-optional)').length
          @$(".#{@CONTINUE_CLASS}").removeClass @DISABLED_CLASS

      _bindIdentityDataFormListeners: ->
        Partials.with @_getIdentityDataForm(), (partial) =>
          partial.on 'completed', => @_onComplete @_getIdentityDataForm()

          # every time the content is replaced, @$el is replaced, so we have to rebind the
          # listeners to the new @$el
          partial.on 'replace', (replaceMethod) =>
            replaceMethod()
            @_bindIdentityDataFormListeners()

      _bindSignatoryFormListeners: ->
        Partials.with @_getSignatoryForm(), (partial) =>
          partial.on 'completed', => @_onComplete @_getSignatoryForm()

          # every time the content is replaced, @$el is replaced, so we have to rebind the
          # listeners to the new @$el
          partial.on 'replace', (replaceMethod) =>
            replaceMethod()
            @_bindSignatoryFormListeners()

      _bindMajorShareholdersFormListeners: ->
        Partials.with @_getMajorShareholdersForm(), (partial) =>
          partial.on 'completed', =>
            @_onComplete @_getMajorShareholdersForm()

          # every time the content is replaced, @$el is replaced, so we have to rebind the
          # listeners to the new @$el
          partial.on 'replace', (replaceMethod) =>
            replaceMethod()
            @_bindMajorShareholdersFormListeners()

      _bindAgreementVerificationFormListeners: ->
        Partials.with @_getAgreementVerificationForm(), (partial) =>
          partial.on 'completed', => @_onComplete @_getAgreementVerificationForm()

          # every time the content is replaced, @$el is replaced, so we have to rebind the
          # listeners to the new @$el
          partial.on 'replace', (replaceMethod) =>
            replaceMethod()
            @_bindAgreementVerificationFormListeners()

      _getIdentityDataForm: ->
        @$(Partials.selector 'entities/trusts/identity_data_form')

      _getMajorShareholdersForm: ->
        @$(Partials.selector 'roles/major_shareholders_form')

      _getSignatoryForm: ->
        @$(Partials.selector 'roles/signatory_form')

      _getAgreementVerificationForm: ->
        @$(Partials.selector 'kyc_aml_statuses/trusts/agreement_verification_form')

      events:
        'click .js-edit': (event) ->
          $target = $(event.target)
          $target.closest('.js-completed').addClass('u-hidden')
          $target.closest('.js-section').find('.js-show_edit').slideDown 'fast'

        'click .js-continue': (event) ->
          if @$(".#{@CONTINUE_CLASS}").hasClass @DISABLED_CLASS
            event.preventDefault()

    new EditFormView el: $this

  identity_data_form: ($$, $this) ->
    class IdentityDataFormView extends Backbone.View
      showSaved: ->
        $('.js-saved_trust_identity').css('display', 'inline-block')
        $('.js-saved_trust_identity').fadeOut 2000

      events:
        'click .js-save': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass 'c-button--loading'
          $target.addClass 'c-button--loading'
          $target.closest('form').ajaxSubmit
            success: (response) =>
              @trigger 'replace', =>
                @$el.replaceWith response.html
              @trigger 'completed'
              @showSaved()
            error: (response) =>
              if response.status == 422
                @trigger 'replace', =>
                  @$el.replaceWith response.responseJSON.html
              else
                Tooltips.formServerError($target)

    new IdentityDataFormView el: $this

  new_form: ($$, $this) ->
    class NewFormView extends Backbone.View
      events:
        'keydown input[type="text"]': (event) ->
          if event.keyCode == Keyboard.enter
            event.preventDefault()

    new NewFormView el: $this

  accreditation_form: ($$, $this) ->
    class AccreditationForm extends Backbone.View
      replaceMultipleUploadForms: ->
        Partials.with Partials.selector('components/multiple_file_upload'), (partial) =>
          htlm = @multipleFileUploadForms[partial.uniqIndex()]
          if htlm.length != 0 && partial.$el.find('.js-form_error').length == 0
            htlm.find('.js-form_error').remove()
            partial.$el.replaceWith htlm

      saveMultipleFileUploadsForms: ->
        @multipleFileUploadForms = {}
        Partials.with Partials.selector('components/multiple_file_upload'), (partial) =>
          @multipleFileUploadForms[partial.uniqIndex()] = partial.$el.clone()

      events:
        'click .js-create': (event) ->
          event.preventDefault()
          # We delegate submission responsibility to the underlying person accreditation form
          # if the trust is revocable. Otherwise we're responsible.
          assetsFormSelector =
            Partials.selector 'entities/companies/acts_like_company_assets_form'
          return if @$(assetsFormSelector).length != 1

          $target = $(event.target)

          return if $target.hasClass 'c-button--loading'

          @saveMultipleFileUploadsForms()

          $target.addClass 'c-button--loading'
          Partials.with @$(assetsFormSelector), (form) =>
            form.submit().catch (response) =>
              if response.status == 422
                $target.removeClass 'c-button--loading'
                @replaceMultipleUploadForms()
              else
                Tooltips.formServerError($target)

    new AccreditationForm el: $this
}
