Plyr = require('plyr')
remodal = require('remodal')
Vimeo = require('@vimeo/player/dist/player')
require('jquery-countdown')
moment = require('moment')
require('moment-timezone')
Partials = require('./lib/partials')

window.Index = {
  deal_page_index: ($$, $this) ->
    class DealPageIndex extends Backbone.View
      events:
        'click .js-deal_page_show_form': (event) ->
          $target = $(event.target)
          event.preventDefault()
          @$('.js-deal_page_content_form').removeClass('u-hidden')
          @$('.js-deal_page_content').addClass('u-hidden')
          $target.addClass('u-hidden')

        'click .js-submit_deal_page': (event) ->
          $target = $(event.target)
          event.preventDefault()
          return if $target.hasClass 'c-button--loading'
          $target.addClass 'c-button--loading'

          @$('form').ajaxSubmit
            success: (response) =>
              window.location.reload()
            error: (response) =>
              $target.removeClass 'c-button--loading'
              error_message = response.responseJSON.error_message
              if error_message
                @$('.js-errors').text(error_message).slideDown()
              else
                Tooltips.formServerError($target)


    new DealPageIndex el: $this

  filecoin_2017_index: ($$, $this) ->
    class Filecoin2017Index extends Backbone.View
      initialize:  ->
        @setupVideo()

      setupVideo: ->
        $video = @$('.js-video')
        @player = new Plyr('.js-video', PlyrPresets.default())

      showSection: (id) ->
        @$('.js-section').hide()
        @$('.js-navigation_link').addClass('c-link--muted')
        @$(".js-navigation_link[href='#{id}']").removeClass('c-link--muted')
        @$("#{id}").show()

      events:
        'click .js-navigation_link': (event) ->
          $target = $(event.target)
          event.preventDefault()
          @showSection($target.attr('href'))

    new Filecoin2017Index el: $this

  filecoin_index: ($$, $this) ->
    class FilecoinIndexView extends Backbone.View
      events:
        'click .js-show_about': (event) ->
          @$('.js-about_content').show()
          @$('.js-purchasers_content').hide()
          @$('.js-show_about').addClass('index-filecoin_index__tab--active')
          @$('.js-show_purchasers').removeClass('index-filecoin_index__tab--active')

        'click .js-show_purchasers': (event) ->
          @$('.js-about_content').hide()
          @$('.js-purchasers_content').show()
          @$('.js-show_about').removeClass('index-filecoin_index__tab--active')
          @$('.js-show_purchasers').addClass('index-filecoin_index__tab--active')


    new FilecoinIndexView el: $this

  trade_waitlist_index: ($$, $this) ->
    class TradeWaitlistIndexView extends Backbone.View
      initialize: ->
        Partials.with Partials.selector('waitlist_requests/shared/form_logged_out'), (partial) =>
          partial.on 'success', (response) =>
            @showSuccess(response)

        Partials.with Partials.selector('waitlist_requests/shared/form_logged_in'), (partial) =>
          partial.on 'success', (response) =>
            @showSuccess(response)

      showSuccess: (response) ->
        @$('.js-form-wrapper').slideUp('fast')
        @$('.js-success-message').slideDown('fast')
        if response.success_message_html?
          @$('.js-success-message-partial').html(response.success_message_html)

    new TradeWaitlistIndexView el: $this

  PropsIndex: {
    video_embed: ($$, $this) =>
      class VideoEmbedView extends Backbone.View
        initialize: ->
          $video = @$('.js-video')
          plyr.setup($video[0], PlyrPresets.default())

      new VideoEmbedView el: $this

    countdown: ($$, $this) =>
      class CountdownView extends Backbone.View
        initialize: ->
          time = moment(@$('.js-countdown').data('time'))
          timeWithZone = moment.tz(time, moment.tz.guess())
          @$('.js-countdown').countdown timeWithZone.format('M/D/YYYY, H:mm:ss'), (event) =>
            @$('.js-countdown').html(event.strftime('%-D day%!d %-H hr%!H %-M min %-S sec'))
            if event.offset.totalSeconds <= 0
              window.location.reload()

      new CountdownView el: $this
  }
  BlockstackIndex: {
    vimeo_embed: ($$, $this) ->
      class VimeoEmbedView extends Backbone.View
        initialize:  ->
          @setupVideo()

        setupVideo: =>
          @player = new Vimeo(document.querySelector('iframe'))
          $('.js-video_container').on 'click', =>
            videoPosterClass = 'index-blockstack_index-vimeo_embed__video_container--with_poster'
            $('.js-video_container').removeClass(videoPosterClass)
            @player.play()

      new VimeoEmbedView el: $this

    video: ($$, $this) ->
      class VideoView extends Backbone.View
        initialize: ->
          time = moment(@$('.js-countdown').data('time'))
          timeWithZone = moment.tz(time, moment.tz.guess())
          @$('.js-countdown').countdown timeWithZone.format('M/D/YYYY, H:mm:ss'), (event) =>
            @$('.js-countdown').html(event.strftime('%-D day%!d %-H hr%!H %-M min %-S sec'))
            if event.offset.totalSeconds <= 0
              window.location.reload()

      new VideoView el: $this
  },

  BlockstackSpvIndex: {
    toolbar: ($$, $this) ->
      class ToolbarView extends Backbone.View
        initialize: ->
          time = moment(@$('.js-countdown').data('time'))
          timeWithZone = moment.tz(time, moment.tz.guess())
          @$('.js-countdown').countdown timeWithZone.format('M/D/YYYY, H:mm:ss'), (event) =>
            @$('.js-countdown').html(event.strftime('%-D day%!d %-H hr%!H %-M min %-S sec'))
            if event.offset.totalSeconds <= 0
              window.location.reload()

      new ToolbarView el: $this

    spv_allocation_request: ($$, $this) =>
      class SpvAllocationRequest extends Backbone.View
        showSuccessMessage: ->
          @$('.js-spv_allocation_request_wrapper').hide()
          @$('.js-success_message').show()
      new SpvAllocationRequest el: $this
  }

  styles: ($$, $this) ->
    class StyleView extends Backbone.View
      initialize: ->
        @initalizeStickySidebar()
        @$(".js-font_size_16").show()

      events:
        'change .js-font_size_range': (event) ->
          $target = $(event.currentTarget)
          value = $target.val()
          @$(".js-font_size").hide()
          @$(".js-font_size_#{value}").show()

        'click .js-navigation_link': (event) ->
          $target = $(event.currentTarget)
          @openNavigationLink($target)

      openNavigationLink: ($target) ->
        $subNavigation = $target.next('.js-sub_navigation')
        if $target.hasClass('c-nav__item')
          @$('.js-navigation_link').removeClass('c-nav__item--active')
          @$('.js-sub_navigation').hide()
          $target.addClass('c-nav__item--active')
        if $subNavigation.length
          $target.next('.js-sub_navigation').show()

      initalizeStickySidebar: ->
        sidebar = @$('.js-navigation')
        sidebarStickyClass = 'admin-style__nav--sticky'
        content = @$('.js-content')
        sections = @$('.js-section')
        $window = $(window)

        $(document).ready =>
          contentTopOffset = content.offset().top
          sidebarBottom = sidebar.height() + sidebar.position().top
          contentBottom = content.height() + content.position().top - sidebarBottom

          $window.on 'scroll.sidebar', =>
            stickySidebar = $window.scrollTop() >= contentTopOffset &&
              $window.scrollTop() <= contentBottom
            sidebar.toggleClass(sidebarStickyClass, stickySidebar)
          $(window).trigger('scroll.sidebar')

          $window.on 'scroll.navigation', =>
            currentPosition = $window.scrollTop()
            sections.each (index, section) =>
              $section = $(section)
              top = $section.offset().top
              bottom = top + $section.outerHeight()
              sectionId = $section.attr('id')
              if currentPosition >= top and currentPosition <= bottom
                @openNavigationLink(@$(".js-navigation_link[href='##{sectionId}']"))
          $(window).trigger('scroll.navigation')

    new StyleView el: $this
}
