
Partials = require('../../lib/partials')
require('trumbowyg')
require('trumbowyg/dist/plugins/upload/trumbowyg.upload')
require('trumbowyg/dist/plugins/noembed/trumbowyg.noembed')

window.Offerings.DealPageSections.Shared =

  sections: ($$, $this) ->
    class SectionsView extends Backbone.View
      initialize: ->
        formPartial = 'offerings/deal_page_sections/shared/form'

        # Avoid listening events from other forms on the page
        $formScoped =
            @$el.find('.js-new_deal_page_sections_form_wrapper')
                .find(Partials.selector(formPartial))

        Partials.with $formScoped , (partial) =>
          partial.on 'success', (sectionHtml) =>
            @$('.js-sections_wrapper').append(sectionHtml)
            @$('.js-deal_page_new_section_content_wrapper').show()
            @$('.js-deal_page_new_section_form_wrapper').hide()
            partial.resetForm()

          partial.on 'cancel', (sectionHtml) =>
            @$('.js-deal_page_new_section_content_wrapper').show()
            @$('.js-deal_page_new_section_form_wrapper').hide()

      scrollToSection: (sectionId) ->
        navigationHeight = @$('.js-navigation_wrapper').height()
        Animate.niceScrollTo($(sectionId), -navigationHeight)

      events:
        'click .js-deal_page_new_section_show_form': (event) ->
          @$('.js-deal_page_new_section_content_wrapper').hide()
          @$('.js-deal_page_new_section_form_wrapper').show()

        'click .js-deal_page_section_show_content': (event) ->
          @$('.js-deal_page_new_section_content_wrapper').show()
          @$('.js-deal_page_new_section_form_wrapper').hide()

        'click .js-navigation_link': (event) ->
          @scrollToSection(@$(event.target).attr('href'))

        'change .js-navigation_select': (event) ->
          @scrollToSection(@$(event.target).val())

    new SectionsView el: $this

  section: ($$, $this) ->
    class SectionView extends Backbone.View
      initialize: ->
        formPartial = 'offerings/deal_page_sections/shared/form'
        Partials.with @$(Partials.selector(formPartial)), (partial) =>
          partial.on 'success', (sectionHtml) =>
            @$el.replaceWith(sectionHtml)
          partial.on 'cancel', (sectionHtml) =>
            @$('.js-deal_page_section_content_wrapper').show()
            @$('.js-deal_page_section_form_wrapper').hide()

      events:
        'click .js-deal_page_section_show_form': (event) ->
          @$('.js-deal_page_section_content_wrapper').hide()
          @$('.js-deal_page_section_form_wrapper').show()

        'click .js-deal_page_section_show_content': (event) ->
          @$('.js-deal_page_section_content_wrapper').show()
          @$('.js-deal_page_section_form_wrapper').hide()

        'click .js-move-section-up': (event) ->
          event.preventDefault()

          $.ajax @$(event.currentTarget).attr('href'),
            type: 'PATCH'
            success: (response) =>
              @$el.prev().insertAfter(@$el);

        'click .js-move-section-down': (event) ->
          event.preventDefault()
          $.ajax @$(event.currentTarget).attr('href'),
            type: 'PATCH'
            success: (response) =>
              @$el.next().insertBefore(@$el);


        'click .js-deal_page_section_destroy': (event) ->
          event.preventDefault()
          $target = @$(event.target)
          if confirm('Are you sure you want to remove this section?')
            $.ajax @$(event.currentTarget).attr('href'),
              dataType: 'json'
              type: 'DELETE'
              success: (response) =>
                @$el.remove()

    new SectionView el: $this

  form: ($$, $this) ->
    class FormView extends Backbone.View
      initialize: ->
        $.trumbowyg.svgPath = @$('.js-svg_icon').data('url')

        # With image upload
        $imageUploadData = @$('.js-image_upload_data')
        @serverPath = $imageUploadData.data('serverPath')

        # Default
        trumbowygOptions =
          removeformatPasted: true
          autogrow: true
          hideButtonTexts: false
          semantic: false
          tagsToKeep: ['hr', 'img', 'embed', 'iframe', 'div']
          btnsDef: {
            align: {
              dropdown: ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
              ico: 'justifyLeft'
            }
          },
          btns: [
            ['viewHTML']
            ['formatting']
            ['align']
            ['horizontalRule']
            ['strong', 'em', 'del']
            ['link']
            ['unorderedList']
            ['upload']
            ['noembed']
          ]
          plugins:
            upload:
              serverPath: @serverPath
              data: [
                {
                 name: 'authenticity_token',
                 value: @$('input[name="authenticity_token"]').val()
                }
              ]
              fileFieldName: 'image'
              urlPropertyName: 'url'

        @$('.js-deal_page_section_body_input').trumbowyg(trumbowygOptions)
        @initialFormBody = @$('.js-deal_page_section_body_input').trumbowyg('html')
        @initiaFormTitle = @$('.js-deal_page_section_title_input').val()


      resetForm: ->
        @$('.js-deal_page_section_body_input').trumbowyg('html', @initialFormBody)
        @$('.js-deal_page_section_title_input').val(@initiaFormTitle)

      events:
        'submit .js-deal_page_section_form': (event) ->
          event.preventDefault()
          $button = @$('.js-deal_page_section_form_submit')
          return if $button.hasClass('c-button--loading')
          $button.addClass('c-button--loading')
          @$('.js-success').addClass 'u-hidden'

          @$('.js-deal_page_section_form').ajaxSubmit
            success: (response) =>
              $button.removeClass('c-button--loading').prop('disabled', false)
              @trigger('success', response.section_html)

            error: (response) =>
              $button.removeClass('c-button--loading').prop('disabled', false)
              if response.status == 422
                @$el.replaceWith(response.responseJSON.form_html)
              else
                Tooltips.formServerError($button)

        'click .js-deal_page_section_form_cancel': (event) ->
          @resetForm()
          @trigger('cancel')

    new FormView el: $this
