Partials = require('../../lib/partials')
Clipboard = require('clipboard')
Accounting = require('accounting')
require('@selectize/selectize')

window.Market.Entities.Wallets =
  customer_wallet: ($$, $this)->
    class CustomerWalletView extends Backbone.View
      initialize: ->
        @assetSymbol = @$('.js-customer-wallet-data').data('asset-symbol')
        @totalBalance = @$('.js-customer-wallet-data').data('total-balance')
        @usdSymbol = @$('.js-customer-wallet-data').data('usd-symbol')
        @_bindIndicativePricingWebSocket()

      _bindIndicativePricingWebSocket: ->
        Partials.with @$(Partials.selector 'web_sockets/rfq/indicative_pricing'), (partial) =>
          partial.on 'update', (message) =>
            if message[@assetSymbol]? && message[@assetSymbol][@usdSymbol]?
              @_updateAmount(@totalBalance*message[@assetSymbol][@usdSymbol].mid_price)

      _updateAmount: (amount) ->
        previousAmount = Accounting.unformat(@$('.js-total-amount-in-usd').text())
        @$('.js-total-amount-in-usd').text(Accounting.formatMoney(amount))

    new CustomerWalletView el: $this

  download_csv_transactions_form: ($$, $this) ->
    class DownloadCsvTransactionsForm extends Backbone.View
      initialize: ->
        $select = @$('.js-asset_symbols_select').selectize({
          plugins: ['remove_button']
        })
        @selectize = $select[0].selectize

      events: ->
        'click .js-selectize_add_all_assets':  (e) ->
          e.preventDefault()
          @$('.js-selectize-data').data('items').forEach (item) =>
            @selectize.addItem(item)

        'click .js-selectize_remove_all_assets':  (e) ->
          e.preventDefault()
          @$('.js-selectize-data').data('items').forEach (item) =>
            @selectize.removeItem(item)

        'submit .js-download_csv_form':  (e) ->
          e.preventDefault()

        'click .js-download_csv_form_submit':  (e) ->
          e.preventDefault()
          $target = @$(e.target)
          return if $target.hasClass('c-button--loading')
          $target.addClass('c-button--loading')

          @$('.js-download_csv_form').ajaxSubmit
            success: (response) =>
              $target.removeClass('c-button--loading')
              @$('.js-form_wrapper').hide()
              @$('.js-success_wrapper').show()
            error: (response) =>
              $target.removeClass('c-button--loading')

    new DownloadCsvTransactionsForm el: $this


  entity_selector: ($$, $this)->
    class EntitySelectView extends Backbone.View
      initialize: ->
        @entitiesData = @$el.data('entitiesData')
        @entityUrl = @$el.data('entityUrl')
        @$('.js-entity').selectize(SelectizePreset.renderHtml(@entitiesData))

      events: ->
        'change .js-entity': (e) ->
          if e.target.value == 'new'
            window.location.href = @entitiesData[e.target.value].url
            return

          $target = @$(e.target)
          $target.attr('disabled', 'disabled')
          $.ajax
            url: @entityUrl
            method: 'POST'
            data: {entity: e.target.value}
            success: (response) =>
              window.location.href = @entitiesData[e.target.value].url
            error: (response) =>
              window.location.href = @entitiesData[e.target.value].url

    new EntitySelectView el: $this


  entity_selector_simple: ($$, $this)->
    class EntitySelectView extends Backbone.View
      initialize: ->
        @action = @$el.data('action')
        @id = @$el.data('id')
        @$('.js-entity-select-input').selectize()

      _getEntitySlug: ->
        @$('.js-entity-select-input').val()

      events: ->
        'change .js-entity-select-input' : (e) ->
          window.location = "/#{@_getEntitySlug()}/wallets/#{@id}/#{@action}"

    new EntitySelectView el: $this

  send_to_pro: ($$, $this) ->
    class SendToProView extends Backbone.View
      LOADING_CLASS: 'c-button--loading'
      DISABLED_CLASS: 'c-button--disabled'

      initialize: ->
        @$amount = @$('.js-amount')
        @$amountError = @$('.js-amount-error')
        @$submitButton = @$('.js-submit')
        @$submitErrors = @$('.js-submit-errors')

        @balance = @$el.data('balance')
        @minWithdrawalAmount = @$el.data('min-withdrawal-amount')
        @decimalPrecision = @$el.data('decimal-precision')
        @inputErrorClass = @$el.data('input-error-class')

      _round: (amount) ->
        parseFloat(amount.toFixed(@decimalPrecision))

      _amount: ->
        amount = @$amount.val().replace(',', '')
        return null if !amount

        amount = parseFloat(amount)
        return 0.0 if isNaN(amount)
        @_round(amount)

      _validate: ->
        @$submitButton.addClass(@DISABLED_CLASS)
        @$amountError.hide()
        @$amount.removeClass(@inputErrorClass)

        amount = @_amount()

        return if !amount || amount < 0

        if amount > @balance
          @$amountError.text('Insufficient funds').show()
          @$amount.addClass(@inputErrorClass)
          return

        if amount < @minWithdrawalAmount
          @$amountError.text(
            "The minimum withdrawal amount is #{@_round(@minWithdrawalAmount)}"
          ).show()
          @$amount.addClass(@inputErrorClass)
          return

        @$submitButton.removeClass(@DISABLED_CLASS)

      events: ->
        'input .js-amount': ->
          @_validate()

        'submit': ->
          # turn off submit on enter key
          false

        'click .js-max-send-to-pro': ->
          @$amount.val(@balance)
          @_validate()

        'click .js-submit':  (e) ->
          $target = @$(e.target)
          return if $target.hasClass(@DISABLED_CLASS) || $target.hasClass(@LOADING_CLASS)

          $target.addClass(@LOADING_CLASS)
          @$submitErrors.hide()

          @$('form.js-submit-form').ajaxSubmit
            success: (response) =>

              @$('.js-success-section').show()
              @$('.js-form-section').hide()

            error: (response) =>
              if response.responseJSON
                $target.removeClass(@LOADING_CLASS)
                @$submitErrors.text(response.responseJSON.errors.message).fadeIn()
              else
                Tooltips.formServerError($target)


    new SendToProView el: $this
