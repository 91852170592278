import * as React from "react";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";

import makeStyles from '@mui/styles/makeStyles';
import Dialog from "@mui/material/Dialog";

import Flex from "@react/components/Flex";

const CONTAINER_PADDING = 20;

export default function ContactDialog(props) {
  const isSmall = useMediaQuery({ maxWidth: 900 });

  const useStyles = makeStyles(() => ({
    dialogPaper: {
      // height: 144,
      margin: isSmall ? 0 : 32,
      width: 350,
    },
  }));

  const classes = useStyles();
  const [copy, setCopy] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText("lend@coinlist.co");
    setCopy(true);
  };

  return (
    <Dialog
      classes={{
        paper: classes.dialogPaper,
      }}
      fullWidth
      onClose={props.onClose}
      open={props.open}
    >
      <Flex container style={{ padding: CONTAINER_PADDING }} spacing={1}>
        <Flex item xs={24}>
          <div className="u-fontWeight700">Contact Us</div>
        </Flex>
        <Flex item xs={24}>
          <Flex
            alignItems="center"
            container
            style={{ background: "#F4F4F4", borderRadius: 6 }}
          >
            <div className="s-padding0_5" style={{ display: "inline" }}>
              Please reach out to lend@coinlist.co
            </div>
            <i
              className={copy ? "icon-ok u-colorGreen" : "icon-docs"}
              onClick={handleCopy}
            ></i>
          </Flex>
        </Flex>
      </Flex>
    </Dialog>
  );
}
