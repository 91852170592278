window.Offerings.Manage.Shared =
  starred: ($$, $this) ->
    class OfferingsManageShared extends Backbone.View
      events:
        'click a.js-toggle-star': (event) ->
          event.preventDefault()
          event.stopPropagation()
          $target = $(event.currentTarget)

          $.ajax
            url: $target.attr('href'),
            type: 'PUT',
            success: (response) =>
              $target.parent().html(response.html)
    new OfferingsManageShared el: $this
