# Forms that use this are expected to define '.js-save',
# a save button for the multiple file upload form
#
# On success, this triggers two events:
#
# 1. 'replace', which passes in a method to replace content. The listening
#    partial should call that method and then rebind it's listeners.
#
# 2. 'completed', which tells listening partials that a successful save
#    is complete
#
require('jquery-form')
Partials = require('../lib/partials')

class FormWithMultipleFileUpload extends Backbone.View
  _replaceMultipleUploadForms: ->
    Partials.with Partials.selector('components/multiple_file_upload'), (partial) =>
      html = @multipleFileUploadForms[partial.uniqIndex()]
      if html.length != 0 && partial.$el.find('.js-form_error').length == 0
        html.find('.js-form_error').remove()
        partial.$el.replaceWith html

  _saveMultipleFileUploadsForms: ->
    @multipleFileUploadForms = {}
    Partials.with Partials.selector('components/multiple_file_upload'), (partial) =>
      @multipleFileUploadForms[partial.uniqIndex()] = partial.$el.clone()

  events:
    'click .js-save': (event) ->
      event.preventDefault()
      $target = $(event.target)
      return if $target.hasClass 'c-button--loading'
      $target.addClass 'c-button--loading'

      @_saveMultipleFileUploadsForms()

      $target.closest('form').ajaxSubmit
        success: (response) =>
          @trigger 'replace', =>
            @$el.replaceWith response.html
          @trigger 'completed'
        error: (response) =>
          $target.removeClass 'c-button--loading'
          if response.status == 422
            @_replaceMultipleUploadForms()
          else
            Tooltips.formServerError($target)

module.exports = FormWithMultipleFileUpload
