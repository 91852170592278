Partials = require('../../lib/partials')

window.Lend.LendingPrograms.Show =
  lending_form: ($$, $this) ->
    class LendingFormView extends Backbone.View
      LOADING_CLASS: 'c-button--loading'
      DISABLED_CLASS: 'c-button--disabled'

      initialize: ->
        @$amount = @$('.js-amount')
        @$amountError = @$('.js-amount-error')
        @$reviewButton = @$('.js-review')
        @$reviewErrors = @$('.js-review-errors')
        @$submitButton = @$('.js-submit')

        @balance = @$el.data('balance')
        @minAmount = @$el.data('min-wrap-amount')
        @decimalPrecision = @$el.data('decimal-precision')
        @inputErrorClass = @$el.data('input-error-class')

      _round: (amount) ->
        parseFloat(amount.toFixed(@decimalPrecision))

      _amount: ->
        amount = @$amount.val().replace(',', '')
        return 0 if !amount

        amount = parseFloat(amount)
        return 0.0 if isNaN(amount)
        @_round(amount)

      _clearInputs: ->
        @$amount.val('')

      _validate: ->
        @$reviewButton.addClass(@DISABLED_CLASS)
        @$amountError.hide()
        @$amount.removeClass(@inputErrorClass)

        amount = @_amount()

        return if amount == 0

        if amount > @_round(parseFloat(@balance))
          @$amountError.text('Insufficient funds').show()
          @$amount.addClass(@inputErrorClass)
          return

        if amount < @minAmount
          @$amountError.text(
            "The minimum amount is #{@minAmount}"
          ).show()
          @$amount.addClass(@inputErrorClass)
          return

        @$reviewButton.removeClass(@DISABLED_CLASS)

      events: ->
        'input .js-amount': ->
          @_validate()

        'click .js-review': (e) ->
          $target = @$(e.target)
          return if $target.hasClass(@DISABLED_CLASS)

          # fill fields for review
          @$('.js-review-amount').text(@_amount())

          # toggle content
          @$('.js-form-section').hide()
          @$('.js-submit-section').show()

        'click .js-back': ->
          @$reviewErrors.hide()

          @$('.js-form-section').show()
          @$('.js-submit-section').hide()

        'click .js-set-to-max': ->
          @$('.js-amount').val(@balance)
          @_validate()

        'click .js-submit':  (e) ->
          $target = @$(e.target)
          return if $target.hasClass(@DISABLED_CLASS) || $target.hasClass(@LOADING_CLASS)

          $target.addClass(@LOADING_CLASS)
          @$reviewErrors.hide()

          @$('form.js-submit-form').ajaxSubmit
            success: (response) =>
              @_clearInputs()
              @$('.js-hide-on-success').hide()
              @$('.js-show-on-success').show()

            error: (response) =>
              if response.responseJSON
                $target.removeClass(@LOADING_CLASS)
                @$reviewErrors.text(response.responseJSON.errors.message).fadeIn()
              else
                Tooltips.formServerError($target)

    new LendingFormView el: $this