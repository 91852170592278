import * as React from "react";
import { useEffect, useState } from "react";
import { getFormattedTime } from "@react/utils/date";
import WalletUnlink from "./WalletUnlink";
import { Entity, LinkedWallet, WalletLinkEntryPoint } from "./types";
import WalletLink from "./WalletLink";
import { walletLinkApi } from "./api";
import { filteredLinkedWallets, getEntityNameByEntityId } from "./utils";
import { Icon, Table } from "@react/components";

interface LinkedWalletsTableProps {
  entities: Entity[];
  linkedWallets: LinkedWallet[];
}

const LinkedWalletsTable: React.FC<LinkedWalletsTableProps> = ({
  entities,
  linkedWallets,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingError, setLoadingError] = useState<Error | undefined>(
    undefined
  );
  const [selectedEntity, setSelectedEntity] = useState<Entity | null>(null);
  const [userLinkedWallets, setUserLinkedWallets] = useState<
    LinkedWallet[] | null
  >(null); // linkedWallets are on the user level

  // initialize selectedEntity
  useEffect(() => {
    if (entities && entities.length) {
      setSelectedEntity(entities[0]);
    }
  }, [entities]);

  // initialize userLinkedWallets
  useEffect(() => {
    if (linkedWallets && linkedWallets.length) {
      setUserLinkedWallets(filteredLinkedWallets(linkedWallets));
    }
  }, [linkedWallets]);

  const handleEntitySelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newEntity: Entity = entities.find(
      (entity) => entity.slug === e.target.value
    );
    setSelectedEntity(newEntity);
  };

  const handleSuccess = (entityId: string) => {
    setIsLoading(true);
    setLoadingError(undefined);

    walletLinkApi()
      .getLinkedWalletsByEntity(entityId)
      .then((res) => {
        setUserLinkedWallets(res.linkedWallets);
      })
      .catch((err) => {
        console.error(err);
        setLoadingError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const rows =
    userLinkedWallets && userLinkedWallets.length
      ? userLinkedWallets.map((wallet) => {
          // If it's a number, it's the number of seconds from JS, and we need milliseconds for getFormattedTime
          // If it's a string, it was passed in as a DateTime string from props in the rails view ('2022-11-22T00:16:59.000Z')
          // and getFormattedTime can handle this
          const formattableTime: string | number =
            typeof wallet.updatedAt === "number"
              ? wallet.updatedAt * 1000
              : wallet.updatedAt;
          return [
            getEntityNameByEntityId(wallet.entity.id, entities),
            <span style={{ wordBreak: "break-word" }}>{wallet.address}</span>,
            <span className="u-colorGray8">
              {getFormattedTime(formattableTime)}
            </span>,
            <WalletUnlink
              address={wallet.address}
              chainId={wallet.chainId}
              entityId={wallet.entity.id}
              onSuccess={handleSuccess}
            />,
          ];
        })
      : [];

  const tableContent = () => {
    if (loadingError) {
      return (
        <p className="u-colorRed">
          {loadingError?.message ||
            "Hmmm, something went wrong, please try again later..."}
        </p>
      );
    }

    if (isLoading) {
      return (
        <div className="u-text-center">
          <Icon icon="spin6" />
        </div>
      );
    }

    if (rows.length) {
      return (
        <Table
          style="bordered"
          headers={["Entity", "Address", "Linked", ""]}
          headersGridSizes={[4, 12, 6, 2]}
          rows={rows}
        />
      );
    } else {
      if (userLinkedWallets) {
        return <p>You have not linked any wallet yet.</p>;
      }
    }

    return null;
  };

  return (
    <div className="c-box">
      <div className="s-paddingVert1 s-paddingHoriz1_5 c-box__separator u-displayFlex u-justifyContentSpaceBetween u-alignItemsCenter">
        <div className="s-fontSize16 u-fontWeight700">Linked Wallets</div>
        <div className="u-displayFlex u-alignItemsCenter">
          {/* only show the dropdown list if a user has more than 1 entities */}
          {entities?.length > 1 && (
            <select
              className="c-input--select c-input c-input--optional c-input--small s-marginRight1"
              value={selectedEntity ? selectedEntity.slug : ""}
              onChange={(e) => handleEntitySelect(e)}
            >
              {entities.map((entity) => (
                <option key={entity.id} value={entity.slug}>
                  {entity.name}
                </option>
              ))}
            </select>
          )}
          <WalletLink
            entity={selectedEntity}
            entryPoint={WalletLinkEntryPoint.ACCOUNT}
            onSuccess={handleSuccess}
          />
        </div>
      </div>
      <div className="s-padding1_5 s-fontSize14">{tableContent()}</div>
    </div>
  );
};

export default LinkedWalletsTable;
