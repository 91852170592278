window.Offerings.Manage.ParticipantEligibility =
  email_eligibility_form: ($$, $this) ->
    class EmailEligibilityFormView extends Backbone.View
      initialize: ->
        @$counter = @$('.js-counter')
        @email_count = @$counter.data('counter')

      events: ->
        'click .js-show_emails': (event) ->
          $eligible_emails = @$('.js-eligible_emails')
          @$(event.currentTarget).text(
            if $eligible_emails.is(':visible') then 'Show' else 'Hide'
          )
          $eligible_emails.slideToggle()

        'click .js-remove': (event) ->
          $target = $(event.target)
          if confirm("Are you sure you want to remove #{$target.data('email')}?")
            $target.closest('form').ajaxSubmit
              success: (response) =>
                @email_count -= 1
                $target.closest('.js-offerings_users_permission').slideUp()
                @$counter.text("#{@email_count} email#{if @email_count == 1 then '' else 's'}")
              error: (response) =>
                Tooltips.formServerError($target)

        'click .js-add_to_eligibility_list': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass 'c-button--loading'
          $target.addClass 'c-button--loading'

          $target.closest('form').ajaxSubmit
            success: (response) =>
              window.location.reload()
            error: (response) =>
              $target.removeClass 'c-button--loading'
              Tooltips.formServerError($target)

    new EmailEligibilityFormView el: $this
