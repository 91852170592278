Partials = require('../../lib/partials')

window.Offerings.Entities.Onboarding =

  requested_amount: ($$, $this) =>
    class RequestedAmountView extends Backbone.View
      updateRequestedAmount: ->
        deferred = $.Deferred()
        @$('.js-form').ajaxSubmit
          success: (response) =>
            @$el.replaceWith response.html
            deferred.resolve()
          error: (response) =>
            if response.status == 422
              @$el.replaceWith response.responseJSON.html
            deferred.reject(response)
        deferred.promise()

      events:
        'submit .js-form': (event) ->
          event.preventDefault()
          @updateRequestedAmount()

    new RequestedAmountView el: $this

  blockstack_onboarding: ($$, $this) ->
    class BlockstackOnboardingView extends Backbone.View
      initialize: ->
        @$button = @$('.js-get_started')
        @updateCta()

      updateCta: ->
        isChecked = @$('.js-new_york_state_resident_input').is(':checked')
        @$button.toggleClass('c-button--disabled', !isChecked)

      events:
        'change .js-new_york_state_resident_input': (event) ->
          @updateCta()

        'click .js-get_started': (event) ->
          event.preventDefault()
          return if @$button.hasClass('c-button--loading')
          @$button.addClass('c-button--loading')
          @trigger 'goToNextStep'

    new BlockstackOnboardingView el: $this

  props_onboarding: ($$, $this) ->
    class BlockstackOnboardingView extends Backbone.View
      initialize: ->
        $data = @$('.js-data')
        @accreditedCtaText = $data.data('accreditedCtaText')
        @unaccreditedCtaText = $data.data('unaccreditedCtaText')
        @$button = @$('.js-get_started')

        @$button.removeAttr('data-disable-with')

      updateCta: ->
        isChecked = @$('.js-accredited_input').is(':checked')
        if isChecked
          @$button.removeClass('u-hidden')
          @$button.toggleClass('c-button--disabled', !isChecked)
          accredited = @$('.js-accredited_input:checked').val()
          if accredited == 'true'
            @$button.val(@accreditedCtaText)
            @$('.js-unaccredited_message').hide()
          else if accredited == 'false'
            @$button.val(@unaccreditedCtaText)
            @$('.js-unaccredited_message').show()

      showUnaccreditedSuccess: ->
        @$('.js-accreditation_content').addClass('u-hidden')
        @$('.js-success_message').removeClass('u-hidden')

      events:
        'change .js-accredited_input': (event) ->
          @updateCta()

        'submit .js-form': (event) ->
          event.preventDefault()
          $form = $(event.target)

          return if @$button.hasClass('c-button--loading') || @$button.prop('disabled')

          @$button.addClass('c-button--loading')

          $form.ajaxSubmit
            success: (response) =>
              if response.accredited
                @trigger 'goToNextStep'
              else
                @showUnaccreditedSuccess()
            error: (response) =>
              if response.status == 422
                @$el.replaceWith response.responseJSON.html
              else
                Tooltips.formServerError(@$button)

    new BlockstackOnboardingView el: $this