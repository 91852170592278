import * as React from "react";

import Flex from "@react/components/Flex";

interface TableProps {
  headCells: Array<string>;
  rows: Array<any>;
  style?: any;
  title?: string;
}

export default function Table(props: TableProps) {
  return (
    <Flex container style={props.style}>
      <div className="c-box" style={{ width: "100%" }}>
        <div className="c-box__separator s-padding1">
          <div className="s-fontSize18 u-fontWeight700">
            <Flex container>
              <Flex container>{props.title}</Flex>
            </Flex>
          </div>
        </div>
        <Flex container justifyContent="space-between" style={{ padding: 16 }}>
          {props.headCells.map((label, index) => (
            <Flex
              item
              key={`${label}-${index}`}
              xs={24 / props.headCells.length}
            >
              <div className={"c-label s-fontSize12 u-colorGray9"}>{label}</div>
            </Flex>
          ))}
          {props.rows.map((row) =>
            row.map((element, index, arr) => (
              <Flex
                item
                key={`item-${index}`}
                xs={24 / arr.length}
                style={{ overflow: "hidden" }}
              >
                <span>{element}</span>
              </Flex>
            ))
          )}
        </Flex>
      </div>
    </Flex>
  );
}
