import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: any) => {
  return {
    paper: {
      display: "flex",
      minHeight: "80%",
      width: "80%",
      padding: 8,
      alignContent: "center",
      alignSelf: "center",
    },
    resultDialogContent: {
      padding: 8,
    },
    paperHeader: {
      display: "block",
      height: "2em",
    },
    formInputWrapper: {
      alignContent: "center",
      alignSelf: "center",
    },
    submitButtonContainer: {
      paddingTop: 8,
    },
    submitButton: {
      color: "white",
    },
    typographyPaperHeader: {
      color: "#666666",
    },
    paperContent: {
      padding: 8,
    },
  };
});
