import { createTheme, adaptV4Theme } from "@mui/material/styles";

const MAIN_FONT_FAMILY = '"Akkurat", "Helvetica Neue", sans-serif;';
/* Taken from: node_modules/@coinlist/css-framework/sass/_global_variables.sass */
export const PRIMARY_COLOR = "#F03393";
export const SECONDARY_COLOR = "#513E63";

export const theme = createTheme(adaptV4Theme({
  custom: {
    coloredButtonRoot: {
      background: PRIMARY_COLOR,
      color: "white",
      //   fontWeight: 700,
      "&:focus": {
        color: "white",
      },
      "&:hover": {
        background: PRIMARY_COLOR,
      },
    },
  },
  overrides: {
    MuiButton: {
      label: {
        textTransform: "none",
      },
    },
    MuiButtonBase: {
      root: {
        background: "transparent",
        /* All pseudo classes: https://developer.mozilla.org/en-US/docs/Web/CSS/Pseudo-classes#user_action_pseudo-classes */
        "&:active": {
          background: "transparent",
        },
        "&:focus": {
          /* https://material-ui.com/customization/palette/ */
          color: "black",
        },
        "&:hover": {
          background: "transparent",
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        color: "#9FA0A5",
        fontFamily: "inherit",
        fontSize: 14,
        fontWeight: 700,
        textTransform: "uppercase",
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 2,
      },
    },
    MuiFormLabel: {
      root: {
        textTransform: "none",
      },
    },
    MuiInputBase: {
      input: {
        fontSize: "inherit",
        letterSpacing: "inherit",
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: "inherit",
        letterSpacing: "inherit",
      },
    },
  },
  palette: {
    primary: {
      main: PRIMARY_COLOR,
    },
  },
  /**
   * Default theme: https://material-ui.com/customization/default-theme/?expand-path=$.typography
   * Typography customization: https://material-ui.com/customization/typography/
   */
  typography: {
    htmlFontSize: 16,
    fontFamily: MAIN_FONT_FAMILY,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
} as any));
