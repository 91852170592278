import * as React from "react";

import Box from "@react/components/Box";
import Flex from "@react/components/Flex";
import { Link } from "@react/components";
import Typography from "@react/components/typography/Typography";
import { hasFullSidebarButSmall } from "@react/utils";

export default function Calendar(props) {
  const fullSideBarButSmall = hasFullSidebarButSmall();
  return (
    <Box
      onClick={props.onClick}
      style={{
        cursor: "pointer",
        marginBottom: 16,
        padding: 16,
        width: "100%",
      }}
    >
      <Flex alignItems="center" container>
        <Flex item xs={16}>
          <Flex container spacing={1}>
            <Flex item xs={24}>
              <Typography bold>Upcoming Events</Typography>
            </Flex>
            <Flex item xs={24}>
              <Typography bold color="gray" fontSize={13}>
                Stay up to date with {props.tokenProfile.name}'s upcoming events
                and ecosystem launches.
              </Typography>
            </Flex>
            <Flex item xs={24}>
              <Link
                fontWeight={700}
                fontSize={13}
                onClick={() =>
                  window.open(props.tokenProfile.calendar_url, "_blank").focus()
                }
              >
                View calendar
              </Link>
            </Flex>
          </Flex>
        </Flex>
        {!fullSideBarButSmall && props.loggedInUser && (
          <Flex item justifyContent="flex-end" xs={8}>
            <i
              className={`icon-calendar`}
              style={{ color: "black", fontSize: 50 }}
            ></i>
          </Flex>
        )}
      </Flex>
    </Box>
  );
}
