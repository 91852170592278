import * as React from "react";
import cx from "classnames";
import { Field, Label, Error, Hint } from "@react/components";

export interface BlockFieldProps {
  label?: React.ReactNode;
  name?: string;
  error?: React.ReactNode;
  hint?: React.ReactNode;
  children?: React.ReactNode;
}

export const BlockField: React.FunctionComponent<BlockFieldProps> = ({
  children,
  label,
  name,
  error,
  hint,
}) => {
  return (
    <Field error={Boolean(error)}>
      {label && <Label htmlFor={name}>{label}</Label>}
      {children}
      {!error && hint && <Hint>{hint}</Hint>}
      {error && <Error>{error}</Error>}
    </Field>
  );
};

export default BlockField;
