export const COLOR_MAP = {
  brandBlue: "#179CEE",
  brandGreen: "#63da68",
  brandRed: "#FF3D00",
  gray: "#bfbfbf",
  grayLighter: "#EEEEEE",
  grayLightest: "#FAFAFA",
  grayDark: "#666666",
  grayDarker: "#333333",
  primary: "#000000",
  white: "#FFFFFF",
};

export function hexToRGB(hex, alpha) {
  var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
  }
};