require 'magnific-popup'

window.Accreditation.AutoAccreditation = {
  form: ($$, $this) ->
    class FormView extends Backbone.View
      initialize: ->
        $('.js-add-crypto-wallet').magnificPopup({
          type: 'ajax'
        })

      events:
        'click .js-add-crypto-wallet': (event) ->
          event.preventDefault()
          $('.js-add-crypto-wallet').magnificPopup({
            type: 'ajax'
          })

    new FormView el: $this
}
