exports.RequestForQuoteCalculations =
  class RequestForQuoteCalculations
    constructor: (@side, @baseAssetSymbol, @counterAssetSymbol, @feeRate, @feeMinimum,
                  @primaryAssetSymbol, @primaryAssetAmount, @indicativePrice) ->
    BUY: 'buy'
    SELL: 'sell'

    # this is a rough estimate of the market maker spread to mid
    # quotable has statistics on this, we could make this from quotable and per pair specific etc
    ESTIMATE_OF_MARKET_MAKER_SPREAD_TO_MID: 0.0025

    calculateFee: (counterAssetAmount) ->
      Math.max(@feeMinimum, counterAssetAmount * @feeRate)

    estimateMarketMakerUnitOfferPrice: ->
      @indicativePrice * (1 + @ESTIMATE_OF_MARKET_MAKER_SPREAD_TO_MID)

    estimateMarketMakerUnitBidPrice: ->
      @indicativePrice * (1 - @ESTIMATE_OF_MARKET_MAKER_SPREAD_TO_MID)

    # ETH-USD, Buy, User enters 0.5 ETH, and their fee rate is 50 bps the steps are:
    # 1. Estimate what the market maker will sell ETH for, expressed as a unit price, i.e. a
    # spread from the market mid price, e.g. $250 is market rate so MM would sell it at $251.25
    # 2. Estimate the market maker's quote based on the specified amount of the base asset
    # 3. Add the fee to the marker maker quote to determine the $ the user will pay
    estimateCounterAssetWhenPrimaryAssetIsBaseAssetForBuyOrder: ->
      estimatedMarketMakerQuote = @estimateMarketMakerUnitOfferPrice() * @primaryAssetAmount
      estimatedCounterAssetAmountForBuyOrder =
        estimatedMarketMakerQuote + @calculateFee(estimatedMarketMakerQuote)
      estimatedCounterAssetAmountForBuyOrder

    # ETH-USD, Sell, User enters 0.5 ETH, and their fee rate is 50 bps the steps are:
    # 1. Estimate what the market maker will buy ETH for, expressed as a unit price, i.e. a
    # spread from the market mid price, e.g. $250 is market rate so MM would buy it at $248.75
    # 2. Estimate the market maker's quote based on the specified amount of the base asset
    # 3. Subtract the fee from the marker maker quote to determine the $ user will receive
    estimateCounterAssetWhenPrimaryAssetIsBaseAssetForSellOrder: ->
      estimatedMarketMakerQuote = @estimateMarketMakerUnitBidPrice() * @primaryAssetAmount
      estimatedCounterAssetAmountForSellOrder =
        estimatedMarketMakerQuote - @calculateFee(estimatedMarketMakerQuote)
      estimatedCounterAssetAmountForSellOrder

    # ETH-USD, Sell, User enters they want to sell ETH for $100, and their fee rate
    # is 50bps (0.005) the steps are:
    # 1. Estimate what the market maker will buy ETH for, expressed as a unit price,
    # using an estimated spread from the market mid price, e.g. $250 is the market rate so
    # MM would buy at $248.75 === (1 - 0.0005) * 250.
    # 2. Calculate the counter asset amount post fees. The market maker is asked to quote on an
    # amount more than the $ specified by the user, the difference is a fee. In this example the
    # market maker is asked to quote on $100.50, the fee is 50 cents as the fee rate is 50 bps,
    # i.e. 0.005 * 100 = $0.50
    # 3. Use results from 1. & 2. to estimate required ETH i.e. 100.50 / 248.75 $/ETH = 0.404 ETH
    estimateBaseAssetWhenPrimaryAssetIsCounterAssetForSellOrder: ->
      primaryAssetAmountAfterFees = @primaryAssetAmount + @calculateFee(@primaryAssetAmount)
      estimatedBaseAssetAmountForSellOrder =
        primaryAssetAmountAfterFees / @estimateMarketMakerUnitBidPrice()
      estimatedBaseAssetAmountForSellOrder

    # ETH-USD, Buy, User enters they want to buy ETH with $100, and their fee rate
    # is 50bps (0.005) the steps are:
    # 1. Estimate what the market maker will sell ETH for, expressed as a unit price,
    # i.e. a spread from the market mid price, e.g. $250 is the market rate so MM would buy at
    # $251.25 i.e. (1 - 0.0005) * 250.
    # 2. Calculate the counter asset amount post fees. The market maker is asked to quote on an
    # amount less than the $ specified by the user, the difference is a fee. In this example the
    # market maker is asked to quote on $99.50, the fee is 50 cents as the fee rate is 50 bps,
    # i.e. 0.005 * 100 = $0.50
    # 3. Use results from 1. & 2. to estimate required ETH i.e. 99.50 / 251.25 $/ETH = 0.396 ETH
    estimateBaseAssetWhenPrimaryAssetIsCounterAssetForBuyOrder: ->
      primaryAssetAmountAfterFees = @primaryAssetAmount - @calculateFee(@primaryAssetAmount)
      estimatedBaseAssetAmountForBuyOrder =
        primaryAssetAmountAfterFees / @estimateMarketMakerUnitOfferPrice()
      estimatedBaseAssetAmountForBuyOrder

    primaryAssetIsBaseAsset: ->
      @primaryAssetSymbol == @baseAssetSymbol

    primaryAssetIsCounterAsset: ->
      @primaryAssetSymbol == @counterAssetSymbol

    isBuy: ->
      @side == @BUY

    isSell: ->
      @side == @SELL

    getSecondaryAssetAmount: ->
      if @primaryAssetAmount == 0
        return 0

      if @primaryAssetIsBaseAsset()
        if @isBuy()
          secondaryAssetAmount = @estimateCounterAssetWhenPrimaryAssetIsBaseAssetForBuyOrder()
        else
          secondaryAssetAmount = @estimateCounterAssetWhenPrimaryAssetIsBaseAssetForSellOrder()
      else if @primaryAssetIsCounterAsset()
        if @isBuy()
          secondaryAssetAmount = @estimateBaseAssetWhenPrimaryAssetIsCounterAssetForBuyOrder()
        else
          secondaryAssetAmount = @estimateBaseAssetWhenPrimaryAssetIsCounterAssetForSellOrder()
      Math.max(0, secondaryAssetAmount)

    calculateSellAssetAmount: ->
      if @isBuy()
        @calculateSellAssetAmountForBuyOrder()
      else
        @calculateSellAssetAmountForSellOrder()

    # if the User is specifying the counter asset, then they just need the specified amount
    # of the counter asset, e.g
    # ETH-USD, Buy, User enters $100, then they need >=$100 balance
    # if the User is specifying the base asset then we need to estimate the amount required.
    calculateSellAssetAmountForBuyOrder: ->
      if @primaryAssetIsCounterAsset()
        @primaryAssetAmount
      else if @primaryAssetIsBaseAsset()
        @estimateCounterAssetWhenPrimaryAssetIsBaseAssetForBuyOrder()

    # if the User is specifying the base asset, then they just need the specified amount
    # of the base asset, e.g
    # ETH-USD, Sell, User enters 0.2 ETH, then they need >=0.2 ETH balance
    # if the User is specifying the counter asset then we need to estimate the amount required.
    calculateSellAssetAmountForSellOrder: ->
      if @primaryAssetIsBaseAsset()
        @primaryAssetAmount
      else if @primaryAssetIsCounterAsset()
        @estimateBaseAssetWhenPrimaryAssetIsCounterAssetForSellOrder()

    getMarketMakerCounterAssetAmount: (counterAssetAmount) ->
      if @side == @BUY
        maket_maker_counter_asset_amount = counterAssetAmount - @calculateFee(counterAssetAmount)
      else
        maket_maker_counter_asset_amount = counterAssetAmount + @calculateFee(counterAssetAmount)
      maket_maker_counter_asset_amount

    getMinimumCounterAssetAmountAfterFees:(minimumCounterAssetAmount) ->
      if @side == @BUY
        minimumCounterAssetAmount + @calculateFee(minimumCounterAssetAmount)
      else
        minimumCounterAssetAmount - @calculateFee(minimumCounterAssetAmount)

    getMaximumCounterAssetAmountAfterFees:(maximumCounterAssetAmount) ->
      if @side == @SELL
        maximumCounterAssetAmount / (1 + @feeRate)
      else
        maximumCounterAssetAmount / (1 - @feeRate)