window.Admin.Entities.Show =
  overview: ($$, $this) ->
    class OverviewView extends Backbone.View
      LOADING_CLASS = 'c-button--loading'

      events:
        'click .js-refresh_kyc_status_admin_entity': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass LOADING_CLASS

          $target.addClass LOADING_CLASS
          $.ajax
            url: $target.attr('href')
            type: 'POST'
            success: (response) ->
              $this.replaceWith(response.html)

    new OverviewView el: $this

  test_settings: ($$, $this) ->
    class TestSettingsView extends Backbone.View
      LOADING_CLASS = 'c-button--loading'

      events:
        'click .js-verify_kyc_aml_as_test_account': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass LOADING_CLASS

          $target.addClass LOADING_CLASS
          $.ajax
            url: $target.attr('href')
            type: 'POST'
            success: (response) ->
              $this.replaceWith(response.html)

        'click .js-verify_msb_as_test_account': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass LOADING_CLASS

          $target.addClass LOADING_CLASS
          $.ajax
            url: $target.attr('href')
            type: 'POST'
            success: (response) ->
              $this.replaceWith(response.html)

        'click .js-verify_accreditation_as_test_account': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass LOADING_CLASS

          $target.addClass LOADING_CLASS
          $.ajax
            url: $target.attr('href')
            type: 'POST'
            success: (response) ->
              $this.replaceWith(response.html)

        'submit .js-fund-wallet-form': (event) ->
          event.preventDefault()
          event.stopPropagation()

          $form = $(event.target)
          $button = $form.find('.js-button')

          return if $button.hasClass 'c-button--loading'
          $button.addClass 'c-button--loading'

          $form.ajaxSubmit
            success: (response) =>
              window.location.href = response.redirect
            error: (response) =>
              $button.removeClass 'c-button--loading'

    return new TestSettingsView el: $this

  wallet_trading_ui_settings: ($$, $this) ->
    class WalletTradingUISettingsView extends Backbone.View
      HIDDEN_CLASS = 'u-hidden'
      DISABLED_CLASS = 'c-button--disabled'
      LOADING_CLASS = 'c-button--loading'

      initialize: ->
        @rfqFeatureEnabled = @$el.data('rfq-feature-enabled')
        @proFeatureEnabled = @$el.data('pro-feature-enabled')
        @withdrawFeatureEnabled = @$el.data('withdraw-feature-enabled')
        @depositFeatureEnabled = @$el.data('deposit-feature-enabled')
        @waitingForZeroBalance = @$el.data('waiting-for-zero-balance')

        if @rfqFeatureEnabled
          @$('.js-turn_on_rfq_ui').addClass DISABLED_CLASS
        else
          @$('.js-turn_off_rfq_ui').addClass DISABLED_CLASS

        if @proFeatureEnabled
          @$('.js-turn_on_pro_ui').addClass DISABLED_CLASS
        else
          @$('.js-turn_off_pro_ui').addClass DISABLED_CLASS

        if @withdrawFeatureEnabled
          @$('.js-turn_on_withdraw_ui').addClass DISABLED_CLASS
        else
          @$('.js-turn_off_withdraw_ui').addClass DISABLED_CLASS

        if @depositFeatureEnabled
          @$('.js-turn_on_deposit_ui').addClass DISABLED_CLASS
        else
          @$('.js-turn_off_deposit_ui').addClass DISABLED_CLASS

        if @waitingForZeroBalance
          @$('.js-unapprove_after_zero_balance').addClass DISABLED_CLASS
          @$('.js-show_unapprove_after_zero_balance_date_picker').addClass HIDDEN_CLASS

      _submit: (event, cb=null) ->
        event.preventDefault()
        $target = $(event.target)
        return if $target.hasClass LOADING_CLASS

        $target.addClass LOADING_CLASS
        $.ajax
          url: $target.attr('href')
          type: 'POST'
          success: (response) ->
            $this.replaceWith(response.html)
            cb?()

      events:
        'click .js-turn_on_rfq_ui': (event) ->
          @_submit(event)

        'click .js-turn_off_rfq_ui': (event) ->
          @_submit(event)

        'click .js-turn_on_pro_ui': (event) ->
          @_submit(event)

        'click .js-turn_off_pro_ui': (event) ->
          @_submit(event)

        'click .js-turn_on_withdraw_ui': (event) ->
          @_submit(event)

        'click .js-turn_off_withdraw_ui': (event) ->
          @_submit(event)

        'click .js-turn_on_deposit_ui': (event) ->
          @_submit(event)

        'click .js-turn_off_deposit_ui': (event) ->
          @_submit(event)

        'click .js-unapprove_after_zero_balance': (event) ->
          @_submit(event)

        'click .js-flush_pro_funds': (event) ->
          @_submit(event, ->
            @$('.js-flush_pro_funds_success').removeClass HIDDEN_CLASS
          )

        'click .js-deunapprove_after_zero': (event) ->
          @_submit(event)

    return new WalletTradingUISettingsView el: $this

  karma: ($$, $this) ->
    class ActivityTrackerViewModel extends Backbone.View
      LOADING_CLASS = 'c-button--loading'

      events:
        'submit .js-karma-form': (event) ->
          event.preventDefault()
          event.stopPropagation()

          $form = $(event.target)
          $button = $form.find('.js-button')

          return if $button.hasClass 'c-button--loading'
          $button.addClass 'c-button--loading'
          @$('.js-errors').slideUp()

          $form.ajaxSubmit
            success: (response) =>
              window.location.href = response.redirect
            error: (response) =>
              $button.removeClass 'c-button--loading'
              error_message = response.responseJSON?.error_message
              if error_message
                @$('.js-errors').text(error_message).slideDown()
              else
                Tooltips.formServerError($target)

        'click .js-refresh_activity_tracker_admin_entity': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass LOADING_CLASS

          $target.addClass LOADING_CLASS
          $.ajax
            url: $target.attr('href')
            type: 'POST'
            success: (response) ->
              $this.replaceWith(response.html)

    new ActivityTrackerViewModel el: $this
