window.Offerings.Participants.AccountUsage =
  form: ($$, $this) ->
    class FormView extends Backbone.View
      events: ->
        'click .js-submit': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass 'c-button--loading'
          $target.addClass 'c-button--loading'

          @$('form').ajaxSubmit
            success: (response) =>
              window.location.href = response.redirect
            error: (response) =>
              if response.status == 422
                @$el.replaceWith(response.responseJSON.html)
              else
                Tooltips.formServerError($target)

    new FormView el: $this
