# globally expose jQuery
window.$ = window.jQuery = require('jquery')
# this patches jQuery to send CSRF parameters to Rails automatically
require('jquery-ujs')
s = require('underscore.string')

requireTest = require.context('.', true, /\.coffee$/)
for key in requireTest.keys()
  try
    requireTest key
  catch e
    console.log 'Failed to import file ' + key
    throw e

window.regulator = new Regulator (name, el) =>
# When we see foo/bar, we convert to window.Foo['bar']
  directories = name.split('/')
  file = directories.pop()
  path = _.map directories, (d) -> s(d).classify().value()

  root = window
  root = root?[name] for name in path

  $$ = (selector) -> $(el).find selector
  root?[file]?.call el, $$, $(el)

window.regulator.observe()

# Tooltips
Tooltips.initialize()
CopyToClipboardInput.initialize()
MoneyInput.initialize()
PrecisionPriceInput.initialize()

SocialMediaSharePopup.initialize()
