window.Index.TrusttokenIndex =
  risks: ($$, $this) =>
    class RisksView extends Backbone.View
      events:
        'click .js-expandable': (event) ->
          @$(event.target).closest('.js-risk').find('.js-risk-content').slideToggle('fast')

        'click .js-show-all': ->
          @$('.js-show-all').hide()
          @$('.js-extra-risks').slideDown(2000)


    new RisksView el: $this
