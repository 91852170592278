# Because JS s*cks
# https://stackoverflow.com/questions/11832914/round-to-at-most-2-decimal-places-only-if-necessary

window.NumberHelper =
  round: (number, places) ->
    +(Math.round(number + 'e+' + places) + 'e-' + places)

  floor: (number, places) ->
    +(Math.floor(number + 'e+' + places) + 'e-' + places)

  decimalPlaces: (n) ->
    # Make sure it is a number and use the builtin number -> string.
    s = '' + +n
    # Pull out the fraction and the exponent.
    match = /(?:\.(\d+))?(?:[eE]([+\-]?\d+))?$/.exec(s)
    # NaN or Infinity or integer.
    # We arbitrarily decide that Infinity is integral.
    if !match
      return 0
    # Count the number of digits in the fraction and subtract the
    # exponent to simulate moving the decimal point left by exponent places.
    # 1.234e+2 has 1 fraction digit and '234'.length -  2 == 1
    # 1.234e-2 has 5 fraction digit and '234'.length - -2 == 5
    Math.max 0, (if match[1] == '0' then 0 else (match[1] or '').length) - (match[2] or 0)