Partials = require('../lib/partials')

window.Market.Dashboard =
  portfolio: ($$, $this) ->
    class PortfolioView extends Backbone.View
      initialize: ->
        Partials.with Partials.selector('market/dashboard/sidebar/entity_selector'), (partial) =>
          partial.on 'entityChange', (data) =>
            if data.url == '/wallets/new'
              window.location.pathname = data.url
            else
              @loadPortfolio(data.portfolio_url)

      loadPortfolio: (portfolioUrl) ->
        $.ajax
          url: portfolioUrl,
          method: 'GET',
          success: (response) =>
            @$el.replaceWith(response.html)

    new PortfolioView el: $this

  sidebar: ($$, $this) ->
    class SidebarView extends Backbone.View
      initialize: ->
        @loadWalletsBalances(@$('.js-wallets_balances_ajax_load').data('url'))
        @loadTokenSales(@$('.js-token_sales_ajax_load').data('url'))

        Partials.with Partials.selector('market/dashboard/sidebar/entity_selector'), (partial) =>
          partial.on 'entityChange', (data) =>
            if data.url == '/wallets/new'
              window.location.pathname = data.url
            else
              @loadWalletsBalances(data.wallets_balances_url)
              @loadTokenSales(data.token_sales_url)

      loadWalletsBalances: (entityBalanceUrl) ->
        @$('.js-wallets_balances_ajax_load').show()
        @$('.js-wallets_balances_wrapper').hide()
        if (entityBalanceUrl?)
          $.ajax
            url: entityBalanceUrl,
            method: 'GET',
            success: (response) =>
              @$('.js-wallets_balances_wrapper').html(response.html).show()
              @$('.js-wallets_balances_ajax_load').hide()

      loadTokenSales: (tokenSalesurl) ->
        @$('.js-token_sales_ajax_load').show()
        @$('.js-token_sales_wrapper').hide()
        if (tokenSalesurl?)
          $.ajax
            url: tokenSalesurl,
            method: 'GET',
            success: (response) =>
              @$('.js-token_sales_wrapper').html(response.html).show()
              @$('.js-token_sales_ajax_load').hide()

    new SidebarView el: $this
