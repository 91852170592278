import $ from "jquery";
import MarkdownIt from "markdown-it";

export const renderMarkdown = (content: string) => {
  const md = new MarkdownIt();

  // Override link to target_blank
  var defaultRender =
    md.renderer.rules.link_open ||
    function (tokens, idx, options, env, self) {
      return self.renderToken(tokens, idx, options);
    };

  md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
    // If you are sure other plugins can't add `target` - drop check below
    var aIndex = tokens[idx].attrIndex("target");

    if (aIndex < 0) {
      tokens[idx].attrPush(["target", "_blank"]); // add new attribute
    } else {
      tokens[idx].attrs[aIndex][1] = "_blank"; // replace value of existing attr
    }

    // pass token to default renderer.
    return defaultRender(tokens, idx, options, env, self);
  };

  const contentHtml = md.render(content);

  return contentHtml;
};

// Hackerman
export const renderTextFromMarkdown = (content: string) => {
  const $html = $(renderMarkdown(content));
  return $html.text();
};
