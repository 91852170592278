import * as React from "react";
import cx from "classnames";
import { formatCryptoMoney, getAmount } from "@react/utils/money";
import { Progress } from "@react/components";

export interface SnapshotResultsProps {
  proposal: any;
  proposal_results: any;
}

const SnapshotResults: React.FunctionComponent<SnapshotResultsProps> = ({
  proposal,
  proposal_results,
}) => {
  const totalVotesResultsAmount = Object.keys(proposal_results)
    .map((choiceValue: string, index: any) => {
      const result = proposal_results[choiceValue];
      return getAmount(result);
    })
    .reduce((partialSum, a) => partialSum + a, 0);

  const renderResult = (choiceValue: string, index: any) => {
    const result = proposal_results[choiceValue];
    const amount = getAmount(result);
    const percentage = ((amount / totalVotesResultsAmount) * 100).toFixed(2);
    const rowClass = cx({
      "s-marginTop1": index != 0,
    });

    return (
      <div key={index} className={rowClass}>
        <p className="u-displayFlex s-marginBottom0_5 s-fontSize14">
          <b className="u-flex1 u-fontWeight700">{choiceValue}</b>
          {result && (
            <>
              <span>{formatCryptoMoney(result)}</span>
              <span className="s-marginLeft0_5 u-colorGray8 ">
                {percentage}%
              </span>
            </>
          )}
        </p>
        <Progress
          progress={Math.round((amount / totalVotesResultsAmount) * 100)}
        />
      </div>
    );
  };

  return <div>{Object.keys(proposal_results).map(renderResult)}</div>;
};

export default SnapshotResults;
