import * as React from "react";
import { ampli } from '../../../../ampli'
import { KarmaSidebar as Sidebar } from "@coinlist/frontend-ui-lib"
require("@coinlist/frontend-ui-lib/dist/style.css");

export default function KarmaSidebar(props) {
    return (
        <Sidebar amplitudeEvent={(event, data) => ampli.track(event, undefined, data)} entityId={props.entityId} apiUrl={"/karma"} />
    );
}
