import * as React from "react";

const AccountBadgeRightArrowIcon: React.FC<{ fill: string }> = ({ fill }) => {
  return (
    <svg
      width="7"
      height="12"
      viewBox="0 0 7 12"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.41992 5.76953C6.41992 5.64844 6.39648 5.53711 6.34961 5.43555C6.30273 5.33398 6.23242 5.23633 6.13867 5.14258L1.63281 0.730469C1.48047 0.582031 1.29492 0.507812 1.07617 0.507812C0.935547 0.507812 0.804688 0.542969 0.683594 0.613281C0.5625 0.683594 0.464844 0.779297 0.390625 0.900391C0.320312 1.01758 0.285156 1.14844 0.285156 1.29297C0.285156 1.51172 0.367188 1.70312 0.53125 1.86719L4.54492 5.76953L0.53125 9.67188C0.367188 9.83984 0.285156 10.0312 0.285156 10.2461C0.285156 10.3945 0.320312 10.5273 0.390625 10.6445C0.464844 10.7617 0.5625 10.8555 0.683594 10.9258C0.804688 10.9961 0.935547 11.0312 1.07617 11.0312C1.29492 11.0312 1.48047 10.957 1.63281 10.8086L6.13867 6.39648C6.23633 6.30273 6.30664 6.20508 6.34961 6.10352C6.39648 5.99805 6.41992 5.88672 6.41992 5.76953Z" />
    </svg>
  );
};

export default AccountBadgeRightArrowIcon;
