import * as React from "react";
import { useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormHelperText from "@mui/material/FormHelperText";

import Loading, { RequestData } from "@react/components/Loading";
import Flex from "@react/components/Flex";
import Typography from "@react/components/typography/Typography";
import {
  TextInput,
  Field,
  Select,
  Label,
  Error,
  Link,
  Button,
  Divider,
} from "@react/components";
import BlockField from "@react/views/shared/forms/BlockField/BlockField";
import InputWithAddon from "@react/views/shared/forms/InputWithAddon/InputWithAddon";
import Switch from "@react/components/Switch";

import { isTestEnv } from "@react/utils";
import {
  changeMonth,
  getDaysForMonthArr,
  getAllYearsArr,
  getAllMonthsArr,
  getYearFromDate,
  getMonthFromDate,
  getDayFromDate,
  changeYear,
  changeDay,
} from "@react/utils/date";
import { RequestType } from "@react/utils/network";
import { COLOR_MAP } from "@react/utils/color";

interface CollateralAsset {
  [key: string]: string;
}
export interface StateObject {
  acceptable_assets: Array<any>;
  collateral_required: boolean;
  loan_amount_subunit: string;
  loan_asset_symbol: string;
  collateral_assets: CollateralAsset;
  collateral_requirement: string;
  entity_api_id: string;
  interest_payment_schedule: string;
  interest_rate: string;
  lending_program_id?: string;
  length_in_days: string;
  margin_call_level: string;
  open_term: boolean;
  over_collateralized_level: string;
  state: string;
  start_date: Date;
  void_past_interest_payments: boolean;
}
interface LoanInput {
  id: string;
  label: string;
  labelWidth?: number;
  menuItems?: Array<any>;
  onChangeHandler?: Function;
  type: string;
}
interface LoanDialogProps {
  addLoanUrl: string;
  collateralAssetSymbols: Array<string>;
  editLoanUrl: string;
  existingData?: any;
  handleClose: () => void;
  interestPaymentOptions: Array<any>;
  loanAssetSymbols: Array<string>;
  lendingPrograms: Array<any>;
  open: boolean;
  possibleStates: Array<string>;
  previousEntities?: Array<any>;
}

const ENTITY_TEXTFIELDS = [
  {
    id: "entity_api_id",
    label: "New Entity API ID",
    type: "textfield",
  },
  {
    id: "linked_entity_api_id",
    label: "Linked Entity API ID (Optional)",
    type: "textfield",
  },
];
const COLLATERAL_TEXTFIELDS = [
  {
    adornmentLabel: "%",
    id: "collateral_requirement",
    label: "Requirement",
    type: "textfield",
  },
  {
    adornmentLabel: "%",
    id: "over_collateralized_level",
    label: "Over Collateralized",
    type: "textfield",
  },
  {
    adornmentLabel: "%",
    id: "margin_call_level",
    label: "Margin Call",
    type: "textfield",
  },
];

const useStyles = makeStyles((theme: any) => ({
  dialogPaper: {
    minHeight: "80%",
    width: "100%",
  },
}));

/**
 * Needed for backwards compatibility
 */
const determineCollateralRequired = (existingData) => {
  if (Object.keys(existingData).length) {
    return (
      existingData.collateral_requirement &&
      existingData.margin_call_level &&
      existingData.over_collateralized_level
    );
  }
  return true;
};

const createInitialStateObj = (
  LOAN_INPUTS: Array<LoanInput>,
  existingData: any,
  collateralAssetSymbols: Array<string>,
  defaultEntityApiId: string
) => {
  const stateObj = {} as StateObject;
  const allInputs: Array<any> = [
    ...ENTITY_TEXTFIELDS,
    ...LOAN_INPUTS,
    ...COLLATERAL_TEXTFIELDS,
  ];
  allInputs.forEach(({ id, menuItems, type }) =>
    existingData[id]
      ? (stateObj[id] = existingData[id])
      : type === "select"
      ? (stateObj[id] = menuItems[0].value)
      : (stateObj[id] = "")
  );
  stateObj.start_date = existingData.start_date || new Date();
  stateObj.collateral_assets = existingData.collateral_assets || {};
  stateObj.acceptable_assets =
    existingData.acceptable_assets ||
    createInitialAcceptableAssets(collateralAssetSymbols);
  stateObj.entity_api_id = isTestEnv()
    ? "3843b22a95674ee6b0beb21a82a87d71"
    : stateObj.entity_api_id || defaultEntityApiId;
  stateObj.collateral_required = determineCollateralRequired(existingData);
  stateObj.open_term = existingData.open_term || false;
  stateObj.void_past_interest_payments =
    existingData.void_past_interest_payments || false;
  return stateObj;
};

const createInitialAcceptableAssets = (acceptableAssets) => {
  const retObj = {};
  acceptableAssets.forEach((asset) => (retObj[asset] = true));
  return retObj;
};

export default function AddLoanDialog(props: LoanDialogProps) {
  const LOAN_INPUTS = [
    {
      id: "loan_asset_symbol",
      label: "Loan Asset",
      labelWidth: 0,
      menuItems: props.loanAssetSymbols.map((symbol) => ({
        labelText: symbol,
        value: symbol,
      })),
      onChangeHandler: function (e) {
        setStateObj({
          ...stateObj,
          loan_asset_symbol: e.target.value,
        });
      },
      type: "select",
    },
    {
      id: "common_loan_number",
      label: "Common Loan Number",
      type: "textfield",
    },
    {
      id: "loan_amount_subunit",
      label: "Amount Lent Out",
      type: "textfield",
    },
    {
      adornmentLabel: "days",
      id: "length_in_days",
      label: "Loan Length",
      type: "textfield",
    },
    {
      adornmentLabel: "%",
      id: "interest_rate",
      label: "Interest Rate",
      type: "textfield",
    },
    {
      id: "interest_payment_schedule",
      label: "Payment Schedule",
      labelWidth: 0,
      menuItems: props.interestPaymentOptions,
      onChangeHandler: function (e) {
        setStateObj({
          ...stateObj,
          interest_payment_schedule: e.target.value,
        });
      },
      type: "select",
    },
  ];

  const classes = useStyles();
  const { existingData = {} } = props;
  const defaultEntityApiId =
    props.previousEntities && props.previousEntities.length
      ? props.previousEntities[0].value
      : "";

  const [errors, setErrors] = useState<any>({});
  const [postResponse, setPostResponse] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [requestData, setRequestData] = useState<RequestData>(null);
  const [stateObj, setStateObj] = useState<StateObject>(
    createInitialStateObj(
      LOAN_INPUTS,
      existingData,
      props.collateralAssetSymbols,
      defaultEntityApiId
    )
  );
  const [newEntityId, setNewEntityId] = useState(false);

  const hasErrors = () => {
    const newErrors: any = {};
    const collateralFields = [
      "collateral_requirement",
      "over_collateralized_level",
      "margin_call_level",
    ];
    /* Check acceptable_assets errors */
    if (
      stateObj.collateral_required &&
      !Object.values(stateObj.acceptable_assets).some((value) => value)
    ) {
      newErrors.acceptable_assets =
        "Please enter at least one acceptable asset.";
    }
    /* Check basic errors */
    Object.keys(stateObj).forEach((key) => {
      /* Check that values are present */
      if (
        [
          "lending_program_id",
          "acceptable_assets",
          "common_loan_number",
          "void_past_interest_payments",
          "linked_entity_api_id",
        ].includes(key)
      ) {
        return;
      }
      if (!stateObj.collateral_required && collateralFields.includes(key)) {
        return;
      }
      if (key === "length_in_days" && stateObj.open_term) {
        return;
      }
      if (
        key !== "collateral_required" &&
        key !== "open_term" &&
        !stateObj[key]
      ) {
        newErrors[key] = "Please enter a value for this field.";
        return;
      }
      if (
        [
          "loan_amount_subunit",
          "length_in_days",
          "interest_rate",
          ...collateralFields,
        ].includes(key) &&
        isNaN(stateObj[key] as any)
      ) {
        newErrors[key] = "Please enter a valid number for this field.";
      }
    });
    /* Check collateral errors */
    if (
      stateObj.collateral_required &&
      !newErrors.over_collateralized_level &&
      parseFloat(stateObj.over_collateralized_level) <=
        parseFloat(stateObj.collateral_requirement)
    ) {
      newErrors.over_collateralized_level =
        "Over collateralized level must be higher than the collateral requirement.";
    }
    if (
      stateObj.collateral_required &&
      !newErrors.margin_call_level &&
      parseFloat(stateObj.margin_call_level) >=
        parseFloat(stateObj.collateral_requirement)
    ) {
      newErrors.margin_call_level =
        "Margin call level must be lower than the collateral requirement.";
    }
    console.log("Errors: ", newErrors);
    setErrors(newErrors);
    return Object.keys(newErrors).length > 0;
  };

  const resetState = () => {
    setStateObj(
      createInitialStateObj(
        LOAN_INPUTS,
        {},
        props.collateralAssetSymbols,
        defaultEntityApiId
      )
    );
    setPostResponse(null);
    setLoading(false);
  };

  const handleAssetSwitch = (e) => {
    setStateObj({
      ...stateObj,
      acceptable_assets: {
        ...stateObj.acceptable_assets,
        [e.target.id]: !stateObj.acceptable_assets[e.target.id],
      },
    });
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      onClose={props.handleClose}
      open={props.open}
    >
      {postResponse ? (
        <div>
          <DialogContent>
            <Flex container spacing={2}>
              <Flex item xs={24}>
                <Typography type="h6">
                  {postResponse.data.errors
                    ? `Error: ${postResponse.data.errors.message}`
                    : `Successfully ${
                        props.existingData ? "updated" : "created"
                      } loan!`}
                </Typography>
              </Flex>
            </Flex>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button
              onClick={
                postResponse.data.errors
                  ? () => setPostResponse(null)
                  : props.existingData
                  ? props.handleClose
                  : resetState
              }
            >
              {postResponse.data.errors
                ? "Try Again"
                : `${props.existingData ? "Close" : "Add Another Loan"}`}
            </Button>
          </DialogActions>
        </div>
      ) : (
        <div>
          <DialogContent>
            <Loading
              handleResponse={(response) => setPostResponse(response)}
              loading={loading}
              requestData={requestData}
            />
            {!loading && (
              <Flex alignItems="flex-start" container spacing={1}>
                {/* ENTITY INFORMATION --> */}
                <Flex item xs={24}>
                  <Typography type="h6">Entity Information</Typography>
                </Flex>
                {props.previousEntities && (
                  <Flex container>
                    <Field>
                      <Label>
                        Previously Used ID&nbsp;&middot;&nbsp;
                        <Link
                          onClick={() => {
                            setNewEntityId(true);
                            setStateObj({
                              ...stateObj,
                              entity_api_id: "",
                            });
                          }}
                        >
                          Enter New ID
                        </Link>
                      </Label>
                      <Select
                        defaultValue={defaultEntityApiId}
                        disabled={newEntityId || props.existingData}
                        onChange={(e) => {
                          setStateObj({
                            ...stateObj,
                            entity_api_id: e.target.value,
                          });
                        }}
                        options={props.previousEntities}
                      />
                    </Field>
                  </Flex>
                )}
                {ENTITY_TEXTFIELDS.filter((input) => {
                  if (input.id === "entity_api_id" && !newEntityId) {
                    return false;
                  }
                  return true;
                }).map((input) => (
                  <Flex container key={input.id} item xs={24}>
                    <BlockField
                      label={input.label}
                      name={input.id}
                      error={errors[input.id]}
                    >
                      <TextInput
                        defaultValue={
                          existingData[input.id] ||
                          (isTestEnv() && input.id === "entity_api_id")
                            ? "3843b22a95674ee6b0beb21a82a87d71"
                            : ""
                        }
                        disabled={
                          existingData[input.id] && input.id === "entity_api_id"
                        }
                        name={input.id}
                        onChange={(e) =>
                          setStateObj({
                            ...stateObj,
                            [input.id]: e.target.value,
                          })
                        }
                      />
                    </BlockField>
                  </Flex>
                ))}
                {/* <-- ENTITY INFORMATION */}
                <Flex item xs={24}>
                  <Divider />
                </Flex>

                {/* LOAN INFORMATION --> */}
                <Flex container justifyContent="space-between">
                  <Typography type="h6">Loan Information</Typography>
                  <Switch
                    checked={stateObj.open_term}
                    disabled={props.existingData}
                    labelText={"Open Term"}
                    onChange={() =>
                      setStateObj({
                        ...stateObj,
                        open_term: !stateObj.open_term,
                      })
                    }
                  />
                </Flex>
                {LOAN_INPUTS.map((input) => {
                  if (input.type === "textfield") {
                    return (
                      <Flex key={input.id} item xs={8}>
                        <BlockField
                          error={errors[input.id]}
                          name={input.id}
                          label={input.label}
                        >
                          <InputWithAddon addon={input.adornmentLabel || ""}>
                            <TextInput
                              name={input.id}
                              value={stateObj[input.id]}
                              onChange={(e) =>
                                setStateObj({
                                  ...stateObj,
                                  [input.id]: e.target.value,
                                })
                              }
                            />
                          </InputWithAddon>
                        </BlockField>
                      </Flex>
                    );
                  } else {
                    let value = existingData[input.id] || input.menuItems[0];
                    return (
                      <Flex key={input.id} item xs={8}>
                        <BlockField
                          error={errors[input.id]}
                          label={input.label}
                        >
                          <Select
                            defaultValue={value}
                            onChange={input.onChangeHandler}
                            options={input.menuItems}
                          />
                        </BlockField>
                      </Flex>
                    );
                  }
                })}
                {/* Just a spacer element */}
                <Flex item xs={8}></Flex>
                <Flex alignItems="flex-end" container spacing={1}>
                  <Flex item xs={8}>
                    <BlockField label="Start Date" name="startDate">
                      <Select
                        name="startDate"
                        defaultValue={getMonthFromDate(stateObj.start_date)}
                        onChange={(e) => {
                          setStateObj({
                            ...stateObj,
                            start_date: changeMonth(
                              stateObj.start_date,
                              e.target.value
                            ),
                          });
                        }}
                        options={getAllMonthsArr().map((value) => ({
                          labelText: value,
                          value,
                        }))}
                      />
                    </BlockField>
                  </Flex>
                  <Flex item xs={8}>
                    <Select
                      defaultValue={getDayFromDate(stateObj.start_date)}
                      onChange={(e) => {
                        setStateObj({
                          ...stateObj,
                          start_date: changeDay(
                            stateObj.start_date,
                            e.target.value
                          ),
                        });
                      }}
                      options={getDaysForMonthArr(
                        getMonthFromDate(stateObj.start_date),
                        getYearFromDate(stateObj.start_date)
                      ).map((value) => ({
                        labelText: value,
                        value,
                      }))}
                    />
                  </Flex>
                  <Flex item xs={8}>
                    <Select
                      defaultValue={getYearFromDate(stateObj.start_date)}
                      onChange={(e) => {
                        setStateObj({
                          ...stateObj,
                          start_date: changeYear(
                            stateObj.start_date,
                            e.target.value
                          ),
                        });
                      }}
                      options={getAllYearsArr().map((value) => ({
                        labelText: value,
                        value,
                      }))}
                    />
                  </Flex>
                </Flex>
                {/* <-- LOAN INFORMATION */}

                <Flex item xs={24}>
                  <Divider />
                </Flex>

                {/* COLLATERAL INFORMATION --> */}
                <Flex item style={{ paddingBottom: 16 }} xs={24}>
                  <Flex
                    alignItems="center"
                    container
                    justifyContent="space-between"
                  >
                    <Typography type="h6">Collateral Information</Typography>
                    <Switch
                      checked={stateObj.collateral_required}
                      disabled={props.existingData}
                      id={"collateral_required"}
                      labelText={"Collateral required"}
                      onChange={() =>
                        setStateObj({
                          ...stateObj,
                          collateral_required: !stateObj.collateral_required,
                        })
                      }
                    />
                  </Flex>
                </Flex>
                {COLLATERAL_TEXTFIELDS.map((input) => (
                  <Flex key={input.id} item xs={4}>
                    <BlockField
                      error={errors[input.id]}
                      name={input.id}
                      label={input.label}
                    >
                      <InputWithAddon addon={input.adornmentLabel}>
                        <TextInput
                          defaultValue={
                            existingData[input.id] || stateObj[input.id]
                          }
                          disabled={!stateObj.collateral_required}
                          name={input.id}
                          onChange={(e) =>
                            setStateObj({
                              ...stateObj,
                              [input.id]: e.target.value,
                            })
                          }
                        />
                      </InputWithAddon>
                    </BlockField>
                  </Flex>
                ))}
                {/* <-- COLLATERAL INFORMATION */}

                <Flex item xs={24}>
                  <Divider />
                </Flex>

                {/* ACCEPTABLE ASSETS --> */}
                {!props.existingData && stateObj.collateral_required && (
                  <Flex container spacing={2}>
                    <Flex item xs={24}>
                      <Typography type="h6">
                        Acceptable Collateral Assets
                      </Typography>
                    </Flex>
                    <Flex item xs={24}>
                      {props.collateralAssetSymbols.map((asset, index) => (
                        <Switch
                          checked={stateObj.acceptable_assets[asset]}
                          id={asset}
                          key={index.toString()}
                          labelText={asset}
                          onChange={handleAssetSwitch}
                        />
                      ))}
                    </Flex>
                    {errors.acceptable_assets && (
                      <Flex item style={{ paddingTop: 0 }} xs={24}>
                        <FormHelperText error>
                          {errors.acceptable_assets}
                        </FormHelperText>
                      </Flex>
                    )}
                  </Flex>
                )}
                {/* <-- ACCEPTABLE ASSETS */}

                <Flex item xs={24}>
                  <Divider />
                </Flex>

                {/* VOID PAST INTEREST PAYMENTS --> */}
                <Flex item style={{ paddingBottom: 16 }} xs={24}>
                  <Flex
                    alignItems="center"
                    container
                    justifyContent="space-between"
                  >
                    <Switch
                      checked={stateObj.void_past_interest_payments}
                      id={"void_past_interest_payments"}
                      labelText={"Void past interest payments"}
                      onChange={() =>
                        setStateObj({
                          ...stateObj,
                          void_past_interest_payments: !stateObj.void_past_interest_payments,
                        })
                      }
                    />
                  </Flex>
                </Flex>
                {/* <-- VOID PAST INTEREST PAYMENTS */}
              </Flex>
            )}
          </DialogContent>
          <DialogActions style={{ marginTop: props.existingData && 40 }}>
            <Button
              onClick={() => {
                if (!hasErrors()) {
                  const stateObjCopy = { ...stateObj };
                  Object.keys(stateObjCopy.acceptable_assets).forEach((key) => {
                    if (!stateObjCopy.acceptable_assets[key]) {
                      delete stateObjCopy.acceptable_assets[key];
                    }
                  });
                  const data: any = {
                    ...stateObjCopy,
                    start_date: stateObjCopy.start_date.toUTCString(),
                  };
                  if (props.existingData) {
                    data.placed_loan_id = props.existingData.id;
                  }
                  setRequestData({
                    data: data,
                    type: RequestType.POST,
                    url: props.existingData
                      ? props.editLoanUrl
                      : props.addLoanUrl,
                  });
                  setLoading(true);
                }
              }}
            >
              {Object.keys(existingData).length > 0
                ? "Save Changes"
                : "Create Loan"}
            </Button>
          </DialogActions>
        </div>
      )}
    </Dialog>
  );
}
