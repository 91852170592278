require('jquery')
require('jquery-form')
require('underscore')
require('../lib/keyboard')

window.AirdropsCampaigns ||= {}
window.AirdropsCampaigns.ReferUsers = {
  new: ($$, $this) ->
    class ReferralsView extends Backbone.View
      events:
        'click .js-submit': (event) ->
          $button = $$(event.target)
          $button.addClass('c-button--loading')
          $$('.js-errors').hide()
          event.preventDefault()

          $.ajax $button.data('url'),
            type: 'POST',
            data: {invited_email: $$('.js-referral_email').val()}
            success: ->
              $$('.js-referral_email').val('')
            error: (response) ->
              $$('.js-errors').html(response.responseJSON.errors).show()
            complete: ->
              $button.removeClass('c-button--loading')

    new ReferralsView el: $this
}
