window.Admin.Offerings.Show =
  deal_page_form: ($$, $this) ->
    class DealPageFormView extends Backbone.View
      events:
        'click .js-submit': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass 'c-button--loading'
          $target.addClass 'c-button--loading'
          @$('.js-success').addClass 'u-hidden'


          @$('form').ajaxSubmit
            success: (response) =>
              $target.removeClass 'c-button--loading'
              @$('.js-success').removeClass 'u-hidden'
            error: (response) =>
              $target.removeClass 'c-button--loading'
              error_message = response.responseJSON.error_message
              if error_message
                @$('.js-errors').text(error_message).slideDown()
              else
                Tooltips.formServerError($target)

    new DealPageFormView el: $this

  offering_slug_form: ($$, $this) ->
    class OfferingSlugFormView extends Backbone.View
      events:
        'click .js-update_offering_slug': (event) ->
          event.preventDefault()
          $target = $(event.target)

          updateConfirmation = "Are you sure?\n\nNote that this will break all existing links you may have shared already."
          return unless confirm updateConfirmation

          @$('.js-errors').slideUp()
          $target.closest('form').ajaxSubmit
            success: (response) =>
              window.location.href = response.redirect
            error: (response) =>
              error_message = response.responseJSON.error_message
              if error_message
                @$('.js-errors').text(error_message).slideDown()
              else
                Tooltips.formServerError($target)
                
        'click .js-group_tags_submit': (event) ->
          event.preventDefault()
          $target = $(event.target)

          updateConfirmation = "Are you sure?\n\nChanging existing groups may break exisiting business logic."
          return unless confirm updateConfirmation

          @$('.js-errors').slideUp()
          $target.closest('form').ajaxSubmit
            success: (response) =>
              window.location.href = response.redirect
            error: (response) =>
              error_message = response.responseJSON.error_message
              if error_message
                @$('.js-errors').text(error_message).slideDown()
              else
                Tooltips.formServerError($target)

    new OfferingSlugFormView el: $this

  offering_admins: ($$, $this) ->
    class OfferingAdminsView extends Backbone.View
      events:
        'click .js-remove': (event) ->
          if confirm('Are you sure?')
            $target = $(event.target)
            $target.closest('form').ajaxSubmit
              success: (response) =>
                window.location.href = response.redirect
              error: (response) =>
                Tooltips.formServerError($target)

        'click .js-add_offering_admin': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass 'c-button--loading'
          $target.addClass 'c-button--loading'
          @$('.js-errors').slideUp()

          $target.closest('form').ajaxSubmit
            success: (response) =>
              window.location.href = response.redirect
            error: (response) =>
              $target.removeClass 'c-button--loading'
              error_message = response.responseJSON.error_message
              if error_message
                @$('.js-errors').text(error_message).slideDown()
              else
                Tooltips.formServerError($target)

    new OfferingAdminsView el: $this

  sale_documents: ($$, $this) ->
    class FileUploadViewModel extends Backbone.View
      events:
        'submit .js-marketing-documents-form': (event) ->
          event.preventDefault()
          event.stopPropagation()

          $form = $(event.target)
          $button = $form.find('.js-button')

          return if $button.hasClass 'c-button--loading'
          $button.addClass 'c-button--loading'

          $form.ajaxSubmit
            success: (response) =>
              $button.removeClass 'c-button--loading'
              $this.replaceWith(response.html)
            error: (response) =>
              $button.removeClass 'c-button--loading'
              $this.replaceWith(response.responseJSON.html)

        'click .js-new-file': (event) ->
          event.preventDefault()
          event.stopPropagation()
          $target = @$(event.currentTarget)

          $.ajax
            url: $target.attr('href'),
            success: (response) =>
              $this.replaceWith(response.html)

        'click .js-delete-file': (event) ->
          event.preventDefault()
          event.stopPropagation()
          $target = @$(event.currentTarget)

          $.ajax
            url: $target.attr('href'),
            type: 'DELETE',
            success: (response) =>
              $this.replaceWith(response.html)

        'click .js-edit-file': (event) ->
          event.preventDefault()
          event.stopPropagation()
          $target = @$(event.currentTarget)

          $.ajax
            url: $target.attr('href'),
            success: (response) =>
              $this.replaceWith(response.html)

        'click .js-cancel-form': (event) ->
          event.preventDefault()
          @$('.js-toggle-add-form-target').toggleClass('u-hidden')
          @$('a.js-new-file').toggleClass('u-hidden')

    new FileUploadViewModel el: $this

  agreements: ($$, $this) ->
    class AgreementsViewModel extends Backbone.View
      events:
        'click .js-move-higher': (event) ->
          event.preventDefault()
          event.stopPropagation()
          $target = @$(event.currentTarget)

          $.ajax
            url: $target.attr('href'),
            type: 'POST',
            success: (response) =>
              $this.replaceWith(response.html)

        'click .js-move-lower': (event) ->
          event.preventDefault()
          event.stopPropagation()
          $target = @$(event.currentTarget)

          $.ajax
            url: $target.attr('href'),
            type: 'POST',
            success: (response) =>
              $this.replaceWith(response.html)

        'click .js-toggle-more-agreements': (event) ->
          event.preventDefault()
          @$(event.currentTarget).addClass('u-hidden')
          @$('.js-agreement').removeClass('u-hidden')

        'click .js-toggle-add-form': (event) ->
          event.preventDefault()
          @$('.js-toggle-add-form-target').toggleClass('u-hidden')
          @$('a.js-toggle-add-form').toggleClass('u-hidden')

        'submit .js-agreements-form': (event) ->
          event.preventDefault()
          event.stopPropagation()

          $form = $(event.target)
          $button = $form.find('.js-button')

          return if $button.hasClass 'c-button--loading'
          $button.addClass 'c-button--loading'

          $form.ajaxSubmit
            success: (response) =>
              $button.removeClass 'c-button--loading'
              $this.replaceWith(response.html)
            error: (response) =>
              $button.removeClass 'c-button--loading'
              $this.replaceWith(response.responseJSON.html)

        'click .js-delete': (event) ->
          event.preventDefault()
          event.stopPropagation()
          $target = @$(event.currentTarget)

          delete_text = 'This will delete the agreement. This is not undo-able.'
          return unless window.confirm(delete_text)

          $.ajax
            url: $target.attr('href'),
            type: 'DELETE',
            success: (response) =>
              $this.replaceWith(response.html)

        'click .js-edit': (event) ->
          event.preventDefault()
          event.stopPropagation()
          $target = @$(event.currentTarget)

          $.ajax
            url: $target.attr('href'),
            success: (response) =>
              $this.replaceWith(response.html)

    new AgreementsViewModel el: $this

  questions: ($$, $this) ->
    class QuestionsViewModel extends Backbone.View
      events:

        'click .js-move-higher': (event) ->
          event.preventDefault()
          event.stopPropagation()
          $target = @$(event.currentTarget)

          $.ajax
            url: $target.attr('href'),
            type: 'POST',
            success: (response) =>
              $this.replaceWith(response.html)

        'click .js-move-lower': (event) ->
          event.preventDefault()
          event.stopPropagation()
          $target = @$(event.currentTarget)

          $.ajax
            url: $target.attr('href'),
            type: 'POST',
            success: (response) =>
              $this.replaceWith(response.html)

        'click .js-toggle-more-questions': (event) ->
          event.preventDefault()
          @$(event.currentTarget).addClass('u-hidden')
          @$('.js-question').removeClass('u-hidden')

        'click .js-toggle-add-form': (event) ->
          event.preventDefault()
          @$('.js-toggle-add-form-target').toggleClass('u-hidden')
          @$('a.js-toggle-add-form').toggleClass('u-hidden')

          $('.js-wrong-answer').addClass('u-hidden')
          $('.js-add-answer').removeClass('u-hidden')
          $('.js-remove-answer').addClass('u-hidden')

        'submit .js-questions-form': (event) ->
          event.preventDefault()
          event.stopPropagation()

          $form = $(event.target)
          $button = $form.find('.js-button')

          return if $button.hasClass 'c-button--loading'
          $button.addClass 'c-button--loading'

          $form.ajaxSubmit
            success: (response) =>
              $button.removeClass 'c-button--loading'
              $this.replaceWith(response.html)
            error: (response) =>
              $button.removeClass 'c-button--loading'
              $this.replaceWith(response.responseJSON.html)

        'click .js-add-answer': (event) ->
          event.preventDefault()
          event.stopPropagation()

          $form = $('.js-questions-form')
          $hidden_tag = $form.find('.js-wrong-answer.u-hidden').first()
          $hidden_tag.toggleClass('u-hidden')

          $('.js-remove-answer').removeClass('u-hidden')

          if $form.find('.js-wrong-answer.u-hidden').length == 0
            $(event.currentTarget).addClass('u-hidden')

        'click .js-remove-answer': (event) ->
          event.preventDefault()
          event.stopPropagation()

          $form = $('.js-questions-form')
          $tag = $form.find('.js-wrong-answer').not('.u-hidden').last()

          $tag.addClass('u-hidden')
          $tag.find('input').first().val('')

          $('.js-add-answer').removeClass('u-hidden')

          if $form.find('.js-wrong-answer.u-hidden').length == 3
            $(event.currentTarget).addClass('u-hidden')

        'click .js-delete': (event) ->
          event.preventDefault()
          event.stopPropagation()
          $target = $(event.currentTarget)
          delete_text = 'This will delete the question. This is not undo-able.'
          return unless window.confirm(delete_text)

          $.ajax
            url: $target.attr('href'),
            type: 'DELETE',
            success: (response) =>
              $this.replaceWith(response.html)

        'click .js-edit': (event) ->
          event.preventDefault()
          event.stopPropagation()
          $target = $(event.currentTarget)

          $.ajax
            url: $target.attr('href'),
            success: (response) =>
              $this.replaceWith(response.html)
              
              # unhides hidden wrong questions
              $('.js-wrong-answer').each (index, element) =>
                if $(element).find('input').first().val() != ''
                  $(element).removeClass('u-hidden')
                  $('.js-remove-answer').removeClass('u-hidden')

    new QuestionsViewModel el: $this


  copy_quiz: ($$, $this) ->
    class CopyQuizModel extends Backbone.View
      events:
        'submit .js-copy-quiz-form': (event) ->
          unless window.confirm("Confirm overwriting existing questions")
            event.preventDefault()
            event.stopPropagation()

    new CopyQuizModel el: $this
    

  distribution_settings: ($$, $this) ->
    class DistributionSettingViewModel extends Backbone.View
      events:
        'click .js-add-token-option': (event) ->
          event.preventDefault()
          event.stopPropagation()

          $form = $('.js-distribution-setting-form')
          $hidden_tag = $form.find('.js-extra-token-option.u-hidden').first()
          $hidden_tag.toggleClass('u-hidden')

          if $form.find('.js-extra-token-option.u-hidden').length == 0
            $(event.currentTarget).addClass('u-hidden')

        'submit .js-distribution-setting-form': (event) ->
          event.preventDefault()
          event.stopPropagation()
          $form = $(event.target)
          $button = $form.find('.js-button')

          return if $button.hasClass 'c-button--loading'
          $button.addClass 'c-button--loading'
          @$('.js-errors').slideUp()

          $form.ajaxSubmit
            success: (response) =>
              window.location.href = response.redirect
            error: (response) =>
              $button.removeClass 'c-button--loading'
              error_message = response.responseJSON?.error_message.distribution_setting.join(', ')
              if error_message
                @$('.js-errors').text(error_message).slideDown()
              else
                Tooltips.formServerError($target)

    new DistributionSettingViewModel el: $this

  distribution: ($$, $this) ->
    class DistributionViewModel extends Backbone.View
      events:
        'submit .js-distribution-form': (event) ->
          event.preventDefault()
          event.stopPropagation()

          $form = $(event.target)
          $button = $form.find('.js-button')

          return if $button.hasClass 'c-button--loading'
          $button.addClass 'c-button--loading'
          @$('.js-errors').slideUp()

          $form.ajaxSubmit
            success: (response) =>
              window.location.href = response.redirect
            error: (response) =>
              $button.removeClass 'c-button--loading'
              error_message = response.responseJSON?.error_message
              if error_message
                @$('.js-errors').text(error_message).slideDown()
              else
                Tooltips.formServerError($target)

    new DistributionViewModel el: $this


  settings: ($$, $this) ->
    class SettingsView extends Backbone.View
      events:
        'click .js-edit': ->
          if @$('.js-display').is(':visible')
            @$('.js-display').hide()
            @$('.js-form').fadeIn()
          else
            @$('.js-display').fadeIn()
            @$('.js-form').hide()

    new SettingsView el: $this

  investment_documents_package: ($$, $this) ->
    class InvestmentDocumentsPackageView extends Backbone.View
      events:
        'click .js-add': (event) ->
          if confirm("""Are you sure? Please add 'multiple-document-packages' to the Group Tags on the offering admin dashboard in order to allow showing an updated version of documents""")
            $(event.target).hide()
            @$('.js-form').show()

        'click .js-remove': (event) ->
          event.preventDefault()
          if confirm('Are you sure?')
            $target = $(event.target)
            $target.closest('form').ajaxSubmit
              success: (response) =>
                window.location.reload()
              error: (response) =>
                Tooltips.formServerError($target)

        'click .js-submit': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass 'c-button--loading'
          $target.addClass 'c-button--loading'
          $target.closest('form').ajaxSubmit
            success: (response) =>
              window.location.href = response.redirect_url
            error: (response) =>
              if response.status == 422
                $html = $(response.responseJSON.html)
                @$el.replaceWith($html)
              else
                Tooltips.formServerError($target)

    new InvestmentDocumentsPackageView el: $this

  external_funding_options: ($$, $this) ->
    class ExternalFundingOptionsView extends Backbone.View
      events:
        'click .js-open-form': (event) ->
          $target = @$(event.target)
          $target.closest('.js-external-funding-option').find('.js-form').toggle()
          if $target.hasClass('js-add')
            $target.hide()
          event.preventDefault()

        'click .js-remove': (event) ->
          event.preventDefault()
          if confirm('Are you sure?')
            $target = $(event.target)
            $target.closest('form').ajaxSubmit
              success: (response) =>
                window.location.reload()
              error: (response) =>
                Tooltips.formServerError($target)

        'click .js-submit': (event) ->
          event.preventDefault()
          $target = $(event.target)
          $target.closest('form').ajaxSubmit
            success: (response) =>
              window.location.reload()
            error: (response) =>
              Tooltips.formServerError($target)

    new ExternalFundingOptionsView el: $this
