window.Offerings.Participants.Residence =
  proof_of_residence_form: ($$, $this) ->
    class ProofOfResidenceFormView extends Backbone.View
      events: ->
        'click .js-submit_proof_of_residence': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass 'c-button--loading'
          $target.addClass 'c-button--loading'
          @$('form').ajaxSubmit
            success: (response) =>
              location.reload()
            error: (response) =>
              $target.removeClass('c-button--loading')
              if response.status == 422
                @$el.replaceWith(response.responseJSON.html)
              else
                Tooltips.formServerError($target)
    new ProofOfResidenceFormView el: $this

  form: ($$, $this) ->
    class FormView extends Backbone.View
      UNITED_STATES = 'US'
      UKRAINE = 'UA'

      initialize: ->
        @restrictedStates = @$el.data('restricted-states')

      _is_us: ->
        @$('.js-country').val() == UNITED_STATES

      _is_ukraine: ->
        @$('.js-country').val() == UKRAINE

      _isRestrictedState: (state) ->
        @restrictedStates.includes(state)

      events: ->
        'input .js-country': (event) ->
          country = $(event.currentTarget).val()
          @$('.js-state_us').toggle(@_is_us())
          @$('.js-state_ukraine').toggle(@_is_ukraine())
          @$('.js-state').val('')

        'input .js-state': (event) ->
          state = $(event.currentTarget).val()
          if @_isRestrictedState(state)
            @$('.js-state-error').show()
          else
            @$('.js-state-error').hide()


        'click .js-submit': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass 'c-button--loading'
          $target.addClass 'c-button--loading'

          # disable unused input
          if !@_is_us()
            @$('.js-state_us select').prop('disabled', true)
          if !@_is_ukraine()
            @$('.js-state_ukraine select').prop('disabled', true)

          @$('form').ajaxSubmit
            success: (response) =>
              window.location.href = response.redirect_url
            error: (response) =>
              if response.status == 422
                @$el.replaceWith(response.responseJSON.html)
              else
                Tooltips.formServerError($target)

    new FormView el: $this
