window.Admin.Hackathons.Images =
  index: ($$, $this) ->
    class IndexView extends Backbone.View
      initialize: ->
        $.initialize '.js-tooltip_image_preview', (html) =>
          $('.js-tooltip_image_preview').qtip(@imagePreviewTooltipSettings())
        $ ->
          $('.js-copy_success').hide().removeClass('u-hidden')
          $('.js-copy_failure').hide().removeClass('u-hidden')

      imagePreviewTooltipSettings: ->
        position:
          my: 'left center'
          at: 'right center'
          adjust:
            x: 10
        hide:
          fixed: true
        style:
          classes: "c-tooltip c-tooltip--simple"

      copyToClipboard: (text) ->
        $temp = $('<input>')
        $('body').append($temp)
        $temp.val(text).select()
        success = document.execCommand('copy')
        $temp.remove()
        return success

      reportCopySuccess: ($clickTarget) ->
        $successElem = $clickTarget.parents('tr').find('.js-copy_success')
        $successElem.fadeIn(1000).delay(2000).fadeOut(1000)

      reportCopyFailure: ($clickTarget) ->
        $failureElem = $clickTarget.parents('tr').find('.js-copy_failure')
        $failureElem.fadeIn(1000).delay(2000).fadeOut(1000)

      events: ->
        'click .js-upload_image_form_button': (event) ->
          $(event.target).hide()
          $('.js-new_image_form').removeClass('u-hidden')

        'click .js-copy_on_click': (event) ->
          $target = $(event.currentTarget)
          copyContent = $target.data('copy-content')
          success = @copyToClipboard copyContent
          if success then @reportCopySuccess($target) else @reportCopyFailure($target)

        'click .js-remove': (event) ->
          event.preventDefault()
          $target = $(event.target)

          $.ajax
            url: $target.data('url')
            method: 'DELETE'
            success: =>
              window.location.reload()
            error: =>
              Tooltips.formServerError($target)

    new IndexView el: $this

  form: ($$, $this) ->
    class FormView extends Backbone.View
      events: ->
        'click .js-submit': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass 'c-button--loading'
          $target.addClass 'c-button--loading'

          @$('form').ajaxSubmit
            success: =>
              window.location.reload()
            error: (response) =>
              @$el.replaceWith(response.responseJSON.html)

    new FormView el: $this
