Partials = require('./lib/partials')

window.HelpArticles = {
  new: ($$, $this) ->
    class ShowView extends Backbone.View
      initialize: ->
        Partials.with Partials.selector('help_articles/shared/form'), (partial) =>
          partial.on 'success', (response) =>
            window.location.href = response.redirect

    new ShowView el: $this

  show: ($$, $this) ->
    class ShowView extends Backbone.View
      initialize: ->
        Partials.with Partials.selector('help_articles/shared/form'), (partial) =>
          partial.on 'success', (response) =>
            window.location.href = response.redirect

      showForm: ->
        @$('.js-help_article_form_wrapper').show()
        @$('.js-help_article_content').hide()

      showContent: ->
        @$('.js-help_article_form_wrapper').hide()
        @$('.js-help_article_content').show()

      events:
        'click .js-edit_help_article': (event) ->
          @showForm()

    new ShowView el: $this
}