window.Market.Dashboard.News =
  index: ($$, $this) ->
    class IndexView extends Backbone.View
      events: ->
        'click .js-show_more_news_items': (e) ->
          e.preventDefault()
          $.ajax
            url: @$('.js-show_more_news_items').attr('href'),
            method: 'GET',
            success: (response) =>
              # Find the news item in the partial & Append to list of news
              @$('.js-news_items_container').append(response.html)

              # Update or hide the "Show more" button
              if response.next_page_url?
                @$('.js-show_more_news_items').attr('href', response.next_page_url)
              else
                @$('.js-show_all_items_wrapper').hide()


    new IndexView el: $this

  article: ($$, $this) ->
    class ArticleView extends Backbone.View
      events: ->
        'click .js-news-content': (e) ->
#          $target = @$(e.currentTarget)
#          argsJson = $target.data('segmentArticleArgs')
#          argsStringified = JSON.stringify(argsJson)
#          args = JSON.parse(argsStringified)
#
#          console.log(args)
#
#          SegmentJavascript.track(args.page, {
#            event: args.event,
#            user_id: args.user_id,
#            session_id: args.session_id,
#            viewed_at: new Date(),
#          });

          # Prevent navigating to link when clicking item in sharing dropdown
          $target = $(e.target)
          isTokenProfileLink = $target.hasClass('js-token-profile-link')
          if ($target.parents('.js-dropdown').length == 0 && !isTokenProfileLink)
            e.preventDefault()

#            $target = @$(e.currentTarget)
#            argsJson = $target.data('segmentArticleArgs')
#
#            console.log(argsJson)
#
#            argsStringified = JSON.stringify(argsJson)
#            args = JSON.parse(argsStringified)
#
#            console.log(args)
#
#            SegmentJavascript.trackLink($target, args.event, {
#              page: args.page,
#              user_id: args.user_id,
#              session_id: args.session_id,
#              viewed_at: new Date(),
#            });

            $target = @$(e.currentTarget)
            link = $target.data('link')
            window.open link, "_blank"

    new ArticleView el: $this

  share_dropdown: ($$, $this) ->
    class ShareDropdownView extends Backbone.View
      events: ->
        'click .js-dropdown': (e) ->
          $target = @$(e.currentTarget)
          $target.toggleClass('c-dropdown--visible')

    new ShareDropdownView el: $this
