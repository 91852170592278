import * as React from "react";
import {
  Grid,
  Col,
  Spacer,
  Link,
  Button,
  Icon,
  Table,
} from "@react/components";

export interface AdminGovernanceProtocolsIndexProps {
  protocols: any[];
  admin_path: string;
  governance_path: string;
}

export const AdminGovernanceProtocolsIndex: React.FunctionComponent<AdminGovernanceProtocolsIndexProps> = ({
  protocols,
  admin_path,
  governance_path,
}) => {
  const rows = protocols.map((protocol) => [
    <Link href={protocol.admin_path}>
      <div className="c-avatar c-avatar--extra_small">
        <img
          className="governance-protocols-shared-sidebar__asset_logo"
          alt={protocol.token_profile.name}
          title={protocol.token_profile.name}
          src={protocol.token_profile.logo_url}
        />
      </div>
      <span className="s-marginLeft0_5">{protocol.token_profile.name}</span>
    </Link>,
    protocol.token_profile.symbol,
    <Link href={protocol.admin_path}>
      {protocol.proposals_count} Proposals
    </Link>,
    <span className="s-fontSize14 u-colorGray8">{protocol.id}</span>,
    <p className="u-textAlignRight">
      <Link href={protocol.path} target="_blank">
        View live page
      </Link>
    </p>,
  ]);

  return (
    <Grid spacing="C">
      <Col sm="24">
        <h1 className="s-marginBottom3 s-borderBottom2">
          <Link href={admin_path}>
            <span className="u-whiteSpaceNowrap">Admin</span>
          </Link>
          <span className="s-paddingHoriz0_5 s-fontSize18 u-displayInlineBlock u-colorGrayA">
            <Icon icon="chevron-right" />
          </span>
          <span>Governance</span>
          <span className="u-floatRight">
            <Link href={governance_path} unstyled target="_blank">
              <Button variant="secondary" size="small">
                Governance page
              </Button>
            </Link>
          </span>
        </h1>
      </Col>
      <Col sm="24">
        <h3 className="s-marginBottom1 s-borderBottom1 u-colorGray2">
          Protocols
        </h3>
        <Table
          headers={["Protocol", "Symbol", "Proposals", "ID", ""]}
          rows={rows}
        />
      </Col>
    </Grid>
  );
};

export default AdminGovernanceProtocolsIndex;
