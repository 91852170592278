window.Components =

  multiple_file_upload: ($$, $this) ->
    # We keep a 'queue' of available file fields, with only one visible to the user at a given
    # time. When the user adds a file, we mark the field they used as unavailable, and add the filename to a list
    # that's visible to the user. When a user 'removes' a file, we clear the field that was populated with that file's
    # data and add it to the queue of available fields, showing the newly available field if no other field is available
    class MultipleFileUploadView extends Backbone.View
      UNUSED_CLASS: 'js-unused'
      FILE_CLASS: 'js-file'
      FILESIZE_ERROR_CLASS: 'js-filesize_error'
      HIDDEN_CLASS: 'u-hidden'
      FILE_FIELD_ID_DATA: 'file_field_id_data'
      CLASS = '.components-multiple_file_upload'

      FILE_TYPE_PDF = 'application/pdf'
      FILE_TYPE_JPEG = 'image/jpeg'
      FILE_TYPE_PNG = 'image/png'

      FILETYPES = [FILE_TYPE_JPEG, FILE_TYPE_PDF, FILE_TYPE_PNG]

      _listFile: ($fileField) ->
        path = $fileField.val()
        fileName = path.split('\\').pop()
        $fileListing = @$(".js-files .#{@FILE_CLASS}.#{@UNUSED_CLASS}").first()
        $fileListing.find('.js-filename').html(fileName)

        # store the id of the associated file field with the $fileListing
        # if we remove the $fileListing later, we'll look up the $fileField using that id,
        # and reset the $fileField
        $fileListing.data(@FILE_FIELD_ID_DATA, $fileField.prop('id'))
        $fileListing.attr("data-#{@FILE_FIELD_ID_DATA}", $fileField.prop('id')) #for cloning
        $fileListing.removeClass @UNUSED_CLASS
        $fileListing.show()

      _unlistFile: ($fileListing) ->
        $fileListing.find('.js-filename').html('')
        $fileListing.addClass @UNUSED_CLASS
        $fileListing.hide()

        # obtain the $fileField
        $input = @$('#'+ $fileListing.data(@FILE_FIELD_ID_DATA))
        $fileListing.data(@FILE_FIELD_ID_DATA, '')

        # reset the data for the file field
        $input.closest(".#{@FILE_CLASS}").addClass @UNUSED_CLASS
        $input.val('')

        if @$(".#{@UNUSED_CLASS} input[type='file']:visible").length == 0
          $input.parent().show()

      _switchInputs: (currentInput) ->
        currentInput.closest(".#{@FILE_CLASS}").removeClass @UNUSED_CLASS
        currentInput.closest(".#{@FILE_CLASS}").hide()
        if @$(".#{@UNUSED_CLASS} input[type='file']").length > 0
          @$(".#{@UNUSED_CLASS} input[type='file']").first().parent().show()

      uniqIndex: () ->
        $form = @$el.closest('form')
        location_url = window.location.pathname.replace(/\//g, '_')
        form_id = $form.attr('id')
        el_index = $(CLASS).index(@$el)
        "#{location_url}_#{form_id}_#{el_index}"

      handleFileUpload: (event) ->
        $target = $(event.target)

        maxFileSize = 4 * 1024 * 1024
        minFileSize = 1024
        fileSize = $target[0].files[0]?.size
        fileType = $target[0].files[0]?.type

        if fileSize? && ((fileSize > maxFileSize ||
        fileSize < minFileSize) || (!FILETYPES.includes(fileType)))
          @$(".#{@FILESIZE_ERROR_CLASS}").removeClass @HIDDEN_CLASS
          # reset the data for the input
          # otherwise, the user can still submit the too-big file to the server
          $target.val('')
        else
          @$(".#{@FILESIZE_ERROR_CLASS}").addClass @HIDDEN_CLASS
          @_listFile $target
          @_switchInputs $target

      events:
        'change input[type="file"]': (event) ->
          @handleFileUpload(event)

        'click .js-remove': (event)->
          event.preventDefault()
          @_unlistFile($(event.target).closest(".#{@FILE_CLASS}"))

    new MultipleFileUploadView el: $this


  range_input_with_buttons: ($$, $this) ->
    class RangeInputWithButtons extends Backbone.View
      updateMultipyAmount: (value) ->
        multiplier = @$('.js-multiply_amount').data('multiplier')
        @$('.js-multiply_amount').text((value * multiplier).toFixed(2))

      events:
        'click .js-range_input_with_cta_button': (event) ->
          event.preventDefault()
          value = $(event.target).data('value')
          @$('.js-range_input_with_cta_input').val(value)
          @$('.js-amount').text(value)
          @updateMultipyAmount(value)
        'change .js-range_input_with_cta_input': (event) ->
          $target = $(event.target)
          @$('.js-amount').text($target.val())
          @updateMultipyAmount($target.val())
        'input .js-range_input_with_cta_input': (event) ->
          $target = $(event.target)
          @$('.js-amount').text($target.val())
          @updateMultipyAmount($target.val())

    new RangeInputWithButtons el: $this
