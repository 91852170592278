import { WalletLinkInput } from "@coinlist/wallet-link";
export interface LinkWalletData extends WalletLinkInput {
  entityId: string;
  websiteSource: string;
}

export interface UnlinkWalletData {
  address: string;
  chainId: number;
  entityId: string;
}

export interface Entity {
  id: string;
  slug: string;
  name: string;
  [key: string]: string | number | boolean | null;
}

export interface LinkedWallet {
  id: string;
  entity: Entity;
  address: string;
  chainId: number;
  createdAt: number;
  updatedAt: number;
  websiteSource: string;
}

export enum WalletLinkEntryPoint {
  DASHBOARD = "DASHBOARD",
  OFFERING = "OFFERING",
  ACCOUNT = "ACCOUNT",
}
