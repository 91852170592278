import * as React from "react";
import { useState } from "react";
import Snackbar from "@mui/material/Snackbar";

import Confirmation from "@react/components/Confirmation";
import Flex from "@react/components/Flex";
import { Button } from "@react/components";
import InfoWarning from "@react/components/warnings/InfoWarning";
import Loading from "@react/components/Loading";
import LPDialog from "@react/components/landing-pages/LPDialog";
import { TextInput, Field, Select, Label } from "@react/components";
import BlockField from "@react/views/shared/forms/BlockField/BlockField";
import InputWithAddon from "@react/views/shared/forms/InputWithAddon/InputWithAddon";
import Warning from "@react/components/warnings/Warning";

import { SelectOption } from "@react/types/ui-component";
import { RequestType, successfulRequest } from "@react/utils/network";
import { getEntitiesForSelect } from "@react/utils";

const MIN_FIL_AMOUNT = 3000;

interface LendDialogProps {
  createPlacedLoanRequestUrl: string;
  entities: Array<any>;
  onClose: () => void;
  open: boolean;
  response: any;
  setResponse: (any) => void;
}

const durationOptions: Array<SelectOption> = [
  {
    labelText: "30 days",
    value: "30",
  },
  {
    labelText: "90 days",
    value: "90",
  },
  {
    labelText: "540 days",
    value: "540",
  },
];

export default function LendDialog(props: LendDialogProps) {
  const [amount, setAmount] = useState("");
  const [duration, setDuration] = useState(durationOptions[0].value);
  const [entityOptions] = useState(() => getEntitiesForSelect(props.entities));
  const [email, setEmail] = useState("");
  const [entityId, setEntityId] = useState(
    entityOptions.length > 0 ? entityOptions[0].value : ""
  );
  const [errors, setErrors] = useState({} as any);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const hasErrors = () => {
    const newErrors: any = {};
    if (props.entities.length === 0 && !name) {
      newErrors.name = "Please enter a name";
    }
    if (props.entities.length === 0 && !email) {
      newErrors.email = "Please enter a valid email";
    }
    if (!amount || isNaN(parseFloat(amount))) {
      newErrors.amount = "Please enter a valid amount";
    } else if (parseFloat(amount) < MIN_FIL_AMOUNT) {
      newErrors.amount = `The minimum amount to borrow is ${MIN_FIL_AMOUNT} FIL`;
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length > 0;
  };

  const getFormContainer = () => {
    return (
      <Flex container spacing={2}>
        <Flex item xs={24}>
          <InfoWarning>
            Once we receive your request, we'll email you with next steps.
          </InfoWarning>
        </Flex>
        {props.entities.length > 0 ? (
          <Flex container>
            <Field>
              <Label htmlFor="entity">Entity</Label>
              <Select
                onChange={(e) => setEntityId(e.currentTarget.value)}
                options={entityOptions}
                value={entityId}
                name="entity"
              />
            </Field>
          </Flex>
        ) : (
          <Flex container spacing={2}>
            <Flex item sm={12} xs={24}>
              <BlockField label="Full Name" name="name" error={errors.name}>
                <TextInput
                  value={name}
                  name="name"
                  onChange={(e) => setName(e.currentTarget.value)}
                />
              </BlockField>
            </Flex>
            <Flex item sm={12} xs={24}>
              <BlockField label="Email" name="email" error={errors.email}>
                <TextInput
                  value={email}
                  name="email"
                  onChange={(e) => setEmail(e.currentTarget.value)}
                />
              </BlockField>
            </Flex>
          </Flex>
        )}
        <Flex item sm={12} xs={24}>
          <BlockField error={errors.amount} label="Loan Amount">
            <InputWithAddon addon="FIL">
              <TextInput
                value={amount.toString()}
                onChange={(e) => setAmount(e.currentTarget.value)}
              />
            </InputWithAddon>
          </BlockField>
        </Flex>
        <Flex item sm={12} xs={24}>
          <Field>
            <Label htmlFor="duration">Duration</Label>
            <Select
              onChange={(e) => setDuration(e.currentTarget.value)}
              options={durationOptions}
              value={duration}
              name="duration"
            />
          </Field>
        </Flex>
        <Flex
          item
          justifyContent="flex-end"
          style={{ position: "absolute", bottom: 16, right: 16 }}
          xs={24}
        >
          <Button
            onClick={() => {
              if (!hasErrors()) {
                setLoading(true);
              }
            }}
            style={{
              height: 46,
              width: 106,
            }}
          >
            <Loading
              handleResponse={(response) => {
                if (!successfulRequest(response)) {
                  setSnackbarOpen(true);
                }
                setLoading(false);
                props.setResponse(response);
              }}
              loading={loading}
              requestData={{
                data: {
                  amount_subunit: amount,
                  entity_id: props.entities.length > 0 ? entityId : "",
                  length_in_days: isNaN(parseFloat(duration)) ? "" : duration,
                  logged_out_email: email,
                  logged_out_name: name,
                },
                type: RequestType.POST,
                url: props.createPlacedLoanRequestUrl,
              }}
              spinnerColor="white"
            />
            {!loading && "Submit"}
          </Button>
        </Flex>
      </Flex>
    );
  };

  const getResponseContainer = () => {
    return (
      <Confirmation
        mainText="Thanks for submitting your loan request."
        onClose={props.onClose}
        subText="Someone from our lending team will email you shortly."
      />
    );
  };

  return (
    <LPDialog onClose={props.onClose} open={props.open} title="Loan Request">
      {props.response && successfulRequest(props.response)
        ? getResponseContainer()
        : getFormContainer()}
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        autoHideDuration={3000}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
      >
        <Warning
          color={"orange"}
          fullWidth
          fontSize={13}
          padding={8}
          variant={"warning"}
          warningText={
            "Error submitting your request, please reload the page & try again."
          }
        />
      </Snackbar>
    </LPDialog>
  );
}
