window.Market.Dashboard.Sidebar =
  entity_selector: ($$, $this)->
    class EntitySelectView extends Backbone.View
      initialize: ->
        @entitiesData = @$el.data('entitiesData')
        @entityUrl = @$el.data('entityUrl')
        @$('.js-entity').selectize(SelectizePreset.renderHtml(@entitiesData))

      events: ->
        'change .js-entity': (e) ->
          if e.target.value == 'new'
            window.location.href = @entitiesData[e.target.value].url
            return

          $target = @$(e.target)
          $.ajax
            url: @entityUrl
            method: 'POST'
            data: {entity: e.target.value}
            success: (response) =>
              @trigger 'entityChange', @entitiesData[e.target.value]
            error: (response) =>
              @trigger 'entityChange', @entitiesData[e.target.value]

    new EntitySelectView el: $this