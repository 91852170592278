import * as React from "react";
import { useState } from "react";

import makeStyles from '@mui/styles/makeStyles';
import Dialog from "@mui/material/Dialog";

import BlockField from "@react/views/shared/forms/BlockField/BlockField";
import { Button, Divider, TextInput } from "@react/components";
import Flex from "@react/components/Flex";
import InputWithAddon from "@react/views/shared/forms/InputWithAddon/InputWithAddon";
import Loading from "@react/components/Loading";
import { Link } from "@react/components";
import Typography from "@react/components/typography/Typography";
import { RequestType } from "@react/utils/network";

const CONTAINER_PADDING = 20;

const useStyles = makeStyles(() => ({
  dialogPaper: {
    height: 420,
    maxWidth: 615,
    width: "100%",
  },
}));

export default function MoveCollateralDialog(props) {
  const classes = useStyles();

  // const [amount, setAmount] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [moveToUserLoading, setMoveToUserLoading] = useState<boolean>(false);
  const [requestData, setRequestData] = useState<any>(null);

  const handleMoveLockedCollateral = (direction: string) => {
    setRequestData({
      data: {
        // amount: amount,
        direction: direction,
        placed_loan_id: props.placedLoanId,
        symbol: props.symbol,
      },
      type: RequestType.POST,
      url: props.url,
    });
    setLoading(true);
  };

  return (
    <Dialog
      classes={{
        paper: classes.dialogPaper,
      }}
      fullWidth
      onClose={props.onClose}
      open={props.open}
    >
      <Flex container style={{ padding: CONTAINER_PADDING }} spacing={1}>
        <Flex item xs={24}>
          <Typography type="h6">Move Collateral</Typography>
        </Flex>
        <Flex item xs={24}>
          <Divider spacingTop={0} spacingBottom={0} />
        </Flex>
        <Flex container spacing={2} style={{ paddingTop: 8 }}>
          {/* <Flex item xs={24}>
            <label className={`c-label s-fontSize12`}>
              Amount&nbsp;&middot;&nbsp;
              <Link onClick={() => setAmount(props.amount)}>Max</Link>
            </label>
            <BlockField
              hint={`You can move up to ${props.amount} ${props.symbol}`}
            >
              <InputWithAddon addon={props.symbol || "BTC"}>
                <TextInput
                  onChange={(e) => setAmount(e.currentTarget.value)}
                  placeholder="1.0123"
                  value={amount}
                />
              </InputWithAddon>
            </BlockField>
          </Flex> */}
          <Flex container>
            Note that this will move ALL of the locked funds. You cannot move
            partial funds.
          </Flex>
          <Flex container justifyContent="flex-end" spacing={1}>
            <Button
              onClick={() => handleMoveLockedCollateral("to_user")}
              variant="secondary"
            >
              {moveToUserLoading ? (
                <Loading
                  handleResponse={(response) => {
                    if (response.status === 200) {
                      alert(response.data.message);
                    } else {
                      alert(
                        "Error moving collateral, please refresh the page and try again."
                      );
                    }
                    setMoveToUserLoading(false);
                  }}
                  loading={moveToUserLoading}
                  requestData={requestData}
                  spinnerColor="white"
                />
              ) : (
                "Move Collateral Back to Locked Distribution Object"
              )}
            </Button>
            <Button onClick={() => handleMoveLockedCollateral("to_coinlist")}>
              {loading ? (
                <Loading
                  handleResponse={(response) => {
                    if (response.status === 200) {
                      alert(response.data.message);
                    } else {
                      alert(
                        "Error moving collateral, please refresh the page and try again."
                      );
                    }
                    setLoading(false);
                  }}
                  loading={loading}
                  requestData={requestData}
                  spinnerColor="white"
                />
              ) : (
                "Move Collateral to CoinList"
              )}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Dialog>
  );
}
