window.Market.Accounts.Transactions =
  transaction_row: ($$, $this) ->
    class TransactionRowView extends Backbone.View
      initialize: ->
        @selectedClass = @$el.data('selected-class')
        console.log(@selectedClass)
        @$('.js-wallet-info').qtip(@_tooltipOptions())

      _tooltipOptions: ->
        position:
          my: 'top right'
          at: 'bottom center'
        show:
          solo: true
        hide:
          event: 'unfocus'
          distance: 500
        style:
          classes: "c-tooltip c-tooltip--white qtip-distributions-wallet-info"
          tip:
            border: 1
            height: 4
            width: 12

      events: ->
        'click .js-row a': (e) ->
          e.stopPropagation()

        'click .js-row': (e) ->
          e.preventDefault()
          $target = @$(e.currentTarget)
          if $target.find('.js-row-details').length > 0
            $target.find('.js-row-details').slideToggle()
            $target.find('.js-arrow').toggleClass('u-rotate180')
            $target.find('.js-name').toggleClass('u-fontWeight700 u-colorGray3')
            $target.toggleClass(@selectedClass)

    new TransactionRowView el: $this
