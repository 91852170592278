import * as React from "react";
import cx from "classnames";

// Sizes
export const INPUT_WITH_ADDON_LEFT = "left";
export const INPUT_WITH_ADDON_RIGHT = "right";

export const InputWithAddonPositions = [
  INPUT_WITH_ADDON_LEFT,
  INPUT_WITH_ADDON_RIGHT,
] as const;

export interface InputWithAddonProps {
  addon: React.ReactNode;
  position?: typeof InputWithAddonPositions[number];
  children?: React.ReactNode;
}

export const InputWithAddon: React.FunctionComponent<InputWithAddonProps> = ({
  children,
  addon,
  position = INPUT_WITH_ADDON_RIGHT,
}) => {
  const addonClasses = cx("c-input-addon", {
    "c-input-addon--right": position === INPUT_WITH_ADDON_RIGHT,
  });

  return (
    <div className="c-input-addon-wrapper">
      <div className={addonClasses}>{addon}</div>
      {children}
    </div>
  );
};

export default InputWithAddon;
