import * as React from "react";
import { KycAppIndividual } from '@coinlist/ui-lib';
import UiLibWrapper from '../../utils/UiLibWrapper';


type KycReviewAndSubmitPageProps = {
  locale: string;
  entityId: typeof KycAppIndividual.defaultProps.entityId;
  isReverificationFlow: typeof KycAppIndividual.defaultProps.isReverificationFlow;
}

export default function KycReviewAndSubmitPage(props : KycReviewAndSubmitPageProps) {  
  return (
    <UiLibWrapper locale={props.locale}>
      <KycAppIndividual
        entityId={props.entityId}
        isReverificationFlow={props.isReverificationFlow}
        navigateToStatusPage={() => {
          window.location.href = "/kyc/status/" + props.entityId;
        }}
      />
    </UiLibWrapper>
  );
}
