window.Validation = {

  # Inspired from:
  # http://stackoverflow.com/questions/8398403/jquery-javascript-to-check-if-correct-e-mail-was-entered
  isValidEmail: (email) ->
    pattern =

    pattern = ///^
      (
        (
          [^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)
          |
          (".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])
          |
          (([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}
        )
      )
    $///i
    pattern.test(email)
}