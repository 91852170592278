import * as React from "react";
import { Grid, Col, Spacer, Link, Icon, Button } from "@react/components";
import { getFormattedTimeLong } from "@react/utils/date";

export interface GovernanceProposalShowSettingsProps {
  proposal: any;
}

export const GovernanceProposalShowSettings: React.FunctionComponent<GovernanceProposalShowSettingsProps> = ({
  proposal,
}) => {
  const snapshotIntegrationId = proposal.snapshot_integration_id ? (
    proposal.snapshot_integration_id
  ) : (
    <span className="u-colorGray8">None</span>
  );
  const classByState = {
    created: "u-colorOrange",
    published: "u-colorGreen",
    closed: "u-colorGray8",
    hidden: "u-colorGrayA",
  };
  const publishedAt = proposal.published_at ? (
    getFormattedTimeLong(proposal.published_at)
  ) : (
    <span className="u-colorGrayA">&mdash;</span>
  );
  const closedAt = proposal.closed_at ? (
    getFormattedTimeLong(proposal.closed_at)
  ) : (
    <span className="u-colorGrayA">&mdash;</span>
  );
  const hiddenAt = proposal.hidden_at ? (
    getFormattedTimeLong(proposal.hidden_at)
  ) : (
    <span className="u-colorGrayA">&mdash;</span>
  );
  const attributesToValue = {
    Title: proposal.title,
    ID: proposal.id,
    "Start at": getFormattedTimeLong(proposal.start_at),
    "End at": getFormattedTimeLong(proposal.end_at),
    State: (
      <span className={classByState[proposal.state]}>{proposal.state}</span>
    ),
    "Snapshot Integration Id": snapshotIntegrationId,
    "Created at": getFormattedTimeLong(proposal.created_at),
    "Updated at": getFormattedTimeLong(proposal.updated_at),
    "Published at": publishedAt,
    "Closed at": closedAt,
    "Hidden at": hiddenAt,
  };

  return (
    <Grid>
      <Col sm="12">
        {Object.keys(attributesToValue).map((key) => (
          <div className="s-marginBottom0_5" key={key}>
            <Grid>
              <Col sm="6">
                <span className="u-colorGray8">{key}</span>
              </Col>
              <Col sm="18">
                <span className="u-colorGray4 u-wordBreakAll">
                  {attributesToValue[key]}
                </span>
              </Col>
            </Grid>
          </div>
        ))}
      </Col>
      <Col sm="12">
        {proposal.choices.map((choice, index) => (
          <div className="s-marginBottom0_5" key={choice.id}>
            <Grid>
              <Col sm="6">
                <span className="u-colorGray8">Choice {index + 1}</span>
              </Col>
              <Col sm="18">
                <span className="u-colorGray4">{choice.value}</span>
              </Col>
            </Grid>
          </div>
        ))}
      </Col>
    </Grid>
  );
};

export default GovernanceProposalShowSettings;
