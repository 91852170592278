require('intl-tel-input/build/js/intlTelInput-jquery')
require('intl-tel-input/build/js/utils')

window.Index.Mobile =
  send_mobile_app_link_form: ($$, $this) =>
    class SendMobileAppLinkFormView extends Backbone.View

      initialize: ->
        @$phoneInput = @$('.js-phone_input')
        autocomplete = @$phoneInput.prop('autocomplete')

        @$phoneInput.intlTelInput({
          initialCountry: @$phoneInput.data('userCountryCode')
        })
        @$phoneInput.attr('autocomplete', autocomplete)

      formattedPhoneNumber: ->
        @$phoneInput.intlTelInput('getNumber')

      isValidNumber: ->
        @$phoneInput.intlTelInput('isValidNumber')

      validatePhoneNumber: ->
        if @isValidNumber()
          @$('.js-phone-error').text('').hide()
          @$phoneInput.parent().removeClass('c-input-group--has-error')
        else
          error = @$phoneInput.intlTelInput('getValidationError')
          errorMessage = if error == intlTelInputUtils.validationError.IS_POSSIBLE
                           'is an invalid phone number'
                         else if error == intlTelInputUtils.validationError.INVALID_COUNTRY_CODE
                           'wrong country code'
                         else if error == intlTelInputUtils.validationError.INVALID_LENGTH
                           'invalid length'
                         else if error == intlTelInputUtils.validationError.TOO_LONG
                           'is too long'
                         else if error == intlTelInputUtils.validationError.TOO_SHORT
                           'is too short'

          @$('.js-phone-error').text(errorMessage).show()
          @$phoneInput.parent().addClass('c-input-group--has-error')


      toggleButtonState: ->
        $button = @$('.js-send_mobile_app_link_form_submit')

        $button.prop('disabled', !@isValidNumber())

      events:
        'input .js-phone_input': (event) ->
          @$('.js-phone_number').text(@formattedPhoneNumber())
          @toggleButtonState()

        'blur .js-phone_input': (event) ->
          @validatePhoneNumber()

        'submit .js-send_mobile_app_link_form': (event) ->
          event.preventDefault()

          $form = $(event.target)
          $button = @$('.js-send_mobile_app_link_form_submit')

          return if $button.hasClass('c-button--loading')
          $button.addClass('c-button--loading')

          $.ajax
            url: $form.attr('action')
            method: 'POST'
            data: {phone_number: @formattedPhoneNumber()}
            dataType: 'json'
            success: (response) =>
              Analytics.trackEvent(@$('.js-data').data('analyticsEvent'))
              $button.removeClass('c-button--loading')
              @$phoneInput.val('')
              @$('.js-phone-success').show()
              @$('.js-input_wrapper').hide()
              @toggleButtonState()

            error: (response) =>
              Tooltips.formServerError($button)

    new SendMobileAppLinkFormView el: $this
