window.Market.Entities.Wallets.MockAssets =
  withdraw: ($$, $this) ->
    class WithdrawView extends Backbone.View
      LOADING_CLASS: 'c-button--loading'
      DISABLED_CLASS: 'c-button--disabled'

      initialize: ->
        @$amount = @$('.js-amount')
        @$amountError = @$('.js-amount-error')
        @$recipientAddress = @$('.js-address')
        @$reviewButton = @$('.js-review')
        @$reviewErrors = @$('.js-review-errors')
        @$submitButton = @$('.js-submit')
        @$formSection = @$('.js-form-section')
        @$submitSection = @$('.js-submit-section')

        @balance = @$el.data('balance')
        @minWithdrawalAmount = @$el.data('min-withdrawal-amount')
        @decimalPrecision = @$el.data('decimal-precision')
        @inputErrorClass = @$el.data('input-error-class')

        @$('.js-fee-tip').qtip(Tooltips.presetSimple())

      _round: (amount) ->
        parseFloat(amount.toFixed(@decimalPrecision))

      _amount: ->
        amount = @$amount.val().replace(',', '')
        return null if !amount

        amount = parseFloat(amount)
        return 0.0 if isNaN(amount)
        @_round(amount)

      _recipientAddress: ->
        @$recipientAddress.val()

      _validate: ->
        @$reviewButton.addClass(@DISABLED_CLASS)
        @$amountError.hide()
        @$amount.removeClass(@inputErrorClass)

        amount = @_amount()

        return if amount == 0
        return if @_recipientAddress() == ''

        if amount > @balance
          @$amountError.text('Insufficient funds').show()
          @$amount.addClass(@inputErrorClass)
          return

        if amount < @minWithdrawalAmount
          @$amountError.text(
            "The minimum withdrawal amount is #{@_round(@minWithdrawalAmount)}"
          ).show()
          @$amount.addClass(@inputErrorClass)
          return

        @$reviewButton.removeClass(@DISABLED_CLASS)

      events: ->
        'input .js-address': ->
          @_validate()

        'input .js-amount': ->
          @_validate()

        'submit': ->
          # turn off submit on enter key
          false

        'click .js-review': (e) ->
          e.preventDefault()
          @$formSection.hide()
          @$submitSection.show()

          @$('.js-review-address').text(@_recipientAddress())
          @$('.js-review-amount').text(@_round(@_amount()))
          @$('.js-review-total-amount').text(@_round(@_amount()))

        'click .js-back': ->
          @$formSection.show()
          @$submitSection.hide()

        'click .js-submit':  (e) ->
          $target = @$(e.target)
          return if $target.hasClass(@DISABLED_CLASS) || $target.hasClass(@LOADING_CLASS)

          $target.addClass(@LOADING_CLASS)
          @$reviewErrors.hide()

          @$('form.js-withdraw-form').ajaxSubmit
            success: (response) =>
              @$('.js-hide-on-success').hide()
              @$('.js-show-on-success').show()

            error: (response) =>
              if response.responseJSON
                $target.removeClass(@LOADING_CLASS)
                @$reviewErrors.text(response.responseJSON.errors.message).fadeIn()
              else
                Tooltips.formServerError($target)


    new WithdrawView el: $this
