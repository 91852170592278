window.Market.Accounts.Banking =
  entity_linked_accounts: ($$, $this) ->
    class EntityLinkedAccountsView extends Backbone.View
      initialize: ->
        showAddWireAccountForm = @$el.data('show-add-wire-account-form')
        if showAddWireAccountForm
          @showWireAccountForm()
          Animate.niceScrollTo(@$('.js-wire_form'))

      LOADING_CLASS = 'c-button--loading'

      showWireAccountForm: ->
        @$('.js-wire_form').show()
        @$('.js-add_wire_account').hide()
        @$('.js-plaid_link').hide()

      hideWireAccountForm: ->
        @$('.js-wire_form').hide()
        @$('.js-add_wire_account').show()
        @$('.js-plaid_link').show()

      removeAchAccount: (event) ->
        event.preventDefault()
        $target = $(event.target)
        return if $target.hasClass LOADING_CLASS

        $target.addClass LOADING_CLASS
        $.ajax
          url: $target.data('url'),
          type: 'PUT',
          success: (response) =>
            $('.js-external_ach_account_' + response.external_ach_account_id).remove()
          error: (response) =>
            Tooltips.formServerError($target)

      removeWireAccount: (event) ->
        event.preventDefault()
        $target = $(event.target)
        return if $target.hasClass LOADING_CLASS

        $target.addClass LOADING_CLASS
        $.ajax
          url: $target.data('url'),
          type: 'PUT',
          success: (response) =>
            $('.js-external_wire_account_' + response.external_wire_account_id).remove()
          error: (response) =>
            Tooltips.formServerError($target)

      events:
        'click .js-remove-bank-account': (event) ->
          if confirm('Are you sure you want to remove this linked bank account?')
            @removeAchAccount(event)

        'click .js-remove-wire-account': (event) ->
          if confirm('Are you sure you want to remove this linked wire account?')
            @removeWireAccount(event)

        'click .js-add_wire_account': ->
          @showWireAccountForm()

        'click .js-cancel_create_wire_account': (event) ->
          @hideWireAccountForm()

    new EntityLinkedAccountsView el: $this

  wire_account_form: ($$, $this) ->
    class WireAccountForm extends Backbone.View
      INPUT_REQUIRED_CLASS = 'c-input--required'

      initialize: ->
        @US = @$el.data('us')
        @NON_US = @$el.data('nonUs')

      _toggleInternational: ->
        if @$('.js-select-international').val() == 'true'
          @$('.js-usa').hide()
          @$('.js-international').show()
          @$('.js-beneficiary-address2-label').text('Account holder city, country')
          @$('.js-required-us-field').removeClass(INPUT_REQUIRED_CLASS).attr('required', false)
          @$('.js-required-int-field').addClass(INPUT_REQUIRED_CLASS).attr('required', true)
        else
          @$('.js-usa').show()
          @$('.js-international').hide()
          @$('.js-beneficiary-address2-label').text('Account holder city, state, zip')
          @$('.js-required-us-field').addClass(INPUT_REQUIRED_CLASS).attr('required', true)
          @$('.js-required-int-field').removeClass(INPUT_REQUIRED_CLASS).attr('required', false)

      _toggleCanadianBranchCode: ->
        if @$('.js-canadian-bank-field').val() == 'true'
          @$('.js-canadian-branch-code').show()
        else
          @$('.js-canadian-branch-code').hide()

      submit: ->
        @$('.js-external-wire-account-form').ajaxSubmit
          success: (response) =>
            window.location = response.redirect
          error: (response) =>
            # 401 only happens when authroize enternal_wire_account in
            # external_wire_accounts_controller.rb fails. The other error cases is when there is
            # bad data in the wire account form
            if response.status == 401
              window.location = response.responseJSON.redirect
            else if response.responseJSON
              @$el.replaceWith response.responseJSON.html
            else
              Tooltips.formServerError($target)

      events:

        'change .js-select-international': '_toggleInternational'
        'change .js-canadian-bank-field': '_toggleCanadianBranchCode'

        'change .js-intermediary-party-international-input': (event) ->
          if event.target.value.toString() == @NON_US.toString()
            $('.js-intermediary-party-swift-code').removeClass('u-hidden')
            $('.js-intermediary-party-routing-number').addClass('u-hidden')
            $('.js-intermediary-party-routing-number-input').val('')
          else if event.target.value.toString() == @US.toString()
            $('.js-intermediary-party-swift-code').addClass('u-hidden')
            $('.js-intermediary-party-routing-number').removeClass('u-hidden')
            $('.js-intermediary-party-swift-code-input').val('')

        'change .js-for-further-credit-input': (event) ->
          if @$('.js-for-further-credit-input').is(':checked')
            @$('.js-for-further-credit-fields').show()
          else
            @$('.js-for-further-credit-fields').hide()

        'change .js-intermediary-party-input': (event) ->
          if @$('.js-intermediary-party-input').is(':checked')
            @$('.js-intermediary-fields').show()
          else
            @$('.js-intermediary-fields').hide()

        'click .js-submit': (event) ->
          event.preventDefault()
          $target = $(event.target)
          if $target.hasClass 'c-button--disabled' || $target.hasClass 'c-button--loading'
            return

          $target.addClass 'c-button--loading'
          @submit()

    new WireAccountForm(el: $this)
