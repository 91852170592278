import * as React from "react";
import { KycSelectorEntityType } from '@coinlist/ui-lib';
import UiLibWrapper from '../../utils/UiLibWrapper';

type EntityTypePageProps = {
  locale: string;
}

export default function EntityTypePa_sege({locale}: EntityTypePageProps) {
  const redirectToPersonApplication = (entityId : string) => {
    window.location.href = `/kyc/person/${entityId}`;
  };

  const redirectToOrgCreation = () => {
    window.location.href = `/kyc/organization/`;
  };

  return (
    <UiLibWrapper locale={locale}>
      <KycSelectorEntityType
        redirectToPersonApplication={redirectToPersonApplication}
        redirectToOrgCreation={redirectToOrgCreation}
      />
    </UiLibWrapper>
  );
}
