import {
  Table,
  TableBody,
  TableHead,
  TableHeaderCell,
  TableRow,
} from "@react/components";
import * as React from "react";
import {
  GenerateTableRow,
  GenerateTableRowData,
} from "@react/views/admin/queue_tokens/components/GenerateTableRow";

interface GenerateTableProps {
  jobs: GenerateTableRowData[];
  generateLoading: boolean;
}

export default function GenerateTable(props: GenerateTableProps) {
  const headers = [
    "Status",
    "Date",
    "Type",
    "Event ID",
    "Total Tokens",
    "Total Karma",
    "Staking karma",
    "Trading Karma",
    "Imported users",
  ];

  return (
    <Table>
      <TableHead>
        <TableRow>
          {headers.map((header, headerIndex) => (
            <TableHeaderCell key={`header-cell-${headerIndex}`}>
              {header}
            </TableHeaderCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {[...props.jobs]
          .sort((row1, row2) => {
            return (
              (row1.status < row2.status ? 1 : -1) ||
              (row1.started_at > row2.started_at ? -1 : 1)
            );
          })
          .map((job) => {
            job["key"] = job.token_generator_id;
            return GenerateTableRow({ ...job });
          })}
      </TableBody>
    </Table>
  );
}
