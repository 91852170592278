webAuthnApp = require('webauthn-simple-app')

window.WebauthnCredentials = {

  app: ($$, $this) ->
    class AppView extends Backbone.View

      initialize: ->
        @registerChallengePath = @$el.data('registerChallengePath')
        @registerResponsePath = @$el.data('registerResponsePath')
        @loginChallengePath = @$el.data('loginChallengePath')
        @loginResponsePath = @$el.data('loginResponsePath')

      get: ->
        return @webAuthn if !!@webAuthn

        @webAuthn = new webAuthnApp.WebAuthnApp(
          registerChallengeEndpoint: @registerChallengePath,
          registerResponseEndpoint: @registerResponsePath,
          loginChallengeEndpoint: @loginChallengePath,
          loginResponseEndpoint: @loginResponsePath,
          csrfToken: $.rails.csrfToken()
        )
        @webAuthn.username = "me"

        @webAuthn

    new AppView el: $this

}