import * as React from "react";
import { useState } from "react";

import AddLoanDialog from "./AddLoanDialog";
import MoveCollateralDialog from "./MoveCollateralDialog";
import Flex from "@react/components/Flex";
import { Button, Link } from "@react/components";
import TableWithTitle from "@react/views/shared/TableWithTitle";

import { axiosRequest, RequestType } from "@react/utils/network";
import { normalizeData } from "../utils";
import { getFormattedTime } from "../../../../../utils/date";
import {
  getTextForPaymentType,
  getTextForPlacedLoanPaymentState,
} from "../../../../../utils/index";

interface PlacedLoanProps {
  addLoanUrl: string;
  cancelLoanUrl: string;
  completeLoanUrl: string;
  markAsInDefaultLoanUrl: string;
  editLoanUrl: string;
  collateralAssets: Array<any>;
  collateralAssetSymbols: Array<string>;
  interestPaymentOptions: Array<string>;
  loanAsset: any;
  lendingPrograms: Array<any>;
  loanAssetSymbols: Array<string>;
  moveCollateralUrl: string;
  placedLoan: any;
  possibleStates: Array<string>;
  previousEntities: Array<any>;
  processPaymentUrl: string;
}

const COLLATERAL_HEAD_CELLS: Array<string> = ["Amount", "Symbol", "State", ""];

export default function PlacedLoan(props: PlacedLoanProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [
    availableCollateralToMove,
    setAvailableCollateralToMove,
  ] = useState<number>(0);
  const [symbolToMove, setSymbolToMove] = useState<string>("");
  const [
    moveCollateralDialogOpen,
    setMoveCollateralDialogOpen,
  ] = useState<boolean>(false);

  const handleCancel = async () => {
    const data = { placed_loan_id: props.placedLoan.id };
    try {
      await axiosRequest(props.cancelLoanUrl, data, RequestType.POST);
      alert(
        "Loan & all payments cancelled. Note that this does not move funds around, so if the borrower has outstanding funds you will have to move them manually."
      );
    } catch (error) {
      alert("Error cancelling loan, please reach out to engineering.");
    }
  };

  const handleComplete = async () => {
    const data = { placed_loan_id: props.placedLoan.id };
    try {
      await axiosRequest(props.completeLoanUrl, data, RequestType.POST);
      alert(
        "Loan marked as completed, payments cancelled. No funds have been moved."
      );
    } catch (error) {
      alert("Error completing loan, please reach out to engineering.");
    }
  };

  const handleMarkAsInDefault = async () => {
    const data = { placed_loan_id: props.placedLoan.id };
    try {
      await axiosRequest(props.markAsInDefaultLoanUrl, data, RequestType.POST);
      alert(
        "Loan marked as default, payments also marked as default. No funds have been moved."
      );
    } catch (error) {
      alert("Error defaulting loan, please reach out to engineering.");
    }
  };

  const handleProcessInterestPayment = async (paymentId) => {
    const data = { payment_id: paymentId };
    try {
      await axiosRequest(props.processPaymentUrl, data, RequestType.POST);
      alert("Payment processed. Refresh the page to view updates.");
    } catch (error) {
      alert("Error processing payment, please reach out to engineering.");
    }
  };

  const getPaymentRows = (rows: Array<any>) => {
    return rows.map((row) => [
      row.amount_subunit,
      row.symbol,
      getFormattedTime(row.due_date),
      getTextForPaymentType(row.payment_type),
      getTextForPlacedLoanPaymentState(row),
      <Link onClick={() => handleProcessInterestPayment(row.id)}>
        Process payment
      </Link>,
    ]);
  };

  const getCollateralRows = (rows: Array<any>) => {
    return rows.map((row) => [
      <div>
        <div
          style={{
            display: "inline",
            textDecoration: row.state === "completed" ? "line-through" : "none",
          }}
        >
          {row.amount_subunit}
        </div>
        {row.state === "completed" && (
          <div
            style={{
              display: "inline",
            }}
          >
            {" 0"}
          </div>
        )}
      </div>,
      <div>{row.symbol}</div>,
      <div>{row.state}</div>,
      <div>
        {row.state === "completed" ? (
          <div>Collateral has been returned to user</div>
        ) : (
          <Link
            onClick={() => {
              setAvailableCollateralToMove(row.amount_subunit);
              setSymbolToMove(row.symbol);
              setMoveCollateralDialogOpen(true);
            }}
          >
            Move Collateral
          </Link>
        )}
      </div>,
    ]);
  };

  return (
    <>
      <Flex container spacing={2}>
        <Flex
          container
          justifyContent="flex-end"
          spacing={1}
          style={{ paddingBottom: 0 }}
        >
          <Button onClick={handleCancel} variant="secondary">
            Cancel Loan
          </Button>
          <Button onClick={handleComplete} variant="secondary">
            Complete Loan
          </Button>
          <Button onClick={handleMarkAsInDefault} variant="secondary">
            Default Loan
          </Button>
          <Button onClick={() => setOpen(true)}>View/Edit Loan</Button>
        </Flex>
        <Flex item xs={24}>
          <TableWithTitle
            headers={COLLATERAL_HEAD_CELLS}
            rows={getCollateralRows(props.collateralAssets)}
            title="Collateral Assets"
          />
        </Flex>
        <Flex item xs={24}>
          <TableWithTitle
            headers={["Amount", "Symbol", "Due Date", "Type", "State", ""]}
            rows={getPaymentRows(props.placedLoan.payments)}
            title="Payments"
          />
        </Flex>
      </Flex>
      <AddLoanDialog
        addLoanUrl={props.addLoanUrl}
        collateralAssetSymbols={props.collateralAssetSymbols}
        editLoanUrl={props.editLoanUrl}
        existingData={normalizeData({
          ...props.placedLoan,
          collateral_assets: props.collateralAssets,
          loan_amount_subunit: props.loanAsset.amount_subunit,
          loan_asset_symbol: props.loanAsset.symbol,
        })}
        handleClose={() => setOpen(false)}
        interestPaymentOptions={props.interestPaymentOptions}
        loanAssetSymbols={props.loanAssetSymbols}
        lendingPrograms={props.lendingPrograms}
        open={open}
        possibleStates={props.possibleStates}
        previousEntities={props.previousEntities}
      />
      <MoveCollateralDialog
        amount={availableCollateralToMove}
        handleClose={() => setMoveCollateralDialogOpen(false)}
        open={moveCollateralDialogOpen}
        onClose={() => setMoveCollateralDialogOpen(false)}
        placedLoanId={props.placedLoan.id}
        symbol={symbolToMove}
        url={props.moveCollateralUrl}
      />
    </>
  );
}
