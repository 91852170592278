window.Admin.Offerings.Show.Settings =
  form: ($$, $this) ->
    class FormView extends Backbone.View
      HIDDEN_CLASS: 'u-hidden'

      events:
        'click .js-preview-markdown': (event) ->
          event.preventDefault()
          $target = @$(event.target)


          name = $target.data('name')
          markdown = @$("textarea#offering_#{ name }").val()
          url = $target.attr('href')

          @$('.js-preview-markdown-area').html('<i class="icon-spin6" />').show()

          $.ajax
            url: url,
            method: 'POST',
            data: { markdown: markdown },
            success: (response) =>
              @$('.js-preview-markdown-area').html(response.html).show()

        'change .js-step_checkbox': (event) ->
          $target = $(event.target)
          $target.closest('.js-step_container').next('.js-step_deadlines').toggleClass @HIDDEN_CLASS

        'change .js-token-price-method-select': (event) ->
          @showTokenPriceInput()

        'click .js-submit': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass 'c-button--loading'
          $target.addClass 'c-button--loading'

          @$('form').ajaxSubmit
            success: (response) =>
              window.location.href = response.redirect
            error: (response) =>
              if response.status == 422
                @$el.replaceWith(response.responseJSON.html)
              else
                Tooltips.formServerError($target)

      initialize: ->
        @showTokenPriceInput()

      showTokenPriceInput: ->
        value = @$('.js-token-price-method-select').val()
        @$(".js-token-price-methods").hide()
        @$(".js-token-price-method-#{ value }").show()

    new FormView el: $this

  change_state_form: ($$, $this) ->
    class SubmitFormView extends Backbone.View
      events:
        'click .js-submit_link': (event) ->
          if confirm('Are you sure?')
            event.preventDefault()
            $target = $(event.target)
            return if $target.hasClass 'disabled'
            $target.addClass 'disabled'

            @$('form').ajaxSubmit
              success: (response) =>
                window.location.href = response.redirect
              error: (response) =>
                $target.removeClass 'disabled'
                error_message = response.responseJSON.error_message
                if error_message
                  Tooltips.formError($target, error_message)
                else
                  Tooltips.formServerError($target)

    new SubmitFormView el: $this
