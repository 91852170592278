import * as React from "react";
import { useState } from "react";

import { ICON_MAP } from "../../../shared/icons";

import makeStyles from '@mui/styles/makeStyles';
import cx from "classnames";

import {
  AccountBadge,
  AccountBadgeAttributes,
  KarmaBadgesAttributes,
  GetMoreBadgesAttributes,
  VerifiedKYCBadgeAttributes,
  ProcessingKYCBadgeAttributes,
  RejectedKYCBadgeAttributes,
  StartKYCBadgeAttributes,
  IncompleteKYCBadgeAttributes,
} from "./components/AccountBadge";
import { Entity, WalletLinkEntryPoint } from "@react/views/walletLink/types";
import WalletLink from "@react/views/walletLink/WalletLink";
import AccountEntitySelector from "./components/AccountEntitySelector";
import Flex from "@react/components/Flex";
import Typography from "@react/components/typography/Typography";

const Account: React.FC<{
  firstName: string;
  lastName: string;
  kycIdentityState: any;
  totalKarmaPoints?: number;
  selectedEntity: Entity;
  entities: Entity[];
  settingsPath: string;
  karmaPath: string;
  addEntityPath: string;
  badges: any;
}> = ({
  firstName,
  lastName,
  kycIdentityState,
  totalKarmaPoints,
  selectedEntity,
  entities,
  settingsPath,
  karmaPath,
  addEntityPath,
  badges,
}) => {
  const [switchEntityDialogOpen, setSwitchEntityDialogOpen] = useState(false);
  const formattedTotalKarmaPoints = (
    totalKarmaPoints ?? ""
  ).toLocaleString(undefined, { maximumFractionDigits: 0 });

  const routeToPath = (url: any) => {
    window.location.href = url;
  };

  const kycBadgeAttributes: () => AccountBadgeAttributes = () => {
    switch (kycIdentityState.status) {
      case "REJECTED": {
        return RejectedKYCBadgeAttributes;
      }
      case "VERIFIED": {
        return VerifiedKYCBadgeAttributes;
      }
      case "NEEDS_REVIEW": {
        return ProcessingKYCBadgeAttributes;
      }
      case "UNCHECKED": {
        return StartKYCBadgeAttributes;
      }
      case "INCOMPLETE": {
        return IncompleteKYCBadgeAttributes;
      }
    }
  };

  const onSelectEntity = (entity: any) => {
    setSwitchEntityDialogOpen(false);
    if (entity.slug != selectedEntity.slug) {
      routeToPath(`/${entity.slug}/account`);
    }
  };

  return (
    <Flex container flexDirection="column">
      <Header
        firstName={firstName}
        lastName={lastName}
        onClickSwitchEntity={() => setSwitchEntityDialogOpen(true)}
        onClickSettings={() => routeToPath(settingsPath)}
      />

      <div
        style={{
          borderRadius: 12,
          borderStyle: "solid",
          borderWidth: 1,
          borderColor: "rgba(51,51,51,0.1)",
          strokeOpacity: 0.1,
          marginTop: "31px",
          padding: "12px",
        }}
      >
        <Typography fontSize={21} style={{ color: "#333333" }}>
          Get access to token sales with Badges
        </Typography>
        <Typography
          fontSize={15}
          style={{ color: "#333333", opacity: 0.5, paddingTop: "6px" }}
        >
          Badges are a new way to get discovered by token issuers. Connect
          external wallets to build up credentials and increase your chances of
          getting into token sales.
        </Typography>
      </div>

      <Flex
        container
        flexDirection="row"
        spacing={1.5}
        style={{ marginTop: "30px" }}
      >
        <WalletLink
          entity={selectedEntity}
          entryPoint={WalletLinkEntryPoint.ACCOUNT}
          customButton={
            <AccountBadge badgeAttributes={GetMoreBadgesAttributes} />
          }
        />

        {totalKarmaPoints && (
          <AccountBadge
            onClick={() => routeToPath(karmaPath)}
            badgeAttributes={KarmaBadgesAttributes(formattedTotalKarmaPoints)}
          />
        )}

        <AccountBadge
          badgeAttributes={kycBadgeAttributes()}
          onClick={() => routeToPath(kycIdentityState.path)}
        />
      </Flex>

      {/* Modals */}
      <AccountEntitySelector
        open={switchEntityDialogOpen}
        onClose={() => setSwitchEntityDialogOpen(false)}
        entities={entities}
        selectedEntity={selectedEntity}
        onAddEntity={() => routeToPath(addEntityPath)}
        onSelectEntity={onSelectEntity}
      />
    </Flex>
  );
};

export default Account;

const Header: React.FC<{
  firstName: string;
  lastName: string;
  onClickSwitchEntity: () => void;
  onClickSettings: () => void;
}> = ({ firstName, lastName, onClickSwitchEntity, onClickSettings }) => {
  const firstLetterFirstName = firstName.substring(0, 1);
  const firstLetterLastName = lastName.substring(0, 1);

  return (
    <Flex container flexDirection="row" alignItems="flex-end">
      <Flex
        justifyContent="center"
        alignItems="center"
        style={{
          width: 88,
          height: 88,
          backgroundColor: "#333333",
          borderRadius: "50%",
        }}
      >
        <Typography fontSize={32} style={{ fontWeight: 600, color: "#FFFFFF" }}>
          {firstLetterFirstName}
          {firstLetterLastName}
        </Typography>
      </Flex>

      <Flex flexDirection="column" style={{ paddingLeft: "12px", gap: "8px" }}>
        <Typography fontSize={21} style={{ color: "#333333" }}>
          {firstName} {lastName}
        </Typography>

        <Flex container flexDirection="row" spacing={1.5}>
          <SettingsButton
            title="Switch Entity"
            icon={ICON_MAP.accountSwitchEntityIcon}
            onClick={onClickSwitchEntity}
          />
          <SettingsButton
            title="Settings"
            icon={ICON_MAP.accountSettingsIcon}
            onClick={onClickSettings}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

const SettingsButton: React.FC<{
  title: string;
  icon: string;
  onClick: () => void;
}> = ({ title, icon, onClick }) => {
  const classes = useStyles();
  return (
    <button onClick={onClick} className={cx(classes.settingsButton)}>
      <img
        src={icon}
        style={{ paddingLeft: "8px", paddingTop: "8px", paddingBottom: "8px" }}
      />
      <Typography
        fontSize={15}
        style={{ paddingRight: "8px", color: "#333333", fontWeight: 600 }}
      >
        {title}
      </Typography>
    </button>
  );
};

const useStyles = makeStyles(() => ({
  settingsButton: {
    borderRadius: 8,
    background: "#F6F6F6",
    borderWidth: "1.5px",
    borderColor: "#eaeaea",
    color: "#333333",
    padding: "0px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
    "&:hover": {
      background: "#F6F6F6",
    },
  },
}));
