import * as React from "react";
import { Button, Container } from "@react/components";

import Flex from "@react/components/Flex";

import ContactDialog from "./ContactDialog";

export default function LendHero(props) {
  const [open, setOpen] = React.useState(false);

  const handleContactUs = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <Container maxSize="18">
      <Flex container flexDirection="column" style={{ paddingBottom: 136, paddingTop: 104 }}>
        <h1 className="s-fontSize48 s-marginBottom1 u-fontWeight700">
          CoinList Lending
        </h1>
        <h2 className="s-fontSize20">
          A simple and reliable way to lend and borrow crypto.
        </h2>
        <Flex container style={{ marginTop: 24 }}>
          <Button
            onClick={handleContactUs}
            style={{ marginRight: 10 }}
            variant="gray"
          >
            Contact Us
          </Button>
        </Flex>
      </Flex>
      <ContactDialog onClose={handleCloseDialog} open={open} />
    </Container>
  );
}
