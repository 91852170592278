import * as React from "react";
import { useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import { Button, Divider } from "@react/components";
import Dialog from "@mui/material/Dialog";
import Flex from "@react/components/Flex";
import FormattedCells from "@react/components/FormattedCells";
import InfoWarning from "@react/components/warnings/InfoWarning";
import LargeDisplayAmount from "@react/components/LargeDisplayAmount";
import Loading from "@react/components/Loading";
import PercentageInput from "@react/components/inputs/PercentageInput";
import Typography from "@react/components/typography/Typography";

import { RequestType } from "../../../../utils/network";
import { isExtraSmallOrSmaller, roundFloat } from "../../../../utils";
import { getAllCells } from "../shared/stakingCells";

const CONTAINER_PADDING = 20;

const useStyles = makeStyles(() => ({
  dialogPaper: {
    minHeight: 560,
    maxHeight: 560,
    maxWidth: 550,
    width: "100%",
  },
  dialogPaperXs: {
    minHeight: 600,
    maxHeight: 600,
    maxWidth: 600,
    width: "100%",
  },
}));

export default function OptInDialog(props) {
  const classes = useStyles();
  const extraSmall = isExtraSmallOrSmaller();
  let walletCells = [];

  const paperStyle = extraSmall ? classes.dialogPaperXs : classes.dialogPaper;

  const [floatingPointError, setFloatingPointError] = useState(false);
  const [insufficientBalanceError, setInsufficientBalanceError] = useState(
    false
  );
  const [loading, setLoading] = useState(false);
  const [percentToStake, setPercentToWithdraw] = useState<string>(
    props.stakingRolloverOptInPercentage === "0"
      ? ""
      : props.stakingRolloverOptInPercentage
  );
  const [response, setResponse] = useState(null);

  walletCells = getAllCells(
    props.interestRate,
    props.lockUpPeriod,
    props.minDeposit,
    props.assetSymbol,
    props.startDate
  );

  walletCells = [walletCells[0], walletCells[3]];

  const renderResponse = () => {
    return (
      <Flex container spacing={2}>
        <Flex item xs={24}>
          <Typography>
            {response.status === 200
              ? "Successfully saved your preferences."
              : response.data.errors.message}
          </Typography>
        </Flex>
        <Flex
          container
          justifyContent="flex-end"
          style={{ position: "absolute", bottom: 16, right: 16 }}
        >
          <Button onClick={() => props.onClose()}>Close</Button>
        </Flex>
      </Flex>
    );
  };

  const renderForm = () => {
    return (
      <Flex container spacing={2}>
        <Flex item xs={8}>
          <PercentageInput
            floatingPointError={floatingPointError}
            insufficientBalanceError={insufficientBalanceError}
            helperText={`You have ${roundFloat(
              props.lockedFlowBalance / 12,
              2
            )} ${props.assetSymbol.toUpperCase()} unlocking each month`}
            percentage={percentToStake}
            setFloatingPointError={setFloatingPointError}
            setInsufficientBalanceError={setInsufficientBalanceError}
            setPercentToWithdraw={setPercentToWithdraw}
          />
        </Flex>
        <Flex item sm={16} style={{ overflow: "hidden" }} xs={0}>
          <LargeDisplayAmount
            error={floatingPointError || insufficientBalanceError}
            mainString={`${
              isNaN(parseFloat(percentToStake))
                ? "0"
                : roundFloat(
                    (parseFloat(percentToStake) *
                      (props.lockedFlowBalance / 12)) /
                      100,
                    2
                  )
            } ${props.assetSymbol.toUpperCase()}`}
            topLabelText={"Monthly Staking Amount"}
          />
        </Flex>
        <Flex item xs={24}>
          <InfoWarning fontSize={12}>
            Each month when funds unlock, we'll stake based on the percentage
            you set. You can change this value at any time & to opt out
            entirely, you can set it to 0.
          </InfoWarning>
        </Flex>
        {!extraSmall && (
          <Flex item xs={24}>
            <InfoWarning fontSize={12}>
              Note that when you unstake funds, you will have to wait until the
              end of the current rewards period (normally 30 days) to receive
              the tokens in your wallet.
            </InfoWarning>
          </Flex>
        )}
        <Flex item xs={24}>
          <FormattedCells
            cells={walletCells}
            cellPadding={extraSmall ? 8 : null}
            parentSpacing={2}
          />
        </Flex>
        <Flex container justifyContent="flex-end">
          <Button
            onClick={() => {
              if (!Boolean(percentToStake)) {
                alert("Please enter a value to stake.");
                return;
              }
              if (!floatingPointError && !insufficientBalanceError) {
                setLoading(true);
              }
            }}
            style={{ position: "absolute", bottom: 16, right: 16 }}
          >
            Save
          </Button>
        </Flex>
      </Flex>
    );
  };

  return (
    <Dialog
      classes={{
        paper: paperStyle,
      }}
      fullWidth
      onClose={props.onClose}
      open={props.open}
    >
      <Flex container style={{ padding: CONTAINER_PADDING }} spacing={1}>
        <Flex item xs={24}>
          <Typography type="h6">Staking Opt In</Typography>
        </Flex>
        <Flex item xs={24}>
          <Divider spacingTop={0} spacingBottom={0} />
        </Flex>
        <Flex container spacing={2} style={{ paddingTop: 8 }}>
          {loading ? (
            <Loading
              handleResponse={(response) => {
                setResponse(response);
                setLoading(false);
              }}
              loading={loading}
              requestData={{
                data: {
                  entity_id: props.selectedEntityId,
                  percent_to_stake: percentToStake,
                  symbol: props.assetSymbol,
                },
                type: RequestType.POST,
                url: props.url,
              }}
            />
          ) : response ? (
            renderResponse()
          ) : (
            renderForm()
          )}
        </Flex>
      </Flex>
    </Dialog>
  );
}
