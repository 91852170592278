window.Offerings.Participants.Distribution =
  form: ($$, $this) ->
    class FormView extends Backbone.View
      events:
        'change .js-wallet_name': (event) ->
          $target = $(event.target)
          useDefaultWallet = $target.val() != ''
          @$('input.js-wallet_name_text').prop('disabled', useDefaultWallet).val('')
          @$('.js-wallet_name_text_holder').toggle(!useDefaultWallet)

        'click .js-submit': (event) ->
          event.preventDefault()
          $target = $(event.target)

          return if $target.hasClass 'c-button--loading'
          $target.addClass 'c-button--loading'

          @$('form').ajaxSubmit
            success: (response) =>
              window.location.href = response.redirect_url
            error: (response) =>
              $target.removeClass 'c-button--loading'
              if response.status == 422
                $html = $(response.responseJSON.html)
                @$el.replaceWith($html)
                $error = $html.find('.js-error').first()
                if $error.length > 0
                  Animate.niceScrollTo($error, -120)
              else
                Tooltips.formServerError($target)

    new FormView el: $this
