import * as React from "react";
import Flex from "@react/components/Flex";
import { Button, Container } from "@react/components";

export default function Hero(props) {
  return (
    /*  Generate gradients: https://cssgradient.io/ */
    <div
      style={{
        color: "#000",
        backgroundColor: "#fafafa",
      }}
    >
      <Container maxSize="18">
        <Flex container style={{ paddingBottom: 136, paddingTop: 104 }}>
          <h1 className="s-fontSize48 s-marginBottom1 u-fontWeight700">
            Earn staking rewards on CoinList
          </h1>
          <h2 className="s-fontSize20">
            On CoinList, it’s never been easier to put your crypto to work.
            Eligible users can stake NU, CELO, and other assets directly on
            CoinList and start earning rewards.
          </h2>
          <Flex container style={{ marginTop: 24 }}>
            <span className="s-marginRight1">
              <Button
                onClick={() => (window.location.href = props.registerLink)}
                variant="primary"
              >
                Get started
              </Button>
            </span>
          </Flex>
        </Flex>
      </Container>
    </div>
  );
}
