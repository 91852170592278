Partials = require('./lib/partials')

window.WalletApplications =
  progress_bar: ($$, $this) ->
    class ProgressBarView extends Backbone.View
      initialize: ->
        @$el.animate({scrollLeft: $('.js-active').position().left - 150}, 100)

    new ProgressBarView el: $this

  new: ($$, $this) ->
    class NewView extends Backbone.View
      LOADING_CLASS: 'c-button--loading'

      initialize: ->
        @$newForm = @$('.js-new-form')
        showNewForm =
          @$('.js-new-entity:checked').length > 0 || @$('.js-entities').length == 0
        @_toggleNewForm(showNewForm)

      _toggleNewForm: (display) ->
        @$newForm.toggle(display)
        @$('.js-existing-entity-content').toggle(!display)

      events:
        'change .js-entity-radio': (event) ->
          $target = @$(event.target)
          @_toggleNewForm($target.hasClass('js-new-entity'))

        'click .js-submit-existing-entity': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass @LOADING_CLASS
          $target.addClass @LOADING_CLASS
          @$('form').ajaxSubmit
            success: (response) =>
              window.location.href = response.redirect_url
            error: (response) =>
              Tooltips.formServerError($target)

        'click .js-create-new-entity': (event) ->
          Partials.with Partials.selector('entities/new_form'), (partial) =>
            partial.submitForm(event)

    new NewView el: $this

  residence_form: ($$, $this) =>
    class ResidenceFormView extends Backbone.View
      UNITED_STATES: 'US'
      UKRAINE: 'UA'
      LOADING_CLASS: 'c-button--loading'

      initialize: ->
        @restrictedStates = @$el.data('restricted-states')

      _is_us: ->
        @$('.js-country').val() == @UNITED_STATES

      _is_ukraine: ->
        @$('.js-country').val() == @UKRAINE

      _isRestrictedState: (state) ->
        @restrictedStates.includes(state)

      events: ->
        'input .js-country': (event) ->
          country = $(event.currentTarget).val()
          @$('.js-state_us').toggle(@_is_us())
          @$('.js-state_ukraine').toggle(@_is_ukraine())
          @$('.js-state').val('')

        'input .js-state': (event) ->
          state = $(event.currentTarget).val()
          if @_isRestrictedState(state)
            @$('.js-state-error').show()
          else
            @$('.js-state-error').hide()

        'click .js-submit': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass(@LOADING_CLASS)
          $target.addClass(@LOADING_CLASS)

          # disable unused input
          if !@_is_us()
            @$('.js-state_us select').prop('disabled', true)
          if !@_is_ukraine()
            @$('.js-state_ukraine select').prop('disabled', true)

          @$('form').ajaxSubmit
            success: (response) =>
              window.location.href = response.redirect_url
            error: (response) =>
              if response.status == 422
                @$el.replaceWith(response.responseJSON.html)
              else
                Tooltips.formServerError($target)
      new ResidenceFormView el: $this

  account_usage_form: ($$, $this) =>
    class AccountUsageFormView extends Backbone.View
      LOADING_CLASS: 'c-button--loading'

      events: ->
        'click .js-submit': (event) ->
          if !($(".c-form.questionnaire")[0].checkValidity())
            return
          else

          event.preventDefault()

          $target = $(event.target)
          return if $target.hasClass(@LOADING_CLASS)
          $target.addClass(@LOADING_CLASS)

          @$('form').ajaxSubmit
            success: (response) =>
              window.location.href = response.redirect_url
            error: (response) =>
              Tooltips.formServerError($target)
      new AccountUsageFormView el: $this
