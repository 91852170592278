require('jquery-form')
require('./lib/keyboard')

Partials = require('./lib/partials')

class FormView extends Backbone.View

  handleSuccessResponse: (response) ->
    $html = $(response.html)
    $success = $html.find('.js-success')
    @$el.replaceWith $html
    $success.removeClass('u-hidden').animate {opacity: 0}, 2000, =>
      $success.hide()

  handleErrorResponse: (response, $button) ->
    if response.status == 422
      @$el.replaceWith response.responseJSON.html
    else
      Tooltips.formServerError($button)

  events:
    'submit form': (event) ->
      event.preventDefault()
      $form = $(event.target)
      $button = @$('.js-cta')
      return if $button.hasClass 'c-button--loading'
      $button.addClass 'c-button--loading'
      $form.ajaxSubmit
        success: (response) =>
          @handleSuccessResponse(response)
        error: (response) =>
          @handleErrorResponse(response, $button)

window.Settings = {
  Shared:
    nav: ($$, $this) ->
      class NavView extends Backbone.View
        events:
          'change .js-mobile-nav': (event) ->
            $target = $(event.target)
            window.location.pathname = $target.val()

      new NavView el: $this

  Index: {
    form: ($$, $this) ->
      class IndexFormView extends FormView
      new IndexFormView el: $this
  }
  Email: {
    form: ($$, $this) ->
      class EmailFormView extends FormView
      new EmailFormView el: $this
  }
  Password: {
    form: ($$, $this) ->
      class PasswordFormView extends FormView
      new PasswordFormView el: $this
  }

  MultiFactorAuthentication: {

    enabled: ($$, $this) ->
      class EnabledView extends Backbone.View

        events:
          'click .js-show_backup_codes': (event) ->
            event.preventDefault()
            $target = $(event.target)
            $target.hide()
            @$('.js-backup_codes').slideDown 'fast'


      new EnabledView el: $this

    form: ($$, $this) ->
      class MultiFactorAuthenticationFormView extends FormView

        handleSuccessResponse: (response) ->
          if response.redirect_url
            window.location.href = response.redirect_url
          else
            window.location.reload()

        events: ->

          _.extend {}, FormView::events, {

            'click .js-show_manual_setup': (event) =>
              event.preventDefault()
              $target = $(event.target)
              $target.hide()
              @$('.js-manual_setup').slideDown 'medium'

          }

      new MultiFactorAuthenticationFormView el: $this
  }

  close_account: ($$, $this) ->
    class CloseAccountView extends Backbone.View
      REQUIRED_CLOSE_TEXT: 'CLOSE'
      REQUIRED_CANCEL_TEXT: 'CANCEL'

      enableSubmit: ->
        @$('.js-submit').removeClass 'c-button--disabled'

      disableSubmit: ->
        @$('.js-submit').addClass 'c-button--disabled'

      canSubmit: ->
        !@$('.js-submit').hasClass 'c-button--disabled'

      events:
        'keydown .js-confirmation': (event) ->
          if event.keyCode == Keyboard.enter
            event.preventDefault()
            return false

        'input .js-confirmation-close': ->
          if @$('.js-confirmation-close').val() == @REQUIRED_CLOSE_TEXT
            @enableSubmit()
          else
            @disableSubmit()

        'input .js-confirmation-cancel': ->
          if @$('.js-confirmation-cancel').val() == @REQUIRED_CANCEL_TEXT
            @enableSubmit()
          else
            @disableSubmit()

        'click .js-submit': (event) ->
          event.preventDefault() unless @canSubmit()

    new CloseAccountView el: $this
}
