import { Spacer, TableBody, TableCell, TableRow } from "@react/components";
import * as React from "react";
import { Table } from "@react/components";

export interface PreviewProps {
  total_registered: number;
  total_qualified: number;
  karma_qualified: number;
  list_qualified: number;
  qualified_by_both: number;
  list_not_eligible: number;
}

export default function Preview(props: PreviewProps) {
  return (
    <>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Total registrations</TableCell>
            <TableCell>{props.total_registered}</TableCell>
          </TableRow>
          <Spacer />

          <TableRow>
            <TableCell>Total priority queue members</TableCell>
            <TableCell>{props.total_qualified}</TableCell>
          </TableRow>
          <Spacer />

          <TableRow>
            <TableCell>Qualified by both the list and karma</TableCell>
            <TableCell>{props.qualified_by_both}</TableCell>
          </TableRow>
          <Spacer />

          <TableRow>
            <TableCell>Qualified only by karma</TableCell>
            <TableCell>{props.karma_qualified}</TableCell>
          </TableRow>
          <Spacer />

          <TableRow>
            <TableCell>Qualified only by the list</TableCell>
            <TableCell>{props.list_qualified}</TableCell>
          </TableRow>
          <Spacer />

          <TableRow>
            <TableCell>
              Emails / Wallets on list that did not register for sale
            </TableCell>
            <TableCell>{props.list_not_eligible}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
}
