d3 = require('d3')
Plyr = require('plyr')

window.Index.OriginIndex = {
  header: ($$, $this) =>
    class HeaderView extends Backbone.View
      initialize:  ->
        @playing = false
        @player = new Plyr('.js-video', PlyrPresets.default())
        @player.on 'playing', =>
          @$('.js-video_holder.video_holder').addClass('active')

      events: ->
        'click .js-video_holder': ->
          unless @playing
            @playing = true
            @player.play()

    new HeaderView el: $this

  risks: ($$, $this) =>
    class RisksView extends Backbone.View
      events:
        'click .js-expandable': (event) ->
          @$(event.target).closest('.js-risk').find('.js-risk-content').slideToggle('fast')

        'click .js-show-all': ->
          @$('.js-show-all').hide()
          @$('.js-extra-risks').slideDown(2000)


    new RisksView el: $this

  particles: ($$, $this) ->
    class ParticlesView extends Backbone.View
      initialize: ->
        #
        # taken from https://www.originprotocol.com/
        #
        particles = `
          (function () {
            var colors = { dots: '#ebf0f3', nodes: ['#6e3bea', '#26d198', '#1a82ff'] };

            // Design Variables
            var opacity = { dots: { active: 1, inactive: 0.2 } };
            var horizontalSpacing = 104;
            var verticalSpacing = 30;
            var horizontalOffset = -horizontalSpacing * 0.25;
            var verticalOffset = -verticalSpacing * 0.25;
            var radius = 7;

            var containerHeight = void 0;
            var containerWidth = void 0;
            var columnsCount = void 0;
            var rowsCount = void 0;
            var dotsCount = void 0;
            var svg = void 0;
            var loop = void 0;
            var data = { connections: [], dots: [], nodes: [] };
            var firstDot = void 0;
            var lastDot = void 0;
            var container = document.getElementById('particles').parentElement;

            // stop and start animation based on window visibility
            // https://stackoverflow.com/questions/1060008/is-there-a-way-to-detect-if-a-browser-window-is-not-currently-active
            (function () {
              var hidden = "hidden";

              if (hidden in document) document.addEventListener("visibilitychange", onchange);else if ((hidden = "mozHidden") in document) document.addEventListener("mozvisibilitychange", onchange);else if ((hidden = "webkitHidden") in document) document.addEventListener("webkitvisibilitychange", onchange);else if ((hidden = "msHidden") in document) document.addEventListener("msvisibilitychange", onchange);else if ("onfocusin" in document) document.onfocusin = document.onfocusout = onchange;else window.onpageshow = window.onpagehide = window.onfocus = window.onblur = onchange;

              function onchange(evt) {
                var v = 'visible';
                var h = 'hidden';
                var evtMap = { focus: v, focusin: v, pageshow: v, blur: h, focusout: h, pagehide: h };
                var status = void 0;

                evt = evt || window.event;

                if (evt.type in evtMap) status = evtMap[evt.type];else status = this[hidden] ? 'hidden' : 'visible';

                if (loop) {
                  if (status === 'hidden') loop.clear();else createLoop();
                }
              }

              if (document[hidden] !== undefined) onchange({ type: document[hidden] ? 'blur' : 'focus' });
            })();

            // use requestAnimationFrame rather than setInterval
            // https://codereview.stackexchange.com/questions/47889/alternative-to-setinterval-and-settimeout
            window.rafInterval = function (callback, delay) {
              var _window = window,
                  requestAnimationFrame = _window.requestAnimationFrame;

              var start = Date.now();
              var stop = void 0;

              var intervalFunc = function intervalFunc() {
                Date.now() - start < delay || (start += delay, callback());

                stop || requestAnimationFrame(intervalFunc);
              };

              requestAnimationFrame(intervalFunc);

              return {
                clear: function clear() {
                  stop = 1;
                }
              };
            };

            // https://raw.githubusercontent.com/jashkenas/underscore/95e007ed57be8927c4b6344dabb684d055fd89c9/underscore.js
            function debounce(func, wait, immediate) {
              var timeout, args, context, timestamp, result;

              function now() {
                return Date.now || function () {
                  return new Date().getTime();
                };
              }

              var later = function later() {
                var last = now() - timestamp;

                if (last < wait && last >= 0) {
                  timeout = setTimeout(later, wait - last);
                } else {
                  timeout = null;
                  if (!immediate) {
                    result = func.apply(context, args);
                    if (!timeout) context = args = null;
                  }
                }
              };

              var debounced = function debounced() {
                context = this;
                args = arguments;
                timestamp = now();
                var callNow = immediate && !timeout;
                if (!timeout) timeout = setTimeout(later, wait);
                if (callNow) {
                  result = func.apply(context, args);
                  context = args = null;
                }

                return result;
              };

              debounced.clear = function () {
                clearTimeout(timeout);
                timeout = context = args = null;
              };

              return debounced;
            };

            var addOrRemoveNode = function addOrRemoveNode(position) {
              var dot = data.dots[position];
              var unique = !data.nodes.find(function (n) {
                return n.cx === dot.cx && n.cy === dot.cy;
              });
              var stroke = colors.nodes[randomInteger(colors.nodes.length)];
              var dotColor = void 0;
              var dotOpacity = void 0;

              // add circle if space is empty, otherwise remove it
              if (unique) {
                data.nodes.push({
                  key: generateId(),
                  cx: dot.cx,
                  cy: dot.cy,
                  stroke: stroke
                });

                dotColor = stroke;
                dotOpacity = opacity.dots.active;
              } else {
                data.nodes = data.nodes.filter(function (n) {
                  return n.cx !== dot.cx || n.cy !== dot.cy;
                });

                dotColor = colors.dots;
                dotOpacity = opacity.dots.inactive;
              }

              // color the dot to match
              svg.selectAll('circle.dot').filter(function (d, i) {
                return i === position;
              }).transition().duration(2000).attr('fill', dotColor).attr('fill-opacity', dotOpacity);

              // append the circle
              svg.selectAll('circle.node').data(data.nodes, function (d) {
                return d.key;
              }).enter().append('circle').classed('node', true).attr('cx', function (d) {
                return d.cx;
              }).attr('cy', function (d) {
                return d.cy;
              }).attr('fill', 'transparent').attr('r', 1).attr('stroke', function (d) {
                return d.stroke;
              }).attr('stroke-width', 1).transition().duration(2000).attr('r', radius);

              // remove the circle
              svg.selectAll('circle.node').data(data.nodes, function (d) {
                return d.key;
              }).exit().transition().duration(1000).attr('r', 0).attr('stroke', 'transparent').remove();

              manageNodeConnections(dot);
            };

            var addShape = function addShape(start, connections) {
              addOrRemoveNode(start);
              var dot = data.dots[start];

              connections.forEach(function (c, i) {
                setTimeout(function () {
                  var x = dot.cx + c.x * horizontalSpacing / 2;
                  var y = dot.cy + c.y * verticalSpacing;

                  // validate that coordinates are within range
                  if (x >= firstDot.cx && x <= lastDot.cx && y >= firstDot.cy && y <= lastDot.cy) {
                    addOrRemoveNode(data.dots.findIndex(function (d) {
                      return d.cx === x && d.cy === y;
                    }));
                  }
                }, (i * randomInteger(5) + 1) * 400);
              });
            };

            // build dot grid, initiate node generation
            var build = function build() {
              // stop existing animation
              if (loop) {
                loop.clear();
              }

              svg = d3.select('#particles');

              generateGradients();

              var newContainerHeight = container.offsetHeight;
              var newContainerWidth = container.offsetWidth;

              // only build if dimensions have truly changed
              if (newContainerHeight === containerHeight && newContainerWidth === containerWidth) {
                return;
              }

              // remove existing dots outside of container
              if (loop && (newContainerHeight < containerHeight || newContainerWidth < containerWidth)) {
                data.dots = data.dots.filter(function (d) {
                  return d.cx < newContainerWidth && d.cy < newContainerHeight;
                });
              }

              // first run or resize is positive
              var newDotsNeeded = !loop || newContainerHeight > containerHeight || newContainerWidth > containerWidth;

              containerHeight = newContainerHeight;
              containerWidth = newContainerWidth;

              if (newDotsNeeded) {
                columnsCount = Math.floor((containerWidth - horizontalOffset) / horizontalSpacing) + 1;
                rowsCount = Math.floor((containerHeight - verticalOffset) / verticalSpacing) + 1;
                dotsCount = Math.floor(columnsCount * rowsCount);

                // matrix algorithm might be useful

                var _loop = function _loop(i) {
                  var column = Math.floor(i % columnsCount);
                  var row = Math.floor(i / columnsCount);
                  var cx = Math.floor(column * horizontalSpacing) + horizontalOffset + (row % 2 ? horizontalSpacing / 2 : 0);
                  var cy = Math.floor(row * verticalSpacing) + verticalOffset;

                  if (!data.dots.find(function (d) {
                    return d.cx === cx && d.cy === cy;
                  })) {
                    var key = generateId();

                    data.dots.push({ cx: cx, cy: cy, key: key });
                  }
                };

                for (var i = 0; i < dotsCount; i++) {
                  _loop(i);
                }
              }

              firstDot = data.dots[0];
              lastDot = data.dots[data.dots.length - 1];

              // fill parent container
              svg.attr('height', '100%').attr('width', '100%');

              svg.selectAll('circle.dot').data(data.dots, function (d) {
                return d.key;
              }).enter().append('circle').classed('dot', true).attr('cx', function (d) {
                return d.cx;
              }).attr('cy', function (d) {
                return d.cy;
              }).attr('fill', colors.dots).attr('fill-opacity', 0).attr('r', 1).transition().duration(1000).attr('fill-opacity', opacity.dots.inactive);

              svg.selectAll('circle.dot').data(data.dots, function (d) {
                return d.key;
              }).exit().remove();

              // only on first run
              if (!loop) {
                setTimeout(function () {
                  generatePrescribedShapes();
                }, 1000);
              }

              setTimeout(function () {
                createLoop();
              }, 2000);
            };

            var connectNodes = function connectNodes(existing, added) {
              var getEnds = function getEnds() {
                var ends = {};

                // vertical
                if (added.cx === existing.cx) {
                  ends.x1 = added.cx;
                  ends.x2 = existing.cx;

                  if (added.cy > existing.cy) {
                    ends.y1 = added.cy - radius;
                    ends.y2 = existing.cy + radius;
                  } else {
                    ends.y1 = added.cy + radius;
                    ends.y2 = existing.cy - radius;
                  }
                  // angular (design does not allow for horizontal)
                } else {
                  var h = Math.round(radius * 0.85);
                  var v = Math.round(radius * 0.57);

                  if (added.cx > existing.cx) {
                    ends.x1 = added.cx - h;
                    ends.x2 = existing.cx + h;
                  } else {
                    ends.x1 = added.cx + h;
                    ends.x2 = existing.cx - h;
                  }

                  if (added.cy > existing.cy) {
                    ends.y1 = added.cy - v;
                    ends.y2 = existing.cy + v;
                  } else {
                    ends.y1 = added.cy + v;
                    ends.y2 = existing.cy - v;
                  }
                }

                return ends;
              };

              var getGradient = function getGradient() {
                var colors = added.stroke.slice(1) + '-' + existing.stroke.slice(1);
                var positions = '';

                if (added.cx > existing.cx) {
                  positions += '100-0-';
                } else if (added.cx < existing.cx) {
                  positions += '0-100-';
                } else {
                  positions += '0-0-';
                }

                if (added.cy > existing.cy) {
                  positions += '100-0';
                } else if (added.cy < existing.cy) {
                  positions += '0-100';
                } else {
                  positions += '0-0';
                }

                return colors + '-' + positions;
              };

              var key = generateId();
              var ends = getEnds();
              var stroke = added.stroke === existing.stroke ? added.stroke : getGradient();

              // perfectly vertical gradients do not work with gradientUnits: objectBoundingBox (default)
              // https://github.com/OriginProtocol/company-website/issues/2#issuecomment-359883885
              if (stroke.match(/0-0-(100-0|0-100)/)) {
                // replace percentage positions with precise coordinate points
                stroke = stroke.replace(/-\d+-\d+-\d+-\d+/, '-' + added.cx + '-' + existing.cx + '-' + added.cy + '-' + existing.cy);

                // boolean !condition will nearly always pass
                if (!document.getElementById(stroke)) {
                  // vertical line gradients are generated on demand for every color/position combination
                  var defs = d3.select('#particles > defs');
                  var lg = defs.append('linearGradient').attr('gradientUnits', 'userSpaceOnUse').attr('id', stroke).attr('x1', ends.x1).attr('x2', ends.x2).attr('y1', ends.y1).attr('y2', ends.y2);

                  lg.append('stop').attr('offset', '0%').attr('stop-color', '#' + stroke.slice(0, 6));

                  lg.append('stop').attr('offset', '100%').attr('stop-color', '#' + stroke.slice(7, 13));
                }
              }

              // unless stroke is a single color, convert it to a document id
              if (stroke.match('-')) {
                stroke = 'url(#' + stroke + ')';
              }

              // line originates from newly added node
              data.connections.push(Object.assign({}, ends, { key: key, stroke: stroke }));

              svg.selectAll('line.connection').data(data.connections, function (c) {
                return c.key;
              }).enter().append('line').classed('connection', true).attr('stroke', function (d) {
                return d.stroke;
              }).attr('stroke-width', 1).attr('x1', function (d) {
                return d.x1;
              }).attr('x2', function (d) {
                return d.x1;
              }).attr('y1', function (d) {
                return d.y1;
              }).attr('y2', function (d) {
                return d.y1;
              }).transition().delay(2000).duration(2000).attr('x2', function (d) {
                return d.x2;
              }).attr('y2', function (d) {
                return d.y2;
              });
            };

            var createLoop = function createLoop() {
              // initiate randomness
              loop = rafInterval(function () {
                generateRandomShape();
              }, 4000);
            };

            var disconnectNodes = function disconnectNodes(dot) {
              data.connections = data.connections.filter(function (c) {
                // multiple is a hack that catches all lines within appropriate proximity
                return (Math.abs(dot.cx - c.x1) >= radius * 2 || Math.abs(dot.cy - c.y1) >= radius * 2) && (Math.abs(dot.cx - c.x2) >= radius * 2 || Math.abs(dot.cy - c.y2) >= radius * 2);
              });

              svg.selectAll('line.connection').data(data.connections, function (c) {
                return c.key;
              }).exit().transition().duration(1000).attr('x2', function (d) {
                return d.x1;
              }).attr('y2', function (d) {
                return d.y1;
              }).remove();
            };

            // could probably use some recursion
            // but it helps to visualize
            var generateGradients = function generateGradients() {
              var defs = d3.select('#particles').append('defs');
              var combinedColors = [];

              // find every possible color pair
              colors.nodes.forEach(function (c) {
                colors.nodes.filter(function (d) {
                  return c !== d;
                }).forEach(function (d) {
                  if (!combinedColors.find(function (col) {
                    return col === c + '-' + d;
                  })) {
                    combinedColors.push(c.slice(1) + '-' + d.slice(1));
                  }

                  if (!combinedColors.find(function (col) {
                    return col === d + '-' + c;
                  })) {
                    combinedColors.push(d.slice(1) + '-' + c.slice(1));
                  }
                });
              });

              var combinedPositions = [];

              // find every possible gradient direction
              combinedColors.forEach(function (c) {
                // bottom-right-to-top-left
                combinedPositions.push({
                  id: c + '-100-0-100-0',
                  x1: '100%',
                  x2: '0%',
                  y1: '100%',
                  y2: '0%'
                });

                // bottom-left-to-top-right
                combinedPositions.push({
                  id: c + '-0-100-100-0',
                  x1: '0%',
                  x2: '100%',
                  y1: '100%',
                  y2: '0%'
                });

                // top-left-to-bottom-right
                combinedPositions.push({
                  id: c + '-0-100-0-100',
                  x1: '0%',
                  x2: '100%',
                  y1: '0%',
                  y2: '100%'
                });

                // top-right-to-bottom-left
                combinedPositions.push({
                  id: c + '-100-0-0-100',
                  x1: '100%',
                  x2: '0%',
                  y1: '0%',
                  y2: '100%'
                });
              });

              // gradient definition required for every possible combination
              combinedPositions.forEach(function (p) {
                var color1 = '#' + p.id.slice(0, 6);
                var color2 = '#' + p.id.slice(7, 13);
                var lg = defs.append('linearGradient').attr('id', p.id).attr('x1', p.x1).attr('x2', p.x2).attr('y1', p.y1).attr('y2', p.y2);

                lg.append('stop').attr('offset', '0%').attr('stop-color', color1);

                lg.append('stop').attr('offset', '100%').attr('stop-color', color2);
              });
            };

            var generateId = function generateId() {
              var chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
              var text = '';

              while (text.length < 8) {
                text += chars.charAt(randomInteger(chars.length));
              }

              return text;
            };

            var generatePrescribedShapes = function generatePrescribedShapes() {
              setTimeout(function () {
                addShape(getDotIndex(Math.floor(columnsCount * 0.4), 4), [{ x: 0, y: 2 }, { x: 1, y: 1 }, { x: 2, y: 0 }]);
              }, 200);

              setTimeout(function () {
                addShape(getDotIndex(Math.floor(columnsCount * 0.2), 7), [{ x: 0, y: 2 }]);
              }, 400);

              setTimeout(function () {
                addShape(getDotIndex(Math.floor(columnsCount * 0.63), 9), [{ x: 0, y: 2 }, { x: 1, y: -1 }]);
              }, 600);

              setTimeout(function () {
                addShape(getDotIndex(Math.floor(columnsCount * 0.95), 7), [{ x: -1, y: 1 }, { x: 1, y: 1 }]);
              }, 800);

              setTimeout(function () {
                addShape(getDotIndex(Math.floor(columnsCount * 0.35), 15), [{ x: 0, y: 2 }, { x: 1, y: 1 }]);
              }, 1000);

              setTimeout(function () {
                addShape(getDotIndex(Math.floor(columnsCount * 0.2), 17), [{ x: -2, y: 4 }, { x: 1, y: -1 }, { x: -1, y: 1 }, { x: -1, y: 3 }]);
              }, 600);
            };

            var generateRandomShape = function generateRandomShape() {
              var count = randomInteger(3);
              var index = randomInteger(data.dots.length);
              var connections = [];

              // random nearby node
              for (var i = 0; i < count; i++) {
                // horizontal distance
                var x = randomInteger(3);
                // vertical distance
                var y = randomInteger(3);

                // both must be odd or both must be even
                if (x % 2 !== y % 2) {
                  // randomly add or subtract one from y unless already max
                  y = y + (y < 2 ? Math.random() < 0.5 ? -1 : 1 : -1);
                }

                connections.push({ x: x, y: y });
              }

              addShape(index, connections);
            };

            var getDotIndex = function getDotIndex(column, row) {
              var x = (column - 1) * horizontalSpacing + horizontalOffset + (row % 2 ? 0 : horizontalSpacing / 2);
              var y = (row - 1) * verticalSpacing + verticalOffset;

              return data.dots.findIndex(function (d) {
                return d.cx === x && d.cy === y;
              });
            };

            // add or remove lines connecting to the relevant point
            var manageNodeConnections = function manageNodeConnections(dot) {
              var added = data.nodes.find(function (n) {
                return dot.cx === n.cx && dot.cy === n.cy;
              });

              if (added) {
                var adjacentDotPositions = [{ cx: dot.cx, cy: dot.cy - verticalSpacing * 2 }, { cx: dot.cx + horizontalSpacing / 2, cy: dot.cy - verticalSpacing }, { cx: dot.cx + horizontalSpacing / 2, cy: dot.cy + verticalSpacing }, { cx: dot.cx, cy: dot.cy + verticalSpacing * 2 }, { cx: dot.cx - horizontalSpacing / 2, cy: dot.cy + verticalSpacing }, { cx: dot.cx - horizontalSpacing / 2, cy: dot.cy - verticalSpacing }];

                adjacentDotPositions.forEach(function (pos) {
                  var node = data.nodes.find(function (n) {
                    return pos.cx === n.cx && pos.cy === n.cy;
                  });

                  if (node) {
                    connectNodes(node, added);
                  }
                });
              } else {
                disconnectNodes(dot);
              }
            };

            // result will be an integer less than exclusiveMax
            var randomInteger = function randomInteger(exclusiveMax) {
              return Math.floor(Math.random() * exclusiveMax);
            };

            var debouncedBuild = debounce(build, 400);

            // there may be a better way to defer execution until dependencies are met
            // but only run if included in a certain template
            window.addEventListener('load', build);
            window.addEventListener('resize', debouncedBuild);
          })();
        `
        particles
    new ParticlesView el: $this
}
