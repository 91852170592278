import * as React from "react";
import { useRef, useState } from "react";
import { Grid, Col, Spacer, Link, Icon } from "@react/components";

import GovernanceProposalsShowSidebar from "@react/views/governance/proposals/show/sidebar";
import GovernanceProposalContent from "@react/views/governance/proposals/show/content";
import SnapshotResults from "@react/views/governance/proposals/show/snapshot_results";

export interface GovernanceProposalShowProps {
  protocol: any;
  proposal: any;
  proposal_results: any;
  voting_entities: any[];
  entity: any;
  entity_can_vote: boolean;
  entity_current_vote: any;
  entity_voting_power_balance: any;
  governance_protocol_path: string;
  governance_proposal_path: string;
  governance_path: string;
  governance_faq_path: string;
}

export const GovernanceProposalShow: React.FunctionComponent<GovernanceProposalShowProps> = ({
  protocol,
  proposal,
  proposal_results,
  voting_entities,
  entity,
  entity_can_vote = false,
  entity_current_vote = "",
  entity_voting_power_balance,
  governance_protocol_path,
  governance_proposal_path,
  governance_path,
  governance_faq_path,
}) => {
  const multipleChoices = proposal.voting_system == "multiple_choices";
  const defaultValue = multipleChoices ? [] : "";

  const [initialVote, setInitialVote] = useState<any>(
    entity_current_vote ? entity_current_vote.value : defaultValue
  );

  const [currentVote, setCurrentVote] = useState<any>(initialVote);

  const votingTermRef = useRef<HTMLDivElement>(null);

  return (
    <Grid>
      <Col sm="24">
        <h1 className="s-fontSize20 s-marginBottom2 u-fontWeight700 s-borderBottom1 u-displayFlex u-alignItemsCenter u-hiddenSmDown">
          <span className="u-whiteSpaceNowrap">
            <Link href={governance_path}>
              <span className="s-marginRight0_5">
                <Icon icon="governance" />
              </span>
              Governance Protocols
            </Link>
          </span>
          <span className="s-paddingHoriz0_5 s-fontSize18 u-displayInlineBlock u-colorGrayA">
            /
          </span>
          <Link href={governance_protocol_path}>
            <div className="u-displayFlex u-alignItemsCenter u-whiteSpaceNowrap">
              {protocol.token_profile.name} Proposals
            </div>
          </Link>
          <span className="u-flex1 u-textAlignRight u-fontWeight400">
            <Link href={governance_faq_path} target="_blank">
              FAQs
            </Link>
          </span>
        </h1>
        <p className="u-hiddenMdUp s-marginBottom1 u-displayFlex">
          <Link href={governance_protocol_path}>
            &laquo; Back to {protocol.token_profile.name} Proposals
          </Link>
          <span className="u-flex1 u-fontWeight400 u-textAlignRight">
            <Link href={governance_faq_path} target="_blank">
              FAQs
            </Link>
          </span>
        </p>
      </Col>
      <Col sm="16">
        <GovernanceProposalContent
          proposal={proposal}
          protocol={protocol}
          entity={entity}
          entity_can_vote={entity_can_vote}
          currentVote={currentVote}
          setCurrentVote={setCurrentVote}
        />
      </Col>
      <Col sm="8">
        <GovernanceProposalsShowSidebar
          entity={entity}
          entity_can_vote={entity_can_vote}
          governance_faq_path={governance_faq_path}
          entity_voting_power_balance={entity_voting_power_balance}
          protocol={protocol}
          proposal={proposal}
          proposal_results={proposal_results}
          governance_proposal_path={governance_proposal_path}
          voting_entities={voting_entities}
          currentVote={currentVote}
          setCurrentVote={setCurrentVote}
          initialVote={initialVote}
          setInitialVote={setInitialVote}
          votingTermRef={votingTermRef}
        />
        <Spacer />
        {!protocol.egl && entity_can_vote && (
          <div className="c-box s-padding1_5" ref={votingTermRef}>
            <strong>Voting terms</strong>
            <ul className="s-fontSize14 u-fontWeight400 u-colorGray8 u-listUnstyled">
              <li className="s-marginTop0_5">
                <strong>1. </strong>I have reviewed the "{proposal.title}"
                proposal related to the {protocol.token_profile.name} Protocol
                (the “Vote”), and understand that by agreeing to the following
                terms (these “Voting Terms”) I am agreeing to the terms of the
                Vote as well as the terms and applicable rules of the Snapshot
                Platform (through which CoinList will vote on "{proposal.title}
                ") available{" "}
                <Link
                  href="https://docs.snapshot.org/proposals/voting-types"
                  target="_blank"
                >
                  here
                </Link>
                . I understand that the information provided by CoinList about
                the Vote was taken directly from the Snapshot Platform without
                editing or verifying the veracity of the statements contained
                therein.
              </li>
              <li className="s-marginTop0_5">
                <strong>2. </strong>
                By participating in the Vote, I am agreeing to vote my{" "}
                {protocol.token_profile.asset} (my “Tokens”) held in my
                “CoinList “Wallet” through CoinList Markets at the time of the
                applicable snapshot.
              </li>
              <li className="s-marginTop0_5">
                <strong>3. </strong>I understand that eligibility to participate
                in the Vote is determined by a “snapshot” of Token holdings as
                of the applicable snapshot time for the relevant proposal (the
                “Snapshot Time”). If I held Tokens directly at the Snapshot
                Time, I would have been able to participate in the Vote directly
                through the Protocol or through the Snapshot Platform. By
                participating in the Vote through CoinList, I understand that my
                vote is subject to further terms and conditions available{" "}
                <Link href={governance_faq_path} target="_blank">
                  here
                </Link>
                . I further understand that, by participating through CoinList,
                I will be unable to change my vote once CoinList closes its
                internal vote and submits its on-chain vote in connection with
                this proposal.
              </li>
              <li className="s-marginTop0_5">
                <strong>4. </strong>
                In voting through CoinList I may be able to choose one or
                multiple selections–depending on what the proposal calls for–on
                the question presented in the Vote (my “Selection”) at the time
                of voting. I understand that CoinList will vote my Tokens in
                accordance with my Selection. I further understand that I will
                not be able to alter my Selection once CoinList closes its
                internal vote and submits its on-chain vote in connection with
                this proposal.
              </li>
              <li className="s-marginTop0_5">
                <strong>5. </strong>I understand the risks of participating in
                blockchain and distributed ledger technology networks include,
                but are not limited to, those listed here, and I accept such
                risks.
              </li>
              <li className="s-marginTop0_5">
                <strong>6. </strong>I agree to digitally agree to these terms
                and to receive information in electronic form.
              </li>
              <li className="s-marginTop0_5">
                <strong>7. </strong>
                In addition to the Voting Terms, I acknowledge that I also
                continue to be bound to the CoinList’s regular{" "}
                <Link href="/terms" target="_blank">
                  Terms of Service
                </Link>{" "}
                (the “Terms of Service”), and attest that I am not violating
                presently, and will not violate in the future, any of the Terms
                of Service, which, among other rights and obligations,
                explicitly prohibits the use of bots or scripts.
              </li>
            </ul>
          </div>
        )}
      </Col>
    </Grid>
  );
};

export default GovernanceProposalShow;
