import * as React from "react";

import Flex from "../Flex";
import SectionTitle from "../SectionTitle";
import Typography from "../typography/Typography";

export interface HeaderSubtitleGridSteps {
  header: string;
  subtitle: string;
}

interface HeaderSubtitleGridProps {
  gridItems: Array<HeaderSubtitleGridSteps>;
  style?: any;
  title: string;
}

export default function HeaderSubtitleGrid(props: HeaderSubtitleGridProps) {
  return (
    <Flex container style={props.style}>
      <SectionTitle style={{ paddingBottom: 24 }} title={props.title} />
      <Flex item xs={24}>
        <Flex alignItems="flex-start" container spacing={5}>
          {props.gridItems.map(({ header, subtitle }) => (
            <Flex item key={header} sm={12} xs={24}>
              <Typography
                bold
                fontSize={18}
                style={{ paddingBottom: 8, width: "100%" }}
              >
                {header}
              </Typography>
              <Typography style={{ paddingBottom: 8, width: "100%" }}>
                {subtitle}
              </Typography>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
}
